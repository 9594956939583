import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserRepository from '@/services/repository/UserRepository';
import UserPreferences from '@/services/UserPreferences';
import VueUtilities from '@/services/VueUtilities';
import WarningModal from '@/views/components/administration/WarningModal.vue';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import SortDirections from '@/entities/enums/SortDirections';
import AppConfig from '@/configLoader';
import debounce from 'lodash.debounce';
var userRepository;
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
const DEBOUNCE_TIMER = 500;
let AdminUserList = class AdminUserList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.ApiUserRoles = ApiUserRoles;
        this.isDisableModalActive = false;
        this.selectedUser = null;
        this.userRolesOptions = ['Company owner', 'System administrator', 'Regular user'];
        this.selectedValue = '';
        this.isIdSearch = false;
        this.isEmailSearch = false;
        this.isUsernameSearch = false;
        this.isFullNameSearch = true;
        this.isCompanySearch = false;
        this.isPhoneSearch = false;
        this.isEnabledSearch = false;
        this.isVerifiedSearch = false;
        this.isNewsletterSearch = false;
        this.idSearch = '';
        this.emailSearch = '';
        this.usernameSearch = '';
        this.fullnameSearch = '';
        this.phoneSearch = '';
        this.enabledSearch = null;
        this.verifiedSearch = null;
        this.newsletterSearch = null;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.currentFilter = [];
        this.total = 0;
        this.tableColumns = {
            apiUserId: {
                field: 'ApiUserId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListApiUserId),
                hasSearch: true
            },
            fullName: {
                field: 'FullName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListFullName),
                hasSearch: true
            },
            email: {
                field: 'Email',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListEmail),
                hasSearch: true
            },
            username: {
                field: 'Username',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListUsername),
                hasSearch: true
            },
            phoneNumber: {
                field: 'PhoneNumber',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListPhoneNumber),
                hasSearch: true
            },
            enabled: {
                field: 'Enabled',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListEnabled),
                hasSearch: true
            },
            verified: {
                field: 'Verified',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListVerified),
                hasSearch: true
            },
            newsletter: {
                field: 'Newsletter',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListNewsletter),
                hasSearch: true
            },
            registeredAt: {
                field: 'RegisteredAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListRegisteredAt),
                hasSearch: false
            },
            lastActivity: {
                field: 'LastActivity',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListLastActivity),
                hasSearch: false
            }
        };
        this.handleFilterIdDebounce = debounce((e) => {
            this.filterUsers(this.tableColumns.apiUserId.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterEmailDebounce = debounce((e) => {
            this.filterUsers(this.tableColumns.email.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterUsernameDebounce = debounce((e) => {
            this.filterUsers(this.tableColumns.username.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterFullnameDebounce = debounce((e) => {
            this.filterUsers(this.tableColumns.fullName.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterPhoneNumberDebounce = debounce((e) => {
            this.filterUsers(this.tableColumns.phoneNumber.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    get hasCopyIconAllowed() {
        return AppConfig.getConfig().features.copy_to_clipboard_btn == true;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    get isMobileView() {
        return window.innerWidth <= 768;
    }
    created() {
        userRepository = new UserRepository(this);
        this.selectedValue = this.tableColumns.fullName.field;
        this.fillFilterFromRoute();
        this.fillSearches();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadUsers', this.pagination, this.sort, this.currentFilter);
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminUser);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.count;
            this.currentFilter = JSON.parse(this.tableQueriesfromUrl.Filter);
            // send queries to parent component
            let objStringAdminUser = JSON.stringify(this.tableQueriesfromUrl);
            this.$emit('changeRouterUrlFromUser', objStringAdminUser);
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminUserSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminUserSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminUserPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminUserRowsPerPage.defaultValue;
    }
    fillSearches() {
        if (this.currentFilter.length !== 0) {
            this.idSearch =
                this.filterExists('ApiUserId==') !== -1
                    ? this.currentFilter[this.filterExists('ApiUserId==')].substring(11)
                    : '';
            this.emailSearch =
                this.filterExists('Email@=') !== -1 ? this.currentFilter[this.filterExists('Email@=')].substring(7) : '';
            this.usernameSearch =
                this.filterExists('Username@=') !== -1 ? this.currentFilter[this.filterExists('Username@=')].substring(10) : '';
            this.fullnameSearch =
                this.filterExists('FullName@=') !== -1 ? this.currentFilter[this.filterExists('FullName@=')].substring(10) : '';
            this.phoneSearch =
                this.filterExists('PhoneNumber@=') !== -1
                    ? this.currentFilter[this.filterExists('PhoneNumber@=')].substring(13)
                    : '';
            this.enabledSearch =
                this.filterExists('Enabled==true') !== -1 ? true : this.filterExists('Enabled==false') !== -1 ? false : null;
            this.verifiedSearch =
                this.filterExists('Verified==true') !== -1 ? true : this.filterExists('Verified==false') !== -1 ? false : null;
            this.newsletterSearch =
                this.filterExists('Newsletter==true') !== -1
                    ? true
                    : this.filterExists('Newsletter==false') !== -1
                        ? false
                        : null;
        }
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onOptionsChange(pagination, sort) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter);
        this.$emit('onOptionsChange', options, this.currentFilter);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    filterUsers(searchField, value) {
        value = value.includes(',') ? value.replace(/,/g, '\\,') : value;
        switch (searchField) {
            case this.tableColumns.apiUserId.field:
                this.$validator.validateAll().then(async (result) => {
                    if (result) {
                        this.replaceFilter('ApiUserId==', value);
                    }
                    else {
                        return;
                    }
                });
                break;
            case this.tableColumns.email.field:
                this.replaceFilter('Email@=', value);
                break;
            case this.tableColumns.username.field:
                this.replaceFilter('Username@=', value);
                break;
            case this.tableColumns.fullName.field:
                this.replaceFilter('FullName@=', value);
                break;
            case this.tableColumns.phoneNumber.field:
                this.replaceFilter('PhoneNumber@=', value);
                break;
            case this.tableColumns.enabled.field:
                this.replaceFilter('Enabled==', value);
                break;
            case this.tableColumns.verified.field:
                this.replaceFilter('Verified==', value);
                break;
            case this.tableColumns.newsletter.field:
                this.replaceFilter('Newsletter==', value);
                break;
            default:
                break;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.onOptionsChange(this.pagination, this.sort);
            }
        });
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value || value == null || value == '') {
                this.currentFilter.splice(index, 1);
            }
            else {
                this.currentFilter[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilter.push(filter + value);
    }
    openDisableModal(user) {
        this.selectedUser = user;
        this.isDisableModalActive = true;
    }
    closeModal() {
        this.isDisableModalActive = false;
    }
    changeRouterUrl(pagination, sort, currentFilter) {
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize,
            Filter: JSON.stringify(currentFilter.filter((x) => x != null))
        };
        let objStringAdminUser = JSON.stringify(this.tableQueries);
        this.$emit('changeRouterUrlFromUser', objStringAdminUser);
    }
    async enableUser(user) {
        let reason = {
            ActionReason: null
        };
        await userRepository.adminEnableUser(user.id, reason);
        VueUtilities.openSuccessToast(this, this.$t('component.userAdministration.list.disable.successfully_enabled').toString());
        this.$emit('reload', this.pagination, this.sort, this.currentFilter);
    }
    async disableUser(action, reasonText) {
        this.isDisableModalActive = false;
        let reason = {
            ActionReason: reasonText
        };
        await userRepository.adminDisableUser(this.selectedUser.id, reason);
        VueUtilities.openSuccessToast(this, this.$t('component.userAdministration.list.disable.successfully_disabled').toString());
        this.selectedUser = null;
        this.$emit('reload', this.pagination, this.sort, this.currentFilter);
    }
    clearFilter() {
        this.idSearch = '';
        this.emailSearch = '';
        this.usernameSearch = '';
        this.fullnameSearch = '';
        this.phoneSearch = '';
        this.enabledSearch = null;
        this.verifiedSearch = null;
        this.newsletterSearch = null;
        this.currentFilter = [];
        this.onPageChange(1);
    }
    changeSearchField(event) {
        this.isIdSearch = false;
        this.isEmailSearch = false;
        this.isUsernameSearch = false;
        this.isFullNameSearch = false;
        this.isCompanySearch = false;
        this.isPhoneSearch = false;
        this.isEnabledSearch = false;
        this.isVerifiedSearch = false;
        this.isNewsletterSearch = false;
        switch (event) {
            case this.tableColumns.apiUserId.field:
                this.isIdSearch = true;
                break;
            case this.tableColumns.email.field:
                this.isEmailSearch = true;
                break;
            case this.tableColumns.username.field:
                this.isUsernameSearch = true;
                break;
            case this.tableColumns.fullName.field:
                this.isFullNameSearch = true;
                break;
            case this.tableColumns.phoneNumber.field:
                this.isPhoneSearch = true;
                break;
            case this.tableColumns.enabled.field:
                this.isEnabledSearch = true;
                break;
            case this.tableColumns.verified.field:
                this.isVerifiedSearch = true;
                break;
            case this.tableColumns.newsletter.field:
                this.isNewsletterSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    copyLinkToClipboard(id) {
        try {
            navigator.clipboard.writeText(id);
            VueUtilities.openSuccessToast(this, this.$t('generals.copy_success'));
        }
        catch (err) {
            VueUtilities.openErrorToast(this, this.$t('generals.copy_failure'));
        }
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.fullName.field;
        this.changeSearchField(this.tableColumns.fullName.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.apiUserId.field:
                if (!event) {
                    this.idSearch = '';
                    this.filterUsers(this.tableColumns.apiUserId.field, this.idSearch);
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.apiUserId.field)
                        this.sort.field = this.tableColumns.fullName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListApiUserId, event);
                break;
            case this.tableColumns.email.field:
                if (!event) {
                    this.emailSearch = '';
                    this.filterUsers(this.tableColumns.email.field, this.emailSearch);
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.email.field)
                        this.sort.field = this.tableColumns.fullName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListEmail, event);
                break;
            case this.tableColumns.username.field:
                if (!event) {
                    this.usernameSearch = '';
                    this.filterUsers(this.tableColumns.username.field, this.usernameSearch);
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.username.field)
                        this.sort.field = this.tableColumns.fullName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListUsername, event);
                break;
            case this.tableColumns.phoneNumber.field:
                if (!event) {
                    this.phoneSearch = '';
                    this.filterUsers(this.tableColumns.phoneNumber.field, this.phoneSearch);
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.phoneNumber.field)
                        this.sort.field = this.tableColumns.fullName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListPhoneNumber, event);
                break;
            case this.tableColumns.enabled.field:
                if (!event) {
                    this.enabledSearch = null;
                    this.currentFilter.splice(this.currentFilter.findIndex((element) => element.includes('Enabled==')), 1);
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.enabled.field)
                        this.sort.field = this.tableColumns.fullName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListEnabled, event);
                break;
            case this.tableColumns.verified.field:
                if (!event) {
                    this.verifiedSearch = null;
                    this.currentFilter.splice(this.currentFilter.findIndex((element) => element.includes('Verified==')), 1);
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.verified.field)
                        this.sort.field = this.tableColumns.fullName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListVerified, event);
                break;
            case this.tableColumns.newsletter.field:
                if (!event) {
                    this.newsletterSearch = null;
                    this.currentFilter.splice(this.currentFilter.findIndex((element) => element.includes('Newsletter==')), 1);
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.newsletter.field)
                        this.sort.field = this.tableColumns.fullName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListNewsletter, event);
                break;
            case this.tableColumns.registeredAt.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.registeredAt.field)
                        this.sort.field = this.tableColumns.fullName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListRegisteredAt, event);
                break;
            case this.tableColumns.lastActivity.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.lastActivity.field)
                        this.sort.field = this.tableColumns.fullName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListLastActivity, event);
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Array })
], AdminUserList.prototype, "users", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Number })
], AdminUserList.prototype, "totalSize", void 0);
AdminUserList = __decorate([
    Component({ components: { WarningModal } })
], AdminUserList);
export default AdminUserList;
