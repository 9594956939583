var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoadingOverlay
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoadingOverlay,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoadingOverlay = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "is-flex is-justify-content-center mx-3 mx-0-desktop" },
        [
          _c("h1", { staticClass: "title is-3 mr-2" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("component.group_dashboard.title")) + " "
            ),
          ]),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DASHBOARD)
            ? _c("b-icon", {
                staticClass: "clickable mt-1",
                attrs: { icon: "share-alt", size: "is-medium" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.createPublicDashboard()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.hasSources
        ? _c(
            "div",
            { staticClass: "tile is-parent" },
            [
              _c(
                "b-message",
                {
                  staticClass: "tile is-child",
                  attrs: { type: "is-warning", "has-icon": "" },
                },
                [
                  _vm._v(_vm._s(_vm.$t("error_messages.no_sources")) + " "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "configuration_loggers",
                          params: {
                            lang: _vm.$route.params.lang,
                            departmentId: _vm.$route.params.departmentId,
                          },
                        },
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("error_messages.no_sources_link"))
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "tile is-ancestor" }, [
            _c("div", { staticClass: "tile is-vertical is-full-width" }, [
              _vm.$ability.can(
                _vm.$permActions.READ,
                _vm.$permSubjects.SOURCE_GROUP
              ) &&
              _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.SOURCE)
                ? _c(
                    "div",
                    { staticClass: "tile is-parent" },
                    [
                      !_vm.isLoading.SourceGroupPicker
                        ? _c("SourceGroupPicker", {
                            staticClass: "tile is-child box",
                            attrs: {
                              sourceGroups: _vm.sourceGroups,
                              selectedSourceGroupId: _vm.selectedSourceGroupId,
                              sources: _vm.sources,
                              favoriteGroupId: _vm.favoriteGroupId,
                            },
                            on: {
                              sourceGroupSelected: _vm.sourceGroupSelected,
                              favoriteSelected: _vm.favoriteSelected,
                            },
                          })
                        : _c(
                            "div",
                            { staticClass: "tile is-child box" },
                            [
                              _c("b-skeleton", {
                                attrs: { height: "90px", animated: true },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$ability.can(
                _vm.$permActions.READ,
                _vm.$permSubjects.SOURCE_GROUP
              ) &&
              _vm.$ability.can(
                _vm.$permActions.READ,
                _vm.$permSubjects.MEASURED_DATA_READ
              )
                ? _c(
                    "div",
                    { staticClass: "tile is-parent" },
                    [
                      _c("LastEventsComponent", {
                        staticClass: "tile is-child box",
                        attrs: {
                          sourceGroupSources: _vm.sourceGroupSources,
                          sourceGroupEvents: _vm.sourceGroupEvents,
                          refreshLastEvents: _vm.refreshLastEvents,
                          componentIsLoading: _vm.isLoading.LastEventsComponent,
                        },
                        on: {
                          refreshChanged: _vm.refreshChanged,
                          scrollToChartFilter: _vm.scrollToChartFilter,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$ability.can(
                _vm.$permActions.READ,
                _vm.$permSubjects.MEASURED_DATA_READ
              )
                ? _c(
                    "div",
                    { staticClass: "tile is-parent" },
                    [
                      _c("ChartComponent", {
                        staticClass: "tile is-child box",
                        attrs: {
                          otherServiceSources: _vm.otherServiceSources,
                          upTimeServiceSources: _vm.upTimeServiceSources,
                          chartData: _vm.otherSourcesChartData,
                          upTimeChartData: _vm.upTimeSourceschartData,
                          annotationsForSources: _vm.annotationsForSources,
                          eventTypes: _vm.eventTypes,
                          chartHeight: _vm.chartHeight,
                          dayBeforeOption: _vm.selectedDayOption,
                          componentIsLoading: _vm.isLoading.ChartComponent,
                        },
                        on: {
                          dayOptionSelected: _vm.dayOptionSelected,
                          changeUserSetting: _vm.changeUserSetting,
                          eventHandler: _vm.eventHandler,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCreateDashboardModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeCreateDashboardModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCreateDashboardModalActive = $event
            },
            "close-modal": _vm.closeCreateDashboardModal,
          },
        },
        [
          _c("CreateDashboardModal", {
            attrs: {
              sources: _vm.sources,
              availableDevices: _vm.devices,
              availableSourceGroups: _vm.sourceGroups,
              availableUsers: _vm.users,
            },
            on: { modalClosed: _vm.closeCreateDashboardModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }