var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _vm.isLayoutList
        ? _c("div", { staticClass: "level section-header-level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("h2", { staticClass: "level-item subtitle is-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.detail.firmwareHistory.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "b-table",
        {
          staticClass: "table-wrap",
          attrs: { data: _vm.device.firmware.history, striped: "" },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.devices.detail.firmwareHistory.table.empty"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "version",
              label: _vm.$t(
                "admin.component.devices.detail.firmwareHistory.table.version"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (instance) {
                  return [_vm._v(_vm._s(instance.row.version))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "updatedAt",
              label: _vm.$t(
                "admin.component.devices.detail.firmwareHistory.table.updatedAt"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (instance) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(instance.row.updatedAt * 1000)
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }