var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _c(
            "b-tabs",
            {
              attrs: { type: "is-boxed" },
              on: { input: _vm.scheduleTabSelected },
              model: {
                value: _vm.selectedScheduleId,
                callback: function ($$v) {
                  _vm.selectedScheduleId = $$v
                },
                expression: "selectedScheduleId",
              },
            },
            [
              _vm._l(_vm.timetableSchedules, function (schedule) {
                return _c(
                  "b-tab-item",
                  {
                    key: schedule.id,
                    attrs: { value: schedule.id },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              !schedule.name
                                ? _c("b-icon", {
                                    attrs: { icon: "exclamation-triangle" },
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                { class: { "is-italic": !schedule.name } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        schedule.name ||
                                          _vm.$t(
                                            "notifications.configuration.schedule_name_missing"
                                          )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("div", {
                                staticClass: "color-circle ml-2",
                                style: {
                                  backgroundColor:
                                    schedule.additionalProperties.color,
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm.isChangingSchedulesTab
                      ? _c("b-loading", {
                          attrs: {
                            "is-full-page": true,
                            active: _vm.isChangingSchedulesTab,
                            "can-cancel": false,
                          },
                          on: {
                            "update:active": function ($event) {
                              _vm.isChangingSchedulesTab = $event
                            },
                          },
                        })
                      : _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "buttons is-right mb-0" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      "icon-left": "clone",
                                      type: "is-primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.duplicateSchedule(schedule)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "notifications.configuration.duplicate_schedule"
                                        )
                                      ) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "columns pt-4" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "column is-one-quarter is-one-quarter-widescreen",
                                },
                                [
                                  _c(
                                    "h2",
                                    { staticClass: "subtitle has-text-dark" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "notifications.configuration.schedule_name_label"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "is-flex is-align-items-center",
                                  },
                                  [
                                    _c(
                                      "b-field",
                                      {
                                        staticClass: "mb-0",
                                        attrs: {
                                          type: {
                                            "is-danger": _vm.errors.has(
                                              _vm.$t(
                                                "notifications.configuration.schedule_name_label"
                                              )
                                            ),
                                          },
                                          message: _vm.errors.first(
                                            _vm.$t(
                                              "notifications.configuration.schedule_name_label"
                                            )
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required|max:50",
                                              expression: "'required|max:50'",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "notifications.configuration.schedule_name_label"
                                            ),
                                            type: "text",
                                            name: _vm.$t(
                                              "notifications.configuration.schedule_name_label"
                                            ),
                                          },
                                          on: { input: _vm.nameChanged },
                                          model: {
                                            value: schedule.name,
                                            callback: function ($$v) {
                                              _vm.$set(schedule, "name", $$v)
                                            },
                                            expression: "schedule.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.timetableSchedules.length > 1
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "button is-small is-primary ml-5",
                                            attrs: {
                                              "icon-right": "trash",
                                              type: "is-white",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeSchedule(
                                                  schedule.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "notifications.configuration.delete_schedule"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("hr"),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-4 is-italic has-text-grey has-text-grey-dark-mode",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "notifications.configuration.timetable.description",
                                        { name: schedule.name || "-" }
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-4 timetable" },
                              _vm._l(_vm.days, function (d) {
                                return _c("div", { key: d }, [
                                  _c("div", { staticClass: "columns" }, [
                                    _c("span", { staticClass: "column is-1" }, [
                                      _vm._v(_vm._s(_vm.$t("days." + d))),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column is-flex is-flex-direction-row is-flex-wrap-wrap flex-column-gap-5 flex-row-gap-5",
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mr-4",
                                            attrs: {
                                              type: {
                                                "is-primary":
                                                  schedule.isAllDay(d),
                                              },
                                              size: "is-small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.daySelected(
                                                  d,
                                                  schedule.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "notifications.configuration.timetable.all_day"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._l(_vm.hours, function (h) {
                                          return _c(
                                            "b-button",
                                            {
                                              key: d + "-" + h,
                                              style: {
                                                backgroundColor: (
                                                  (
                                                    _vm.timetableSchedules.find(
                                                      function (x) {
                                                        return x.weekMinutes.includes(
                                                          _vm.calculateHoursValue(
                                                            d * 24 + h
                                                          )
                                                        )
                                                      }
                                                    ) || {}
                                                  ).additionalProperties || {}
                                                ).color,
                                                opacity:
                                                  _vm.selectedSchedule.weekMinutes.includes(
                                                    _vm.calculateHoursValue(
                                                      d * 24 + h
                                                    )
                                                  )
                                                    ? 1
                                                    : 0.5,
                                              },
                                              attrs: {
                                                size: "is-small",
                                                rounded: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.hourSelected(
                                                    _vm.calculateHoursValue(
                                                      d * 24 + h
                                                    ),
                                                    schedule.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.formatHour(h)))]
                                          )
                                        }),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "notifications.configuration.timetable.copy_day"
                                              ),
                                              position: "is-top",
                                              type: "is-dark",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  size: "is-small",
                                                  rounded: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.copyDay(d)
                                                  },
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: {
                                                    type: "is-primary",
                                                    icon: "copy",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "notifications.configuration.timetable.paste_day"
                                              ),
                                              position: "is-top",
                                              type: "is-dark",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  size: "is-small",
                                                  rounded: "",
                                                  disabled:
                                                    _vm.copiedDay === null,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.pasteDay(d)
                                                  },
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: {
                                                    type: "is-primary",
                                                    icon: "paste",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                            _c("hr"),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "pt-4 pb-5 subtitle has-text-weight-semibold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "notifications.configuration.general_or_preset"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "b-dropdown",
                                  {
                                    attrs: { "aria-role": "list" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.applyPresetTimetable(
                                          schedule,
                                          $event
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "trigger",
                                          fn: function (ref) {
                                            var active = ref.active
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "is-italic is-underlined clickable-text",
                                                  attrs: { role: "button" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "notifications.configuration.general_or_preset_link"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("b-icon", {
                                                    attrs: {
                                                      icon: active
                                                        ? "caret-up"
                                                        : "caret-down",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        schedule.additionalProperties
                                          .usedPreset,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          schedule.additionalProperties,
                                          "usedPreset",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "schedule.additionalProperties.usedPreset",
                                    },
                                  },
                                  _vm._l(_vm.presets, function (preset) {
                                    return _c(
                                      "b-dropdown-item",
                                      {
                                        key: preset.id,
                                        attrs: {
                                          "aria-role": "listitem",
                                          value: preset,
                                        },
                                      },
                                      [_vm._v(_vm._s(preset.name))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            schedule.additionalProperties
                              .selectedMeasuredRulesModeTimetable ===
                            _vm.NotificationMeasuredRuleScope.COMPANY
                              ? _c("MeasuredDataNotification", {
                                  attrs: {
                                    rule: _vm.singleMeasuredRuleTimetable(
                                      schedule.id
                                    ),
                                    types: _vm.measuredTypes,
                                    currentUser: _vm.currentUser,
                                    selectedMeasuredRulesMode:
                                      schedule.additionalProperties
                                        .selectedMeasuredRulesModeTimetable,
                                    departmentId: _vm.departmentId,
                                    autoSave: false,
                                    measuredRuleEnabled: _vm.measuredRuleEnabled
                                      ? _vm.measuredRuleEnabled
                                      : _vm.getMeasuredRuleEnabled(schedule.id),
                                    tier: _vm.tier,
                                  },
                                  on: {
                                    ruleChanged: function ($event) {
                                      return _vm.timetableSubscriptionChanged(
                                        $event,
                                        schedule.id
                                      )
                                    },
                                    changeMode: function ($event) {
                                      return _vm.changeModeTimetable(
                                        $event,
                                        schedule.id
                                      )
                                    },
                                    switchedValue: _vm.switchedValue,
                                  },
                                })
                              : schedule.additionalProperties
                                  .selectedMeasuredRulesModeTimetable ===
                                _vm.NotificationMeasuredRuleScope
                                  .SOURCE_OR_GROUP
                              ? _c("EntityMeasuredDataNotification", {
                                  attrs: {
                                    rules: _vm.measuredRulesTimetable(
                                      schedule.id
                                    ),
                                    types: _vm.measuredTypes,
                                    currentUser: _vm.currentUser,
                                    selectedMeasuredRulesMode:
                                      schedule.additionalProperties
                                        .selectedMeasuredRulesModeTimetable,
                                    departmentId: _vm.departmentId,
                                    autoSave: false,
                                    tier: _vm.tier,
                                  },
                                  on: {
                                    changeMode: function ($event) {
                                      return _vm.changeModeTimetable(
                                        $event,
                                        schedule.id
                                      )
                                    },
                                    ruleChanged: function ($event) {
                                      return _vm.timetableSubscriptionChanged(
                                        $event,
                                        schedule.id
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("hr"),
                            _c("GeneralNotificationComponent", {
                              attrs: {
                                rule: _vm.getRuleForTimetable(
                                  _vm.NotificationSubscriptionType
                                    .DEVICE_OFFLINE,
                                  schedule.id
                                ),
                                type: _vm.getType(
                                  _vm.NotificationSubscriptionType
                                    .DEVICE_OFFLINE
                                ),
                                currentUser: _vm.currentUser,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                scheduleId: _vm.selectedScheduleId,
                              },
                              on: {
                                ruleChanged: function ($event) {
                                  return _vm.timetableSubscriptionChanged(
                                    $event,
                                    schedule.id
                                  )
                                },
                              },
                            }),
                            _c("hr"),
                            _c("GeneralNotificationComponent", {
                              attrs: {
                                rule: _vm.getRuleForTimetable(
                                  _vm.NotificationSubscriptionType
                                    .SOURCE_OFFLINE,
                                  schedule.id
                                ),
                                type: _vm.getType(
                                  _vm.NotificationSubscriptionType
                                    .SOURCE_OFFLINE
                                ),
                                currentUser: _vm.currentUser,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                scheduleId: _vm.selectedScheduleId,
                              },
                              on: {
                                ruleChanged: function ($event) {
                                  return _vm.timetableSubscriptionChanged(
                                    $event,
                                    schedule.id
                                  )
                                },
                              },
                            }),
                            _c("hr"),
                            _c(
                              "h2",
                              { staticClass: "subtitle has-text-dark" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "notifications.configuration.other_rules_title"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c("GeneralNotificationComponent", {
                              attrs: {
                                rule: _vm.getRuleForTimetable(
                                  _vm.NotificationSubscriptionType.LOW_BATTERY,
                                  schedule.id
                                ),
                                type: _vm.getType(
                                  _vm.NotificationSubscriptionType.LOW_BATTERY
                                ),
                                currentUser: _vm.currentUser,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                scheduleId: _vm.selectedScheduleId,
                              },
                              on: {
                                ruleChanged: function ($event) {
                                  return _vm.timetableSubscriptionChanged(
                                    $event,
                                    schedule.id
                                  )
                                },
                              },
                            }),
                            _c("GeneralNotificationComponent", {
                              attrs: {
                                rule: _vm.getRuleForTimetable(
                                  _vm.NotificationSubscriptionType
                                    .CUSTOMER_ENDING_CALIBRATION,
                                  schedule.id
                                ),
                                type: _vm.getType(
                                  _vm.NotificationSubscriptionType
                                    .CUSTOMER_ENDING_CALIBRATION
                                ),
                                currentUser: _vm.currentUser,
                                advancedAllowed: false,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                scheduleId: _vm.selectedScheduleId,
                              },
                              on: {
                                ruleChanged: function ($event) {
                                  return _vm.timetableSubscriptionChanged(
                                    $event,
                                    schedule.id
                                  )
                                },
                              },
                            }),
                            _c("GeneralNotificationComponent", {
                              attrs: {
                                rule: _vm.getRuleForTimetable(
                                  _vm.NotificationSubscriptionType
                                    .PDF_RECORDS_REGENERATED,
                                  schedule.id
                                ),
                                type: _vm.getType(
                                  _vm.NotificationSubscriptionType
                                    .PDF_RECORDS_REGENERATED
                                ),
                                currentUser: _vm.currentUser,
                                advancedAllowed: false,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                scheduleId: _vm.selectedScheduleId,
                              },
                              on: {
                                ruleChanged: function ($event) {
                                  return _vm.timetableSubscriptionChanged(
                                    $event,
                                    schedule.id
                                  )
                                },
                              },
                            }),
                            _c("GeneralNotificationComponent", {
                              attrs: {
                                rule: _vm.getRuleForTimetable(
                                  _vm.NotificationSubscriptionType
                                    .AUTOMATIC_REPORTS_GENERATED,
                                  schedule.id
                                ),
                                type: _vm.getType(
                                  _vm.NotificationSubscriptionType
                                    .AUTOMATIC_REPORTS_GENERATED
                                ),
                                currentUser: _vm.currentUser,
                                advancedAllowed: false,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                scheduleId: _vm.selectedScheduleId,
                              },
                              on: {
                                ruleChanged: function ($event) {
                                  return _vm.timetableSubscriptionChanged(
                                    $event,
                                    schedule.id
                                  )
                                },
                              },
                            }),
                            _c("GeneralNotificationComponent", {
                              attrs: {
                                rule: _vm.getRuleForTimetable(
                                  _vm.NotificationSubscriptionType
                                    .RECORDS_UPDATED,
                                  schedule.id
                                ),
                                type: _vm.getType(
                                  _vm.NotificationSubscriptionType
                                    .RECORDS_UPDATED
                                ),
                                currentUser: _vm.currentUser,
                                advancedAllowed: false,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                scheduleId: _vm.selectedScheduleId,
                              },
                              on: {
                                ruleChanged: function ($event) {
                                  return _vm.timetableSubscriptionChanged(
                                    $event,
                                    schedule.id
                                  )
                                },
                              },
                            }),
                            _c("GeneralNotificationComponent", {
                              attrs: {
                                rule: _vm.getRuleForTimetable(
                                  _vm.NotificationSubscriptionType.LOGGER_STATE,
                                  schedule.id
                                ),
                                type: _vm.getType(
                                  _vm.NotificationSubscriptionType.LOGGER_STATE
                                ),
                                currentUser: _vm.currentUser,
                                advancedAllowed: false,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                scheduleId: _vm.selectedScheduleId,
                              },
                              on: {
                                ruleChanged: function ($event) {
                                  return _vm.timetableSubscriptionChanged(
                                    $event,
                                    schedule.id
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                )
              }),
              _vm.timetableSchedules.length <= 8
                ? _c("b-tab-item", {
                    attrs: {
                      value: _vm.NEW_SCHEDULE_IDENTIFIER,
                      label: _vm.$t(
                        "notifications.configuration.add_timetable_label"
                      ),
                      icon: "plus",
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
      _vm.haveChangesToSave
        ? _c(
            "div",
            { staticClass: "fixed-form-footer" },
            [
              _c("span", { staticClass: "pb-2 has-text-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "notifications.configuration.timetable.save_description"
                    )
                  )
                ),
              ]),
              _c(
                "b-button",
                {
                  attrs: { type: "is-success", disabled: _vm.isLoading },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("notifications.configuration.timetable.save"))
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isUnsavedChangesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeUnsavedChangesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isUnsavedChangesModalActive = $event
            },
            "close-modal": _vm.closeUnsavedChangesModal,
          },
        },
        [
          _c("UnsavedChangesDialog", {
            attrs: { routeToNavigate: _vm.routeToNavigateOnExit },
            on: {
              modalClosed: _vm.closeUnsavedChangesModal,
              cancel: _vm.closeUnsavedChangesModal,
              save: _vm.saveUnsavedChanges,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }