var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.location.detail.msg.file_download_info")
                    ) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("admin.component.loggers.history.title")) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: { position: "is-bottom-left", "aria-role": "list" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.company.list.actions.columns"
                                ),
                                position: "is-left",
                                type: "is-dark",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                { staticStyle: { "font-size": "1rem" } },
                                [
                                  _c("b-icon", {
                                    attrs: { type: "is-primary", icon: "list" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: column.field,
                      staticClass: "custom-dropdown-item",
                      attrs: {
                        focusable: false,
                        custom: "",
                        "aria-role": "listitem",
                      },
                    },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            disabled:
                              column.field ==
                              _vm.tableColumns.companyLoggerId.field,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.chooseTableColumns(
                                column.field,
                                $event
                              )
                            },
                          },
                          model: {
                            value: column.visible,
                            callback: function ($$v) {
                              _vm.$set(column, "visible", $$v)
                            },
                            expression: "column.visible",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `admin.component.loggers.history.table.${column.field}`
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "section",
        [
          _c(
            "b-table",
            {
              ref: "table",
              staticClass: "table-wrap",
              attrs: {
                data: _vm.logger.publishedAs,
                striped: "",
                "default-sort-direction": _vm.sortDirection,
                "default-sort": _vm.sortField,
              },
              on: { sort: _vm.onSort },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("section", { staticClass: "section" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "content has-text-grey has-text-centered",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.loggers.history.table.empty"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.companyLoggerId.field,
                  label: _vm.$t(
                    `admin.component.loggers.history.table.${_vm.tableColumns.companyLoggerId.field}`
                  ),
                  visible: _vm.tableColumns.companyLoggerId.visible,
                  sortable: _vm.tableColumns.companyLoggerId.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (publishedAs) {
                      return [
                        _c("span", { staticClass: "is-family-monospace" }, [
                          _vm._v(_vm._s(publishedAs.row.companyLoggerId)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.serialNumber.field,
                  label: _vm.$t(
                    `admin.component.loggers.history.table.${_vm.tableColumns.serialNumber.field}`
                  ),
                  visible: _vm.tableColumns.serialNumber.visible,
                  sortable: _vm.tableColumns.serialNumber.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (publishedAs) {
                      return [
                        _vm._v(
                          " " + _vm._s(publishedAs.row.serialNumber) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.lastSeen.field,
                  label: _vm.$t(
                    `admin.component.loggers.history.table.${_vm.tableColumns.lastSeen.field}`
                  ),
                  visible: _vm.tableColumns.lastSeen.visible,
                  sortable: _vm.tableColumns.lastSeen.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (publishedAs) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(publishedAs.row.lastSeen * 1000),
                              null,
                              null,
                              null
                            )
                          ) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "company.companyName",
                  label: _vm.$t(
                    `admin.component.loggers.history.table.${_vm.tableColumns.company.field}`
                  ),
                  visible: _vm.tableColumns.company.visible,
                  sortable: _vm.tableColumns.company.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (publishedAs) {
                      return [
                        publishedAs.row.company
                          ? [
                              _vm.hasPermissionToViewDepartment(
                                publishedAs.row.company.companyId
                              )
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "adminCompanyDetail",
                                          params: {
                                            departmentId:
                                              publishedAs.row.company.companyId,
                                            title: _vm.$t(
                                              "admin.component.devices.deviceList.title"
                                            ),
                                            lang: _vm.$route.params.lang,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          publishedAs.row.company.companyName
                                        )
                                      ),
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        publishedAs.row.company.companyName
                                      )
                                    ),
                                  ]),
                            ]
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.active.field,
                  label: _vm.$t(
                    `admin.component.loggers.history.table.${_vm.tableColumns.active.field}`
                  ),
                  visible: _vm.tableColumns.active.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (publishedAs) {
                      return [
                        _c("b-icon", {
                          attrs: {
                            icon: publishedAs.row.active ? "check" : "times",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.calibration.field,
                  label: _vm.$t(
                    `admin.component.loggers.history.table.${_vm.tableColumns.calibration.field}`
                  ),
                  visible: _vm.tableColumns.calibration.visible,
                  sortable: _vm.tableColumns.calibration.visible,
                  width: "10%",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (publishedAs) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "is-flex is-align-items-center ml-3-mobile",
                          },
                          [
                            publishedAs.row.calibration
                              ? _c(
                                  "b-tag",
                                  {
                                    attrs: {
                                      rounded: "",
                                      type: publishedAs.row.calibration.status
                                        .type,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          `calibration_status.${publishedAs.row.calibration.status.name}`
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _c(
                                  "b-tag",
                                  {
                                    attrs: {
                                      type: _vm.CalibrationStatus.UNKNOWN.type,
                                      rounded: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          `calibration_status.${_vm.CalibrationStatus.UNKNOWN.name}`
                                        )
                                      )
                                    ),
                                  ]
                                ),
                            _vm.hasCalibrationProtocolToDownload(
                              publishedAs.row
                            ) &&
                            _vm.$ability.can(
                              _vm.$permActions.PROTOCOL,
                              _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
                            )
                              ? [
                                  _c(
                                    "b-tooltip",
                                    {
                                      staticClass: "is-hidden-mobile",
                                      attrs: {
                                        label: _vm.$t("generals.download"),
                                        position: "is-left",
                                        type: "is-light",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "control-icon clickable",
                                        attrs: { icon: "file-download" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.downloadCalibration(
                                              publishedAs.row.companyLoggerId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "is-hidden-tablet" },
                                    [
                                      _c("b-icon", {
                                        staticClass: "control-icon clickable",
                                        attrs: { icon: "file-download" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.downloadCalibration(
                                              publishedAs.row.companyLoggerId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.canCopyCalibration(publishedAs.row) &&
                            _vm.$ability.can(
                              _vm.$permActions.EDIT,
                              _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
                            )
                              ? _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "admin.component.loggers.history.table.copy"
                                      ),
                                      position: "is-left",
                                      type: "is-light",
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "control-icon clickable",
                                      attrs: { icon: "copy" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.copyCalibrationDialog(
                                            publishedAs.row.companyLoggerId
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }