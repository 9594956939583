var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "section-header-level level is-flex-wrap-wrap" }, [
      _c("div", { staticClass: "level-left mb-4" }, [
        _vm.hasDataForTimerange
          ? _c(
              "div",
              { staticClass: "chartData" },
              [
                _vm._l(_vm.chartServices, function (item) {
                  return [
                    _c(
                      "a",
                      {
                        key: item,
                        staticClass: "p-2 chartServices",
                        class: [
                          { "is-active": item == _vm.selectedChartService },
                          _vm.chartId,
                        ],
                        attrs: { id: item, value: item },
                        on: {
                          click: function ($event) {
                            return _vm.selectChartServices(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t(`services.${item}`)))]
                    ),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass:
            "is-flex is-flex-wrap-wrap is-justify-content-flex-end-tablet is-justify-content-flex-start-mobile is-align-items-center",
        },
        [
          _vm.isHasBoundaries && _vm.hasDataForTimerange
            ? _c(
                "div",
                { staticClass: "field mr-4" },
                [
                  _c(
                    "b-switch",
                    {
                      staticClass: "chartScale",
                      class: _vm.chartId,
                      on: { input: _vm.changeChartServices },
                      model: {
                        value: _vm.isScaleByData,
                        callback: function ($$v) {
                          _vm.isScaleByData = $$v
                        },
                        expression: "isScaleByData",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("component.report.scaleByData")) + " "
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            type: "is-dark",
                            label: _vm.$t("component.report.scaleByDataNote"),
                            position: "is-left",
                            multilined: "",
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              size: "is-small",
                              icon: "question-circle",
                              type: "is-grey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-field",
            { staticClass: "pr-3" },
            [
              _c(
                "b-radio-button",
                {
                  attrs: {
                    "native-value": _vm.DaysBeforeOptions.ONE,
                    type: "is-primary is-outlined",
                    disabled: _vm.componentIsLoading,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.dayOptionChanged()
                    },
                  },
                  model: {
                    value: _vm.selectedDayOption,
                    callback: function ($$v) {
                      _vm.selectedDayOption = $$v
                    },
                    expression: "selectedDayOption",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("component.group_dashboard.day_options.one")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-radio-button",
                {
                  attrs: {
                    "native-value": _vm.DaysBeforeOptions.THREE,
                    type: "is-primary is-outlined",
                    disabled: _vm.componentIsLoading,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.dayOptionChanged()
                    },
                  },
                  model: {
                    value: _vm.selectedDayOption,
                    callback: function ($$v) {
                      _vm.selectedDayOption = $$v
                    },
                    expression: "selectedDayOption",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("component.group_dashboard.day_options.three")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-radio-button",
                {
                  attrs: {
                    "native-value": _vm.DaysBeforeOptions.SEVEN,
                    type: "is-primary is-outlined",
                    disabled: _vm.componentIsLoading,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.dayOptionChanged()
                    },
                  },
                  model: {
                    value: _vm.selectedDayOption,
                    callback: function ($$v) {
                      _vm.selectedDayOption = $$v
                    },
                    expression: "selectedDayOption",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("component.group_dashboard.day_options.seven")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "is-flex" },
            [
              _vm.hasDataForTimerange && !_vm.componentIsLoading
                ? _c("div", { staticClass: "chart-controls mb-3 mr-2" }, [
                    _c(
                      "div",
                      { staticClass: "buttons" },
                      [
                        _c("b-button", {
                          staticClass: "zoom_btn",
                          class: _vm.chartId,
                          attrs: {
                            type: "is-primary",
                            "icon-right": "plus",
                            id: "zoom_in",
                          },
                        }),
                        _c("b-button", {
                          staticClass: "zoom_btn",
                          class: _vm.chartId,
                          attrs: {
                            type: "is-primary",
                            "icon-right": "minus",
                            id: "zoom_out",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.hasDataForTimerange && !_vm.componentIsLoading
                ? _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "append-to-body": "",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "trigger",
                            fn: function () {
                              return [
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "component.group_dashboard.chart_options.chart_height"
                                      ),
                                      position: "is-left",
                                      type: "is-dark",
                                    },
                                  },
                                  [
                                    _c("b-button", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        type: "is-primary",
                                        "icon-right": "arrows-alt-v",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1419541444
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-field",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                label: _vm.$t(
                                  "component.group_dashboard.chart_options.chart_height"
                                ),
                              },
                            },
                            [
                              _c(
                                "b-radio-button",
                                {
                                  attrs: {
                                    "native-value": _vm.ChartHeight.SMALL,
                                    type: "is-primary is-outlined",
                                    size: "is-small",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.changeChartHeight()
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedChartHeight,
                                    callback: function ($$v) {
                                      _vm.selectedChartHeight = $$v
                                    },
                                    expression: "selectedChartHeight",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "compress-arrows-alt" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          `component.group_dashboard.chart_options.${_vm.ChartHeight.SMALL}`
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "b-radio-button",
                                {
                                  attrs: {
                                    "native-value": _vm.ChartHeight.LARGE,
                                    type: "is-primary is-outlined",
                                    size: "is-small",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.changeChartHeight()
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedChartHeight,
                                    callback: function ($$v) {
                                      _vm.selectedChartHeight = $$v
                                    },
                                    expression: "selectedChartHeight",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "expand-arrows-alt" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          `component.group_dashboard.chart_options.${_vm.ChartHeight.LARGE}`
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "b-radio-button",
                                {
                                  attrs: {
                                    "native-value": _vm.ChartHeight.FIT,
                                    type: "is-primary is-outlined",
                                    size: "is-small",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.changeChartHeight()
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedChartHeight,
                                    callback: function ($$v) {
                                      _vm.selectedChartHeight = $$v
                                    },
                                    expression: "selectedChartHeight",
                                  },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "expand" } }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          `component.group_dashboard.chart_options.${_vm.ChartHeight.FIT}`
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasDataForTimerange && !_vm.componentIsLoading
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "more-chart-filter",
                      attrs: {
                        position: "is-bottom-left",
                        "append-to-body": "",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "trigger",
                            fn: function () {
                              return [
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "component.group_dashboard.filters.more_filters"
                                      ),
                                      position: "is-left",
                                      type: "is-dark",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mb-2",
                                        staticStyle: { "font-size": "1rem" },
                                        attrs: { id: "more-chart-filter" },
                                        on: { click: _vm.changeApplyButton },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            type: "is-primary",
                                            icon: "filter",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2889971883
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item has-text-grey",
                          attrs: { custom: "", "aria-role": "menuitem" },
                        },
                        [
                          _c("b-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "filter" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.group_dashboard.filters.more_filters"
                                )
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c("hr", { staticClass: "dropdown-divider" }),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-field",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                label: _vm.$t(
                                  "component.group_dashboard.filters.loggers"
                                ),
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "logger-checkboxes" },
                                [
                                  _c("div", [
                                    _c("input", {
                                      staticClass:
                                        "mb-2 logger-checkbox allCheckboxes",
                                      class: _vm.chartId,
                                      attrs: { checked: "", type: "checkbox" },
                                      domProps: {
                                        value: _vm.$t(
                                          "component.group_dashboard.filters.allCheckboxes"
                                        ),
                                      },
                                      on: { input: _vm.changeApplyButton },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.group_dashboard.filters.allCheckboxes"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.availableChartLogger,
                                    function (option) {
                                      return _c("div", { key: option.name }, [
                                        _c("div", [
                                          _c("input", {
                                            staticClass: "mb-2 logger-checkbox",
                                            class: [
                                              "checkbox-" +
                                                _vm.Chart.normalizeSelectorName(
                                                  option.name
                                                ),
                                              _vm.chartId,
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              checked: "",
                                              id:
                                                "checkbox-" +
                                                _vm.Chart.normalizeSelectorName(
                                                  option.name
                                                ),
                                            },
                                            domProps: {
                                              value:
                                                "checkbox-" +
                                                _vm.Chart.normalizeSelectorName(
                                                  option.name
                                                ),
                                            },
                                            on: {
                                              input: _vm.changeApplyButton,
                                            },
                                          }),
                                          _vm._v(
                                            " " + _vm._s(option.name) + " "
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "mt-2" }, [
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "applyLoggerFilter",
                                    class: _vm.chartId,
                                    attrs: {
                                      expanded: "",
                                      size: "is-small",
                                      checked: "",
                                      type: _vm.isLoggersFilterApplied
                                        ? "is-success"
                                        : "is-primary",
                                      "icon-left": "check",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.applyLoggersFilter()
                                      },
                                    },
                                  },
                                  [
                                    _vm.isLoggersFilterApplied
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "component.group_dashboard.filters.applied"
                                              )
                                            )
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "component.group_dashboard.filters.apply"
                                              )
                                            )
                                          ),
                                        ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm.componentIsLoading
      ? _c(
          "div",
          [_c("b-skeleton", { attrs: { height: "90px", animated: true } })],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.componentIsLoading,
            expression: "!componentIsLoading",
          },
        ],
      },
      [
        _c("section", [
          !_vm.hasDataForTimerange
            ? _c("div", { staticClass: "empty-chart-data" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("component.report.no_data")))]),
              ])
            : _vm._e(),
          _c("div", { attrs: { id: _vm.chartId, width: "100%" } }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }