import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import Languages from '@/entities/enums/languages';
import UserRepository from '@/services/repository/UserRepository';
import ApiUserRoles from '@/entities/enums/UserRoles';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import InvitationStates from '@/entities/enums/InvitationStates';
var departmentRepository;
var userSettingsRepository;
var userRepository;
var notificationSubscriptionRepository;
let ResendInvitationModal = class ResendInvitationModal extends Vue {
    constructor() {
        super(...arguments);
        this.Languages = Languages;
        this.isLoading = false;
        this.selectedLang = Languages.ENGLISH;
        this.inviteMessage = '';
        this.availableRoles = [];
        this.availablePresets = [];
        this.email = '';
        this.role = null;
        this.preset = null;
        this.emailIndex = null;
        this.currentUser = null;
        this.InvitationStates = InvitationStates;
    }
    async created() {
        departmentRepository = new DepartmentRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        userRepository = new UserRepository(this);
        notificationSubscriptionRepository = new NotificationSubscriptionRepository(this);
        this.isLoading = true;
        this.currentUser = await userRepository.getCurrentUser();
        this.settings = await userSettingsRepository.loadUserSettings();
        this.availableRoles = (await userRepository.getUserRoles()).filter((x) => x.Key !== ApiUserRoles.COMPANY_OWNER);
        this.availablePresets = await notificationSubscriptionRepository.getNotificationPresets(this.currentUser.apiUserId);
        this.email = this.invitation.invitedEmail;
        this.selectedLang = this.invitation && this.invitation.locale ? this.invitation.locale : this.settings.language;
        this.role = this.availableRoles.find((role) => role.Key == this.invitation.role);
        this.preset = this.availablePresets.find((preset) => preset.id == this.invitation.notificationPreset);
        if (this.invitation.text)
            this.inviteMessage = this.invitation.text;
        this.isLoading = false;
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    selectLang(event) {
        this.selectedLang = event;
    }
    prefillMessage() {
        if (!this.inviteMessage)
            this.inviteMessage = this.$t('component.userAdministration.list.invite.form.write').toString();
    }
    validateBeforeSubmit() {
        this.$validator.validateAll().then((result) => {
            if (result) {
                this.submitInvitations();
            }
        });
    }
    async submitInvitations() {
        this.isLoading = true;
        let invitedUsers = {
            Email: this.email,
            NotificationPreset: this.preset
                ? this.preset.id
                : this.invitation.notificationPreset
                    ? this.invitation.notificationPreset
                    : '',
            Role: this.role.Key
        };
        let config = {
            Users: [invitedUsers],
            Text: this.inviteMessage,
            Locale: this.selectedLang
        };
        let response = await departmentRepository.inviteUsers(config);
        if (response) {
            if (this.invitation.state === InvitationStates.PENDING)
                await departmentRepository.cancelSentInvitation(this.invitation.id);
            VueUtilities.openSuccessToast(this, this.$t('component.userAdministration.list.invite.success'));
            this.resultRetrieved(true);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.userAdministration.list.invite.failure'));
        }
        this.isLoading = false;
    }
};
__decorate([
    Prop({ type: Object })
], ResendInvitationModal.prototype, "invitation", void 0);
ResendInvitationModal = __decorate([
    Component({ components: {} })
], ResendInvitationModal);
export default ResendInvitationModal;
