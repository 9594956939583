var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.scanningMethod === "scanner"
    ? _c(
        "div",
        { staticClass: "qr-scanner modal-card p-0" },
        [
          _c("QRCodeScanner", { on: { parseData: _vm.parseData } }),
          _c(
            "section",
            { staticClass: "modal-card-body has-background-white" },
            [
              _c(
                "b-table",
                {
                  attrs: { data: _vm.scannedLoggers, "mobile-cards": false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("section", { staticClass: "section" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "content has-text-grey has-text-centered",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.company.detail.registerLoggers.noLoggers"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    267329525
                  ),
                },
                [
                  _c("b-table-column", {
                    attrs: {
                      field: "sn",
                      label: _vm.$t("loggers.serial_number"),
                      width: "80%",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [_vm._v(" " + _vm._s(props.row.sn) + " ")]
                          },
                        },
                      ],
                      null,
                      false,
                      3572047232
                    ),
                  }),
                  _c("b-table-column", {
                    attrs: { label: _vm.$t("generals.action") },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c("b-icon", {
                                staticClass: "clickable",
                                attrs: { icon: "trash" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.removeFromList(props.row.sn)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4053775251
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "footer",
            {
              staticClass: "modal-card-foot",
              staticStyle: { "justify-content": "space-between" },
            },
            [
              _c(
                "b-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.setScanMethod(null)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.close")))]
              ),
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.setScanMethod(null)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.company.addCompanyUsersModal.confirm"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.scanningMethod === "fromFile"
    ? _c("QRCodeFromFile", {
        on: { parseData: _vm.parseData, setScanMethod: _vm.setScanMethod },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }