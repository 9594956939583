import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceInstanceState } from '@/entities/enums/DeviceInstanceState';
import { DeviceState } from '@/entities/enums/DeviceState';
import debounce from 'lodash.debounce';
const DEBOUNCE_TIMER = 500;
let DevicesSelectionComponent = class DevicesSelectionComponent extends Vue {
    constructor() {
        super(...arguments);
        this.DeviceInstanceState = DeviceInstanceState;
        this.DeviceState = DeviceState;
        this.selectedDevices = [];
        this.page = 1;
        this.pageSize = 6;
        this.serialNameSearch = '';
        this.currentFilter = [];
        this.handleFilterSerialNameDebounce = debounce((e) => {
            this.filterDevices(e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    async created() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        if (this.devices.length == 0)
            this.$emit('loadDevices', this.pagination, this.currentFilter);
    }
    onPageChange(page) {
        this.page = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination);
    }
    onOptionsChange(pagination) {
        this.$emit('loadDevices', pagination, this.currentFilter);
    }
    filterDevices(event) {
        if (typeof event === 'string') {
            event = event.includes(',') ? event.replace(/,/g, '\\,') : event;
        }
        let index = this.filterExists('SerialName@=');
        if (index !== -1)
            this.currentFilter[index] = 'SerialName@=' + event;
        else
            this.currentFilter.push('SerialName@=' + event);
        this.onOptionsChange(this.pagination);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    selectDevices(selectedDevices) {
        this.selectedDevices = selectedDevices;
        this.$emit('backUpSelectedDevices', this.selectedDevices);
    }
};
__decorate([
    Prop({ type: Array })
], DevicesSelectionComponent.prototype, "devices", void 0);
__decorate([
    Prop({ type: Number })
], DevicesSelectionComponent.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Boolean })
], DevicesSelectionComponent.prototype, "componentIsLoading", void 0);
DevicesSelectionComponent = __decorate([
    Component({
        components: {}
    })
], DevicesSelectionComponent);
export default DevicesSelectionComponent;
