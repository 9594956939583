var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        [
          _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "multi-header is-flex is-justify-content-space-between mx-3 mx-0-desktop mb-4 mb-1-touch",
              },
              [
                _c(
                  "nav",
                  {
                    staticClass: "breadcrumb my-1",
                    attrs: { "aria-label": "breadcrumb" },
                  },
                  [
                    _c("h1", { staticClass: "title has-text-centered-touch" }, [
                      _c("ul", [
                        _c("li", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$router.go(-1)
                                },
                              },
                            },
                            [
                              _vm._m(0),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generals.back"))),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "adminResellers",
                                    params: {
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "box-open" } }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("admin.sidebar.resellers"))
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("li", { staticClass: "is-active" }, [
                          _c(
                            "a",
                            { attrs: { href: "", "aria-current": "page" } },
                            [_vm._v(_vm._s(_vm.reseller.name))]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "is-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "mr-3" },
                      [
                        !_vm.isLayoutList
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.layout.listView"
                                  ),
                                  position: "is-left",
                                  type: "is-dark",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticStyle: { "font-size": "1rem" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLayout()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        type: "is-primary",
                                        icon: "stream",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isLayoutList
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.layout.tabView"
                                  ),
                                  position: "is-left",
                                  type: "is-dark",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticStyle: { "font-size": "1rem" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLayout()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        type: "is-primary",
                                        icon: "layer-group fa-rotate-270",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "append-to-body": "",
                          "aria-role": "list",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "trigger",
                            fn: function () {
                              return [
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "admin.component.devices.detail.actions.title"
                                      ),
                                      position: "is-left",
                                      type: "is-dark",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      { staticStyle: { "font-size": "1rem" } },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            type: "is-primary",
                                            icon: "ellipsis-v",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        !_vm.isEditing
                          ? _c(
                              "b-dropdown-item",
                              {
                                staticClass: "custom-dropdown-item",
                                attrs: { "aria-role": "listitem" },
                                on: { click: _vm.editReseller },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "pencil-alt" } }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.reseller.detail.resellerInfo.editReseller"
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _c(
                              "b-dropdown-item",
                              {
                                staticClass: "custom-dropdown-item",
                                attrs: { "aria-role": "listitem" },
                                on: { click: _vm.editReseller },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "times" } }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.reseller.detail.resellerInfo.cancelEdit"
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteReseller(_vm.reseller)
                              },
                            },
                          },
                          [
                            _c("b-icon", { attrs: { icon: "trash" } }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.reseller.detail.resellerInfo.deleteReseller"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: { click: _vm.openAddUsersModal },
                          },
                          [
                            _c("b-icon", { attrs: { icon: "user-plus" } }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.reseller.detail.resellerUsers.addUsers"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: { click: _vm.openAddDepartmentsModal },
                          },
                          [
                            _c("b-icon", { attrs: { icon: "warehouse" } }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.reseller.detail.resellerDepartments.addDepartments"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: { click: _vm.openAddDevicesModal },
                          },
                          [
                            _c("b-icon", { attrs: { icon: "hdd" } }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.reseller.detail.resellerDevices.addDevices"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: { click: _vm.openAddLoggersModal },
                          },
                          [
                            _c("b-icon", { attrs: { icon: "microchip" } }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.reseller.detail.resellerLoggers.addLoggers"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            !_vm.isLayoutList
              ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
                  _c("div", { staticClass: "tile is-parent" }, [
                    _c(
                      "div",
                      { staticClass: "box tile is-child" },
                      [
                        _c(
                          "b-tabs",
                          {
                            staticClass: "custom-layout-tabs",
                            attrs: { type: "is-boxed" },
                            on: {
                              input: function ($event) {
                                return _vm.changedActiveTab($event)
                              },
                            },
                            model: {
                              value: _vm.activeTab,
                              callback: function ($$v) {
                                _vm.activeTab = $$v
                              },
                              expression: "activeTab",
                            },
                          },
                          [
                            _c(
                              "b-tab-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.reseller.detail.resellerInfo.title"
                                  ),
                                },
                              },
                              [
                                !_vm.isLoading
                                  ? _c("AdminResellerInfoComponent", {
                                      attrs: {
                                        isLayoutList: _vm.isLayoutList,
                                        isEditing: _vm.isEditing,
                                        reseller: _vm.reseller,
                                      },
                                      on: {
                                        editReseller: _vm.editReseller,
                                        updateReseller: _vm.updateReseller,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-tab-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.reseller.detail.resellerUsers.title"
                                  ),
                                  disabled: _vm.isLoading,
                                },
                              },
                              [
                                _c("AdminResellerUsersComponent", {
                                  attrs: {
                                    isLayoutList: _vm.isLayoutList,
                                    users: _vm.members,
                                    reseller: _vm.reseller,
                                    dateTimeManager: _vm.dateTimeManager,
                                  },
                                  on: { unassignUser: _vm.unassignUser },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-tab-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.reseller.detail.resellerDepartments.title"
                                  ),
                                  disabled: _vm.isLoading,
                                },
                              },
                              [
                                _c("AdminResellerDepartmentsComponent", {
                                  attrs: {
                                    isLayoutList: _vm.isLayoutList,
                                    departments: _vm.departments,
                                    totalSize: _vm.departments.length,
                                    reseller: _vm.reseller,
                                    departmentsLoaded: _vm.departmentsLoaded,
                                    dateTimeManager: _vm.dateTimeManager,
                                  },
                                  on: {
                                    unassignDepartment: _vm.unassignDepartment,
                                    loadResellerDepartments:
                                      _vm.loadResellerDepartments,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-tab-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.reseller.detail.resellerDevices.title"
                                  ),
                                  disabled: _vm.isLoading,
                                },
                              },
                              [
                                _c("AdminResellerDevicesComponent", {
                                  attrs: {
                                    isLayoutList: _vm.isLayoutList,
                                    devices: _vm.devices.getData() || [],
                                    totalSize:
                                      _vm.devices.getPagination().total,
                                    reseller: _vm.reseller,
                                    devicesLoaded: _vm.devicesLoaded,
                                    dateTimeManager: _vm.dateTimeManager,
                                  },
                                  on: {
                                    unassignDevice: _vm.unassignDevice,
                                    resellerDevices: _vm.loadResellerGateways,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-tab-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.reseller.detail.resellerLoggers.title"
                                  ),
                                  disabled: _vm.isLoading,
                                },
                              },
                              [
                                _c("AdminResellerLoggersComponent", {
                                  attrs: {
                                    isLayoutList: _vm.isLayoutList,
                                    loggers:
                                      _vm.loggersWithStates.getData().loggers ||
                                      [],
                                    reseller: _vm.reseller,
                                    totalSize: _vm.loggersWithStates.getData()
                                      .loggers
                                      ? _vm.loggersWithStates.getData().loggers
                                          .length
                                      : 0,
                                    dateTimeManager: _vm.dateTimeManager,
                                    loggersLoaded: _vm.loggersLoaded,
                                  },
                                  on: {
                                    unassignLogger: _vm.unassignLogger,
                                    downloadCalibration:
                                      _vm.downloadCalibration,
                                    resellerLoggers: _vm.loadResellerLoggers,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
                  _c(
                    "div",
                    { staticClass: "tile is-parent" },
                    [
                      !_vm.isLoading
                        ? _c("AdminResellerInfoComponent", {
                            staticClass: "box tile is-child",
                            attrs: {
                              reseller: _vm.reseller,
                              isEditing: _vm.isEditing,
                            },
                            on: {
                              editReseller: _vm.editReseller,
                              updateReseller: _vm.updateReseller,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tile is-parent" },
                    [
                      _c("AdminResellerUsersComponent", {
                        staticClass: "box tile is-child",
                        attrs: {
                          users: _vm.members,
                          reseller: _vm.reseller,
                          dateTimeManager: _vm.dateTimeManager,
                        },
                        on: { unassignUser: _vm.unassignUser },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tile is-parent" },
                    [
                      _c("AdminResellerDepartmentsComponent", {
                        staticClass: "box tile is-child",
                        attrs: {
                          departments: _vm.departments,
                          totalSize: _vm.departments.length,
                          reseller: _vm.reseller,
                          departmentsLoaded: _vm.departmentsLoaded,
                          dateTimeManager: _vm.dateTimeManager,
                        },
                        on: {
                          unassignDepartment: _vm.unassignDepartment,
                          loadResellerDepartments: _vm.loadResellerDepartments,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tile is-parent" },
                    [
                      _c("AdminResellerDevicesComponent", {
                        staticClass: "box tile is-child",
                        attrs: {
                          devices: _vm.devices.getData() || [],
                          totalSize: _vm.devices.getPagination().total,
                          reseller: _vm.reseller,
                          dateTimeManager: _vm.dateTimeManager,
                          devicesLoaded: _vm.devicesLoaded,
                        },
                        on: {
                          unassignDevice: _vm.unassignDevice,
                          resellerDevices: _vm.loadResellerGateways,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tile is-parent" },
                    [
                      _c("AdminResellerLoggersComponent", {
                        staticClass: "box tile is-child",
                        attrs: {
                          loggers:
                            _vm.loggersWithStates.getData().loggers || [],
                          reseller: _vm.reseller,
                          totalSize: _vm.loggersWithStates.getData().loggers
                            ? _vm.loggersWithStates.getData().loggers.length
                            : 0,
                          dateTimeManager: _vm.dateTimeManager,
                          loggersLoaded: _vm.loggersLoaded,
                        },
                        on: {
                          unassignLogger: _vm.unassignLogger,
                          downloadCalibration: _vm.downloadCalibration,
                          resellerLoggers: _vm.loadResellerLoggers,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
          ]),
          _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isAddUserModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeAddUsersModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isAddUserModalActive = $event
                },
                "close-modal": _vm.closeAddUsersModal,
              },
            },
            [
              _c("AddResellerUsersModal", {
                attrs: {
                  availableUsers: _vm.userList.getData(),
                  members: _vm.members,
                  totalSize: _vm.userList.getPagination().total,
                },
                on: {
                  loadUsers: _vm.loadUsers,
                  modalClosed: _vm.closeAddUsersModal,
                  addResellerUsers: _vm.addResellerUsers,
                },
              }),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isAddDepartmentModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeAddDepartmentsModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isAddDepartmentModalActive = $event
                },
                "close-modal": _vm.closeAddDepartmentsModal,
              },
            },
            [
              _c("AddDepartmentsModal", {
                attrs: {
                  availableDepartments: _vm.departmentList,
                  departments: _vm.departments,
                },
                on: {
                  loadDepartments: _vm.loadDepartments,
                  modalClosed: _vm.closeAddDepartmentsModal,
                  addDepartments: _vm.addResellerDepartments,
                },
              }),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isAddDeviceModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeAddDevicesModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isAddDeviceModalActive = $event
                },
                "close-modal": _vm.closeAddDevicesModal,
              },
            },
            [
              _c("AddResellerDevicesModal", {
                attrs: {
                  availableDevices: _vm.deviceList,
                  devices: _vm.devices.getData(),
                },
                on: {
                  loadDevices: _vm.loadDevices,
                  modalClosed: _vm.closeAddDevicesModal,
                  addResellerDevices: _vm.addResellerDevices,
                },
              }),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isAddLoggerModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeAddLoggersModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isAddLoggerModalActive = $event
                },
                "close-modal": _vm.closeAddLoggersModal,
              },
            },
            [
              _c("AddResellerLoggersModal", {
                attrs: {
                  availableLoggers: _vm.loggerList.getData(),
                  loggers: _vm.loggersWithStates.getData().loggers,
                },
                on: {
                  loadLoggers: _vm.loadLoggers,
                  modalClosed: _vm.closeAddLoggersModal,
                  addResellerLoggers: _vm.addResellerLoggers,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }