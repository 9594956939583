import { AdminDepartmentSettings } from '../AdminDepartmentSettings';
import User from '../User';
import { DepartmentArray } from './arrayModels/DepartmentArray';
import { Department } from './Department';
export class AdminDepartment {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminDepartment.define(et));
        }
        else if (data != null) {
            return AdminDepartment.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let company = new AdminDepartment();
        company.companyId = data.CompanyId;
        company.companyName = data.CompanyName;
        company.ico = data.Ico;
        company.note = data.Note;
        company.publicKey = data.PublicKey;
        company.createdAt = data.CreatedAt;
        company.owner = User.fromApi(data.Owner);
        company.loggerCount = data.LoggerCount;
        company.deviceCount = data.DeviceCount;
        company.userCount = data.UserCount;
        company.tier = data.Tier;
        company.settings = AdminDepartmentSettings.fromApi(data.Settings);
        if (company.tier) {
            company.tierName = data.Tier.Name + ' (' + data.Tier.Tier + ')';
        }
        company.isDeleted = data.IsDeleted;
        return company;
    }
    static sortByName(companies) {
        return companies.sort(function (a, b) {
            let aId = a ? a.companyName.toLowerCase() : '';
            let bId = b ? b.companyName.toLowerCase() : '';
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
    static convertToUserDepartments(adminDepartments) {
        let userDepartments = [];
        adminDepartments.forEach((adminDepartment) => {
            userDepartments.push(AdminDepartment.convertToUserDepartment(adminDepartment));
        });
        return userDepartments;
    }
    static convertToUserDepartment(adminDepartment) {
        let department = new Department();
        department.id = adminDepartment.companyId;
        department.name = adminDepartment.companyName;
        department.createdAt = adminDepartment.createdAt;
        return department;
    }
    static convertToDepartmentsArray(adminDepartments) {
        let userDepartments = [];
        adminDepartments.forEach((adminDepartment) => {
            let department = new Department();
            department.id = adminDepartment.companyId;
            department.name = adminDepartment.companyName;
            department.createdAt = adminDepartment.createdAt;
            userDepartments.push(department);
        });
        return new DepartmentArray(userDepartments);
    }
}
