var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("div", { staticClass: "title-with-edit" }, [
          _c("h2", { staticClass: "subtitle is-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("admin.component.company.list.title")) + " "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item is-hidden-mobile",
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.clearFilter()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
            ),
            _c(
              "div",
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: { position: "is-bottom-left", "aria-role": "list" },
                    scopedSlots: _vm._u([
                      {
                        key: "trigger",
                        fn: function () {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.company.list.actions.columns"
                                  ),
                                  position: "is-left",
                                  type: "is-dark",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  { staticStyle: { "font-size": "1rem" } },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        type: "is-primary",
                                        icon: "list",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  _vm._l(_vm.tableColumns, function (column) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: column.field,
                        staticClass: "custom-dropdown-item",
                        attrs: {
                          focusable: false,
                          custom: "",
                          "aria-role": "listitem",
                        },
                      },
                      [
                        _c(
                          "b-checkbox",
                          {
                            attrs: {
                              disabled:
                                column.field ==
                                _vm.tableColumns.companyName.field,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.chooseTableColumns(
                                  column.field,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: column.visible,
                              callback: function ($$v) {
                                _vm.$set(column, "visible", $$v)
                              },
                              expression: "column.visible",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    `admin.component.company.list.table.${column.field}`
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "mobile-filter res-table",
          attrs: {
            data: _vm.companies,
            paginated: "",
            "current-page": _vm.page,
            "per-page": _vm.pageSize,
            total: _vm.totalSize,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            striped: "",
            "page-input": true,
            "pagination-order": "is-centered",
            "pagination-position": _vm.isMobileView ? "bottom" : "both",
            "backend-pagination": "",
            "backend-sorting": "",
            "backend-filtering": "",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "page-change": _vm.onPageChange,
            sort: _vm.onSort,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "top-left",
                fn: function () {
                  return [
                    _c("p", { staticClass: "is-hidden-mobile" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 is-hidden-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "empty",
                fn: function () {
                  return [
                    _c("section", { staticClass: "section" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "content has-text-grey has-text-centered",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.company.list.table.empty"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.companies.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.totalSize,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.companyId.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.companyId.field}`
              ),
              visible: _vm.tableColumns.companyId.visible,
              sortable: _vm.tableColumns.companyId.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isIdSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.company.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              {
                                attrs: {
                                  expanded: "",
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        `admin.component.company.list.table.${_vm.tableColumns.companyId.field}`
                                      )
                                    ),
                                  },
                                  message: _vm.errors.first(
                                    _vm.$t(
                                      `admin.component.company.list.table.${_vm.tableColumns.companyId.field}`
                                    )
                                  ),
                                },
                              },
                              [
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "min:36",
                                      expression: "'min:36'",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: { input: _vm.handleFilterIdDebounce },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterDepartments(
                                        _vm.tableColumns.companyId.field,
                                        _vm.idSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.idSearch,
                                    callback: function ($$v) {
                                      _vm.idSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "idSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile is-full-width",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "b-field",
                          {
                            attrs: {
                              expanded: "",
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(
                                    `admin.component.company.list.table.${_vm.tableColumns.companyId.field}`
                                  )
                                ),
                              },
                              message: _vm.errors.first(
                                _vm.$t(
                                  `admin.component.company.list.table.${_vm.tableColumns.companyId.field}`
                                )
                              ),
                            },
                          },
                          [
                            _c("b-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:36",
                                  expression: "'min:36'",
                                },
                              ],
                              attrs: {
                                type: "text",
                                name: _vm.$t(
                                  `admin.component.company.list.table.${_vm.tableColumns.companyId.field}`
                                ),
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: { input: _vm.handleFilterIdDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterDepartments(
                                    _vm.tableColumns.companyId.field,
                                    _vm.idSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.idSearch,
                                callback: function ($$v) {
                                  _vm.idSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "idSearch",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (companies) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "is-family-monospace",
                        attrs: {
                          to: {
                            name: "adminCompanyDetail",
                            params: {
                              departmentId: companies.row.companyId,
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                        nativeOn: {
                          contextmenu: function ($event) {
                            return _vm.copyLinkToClipboard(
                              companies.row.companyId
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(companies.row.companyId))]
                    ),
                    _vm.hasCopyIconAllowed
                      ? _c("b-icon", {
                          staticClass: "clickable is-size-7 is-hidden-touch",
                          attrs: { icon: "clipboard" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.copyLinkToClipboard(
                                companies.row.companyId
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.companyName.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.companyName.field}`
              ),
              visible: _vm.tableColumns.companyName.visible,
              sortable: _vm.tableColumns.companyName.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isNameSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.company.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-input", {
                              attrs: {
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: {
                                input: _vm.handleFilterCompanyNameDebounce,
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterDepartments(
                                    _vm.tableColumns.companyName.field,
                                    _vm.companyNameSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.companyNameSearch,
                                callback: function ($$v) {
                                  _vm.companyNameSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "companyNameSearch",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterCompanyNameDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterDepartments(
                                _vm.tableColumns.companyName.field,
                                _vm.companyNameSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.companyNameSearch,
                            callback: function ($$v) {
                              _vm.companyNameSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "companyNameSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (companies) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "adminCompanyDetail",
                            params: {
                              departmentId: companies.row.companyId,
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [_vm._v(_vm._s(companies.row.companyName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.companyStatus.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.companyStatus.field}`
              ),
              visible: _vm.tableColumns.companyStatus.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isStatusSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.company.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.filterDepartments(
                                      _vm.tableColumns.companyStatus.field,
                                      _vm.companyStatusSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.companyStatusSearch,
                                  callback: function ($$v) {
                                    _vm.companyStatusSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "companyStatusSearch",
                                },
                              },
                              _vm._l(_vm.issueTypes, function (issue) {
                                return _c(
                                  "option",
                                  { key: issue, domProps: { value: issue } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.organization_card.tooltip." +
                                              issue
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            attrs: {
                              placeholder: _vm.$t("generals.search"),
                              "icon-pack": "fas",
                              icon: "search",
                              expanded: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.filterDepartments(
                                  _vm.tableColumns.companyStatus.field,
                                  _vm.companyStatusSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.companyStatusSearch,
                              callback: function ($$v) {
                                _vm.companyStatusSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "companyStatusSearch",
                            },
                          },
                          _vm._l(_vm.issueTypes, function (issue) {
                            return _c(
                              "option",
                              { key: issue, domProps: { value: issue } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.organization_card.tooltip." +
                                          issue
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (companies) {
                  return [
                    _c("OrganizationStatus", {
                      attrs: { issuesSummary: companies.row.issuesSummary },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.ico.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.ico.field}`
              ),
              visible: _vm.tableColumns.ico.visible,
              sortable: _vm.tableColumns.ico.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isIcoSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.company.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-input", {
                              attrs: {
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: { input: _vm.handleFilterIcoDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterDepartments(
                                    _vm.tableColumns.ico.field,
                                    _vm.icoSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.icoSearch,
                                callback: function ($$v) {
                                  _vm.icoSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "icoSearch",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterIcoDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterDepartments(
                                _vm.tableColumns.ico.field,
                                _vm.icoSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.icoSearch,
                            callback: function ($$v) {
                              _vm.icoSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "icoSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (companies) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(companies.row.ico ? companies.row.ico : "-") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "Company.ResponsiblePerson.FullName",
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.responsiblePerson.field}`
              ),
              visible: _vm.tableColumns.responsiblePerson.visible,
              sortable: _vm.tableColumns.responsiblePerson.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isResponsiblePersonSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.company.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-input", {
                              attrs: {
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: {
                                input:
                                  _vm.handleFilterResponsiblePersonDebounce,
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterDepartments(
                                    _vm.tableColumns.responsiblePerson.field,
                                    _vm.responsiblePersonSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.responsiblePersonSearch,
                                callback: function ($$v) {
                                  _vm.responsiblePersonSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "responsiblePersonSearch",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: {
                            input: _vm.handleFilterResponsiblePersonDebounce,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterDepartments(
                                _vm.tableColumns.responsiblePerson.field,
                                _vm.responsiblePersonSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.responsiblePersonSearch,
                            callback: function ($$v) {
                              _vm.responsiblePersonSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "responsiblePersonSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (companies) {
                  return [
                    companies.row.responsiblePerson
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminUserDetail",
                                params: {
                                  userId:
                                    companies.row.responsiblePerson.apiUserId,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(companies.row.responsiblePerson.fullName)
                            ),
                          ]
                        )
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "Seller.Name",
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.resellerName.field}`
              ),
              visible: _vm.tableColumns.resellerName.visible,
              sortable: _vm.tableColumns.resellerName.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isResellerNameSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.company.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-input", {
                              attrs: {
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: {
                                input: _vm.handleFilterResellerNameDebounce,
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterDepartments(
                                    _vm.tableColumns.resellerName.field,
                                    _vm.resellerNameSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.resellerNameSearch,
                                callback: function ($$v) {
                                  _vm.resellerNameSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "resellerNameSearch",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterResellerNameDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterDepartments(
                                _vm.tableColumns.resellerName.field,
                                _vm.resellerNameSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.resellerNameSearch,
                            callback: function ($$v) {
                              _vm.resellerNameSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "resellerNameSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (companies) {
                  return [
                    companies.row.seller
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminResellerDetail",
                                params: {
                                  resellerId: companies.row.seller.id,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(companies.row.seller.name))]
                        )
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.tag.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.tag.field}`
              ),
              visible: _vm.tableColumns.tag.visible,
              sortable: _vm.tableColumns.tag.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isTagSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.company.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-input", {
                              attrs: {
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: { input: _vm.handleFilterTagDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterDepartments(
                                    _vm.tableColumns.tag.field,
                                    _vm.tagSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.tagSearch,
                                callback: function ($$v) {
                                  _vm.tagSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "tagSearch",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterTagDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterDepartments(
                                _vm.tableColumns.tag.field,
                                _vm.tagSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.tagSearch,
                            callback: function ($$v) {
                              _vm.tagSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "tagSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (companies) {
                  return [
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "append-to-body": "",
                          "aria-role": "list",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "is-flex is-clickable" },
                                    [
                                      companies.row.tag
                                        ? _c(
                                            "b-tag",
                                            {
                                              attrs: {
                                                type: _vm.getTagType(
                                                  companies.row.tag
                                                ),
                                                rounded: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(companies.row.tag)
                                              ),
                                            ]
                                          )
                                        : _c("span", [_vm._v("-")]),
                                      _c("b-icon", {
                                        staticClass: "ml-1",
                                        attrs: { icon: "pencil-alt" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      _vm._l(_vm.departmentTags, function (option) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: option.CompanyTagId,
                            staticClass: "custom-dropdown-item",
                            class:
                              option.CompanyTagId === -1
                                ? "is-italic has-text-grey"
                                : "",
                            attrs: {
                              value: option.Tag,
                              "aria-role": "listitem",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveTagChanges(
                                  companies.row.companyId,
                                  companies.row.companyName,
                                  companies.row.ico,
                                  option,
                                  companies.row.note,
                                  companies.row.responsiblePerson
                                )
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(option.Tag) + " ")]
                        )
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          !_vm.isResellerAdmin
            ? _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.note.field,
                  label: _vm.$t(
                    `admin.component.company.list.table.${_vm.tableColumns.note.field}`
                  ),
                  visible: _vm.tableColumns.note.visible,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (companies) {
                        return [
                          _c("div", { staticClass: "truncate-long-text" }, [
                            _vm._v(
                              _vm._s(
                                companies.row.note ? companies.row.note : "-"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  157412941
                ),
              })
            : _vm._e(),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.tierName.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.tierName.field}`
              ),
              visible: _vm.tableColumns.tierName.visible,
              sortable: _vm.tableColumns.tierName.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isTierSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.company.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-input", {
                              attrs: {
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: { input: _vm.handleFilterTierNameDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterDepartments(
                                    _vm.tableColumns.tierName.field,
                                    _vm.tierSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.tierSearch,
                                callback: function ($$v) {
                                  _vm.tierSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "tierSearch",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterTierNameDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterDepartments(
                                _vm.tableColumns.tierName.field,
                                _vm.tierSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.tierSearch,
                            callback: function ($$v) {
                              _vm.tierSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "tierSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (companies) {
                  return [
                    companies.row.tierName
                      ? _c(
                          "span",
                          [
                            _vm._v(" " + _vm._s(companies.row.tierName) + " "),
                            _c("b-tag", { attrs: { rounded: "" } }, [
                              _vm._v(_vm._s(companies.row.tier.Name)),
                            ]),
                          ],
                          1
                        )
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.userCount.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.userCount.field}`
              ),
              visible: _vm.tableColumns.userCount.visible,
              sortable: _vm.tableColumns.userCount.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (companies) {
                  return [_vm._v(_vm._s(companies.row.userCount))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.deviceCount.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.deviceCount.field}`
              ),
              visible: _vm.tableColumns.deviceCount.visible,
              sortable: _vm.tableColumns.deviceCount.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (companies) {
                  return [_vm._v(_vm._s(companies.row.deviceCount))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.loggerCount.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.loggerCount.field}`
              ),
              visible: _vm.tableColumns.loggerCount.visible,
              sortable: _vm.tableColumns.loggerCount.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (companies) {
                  return [_vm._v(_vm._s(companies.row.loggerCount))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdAt.field,
              label: _vm.$t(
                `admin.component.company.list.table.${_vm.tableColumns.createdAt.field}`
              ),
              visible: _vm.tableColumns.createdAt.visible,
              sortable: _vm.tableColumns.createdAt.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (companies) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(companies.row.createdAt * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              "cell-class": "actions",
              field: "action",
              label: _vm.$t("admin.component.company.list.table.action"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (companies) {
                  return [
                    _c("div", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.loginIntoCompany(companies.row)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "admin.component.company.list.table.actions.loginIntoCompany"
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }