import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import LoggerRepository from '@/services/repository/LoggerRepository';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import { Logger } from '@/entities/models/Logger';
import AdminLoggerDetail from '@/views/components/admin/loggers/AdminLoggerDetail.vue';
import { CompanyLoggerFullCalibration } from '@/entities/models/CompanyLogger';
import DateTimeManager from '@/services/DateTimeManager';
import VueUtilities from '@/services/VueUtilities';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
var loggerRepository;
var companyLoggerRepository;
let AdminLoggerDetailManagement = class AdminLoggerDetailManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.isAdminLoggerChartLoading = true;
        this.logger = new Logger();
        this.companyLogger = new CompanyLoggerFullCalibration();
        this.dateTimeManager = null;
        this.chartData = {};
        this.selectedFromDate = new Date();
    }
    async created() {
        //TODO: nacitat data pre tento logger, osetrit situaciu ked nebude take id existovat
        loggerRepository = new LoggerRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData(this.$route.params.loggerId);
    }
    async loadData(loggerId) {
        this.isLoading = true;
        //read TS from route
        try {
            let givenTS = this.$route.query.date;
            if (givenTS && !isNaN(givenTS)) {
                let ts = parseInt(givenTS);
                if (!isNaN(ts)) {
                    this.selectedFromDate = new Date(ts);
                }
            }
            else {
                this.selectedFromDate.setDate(new Date().getDate() - 1);
            }
        }
        catch (error) {
            this.selectedFromDate.setDate(new Date().getDate() - 1);
        }
        let allPromises = [];
        allPromises.push(loggerRepository.getAdminLogger(loggerId));
        Promise.all(allPromises).then(async (response) => {
            this.processLoadedData(response[0]);
        });
    }
    async loadChartData(date) {
        let convertedDate = this.dateTimeManager.formatStartDateForReports(date).text;
        return loggerRepository.getAdminLoggerChartData(this.logger.currentlyPublishedAs.companyLoggerId, convertedDate);
    }
    async selectedFromDateChange(date) {
        this.selectedFromDate = date;
        this.isAdminLoggerChartLoading = true;
        if (this.logger.currentlyPublishedAs) {
            this.chartData = await this.loadChartData(this.selectedFromDate);
            this.isAdminLoggerChartLoading = false;
        }
        else {
            this.isAdminLoggerChartLoading = false;
        }
        this.updateDateRouteQuery(date);
        this.isAdminLoggerChartLoading = false;
    }
    async createVerificationKey(verificationKey) {
        let res = await loggerRepository.adminAddLoggerVerificationKey(this.logger.id, verificationKey);
        if (res === true) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.createVerificationKeyModal.success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.createVerificationKeyModal.failure'));
        }
    }
    async deleteVerificationKey() {
        let res = await loggerRepository.adminDeleteLoggerVerificationKey(this.logger.id);
        if (res === true) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.deleteVerificationKeyModal.success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.deleteVerificationKeyModal.failure'));
        }
    }
    updateDateRouteQuery(date) {
        let timestamp = date.getTime();
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, { date: timestamp }),
            params: this.$route.params
        })
            .catch((err) => err);
    }
    async processLoadedData(logger) {
        this.logger = logger;
        if (this.logger.currentlyPublishedAs)
            this.companyLogger = await companyLoggerRepository.adminGetCompanyLogger(this.logger.currentlyPublishedAs.companyLoggerId);
        this.isLoading = false;
        if (this.logger.currentlyPublishedAs) {
            this.isAdminLoggerChartLoading = true;
            this.chartData = await this.loadChartData(this.selectedFromDate);
            this.isAdminLoggerChartLoading = false;
        }
        else {
            this.isAdminLoggerChartLoading = false;
        }
    }
    async reloadLogger() {
        this.loadData(this.logger.id.toString());
    }
    async revertDevice() {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        await companyLoggerRepository.adminRevertCompanyLogger(this.logger.currentlyPublishedAs.companyLoggerId);
        this.loadData(this.logger.id.toString());
    }
    async returnFromDepartment() {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        await companyLoggerRepository.adminReturnLoggerFromDepartment(this.logger.currentlyPublishedAs.companyLoggerId);
        this.loadData(this.logger.id.toString());
    }
    async readData(device) {
        let params = { DeviceId: device.deviceId };
        let res = await companyLoggerRepository.adminReadLoggerData(this.logger.currentlyPublishedAs.companyLoggerId, params);
        if (res === true) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.detail.info.readData.success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.detail.info.readData.failure'));
        }
    }
};
AdminLoggerDetailManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_COMPANYLOGGERS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_LOGGERS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            localStorage.setItem(LocalStorageKeys.ADMIN_PREV_ROUTE, JSON.stringify({
                name: this.$route.name,
                params: this.$route.params,
                meta: { name: this.logger?.currentlyPublishedAs?.serialNumber }
            }));
            next();
        },
        components: { AdminLoggerDetail }
    })
], AdminLoggerDetailManagement);
export default AdminLoggerDetailManagement;
