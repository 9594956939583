import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { AdminReseller } from '@/entities/models/AdminReseller';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import { AdminDepartment } from '@/entities/models/AdminDepartment';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiResponse from '@/entities/ApiResponse';
import AdminDevice from '@/entities/models/AdminDevice';
import { LoggersWithStates } from '@/entities/models/LoggersWithStates';
import { AdminResellerListable } from '@/entities/models/AdminResellerListable';
export default class ResellerRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    /**
     * Gets all resellers
     * @returns Array of AdminReseller
     */
    async getAdminResellers() {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_RESELLER)) {
            return [];
        }
        let url = ApiLinks.Reseller.Home;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return AdminResellerListable.fromApi(response.data.data);
        }
    }
    async adminCreateReseller(resellerDto) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let data = {
            Name: resellerDto.Name
        };
        let url = ApiLinks.Reseller.Home;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status === ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    /**
     * Gets reseller by ID. If no ID is specified, currently selected reseller ID is used.
     * @param [resellerId]
     * @returns reseller
     */
    async getResellerAdmin(resellerId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_RESELLER)) {
            return null;
        }
        if (!resellerId)
            return null;
        let url = `${ApiLinks.Reseller.Home}/${resellerId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new AdminReseller();
        else
            return AdminReseller.fromApi(response.data.data);
    }
    /**
     * Gets all departments for resellerID
     * @returns List of AdminDepartment objects
     */
    async adminResellerDepartments(resellerId, pagination = null, sort = null, filters = []) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_RESELLER)) {
            return null;
        }
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Departments}`;
        let queries = new ApiQueries();
        if (pagination)
            queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filters.length > 0) {
            queries.addFilters(filters);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return AdminDepartment.fromApi(response.data.data);
        }
    }
    async adminResellerDevices(resellerId, pagination = null, sort = null, filters = []) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_RESELLER)) {
            return parsedResponse;
        }
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Gateways}`;
        let queries = new ApiQueries();
        if (pagination)
            queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filters.length > 0) {
            queries.addFilters(filters);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            //Create response with pagination data
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(AdminDevice.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    async adminResellerLoggers(resellerId, pagination = null, sort = null, filters = []) {
        let parsedResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_RESELLER)) {
            return parsedResponse;
        }
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Loggers}`;
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filters.length > 0) {
            queries.addFilters(filters);
        }
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return parsedResponse;
        else {
            parsedResponse.setPagination(response.data);
            parsedResponse.setData(LoggersWithStates.fromApi(response.data.data));
            return parsedResponse;
        }
    }
    /**
     * Update Reseller name
     */
    async adminUpdateReseller(resellerId, data) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_RESELLER)) {
            return null;
        }
        let url = `${ApiLinks.Reseller.Home}/${resellerId}`;
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.CREATED)
            return new AdminReseller();
        else
            return AdminReseller.fromApi(response.data.data);
    }
    /**
     * Remove reseller user
     */
    async adminUnassignUser(resellerId, userId) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let data = new FormData();
        data.append('members[]', userId);
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Members}`;
        let response = await AxiosService.deleteDataFromServer(url, data, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    async addResellerUsers(resellerId, selectedUsersId) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let data = new FormData();
        selectedUsersId.forEach((userId) => {
            data.append('members[]', userId);
        });
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Members}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async addResellerDepartments(resellerId, selectedDepartmentsId) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let data = new FormData();
        selectedDepartmentsId.forEach((id) => {
            data.append('departments[]', id);
        });
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Departments}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async addResellerDevices(resellerId, selectedDevicesId) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let data = new FormData();
        selectedDevicesId.forEach((id) => {
            data.append('gateways[]', id);
        });
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Gateways}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async addResellerLoggers(resellerId, selectedLoggersId) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let data = new FormData();
        selectedLoggersId.forEach((id) => {
            data.append('loggers[]', id);
        });
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Loggers}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async adminUnassignDepartment(resellerId, departmentId) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let data = new FormData();
        data.append('departments[]', departmentId);
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Departments}`;
        let response = await AxiosService.deleteDataFromServer(url, data, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    async adminDeleteReseller(resellerId) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let url = `${ApiLinks.Reseller.Home}/${resellerId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    async adminUnassignLogger(resellerId, loggerId) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let data = new FormData();
        data.append('loggers[]', loggerId);
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Loggers}`;
        let response = await AxiosService.deleteDataFromServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async adminUnassignDevice(resellerId, deviceId) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_RESELLER)) {
            return false;
        }
        let data = new FormData();
        data.append('gateways[]', deviceId);
        let url = `${ApiLinks.Reseller.Home}/${resellerId}${ApiLinks.Reseller.Keys.Gateways}`;
        let response = await AxiosService.deleteDataFromServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    /**
     * Get reseller by user ID.
     * @param [userId]
     * @returns reseller
     */
    async adminResellerByUserId(userId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_RESELLER)) {
            return null;
        }
        let url = `${ApiLinks.Reseller.Home}${ApiLinks.Reseller.Keys.ByUser}/${userId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new AdminReseller();
        else
            return AdminReseller.fromApi(response.data.data);
    }
}
