import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var pdf = require('@/assets/other/LogHub - NAVOD NA POUZITIE.pdf');
let ManualComponent = class ManualComponent extends Vue {
    constructor() {
        super(...arguments);
        this.pdf = pdf;
    }
    downloadAppManual() {
        this.$emit('downloadAppManual', pdf);
    }
};
ManualComponent = __decorate([
    Component({ components: {} })
], ManualComponent);
export default ManualComponent;
