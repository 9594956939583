import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import Header from '@/views/components/common/Header.vue';
import Footer from '@/views/components/common/Footer.vue';
import NavSideBar from '@/views/components/common/NavSideBar.vue';
import UserPreferences from '@/services/UserPreferences';
import BackToTop from '../components/common/BackToTop.vue';
let WithSideBar = class WithSideBar extends Vue {
    constructor() {
        super(...arguments);
        this.isExpanded = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.SideMenuExpanded);
    }
    menuExpanded(isExpanded) {
        this.isExpanded = isExpanded;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.SideMenuExpanded, this.isExpanded);
    }
};
WithSideBar = __decorate([
    Component({
        components: {
            Header,
            Footer,
            NavSideBar,
            BackToTop
        }
    })
], WithSideBar);
export default WithSideBar;
