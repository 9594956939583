var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isScreenOnTop
    ? _c("b-button", {
        staticClass: "back-to-top is-hidden-tablet",
        attrs: { "icon-left": "angle-up", type: "is-primary" },
        on: { click: _vm.scrollToTop },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }