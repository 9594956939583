var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("h3", { staticClass: "subtitle is-4" }, [
          _vm._v(_vm._s(_vm.$t("tiers.features.usage"))),
        ]),
      ]),
      _c(
        "b-table",
        {
          staticClass: "table-wrap",
          attrs: {
            data: _vm.activeTier.enabledFeatures,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
          },
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "Name",
              label: _vm.$t("tiers.features.name"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (features) {
                  return [
                    _vm._v(
                      _vm._s(_vm.$t("tiers.features." + features.row.Name))
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "Type",
              label: _vm.$t("tiers.features.type"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (features) {
                  return [_vm._v(_vm._s(features.row.Type))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "Value",
              label: _vm.$t("tiers.features.value"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (features) {
                  return _vm._l(
                    Object.keys(features.row.Value),
                    function (valueKey) {
                      return _c("p", { key: valueKey }, [
                        _vm._v(
                          " " +
                            _vm._s(valueKey) +
                            ": " +
                            _vm._s(features.row.Value[valueKey]) +
                            " "
                        ),
                      ])
                    }
                  )
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "CurrentUsage",
              label: _vm.$t("tiers.features.usage"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (features) {
                  return [
                    features.row.CurrentUsage
                      ? _c(
                          "span",
                          _vm._l(
                            Object.keys(features.row.CurrentUsage),
                            function (valueKey) {
                              return _c("p", { key: valueKey }, [
                                _vm._v(
                                  " " +
                                    _vm._s(valueKey) +
                                    ": " +
                                    _vm._s(
                                      features.row.CurrentUsage[valueKey]
                                    ) +
                                    " "
                                ),
                              ])
                            }
                          ),
                          0
                        )
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }