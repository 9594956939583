var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-field",
        {
          attrs: {
            label: _vm.$t(
              "admin.component.company.registerCompanyDevicesModal.devices"
            ),
          },
        },
        [
          _c(
            "b-table",
            {
              staticClass: "select-location-table border select-user-table",
              attrs: {
                data: _vm.devices,
                "checked-rows": _vm.selectedDevices,
                paginated: true,
                "backend-pagination": "",
                "current-page": _vm.page,
                "per-page": _vm.pageSize,
                loading: _vm.componentIsLoading,
                total: _vm.totalSize,
                narrowed: "",
                checkable: "",
                "mobile-cards": false,
                "backend-filtering": "",
                "custom-is-checked": (a, b) => {
                  return a.deviceId === b.deviceId
                },
              },
              on: {
                "update:checkedRows": function ($event) {
                  _vm.selectedDevices = $event
                },
                "update:checked-rows": function ($event) {
                  _vm.selectedDevices = $event
                },
                "update:currentPage": function ($event) {
                  _vm.page = $event
                },
                "update:current-page": function ($event) {
                  _vm.page = $event
                },
                "page-change": _vm.onPageChange,
                check: _vm.selectDevices,
              },
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: "Name",
                  label: _vm.$t(
                    "admin.component.company.registerCompanyDevicesModal.table.serialName"
                  ),
                  searchable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchable",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "buttons mb-1" },
                          [
                            _c("b-input", {
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("generals.search"),
                                size: "is-small",
                              },
                              on: { input: _vm.handleFilterSerialNameDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterDevices(_vm.serialNameSearch)
                                },
                              },
                              model: {
                                value: _vm.serialNameSearch,
                                callback: function ($$v) {
                                  _vm.serialNameSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "serialNameSearch",
                              },
                            }),
                            _c("b-icon", {
                              staticClass: "level-item clickable",
                              attrs: { icon: "search" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.filterDevices(_vm.serialNameSearch)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (device) {
                      return [
                        _c("p", [
                          _vm._v(" " + _vm._s(device.row.deviceName) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "Description",
                  label: _vm.$t(
                    "admin.component.devices.detail.deviceInfo.description"
                  ),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (device) {
                      return [
                        _c("p", [
                          device.row.description
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s(device.row.description) + " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-field",
        {
          attrs: {
            label: _vm.$t(
              "admin.component.company.registerCompanyDevicesModal.selectedDevices"
            ),
          },
        },
        [
          _c(
            "b-table",
            {
              staticClass:
                "select-location-table border select-user-table-list",
              attrs: { data: _vm.selectedDevices, striped: "" },
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: "Name",
                  label: _vm.$t(
                    "admin.component.company.registerCompanyDevicesModal.table.serialName"
                  ),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (device) {
                      return [
                        _c("p", [
                          _vm._v(" " + _vm._s(device.row.deviceName) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "Description",
                  label: _vm.$t(
                    "admin.component.devices.detail.deviceInfo.description"
                  ),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (device) {
                      return [
                        _c("p", [
                          device.row.description
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s(device.row.description) + " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("section", { staticClass: "section" }, [
                  _c(
                    "div",
                    { staticClass: "content has-text-grey has-text-centered" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "admin.component.company.registerCompanyDevicesModal.table.empty"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }