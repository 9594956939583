import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ChartDataConverter } from '@/services/chart-data-conversion/chartDataConversion';
import { Chart, ChartBuilder, LegendOrientation, LabelPosition } from 'd3Chart';
import DaysBeforeOptions from '@/entities/enums/DaysBeforeOptions';
import { ChartServices } from '@/entities/enums/ChartServices';
import UserPreferences from '@/services/UserPreferences';
import { ChartHeight } from '@/entities/enums/ChartHeight';
import { ChartComponents } from '@/entities/enums/ChartComponents';
let ChartComponent = class ChartComponent extends Vue {
    constructor() {
        super(...arguments);
        this.Chart = Chart;
        this.chartServices = [];
        this.selectedChartService = ChartServices.TEMPERATURE;
        this.DaysBeforeOptions = DaysBeforeOptions;
        this.chartId = 'mainChart';
        this.ChartHeight = ChartHeight;
        this.hasDataForTimerange = false;
        this.selectedDayOption = DaysBeforeOptions.ONE;
        this.isHasBoundaries = false;
        this.isScaleByData = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IsScaleByData);
        this.availableChartLogger = [];
        this.isLoggersFilterApplied = false;
        this.chartHeightPixel = 0;
        this.selectedChartHeight = ChartHeight.SMALL;
    }
    onLoading() {
        if (!this.componentIsLoading) {
            this.selectedChartHeight = this.chartHeight;
            this.chartHeightPixel = this.getChartHeight();
            this.loadChartServices();
            this.createChart();
        }
        else
            this.destroyChart();
    }
    mounted() {
        this.selectedDayOption = this.dayBeforeOption;
    }
    created() {
        //read TS from route
        try {
            let givenTS = this.$route.query.service;
            this.selectedChartService = Object.values(ChartServices).includes(givenTS) ? givenTS : ChartServices.TEMPERATURE;
        }
        catch (error) {
            this.selectedChartService = ChartServices.TEMPERATURE;
        }
    }
    changeChartHeight() {
        this.destroyChart();
        this.chartHeightPixel = this.getChartHeight();
        this.loadChartServices();
        this.createChart();
        this.$emit('changeUserSetting', this.selectedChartHeight);
    }
    loadChartServices() {
        this.chartServices = [];
        this.eventTypes.forEach((event) => {
            if (event.name.toLowerCase() == ChartServices.HUMIDITY ||
                event.name.toLowerCase() == ChartServices.PRESSURE ||
                event.name.toLowerCase() == ChartServices.TEMPERATURE ||
                event.name.toLowerCase() == ChartServices.UPTIME) {
                this.chartServices.push(event.name.toLowerCase());
            }
        });
        if (this.chartServices.length > 1)
            this.chartServices.unshift(ChartServices.ALL);
    }
    getChartHeight() {
        if (this.selectedChartHeight == ChartHeight.SMALL) {
            return 300;
        }
        else if (this.selectedChartHeight == ChartHeight.LARGE) {
            return 500;
        }
        else {
            return Math.floor(70 * (screen.height / 100));
        }
    }
    beforeDestroy() {
        this.destroyChart();
    }
    applyLoggersFilter() {
        this.isLoggersFilterApplied = true;
    }
    changeApplyButton() {
        this.isLoggersFilterApplied = false;
    }
    async createChart() {
        this.isHasBoundaries = false;
        let dataConverter = new ChartDataConverter(this.otherServiceSources, this.upTimeServiceSources, this.chartData, this.upTimeChartData, this.eventTypes, this.annotationsForSources);
        if (dataConverter.boundaries.length > 0) {
            this.isHasBoundaries = true;
        }
        let rightMargin = dataConverter.services.length > 1 ? 40 : 15;
        let settings = {
            fullHeight: this.chartHeightPixel,
            previewChartHeight: 70,
            gapBetweenCharts: 20,
            margin: {
                left: 40,
                right: rightMargin
            },
            marginPreview: {
                left: 40,
                right: rightMargin
            }
        };
        let date = new Date();
        date.setDate(date.getDate() - this.selectedDayOption);
        let dateRange = {
            startTS: date.getTime(),
            stopTS: new Date().getTime()
        };
        if (dataConverter.hasData) {
            this.availableChartLogger = dataConverter.data;
            let availableChartLogger = this.availableChartLogger.filter((x, i, a) => a.findIndex((x2) => x.name === x2.name) === i);
            this.availableChartLogger = availableChartLogger;
            this.hasDataForTimerange = true;
            this.chartEntity = new ChartBuilder(this.chartId, settings, dataConverter.services, dataConverter.data)
                .addBoundaries(dataConverter.boundaries, false)
                .makeInteractive()
                .setDateFormatter(await dataConverter.getTimeFormatter(this, this.onPublicPage))
                .setChartDateFormatter(await dataConverter.getChartTimeFormatter(this, this.onPublicPage))
                .setOnlyTimeFormatter(await dataConverter.getOnlyTimeFormatter(this, this.onPublicPage))
                .setOnlyDateFormatter(await dataConverter.getOnlyDateFormatter(this, this.onPublicPage))
                .addPreviewChart()
                .setLabelPosition(LabelPosition.UP)
                .addLegend(LegendOrientation.ROW)
                .addGridlines(true, true)
                .addZoomButtons()
                .setDateRange(dateRange)
                .addServiceButtonsHandling()
                .addScaleButton(this.isScaleByData)
                .addLoggersFilter()
                .addLegendsFilter()
                .setFontSize('12px')
                .build();
        }
        else {
            this.hasDataForTimerange = false;
        }
        this.$emit('eventHandler', this.hasDataForTimerange, this.chartEntity, ChartComponents.CHARTCOMPONENT);
    }
    changeChartServices(event) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IsScaleByData, event);
    }
    selectChartServices(item) {
        let tempServices = document.getElementById(ChartServices.TEMPERATURE);
        let humidityServices = document.getElementById(ChartServices.HUMIDITY);
        let pressureServices = document.getElementById(ChartServices.PRESSURE);
        let uptimeServices = document.getElementById(ChartServices.UPTIME);
        let allServices = document.getElementById(ChartServices.ALL);
        //Changing style for sevices selection tab
        if (tempServices)
            tempServices.classList.remove('is-active');
        if (humidityServices)
            humidityServices.classList.remove('is-active');
        if (pressureServices)
            pressureServices.classList.remove('is-active');
        if (uptimeServices)
            uptimeServices.classList.remove('is-active');
        if (allServices)
            allServices.classList.remove('is-active');
        switch (item) {
            case ChartServices.TEMPERATURE:
                tempServices.classList.add('is-active');
                break;
            case ChartServices.HUMIDITY:
                humidityServices.classList.add('is-active');
                break;
            case ChartServices.PRESSURE:
                pressureServices.classList.add('is-active');
                break;
            case ChartServices.UPTIME:
                uptimeServices.classList.add('is-active');
                break;
            default:
                allServices.classList.add('is-active');
                break;
        }
        this.selectedChartService = item;
    }
    destroyChart() {
        if (this.chartEntity)
            this.chartEntity.destroyChart();
    }
    dayOptionChanged() {
        this.$emit('dayOptionSelected', this.selectedDayOption);
    }
};
__decorate([
    Prop({
        type: Array,
        default: () => {
            [];
        }
    })
], ChartComponent.prototype, "otherServiceSources", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => {
            [];
        }
    })
], ChartComponent.prototype, "upTimeServiceSources", void 0);
__decorate([
    Prop({ type: Object })
], ChartComponent.prototype, "chartData", void 0);
__decorate([
    Prop({ type: Object })
], ChartComponent.prototype, "upTimeChartData", void 0);
__decorate([
    Prop({ type: Array })
], ChartComponent.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], ChartComponent.prototype, "annotationsForSources", void 0);
__decorate([
    Prop({ type: Number })
], ChartComponent.prototype, "dayBeforeOption", void 0);
__decorate([
    Prop({ type: Boolean })
], ChartComponent.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ChartComponent.prototype, "onPublicPage", void 0);
__decorate([
    Prop({ type: String })
], ChartComponent.prototype, "chartHeight", void 0);
__decorate([
    Watch('componentIsLoading')
], ChartComponent.prototype, "onLoading", null);
ChartComponent = __decorate([
    Component({ components: {} })
], ChartComponent);
export default ChartComponent;
