import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { BoundaryDTO } from '@/entities/models/Boundary';
import { assignDefaultBoundariesValues } from '@/entities/enums/EventTypeDefaults';
import DecimalConversion from '@/services/DecimalConversion';
import EventType from '@/entities/EventType';
let BoundaryInfoWithEdit = class BoundaryInfoWithEdit extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
        this.BoundaryID = BoundaryID;
        this.isBoundaryEdit = false;
        this.newBoundaries = [];
        this.customErrors = {};
    }
    /** For now, filter out uptime service. There is no reason to have boundaries for this service */
    get eventTypesWithoutUptime() {
        return this.eventTypes.filter((x) => x.id != EventType.UPTIME_ID);
    }
    get notBoundedEventTypes() {
        let usedTypes = this.newBoundaries.map((x) => x.measuredDataTypeSlug);
        let availableTypes = this.eventTypesWithoutUptime.filter((x) => !usedTypes.includes(x.slug));
        return availableTypes;
    }
    get maxNumberOfBoundaries() {
        return this.eventTypesWithoutUptime.length;
    }
    get canAddMoreBoundaries() {
        return this.newBoundaries.length < this.maxNumberOfBoundaries;
    }
    get createdNumberOfBoundaries() {
        return this.newBoundaries.length;
    }
    created() {
        this.assignBoundaries(this.boundaries);
        this.isBoundaryEdit = this.$route.query.isBoundaryEdit == 'true' ? true : false;
    }
    assignBoundaries(boundaries) {
        this.newBoundaries = this.getCopyOfBoundaries(boundaries);
    }
    getCopyOfBoundaries(boundaries) {
        if (!boundaries)
            return [];
        else {
            let copied = JSON.parse(JSON.stringify(boundaries));
            copied.forEach((b) => {
                b.lowerAlarmBoundary = DecimalConversion.toCommaString(b.lowerAlarmBoundary);
                b.lowerWarningBoundary = DecimalConversion.toCommaString(b.lowerWarningBoundary);
                b.upperWarningBoundary = DecimalConversion.toCommaString(b.upperWarningBoundary);
                b.upperAlarmBoundary = DecimalConversion.toCommaString(b.upperAlarmBoundary);
            });
            return copied;
        }
    }
    addNewBoundary() {
        let newBound = new BoundaryDTO();
        newBound.isNew = true;
        newBound.lowerAlarmBoundary = null;
        newBound.lowerWarningBoundary = null;
        newBound.upperWarningBoundary = null;
        newBound.upperAlarmBoundary = null;
        if (this.notBoundedEventTypes.length == 1) {
            newBound.measuredDataTypeSlug = this.notBoundedEventTypes[0].slug;
            assignDefaultBoundariesValues(newBound, newBound.measuredDataTypeSlug);
        }
        this.newBoundaries.push(newBound);
        this.emitChangeEvent();
    }
    removeBoundary(service) {
        this.newBoundaries = this.newBoundaries.filter((x) => x.measuredDataTypeSlug != service);
        this.emitChangeEvent();
    }
    typeSelected(boundary, typeSlug) {
        boundary.measuredDataTypeSlug = typeSlug;
        boundary.unit = this.eventTypes.find((x) => x.slug == typeSlug).unit.unit;
        let existingBoundary = this.boundaries.find((x) => x.measuredDataTypeSlug == typeSlug);
        if (existingBoundary) {
            boundary.lowerAlarmBoundary = DecimalConversion.toCommaString(existingBoundary.lowerAlarmBoundary);
            boundary.lowerWarningBoundary = DecimalConversion.toCommaString(existingBoundary.lowerWarningBoundary);
            boundary.upperWarningBoundary = DecimalConversion.toCommaString(existingBoundary.upperWarningBoundary);
            boundary.upperAlarmBoundary = DecimalConversion.toCommaString(existingBoundary.upperAlarmBoundary);
        }
        else {
            if (!boundary.lowerAlarmBoundary &&
                !boundary.lowerWarningBoundary &&
                !boundary.upperWarningBoundary &&
                !boundary.upperAlarmBoundary) {
                assignDefaultBoundariesValues(boundary, typeSlug);
            }
        }
        this.emitChangeEvent();
    }
    emitChangeEvent() {
        this.$emit('boundariesChanged', this.newBoundaries);
    }
    boundaryValueChanged() {
        let comparisonResult = this.compareBoundariesValues();
        this.$nextTick(() => {
            this.$validator.validateAll().then(async (result) => {
                if (result && comparisonResult) {
                    this.emitChangeEvent();
                }
            });
        });
    }
    compareBoundariesValues() {
        let finalBoundaries = this.getCopyOfBoundaries(this.newBoundaries);
        let comparisonResult = true;
        finalBoundaries.forEach((boundary) => {
            boundary.lowerAlarmBoundary = DecimalConversion.toFloat(boundary.lowerAlarmBoundary);
            boundary.lowerWarningBoundary = DecimalConversion.toFloat(boundary.lowerWarningBoundary);
            boundary.upperWarningBoundary = DecimalConversion.toFloat(boundary.upperWarningBoundary);
            boundary.upperAlarmBoundary = DecimalConversion.toFloat(boundary.upperAlarmBoundary);
            this.customErrors[boundary.measuredDataTypeSlug] = {};
            if (boundary.lowerAlarmBoundary >= boundary.upperAlarmBoundary) {
                comparisonResult = false;
                this.customErrors[boundary.measuredDataTypeSlug][BoundaryID.LA] = this.$t('component.wizard.wizardBoundaries.setBoundaries.boundary_values_invalid');
                this.customErrors[boundary.measuredDataTypeSlug][BoundaryID.UA] = this.$t('component.wizard.wizardBoundaries.setBoundaries.boundary_values_invalid');
            }
            if (boundary.lowerWarningBoundary != null && boundary.lowerWarningBoundary <= boundary.lowerAlarmBoundary) {
                comparisonResult = false;
                this.customErrors[boundary.measuredDataTypeSlug][BoundaryID.LW] = this.$t('component.wizard.wizardBoundaries.setBoundaries.boundary_values_invalid');
                this.customErrors[boundary.measuredDataTypeSlug][BoundaryID.LA] = this.$t('component.wizard.wizardBoundaries.setBoundaries.boundary_values_invalid');
            }
            if (boundary.upperWarningBoundary != null && boundary.upperWarningBoundary >= boundary.upperAlarmBoundary) {
                comparisonResult = false;
                this.customErrors[boundary.measuredDataTypeSlug][BoundaryID.UW] = this.$t('component.wizard.wizardBoundaries.setBoundaries.boundary_values_invalid');
                this.customErrors[boundary.measuredDataTypeSlug][BoundaryID.UA] = this.$t('component.wizard.wizardBoundaries.setBoundaries.boundary_values_invalid');
            }
            if (boundary.lowerWarningBoundary != null &&
                boundary.upperWarningBoundary != null &&
                boundary.lowerWarningBoundary >= boundary.upperWarningBoundary) {
                comparisonResult = false;
                this.customErrors[boundary.measuredDataTypeSlug][BoundaryID.LW] = this.$t('component.wizard.wizardBoundaries.setBoundaries.boundary_values_invalid');
                this.customErrors[boundary.measuredDataTypeSlug][BoundaryID.UW] = this.$t('component.wizard.wizardBoundaries.setBoundaries.boundary_values_invalid');
            }
        });
        return comparisonResult;
    }
    validate() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.compareBoundariesValues()) {
                    this.$emit('validation', true);
                }
            }
            else {
                this.$emit('validation', false);
            }
        });
    }
    hasErrorMsg(serviceName, boundary) {
        if ((this.customErrors[serviceName] || {})[boundary])
            return true;
        else
            return false;
    }
    getErrorMsg(serviceName, boundary) {
        if ((this.customErrors[serviceName] || {})[boundary])
            return this.customErrors[serviceName][boundary];
        else
            return null;
    }
};
__decorate([
    Prop({ type: Array })
], BoundaryInfoWithEdit.prototype, "boundaries", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], BoundaryInfoWithEdit.prototype, "hasEditPermissions", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], BoundaryInfoWithEdit.prototype, "showAllBoundaries", void 0);
__decorate([
    Prop({ type: Array })
], BoundaryInfoWithEdit.prototype, "eventTypes", void 0);
BoundaryInfoWithEdit = __decorate([
    Component({})
], BoundaryInfoWithEdit);
export default BoundaryInfoWithEdit;
var BoundaryID;
(function (BoundaryID) {
    BoundaryID["LW"] = "LW";
    BoundaryID["UW"] = "UW";
    BoundaryID["LA"] = "LA";
    BoundaryID["UA"] = "UA";
})(BoundaryID || (BoundaryID = {}));
