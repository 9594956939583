import { __decorate } from "tslib";
import SortDirections from '@/entities/enums/SortDirections';
import UserPreferences from '@/services/UserPreferences';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import IssueRepository from '@/services/repository/IssueRepository';
import NotificationChannelType from '@/entities/notifications/NotificationChannelType';
import { vxm } from '@/store/store.vuex';
import MeasurementStatuses from '@/entities/enums/measurementStatuses';
import IssueTableFilter from '@/views/components/issues/IssueTableFilter.vue';
import DepartmentIssueTypes from '@/entities/enums/DepartmentIssueTypes';
import IssuesFilterDateRangeOptions from '@/entities/enums/IssuesFilterDateRangeOptions';
var issueRepository;
var vxDepartmentStore = vxm.departmentStore;
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let IssuesTable = class IssuesTable extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.MeasurementStatuses = MeasurementStatuses;
        this.IssuesFilterDateRangeOptions = IssuesFilterDateRangeOptions;
        this.DepartmentIssueTypes = DepartmentIssueTypes;
        this.rowsPerPage = null;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.currentFilters = [];
        this.allIssueNotifications = [];
        this.isIncludeResolved = false;
        this.selectedType = null;
        this.selectedDateRange = [];
        this.entityId = '';
        this.dateRangeValue = null;
        this.tableColumns = {
            type: {
                field: 'type',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IssuesTableType)
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IssuesTableCreatedAt)
            },
            resolvedAt: {
                field: 'resolvedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IssuesTableResolvedAt)
            },
            acknowledgedBy: {
                field: 'acknowledgedBy',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IssuesTableAcknowledgedBy)
            }
        };
    }
    get totalSize() {
        if (this.departmentIssues)
            return this.departmentIssues.getPagination().total;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.rowsPerPage + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.rowsPerPage > this.totalSize ? this.totalSize : this.page * this.rowsPerPage;
    }
    loadIssuesData() {
        if (this.issuesLoaded === true) {
            this.onOptionsChange(this.pagination, this.sort, false);
        }
    }
    created() {
        issueRepository = new IssueRepository(this);
        this.initLoadIssues();
    }
    initLoadIssues() {
        this.fillFilterFromRoute();
        this.fillSearches();
        this.resolvedFilter(this.isIncludeResolved);
        if (this.issuesLoaded === true) {
            this.onOptionsChange(this.pagination, this.sort, false);
        }
    }
    fillSearches() {
        if (this.tableQueriesfromUrl && this.tableQueriesfromUrl.filters)
            this.isIncludeResolved = this.filterExists('ResolvedAt==null') !== -1 ? false : true;
        if (this.currentFilters.length !== 0) {
            this.selectedType =
                this.filterExists('Type==') !== -1
                    ? this.currentFilters[this.filterExists('Type==')].substring(6)
                    : null;
            if (this.filterExists('CreatedAt>=') !== -1)
                this.selectedDateRange[0] = new Date(this.currentFilters[this.filterExists('CreatedAt>=')].substring(11));
            if (this.filterExists('CreatedAt<') !== -1)
                this.selectedDateRange[1] = new Date(this.currentFilters[this.filterExists('CreatedAt<')].substring(10));
            this.entityId =
                this.filterExists('EntityId==') !== -1
                    ? this.currentFilters[this.filterExists('EntityId==')].substring(10)
                    : '';
        }
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value || value == null || value == '') {
                this.currentFilters.splice(index, 1);
            }
            else {
                this.currentFilters[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilters.push(filter + value);
    }
    filterExists(filter) {
        return this.currentFilters.findIndex((element) => element.includes(filter));
    }
    redirectChecker(issue, activeTabNumber = 0) {
        if (this.fromAdmin) {
            this.$router
                .push({
                name: 'issueDetailAdmin',
                params: {
                    companyId: this.$route.params.departmentId,
                    lang: this.$route.params.lang,
                    issueId: issue.id
                },
                query: { activeTab: activeTabNumber.toString() }
            })
                .catch((err) => err);
        }
        else {
            this.$router
                .push({
                name: 'issueDetail',
                params: {
                    departmentId: this.$route.params.departmentId,
                    lang: this.$route.params.lang,
                    issueId: issue.id
                },
                query: { activeTab: activeTabNumber.toString() }
            })
                .catch((err) => err);
        }
    }
    acknowledgeable(issue) {
        return issue.acknowledgedBy || issue.resolvedAt ? false : true;
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.issues);
            this.sortField = this.tableQueriesfromUrl.field;
            this.sortDirection = this.tableQueriesfromUrl.direction;
            this.page = this.tableQueriesfromUrl.page;
            this.rowsPerPage = this.tableQueriesfromUrl.pageSize;
            this.currentFilters = JSON.parse(this.tableQueriesfromUrl.filters);
            this.dateRangeValue = this.tableQueriesfromUrl.dateRangeValue ? this.tableQueriesfromUrl.dateRangeValue : null;
            this.sort = {
                field: this.sortField,
                direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
            };
            this.pagination = { page: this.page, pageSize: this.rowsPerPage };
            if (!this.sortField || !this.sortDirection || !this.page || !this.rowsPerPage) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.IssuesTableSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.IssuesTableSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.IssuesTablePage.defaultValue;
        this.rowsPerPage = UserPreferences.LocalStorageKeys.IssuesTableRowsPerPage.defaultValue;
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.rowsPerPage };
    }
    issueTableCheckbox(isIncludeResolved) {
        this.resolvedFilter(isIncludeResolved);
        this.onOptionsChange(this.pagination, this.sort);
    }
    entitySelected(entityId) {
        entityId = entityId.includes(',') ? entityId.replace(/,/g, '\\,') : entityId;
        this.replaceFilter('EntityId==', entityId);
        this.onOptionsChange(this.pagination, this.sort);
    }
    dateRangeSelected(dateRange, dateRangeValue) {
        this.dateRangeValue = dateRangeValue;
        let convertedDateRange = this.dateTimeManager.formatDateRange(dateRange);
        this.replaceFilter('CreatedAt>=', this.dateTimeManager.formatStartDateForReports(convertedDateRange[0]).text);
        this.replaceFilter('CreatedAt<', this.dateTimeManager.formatEndDateForReports(convertedDateRange[1], true).text);
        this.onOptionsChange(this.pagination, this.sort);
    }
    issueTypeSelected(type) {
        this.replaceFilter('Type==', type);
        this.onOptionsChange(this.pagination, this.sort);
    }
    clearFilter() {
        this.currentFilters = [];
        this.dateRangeValue = null;
        this.isIncludeResolved = false;
        this.resolvedFilter(this.isIncludeResolved);
        this.onOptionsChange(this.pagination, this.sort);
    }
    resolvedFilter(isIncludeResolved) {
        if (isIncludeResolved) {
            this.replaceFilter('ResolvedAt==', '');
        }
        else {
            this.replaceFilter('ResolvedAt==', 'null');
        }
    }
    // changing default sort field
    changeDefaultSortField(sortFieldName) {
        if (this.sort.field == sortFieldName) {
            this.sort.field = this.tableColumns.type.field;
            this.sortField = this.sort.field;
        }
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.createdAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.createdAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IssuesTableCreatedAt, event);
                break;
            case this.tableColumns.resolvedAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.resolvedAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IssuesTableResolvedAt, event);
                break;
            case this.tableColumns.acknowledgedBy.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.acknowledgedBy.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IssuesTableAcknowledgedBy, event);
                break;
        }
        this.$nextTick(() => {
            this.$refs.table.initSort();
        });
    }
    async loadIssueNotification(issue) {
        if (!this.allIssueNotifications.find((x) => x.issue.id == issue.id)) {
            let issueNotifications = (await issueRepository.getDepartmentIssueNotifications(issue.id, this.fromAdmin ? this.$route.params.departmentId : this.getSelectedDepartmentId()))
                .getData()
                .filter((notification) => notification.channel != NotificationChannelType.SYSTEM);
            this.allIssueNotifications.push({
                issue: issue,
                notifications: issueNotifications
            });
        }
    }
    getIssueNotificationsLength(issue) {
        return this.allIssueNotifications.find((x) => x.issue.id == issue.id);
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    getDevice(deviceId) {
        return this.devices.find((x) => x.deviceId == deviceId);
    }
    getSource(sourceId) {
        return this.sources.find((x) => x.id == sourceId);
    }
    getCompanyLogger(companyLoggerId) {
        return this.sources.find((x) => x.logger && x.logger.companyLoggerId == companyLoggerId);
    }
    // getAdminDevice(deviceId: string) {
    //   return this.deviceList.find((x) => x.currentConfig && x.currentConfig.deviceId == deviceId);
    // }
    getAdminCompanyLogger(companyLoggerId) {
        return this.companyLoggers.find((x) => x.companyLoggerId == companyLoggerId);
    }
    getAdminLogger(sourceId) {
        let source = this.sources.find((source) => source.id == sourceId);
        return this.companyLoggers.find((x) => x.companyLoggerId == source?.logger?.companyLoggerId);
    }
    getSourceGroup(sourceGroupId) {
        return this.sourceGroups.find((x) => x.id == sourceGroupId);
    }
    getName(issue) {
        let name = '';
        try {
            switch (issue.type) {
                case NotificationSubscriptionType.DEVICE_OFFLINE:
                    if (this.fromAdmin)
                        name = this.getDevice(issue.metadata.deviceInstanceId).deviceName;
                    else
                        name = this.getDevice(issue.metadata.deviceInstanceId).deviceName;
                    break;
                case NotificationSubscriptionType.SOURCE_OFFLINE:
                    if (this.fromAdmin)
                        name = this.getAdminLogger(issue.metadata.sourceId).source.name;
                    else
                        name = this.getSource(issue.metadata.sourceId).name;
                    break;
                case NotificationSubscriptionType.LOW_BATTERY:
                    if (this.fromAdmin)
                        name = this.getAdminCompanyLogger(issue.metadata.companyLoggerId).source.name;
                    else
                        name = this.getCompanyLogger(issue.metadata.companyLoggerId).name;
                    break;
                case NotificationSubscriptionType.MEASURED_DATA_ALARM:
                    if (this.fromAdmin)
                        name = this.getAdminLogger(issue.metadata.sourceId).source.name;
                    else
                        name = this.getSource(issue.metadata.sourceId).name;
                    break;
                case NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM:
                    if (this.fromAdmin)
                        name = 'Unknown';
                    else
                        name = this.getSourceGroup(issue.metadata.sourceGroupId).name;
                    break;
                default:
                    name = 'Unknown';
                    break;
            }
        }
        catch (err) {
            name = 'Unknown';
        }
        return name;
    }
    acknowledgeIssue(issueId) {
        this.$emit('acknowledgeIssue', issueId, this.pagination, this.sort, this.currentFilters);
    }
    getTimeDifferenceBySegment(date) {
        let timeSegments = this.dateTimeManager.getTimeDifferenceBySegment(date);
        let timeSegmentsString = '';
        if (timeSegments.days > 0) {
            timeSegmentsString = timeSegments.days == 1 ? timeSegments.days + 'day ' : timeSegments.days + 'days ';
        }
        if (timeSegments.hours > 0) {
            timeSegmentsString += timeSegments.hours == 1 ? timeSegments.hours + 'hr ' : timeSegments.hours + 'hrs ';
        }
        if (timeSegments.minutes > 0) {
            timeSegmentsString += timeSegments.minutes == 1 ? timeSegments.minutes + 'min ' : timeSegments.minutes + 'mins ';
        }
        return timeSegmentsString;
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.rowsPerPage };
        this.onOptionsChange(this.pagination, this.sort);
    }
    sorted(field, order) {
        this.sortField = field;
        this.sortDirection = order;
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.rowsPerPage };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onOptionsChange(pagination, sort, changeRouterUrl = true) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.$emit('onOptionsChange', options, this.currentFilters);
        if (changeRouterUrl)
            this.changeRouterUrl(this.pagination, this.sort);
    }
    changeRouterUrl(pagination, sort) {
        this.tableQueries = {
            field: sort.field,
            direction: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            pageSize: pagination.pageSize,
            filters: JSON.stringify(this.currentFilters.filter((x) => x != null)),
            dateRangeValue: this.dateRangeValue
        };
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                issues: JSON.stringify(this.tableQueries)
            })
        })
            .catch((err) => err);
    }
};
__decorate([
    Prop({ type: Object })
], IssuesTable.prototype, "departmentIssues", void 0);
__decorate([
    Prop({ type: Object })
], IssuesTable.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], IssuesTable.prototype, "issuesLoaded", void 0);
__decorate([
    Prop({ type: Array })
], IssuesTable.prototype, "devices", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], IssuesTable.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array })
], IssuesTable.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array })
], IssuesTable.prototype, "companyLoggers", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], IssuesTable.prototype, "fromAdmin", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], IssuesTable.prototype, "isIssuesTableLoading", void 0);
__decorate([
    Watch('issuesLoaded')
], IssuesTable.prototype, "loadIssuesData", null);
IssuesTable = __decorate([
    Component({ components: { IssueTableFilter } })
], IssuesTable);
export default IssuesTable;
