var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "section",
        [
          _c("div", { staticClass: "level is-mobile multi-header" }, [
            _c(
              "div",
              { staticClass: "level-left is-flex is-align-content-center" },
              [
                !(_vm.isScanning && _vm.isSelectedDeviceOnline) &&
                !_vm.isWaitingForScanStart
                  ? _c(
                      "b-field",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "b-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "component.logger.modal.select_device"
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.startScanning()
                              },
                            },
                            model: {
                              value: _vm.selectedDeviceId,
                              callback: function ($$v) {
                                _vm.selectedDeviceId = $$v
                              },
                              expression: "selectedDeviceId",
                            },
                          },
                          [
                            _c(
                              "optgroup",
                              { attrs: { label: _vm.$t("generals.online") } },
                              _vm._l(_vm.onlineDevices, function (device) {
                                return _c(
                                  "option",
                                  {
                                    key: device.deviceId,
                                    domProps: { value: device.deviceId },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(device.deviceName) + " "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "optgroup",
                              { attrs: { label: _vm.$t("generals.offline") } },
                              _vm._l(_vm.offlineDevices, function (device) {
                                return _c(
                                  "option",
                                  {
                                    key: device.deviceId,
                                    domProps: { value: device.deviceId },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(device.deviceName) + " "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "has-device-container" }, [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              (
                                _vm.devices.find(
                                  (x) => x.deviceId == _vm.selectedDeviceId
                                ) || {}
                              ).deviceName
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                _vm.selectedDeviceId && !_vm.isSelectedDeviceOnline
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "ml-3 has-text-weight-bold has-text-primary",
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("b-icon", {
                              attrs: {
                                pack: "fas",
                                icon: "exclamation-triangle",
                                size: "is-small",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.logger.modal.messages.start.notOnline"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "level-right" }, [
              !_vm.isScanning && !_vm.isWaitingForScanStart
                ? _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      attrs: {
                        type: "button",
                        disabled:
                          (_vm.selectedDeviceId ? false : true) ||
                          !_vm.isSelectedDeviceOnline,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.startScanning()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.logger.modal.start")) +
                          " "
                      ),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.stopScanning()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.logger.modal.stop")) +
                          " "
                      ),
                    ]
                  ),
            ]),
          ]),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
            },
            [_vm._v(_vm._s(_vm.intervalDuration))]
          ),
          _c(
            "b-table",
            {
              attrs: {
                data: _vm.loggers,
                striped: "",
                checkable: "",
                "checked-rows": _vm.checkedLoggers,
                "is-row-checkable": (row) => {
                  return !row.hasSource
                },
              },
              on: {
                "update:checkedRows": function ($event) {
                  _vm.checkedLoggers = $event
                },
                "update:checked-rows": function ($event) {
                  _vm.checkedLoggers = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  _vm.loggers.length > 0
                    ? {
                        key: "bottom-left",
                        fn: function () {
                          return [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("component.logger.modal.table.checked")
                                )
                              ),
                            ]),
                            _vm._v(
                              " : " +
                                _vm._s(_vm.checkedLoggers.length) +
                                "/" +
                                _vm._s(_vm.loggers.length) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("section", { staticClass: "section" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered",
                            },
                            [
                              _vm.loggers.length > 0
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.logger.modal.table.empty"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm.isWaitingForScanStart
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.logger.modal.messages.waiting"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : !_vm.isScanning
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.logger.modal.table.selectAndStart"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "component.logger.modal.table.loading"
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: "serialNumber",
                  label: _vm.$t("component.logger.modal.table.serialNumber"),
                  sortable: "",
                  searchable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchable",
                    fn: function (loggers) {
                      return [
                        _c("b-input", {
                          attrs: { placeholder: _vm.$t("generals.search") },
                          model: {
                            value: loggers.filters[loggers.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                loggers.filters,
                                loggers.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "loggers.filters[loggers.column.field]",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (loggers) {
                      return [
                        _vm._v(" " + _vm._s(loggers.row.serialNumber) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "name",
                  label: _vm.$t("component.logger.modal.table.name"),
                  searchable: "",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchable",
                    fn: function (loggers) {
                      return [
                        _c("b-input", {
                          attrs: { placeholder: _vm.$t("generals.search") },
                          model: {
                            value: loggers.filters[loggers.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                loggers.filters,
                                loggers.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "loggers.filters[loggers.column.field]",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (loggers) {
                      return [
                        !loggers.row.hasSource
                          ? _c(
                              "b-field",
                              {
                                attrs: {
                                  type: {
                                    "is-danger": loggers.row.hasErrorField,
                                  },
                                  message: loggers.row.errorMsg,
                                },
                              },
                              [
                                _c("b-input", {
                                  attrs: {
                                    type: "text",
                                    name:
                                      _vm.$t(
                                        "component.logger.modal.table.name"
                                      ) + loggers.index,
                                    expanded: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.checkName(
                                        loggers.row,
                                        loggers.index
                                      )
                                    },
                                  },
                                  model: {
                                    value: loggers.row.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        loggers.row,
                                        "name",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "loggers.row.name",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(loggers.row.name))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "description",
                  label: _vm.$t("component.logger.modal.table.description"),
                  searchable: "",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchable",
                    fn: function (loggers) {
                      return [
                        _c("b-input", {
                          attrs: { placeholder: _vm.$t("generals.search") },
                          model: {
                            value: loggers.filters[loggers.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                loggers.filters,
                                loggers.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "loggers.filters[loggers.column.field]",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (loggers) {
                      return [
                        !loggers.row.hasSource
                          ? _c(
                              "b-field",
                              [
                                _c("b-input", {
                                  attrs: {
                                    type: "text",
                                    name: _vm.$t(
                                      "component.logger.modal.table.description"
                                    ),
                                    expanded: "",
                                  },
                                  model: {
                                    value: loggers.row.description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        loggers.row,
                                        "description",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "loggers.row.description",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(_vm._s(loggers.row.description)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "macAddress",
                  label: _vm.$t("component.logger.modal.table.mac"),
                  searchable: "",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchable",
                    fn: function (loggers) {
                      return [
                        _c("b-input", {
                          attrs: { placeholder: _vm.$t("generals.search") },
                          model: {
                            value: loggers.filters[loggers.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                loggers.filters,
                                loggers.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "loggers.filters[loggers.column.field]",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (loggers) {
                      return [
                        _vm._v(" " + _vm._s(loggers.row.macAddress) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "rssi",
                  label: _vm.$t("component.logger.modal.table.signal"),
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (loggers) {
                      return [
                        _c("div", { staticClass: "is-flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "signal-strength mr-2",
                              class: _vm.getClassBasedOnRssi(loggers.row.rssi),
                            },
                            [
                              _c("div", { staticClass: "signal-bar" }),
                              _c("div", { staticClass: "signal-bar" }),
                              _c("div", { staticClass: "signal-bar" }),
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.logger.modal.table.dBm", {
                                  dBmValue: loggers.row.rssi.toString(),
                                })
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "lastSeenBefore",
                  label: _vm.$t("component.logger.modal.table.lastUpdate"),
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (loggers) {
                      return [
                        _vm._v(" " + _vm._s(loggers.row.lastSeenBefore) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "assigned",
                  label: _vm.$t("component.logger.modal.table.assigned"),
                  centered: "",
                  searchable: "",
                  sortable: "",
                  "custom-search": _vm.customSearch,
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchable",
                    fn: function (loggers) {
                      return [
                        _c(
                          "b-select",
                          {
                            attrs: { placeholder: _vm.$t("generals.search") },
                            model: {
                              value: loggers.filters[loggers.column.field],
                              callback: function ($$v) {
                                _vm.$set(
                                  loggers.filters,
                                  loggers.column.field,
                                  $$v
                                )
                              },
                              expression:
                                "loggers.filters[loggers.column.field]",
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                staticClass: "clear-filter-option",
                                domProps: { value: null },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t(`generals.clearFilter`)) +
                                    " "
                                ),
                              ]
                            ),
                            _c("option", { attrs: { value: "true" } }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("generals.yes")) + " "
                              ),
                            ]),
                            _c("option", { attrs: { value: "false" } }, [
                              _vm._v(" " + _vm._s(_vm.$t("generals.no")) + " "),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (loggers) {
                      return [
                        loggers.row.assigned
                          ? _c("b-icon", {
                              attrs: { type: "is-success", icon: "check" },
                            })
                          : _c("b-icon", {
                              attrs: { type: "is-danger", icon: "times" },
                            }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isModal
        ? _c(
            "button",
            {
              staticClass: "button is-primary is-pulled-right",
              attrs: {
                type: "button",
                disabled: _vm.loggers.length > 0 ? false : true,
              },
              on: {
                click: function ($event) {
                  return _vm.validateBeforeSubmit()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("component.logger.modal.add")) + " ")]
          )
        : _vm._e(),
      _vm.createRuleWarningVisible
        ? _c("CreateRuleWarning", {
            attrs: { sourceIds: _vm.createdSourcesIds },
            on: { warningClosed: _vm.warningClosed },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isConfirmModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeConfirmModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isConfirmModalActive = $event
            },
            "close-modal": _vm.closeConfirmModal,
          },
        },
        [
          _c("ConfirmCreateLoggerModal", {
            attrs: {
              loggesWithoutNameNO: _vm.checkedLoggers.filter((x) => !x.name)
                .length,
              loggesToAssignNO: _vm.checkedLoggers.filter((x) => !x.assigned)
                .length,
            },
            on: {
              modalClosed: _vm.closeConfirmModal,
              createSources: _vm.createSources,
              onlyActivate: _vm.onlyActivate,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }