import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import CreateOrEditPreset from '@/views/components/admin/notifications/CreateOrEditPreset.vue';
import AssignPreset from '@/views/components/admin/notifications/AssignPreset.vue';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import VueUtilities from '@/services/VueUtilities';
import UserRepository from '@/services/repository/UserRepository';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import { SystemUserRoles } from '@/entities/enums/UserRoles';
var notificationSubscriptionsRepository;
var userRepository;
var departmentRepository;
let AdminPresetsList = class AdminPresetsList extends Vue {
    constructor() {
        super(...arguments);
        this.isCreateModalActive = false;
        this.isAssignModalActive = false;
        this.isEditModalActive = false;
        this.isLoading = false;
        this.presetToEdit = null;
        this.departmentUsers = [];
        this.userDepartments = [];
        this.hasAdminOrResellerRole = false;
    }
    created() {
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        userRepository = new UserRepository(this);
        departmentRepository = new DepartmentRepository(this);
    }
    async loadDepartmentUsers(companyId) {
        this.isLoading = true;
        this.departmentUsers = companyId.length > 0 ? await userRepository.getCompanyUsers(companyId) : [];
        this.isLoading = false;
    }
    async loadUserDepartments(userId) {
        this.isLoading = true;
        this.hasAdminOrResellerRole = false;
        if (userId.length > 0)
            departmentRepository.getAllDepartmentsForUserAdmin(userId).then(async (departments) => {
                let systemAdminDepartments = departments.filter((x) => x.Member.Role.Key === SystemUserRoles.SYSTEM_ADMIN);
                let resellerDepartments = departments.filter((x) => x.Member.Role.Key === SystemUserRoles.RESELLER_ADMIN);
                if (systemAdminDepartments.length > 0 || resellerDepartments.length > 0) {
                    this.hasAdminOrResellerRole = true;
                }
                if (this.hasAdminOrResellerRole)
                    this.userDepartments = this.departments;
                else
                    this.userDepartments = departments.map((dep) => dep.Department);
            });
        else
            this.userDepartments = [];
        this.isLoading = false;
    }
    closeAssignModal(val) {
        this.isAssignModalActive = false;
        if (val == true)
            this.$emit('reloadPresets');
    }
    closeCreateModal(val) {
        this.isCreateModalActive = false;
        if (val == true)
            this.$emit('reloadPresets');
    }
    closeEditPresetModal(val) {
        this.isEditModalActive = false;
        if (val == true)
            this.$emit('reloadPresets');
    }
    closeEditModal(val) {
        this.isEditModalActive = false;
        this.presetToEdit = null;
        if (val == true)
            this.$emit('reloadPresets');
    }
    createPreset() {
        this.isCreateModalActive = true;
    }
    assignPreset() {
        this.isAssignModalActive = true;
    }
    confirmPresetDelete(preset) {
        this.$buefy.dialog.confirm({
            title: this.$t('component.notification.presets.delete_preset_dialog.title'),
            message: this.$t('component.notification.presets.delete_preset_dialog.message', { name: preset.name }),
            confirmText: this.$t('component.notification.presets.delete_preset_dialog.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let resp = await notificationSubscriptionsRepository.adminDeleteNotificationPreset(preset.id);
                if (resp === true) {
                    VueUtilities.openSuccessToast(this, this.$t('component.notification.presets.delete_success'));
                    this.$emit('reloadPresets');
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.notification.presets.delete_failure'));
                }
            }
        });
    }
    async editPreset(preset) {
        this.isLoading = true;
        //Load preset with subscriptions
        let response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(preset.id);
        //handle error response
        if (typeof response == 'string') {
            VueUtilities.openErrorToast(this, response);
        }
        else {
            this.presetToEdit = response;
        }
        this.isLoading = false;
        this.isEditModalActive = true;
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], AdminPresetsList.prototype, "presets", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AdminPresetsList.prototype, "departments", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AdminPresetsList.prototype, "users", void 0);
AdminPresetsList = __decorate([
    Component({ components: { AssignPreset, CreateOrEditPreset } })
], AdminPresetsList);
export default AdminPresetsList;
