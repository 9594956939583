import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
import VueUtilities from '@/services/VueUtilities';
import AppConfig from '@/configLoader';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminResellerDepartmentsComponent = class AdminResellerDepartmentsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.Action = Action;
        this.selectedValue = '';
        this.isNameSearch = true;
        this.isTierSearch = false;
        this.isIdSearch = false;
        this.isIcoSearch = false;
        this.idSearch = '';
        this.nameSearch = '';
        this.icoSearch = '';
        this.tierSearch = '';
        this.sort = null;
        this.pagination = null;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.currentFilter = [];
        this.tableQueries = null;
        this.urlQueriesLoaded = false;
        this.tableColumns = {
            companyId: {
                field: 'companyId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyId),
                hasSearch: true
            },
            companyName: {
                field: 'companyName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyName),
                hasSearch: true
            },
            ico: {
                field: 'ico',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListIco),
                hasSearch: true
            },
            tierName: {
                field: 'tierName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListTierName),
                hasSearch: true
            },
            userCount: {
                field: 'userCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListUserCount),
                hasSearch: false
            },
            deviceCount: {
                field: 'deviceCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListDeviceCount),
                hasSearch: false
            },
            loggerCount: {
                field: 'loggerCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListLoggerCount),
                hasSearch: false
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCreatedAt),
                hasSearch: false
            }
        };
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    get hasCopyIconAllowed() {
        return AppConfig.getConfig().features.copy_to_clipboard_btn == true;
    }
    loadDepartments() {
        if (this.departmentsLoaded) {
            if (!this.urlQueriesLoaded) {
                this.loadQueries();
            }
            this.$emit('loadResellerDepartments', this.pagination, this.sort, this.currentFilter);
        }
    }
    async created() {
        this.selectedValue = this.tableColumns.companyName.field;
        this.loadQueries();
        if (this.departmentsLoaded && this.departments.length == 0) {
            this.$emit('loadResellerDepartments', this.pagination, this.sort, this.currentFilter);
        }
    }
    loadQueries() {
        this.fillFilterFromRoute();
        this.fillSearches();
        this.urlQueriesLoaded = true;
    }
    fillFilterFromRoute() {
        try {
            let tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminCompany);
            this.sortField = tableQueriesfromUrl.field;
            this.sortDirection = tableQueriesfromUrl.direction;
            this.page = tableQueriesfromUrl.page;
            this.pageSize = tableQueriesfromUrl.pageSize;
            this.sort = {
                field: this.sortField,
                direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
            };
            this.pagination = { page: this.page, pageSize: this.pageSize };
            this.currentFilter = JSON.parse(tableQueriesfromUrl.filter);
            this.changeRouterUrl(this.pagination, this.sort, this.currentFilter);
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminCompanySortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminCompanySortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminCompanyPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminCompanyRowsPerPage.defaultValue;
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
    }
    fillSearches() {
        if (this.currentFilter.length !== 0) {
            this.idSearch =
                this.filterExists('CompanyId==') !== -1
                    ? this.currentFilter[this.filterExists('CompanyId==')].substring(11)
                    : '';
            this.nameSearch =
                this.filterExists('CompanyName@=') !== -1
                    ? this.currentFilter[this.filterExists('CompanyName@=')].substring(13)
                    : '';
            this.icoSearch =
                this.filterExists('Ico@=') !== -1 ? this.currentFilter[this.filterExists('Ico@=')].substring(5) : '';
            this.tierSearch =
                this.filterExists('TierName@=') !== -1 ? this.currentFilter[this.filterExists('TierName@=')].substring(10) : '';
        }
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange();
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange();
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange();
    }
    onOptionsChange() {
        this.changeRouterUrl(this.pagination, this.sort, this.currentFilter);
        this.$emit('loadResellerDepartments', this.pagination, this.sort, this.currentFilter);
    }
    filterTable(searchField, value) {
        switch (searchField) {
            case this.tableColumns.companyId.field:
                this.replaceFilter('CompanyId==', value);
                break;
            case this.tableColumns.companyName.field:
                this.replaceFilter('CompanyName@=', value);
                break;
            case this.tableColumns.ico.field:
                this.replaceFilter('Ico@=', value);
                break;
            case this.tableColumns.tierName.field:
                this.replaceFilter('TierName@=', value);
                break;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.onOptionsChange();
            }
        });
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value) {
                this.currentFilter.splice(index, 1);
            }
            else {
                this.currentFilter[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilter.push(filter + value);
    }
    actionSelect(department) {
        this.$emit('unassignDepartment', department, this.pagination, this.sort, this.currentFilter);
    }
    clearFilter() {
        this.idSearch = '';
        this.nameSearch = '';
        this.icoSearch = '';
        this.tierSearch = '';
        this.currentFilter = [];
        this.onPageChange(1);
    }
    changeRouterUrl(pagination, sort, currentFilter) {
        this.tableQueries = {
            field: sort.field,
            direction: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            pageSize: pagination.pageSize,
            filter: JSON.stringify(currentFilter.filter((x) => x != null))
        };
        let objString = JSON.stringify(this.tableQueries);
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                adminCompany: objString
            })
        })
            .catch((err) => err);
    }
    changeSearchField(event) {
        this.isTierSearch = false;
        this.isNameSearch = false;
        this.isIdSearch = false;
        this.isIcoSearch = false;
        this.clearFilter();
        if (event == this.tableColumns.companyName.field) {
            this.isNameSearch = true;
        }
        else if (event == this.tableColumns.tierName.field) {
            this.isTierSearch = true;
        }
        else if (event == this.tableColumns.companyId.field) {
            this.isIdSearch = true;
        }
        else if (event == this.tableColumns.ico.field) {
            this.isIcoSearch = true;
        }
    }
    copyLinkToClipboard(id) {
        try {
            navigator.clipboard.writeText(id);
            VueUtilities.openSuccessToast(this, this.$t('generals.copy_success'));
        }
        catch (err) {
            VueUtilities.openErrorToast(this, this.$t('generals.copy_failure'));
        }
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.companyName.field;
        this.changeSearchField(this.tableColumns.companyName.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.companyId.field:
                if (!event) {
                    this.$refs.table.filters.companyId = '';
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.companyId.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyId, event);
                break;
            case this.tableColumns.companyName.field:
                if (!event) {
                    this.$refs.table.filters.companyName = '';
                    this.changeDefaultField();
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyName, event);
                break;
            case this.tableColumns.ico.field:
                if (!event) {
                    this.$refs.table.filters.ico = '';
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.ico.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListIco, event);
                break;
            case this.tableColumns.tierName.field:
                if (!event) {
                    this.$refs.table.filters.tierName = '';
                    this.changeDefaultField();
                    if (this.sort.field == this.tableColumns.tierName.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListTierName, event);
                break;
            case this.tableColumns.userCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.userCount.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListUserCount, event);
                break;
            case this.tableColumns.deviceCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.deviceCount.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListDeviceCount, event);
                break;
            case this.tableColumns.loggerCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.loggerCount.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListLoggerCount, event);
                break;
            case this.tableColumns.createdAt.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.createdAt.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCreatedAt, event);
                break;
        }
        this.onOptionsChange();
    }
};
__decorate([
    Prop({ type: Array })
], AdminResellerDepartmentsComponent.prototype, "departments", void 0);
__decorate([
    Prop({ type: Object })
], AdminResellerDepartmentsComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminResellerDepartmentsComponent.prototype, "isLayoutList", void 0);
__decorate([
    Prop({ type: Number })
], AdminResellerDepartmentsComponent.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminResellerDepartmentsComponent.prototype, "departmentsLoaded", void 0);
__decorate([
    Watch('departmentsLoaded')
], AdminResellerDepartmentsComponent.prototype, "loadDepartments", null);
AdminResellerDepartmentsComponent = __decorate([
    Component({})
], AdminResellerDepartmentsComponent);
export default AdminResellerDepartmentsComponent;
var Action;
(function (Action) {
    Action["REMOVE"] = "remove";
})(Action || (Action = {}));
