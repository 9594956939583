import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let IssueNotifications = class IssueNotifications extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.total = 0;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.tableColumns = {
            fullName: {
                field: 'fullName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IssueNotificationsFullName)
            },
            email: {
                field: 'email',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IssueNotificationsEmail)
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IssueNotificationsCreatedAt)
            },
            channel: {
                field: 'channel',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IssueNotificationsChannel)
            }
        };
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    created() {
        this.total = this.issueNotifications.length;
        this.fillFilterFromRoute();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.issueNotifications);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.count;
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.IssueNotificationsSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.IssueNotificationsSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.IssueNotificationsPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.IssueNotificationsRowsPerPage.defaultValue;
    }
    // changing default sort field
    changeDefaultSortField(sortFieldName) {
        if (this.sort.field == sortFieldName) {
            this.sort.field = this.tableColumns.fullName.field;
            this.sortField = this.sort.field;
        }
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.fullName.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.fullName.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IssueNotificationsFullName, event);
                break;
            case this.tableColumns.email.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.email.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IssueNotificationsEmail, event);
                break;
            case this.tableColumns.createdAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.createdAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IssueNotificationsCreatedAt, event);
                break;
            case this.tableColumns.channel.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.channel.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IssueNotificationsChannel, event);
                break;
        }
        this.$nextTick(() => {
            this.$refs.table.initSort();
        });
    }
    onSort(field, order) {
        this.sortField = field;
        this.sortDirection = order;
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.changeRouterUrl(this.pagination, this.sort);
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.changeRouterUrl(this.pagination, this.sort);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.changeRouterUrl(this.pagination, this.sort);
    }
    changeRouterUrl(pagination, sort) {
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize
        };
        let objString = JSON.stringify(this.tableQueries);
        this.$emit('changeRouterUrl', objString);
    }
};
__decorate([
    Prop({ type: Object })
], IssueNotifications.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], IssueNotifications.prototype, "issueNotifications", void 0);
IssueNotifications = __decorate([
    Component({ components: {} })
], IssueNotifications);
export default IssueNotifications;
