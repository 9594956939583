import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import LoggedUserManager from '@/services/LoggedUserManager';
import UserRepository from '@/services/repository/UserRepository';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import MeasuredDataNotification from '@/views/components/notification/configurationPerType/MeasuredDataNotification.vue';
import EntityMeasuredDataNotification from '@/views/components/notification/configurationPerType/EntityMeasuredDataNotification.vue';
import GeneralNotificationComponent from '@/views/components/notification/configurationPerType/GeneralNotificationComponent.vue';
import NonWorkingHours from '@/views/components/notification/NonWorkingHours.vue';
import Timetable from '@/views/components/notification/Timetable.vue';
import NotificationSubscriptionCategory from '@/entities/notifications/NotificationSubscriptionCategory';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import NotificationTabs from '@/entities/notifications/NotificationTabs';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import EventValidationRepository from '@/services/repository/EventValidationRepository';
import ValidationRuleScope from '@/entities/notifications/ValidationRuleScope';
import VueUtilities from '@/services/VueUtilities';
import TestNotificationModal from '@/views/components/notification/TestNotificationModal.vue';
import OtherNotificationSettings from '@/views/components/notification/OtherNotificationSettings.vue';
import { DepartmentSettingsKeys } from '@/entities/models/DepartmentSettings';
import PauseNotificationsComponent from '@/views/components/notification/PauseNotificationsComponent.vue';
import DateTimeManager from '@/services/DateTimeManager';
import { vxm } from '@/store/store.vuex';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import NotificationScheduleType from '@/entities/notifications/NotificationScheduleType';
import TiersRepository from '@/services/repository/TiersRepository';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import UnsavedChangesDialog from '@/views/components/common/UnsavedChangesDialog.vue';
var notificationSubscriptionsRepository;
var userRepository;
var departmentRepository;
var userSettingsRepository;
var eventsValidationRepository;
var tiersRepository;
var vxDepartmentStore = vxm.departmentStore;
let NotificationsConfiguration = class NotificationsConfiguration extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.NotificationTabs = NotificationTabs;
        this.isLoading = true;
        this.isChangingMode = false;
        this.currentUser = null;
        this.notificationSubscriptions = [];
        this.availableTypes = [];
        this.selectedMeasuredRulesMode = null;
        this.isTestNotificationModalActive = false;
        this.departmentSettings = null;
        this.pauseNotificationsUntil = 0;
        this.dateTimeManager = null;
        this.presets = [];
        this.selectedPreset = null;
        this.activeTab = NotificationTabs.GENERAL;
        this.schedules = [];
        this.departmentUserSettings = null;
        this.timetableEnabled = false;
        this.defaultScheduleId = null;
        this.defaultSchedule = null;
        this.subscriptionsToCreate = [];
        this.haveChangesToSave = false;
        this.isUnsavedChangesModalActive = false;
        this.routeToNavigateOnExit = null;
        this.tier = null;
        this.SELECTED_DEPARTMENT_ID = null;
        this.timetableSchedules = [];
        this.measuredRuleEnabled = null;
    }
    get isAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    get singleMeasuredRule() {
        return this.notificationSubscriptions?.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredRules() {
        return (this.notificationSubscriptions?.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE) || []);
    }
    get measuredTypes() {
        return this.availableTypes.filter((x) => x.category == NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get systemRules() {
        return (this.notificationSubscriptions?.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.SYSTEM_RULE) || []);
    }
    get hasRulesForEntities() {
        return this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.SOURCE_OR_GROUP;
    }
    get notificationsPaused() {
        let now = Math.floor(new Date().getTime() / 1000);
        return this.pauseNotificationsUntil > now;
    }
    get selectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    get hasPresetSelected() {
        return this.selectedPreset != null;
    }
    get nonWorkingSchedules() {
        return this.schedules.filter((x) => x.type == NotificationScheduleType.SPECIAL_DAYS);
    }
    async created() {
        //load active tab from url
        this.activeTab = this.$route.query.activeTab || NotificationTabs.GENERAL;
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        userRepository = new UserRepository(this);
        departmentRepository = new DepartmentRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        eventsValidationRepository = new EventValidationRepository(this);
        tiersRepository = new TiersRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.SELECTED_DEPARTMENT_ID = this.selectedDepartmentId;
        this.subscriptionsToCreate = [];
        this.currentUser = await userRepository.getCurrentUser();
        let allPromises = [];
        allPromises.push(notificationSubscriptionsRepository.getNotificationTypes());
        allPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.currentUser.apiUserId, true, this.selectedDepartmentId));
        allPromises.push(departmentRepository.getDepartment());
        allPromises.push(notificationSubscriptionsRepository.getNotificationPresets(this.currentUser.apiUserId));
        allPromises.push(tiersRepository.getActiveTier());
        allPromises.push(notificationSubscriptionsRepository.getNotificationSchedules(this.currentUser.apiUserId, this.selectedDepartmentId));
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3], response[4], response[5]);
        });
    }
    async reloadSchedules() {
        this.isLoading = true;
        this.schedules = await notificationSubscriptionsRepository.getNotificationSchedules(this.currentUser.apiUserId, this.selectedDepartmentId);
        this.departmentUserSettings = await userSettingsRepository.loadUserSettingsForDepartment(this.currentUser.apiUserId, true, this.selectedDepartmentId);
        this.timetableSchedules = this.schedules.filter((x) => x.type === NotificationScheduleType.TIMETABLE);
        this.isLoading = false;
    }
    async processLoadedDataForPage(types, departmentUserSettings, department, presets, tier, schedules) {
        let timetableMode = departmentUserSettings.notifications.schedule.mode;
        if (timetableMode === NotificationScheduleType.TIMETABLE) {
            this.timetableEnabled = true;
            this.notificationSubscriptions = [];
            this.timetableSchedules = schedules.filter((x) => x.type === NotificationScheduleType.TIMETABLE);
        }
        else {
            this.timetableEnabled = false;
            this.defaultSchedule = schedules.find((x) => x.type === NotificationScheduleType.DEFAULT);
            this.defaultScheduleId = this.defaultSchedule?.id;
            this.notificationSubscriptions = this.defaultSchedule?.subscriptions || [];
            this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
        }
        this.availableTypes = types;
        this.selectedMeasuredRulesMode =
            departmentUserSettings.notifications.rules.mode ||
                NotificationMeasuredRuleScope.COMPANY;
        this.departmentSettings = department.settings;
        this.pauseNotificationsUntil = parseInt(departmentUserSettings.notifications.pause.until);
        this.presets = presets;
        this.tier = tier;
        this.schedules = schedules;
        this.departmentUserSettings = departmentUserSettings;
        this.isLoading = false;
    }
    getMeasuredRuleEnabled() {
        return this.singleMeasuredRule ? this.singleMeasuredRule.enabled : false;
    }
    switchedValue(event) {
        this.measuredRuleEnabled = event;
    }
    getRule(name) {
        return this.systemRules.find((x) => x.notificationType === name);
    }
    getType(name) {
        return this.availableTypes.find((x) => x.name === name);
    }
    //triggered when measured rules mode is changed
    async changeMode(selectedMode) {
        this.measuredRuleEnabled = true;
        this.isChangingMode = true;
        this.selectedMeasuredRulesMode = selectedMode;
        this.subscriptionsToCreate = this.subscriptionsToCreate.filter((x) => x.NotificationType !== NotificationSubscriptionType.MEASURED_DATA_ALARM &&
            x.NotificationType !== NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
        this.isChangingMode = false;
    }
    openTestNotificationModal() {
        this.isTestNotificationModalActive = true;
    }
    closeTestNotificationModal() {
        this.isTestNotificationModalActive = false;
    }
    async sendTestNotification(value) {
        let res = false;
        if (value == ValidationRuleScope.SOURCE) {
            res = await eventsValidationRepository.testSingleNotification(this.currentUser.apiUserId);
        }
        else if (value == ValidationRuleScope.SOURCE_GROUP) {
            res = await eventsValidationRepository.testGroupNotification(this.currentUser.apiUserId);
        }
        if (res !== true) {
            VueUtilities.openErrorToast(this, (this.$t('component.notification.settings.test_notification_send_failure') + res));
        }
        else {
            VueUtilities.openSuccessToast(this, this.$t('component.notification.settings.test_notification_send'));
        }
    }
    async updateOptimizeNotificationsSetting(value) {
        this.departmentSettings = await departmentRepository.saveDepartmentSetting([DepartmentSettingsKeys.SOURCE, DepartmentSettingsKeys.DO_NOT_NOTIFY], value);
    }
    async applyPreset(preset) {
        this.selectedPreset = preset;
        this.isLoading = true;
        this.notificationSubscriptions = [];
        this.selectedPreset.subscriptions.forEach((subs) => {
            this.notificationSubscriptions.push(NotificationSubscription.convertFromPreset(subs, this.selectedDepartmentId));
        });
        this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
        this.$nextTick(() => {
            this.isLoading = false;
        });
    }
    tabChanged(tab) {
        //set active tab in url;
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: tab
            })
        })
            .catch((err) => err);
    }
    async onEnableTimetable() {
        this.isLoading = true;
        await notificationSubscriptionsRepository.changeTimetableMode(this.currentUser.apiUserId, this.selectedDepartmentId);
        VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.use_timetable_success'));
        this.loadData();
    }
    async saveDefault() {
        let errors = [];
        this.isLoading = true;
        //check if preset is used
        //check if preset was selected and compare current subscriptions with preset
        if (this.selectedPreset) {
            let presetIsSameAsSubs = this.selectedPreset.comparePresetWithSubscriptions(this.subscriptionsToCreate);
            //if subscriptions are same, set preset id
            if (presetIsSameAsSubs == true) {
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = this.selectedPreset.id;
                });
            }
            else {
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = null;
                });
            }
        }
        else {
            // if subscriptions are not same, check if there was some preset defined before and if yes,
            //compare subscriptions from this preset (if exists), otherwise set presetId to null
            let presetIds = [...new Set(this.subscriptionsToCreate.map((x) => x.PresetId))];
            if (presetIds.length === 1 && presetIds[0] != null) {
                let loadedPreset = this.presets.find((x) => x.id === presetIds[0]);
                if (!loadedPreset) {
                    //if preset does not exists
                    this.subscriptionsToCreate.forEach((sub) => {
                        sub.PresetId = null;
                    });
                }
                else {
                    //if preset exists, compare subscriptions
                    let presetIsSameAsSubs = loadedPreset.comparePresetWithSubscriptions(this.subscriptionsToCreate);
                    if (presetIsSameAsSubs == true) {
                        //if subs are same
                        this.subscriptionsToCreate.forEach((sub) => {
                            sub.PresetId = loadedPreset.id;
                        });
                    }
                    else {
                        //if not, set preset id to null
                        this.subscriptionsToCreate.forEach((sub) => {
                            sub.PresetId = null;
                        });
                    }
                }
            }
            else {
                //if there is multiple presets in subscriptions or is null
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = null;
                });
            }
        }
        let promises = [];
        //check if type of measured rule was updated
        if (this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.COMPANY) {
            let measuredForEntity = this.defaultSchedule?.subscriptions?.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                [NotificationScopeType.SOURCE, NotificationScopeType.SOURCE_GROUP].includes(x.scopeType)) || [];
            measuredForEntity.forEach((subToDel) => {
                promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.currentUser.apiUserId, this.selectedDepartmentId));
            });
        }
        else if (this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.SOURCE_OR_GROUP) {
            let measuredForCompany = this.defaultSchedule?.subscriptions?.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                x.scopeType === NotificationScopeType.COMPANY) || [];
            measuredForCompany.forEach((subToDel) => {
                promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.currentUser.apiUserId, this.selectedDepartmentId));
            });
        }
        //update or create subscription
        let rulesToCreate = [];
        this.subscriptionsToCreate.forEach((subs) => {
            let idOfExisting = null;
            if (this.availableTypes.find((x) => x.name === subs.NotificationType).category ==
                NotificationSubscriptionCategory.MEASUREMENT_RULE) {
                idOfExisting = this.defaultSchedule?.subscriptions?.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                    x.scopeType === subs.ScopeType &&
                    x.scopeId === subs.ScopeId)?.notificationSubscriptionId;
            }
            else {
                idOfExisting = this.defaultSchedule?.subscriptions?.find((x) => x.notificationType === subs.NotificationType)?.notificationSubscriptionId;
            }
            if (idOfExisting) {
                let subsUpdateDto = {
                    Channels: subs.Channels,
                    NotificationType: subs.NotificationType,
                    PresetId: subs.PresetId,
                    Priority: subs.Priority,
                    DisplayMode: subs.DisplayMode,
                    Enabled: subs.Enabled
                };
                promises.push(notificationSubscriptionsRepository.updateNotificationSubscription(idOfExisting, subsUpdateDto, this.currentUser.apiUserId, this.selectedDepartmentId));
            }
            else {
                let newRule = {
                    ScopeType: subs.ScopeType,
                    ScopeId: subs.ScopeId,
                    NotificationType: subs.NotificationType,
                    Enabled: subs.Enabled,
                    Channels: subs.Channels,
                    DisplayMode: subs.DisplayMode,
                    PresetId: subs.PresetId
                };
                rulesToCreate.push(newRule);
            }
        });
        if (rulesToCreate.length > 0) {
            promises.push(notificationSubscriptionsRepository.createNotificationSubscription(rulesToCreate, this.currentUser.apiUserId, this.selectedDepartmentId, null));
        }
        //check responses
        await Promise.all(promises).then((response) => {
            errors = response.filter((x) => typeof x === 'string');
        });
        if (errors.length <= 0) {
            await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.RULES, UserSettingsKeys.MODE], this.selectedMeasuredRulesMode);
        }
        if (errors.length <= 0) {
            VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.non_working_list.success'));
            this.loadData();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('notifications.configuration.non_working_list.failure', {
                error: errors.join(', ')
            }));
            this.isLoading = false;
        }
        this.isUnsavedChangesModalActive = false;
    }
    //triggered when some subscriptions is changed
    ruleChanged(newRule) {
        if (newRule.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            newRule.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
            let indexOfMeasured = this.subscriptionsToCreate.findIndex((x) => (x.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                x.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) &&
                x.ScopeId === newRule.ScopeId);
            if (indexOfMeasured >= 0) {
                //remove this measured rule by index
                this.subscriptionsToCreate.splice(indexOfMeasured, 1);
            }
        }
        else {
            //remove current subscription from list
            this.subscriptionsToCreate = this.subscriptionsToCreate.filter((x) => x.NotificationType !== newRule.NotificationType);
        }
        this.subscriptionsToCreate.push(newRule);
        this.haveChangesToSave = !NotificationSubscription.compareSubscriptionsWithSubscriptionsCreateDto(this.defaultSchedule?.subscriptions || [], this.subscriptionsToCreate);
    }
    closeUnsavedChangesModal() {
        this.isUnsavedChangesModalActive = false;
    }
    saveUnsavedChanges() {
        this.closeUnsavedChangesModal();
        this.saveDefault();
    }
};
NotificationsConfiguration = __decorate([
    Component({
        beforeRouteUpdate(to, from, next) {
            if (this.SELECTED_DEPARTMENT_ID !=
                this.selectedDepartmentId) {
                this.loadData();
            }
            next();
        },
        beforeRouteLeave(to, from, next) {
            if (this.timetableEnabled === false) {
                if (this.haveChangesToSave === true && to.query.confirmed != 'true') {
                    this.routeToNavigateOnExit = to;
                    this.isUnsavedChangesModalActive = true;
                }
                else {
                    next();
                }
            }
            else {
                if (this.$refs.timetableComponent.haveChangesToSave === true && to.query.confirmed != 'true') {
                    this.$refs.timetableComponent.routeToNavigateOnExit = to;
                    this.$refs.timetableComponent.isUnsavedChangesModalActive = true;
                }
                else {
                    next();
                }
            }
        },
        components: {
            MeasuredDataNotification,
            GeneralNotificationComponent,
            EntityMeasuredDataNotification,
            TestNotificationModal,
            OtherNotificationSettings,
            PauseNotificationsComponent,
            NonWorkingHours,
            Timetable,
            UnsavedChangesDialog
        }
    })
], NotificationsConfiguration);
export default NotificationsConfiguration;
