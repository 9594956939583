var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "header",
        { staticClass: "modal-card-head" },
        [
          _c("b-icon", {
            staticClass: "mr-1",
            attrs: { icon: "hdd", size: "is-medium" },
          }),
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "admin.component.company.registerCompanyDevicesModal.title"
                  )
                ) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.company.registerCompanyDevicesModal.devices"
                ),
              },
            },
            [
              _c(
                "b-table",
                {
                  staticClass: "select-location-table border select-user-table",
                  attrs: {
                    data: _vm.adminDevices,
                    "checked-rows": _vm.selectedDevices,
                    paginated: true,
                    "backend-pagination": "",
                    "current-page": _vm.page,
                    loading: _vm.componentIsLoading,
                    "per-page": _vm.pageSize,
                    total: _vm.totalSize,
                    narrowed: "",
                    checkable: "",
                    "mobile-cards": false,
                    "backend-filtering": "",
                    "row-class": (row) =>
                      row.instanceState === _vm.DeviceInstanceState.INACTIVE &&
                      row.state === _vm.DeviceState.ACTIVATED
                        ? ""
                        : "is-disabled has-text-grey",
                    "is-row-checkable": (row) =>
                      row.instanceState === _vm.DeviceInstanceState.INACTIVE &&
                      row.state === _vm.DeviceState.ACTIVATED,
                    "custom-is-checked": (a, b) => {
                      return a.deviceId === b.deviceId
                    },
                  },
                  on: {
                    "update:checkedRows": function ($event) {
                      _vm.selectedDevices = $event
                    },
                    "update:checked-rows": function ($event) {
                      _vm.selectedDevices = $event
                    },
                    "update:currentPage": function ($event) {
                      _vm.page = $event
                    },
                    "update:current-page": function ($event) {
                      _vm.page = $event
                    },
                    "page-change": _vm.onPageChange,
                  },
                },
                [
                  _c("b-table-column", {
                    attrs: {
                      field: "SerialName",
                      label: _vm.$t(
                        "admin.component.company.registerCompanyDevicesModal.table.serialName"
                      ),
                      searchable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "searchable",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "buttons mb-1" },
                              [
                                _c("b-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("generals.search"),
                                    size: "is-small",
                                  },
                                  on: {
                                    input: _vm.handleFilterSerialNameDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterDevices(
                                        _vm.serialNameSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.serialNameSearch,
                                    callback: function ($$v) {
                                      _vm.serialNameSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "serialNameSearch",
                                  },
                                }),
                                _c("b-icon", {
                                  staticClass: "level-item clickable",
                                  attrs: { icon: "search" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.filterDevices(
                                        _vm.serialNameSearch
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (adminDevice) {
                          return [
                            _c("p", [
                              _vm._v(
                                " " + _vm._s(adminDevice.row.deviceName) + " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    attrs: {
                      field: "Company.CompanyName",
                      label: _vm.$t(
                        "admin.component.company.registerCompanyDevicesModal.table.companyName"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (adminDevice) {
                          return [
                            _c("p", [
                              adminDevice.row.company &&
                              adminDevice.row.company.companyName
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          adminDevice.row.company.companyName
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.company.registerCompanyDevicesModal.selectedDevices"
                ),
              },
            },
            [
              _c(
                "b-table",
                {
                  staticClass:
                    "select-location-table border select-user-table-list",
                  attrs: { data: _vm.selectedDevices, striped: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "empty",
                      fn: function () {
                        return [
                          _c("section", { staticClass: "section" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "content has-text-grey has-text-centered",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.company.registerCompanyDevicesModal.table.empty"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-table-column", {
                    attrs: {
                      field: "SerialName",
                      label: _vm.$t(
                        "admin.component.company.registerCompanyDevicesModal.table.serialName"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (adminDevice) {
                          return [
                            _c("p", [
                              _vm._v(
                                " " + _vm._s(adminDevice.row.deviceName) + " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.registerDevices()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "admin.component.company.registerCompanyDevicesModal.confirm"
                  )
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }