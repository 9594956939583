var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.location.detail.msg.file_download_info")
                    ) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("component.logger.list.title")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _vm.$ability.can(
                _vm.$permActions.MANAGE,
                _vm.$permSubjects.LOGGER
              ) &&
              _vm.$ability.can(
                _vm.$permActions.MANAGE,
                _vm.$permSubjects.DEVICE
              ) &&
              _vm.$featuresManager.isAvailable(_vm.$features.LOGGERS_IN_COMPANY)
                ? _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("component.logger.list.button_label"))
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.company.list.actions.columns"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    { staticStyle: { "font-size": "1rem" } },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "list",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.tableColumns, function (column) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: column.field,
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-checkbox",
                            {
                              attrs: {
                                disabled:
                                  column.field == _vm.tableColumns.name.field ||
                                  column.field ==
                                    _vm.tableColumns.serialNumber.field,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.chooseTableColumns(
                                    column.field,
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: column.visible,
                                callback: function ($$v) {
                                  _vm.$set(column, "visible", $$v)
                                },
                                expression: "column.visible",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      `component.logger.list.table.${column.field}`
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "div",
                    { staticClass: "ml-2" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            position: "is-bottom-left",
                            "append-to-body": "",
                            "aria-role": "list",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "trigger",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "admin.component.loggers.detail.actions.title"
                                          ),
                                          position: "is-left",
                                          type: "is-dark",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticStyle: {
                                              "font-size": "1rem",
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                type: "is-primary",
                                                icon: "ellipsis-v",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3412236272
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "custom-dropdown-item",
                              attrs: { "aria-role": "listitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeAllVisibility(false)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "level is-mobile" }, [
                                _c(
                                  "div",
                                  { staticClass: "level-left" },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "eye-slash" },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.logger.list.actions.hideAll"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "custom-dropdown-item",
                              attrs: { "aria-role": "listitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeAllVisibility(true)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "level is-mobile" }, [
                                _c(
                                  "div",
                                  { staticClass: "level-left" },
                                  [
                                    _c("b-icon", { attrs: { icon: "eye" } }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.logger.list.actions.showAll"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      !_vm.isLoading
        ? _c(
            "b-table",
            {
              ref: "table",
              staticClass: "table-wrap custom-card-space-mobile",
              attrs: {
                data: _vm.sourceList,
                paginated: "",
                "current-page": _vm.page,
                "per-page": _vm.pageSize,
                total: _vm.total,
                "default-sort-direction": _vm.sortDirection,
                "default-sort": _vm.sortField,
                striped: "",
                "page-input": true,
                "pagination-order": "is-centered",
              },
              on: {
                "update:currentPage": function ($event) {
                  _vm.page = $event
                },
                "update:current-page": function ($event) {
                  _vm.page = $event
                },
                "page-change": _vm.onPageChange,
                sort: _vm.onSort,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("section", { staticClass: "section" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.logger.list.table.empty")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "bottom-left",
                    fn: function () {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.notifications_list.table.row_per_page"
                              )
                            )
                          ),
                        ]),
                        _c(
                          "b-field",
                          { staticClass: "pl-2 mb-4-mobile" },
                          [
                            _c(
                              "b-select",
                              {
                                on: { input: _vm.rowsPerPageSelected },
                                model: {
                                  value: _vm.pageSize,
                                  callback: function ($$v) {
                                    _vm.pageSize = $$v
                                  },
                                  expression: "pageSize",
                                },
                              },
                              _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                                return _c(
                                  "option",
                                  { key: option, domProps: { value: option } },
                                  [_vm._v(" " + _vm._s(option) + " ")]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  _vm.sourceList && _vm.sourceList.length > 0
                    ? {
                        key: "footer",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "is-flex is-justify-content-flex-end",
                              },
                              [
                                _c("p", { staticClass: "pt-2 has-text-grey" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.company.list.table.results",
                                          {
                                            from: _vm.fromTableData,
                                            to: _vm.toTableData,
                                            total: _vm.total,
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.name.field,
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.name.field}`
                  ),
                  visible: _vm.tableColumns.name.visible,
                  sortable: _vm.tableColumns.name.visible,
                  width: "15%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          !_vm.inWizard
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "logger_detail",
                                      params: {
                                        locationId: props.row.id,
                                        departmentId:
                                          _vm.$route.params.departmentId,
                                        lang: _vm.$route.params.lang,
                                      },
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(props.row.name) + " ")]
                              )
                            : _c("p", [_vm._v(_vm._s(props.row.name))]),
                          props.row.logger &&
                          props.row.logger.state === _vm.ServiceState.ERROR
                            ? _c(
                                "b-tag",
                                { attrs: { rounded: "", type: "is-danger" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("loggers.logger_states.Error")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          props.row.activeService
                            ? _c(
                                "b-tag",
                                { attrs: { rounded: "", type: "is-info" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("loggers.logger_states.Service")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1753136693
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "logger.serialNumber",
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.serialNumber.field}`
                  ),
                  visible: _vm.tableColumns.serialNumber.visible,
                  sortable: _vm.tableColumns.serialNumber.visible,
                  width: "10%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.row.logger
                                  ? props.row.logger.serialNumber
                                  : ""
                              ) +
                              " "
                          ),
                          props.row.logger && !props.row.logger.active
                            ? _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "component.logger.overview.deactivated_logger"
                                    ),
                                    position: _vm.isMobileView
                                      ? "is-left"
                                      : "is-top",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "ml-1",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      icon: "exclamation-triangle",
                                      type: "is-danger",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2494845078
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.description.field,
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.description.field}`
                  ),
                  visible: _vm.tableColumns.description.visible,
                  sortable: _vm.tableColumns.description.visible,
                  width: "20%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("p", { staticClass: "description" }, [
                            _vm._v(_vm._s(props.row.description)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2514484412
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.lastSeen.field,
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.lastSeen.field}`
                  ),
                  visible: _vm.tableColumns.lastSeen.visible,
                  sortable: _vm.tableColumns.lastSeen.visible,
                  width: "15%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.dateTimeManager.formatTime(
                                new Date(props.row.lastSeen * 1000),
                                null,
                                null,
                                null
                              )
                            )
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4082372116
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "logger.battery",
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.battery.field}`
                  ),
                  visible: _vm.tableColumns.battery.visible,
                  sortable: _vm.tableColumns.battery.visible,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.logger && props.row.logger.battery != null
                            ? _c(
                                "div",
                                {
                                  staticClass: "is-flex is-align-items-center",
                                },
                                [
                                  props.row.logger.hasLowBattery()
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-dark",
                                            label: _vm.$t(
                                              "component.dashboard.status.batteryStatus.criticalTooltip"
                                            ),
                                            position: _vm.isMobileView
                                              ? "is-left"
                                              : "is-top",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "is-size-5",
                                            attrs: {
                                              icon: "battery-quarter",
                                              type: "is-primary",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-dark",
                                            label: _vm.$t(
                                              "component.dashboard.status.batteryStatus.okTooltip"
                                            ),
                                            position: _vm.isMobileView
                                              ? "is-left"
                                              : "is-top",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "is-size-5",
                                            attrs: {
                                              icon: "battery-full",
                                              type: "is-success",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              )
                            : _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    type: "is-dark",
                                    label: _vm.$t(
                                      "component.dashboard.status.batteryStatus.unknownTooltip"
                                    ),
                                    position: _vm.isMobileView
                                      ? "is-left"
                                      : "is-top",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "is-size-5",
                                    attrs: {
                                      icon: "battery-empty",
                                      type: "is-grey",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1505005642
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "logger.isOnline",
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.isOnline.field}`
                  ),
                  visible: _vm.tableColumns.isOnline.visible,
                  sortable: _vm.tableColumns.isOnline.visible,
                  "custom-sort": _vm.sortByIsOnline,
                  width: "10%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            { staticClass: "is-flex is-align-items-center" },
                            [
                              _c("b-icon", {
                                staticClass: "is-size-7",
                                attrs: {
                                  type:
                                    props.row.logger &&
                                    props.row.logger.isOnline == true
                                      ? "is-success"
                                      : "is-danger",
                                  icon: "circle",
                                },
                              }),
                              props.row.logger &&
                              props.row.logger.isOnline == true
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("generals.online")) + " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("generals.offline")) + " "
                                    ),
                                  ]),
                              _c(
                                "b-tooltip",
                                {
                                  staticClass: "is-hidden-tablet",
                                  attrs: {
                                    type: "is-light",
                                    label:
                                      _vm.$t(
                                        "component.logger.list.table.paused_measurement"
                                      ) +
                                      (props.row.pausedAt
                                        ? " " +
                                          _vm.$t(
                                            "component.logger.list.table.paused_measurement_from",
                                            {
                                              pausedAt:
                                                _vm.dateTimeManager.formatTime(
                                                  new Date(
                                                    props.row.pausedAt * 1000
                                                  )
                                                ),
                                            }
                                          )
                                        : ""),
                                    position: "is-left",
                                    multilined: "",
                                  },
                                },
                                [
                                  props.row.pausedMeasurement
                                    ? _c("b-icon", {
                                        attrs: {
                                          icon: "fas fa-pause-circle",
                                          type: "is-danger",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-tooltip",
                                {
                                  staticClass: "is-hidden-mobile",
                                  attrs: {
                                    type: "is-light",
                                    label:
                                      _vm.$t(
                                        "component.logger.list.table.paused_measurement"
                                      ) +
                                      (props.row.pausedAt
                                        ? " " +
                                          _vm.$t(
                                            "component.logger.list.table.paused_measurement_from",
                                            {
                                              pausedAt:
                                                _vm.dateTimeManager.formatTime(
                                                  new Date(
                                                    props.row.pausedAt * 1000
                                                  )
                                                ),
                                            }
                                          )
                                        : ""),
                                    position: "is-right",
                                    multilined: "",
                                  },
                                },
                                [
                                  props.row.pausedMeasurement
                                    ? _c("b-icon", {
                                        attrs: {
                                          icon: "fas fa-pause-circle",
                                          type: "is-danger",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2806675356
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "logger.calibration.status.name",
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.calibration.field}`
                  ),
                  visible: _vm.tableColumns.calibration.visible,
                  sortable: _vm.tableColumns.calibration.visible,
                  width: "10%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "is-flex is-align-items-center ml-3-mobile",
                            },
                            [
                              props.row.logger && props.row.logger.calibration
                                ? _c(
                                    "b-tag",
                                    {
                                      attrs: {
                                        rounded: "",
                                        type: props.row.logger.calibration
                                          .status.type,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            `calibration_status.${props.row.logger.calibration.status.name}`
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "b-tag",
                                    {
                                      attrs: {
                                        type: _vm.CalibrationStatus.UNKNOWN
                                          .type,
                                        rounded: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            `calibration_status.${_vm.CalibrationStatus.UNKNOWN.name}`
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                              _vm.$ability.can(
                                _vm.$permActions.READ,
                                _vm.$permSubjects.LOGGER
                              ) &&
                              _vm.hasCalibrationProtocolToDownload(props.row)
                                ? [
                                    _c(
                                      "b-tooltip",
                                      {
                                        staticClass: "is-hidden-mobile",
                                        attrs: {
                                          label: _vm.$t("generals.download"),
                                          position: "is-left",
                                          type: "is-light",
                                        },
                                      },
                                      [
                                        !_vm.isDemo
                                          ? _c("b-icon", {
                                              staticClass:
                                                "control-icon clickable",
                                              attrs: { icon: "file-download" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.downloadCalibration(
                                                    props.row.logger
                                                      .companyLoggerId
                                                  )
                                                },
                                              },
                                            })
                                          : _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "pdf.default",
                                                  download:
                                                    "Calibration Sample",
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  staticClass:
                                                    "control-icon clickable",
                                                  attrs: {
                                                    icon: "file-download",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "is-hidden-tablet" },
                                      [
                                        !_vm.isDemo
                                          ? _c("b-icon", {
                                              staticClass:
                                                "control-icon clickable",
                                              attrs: { icon: "file-download" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.downloadCalibration(
                                                    props.row.logger
                                                      .companyLoggerId
                                                  )
                                                },
                                              },
                                            })
                                          : _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "pdf.default",
                                                  download:
                                                    "Calibration Sample",
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  staticClass:
                                                    "control-icon clickable",
                                                  attrs: {
                                                    icon: "file-download",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2713106230
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "logger.macAddress",
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.macAddress.field}`
                  ),
                  visible: _vm.tableColumns.macAddress.visible,
                  sortable: _vm.tableColumns.macAddress.visible,
                  width: "15%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(
                              props.row.logger
                                ? props.row.logger.macAddress
                                : ""
                            )
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2271010443
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.visible.field,
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.visible.field}`
                  ),
                  visible:
                    _vm.tableColumns.visible.visible && _vm.showHiddenLoggers,
                  centered: "",
                  width: "5%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: props.row.visible
                                  ? _vm.$t(
                                      "component.logger.list.table.visible"
                                    )
                                  : _vm.$t(
                                      "component.logger.list.table.hidden"
                                    ),
                                type: "is-light",
                                position: "is-left",
                              },
                            },
                            [
                              _vm.isAdmin
                                ? _c("b-icon", {
                                    staticClass: "clickable",
                                    attrs: {
                                      icon: props.row.visible
                                        ? "eye"
                                        : "eye-slash",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.changeVisibility(props.row)
                                      },
                                    },
                                  })
                                : _c("b-icon", {
                                    attrs: {
                                      icon: props.row.visible
                                        ? "eye"
                                        : "eye-slash",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1195454292
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.LOGGER) &&
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DEVICE)
        ? _c(
            "b-modal",
            {
              class: _vm.isScannerModalActive ? "registerLoggersModal" : "",
              attrs: {
                active: _vm.isRegisterLoggersModalActive,
                "has-modal-card": "",
                "can-cancel": ["escape", "x"],
                onCancel: _vm.onModalClose,
                "full-screen": !_vm.isScannerModalActive,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isRegisterLoggersModalActive = $event
                },
              },
            },
            [
              _c("RegisterLoggersToDepartmentModal", {
                attrs: {
                  deviceId: _vm.deviceId,
                  sourceList: _vm.sourceList,
                  assignedLoggers: _vm.activeLoggers,
                  inWizard: _vm.inWizard,
                  dateTimeManager: _vm.dateTimeManager,
                },
                on: {
                  scannerModalActive: _vm.scannerModalActive,
                  "close-modal": _vm.onModalClose,
                  "reload-loggers": _vm.reloadLoggers,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }