var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
        },
        [
          _c("h2", { staticClass: "subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.settings.profile.user_info.title")) +
                " "
            ),
          ]),
          _c("b-button", {
            attrs: { "icon-right": "pencil-alt", type: "is-white" },
            on: {
              click: function ($event) {
                return _vm.editUser()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "section",
        [
          _c("div", { staticClass: "columns columns-info-form" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.settings.profile.user_info.email"
                      ),
                      type:
                        _vm.currentUser.detailedInfo.Verified == true
                          ? ""
                          : "is-danger",
                      message:
                        _vm.currentUser.detailedInfo.Verified == true
                          ? ""
                          : _vm.$t(
                              "component.settings.profile.user_info.account_not_verified"
                            ),
                    },
                  },
                  [
                    _vm.currentUser.detailedInfo.Verified == true
                      ? _c("p", [
                          _vm._v(" " + _vm._s(_vm.currentUser.email) + " "),
                        ])
                      : _c("b-input", {
                          attrs: { placeholder: _vm.currentUser.email },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openVerificationModal()
                            },
                          },
                          model: {
                            value: _vm.currentUser.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentUser, "email", $$v)
                            },
                            expression: "currentUser.email",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.settings.profile.user_info.username"
                      ),
                    },
                  },
                  [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.currentUser.username) + " "),
                    ]),
                  ]
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.settings.profile.user_info.fullname"
                      ),
                    },
                  },
                  [
                    !_vm.isEditing
                      ? _c("p", [_vm._v(_vm._s(_vm.currentUser.fullName))])
                      : _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t(
                              "component.settings.profile.user_info.new_name"
                            ),
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.updateName()
                            },
                          },
                          model: {
                            value: _vm.newName,
                            callback: function ($$v) {
                              _vm.newName = $$v
                            },
                            expression: "newName",
                          },
                        }),
                  ],
                  1
                ),
                _vm.hasPhoneNumber
                  ? _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "component.settings.profile.user_info.phone_number"
                          ),
                          type:
                            _vm.currentUser.detailedInfo.PhoneNumber.Verified ==
                            true
                              ? ""
                              : "is-danger",
                          message:
                            _vm.currentUser.detailedInfo.PhoneNumber.Verified ==
                            true
                              ? ""
                              : _vm.$t(
                                  "component.settings.profile.user_info.phone_not_verified"
                                ),
                        },
                      },
                      [
                        _vm.currentUser.detailedInfo.PhoneNumber.Verified ==
                        true
                          ? _c(
                              "div",
                              { staticClass: "is-flex is-flex-direction-row" },
                              [
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentUser.detailedInfo.PhoneNumber
                                          .Number
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("b-icon", {
                                  staticClass: "ml-1 control-icon",
                                  attrs: { icon: "trash" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.confirmNumberDelete()
                                    },
                                  },
                                }),
                                _c("b-icon", {
                                  staticClass: "ml-1 control-icon",
                                  attrs: { icon: "pen" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.openPhoneVerificationModal()
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : [
                              _c("b-input", {
                                attrs: {
                                  placeholder:
                                    _vm.currentUser.detailedInfo.PhoneNumber
                                      .Number,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openPhoneVerificationModal()
                                  },
                                },
                                model: {
                                  value:
                                    _vm.currentUser.detailedInfo.PhoneNumber
                                      .Number,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentUser.detailedInfo.PhoneNumber,
                                      "Number",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentUser.detailedInfo.PhoneNumber.Number",
                                },
                              }),
                              _c(
                                "p",
                                { staticClass: "control is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPhoneVerificationModal()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.verify_phone.verify_btn"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("b-button", {
                                    staticClass: "ml-1 control-icon",
                                    attrs: {
                                      "icon-right": "trash",
                                      type: "is-white",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmNumberDelete()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  : _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "component.settings.profile.user_info.phone_number"
                          ),
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.openPhoneVerificationModal()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.settings.profile.user_info.empty_phone_number"
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.settings.profile.user_info.registered_at"
                      ),
                    },
                  },
                  [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(
                                _vm.currentUser.detailedInfo.RegisteredAt * 1000
                              )
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm.isEditing
            ? _c(
                "div",
                { staticClass: "has-text-centered" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary", disabled: !_vm.nameChanged },
                      on: {
                        click: function ($event) {
                          return _vm.update()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("generals.save")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoggedFromAdmin
            ? [
                _c("hr", { staticClass: "m-0" }),
                _c("h3", { staticClass: "is-size-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.settings.profile.user_settings.actions.title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("ul", { staticClass: "pl-4" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.signOut()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.settings.profile.user_settings.actions.sign_out"
                            )
                          )
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.signOutAllDevices()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.settings.profile.user_settings.actions.sign_out_all"
                            )
                          )
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showChangePasswordModal()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.settings.profile.user_settings.actions.change_pswd"
                            )
                          )
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showDeleteAccountModal()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.settings.profile.user_settings.actions.delete_acc"
                            )
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isVerificationModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isVerificationModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("ResendVerificationModal", {
            attrs: { email: _vm.currentUser.email },
            on: {
              modalClosed: function ($event) {
                return _vm.closeModal()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isPhoneVerificationModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isPhoneVerificationModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("VerifyPhoneNumberModal", {
            attrs: { phoneNumberDto: _vm.currentUser.detailedInfo.PhoneNumber },
            on: {
              modalClosed: function ($event) {
                return _vm.closeModal()
              },
              reloadUser: _vm.reloadUser,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isChangePasswordModalActive,
            "has-modal-card": "",
            onCancel: _vm.closePasswordModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isChangePasswordModalActive = $event
            },
            "close-modal": _vm.closePasswordModal,
          },
        },
        [
          _c("ChangePasswordModal", {
            on: {
              modalClosed: _vm.closePasswordModal,
              changePassword: _vm.changePassword,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isDeleteAccountModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeDeleteAccountModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isDeleteAccountModalActive = $event
            },
            "close-modal": _vm.closeDeleteAccountModal,
          },
        },
        [
          _c("DeleteAccountModal", {
            attrs: { email: _vm.currentUser.email },
            on: {
              modalClosed: _vm.closeDeleteAccountModal,
              deleteAccount: _vm.deleteAccount,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }