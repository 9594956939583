var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "header",
        { staticClass: "modal-card-head" },
        [
          _c("b-icon", {
            staticClass: "mr-1",
            attrs: { icon: "building", size: "is-medium" },
          }),
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("admin.component.company.addCompanyUsersModal.title")
                ) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.company.createCompanyModal.addUsers"
                ),
              },
            },
            [
              _c(
                "b-table",
                {
                  staticClass: "select-location-table border select-user-table",
                  attrs: {
                    data: _vm.availableUsersForRole,
                    "checked-rows": _vm.selectedUsers,
                    paginated: true,
                    "backend-pagination": "",
                    "current-page": _vm.page,
                    "per-page": _vm.pageSize,
                    total: _vm.totalSize,
                    loading: _vm.componentIsLoading,
                    narrowed: "",
                    checkable: "",
                    "mobile-cards": false,
                    "backend-filtering": "",
                    "row-class": (row) =>
                      _vm.users.filter((x) => x.email === row.email).length > 0
                        ? "is-disabled has-text-grey"
                        : "",
                    "is-row-checkable": (row) =>
                      _vm.users.filter((x) => x.email === row.email).length ===
                      0,
                    "custom-is-checked": (a, b) => {
                      return a.apiUserId === b.apiUserId
                    },
                  },
                  on: {
                    "update:checkedRows": function ($event) {
                      _vm.selectedUsers = $event
                    },
                    "update:checked-rows": function ($event) {
                      _vm.selectedUsers = $event
                    },
                    "update:currentPage": function ($event) {
                      _vm.page = $event
                    },
                    "update:current-page": function ($event) {
                      _vm.page = $event
                    },
                    "page-change": _vm.onPageChange,
                    check: _vm.deleteUnChecked,
                  },
                },
                [
                  _c("b-table-column", {
                    attrs: {
                      field: "email",
                      label: _vm.$t(
                        "admin.component.company.createCompanyModal.form.selectAll"
                      ),
                      searchable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "searchable",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c("b-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("generals.search"),
                                    size: "is-small",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterUsers(_vm.emailSearch)
                                    },
                                  },
                                  model: {
                                    value: _vm.emailSearch,
                                    callback: function ($$v) {
                                      _vm.emailSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "emailSearch",
                                  },
                                }),
                                _c("b-icon", {
                                  staticClass: "level-item clickable",
                                  attrs: { icon: "search" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.filterUsers(_vm.emailSearch)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (availableUsersForRole) {
                          return [
                            _c("span", [
                              _c(
                                "div",
                                { staticClass: "field flex-space-between" },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        availableUsersForRole.row.fullName
                                      ) +
                                        " ( " +
                                        _vm._s(
                                          availableUsersForRole.row.email
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                  _vm.selectedUsers.find(
                                    (data) =>
                                      availableUsersForRole.row.id == data.id
                                  )
                                    ? _c(
                                        "b-select",
                                        {
                                          attrs: {
                                            size: "is-small",
                                            placeholder: _vm.$t(
                                              "admin.component.company.createCompanyModal.form.selectRole"
                                            ),
                                            name: _vm.$t(
                                              "admin.component.company.createCompanyModal.form.selectRole"
                                            ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.selectRole(
                                                availableUsersForRole.row,
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: (
                                              _vm.assignUsers.find(
                                                (x) =>
                                                  x.userId ===
                                                  availableUsersForRole.row.id
                                              ) || {}
                                            ).userRole,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.assignUsers.find(
                                                  (x) =>
                                                    x.userId ===
                                                    availableUsersForRole.row.id
                                                ) || {},
                                                "userRole",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "(assignUsers.find((x) => x.userId === availableUsersForRole.row.id) || {}).userRole",
                                          },
                                        },
                                        [
                                          Object.keys(_vm.adminRoles).length > 0
                                            ? _c(
                                                "optgroup",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t(
                                                        `users.groups.admin`
                                                      ),
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.adminRoles,
                                                  function (option) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: option,
                                                        domProps: {
                                                          value: option,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                `users.roles.${option}`
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _c(
                                            "optgroup",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.$t(`users.groups.user`),
                                              },
                                            },
                                            _vm._l(
                                              _vm.userRoles,
                                              function (option) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: option,
                                                    domProps: { value: option },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `users.roles.${option}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.company.createCompanyModal.list.selectedUsers"
                ),
              },
            },
            [
              _c(
                "b-table",
                {
                  staticClass:
                    "select-location-table border select-user-table-list",
                  attrs: { data: _vm.assignUsers, striped: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "empty",
                      fn: function () {
                        return [
                          _c("section", { staticClass: "section" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "content has-text-grey has-text-centered",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.company.createCompanyModal.list.empty"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-table-column", {
                    attrs: {
                      field: "name",
                      label: _vm.$t(
                        "admin.component.company.createCompanyModal.list.userName"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (assignUsers) {
                          return [
                            _c("span", [
                              _c(
                                "div",
                                { staticClass: "field flex-space-between" },
                                [
                                  _vm._v(
                                    _vm._s(assignUsers.row.fullName) +
                                      " (" +
                                      _vm._s(assignUsers.row.email) +
                                      ")"
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    attrs: {
                      field: "Role",
                      label: _vm.$t(
                        "admin.component.company.createCompanyModal.list.role"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (assignUsers) {
                          return [
                            _c("span", [
                              _c(
                                "div",
                                { staticClass: "field flex-space-between" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          `users.roles.${assignUsers.row.userRole}`
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.addCompanyUsers()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("admin.component.company.addCompanyUsersModal.confirm")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }