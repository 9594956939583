import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
let AdminDeviceConfigHistoryComponent = class AdminDeviceConfigHistoryComponent extends Vue {
    constructor() {
        super(...arguments);
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminDeviceConfigurationListSortDirection
            .defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminDeviceConfigurationListSortField.defaultValue;
        this.tableColumns = {
            configId: {
                field: 'configId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceConfigHistoryConfigId)
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceConfigHistoryCreatedAt)
            },
            configuredAt: {
                field: 'configuredAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceConfigHistoryConfiguredAt)
            },
            interval: {
                field: 'interval',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceConfigHistoryInterval)
            },
            current: {
                field: 'current',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceConfigHistoryCurrent)
            }
        };
    }
    isCurrentConfig(config) {
        if (!this.device.currentConfig)
            return false;
        else
            return this.device.currentConfig.configId == config.configId;
    }
    // changing default sort field
    changeDefaultSortField(sortFieldName) {
        if (this.sortField == sortFieldName) {
            this.sortField = this.tableColumns.configId.field;
        }
    }
    onSort(field, order) {
        this.sortField = field;
        this.sortDirection = order;
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.configuredAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.configuredAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceConfigHistoryConfiguredAt, event);
                break;
            case this.tableColumns.createdAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.createdAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceConfigHistoryCreatedAt, event);
                break;
            case this.tableColumns.interval.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.interval.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceConfigHistoryInterval, event);
                break;
            case this.tableColumns.current.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.current.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceConfigHistoryCurrent, event);
                break;
        }
        this.$nextTick(() => {
            this.$refs.table.initSort();
        });
    }
};
__decorate([
    Prop({ type: Object })
], AdminDeviceConfigHistoryComponent.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceConfigHistoryComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminDeviceConfigHistoryComponent.prototype, "isLayoutList", void 0);
AdminDeviceConfigHistoryComponent = __decorate([
    Component({})
], AdminDeviceConfigHistoryComponent);
export default AdminDeviceConfigHistoryComponent;
