import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import SourceGroupPicker from '@/views/components/widgets/SourceGroupPicker.vue';
import LastEventsComponent from '@/views/components/events/LastEventsComponent.vue';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import { SourceGroup } from '@/entities/models/SourceGroup';
import EventRepository from '@/services/repository/EventRepository';
import { Event } from '@/entities/models/Event';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import ChartComponent from '@/views/components/ChartComponent.vue';
import CreateDashboardModal from '@/views/components/publicDashboard/CreateDashboardModal.vue';
import DateTimeManager from '@/services/DateTimeManager';
import SourceRepository from '@/services/repository/SourceRepository';
import { Source } from '@/entities/models/Source';
import VueUtilities from '@/services/VueUtilities';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import UserRepository from '@/services/repository/UserRepository';
import { UserSettingsValues } from '@/entities/UserSettings';
import UserPreferences from '@/services/UserPreferences';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import CookiesStorage from '@/services/CookiesStorage';
import { vxm } from '@/store/store.vuex';
import DeviceRepository from '@/services/repository/DeviceRepository';
import ReportRecordRepository from '@/services/repository/ReportRecordRepository';
import StatisticsType from '@/entities/enums/statisticsType';
import { ChartComponents } from '@/entities/enums/ChartComponents';
var sourceGroupRepository;
var sourceRepository;
var eventRepository;
var eventTypeRepository;
var userSettingsRepository;
var userRepository;
var deviceRepository;
var reportRecordRepository;
var vxDepartmentStore = vxm.departmentStore;
let GroupDashboard = class GroupDashboard extends Vue {
    constructor() {
        super(...arguments);
        this.sourceGroups = [];
        this.sources = [];
        this.devices = [];
        this.selectedSourceGroupId = '';
        this.lastEvents = {};
        this.sourceGroupEvents = [];
        this.sourceGroupSources = [];
        this.upTimeServiceSources = [];
        this.otherServiceSources = [];
        this.eventTypes = [];
        this.upTimeSourceschartData = {};
        this.otherSourcesChartData = {};
        this.hasSources = true;
        this.dateTimeManager = null;
        this.favoriteGroupId = '';
        this.selectedDayOption = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ChartDayOption);
        this.chartHeight = null;
        this.isLoading = {
            SourceGroupPicker: false,
            LastEventsComponent: false,
            ChartComponent: false
        };
        this.refreshLastEvents = null;
        this.refreshEventsInterval = null;
        this.refreshIntervalHandler = null;
        this.isCreateDashboardModalActive = false;
        this.users = [];
        this.isLoadingOverlay = false;
        this.currentUser = null;
        this.selectedDepartmentId = null;
        this.controller = null;
        this.signal = null;
        this.departmentUserSettings = null;
        this.startDate = null;
        this.annotationsForSources = [];
        this.chartEntityChartComponent = null;
        this.lastWidth = document.body.offsetWidth;
    }
    get unassignedSources() {
        return Source.getUnassignedSources(this.sources);
    }
    async created() {
        sourceGroupRepository = new SourceGroupRepository(this);
        sourceRepository = new SourceRepository(this);
        eventRepository = new EventRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        userRepository = new UserRepository(this);
        deviceRepository = new DeviceRepository(this);
        reportRecordRepository = new ReportRecordRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        await this.loadDataForPage();
    }
    beforeDestroy() {
        clearInterval(this.refreshIntervalHandler);
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    urlIdExists(id) {
        return !(this.sourceGroups.find((x) => x.id === id) || id === 'A' || id === 'U');
    }
    selectGroupFromRoute() {
        if (this.$router.currentRoute.query.groupId) {
            // check if url id exists
            if (this.urlIdExists(this.$router.currentRoute.query.groupId)) {
                VueUtilities.openErrorToast(this, this.$t('error_messages.invalid_group_id').toString());
                let selectedGroupId = this.findGroupToSelect();
                this.$router.replace({
                    name: this.$route.name,
                    query: { groupId: selectedGroupId },
                    params: {
                        lang: this.$route.params.lang,
                        departmentId: this.$route.params.departmentId
                    }
                });
                return selectedGroupId;
            }
            return this.$router.currentRoute.query.groupId;
        }
        return this.findGroupToSelect();
    }
    async loadDataForPage(reloadUserSettingsForDepartment = false) {
        this.selectedDepartmentId = this.getSelectedDepartmentId();
        this.isLoading.SourceGroupPicker = true;
        this.isLoading.LastEventsComponent = true;
        this.isLoading.ChartComponent = true;
        this.currentUser = CookiesStorage.loadData(this, CookiesStorage.keys.API_USER_ID);
        this.startDate = this.getStartDate();
        let initialPromises = [];
        initialPromises.push(this.loadSourceGroups());
        initialPromises.push(this.loadSources());
        initialPromises.push(this.loadDevices());
        initialPromises.push(this.loadEventTypes());
        initialPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.currentUser, reloadUserSettingsForDepartment));
        initialPromises.push(userSettingsRepository.loadUserSettings());
        await Promise.all(initialPromises).then(async (response) => {
            this.processInitialDataForPage(response[0], response[1], response[2], response[3], response[4], response[5]);
            if (this.sources.length > 0) {
                await this.loadAdditionalData();
                this.hasSources = true;
            }
            else {
                this.hasSources = false;
                this.isLoading.LastEventsComponent = false;
                this.isLoading.ChartComponent = false;
            }
        });
    }
    processInitialDataForPage(sourceGroups, sources, devices, eventTypes, departmentUserSettings, settings) {
        this.sourceGroups = SourceGroup.assignSourcesToSourceGroups(sourceGroups.getData(), sources.getData());
        this.sources = sources.getData();
        this.devices = devices.getData();
        this.eventTypes = eventTypes;
        this.favoriteGroupId = departmentUserSettings.preferences.favoriteGroupId;
        this.selectedSourceGroupId = this.selectGroupFromRoute();
        this.sourceGroupSources = this.getSourcesForSelectedGroup();
        this.chartHeight = settings.preferences.chartHeight;
        Source.sortByName(this.sourceGroupSources);
        this.refreshLastEvents = departmentUserSettings.preferences.eventsRefresh.enabled == 'true';
        this.refreshEventsInterval = parseInt(departmentUserSettings.preferences.eventsRefresh.interval);
        this.controlRefresh(this.refreshLastEvents);
        this.isLoading.SourceGroupPicker = false;
    }
    async loadAdditionalData() {
        this.isLoading.LastEventsComponent = true;
        this.isLoading.ChartComponent = true;
        this.controller = new AbortController();
        this.signal = this.controller.signal;
        let additionalPromises = [];
        additionalPromises.push(this.loadLastEvents(this.signal));
        return Promise.all(additionalPromises)
            .then((response) => {
            this.processAdditionalDataForPage(response[0]);
        })
            .catch((err) => {
            return;
        });
    }
    async processAdditionalDataForPage(lastEvents) {
        if (this.sourceGroupSources.length <= 0) {
            this.lastEvents = {};
        }
        else {
            this.lastEvents = Event.calculateBoundaryDifference(lastEvents, this.sources);
        }
        this.sourceGroupEvents = Event.getEventsFromEventsRecord(this.lastEvents);
        this.filterSourcesByEventType();
        this.annotationsForSources = await this.loadAnnotationsForSources();
        let additionalPromises = [];
        additionalPromises.push(this.loadChartData(this.signal));
        additionalPromises.push(this.loadUptimeChartData(this.signal));
        return Promise.all(additionalPromises)
            .then((response) => {
            this.processAdditionalDataForChart(response[0], response[1]);
        })
            .catch((err) => {
            return;
        });
    }
    processAdditionalDataForChart(otherSourcesChartData, upTimeSourceschartData) {
        this.otherSourcesChartData = otherSourcesChartData;
        this.upTimeSourceschartData = upTimeSourceschartData;
        this.isLoading.LastEventsComponent = false;
        this.isLoading.ChartComponent = false;
    }
    async loadAnnotationsForSources() {
        let annotationsForSources = [];
        let endDate = this.dateTimeManager.formatEndDateForReports(new Date(), true).text;
        let annotationPromises = [];
        this.otherServiceSources.forEach(async (source) => {
            annotationPromises.push(reportRecordRepository.getAnnotationsForSource(source.id, true, endDate, null, null, this.startDate, StatisticsType.AVERAGE, null, null, this.dateTimeManager.getSelectedTimezone()));
        });
        await Promise.all(annotationPromises).then((response) => {
            response.forEach((annotations) => {
                if (annotations.length > 0)
                    annotationsForSources.push(annotations);
            });
        });
        return annotationsForSources;
    }
    filterSourcesByEventType() {
        this.upTimeServiceSources = [];
        this.otherServiceSources = [];
        this.sourceGroupSources.filter((x) => {
            if (this.sourceGroupEvents.find((y) => y.sourceId == x.id && y.eventType.type == 5)) {
                this.upTimeServiceSources.push(x);
            }
            else {
                this.otherServiceSources.push(x);
            }
        });
    }
    findGroupToSelect() {
        // if already selected
        if (this.selectedSourceGroupId && this.selectedSourceGroupId.length > 0) {
            return this.selectedSourceGroupId;
        }
        // sourceGroups.length == 0
        if (this.sourceGroups.length <= 0) {
            return this.unassignedSources.length > 0
                ? UserSettingsValues.UNASSIGNED_GROUP_ID
                : UserSettingsValues.ALL_GROUP_ID;
        }
        // sourceGroups.length > 0
        switch (this.favoriteGroupId) {
            case UserSettingsValues.UNASSIGNED_GROUP_ID:
                return UserSettingsValues.UNASSIGNED_GROUP_ID;
            case UserSettingsValues.ALL_GROUP_ID:
                return UserSettingsValues.ALL_GROUP_ID;
            case '' || null:
                return this.sourceGroups[0].id;
            default:
                if (this.sourceGroups.find((x) => x.id === this.favoriteGroupId)) {
                    return this.favoriteGroupId;
                }
                else {
                    return this.sourceGroups[0].id;
                }
        }
    }
    selectGroup() {
        if (!this.selectedSourceGroupId) {
            return (this.sourceGroups[0] || {}).id || null;
        }
        else if (this.selectedSourceGroupId != null &&
            this.sourceGroups.length > 0 &&
            this.sourceGroups.filter((x) => x.id == this.selectedSourceGroupId).length <= 0) {
            VueUtilities.openErrorToast(this, this.$t('error_messages.invalid_group_id').toString());
            return (this.sourceGroups[0] || {}).id || null;
        }
        else {
            return this.selectedSourceGroupId;
        }
    }
    async loadLastEvents(signal) {
        return eventRepository.getLastEventsForSources(this.sourceGroupSources.map((x) => x.id), signal);
    }
    async loadEventTypes() {
        return eventTypeRepository.getAllTypes();
    }
    async loadSources() {
        return sourceRepository.getVisibleSources();
    }
    async loadDevices() {
        return deviceRepository.getDevices();
    }
    async loadChartData(signal) {
        return eventRepository.getChartDataForSources(this.otherServiceSources.map((x) => x.id), this.startDate, signal);
    }
    async loadUptimeChartData(signal) {
        return eventRepository.getUptimeChartDataForSources(this.upTimeServiceSources.map((x) => x.id), this.startDate, signal);
    }
    getStartDate() {
        let date = new Date();
        date.setDate(date.getDate() - this.selectedDayOption);
        return this.dateTimeManager.formatStartDateForReports(date).text;
    }
    async loadSourceGroups() {
        return sourceGroupRepository.getSourceGroups();
    }
    async sourceGroupSelected(sourceGroupId) {
        this.controller.abort();
        this.sourceGroupEvents = [];
        this.selectedSourceGroupId = sourceGroupId;
        this.sourceGroupSources = this.getSourcesForSelectedGroup();
        Source.sortByName(this.sourceGroupSources);
    }
    async favoriteSelected(id) {
        this.isLoading.SourceGroupPicker = true;
        let idToStore = this.favoriteGroupId == id ? '' : id;
        let departmentUserSettings = await userSettingsRepository.saveUserSettingForDepartment(this.currentUser, [UserSettingsKeys.PREFERENCES, UserSettingsKeys.FAVORITE_GROUP_ID], idToStore);
        this.favoriteGroupId = departmentUserSettings.preferences.favoriteGroupId;
        let msg = idToStore != ''
            ? this.$t('component.source_group_picker.selected_as_favorite')
            : this.$t('component.source_group_picker.favorite_removed');
        VueUtilities.openSuccessToast(this, msg);
        this.isLoading.SourceGroupPicker = false;
    }
    async refreshChanged(value) {
        let departmentUserSettings = await userSettingsRepository.saveUserSettingForDepartment(this.currentUser, [UserSettingsKeys.PREFERENCES, UserSettingsKeys.EVENTS_REFRESH, UserSettingsKeys.ENABLED], value);
        this.refreshLastEvents = departmentUserSettings.preferences.eventsRefresh.enabled == 'true';
        this.refreshEventsInterval = parseInt(departmentUserSettings.preferences.eventsRefresh.interval);
        this.controlRefresh(this.refreshLastEvents);
    }
    controlRefresh(inOn) {
        if (inOn) {
            this.refreshIntervalHandler = setInterval(this.loadAdditionalData, this.refreshEventsInterval * 60 * 1000 //min to ms
            );
        }
        else {
            clearInterval(this.refreshIntervalHandler);
        }
    }
    getSourcesForSelectedGroup() {
        return SourceGroup.assignSourcesForSelectedGroup(this.selectedSourceGroupId, this.sources);
    }
    async dayOptionSelected(option) {
        this.isLoading.ChartComponent = true;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.ChartDayOption, option);
        this.selectedDayOption = option;
        this.startDate = this.getStartDate();
        this.filterSourcesByEventType();
        this.annotationsForSources = await this.loadAnnotationsForSources();
        this.otherSourcesChartData = await this.loadChartData(this.signal);
        this.upTimeSourceschartData = await this.loadUptimeChartData(this.signal);
        this.isLoading.ChartComponent = false;
    }
    eventHandler(hasDataForTimerange, chartEntity, chartComponent) {
        if (hasDataForTimerange) {
            if (chartComponent == ChartComponents.CHARTCOMPONENT) {
                this.chartEntityChartComponent = chartEntity;
                window.addEventListener('resize', this.resizeHandlerChartComponent);
            }
        }
        else {
            if (chartComponent == ChartComponents.CHARTCOMPONENT) {
                this.chartEntityChartComponent = chartEntity;
                window.removeEventListener('resize', this.resizeHandlerChartComponent);
            }
        }
    }
    resizeHandlerChartComponent() {
        const currentWidth = document.body.offsetWidth;
        if (currentWidth !== this.lastWidth) {
            this.lastWidth = currentWidth;
            if (this.chartEntityChartComponent)
                this.chartEntityChartComponent.makeResponsive();
        }
    }
    async changeUserSetting(option) {
        this.chartHeight = option;
        await userSettingsRepository.saveUserSetting([UserSettingsKeys.PREFERENCES, UserSettingsKeys.CHARTHEIGHT], option);
    }
    closeCreateDashboardModal(result) {
        this.isCreateDashboardModalActive = false;
    }
    scrollToChartFilter() {
        const filters = this.$el.querySelector('#more-chart-filter');
        filters.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(function () {
            filters.dispatchEvent(new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            }));
        }, 400);
    }
    async createPublicDashboard() {
        this.isLoadingOverlay = true;
        this.users = await userRepository.getUsers();
        this.isLoadingOverlay = false;
        this.isCreateDashboardModalActive = true;
    }
};
GroupDashboard = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.MEASURED_DATA_READ) &&
                    vm.$ability.can(Actions.READ, Subjects.SOURCE_GROUP) &&
                    vm.$ability.can(Actions.READ, Subjects.SOURCE)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            this.selectedSourceGroupId = '';
            this.loadDataForPage(true);
            next(); // to resolve hook
        },
        components: {
            SourceGroupPicker,
            LastEventsComponent,
            ChartComponent,
            CreateDashboardModal
        }
    })
], GroupDashboard);
export default GroupDashboard;
