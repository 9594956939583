var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { attrs: { id: "landing-page" } },
    [
      _c(
        "div",
        { staticClass: "hero" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: {
                    lang: _vm.$route.params.lang,
                  },
                },
              },
            },
            [
              _c("h1", { staticClass: "title is-1" }, [
                _c("img", {
                  attrs: { src: require("@/assets/img/loghub_logo_trans.svg") },
                }),
                _vm._v("LogHub"),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "has-text-white is-flex is-flex-direction-row is-justify-content-center mt-2",
            },
            [
              _vm.isCurrentLanguage(_vm.Languages.SLOVAK)
                ? _c(
                    "span",
                    {
                      staticClass: "has-l-m is-underlined has-text-weight-bold",
                    },
                    [_vm._v(_vm._s(_vm.$t("navbar.slovak_short")))]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "has-l-m has-text-white",
                      on: {
                        click: function ($event) {
                          return _vm.changeLanguage(_vm.Languages.SLOVAK)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("navbar.slovak_short")))]
                  ),
              _vm.isCurrentLanguage(_vm.Languages.ENGLISH)
                ? _c(
                    "span",
                    {
                      staticClass: "has-l-m is-underlined has-text-weight-bold",
                    },
                    [_vm._v(_vm._s(_vm.$t("navbar.english_short")))]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "has-l-m has-text-white",
                      on: {
                        click: function ($event) {
                          return _vm.changeLanguage(_vm.Languages.ENGLISH)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("navbar.english_short")))]
                  ),
              _vm.isCurrentLanguage(_vm.Languages.CZECH)
                ? _c(
                    "span",
                    {
                      staticClass: "has-l-m is-underlined has-text-weight-bold",
                    },
                    [_vm._v(_vm._s(_vm.$t("navbar.czech_short")))]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "has-l-m has-text-white",
                      on: {
                        click: function ($event) {
                          return _vm.changeLanguage(_vm.Languages.CZECH)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("navbar.czech_short")))]
                  ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "site-content" }, [
        _c("div", [_vm._t("default")], 2),
      ]),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }