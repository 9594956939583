var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("div", [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("admin.component.devices.configureModal.title")) +
              " "
          ),
          _vm.deviceList.length == 1
            ? _c("span", [_vm._v(_vm._s(_vm.deviceList[0].deviceName))])
            : _vm._e(),
        ]),
        _vm.deviceList.length > 1
          ? _c("p", { staticClass: "is-italic is-3 pt-1" }, [
              _vm._v(" " + _vm._s(_vm.deviceNames) + " "),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("section", { staticClass: "modal-card-body" }, [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c("div", { staticClass: "level section-header-level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("h2", { staticClass: "level-item subtitle is-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.configureModal.configuration.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c(
            "b-field",
            {
              staticClass: "required",
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.devices.configureModal.configuration.interval"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.devices.configureModal.configuration.interval"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.devices.configureModal.configuration.interval"
                  )
                ),
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|numeric|min_value:1",
                    expression: "'required|numeric|min_value:1'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.devices.configureModal.configuration.placeholder"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.configureModal.configuration.interval"
                  ),
                },
                model: {
                  value: _vm.interval,
                  callback: function ($$v) {
                    _vm.interval = $$v
                  },
                  expression: "interval",
                },
              }),
            ],
            1
          ),
          _c("hr"),
          _c("h3", { staticClass: "subtitle is-5" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "admin.component.devices.configureModal.configuration.additional_title"
                  )
                ) +
                " "
            ),
          ]),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.devices.configureModal.configuration.maxOutOfRangeTime"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.devices.configureModal.configuration.maxOutOfRangeTime"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.devices.configureModal.configuration.maxOutOfRangeTime"
                  )
                ),
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "numeric|min_value:1",
                    expression: "'numeric|min_value:1'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.devices.configureModal.configuration.maxOutOfRangeTime"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.configureModal.configuration.maxOutOfRangeTime"
                  ),
                },
                model: {
                  value: _vm.maxOutOfRangeTime,
                  callback: function ($$v) {
                    _vm.maxOutOfRangeTime = $$v
                  },
                  expression: "maxOutOfRangeTime",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.devices.configureModal.configuration.offlineSyncInterval"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.devices.configureModal.configuration.offlineSyncInterval"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.devices.configureModal.configuration.offlineSyncInterval"
                  )
                ),
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "numeric|min_value:1",
                    expression: "'numeric|min_value:1'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.devices.configureModal.configuration.offlineSyncInterval"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.configureModal.configuration.offlineSyncInterval"
                  ),
                },
                model: {
                  value: _vm.offlineSyncInterval,
                  callback: function ($$v) {
                    _vm.offlineSyncInterval = $$v
                  },
                  expression: "offlineSyncInterval",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.devices.configureModal.configuration.scanTimeout"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.devices.configureModal.configuration.scanTimeout"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.devices.configureModal.configuration.scanTimeout"
                  )
                ),
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "numeric|min_value:1",
                    expression: "'numeric|min_value:1'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.devices.configureModal.configuration.scanTimeout"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.configureModal.configuration.scanTimeout"
                  ),
                },
                model: {
                  value: _vm.scanTimeout,
                  callback: function ($$v) {
                    _vm.scanTimeout = $$v
                  },
                  expression: "scanTimeout",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.devices.configureModal.configuration.checkInterval"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.devices.configureModal.configuration.checkInterval"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.devices.configureModal.configuration.checkInterval"
                  )
                ),
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "numeric|min_value:1",
                    expression: "'numeric|min_value:1'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.devices.configureModal.configuration.checkInterval"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.configureModal.configuration.checkInterval"
                  ),
                },
                model: {
                  value: _vm.checkInterval,
                  callback: function ($$v) {
                    _vm.checkInterval = $$v
                  },
                  expression: "checkInterval",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.devices.configureModal.configuration.chunkSize"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.devices.configureModal.configuration.chunkSize"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.devices.configureModal.configuration.chunkSize"
                  )
                ),
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "numeric|min_value:1",
                    expression: "'numeric|min_value:1'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.devices.configureModal.configuration.chunkSize"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.configureModal.configuration.chunkSize"
                  ),
                },
                model: {
                  value: _vm.chunkSize,
                  callback: function ($$v) {
                    _vm.chunkSize = $$v
                  },
                  expression: "chunkSize",
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.customPair, function (item, index) {
            return _c(
              "b-field",
              {
                key: index,
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "admin.component.devices.configureModal.configuration.customPair"
                  ),
                },
              },
              [
                _c("b-input", {
                  attrs: {
                    name: _vm.$t("generals.key") + index,
                    placeholder: _vm.$t("generals.key"),
                  },
                  model: {
                    value: item.customKey,
                    callback: function ($$v) {
                      _vm.$set(item, "customKey", $$v)
                    },
                    expression: "item.customKey",
                  },
                }),
                _c("b-input", {
                  attrs: {
                    name: _vm.$t("generals.value") + index,
                    placeholder: _vm.$t("generals.value"),
                  },
                  model: {
                    value: item.customValue,
                    callback: function ($$v) {
                      _vm.$set(item, "customValue", $$v)
                    },
                    expression: "item.customValue",
                  },
                }),
                index == _vm.customPair.length - 1
                  ? _c(
                      "b-button",
                      {
                        attrs: { type: "is-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.addCustomSetting()
                          },
                        },
                      },
                      [_vm._v("+")]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "level section-header-level" }, [
          _c("div", { staticClass: "level-left" }, [
            _c("h2", { staticClass: "level-item subtitle is-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("admin.component.devices.configureModal.list.title")
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          [
            _c(
              "b-table",
              {
                attrs: {
                  data: _vm.deviceList,
                  striped: "",
                  "mobile-cards": false,
                  scrollable: true,
                  "sticky-header": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("section", { staticClass: "section" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.devices.deviceList.table.empty"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("b-table-column", {
                  attrs: {
                    field: "deviceId",
                    label: _vm.$t(
                      "admin.component.devices.configureModal.list.table.id"
                    ),
                    sortable: "",
                    sticky: true,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (deviceList) {
                        return [_vm._v(_vm._s(deviceList.row.deviceId))]
                      },
                    },
                  ]),
                }),
                _c("b-table-column", {
                  attrs: {
                    field: "deviceName",
                    label: _vm.$t(
                      "admin.component.devices.configureModal.list.table.name"
                    ),
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (deviceList) {
                        return [_vm._v(_vm._s(deviceList.row.deviceName))]
                      },
                    },
                  ]),
                }),
                _c("b-table-column", {
                  attrs: {
                    field: "deviceMacAddress",
                    label: _vm.$t(
                      "admin.component.devices.configureModal.list.table.macAddress"
                    ),
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (deviceList) {
                        return [_vm._v(_vm._s(deviceList.row.deviceMacAddress))]
                      },
                    },
                  ]),
                }),
                _c("b-table-column", {
                  attrs: {
                    field: "company",
                    label: _vm.$t(
                      "admin.component.devices.configureModal.list.table.company"
                    ),
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (deviceList) {
                        return [
                          deviceList.row.company &&
                          deviceList.row.instanceState !==
                            _vm.DeviceInstanceState.RETURNED
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(deviceList.row.company.companyName) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("b-table-column", {
                  attrs: {
                    field: "currentInterval",
                    label: _vm.$t(
                      "admin.component.devices.configureModal.list.table.currentInterval"
                    ),
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (deviceList) {
                        return [
                          deviceList.row.currentConfig
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      deviceList.row.currentConfig.interval
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("b-table-column", {
                  attrs: {
                    field: "isOnline",
                    label: _vm.$t(
                      "admin.component.devices.configureModal.list.table.isAlive"
                    ),
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (deviceList) {
                        return [
                          deviceList.row.isOnline == false
                            ? _c(
                                "b-tag",
                                { attrs: { type: "is-primary", rounded: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "component.device.list.table.disconnected"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : deviceList.row.isOnline == true
                            ? _c(
                                "b-tag",
                                { attrs: { type: "is-success", rounded: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "component.device.list.table.connected"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            staticClass: "level-item",
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.configure()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.$tc(
                  "admin.component.devices.configureModal.configuration.configure",
                  _vm.deviceList.length
                )
              )
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }