import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import LoggerRepository from '@/services/repository/LoggerRepository';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
var loggerRepository;
let CreateLoggerModal = class CreateLoggerModal extends Vue {
    constructor() {
        super(...arguments);
        this.macAddress = null;
        this.isLoading = false;
    }
    created() {
        loggerRepository = new LoggerRepository(this);
    }
    createLogger() {
        if (this.isLoading === true)
            return;
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let loggerDto = {
                    MacAddress: this.macAddress
                };
                let createResult = await loggerRepository.adminCreateLogger(loggerDto);
                this.isLoading = false;
                if (createResult == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.create_logger.success'));
                    this.resultRetrieved(true);
                }
                else if (createResult.code == ApiResponseCodes.ENTITY_ALREADY_EXISTS) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.create_logger.already_exists'));
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.create_logger.failure'));
                }
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
CreateLoggerModal = __decorate([
    Component({})
], CreateLoggerModal);
export default CreateLoggerModal;
