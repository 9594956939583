import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import FloorPlanEntities from '@/entities/enums/FloorPlanEntities';
import UserPreferences from '@/services/UserPreferences';
import { arrayBufferToBase64 } from '@/services/FileManager';
let FloorPlanOverview = class FloorPlanOverview extends Vue {
    constructor() {
        super(...arguments);
        this.FloorPlanEntities = FloorPlanEntities;
        this.image = new Image();
        this.currCanvasHeight = 0;
        this.ctx = null;
        this.numberOfLoggers = 0;
        this.isLayoutList = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FloorPlanUploadLayoutList);
        this.offsetX = null;
        this.offsetY = null;
        this.touchStartX = 0;
        this.touchEndX = 0;
        this.minSwipeDistance = 50;
        this.thumbnailsMaxHeight = 0;
    }
    get isMobileView() {
        return window.innerWidth <= 768;
    }
    calculateMaxHeight() {
        const canvasHeight = this.$refs.canvasCol?.offsetHeight || 0;
        const infoHeight = this.$refs.floorplanInfoCol?.offsetHeight || 0;
        this.thumbnailsMaxHeight = Math.max(canvasHeight, infoHeight);
    }
    async mounted() {
        this.loadData();
    }
    async loadData() {
        if (this.floorPlans.length > 0 && this.selectedFloorPlanLocationData && this.selectedFloorPlanImage) {
            this.numberOfLoggers = 1;
            await new Promise((resolve, reject) => {
                this.image.src =
                    'data:' +
                        this.selectedFloorPlan.fileDetails.mimeType +
                        ';base64, ' +
                        arrayBufferToBase64(this.selectedFloorPlanImage);
                this.image.onload = resolve;
                this.image.onerror = reject;
            });
            this.image.setAttribute('crossOrigin', 'anonymous');
            this.canvas = document.getElementById('canvas');
            this.ctx = this.canvas.getContext('2d');
            // Getting and apply percentage for creating responsive image
            let percentage = this.getPercentage(this.canvas.offsetWidth, this.image.width);
            let percentageForWidth = (this.image.width / 100) * percentage;
            let percentageForHeight = (this.image.height / 100) * percentage;
            this.canvas.width = this.image.width + percentageForWidth;
            this.canvas.height = this.image.height + percentageForHeight;
            this.currCanvasHeight = this.canvas.height;
            this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
            if (this.isOldFormat()) {
                this.selectedFloorPlanLocationData.forEach((x) => {
                    let posX = (x.coordinates[0] / this.image.width) * this.canvas.width;
                    let posY = (x.coordinates[1] / this.image.height) * this.canvas.height;
                    this.drawData(posX, posY);
                });
            }
            else {
                this.selectedFloorPlanLocationData.forEach((x) => {
                    let posX = x.coordinates[0] * this.canvas.width;
                    let posY = x.coordinates[1] * this.canvas.height;
                    this.drawData(posX, posY);
                });
            }
            this.calculateMaxHeight();
        }
    }
    getPercentage(a, b) {
        return ((a - b) / b) * 100;
    }
    setSelectedFloorplan(id) {
        this.$emit('setSelectedFloorplan', id);
    }
    /**
     * Check if coordinates are in old format
     */
    isOldFormat() {
        return this.selectedFloorPlanLocationData.filter((x) => x.coordinates[0] > 1).length > 0;
    }
    // Record the starting touch position
    handleTouchStart(event) {
        this.touchStartX = event.touches[0].clientX;
    }
    // Record the ending touch position
    handleTouchEnd(event) {
        this.touchEndX = event.changedTouches[0].clientX;
        this.detectSwipe();
    }
    detectSwipe() {
        if (this.floorPlans.length > 1) {
            let deltaX = this.touchEndX - this.touchStartX;
            if (Math.abs(deltaX) > this.minSwipeDistance) {
                let i = this.floorPlans.indexOf(this.selectedFloorPlan);
                if (deltaX > 0) {
                    i = i == this.floorPlans.length - 1 ? 0 : i + 1;
                }
                else {
                    i = i == 0 ? this.floorPlans.length - 1 : i - 1;
                }
                this.setSelectedFloorplan(this.floorPlans[i].id);
            }
        }
    }
    changeLayout() {
        this.isLayoutList = !this.isLayoutList;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.FloorPlanUploadLayoutList, this.isLayoutList);
        this.loadData();
    }
    updatePlan() {
        this.$emit('updateFloorPlan', true, this.selectedFloorPlan.id);
    }
    uploadPlan() {
        this.$emit('uploadFloorPlan', true);
    }
    deleteFloorPlan() {
        this.$emit('deleteFloorPlan', this.selectedFloorPlan.id);
    }
    getAppThemeColor() {
        let theme = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.Theme);
        if (theme === 'dark')
            return '#ff6d5a';
        else
            return '#be2528';
    }
    drawData(posX, posY) {
        this.ctx.font = '15px Arial';
        let width = this.ctx.measureText(this.numberOfLoggers.toString()).width;
        this.ctx.fillStyle = this.getAppThemeColor();
        this.ctx.beginPath();
        // this.ctx.roundRect(posX, posY, 20, 20, 4);
        this.ctx.roundRect(posX - width / 2 - 7, posY - 17, width + 15, parseInt(this.ctx.font, 10) + 10, 4);
        this.ctx.fill();
        this.ctx.fillStyle = 'white';
        this.ctx.textAlign = 'center';
        this.ctx.fillText(this.numberOfLoggers.toString(), posX, posY);
        this.numberOfLoggers += 1;
    }
    getLoggerName(entityId) {
        return this.sources.find((x) => x.id == entityId).name;
    }
    getDeviceName(entityId) {
        return this.devices.find((x) => x.deviceId == entityId).deviceName;
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], FloorPlanOverview.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], FloorPlanOverview.prototype, "devices", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], FloorPlanOverview.prototype, "floorPlans", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], FloorPlanOverview.prototype, "floorPlanThumbnails", void 0);
__decorate([
    Prop({ type: Object })
], FloorPlanOverview.prototype, "selectedFloorPlan", void 0);
__decorate([
    Prop({ default: null })
], FloorPlanOverview.prototype, "selectedFloorPlanImage", void 0);
__decorate([
    Prop({ default: null })
], FloorPlanOverview.prototype, "selectedFloorPlanLocationData", void 0);
FloorPlanOverview = __decorate([
    Component
], FloorPlanOverview);
export default FloorPlanOverview;
