var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "generals-settings" }, [
    _c("div", { staticClass: "level is-mobile" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.settings.profile.units.title")) + " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "level-right" }, [
        _vm.requestPending
          ? _c(
              "span",
              {
                staticClass:
                  "level-item has-text-warning has-text-weight-semibold is-size-4",
              },
              [_vm._v(" " + _vm._s(_vm.$t("generals.saving_changes")) + " ")]
            )
          : _vm.requestFinished
          ? _c(
              "span",
              {
                staticClass:
                  "level-item has-text-success successfully-saved has-text-weight-semibold is-size-4",
                class: { "hide-opacity": _vm.requestFinished },
              },
              [_vm._v(" " + _vm._s(_vm.$t("generals.changes_saved")) + " ")]
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "table",
      { staticClass: "section-table" },
      _vm._l(_vm.eventTypes, function (type) {
        return _c(
          "tr",
          { key: type.id },
          [
            type.slug !== _vm.UnitNames.DEWPOINT.name &&
            type.slug != _vm.UnitNames.ATMOSPHERICPRESSURE.name
              ? [
                  type.slug == _vm.UnitNames.TEMPERATURE.name
                    ? _c("th", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t(`services.${type.slug}`)) +
                            " / " +
                            _vm._s(_vm.$t(`services.dewpoint`)) +
                            " "
                        ),
                      ])
                    : type.slug == _vm.UnitNames.PRESSURE.name
                    ? _c("th", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t(`services.${type.slug}`)) +
                            " / " +
                            _vm._s(_vm.$t(`services.atmosphericpressure`)) +
                            " "
                        ),
                      ])
                    : _c("th", [
                        _vm._v(_vm._s(_vm.$t(`services.${type.slug}`))),
                      ]),
                  _c(
                    "td",
                    [
                      _c(
                        "b-field",
                        _vm._l(_vm.units[type.type], function (option) {
                          return _c(
                            "b-radio-button",
                            {
                              key: option.identifier,
                              attrs: {
                                expanded: true,
                                "native-value": option.identifier,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.unitChanged(
                                    type,
                                    option.identifier
                                  )
                                },
                              },
                              model: {
                                value: _vm.selectedUnits[type.type],
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectedUnits, type.type, $$v)
                                },
                                expression: "selectedUnits[type.type]",
                              },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `units.${option.name
                                          .split(" ")
                                          .join("_")
                                          .toLowerCase()}`
                                      )
                                    ) +
                                    " "
                                ),
                                option.unit
                                  ? _c("span", [
                                      _vm._v(
                                        " " + _vm._s(`(${option.unit})`) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }