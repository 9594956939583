import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { NotificationType } from '@/entities/notifications/NotificationType';
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import { vxm } from '@/store/store.vuex';
import { NotificationPresetWithCount, NotificationPresetWithSubscriptions } from '@/entities/notifications/NotificationPreset';
import NotificationSchedule from '@/entities/notifications/NotificationSchedule';
import { ApiQueries } from '@/entities/ApiQueries';
var vxDepartmentStore = vxm.departmentStore;
export default class NotificationSubscriptionRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getAllNotificationSubscriptions(userId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Settings}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return NotificationSubscription.fromApi(response.data.data);
    }
    async getNotificationTypes() {
        let url = ApiLinks.Notifications.Types;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return NotificationType.fromApi(response.data.data);
    }
    async deleteNotificationSubscription(subscriptionId, userId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Settings}/${subscriptionId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return JSON.stringify(response.data);
    }
    async deleteAllNotificationSubscriptions(userId, scheduleId = null, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Settings}`;
        let queries = new ApiQueries();
        if (scheduleId) {
            queries.scheduleId = scheduleId;
            if (queries.hasQueries())
                url += `?${queries.toQueryString()}`;
        }
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return JSON.stringify(response.data);
    }
    async updateNotificationSubscription(subscriptionId, data, userId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Settings}/${subscriptionId}`;
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.CREATED || response.status == ApiStatus.SUCCESS)
            return true;
        else
            return JSON.stringify(response.data);
    }
    async createNotificationSubscription(data, userId, departmentId = this.getSelectedDepartmentId(), scheduleId) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Settings}`;
        let queries = new ApiQueries();
        if (scheduleId) {
            queries.scheduleId = scheduleId;
            if (queries.hasQueries())
                url += `?${queries.toQueryString()}`;
        }
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return JSON.stringify(response.data);
        else
            return NotificationSubscription.fromApi(response.data.data);
    }
    async pauseNotification(pauseDto, userId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.EventValidation.Settings}${ApiLinks.EventValidation.Keys.Pause}`;
        let response = await AxiosService.postDataToServer(url, pauseDto, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async getAllNotificationSubscriptionsForUser(userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READSUBS, Subjects.NOTIFICATION_COMPANY)) {
            return [];
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Settings}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return NotificationSubscription.fromApi(response.data.data);
    }
    async getNotificationPresets(userId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Settings}${ApiLinks.NotificationSubscriptions.Presets}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return NotificationPresetWithSubscriptions.fromApi(response.data.data);
    }
    async createNotificationPreset(dto, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.DEPARTMENT_PRESETS)) {
            throw 'Forbidden action createNotificationPreset';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}${ApiLinks.NotificationSubscriptions.Presets}`;
        let response = await AxiosService.postDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return JSON.stringify(response.data);
        else
            return true;
    }
    async updateNotificationPreset(presetId, dto, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}${ApiLinks.NotificationSubscriptions.Presets}/${presetId}`;
        let response = await AxiosService.putDataToServer(url, dto, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return JSON.stringify(response.data);
    }
    async deleteNotificationPreset(presetId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}${ApiLinks.NotificationSubscriptions.Presets}/${presetId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return JSON.stringify(response.data);
    }
    async changeTimetableMode(userId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Mode}`;
        let response = await AxiosService.postDataToServer(url, url, this.ctx);
        if (response.status === ApiStatus.NOT_ALLOWED) {
            return response.data.code;
        }
        else if (response.status != ApiStatus.SUCCESS)
            return JSON.stringify(response.data);
        else
            return true;
    }
    async changeTimetableModeForUser(userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EDITSUBS, Subjects.NOTIFICATION_COMPANY)) {
            throw 'Forbidden action changeTimetableMode';
        }
        return this.changeTimetableMode(userId, departmentId);
    }
    async getNotificationSchedules(userId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Schedules}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return NotificationSchedule.fromApi(response.data.data);
    }
    async deleteNotificationSchedules(scheduleId, userId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Schedules}/${scheduleId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return JSON.stringify(response.data);
    }
    async createNotificationSchedule(dto, userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EDITSUBS, Subjects.NOTIFICATION_COMPANY)) {
            //  throw 'Forbidden action createNotificationSchedule';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Schedules}`;
        let response = await AxiosService.postDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return JSON.stringify(response.data);
        else
            return NotificationSchedule.fromApi(response.data.data);
    }
    async updateNotificationSchedule(scheduleId, dto, userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EDITSUBS, Subjects.NOTIFICATION_COMPANY)) {
            //  throw 'Forbidden action updateNotificationSchedule';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.NotificationSubscriptions.Notifications}/${userId}${ApiLinks.NotificationSubscriptions.Schedules}/${scheduleId}`;
        let response = await AxiosService.putDataToServer(url, dto, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return JSON.stringify(response.data);
    }
    async getNotificationChannels() {
        let response = await AxiosService.getDataFromServer(ApiLinks.Notifications.Channels, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return response.data.data;
    }
    async adminGetAdminNotificationTypes() {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_NOTIFICATIONS_SETTINGS)) {
            throw 'Forbidden action adminGetAdminNotificationTypes';
        }
        let url = ApiLinks.Notifications.AdminTypes;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return NotificationType.fromApi(response.data.data);
    }
    async adminGetNotificationPresets() {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_PRESETS)) {
            throw 'Forbidden action adminGetNotificationPresets';
        }
        let url = `${ApiLinks.Notifications.AdminHome}${ApiLinks.NotificationSubscriptions.Presets}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return NotificationPresetWithCount.fromApi(response.data.data);
    }
    async adminGetNotificationPresetById(id) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_PRESETS)) {
            throw 'Forbidden action adminGetNotificationPresetById';
        }
        let url = `${ApiLinks.Notifications.AdminHome}${ApiLinks.NotificationSubscriptions.Presets}/${id}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return JSON.stringify(response.data);
        else
            return NotificationPresetWithSubscriptions.fromApi(response.data.data);
    }
    async adminCreateNotificationPresets(dto) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_PRESETS)) {
            throw 'Forbidden action adminCreateNotificationPresets';
        }
        let url = `${ApiLinks.Notifications.AdminHome}${ApiLinks.NotificationSubscriptions.Presets}`;
        let response = await AxiosService.postDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return JSON.stringify(response.data);
        else
            return true;
    }
    async adminUpdateNotificationPresets(dto, id) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_PRESETS)) {
            throw 'Forbidden action adminCreateNotificationPresets';
        }
        let url = `${ApiLinks.Notifications.AdminHome}${ApiLinks.NotificationSubscriptions.Presets}/${id}`;
        let response = await AxiosService.putDataToServer(url, dto, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return JSON.stringify(response.data);
        else
            return true;
    }
    async adminDeleteNotificationPreset(id) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.ADMIN_PRESETS)) {
            throw 'Forbidden action adminDeleteNotificationPreset';
        }
        let url = `${ApiLinks.Notifications.AdminHome}${ApiLinks.NotificationSubscriptions.Presets}/${id}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return JSON.stringify(response.data);
    }
    async getAdminAllNotificationSubscriptionsForUser(userId) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_NOTIFICATIONS_SETTINGS)) {
            throw 'Forbidden action getAdminAllNotificationSubscriptionsForUser';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.NotificationSubscriptions.Notifications}${ApiLinks.NotificationSubscriptions.Settings}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return NotificationSubscription.fromApi(response.data.data);
    }
    async createAdminNotificationSubscription(data, userId, scheduleId) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_NOTIFICATIONS_SETTINGS)) {
            throw 'Forbidden action getAdminAllNotificationSubscriptionsForUser';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.NotificationSubscriptions.Notifications}${ApiLinks.NotificationSubscriptions.Settings}`;
        let queries = new ApiQueries();
        if (scheduleId) {
            queries.scheduleId = scheduleId;
            if (queries.hasQueries())
                url += `?${queries.toQueryString()}`;
        }
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return JSON.stringify(response.data);
        else
            return NotificationSubscription.fromApi(response.data.data);
    }
    async updateAdminNotificationSubscription(subscriptionId, data, userId) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_NOTIFICATIONS_SETTINGS)) {
            throw 'Forbidden action getAdminAllNotificationSubscriptionsForUser';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.NotificationSubscriptions.Notifications}${ApiLinks.NotificationSubscriptions.Settings}/${subscriptionId}`;
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.CREATED || response.status == ApiStatus.SUCCESS)
            return true;
        else
            return JSON.stringify(response.data);
    }
    async deleteAdminAllNotificationSubscriptions(userId, scheduleId = null) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_NOTIFICATIONS_SETTINGS)) {
            throw 'Forbidden action getAdminAllNotificationSubscriptionsForUser';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.NotificationSubscriptions.Notifications}${ApiLinks.NotificationSubscriptions.Settings}`;
        let queries = new ApiQueries();
        if (scheduleId) {
            queries.scheduleId = scheduleId;
            if (queries.hasQueries())
                url += `?${queries.toQueryString()}`;
        }
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return JSON.stringify(response.data);
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
