var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c(
          "div",
          { staticClass: "title-with-edit" },
          [
            _c("h2", { staticClass: "subtitle is-4 mr-2" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("admin.component.user.detail.userInfo.title")) +
                  " "
              ),
            ]),
            !_vm.componentIsLoading &&
            _vm.$ability.can(
              _vm.$permActions.UPDATE,
              _vm.$permSubjects.ADMIN_USERS
            )
              ? [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "icon-left": _vm.isEditing
                          ? "times-circle"
                          : "pencil-alt",
                        type: "is-white",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.editUser()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isEditing ? _vm.$t("generals.cancel_edit") : ""
                          )
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm.componentIsLoading
        ? _c(
            "div",
            [_c("b-skeleton", { attrs: { height: "110px", animated: true } })],
            1
          )
        : _c("div", { staticClass: "columns columns-info-form" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t("admin.component.user.detail.userInfo.id"),
                    },
                  },
                  [_c("p", [_vm._v(" " + _vm._s(_vm.user.id) + " ")])]
                ),
                _c(
                  "b-field",
                  {
                    staticClass: "is-align-items-center",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.email"
                      ),
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t("admin.component.user.detail.userInfo.email")
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t("admin.component.user.detail.userInfo.email")
                      ),
                    },
                  },
                  [
                    !_vm.isEditing
                      ? _c("p", [_vm._v(" " + _vm._s(_vm.user.email) + " ")])
                      : _c("b-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:50",
                              expression: "'required|max:50'",
                            },
                          ],
                          attrs: {
                            type: "text",
                            name: _vm.$t(
                              "admin.component.user.detail.userInfo.email"
                            ),
                            expanded: "",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    staticClass: "is-align-items-center",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.username"
                      ),
                    },
                  },
                  [_c("p", [_vm._v(" " + _vm._s(_vm.user.username) + " ")])]
                ),
                _c(
                  "b-field",
                  {
                    staticClass: "is-align-items-center",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.fullname"
                      ),
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t(
                            "admin.component.user.detail.userInfo.fullname"
                          )
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t("admin.component.user.detail.userInfo.fullname")
                      ),
                    },
                  },
                  [
                    !_vm.isEditing
                      ? _c("p", [_vm._v(" " + _vm._s(_vm.user.fullName) + " ")])
                      : _c("b-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:50",
                              expression: "'required|max:50'",
                            },
                          ],
                          attrs: {
                            type: "text",
                            name: _vm.$t(
                              "admin.component.user.detail.userInfo.fullname"
                            ),
                            expanded: "",
                          },
                          model: {
                            value: _vm.fullname,
                            callback: function ($$v) {
                              _vm.fullname = $$v
                            },
                            expression: "fullname",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.company"
                      ),
                    },
                  },
                  [
                    _vm.departments.length > 0
                      ? _c(
                          "ul",
                          { staticClass: "disc-style ml-5-mobile" },
                          _vm._l(_vm.departments, function (department) {
                            return _c(
                              "li",
                              {
                                key: department.Department.Id,
                                staticClass: "pb-2",
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "adminCompanyDetail",
                                        params: {
                                          departmentId:
                                            department.Department.Id,
                                          title: _vm.user.email,
                                          lang: _vm.$route.params.lang,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(department.Department.Name) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("b-tag", { attrs: { type: "is-light" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        `users.roles.department_roles.${department.Member.Role.Key}`
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _c("span", [_vm._v("-")]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.registeredAt"
                      ),
                    },
                  },
                  [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(_vm.user.registeredAt * 1000),
                              null,
                              null,
                              null
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.loggedSince"
                      ),
                    },
                  },
                  [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(_vm.user.lastLogin * 1000),
                              null,
                              null,
                              null
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "b-field",
                  {
                    staticClass: "is-align-items-center",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.role"
                      ),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-justify-content-space-between is-align-items-center",
                      },
                      [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `users.roles.system_roles.${_vm.user.role.Key}`
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "level-item",
                                attrs: { size: "is-small", type: "is-primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openChangeRoleModal()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.user.detail.userInfo.actions.change"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "b-field",
                  {
                    staticClass: "is-align-items-center",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.verified"
                      ),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-justify-content-space-between is-align-items-center",
                      },
                      [
                        _c("p", [
                          _vm._v(" " + _vm._s(_vm.user.verified) + " "),
                        ]),
                        _vm.$ability.can(
                          _vm.$permActions.UPDATE,
                          _vm.$permSubjects.ADMIN_USERS
                        )
                          ? [
                              !_vm.user.verified
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: {
                                        size: "is-small",
                                        type: "is-primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.verifyUser(
                                            !_vm.user.verified
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "admin.component.user.detail.userInfo.actions.verify"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: {
                                        size: "is-small",
                                        type: "is-primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.verifyUser(
                                            !_vm.user.verified
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "admin.component.user.detail.userInfo.actions.unverify"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "b-field",
                  {
                    staticClass: "is-align-items-center",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.enabled"
                      ),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-justify-content-space-between is-align-items-center",
                      },
                      [
                        _c("p", [_vm._v(" " + _vm._s(_vm.user.enabled) + " ")]),
                        !_vm.user.enabled &&
                        _vm.$ability.can(
                          _vm.$permActions.ENABLE,
                          _vm.$permSubjects.ADMIN_USERS
                        )
                          ? _c(
                              "b-button",
                              {
                                staticClass: "level-item",
                                attrs: { size: "is-small", type: "is-primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.enableUser()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.user.detail.userInfo.actions.enable"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.user.enabled &&
                        _vm.$ability.can(
                          _vm.$permActions.DISABLE,
                          _vm.$permSubjects.ADMIN_USERS
                        )
                          ? _c(
                              "b-button",
                              {
                                staticClass: "level-item",
                                attrs: { size: "is-small", type: "is-primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.disableUser()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.user.detail.userInfo.actions.disable"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                !_vm.user.enabled && _vm.user.actionReason
                  ? _c(
                      "b-field",
                      {
                        staticClass: "is-align-items-center",
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "admin.component.user.detail.userInfo.disable_reason"
                          ),
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "is-flex is-justify-content-space-between is-align-items-center",
                          },
                          [
                            _c("p", [
                              _vm._v(" " + _vm._s(_vm.user.actionReason) + " "),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "b-field",
                  {
                    staticClass: "is-align-items-center",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "admin.component.user.detail.userInfo.phone_number"
                      ),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-justify-content-space-between is-align-items-center",
                      },
                      [
                        _vm.user.phoneNumber
                          ? [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.user.phoneNumber.Number) +
                                    " "
                                ),
                              ]),
                            ]
                          : [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.settings.profile.user_info.empty_phone_number"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _vm.$ability.can(
                              _vm.$permActions.CREATE,
                              _vm.$permSubjects.ADMIN_USERS_PHONE
                            )
                              ? _c("b-button", {
                                  staticClass: "level-item",
                                  attrs: {
                                    size: "is-small",
                                    "icon-left": _vm.user.phoneNumber
                                      ? "pen"
                                      : "plus",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addPhoneNumber()
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.user.phoneNumber &&
                            _vm.$ability.can(
                              _vm.$permActions.DELETE,
                              _vm.$permSubjects.ADMIN_USERS_PHONE
                            )
                              ? _c("b-button", {
                                  staticClass: "level-item",
                                  attrs: {
                                    size: "is-small",
                                    type: "is-primary",
                                    "icon-left": "trash",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteNumber()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                ),
                _vm.user.phoneNumber
                  ? _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "admin.component.user.detail.userInfo.phone_number_verified"
                          ),
                        },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.user.phoneNumber.Verified
                                  ? _vm.$t("generals.yes")
                                  : _vm.$t("generals.no")
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
      _vm.isEditing
        ? _c(
            "div",
            { staticClass: "has-text-right" },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: !_vm.hasChanges, type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveChanges()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("generals.save_changes")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.user.customerData && _vm.user.customerData.connection
        ? _c("div", { staticClass: "mt-4" }, [
            _c("div", { staticClass: "section-header-level" }, [
              _c("h2", { staticClass: "subtitle is-4" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("component.register.additional_info.title")) +
                    " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "columns columns-info-form label-grow-4" },
              [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "component.register.additional_info.connection"
                          ),
                        },
                      },
                      [
                        _vm.user.customerData.connection
                          ? _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      `component.register.additional_info.connection_type.${_vm.user.customerData.connection}`
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("p", [_vm._v("-")]),
                      ]
                    ),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "component.register.additional_info.signal_range"
                          ),
                        },
                      },
                      [
                        _vm.user.customerData.connectionRange
                          ? _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user.customerData.connectionRange
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("p", [_vm._v("-")]),
                      ]
                    ),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "component.register.additional_info.logger_no"
                          ),
                        },
                      },
                      [
                        _vm.user.customerData.loggerCount
                          ? _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.user.customerData.loggerCount) +
                                  " "
                              ),
                            ])
                          : _c("p", [_vm._v("-")]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "component.register.additional_info.address"
                          ),
                        },
                      },
                      [
                        _vm.user.customerData.address
                          ? _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.user.customerData.address) +
                                  " "
                              ),
                            ])
                          : _c("p", [_vm._v("-")]),
                      ]
                    ),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t(
                            "component.register.additional_info.room_no"
                          ),
                        },
                      },
                      [
                        _vm.user.customerData.roomCount
                          ? _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.user.customerData.roomCount) +
                                  " "
                              ),
                            ])
                          : _c("p", [_vm._v("-")]),
                      ]
                    ),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          horizontal: "",
                          label: _vm.$t("generals.newsletter"),
                        },
                      },
                      [
                        _vm.user.customerData.newsletter
                          ? _c("b-icon", {
                              attrs: { type: "is-success", icon: "check" },
                            })
                          : _c("b-icon", {
                              attrs: { type: "is-danger", icon: "times" },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isChangeRoleModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isChangeRoleModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("ChangeUserRoleModal", {
            attrs: {
              user: _vm.user,
              departmentScope: false,
              userRoles: _vm.userRoles,
            },
            on: { modalClosed: _vm.closeModal, changeRole: _vm.changeRole },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }