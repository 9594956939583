var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "has-text-centered box is-full-height tier-card" },
    [
      _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "adminTierDetail",
              params: {
                tierId: _vm.tier.id,

                lang: _vm.$route.params.lang,
              },
            },
          },
        },
        [
          _c(
            "h3",
            { staticClass: "is-size-4 has-text-weight-bold has-text-primary" },
            [_vm._v(" " + _vm._s(_vm.tier.displayName) + " ")]
          ),
          _c("h4", [_vm._v("(" + _vm._s(_vm.tier.name) + ")")]),
          _vm.tier.active
            ? _c(
                "p",
                { staticClass: "has-text-success has-text-weight-semibold" },
                [_vm._v(" " + _vm._s(_vm.$t("tiers.active")) + " ")]
              )
            : _c(
                "p",
                { staticClass: "has-text-danger has-text-weight-semibold" },
                [_vm._v(" " + _vm._s(_vm.$t("tiers.inactive")) + " ")]
              ),
          _c(
            "p",
            {
              staticClass: "mt-4 has-text-weight-bold is-size-5 has-text-dark",
            },
            [_vm._v(" " + _vm._s(_vm.$t("tiers.features_list")) + " ")]
          ),
          _c(
            "ul",
            { staticClass: "has-text-dark has-text-left" },
            _vm._l(_vm.tier.tierFeatures, function (feature, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "is-size-6 is-italic",
                  class:
                    _vm.tier.enabledFeatures.find(function (x) {
                      return x.Name == feature.Name
                    }) == null
                      ? "has-text-weight-light has-text-grey has-text-grey-dark-mode"
                      : "has-text-weight-semibold",
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("tiers.features.short." + feature.Name)) +
                        " "
                    ),
                  ]),
                  feature.Value.Quota
                    ? _c("span", [
                        _vm._v("(" + _vm._s(feature.Value.Quota)),
                        feature.Value.AllowOverflow
                          ? _c("span", [_vm._v("+")])
                          : _vm._e(),
                        _vm._v(")"),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }