var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-flex is-justify-content-space-between" },
        [
          _c("p", { staticClass: "mr-2" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("component.location.list.table.name")) + " "
            ),
          ]),
          _c(
            "router-link",
            {
              staticClass: "has-text-right text-wrap-anywhere",
              attrs: {
                to: {
                  name: "logger_detail",
                  params: {
                    locationId: _vm.data.id,
                    departmentId: _vm.$route.params.departmentId,
                    lang: _vm.$route.params.lang,
                  },
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.data.name) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column is-flex is-justify-content-space-between" },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.location.list.table.logger_name")) +
                " "
            ),
          ]),
          _vm.data.logger && _vm.data.logger.serialNumber
            ? _c("span", [
                _vm._v(" " + _vm._s(_vm.data.logger.serialNumber) + " "),
              ])
            : _c("span", [_vm._v(" - ")]),
        ]
      ),
      _c(
        "div",
        { staticClass: "column is-flex is-justify-content-space-between" },
        [
          _c("p", [
            _vm._v(
              " " + _vm._s(_vm.$t("component.location.list.table.state")) + " "
            ),
          ]),
          _c(
            "div",
            [
              _c("b-icon", {
                staticClass: "is-size-7",
                attrs: {
                  type:
                    _vm.data.logger && _vm.data.logger.isOnline == true
                      ? "is-success"
                      : "is-danger",
                  icon: "circle",
                },
              }),
              _vm.data.logger && _vm.data.logger.isOnline == true
                ? _c("span", [_vm._v(_vm._s(_vm.$t("generals.online")))])
                : _c("span", [_vm._v(_vm._s(_vm.$t("generals.offline")))]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "column is-flex is-justify-content-space-between" },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.location.list.table.last_seen")) +
                " "
            ),
          ]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.dateTimeManager.formatTime(
                    new Date(_vm.data.lastSeen * 1000),
                    null,
                    null,
                    null
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "columns is-mobile" }, [
          _c("div", { staticClass: "column is-4 is-label" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.location.list.table.boundaries")) +
                " "
            ),
          ]),
          _c("div", { staticClass: "column is-8 is-value" }, [
            _vm.data.boundaries && _vm.data.boundaries.length > 0
              ? _c(
                  "div",
                  { staticClass: "columns" },
                  _vm._l(_vm.data.boundaries, function (boundary) {
                    return _c(
                      "div",
                      { key: boundary.eventTypeSlug, staticClass: "column" },
                      [
                        _c(
                          "div",
                          { staticClass: "columns is-mobile has-text-right" },
                          [
                            _c("div", { staticClass: "column is-italic" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("services." + boundary.eventTypeSlug)
                                ) + ":"
                              ),
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DecimalConversion.toCommaString(
                                      boundary.lowerAlarmBoundary.Value
                                    ) + boundary.lowerAlarmBoundary.Unit
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "column" }, [
                              boundary.lowerWarningBoundary.Value != null
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DecimalConversion.toCommaString(
                                            boundary.lowerWarningBoundary.Value
                                          ) + boundary.lowerWarningBoundary.Unit
                                        )
                                    ),
                                  ])
                                : _c("span", { staticClass: "text-no-wrap" }, [
                                    _vm._v("-"),
                                  ]),
                            ]),
                            _c("div", { staticClass: "column" }, [
                              boundary.upperWarningBoundary.Value != null
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.DecimalConversion.toCommaString(
                                          boundary.upperWarningBoundary.Value
                                        ) + boundary.upperWarningBoundary.Unit
                                      )
                                    ),
                                  ])
                                : _c("span", { staticClass: "text-no-wrap" }, [
                                    _vm._v("-"),
                                  ]),
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DecimalConversion.toCommaString(
                                      boundary.upperAlarmBoundary.Value
                                    ) + boundary.upperAlarmBoundary.Unit
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "has-text-right" }, [
                  _c("span", { staticClass: "is-italic is-grey" }, [
                    _vm._v(_vm._s(_vm.$t("measurement_status.NotAvailable"))),
                  ]),
                ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }