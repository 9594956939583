var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _vm.isLayoutList
      ? _c("div", { staticClass: "level section-header-level" }, [
          _c("div", { staticClass: "level-left" }, [
            _c("h2", { staticClass: "level-item subtitle is-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.title")
                  ) +
                  " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "columns m-0" }, [
      _c("div", { staticClass: "column p-0" }, [
        _c("table", { staticClass: "info-table table-layout-equal" }, [
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.$t("admin.component.devices.detail.deviceInfo.id"))
                ),
              ]),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.device.deviceId))]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.name")
                  )
                ),
              ]),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.device.deviceName))]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.mac")
                  )
                ),
              ]),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.device.deviceMacAddress))]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.company")
                  )
                ),
              ]),
            ]),
            _c(
              "td",
              [
                _vm.device.company &&
                _vm.device.company.isDeleted == true &&
                _vm.device.instanceState !== _vm.DeviceInstanceState.RETURNED
                  ? _c(
                      "span",
                      [
                        _vm._v(
                          " " + _vm._s(_vm.device.company.companyName) + " "
                        ),
                        _c(
                          "b-tag",
                          { attrs: { type: "is-primary", rounded: "" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "admin.component.devices.detail.deviceInfo.deleted"
                                )
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm.device.company &&
                    _vm.device.instanceState !==
                      _vm.DeviceInstanceState.RETURNED
                  ? [
                      _vm.hasPermissionToViewDepartment(
                        _vm.device.company.companyId
                      )
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "adminCompanyDetail",
                                  params: {
                                    departmentId: _vm.device.company.companyId,
                                    title: _vm.$t(
                                      "admin.component.devices.deviceList.title"
                                    ),
                                    lang: _vm.$route.params.lang,
                                  },
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.device.company.companyName))]
                          )
                        : _c("span", [
                            _vm._v(_vm._s(_vm.device.company.companyName)),
                          ]),
                    ]
                  : _c("span", [_vm._v("-")]),
              ],
              2
            ),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.isAlive")
                  )
                ),
              ]),
            ]),
            _c(
              "td",
              [
                _vm.device.isOnline == false
                  ? _c(
                      "b-tag",
                      { attrs: { type: "is-primary", rounded: "" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.device.list.table.disconnected")
                          )
                        ),
                      ]
                    )
                  : _vm.device.isOnline == true
                  ? _c(
                      "b-tag",
                      { attrs: { type: "is-success", rounded: "" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.device.list.table.connected")
                          )
                        ),
                      ]
                    )
                  : _c("span", [_vm._v("-")]),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.enabled")
                  )
                ),
              ]),
            ]),
            _c(
              "td",
              [
                _c("b-icon", {
                  attrs: { icon: _vm.device.enabled ? "check" : "times" },
                }),
                _vm.device.enabled == false
                  ? _c(
                      "span",
                      { staticClass: "has-text-primary has-text-weight-bold" },
                      [
                        _vm._v(
                          "(" +
                            _vm._s(
                              _vm.$t(
                                "admin.component.devices.detail.deviceInfo.disabled_note"
                              )
                            ) +
                            ")"
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.deviceInfo.last_seen"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.dateTimeManager.formatTime(
                      new Date(_vm.device.lastSeen * 1000)
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.reg_key")
                  )
                ),
              ]),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.device.registrationKey))]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.hw_key")
                  )
                ),
              ]),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.device.deviceHardwareKey))]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.type")
                  )
                ),
              ]),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.device.type))]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.state")
                  )
                ),
              ]),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.device.state))]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.deviceInfo.instanceState"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.device.instanceState))]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "column p-0" }, [
        _c("table", { staticClass: "info-table table-layout-equal" }, [
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("admin.component.devices.detail.deviceInfo.interval")
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.device.currentConfig
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.device.currentConfig.interval + "s") +
                        " "
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.deviceInfo.maxOutOfRangeTime"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.device.currentConfig
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.device.currentConfig.additionalData
                            .maxOutOfRangeTime
                            ? _vm.device.currentConfig.additionalData
                                .maxOutOfRangeTime + "s"
                            : _vm.$t("generals.na")
                        ) +
                        " "
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.deviceInfo.offlineSyncInterval"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.device.currentConfig
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.device.currentConfig.additionalData
                            .offlineSyncInterval
                            ? _vm.device.currentConfig.additionalData
                                .offlineSyncInterval + "s"
                            : _vm.$t("generals.na")
                        ) +
                        " "
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.deviceInfo.scanTimeout"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.device.currentConfig
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.device.currentConfig.additionalData.scanTimeout
                            ? _vm.device.currentConfig.additionalData
                                .scanTimeout + "s"
                            : _vm.$t("generals.na")
                        ) +
                        " "
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.deviceInfo.checkInterval"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.device.currentConfig
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.device.currentConfig.additionalData.checkInterval
                            ? _vm.device.currentConfig.additionalData
                                .checkInterval + "s"
                            : _vm.$t("generals.na")
                        ) +
                        " "
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.deviceInfo.chunkSize"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.device.currentConfig
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.device.currentConfig.additionalData.chunkSize
                        ) +
                        " "
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
          _c("tr", { staticClass: "text-wrap-anywhere" }, [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.deviceInfo.description"
                    )
                  )
                ),
              ]),
            ]),
            _vm.device.description
              ? _c("td", [_vm._v(_vm._s(_vm.device.description))])
              : _c("td", [_vm._v("-")]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }