var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.isLoggedFromAdmin
        ? _c(
            "b-tooltip",
            {
              attrs: {
                label: _vm.$t(
                  "component.organization_card.tooltip.acknowledged"
                ),
                position: "is-top",
                type: "is-dark",
              },
            },
            [
              _vm.isAcknowledged
                ? _c("b-icon", {
                    attrs: {
                      "icon-pack": "fas",
                      icon: "check-circle",
                      type: "is-success",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-tooltip",
        {
          attrs: {
            label: _vm.$t(
              "component.organization_card.tooltip.plannedMaintenance"
            ),
            position: "is-top",
            type: "is-dark",
          },
        },
        [
          _c("b-icon", {
            attrs: {
              "icon-pack": "fas",
              icon: "wrench",
              type: _vm.getIssueByType(_vm.IssueType.MAINTENANCE)
                ? "is-info"
                : "is-light",
            },
          }),
        ],
        1
      ),
      !_vm.isLoggedFromAdmin
        ? _c(
            "b-tooltip",
            {
              attrs: {
                label: _vm.$t("component.organization_card.tooltip.fine"),
                position: "is-top",
                type: "is-dark",
              },
            },
            [
              _c("b-icon", {
                attrs: {
                  "icon-pack": "fas",
                  icon: "check",
                  type: _vm.fineState ? "is-light" : "is-success",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-tooltip",
        {
          attrs: {
            label: _vm.$t("component.organization_card.tooltip.warning"),
            position: "is-top",
            type: "is-dark",
          },
        },
        [
          _c("b-icon", {
            attrs: {
              "icon-pack": "fas",
              icon: "exclamation-triangle",
              type: _vm.getIssueByType(_vm.IssueType.ALARM, "Warning")
                ? "is-warning"
                : "is-light",
            },
          }),
        ],
        1
      ),
      _c(
        "b-tooltip",
        {
          attrs: {
            label: _vm.$t("component.organization_card.tooltip.alarm"),
            position: "is-top",
            type: "is-dark",
          },
        },
        [
          _c("b-icon", {
            attrs: {
              "icon-pack": "fas",
              icon: "exclamation-circle",
              type: _vm.getIssueByType(_vm.IssueType.ALARM, "Alarm")
                ? "is-danger"
                : "is-light",
            },
          }),
        ],
        1
      ),
      _c(
        "b-tooltip",
        {
          attrs: {
            label: _vm.$t("component.organization_card.tooltip.lowBattery"),
            position: "is-top",
            type: "is-dark",
          },
        },
        [
          _c("b-icon", {
            attrs: {
              "icon-pack": "fas",
              icon: "battery-quarter",
              type: _vm.getIssueByType(_vm.IssueType.LOW_BATTERY)
                ? "is-warning"
                : "is-light",
            },
          }),
        ],
        1
      ),
      _c(
        "b-tooltip",
        {
          attrs: {
            label: _vm.$t("component.organization_card.tooltip.noConnection"),
            position: "is-left",
            type: "is-dark",
          },
        },
        [
          _c("b-icon", {
            attrs: {
              "icon-pack": "fas",
              icon: "wifi",
              type:
                _vm.getIssueByType(_vm.IssueType.SOURCE_OFFLINE) ||
                _vm.getIssueByType(_vm.IssueType.DEVICE_OFFLINE)
                  ? "is-grey"
                  : "is-light",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }