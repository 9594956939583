var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: { active: _vm.isLoading, "can-cancel": false },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _c(
            "div",
            {
              staticClass: "columns is-mobile",
              staticStyle: { margin: "0 !important" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "column is-hidden-touch",
                  class: _vm.hasEditPermissions
                    ? "is-hidden-touch is-one-third"
                    : "",
                },
                [
                  _vm.hasEditPermissions
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "is-size-6 has-text-grey is-hidden-touch",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("generals.selected")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-table",
                    {
                      attrs: {
                        data: _vm.selectedEntities,
                        narrowed: "",
                        "sticky-header": "",
                        "mobile-cards": false,
                        "row-class": (row) => "has-height-30",
                      },
                    },
                    [
                      _c("b-table-column", {
                        attrs: {
                          label: _vm.$t(
                            "table_labels." +
                              _vm.choosedEntityColumn.toLowerCase()
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (props) {
                              return [
                                _c(
                                  "p",
                                  { staticClass: "has-text-weight-medium" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getValue(
                                            _vm.choosedEntityColumn,
                                            props.row
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.isLinkable
                                      ? _c("b-icon", {
                                          staticClass:
                                            "is-hidden-mobile clickable",
                                          attrs: {
                                            type: "is-primary",
                                            icon: "fas fa-external-link-alt",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.getLink(props.row)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.hasEditPermissions
                ? _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "is-size-6 has-text-grey is-hidden-touch",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.group.detail.table.available")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "b-table",
                        {
                          staticClass: "select-location-table",
                          attrs: {
                            data: _vm.filteredTableData,
                            narrowed: "",
                            "sticky-header": "",
                            "mobile-cards": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "empty",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "content has-text-grey" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("generals.no_results")
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2446854285
                          ),
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              field: "action",
                              label: _vm.$t("generals.action"),
                              searchable: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "searchable",
                                  fn: function () {
                                    return [
                                      _c("b-icon", {
                                        staticClass:
                                          "control-icon clickable ml-0",
                                        attrs: {
                                          type: _vm.areSelectedAll()
                                            ? "is-danger"
                                            : "is-success",
                                          icon: _vm.areSelectedAll()
                                            ? "fa-solid fa-minus"
                                            : "fa-solid fa-plus",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.changeStateAll()
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c("b-icon", {
                                        staticClass: "control-icon clickable",
                                        attrs: {
                                          type: _vm.isCurrentlyChoosed(
                                            props.row
                                          )
                                            ? "is-danger"
                                            : "is-success",
                                          icon: _vm.isCurrentlyChoosed(
                                            props.row
                                          )
                                            ? "fa-solid fa-minus"
                                            : "fa-solid fa-plus",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.changeState(props.row)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2892092115
                            ),
                          }),
                          _vm._l(_vm.tableColumns, function (column) {
                            return _c("b-table-column", {
                              key: column,
                              attrs: {
                                label: _vm.$t(
                                  "table_labels." + column.toLowerCase()
                                ),
                                field: column,
                                searchable: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "searchable",
                                    fn: function () {
                                      return [
                                        _c("b-input", {
                                          attrs: {
                                            placeholder:
                                              _vm.$t("generals.search"),
                                            size: "is-small",
                                            icon: "search",
                                          },
                                          model: {
                                            value: _vm.filters[column],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                column,
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "filters[column]",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getValue(column, props.row)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }