import { __decorate } from "tslib";
import UserPreferences from '@/services/UserPreferences';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DeviceState } from '@/entities/enums/DeviceState';
import { DeviceInstanceState } from '@/entities/enums/DeviceInstanceState';
import VueUtilities from '@/services/VueUtilities';
import DeviceRepository from '@/services/repository/DeviceRepository';
import debounce from 'lodash.debounce';
var deviceRepository;
const DEBOUNCE_TIMER = 500;
let AdminRegisterDevicesModal = class AdminRegisterDevicesModal extends Vue {
    constructor() {
        super(...arguments);
        this.DeviceState = DeviceState;
        this.DeviceInstanceState = DeviceInstanceState;
        this.isLoading = false;
        this.selectedDevices = Array();
        this.currentFilter = [];
        this.page = UserPreferences.LocalStorageKeys.AdminDeviceListPage.defaultValue;
        this.pageSize = 6;
        this.jumpToPageValue = null;
        this.serialNameSearch = '';
        this.handleFilterSerialNameDebounce = debounce((e) => {
            this.filterDevices(e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    async created() {
        deviceRepository = new DeviceRepository(this);
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadAdminDevices', this.pagination, this.currentFilter);
    }
    filterDevices(event) {
        if (typeof event === 'string') {
            event = event.includes(',') ? event.replace(/,/g, '\\,') : event;
        }
        let index = this.filterExists('SerialName@=');
        if (index !== -1)
            this.currentFilter[index] = 'SerialName@=' + event;
        else
            this.currentFilter.push('SerialName@=' + event);
        this.onOptionsChange(this.pagination);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination);
    }
    onOptionsChange(pagination) {
        this.$emit('loadAdminDevices', pagination, this.currentFilter);
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    registerDevices() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let allPromises = new Array();
                this.selectedDevices.forEach((device) => {
                    allPromises.push(deviceRepository.adminRegisterDevice(device.deviceId, this.companyId));
                });
                await Promise.all(allPromises).then((response) => {
                    if (response.find((x) => x !== true)) {
                        VueUtilities.openErrorToast(this, this.$t('admin.component.company.registerCompanyDevicesModal.failure'));
                    }
                    else {
                        VueUtilities.openSuccessToast(this, this.$t('admin.component.company.registerCompanyDevicesModal.success'));
                        this.resultRetrieved(true);
                        this.$emit('loadDevicesAndLoggers');
                    }
                    this.isLoading = false;
                });
            }
        });
    }
};
__decorate([
    Prop({ type: String })
], AdminRegisterDevicesModal.prototype, "companyId", void 0);
__decorate([
    Prop({ type: Object })
], AdminRegisterDevicesModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Number })
], AdminRegisterDevicesModal.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminRegisterDevicesModal.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Array })
], AdminRegisterDevicesModal.prototype, "adminDevices", void 0);
AdminRegisterDevicesModal = __decorate([
    Component({})
], AdminRegisterDevicesModal);
export default AdminRegisterDevicesModal;
