var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-level-header" }, [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(" " + _vm._s(_vm.$t("component.invitation.received")) + " "),
        ]),
      ]),
      _c(
        "b-table",
        {
          staticClass: "mt-4",
          attrs: {
            data: _vm.invitations,
            "row-class": (row) =>
              row.department.CompanyId === _vm.deptId ? "row-highlight" : "",
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("component.invitation.empty")) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "departmentName",
              label: _vm.$t("component.invitation.departmentName"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (invitation) {
                  return [
                    _vm._v(
                      " " + _vm._s(invitation.row.department.CompanyName) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "validUntil",
              label: _vm.$t("component.invitation.validUntil"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (invitation) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(invitation.row.validUntil * 1000)
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: { field: "action", label: _vm.$t("generals.action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (invitation) {
                  return [
                    invitation.row.state === "Pending"
                      ? _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c("b-button", {
                              staticClass: "is-size-7",
                              attrs: {
                                type: "is-success",
                                label: _vm.$t("component.invitation.accept"),
                                "icon-left": "check",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.acceptInv(invitation.row)
                                },
                              },
                            }),
                            _c("b-button", {
                              staticClass: "is-size-7",
                              attrs: {
                                type: "is-primary",
                                label: _vm.$t("component.invitation.decline"),
                                "icon-left": "times",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.declineInv(invitation.row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }