import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import EventDomain from '@/entities/enums/eventDomains';
var companyLoggerRepository;
var eventTypeRepository;
let PublishLoggerModal = class PublishLoggerModal extends Vue {
    constructor() {
        super(...arguments);
        this.serialNumber = null;
        this.hasError = false;
        this.errorMsg = null;
        this.availableTypes = [];
        this.selectedEventTypes = [];
        this.isLoading = true;
    }
    async created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        this.loadData();
    }
    async loadData() {
        let allTypes = await eventTypeRepository.adminGetAllTypes();
        this.availableTypes = allTypes.filter((x) => x.domain === EventDomain.MEASURED);
        this.isLoading = false;
    }
    async select() {
        await this.checkSerialNumber(this.serialNumber);
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.hasError) {
                this.$emit('serialNumberSelected', this.serialNumber, this.selectedEventTypes);
            }
        });
    }
    async checkSerialNumber(sn) {
        if (sn) {
            let allPromises = [];
            allPromises.push(companyLoggerRepository.adminValidateSerialNumber(sn));
            allPromises.push(companyLoggerRepository.adminCheckIfSerialNumberExists(sn));
            Promise.all(allPromises).then((response) => {
                this.processLoadedData(response[0], response[1]);
            });
        }
    }
    processLoadedData(serialNumberValidResponse, serialNumberExistResponse) {
        this.serialNumberValid = serialNumberValidResponse;
        this.serialNumberExist = serialNumberExistResponse;
        this.hasError = this.checkSnError();
        this.errorMsg = this.checkErrorMsg();
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    checkSnError() {
        if (!this.serialNumber ||
            this.serialNumber.length <= 0 ||
            this.serialNumberValid?.Valid == false ||
            this.serialNumberExist?.Available == false) {
            return true;
        }
        else
            return false;
    }
    checkErrorMsg() {
        if (!this.serialNumber || this.serialNumber.length <= 0) {
            return this.$t('admin.component.loggers.publishModal.serialNumberEmpty_msg');
        }
        else if (this.serialNumberValid?.Valid == false) {
            return this.$t('admin.component.loggers.publishModal.invalidSerialNumber_msg');
        }
        else if (this.serialNumberExist?.Exist == true && this.serialNumberExist?.Available == false) {
            return this.$t('admin.component.loggers.publishModal.existingSerialNumber_msg');
        }
        else if (this.serialNumberExist?.Exist == false && this.serialNumberExist?.Available == false) {
            return this.$t('admin.component.loggers.publishModal.invalidSerialNumber_msg');
        }
        else {
            return null;
        }
    }
};
__decorate([
    Prop({ type: Object })
], PublishLoggerModal.prototype, "logger", void 0);
PublishLoggerModal = __decorate([
    Component({})
], PublishLoggerModal);
export default PublishLoggerModal;
