import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import ReportDetailModal from '@/views/components/reports/ReportDetailModal.vue';
import { ReportGenerationStatus } from '@/entities/enums/ReportGenerationStatus';
import { PdfReportTypes, getIcon } from '@/entities/enums/ReportTypes';
import UserPreferences from '@/services/UserPreferences';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let ReportsList = class ReportsList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.getIcon = getIcon;
        this.ReportGenerationStatus = ReportGenerationStatus;
        this.DateTimeManager = DateTimeManager;
        this.PdfReportTypes = PdfReportTypes;
        this.selectedReport = null;
        this.isReportDetailModalActive = false;
        this.selectedReports = new Array();
        this.sortDirection = UserPreferences.LocalStorageKeys.ReportsListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.ReportsListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.ReportsListPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.ReportsListRowsPerPage.defaultValue;
        this.total = 0;
        this.tableIsLoading = false;
        this.tableColumns = {
            from: {
                field: 'from',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportsListFrom)
            },
            to: {
                field: 'to',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportsListTo)
            },
            fileName: {
                field: 'fileName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportsListFileName)
            },
            reportType: {
                field: 'reportType',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportsListReportType)
            },
            state: {
                field: 'state',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportsListState)
            },
            generatedAt: {
                field: 'generatedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportsListGeneratedAt)
            },
            generatedBy: {
                field: 'generatedBy',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportsListGeneratedBy)
            }
        };
    }
    reportsChanged() {
        if (this.selectedReport)
            this.selectedReport = this.reports.find((x) => x.id == this.selectedReport.id);
        this.tableIsLoading = false;
    }
    // older reports hasn't all metadata and can't be regenerated
    hasAllMetadata(report) {
        return !(report.metadata &&
            report.metadata.timezone == null &&
            report.metadata.sourceIds == null &&
            report.metadata.types == null);
    }
    mounted() {
        this.total = this.$refs.table._data.newData.length;
    }
    downloadReport(filename) {
        this.$emit('downloadReport', filename);
    }
    regenerateReport(report) {
        this.$emit('regenerateReport', report);
    }
    removeReport(report) {
        this.$buefy.dialog.confirm({
            title: `${this.$t('reports.report_remove_dalog.title')}`,
            message: `${this.$t('reports.report_remove_dalog.msg')} ${report.fileName ? `(${report.fileName})` : ''}`,
            confirmText: this.$t('reports.report_remove_dalog.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.tableIsLoading = true;
                this.$emit('removeReport', report.id);
                this.closeReportDetailModal();
            }
        });
    }
    deleteSelectedReports() {
        let fileNames = '';
        this.selectedReports.forEach((report, index) => {
            fileNames += report.fileName ? report.fileName + (index == this.selectedReports.length - 1 ? '' : ', \n') : '';
        });
        this.$buefy.dialog.confirm({
            title: `${this.$t('reports.reports_remove_dialog.title')}`,
            message: `${this.$t('reports.reports_remove_dialog.msg')} ${fileNames ? `(${fileNames})` : ``}`,
            confirmText: this.$t('reports.reports_remove_dialog.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.tableIsLoading = true;
                this.$emit('removeReports', this.selectedReports);
            }
        });
    }
    showDetailedInfo(report) {
        this.selectedReport = report;
        this.isReportDetailModalActive = true;
    }
    closeReportDetailModal() {
        this.isReportDetailModalActive = false;
    }
    sortByGeneratedAt(a, b, isAsc) {
        if (a.state == ReportGenerationStatus.PENDING || a.state == ReportGenerationStatus.REQUESTED) {
            return -1;
        }
        if (b.state == ReportGenerationStatus.PENDING || b.state == ReportGenerationStatus.REQUESTED) {
            return 1;
        }
        if (isAsc) {
            return a.generatedAt < b.generatedAt ? -1 : a.generatedAt > b.generatedAt ? 1 : 0;
        }
        else {
            return a.generatedAt < b.generatedAt ? 1 : a.generatedAt > b.generatedAt ? -1 : 0;
        }
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.from.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.ReportsListFrom, event);
                break;
            case this.tableColumns.to.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.ReportsListTo, event);
                break;
            case this.tableColumns.reportType.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.ReportsListReportType, event);
                break;
            case this.tableColumns.state.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.ReportsListState, event);
                break;
            case this.tableColumns.generatedBy.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.ReportsListGeneratedBy, event);
                break;
        }
    }
};
__decorate([
    Prop({ type: Array })
], ReportsList.prototype, "reports", void 0);
__decorate([
    Prop({ type: Object })
], ReportsList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportsList.prototype, "sources", void 0);
__decorate([
    Watch('reports')
], ReportsList.prototype, "reportsChanged", null);
ReportsList = __decorate([
    Component({ components: { ReportDetailModal } })
], ReportsList);
export default ReportsList;
