var ApiResponseCodes;
(function (ApiResponseCodes) {
    ApiResponseCodes[ApiResponseCodes["LOGGER_ACTIVATED"] = 1200] = "LOGGER_ACTIVATED";
    ApiResponseCodes[ApiResponseCodes["LOGGER_DEACTIVATED"] = 1201] = "LOGGER_DEACTIVATED";
    ApiResponseCodes[ApiResponseCodes["SOURCE_ACTIVATED"] = 1300] = "SOURCE_ACTIVATED";
    ApiResponseCodes[ApiResponseCodes["SOURCE_DEACTIVATED"] = 1301] = "SOURCE_DEACTIVATED";
    ApiResponseCodes[ApiResponseCodes["NOTIFICATIONS_MODE_SWITCHED_TO_BASIC"] = 11401] = "NOTIFICATIONS_MODE_SWITCHED_TO_BASIC";
    ApiResponseCodes[ApiResponseCodes["NOTIFICATIONS_MODE_SWITCHED_TO_ADVANCED"] = 11402] = "NOTIFICATIONS_MODE_SWITCHED_TO_ADVANCED";
    ApiResponseCodes[ApiResponseCodes["ACCOUNT_DISABLED"] = 2003] = "ACCOUNT_DISABLED";
    ApiResponseCodes[ApiResponseCodes["ACCOUNT_NOT_VERIFIED"] = 2004] = "ACCOUNT_NOT_VERIFIED";
    ApiResponseCodes[ApiResponseCodes["WRONG_CREDENTIALS"] = 2005] = "WRONG_CREDENTIALS";
    ApiResponseCodes[ApiResponseCodes["UNAUTHORIZED"] = 2006] = "UNAUTHORIZED";
    ApiResponseCodes[ApiResponseCodes["WRONG_TOKEN"] = 2007] = "WRONG_TOKEN";
    ApiResponseCodes[ApiResponseCodes["VALIDATION_ERROR"] = 2999] = "VALIDATION_ERROR";
    ApiResponseCodes[ApiResponseCodes["INVALID_KEY"] = 3000] = "INVALID_KEY";
    ApiResponseCodes[ApiResponseCodes["ENTITY_ALREADY_EXISTS"] = 3004] = "ENTITY_ALREADY_EXISTS";
    ApiResponseCodes[ApiResponseCodes["DEPARTMENT_NOT_FOUND"] = 3040] = "DEPARTMENT_NOT_FOUND";
    ApiResponseCodes[ApiResponseCodes["INVALID_REG_KEY"] = 3052] = "INVALID_REG_KEY";
    ApiResponseCodes[ApiResponseCodes["DEVICE_ALREADY_REGISTERED"] = 3053] = "DEVICE_ALREADY_REGISTERED";
    ApiResponseCodes[ApiResponseCodes["DEVICE_NOT_ACTIVE"] = 3054] = "DEVICE_NOT_ACTIVE";
    ApiResponseCodes[ApiResponseCodes["CANNOT_CHANGE_ROLE"] = 30110] = "CANNOT_CHANGE_ROLE";
    ApiResponseCodes[ApiResponseCodes["SYSTEM_SETTING_EXISTS"] = 30210] = "SYSTEM_SETTING_EXISTS";
    ApiResponseCodes[ApiResponseCodes["FEATURE_DISABLED"] = 30706] = "FEATURE_DISABLED";
    ApiResponseCodes[ApiResponseCodes["QUOTA_REACHED"] = 30707] = "QUOTA_REACHED";
    ApiResponseCodes[ApiResponseCodes["CANNOT_PUBLISH"] = 31104] = "CANNOT_PUBLISH";
    ApiResponseCodes[ApiResponseCodes["REG_KEY_ALREADY_USED"] = 31105] = "REG_KEY_ALREADY_USED";
    ApiResponseCodes[ApiResponseCodes["REG_KEY_STILL_ACTIVE"] = 31106] = "REG_KEY_STILL_ACTIVE";
    ApiResponseCodes[ApiResponseCodes["CANNOT_DELETE_DEVICE"] = 31108] = "CANNOT_DELETE_DEVICE";
    ApiResponseCodes[ApiResponseCodes["FLOOR_PLAN_NOT_FOUND"] = 31313] = "FLOOR_PLAN_NOT_FOUND";
    ApiResponseCodes[ApiResponseCodes["INVALID_PHONE_NUMBER"] = 38100] = "INVALID_PHONE_NUMBER";
    ApiResponseCodes[ApiResponseCodes["CANNOT_SEND_VERIFICATION"] = 38102] = "CANNOT_SEND_VERIFICATION";
    ApiResponseCodes[ApiResponseCodes["CANNOT_CHECK_VERIFICATION"] = 38103] = "CANNOT_CHECK_VERIFICATION";
})(ApiResponseCodes || (ApiResponseCodes = {}));
export default ApiResponseCodes;
