import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import ResellerRepository from '@/services/repository/ResellerRepository';
import { AdminReseller } from '@/entities/models/AdminReseller';
import VueUtilities from '@/services/VueUtilities';
import UserRepository from '@/services/repository/UserRepository';
import AddResellerUsersModal from '@/views/components/admin/reseller/AddResellerUsersModal.vue';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import AddDepartmentsModal from '@/views/components/admin/reseller/AddDepartmentsModal.vue';
import ApiResponse from '@/entities/ApiResponse';
import DeviceRepository from '@/services/repository/DeviceRepository';
import AddResellerDevicesModal from '@/views/components/admin/reseller/AddResellerDevicesModal.vue';
import LoggerRepository from '@/services/repository/LoggerRepository';
import AddResellerLoggersModal from '@/views/components/admin/reseller/AddResellerLoggersModal.vue';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import UserPreferences from '@/services/UserPreferences';
import AdminResellerDepartmentsComponent from '@/views/components/admin/reseller/AdminResellerDepartmentsComponent.vue';
import AdminResellerDevicesComponent from '@/views/components/admin/reseller/AdminResellerDevicesComponent.vue';
import AdminResellerInfoComponent from '@/views/components/admin/reseller/AdminResellerInfoComponent.vue';
import AdminResellerLoggersComponent from '@/views/components/admin/reseller/AdminResellerLoggersComponent.vue';
import AdminResellerUsersComponent from '@/views/components/admin/reseller/AdminResellerUsersComponent.vue';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
var resellerRepository;
var userRepository;
var departmentRepository;
var deviceRepository;
var loggerRepository;
var companyLoggerRepository;
let AdminResellerDetailManagement = class AdminResellerDetailManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.dateTimeManager = null;
        this.reseller = new AdminReseller();
        this.isAddUserModalActive = false;
        this.isAddDepartmentModalActive = false;
        this.isAddDeviceModalActive = false;
        this.isAddLoggerModalActive = false;
        this.resellerId = this.$route.params.resellerId;
        this.members = [];
        this.departments = [];
        this.devices = new ApiResponse();
        this.userList = new ApiResponse();
        this.departmentList = new Array();
        this.deviceList = new Array();
        this.loggerList = new ApiResponse();
        this.loggersWithStates = new ApiResponse();
        this.isEditing = false;
        this.isLayoutList = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IsAdminResellerPageLayoutList);
        this.activeTab = UserPreferences.LocalStorageKeys.IsAdminResellerPageLayoutActiveTab.defaultValue;
        this.departmentsLoaded = false;
        this.devicesLoaded = false;
        this.loggersLoaded = false;
        this.devicesOptions = null;
        this.devicesFilters = [];
        this.loggersOptions = null;
        this.loggersFilters = [];
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        resellerRepository = new ResellerRepository(this);
        userRepository = new UserRepository(this);
        departmentRepository = new DepartmentRepository(this);
        deviceRepository = new DeviceRepository(this);
        loggerRepository = new LoggerRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.reseller = await resellerRepository.getResellerAdmin(this.resellerId);
        this.members = this.reseller.members;
        if (this.isLayoutList) {
            this.departmentsLoaded = true;
            this.devicesLoaded = true;
            this.loggersLoaded = true;
        }
        else {
            this.activeTab = parseInt(this.$route.query.activeTab)
                ? parseInt(this.$route.query.activeTab)
                : UserPreferences.LocalStorageKeys.IsAdminResellerPageLayoutActiveTab.defaultValue;
            this.changedActiveTab(this.activeTab);
        }
        this.isLoading = false;
    }
    async loadUsers(pagination, currentFilter) {
        let res = await userRepository.getUsersAdmin(null, pagination, null, currentFilter);
        this.userList = res;
    }
    async loadDepartments() {
        this.departmentList = (await departmentRepository.getAllDepartmentsAdmin()).getData();
    }
    async loadDevices() {
        let res = await deviceRepository.getAdminDevices();
        this.deviceList = res.getData();
    }
    async loadLoggers() {
        let res = await loggerRepository.getAdminLoggers();
        this.loggerList = res;
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.adminDownloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    openAddUsersModal() {
        this.isAddUserModalActive = true;
    }
    openAddDepartmentsModal() {
        this.isAddDepartmentModalActive = true;
        if (!this.departmentsLoaded) {
            this.departmentsLoaded = true;
        }
    }
    openAddDevicesModal() {
        this.isAddDeviceModalActive = true;
        if (!this.devicesLoaded) {
            this.devicesLoaded = true;
        }
    }
    openAddLoggersModal() {
        this.isAddLoggerModalActive = true;
        if (!this.loggersLoaded) {
            this.loggersLoaded = true;
        }
    }
    closeAddDevicesModal() {
        this.isAddDeviceModalActive = false;
    }
    closeAddLoggersModal() {
        this.isAddLoggerModalActive = false;
    }
    closeAddUsersModal() {
        this.isAddUserModalActive = false;
    }
    closeAddDepartmentsModal() {
        this.isAddDepartmentModalActive = false;
    }
    async loadResellerDepartments(pagination, sort, currentFilter) {
        this.isLoading = true;
        this.departments = await resellerRepository.adminResellerDepartments(this.resellerId, pagination, sort, currentFilter);
        this.isLoading = false;
    }
    async loadResellerGateways(options, filters = []) {
        this.isLoading = true;
        this.devices = await resellerRepository.adminResellerDevices(this.resellerId, options.pagination, options.sort, filters);
        this.devicesOptions = options;
        this.devicesFilters = filters;
        this.isLoading = false;
    }
    async loadResellerLoggers(options, filters = []) {
        this.isLoading = true;
        this.loggersWithStates = await resellerRepository.adminResellerLoggers(this.resellerId);
        this.loggersOptions = options;
        this.loggersFilters = filters;
        this.isLoading = false;
    }
    async unassignLogger(logger) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.reseller.detail.resellerLoggers.list.removeLoggersModal.title')}`,
            message: this.$t('admin.component.reseller.detail.resellerLoggers.list.removeLoggersModal.message', {
                loggerName: logger.currentlyPublishedAs.serialNumber
            }),
            confirmText: `${this.$t('admin.component.reseller.detail.resellerLoggers.list.removeLoggersModal.confirm')}`,
            cancelText: `${this.$t('admin.component.reseller.detail.resellerLoggers.list.removeLoggersModal.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let result = await resellerRepository.adminUnassignLogger(this.resellerId, logger.currentlyPublishedAs.companyLoggerId);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.detail.resellerLoggers.list.removeLoggersModal.success'));
                    this.loadResellerLoggers(this.loggersOptions, this.loggersFilters);
                    this.isLoading = false;
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerLoggers.list.removeLoggersModal.failure'));
                }
            },
            onCancel: () => { }
        });
        this.isLoading = false;
    }
    changeLayout() {
        this.isLayoutList = !this.isLayoutList;
        if (this.isLayoutList) {
            if (!this.departmentsLoaded)
                this.departmentsLoaded = true;
            if (!this.devicesLoaded)
                this.devicesLoaded = true;
            if (!this.loggersLoaded)
                this.loggersLoaded = true;
        }
        else {
            this.activeTab = parseInt(this.$route.query.activeTab)
                ? parseInt(this.$route.query.activeTab)
                : UserPreferences.LocalStorageKeys.IsAdminResellerPageLayoutActiveTab.defaultValue;
            this.changedActiveTab(this.activeTab);
        }
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IsAdminResellerPageLayoutList, this.isLayoutList);
    }
    changedActiveTab(event) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IsAdminDetailPagesLayoutActiveTab, event);
        switch (this.activeTab) {
            case SelectedTab.DEPARTMENTS:
                if (!this.departmentsLoaded) {
                    this.departmentsLoaded = true;
                }
                break;
            case SelectedTab.DEVICES:
                if (!this.devicesLoaded) {
                    this.devicesLoaded = true;
                }
                break;
            case SelectedTab.LOGGERS:
                if (!this.loggersLoaded) {
                    this.loggersLoaded = true;
                }
                break;
        }
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: this.activeTab
            })
        })
            .catch((err) => err);
    }
    editReseller() {
        if (this.isLayoutList === false)
            this.activeTab = SelectedTab.DETAIL;
        this.isEditing = !this.isEditing;
    }
    async unassignDevice(device) {
        if (this.isLoading === true)
            return;
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.reseller.detail.resellerDevices.list.removeDevicesModel.title')}`,
            message: this.$t('admin.component.reseller.detail.resellerDevices.list.removeDevicesModel.message', {
                deviceName: device.deviceName
            }),
            confirmText: `${this.$t('admin.component.reseller.detail.resellerDevices.list.removeDevicesModel.confirm')}`,
            cancelText: `${this.$t('admin.component.reseller.detail.resellerDevices.list.removeDevicesModel.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                this.isLoading = true;
                let result = await resellerRepository.adminUnassignDevice(this.resellerId, device.deviceId);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.detail.resellerDevices.list.removeDevicesModel.success'));
                    this.loadResellerGateways(this.devicesOptions, this.devicesFilters);
                    this.isLoading = false;
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerDevices.list.removeDevicesModel.failure'));
                }
            },
            onCancel: () => { }
        });
        this.isLoading = false;
    }
    deleteReseller(reseller) {
        if (this.isLoading === true)
            return;
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.reseller.deleteReseller.title')}`,
            message: this.$t('admin.component.reseller.deleteReseller.message', {
                resellerName: reseller.name
            }),
            confirmText: `${this.$t('admin.component.reseller.deleteReseller.confirm')}`,
            cancelText: `${this.$t('admin.component.reseller.deleteReseller.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                this.isLoading = true;
                let result = await resellerRepository.adminDeleteReseller(reseller.id);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.deleteReseller.success'));
                    this.$router
                        .push({
                        name: 'adminResellers',
                        params: {
                            lang: this.$route.params.lang
                        }
                    })
                        .catch((err) => err);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.deleteReseller.failure'));
                }
                this.isLoading = false;
            },
            onCancel: () => { }
        });
    }
    async unassignDepartment(department, pagination, sort, currentFilter) {
        if (this.isLoading === true)
            return;
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.reseller.detail.resellerDepartments.list.removeDepartmentModal.title')}`,
            message: this.$t('admin.component.reseller.detail.resellerDepartments.list.removeDepartmentModal.message', {
                departmentName: department.companyName
            }),
            confirmText: `${this.$t('admin.component.reseller.detail.resellerDepartments.list.removeDepartmentModal.confirm')}`,
            cancelText: `${this.$t('admin.component.reseller.detail.resellerDepartments.list.removeDepartmentModal.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                this.isLoading = true;
                let result = await resellerRepository.adminUnassignDepartment(this.resellerId, department.companyId);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.detail.resellerDepartments.list.removeDepartmentModal.success'));
                    this.loadResellerDepartments(pagination, sort, currentFilter);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerDepartments.list.removeDepartmentModal.failure'));
                }
            },
            onCancel: () => { }
        });
        this.isLoading = false;
    }
    async unassignUser(user) {
        if (this.isLoading === true)
            return;
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.reseller.detail.resellerUsers.list.removeUserModal.title')}`,
            message: this.$t('admin.component.reseller.detail.resellerUsers.list.removeUserModal.message', {
                userName: user.fullName
            }),
            confirmText: `${this.$t('admin.component.reseller.detail.resellerUsers.list.removeUserModal.confirm')}`,
            cancelText: `${this.$t('admin.component.reseller.detail.resellerUsers.list.removeUserModal.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                this.isLoading = true;
                let result = await resellerRepository.adminUnassignUser(this.resellerId, user.apiUserId);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.detail.resellerUsers.list.removeUserModal.success'));
                    this.loadData();
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerUsers.list.removeUserModal.failure'));
                }
            },
            onCancel: () => { }
        });
        this.isLoading = true;
    }
    async updateReseller(data, resellerId) {
        this.reseller = await resellerRepository.adminUpdateReseller(resellerId, data);
    }
    async addResellerLoggers(selectedLoggersId) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let result = await resellerRepository.addResellerLoggers(this.resellerId, selectedLoggersId);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.detail.resellerLoggers.list.addLoggerModal.success'));
            this.closeAddLoggersModal();
            this.loadResellerLoggers(this.loggersOptions, this.loggersFilters);
            this.isLoading = false;
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerLoggers.list.addLoggerModal.failure'));
        }
        this.isLoading = false;
    }
    async addResellerDevices(selectedDevicesId) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let result = await resellerRepository.addResellerDevices(this.resellerId, selectedDevicesId);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.detail.resellerDevices.list.addDeviceModal.success'));
            this.closeAddDevicesModal();
            this.loadResellerGateways(this.devicesOptions, this.devicesFilters);
            this.isLoading = false;
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerDevices.list.addDeviceModal.failure'));
        }
        this.isLoading = false;
    }
    async addResellerDepartments(selectedDepartmentsId) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let result = await resellerRepository.addResellerDepartments(this.resellerId, selectedDepartmentsId);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.detail.resellerDepartments.list.addDepartmentModal.success'));
            this.closeAddDepartmentsModal();
            await this.$refs.resDepComponent.onOptionsChange();
            this.isLoading = false;
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerDepartments.list.addDepartmentModal.failure'));
        }
        this.isLoading = false;
    }
    async addResellerUsers(selectedUsersId) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let result = await resellerRepository.addResellerUsers(this.resellerId, selectedUsersId);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.reseller.detail.resellerUsers.list.addUserModal.success'));
            this.closeAddUsersModal();
            this.loadData();
            this.isLoading = false;
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.reseller.detail.resellerUsers.list.addUserModal.failure'));
        }
        this.isLoading = false;
    }
};
AdminResellerDetailManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_RESELLER)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            localStorage.setItem(LocalStorageKeys.ADMIN_PREV_ROUTE, JSON.stringify({
                name: this.$route.name,
                params: this.$route.params,
                meta: { name: this.reseller.name }
            }));
            next();
        },
        components: {
            AddResellerUsersModal,
            AddDepartmentsModal,
            AddResellerDevicesModal,
            AddResellerLoggersModal,
            AdminResellerInfoComponent,
            AdminResellerUsersComponent,
            AdminResellerDepartmentsComponent,
            AdminResellerDevicesComponent,
            AdminResellerLoggersComponent
        }
    })
], AdminResellerDetailManagement);
export default AdminResellerDetailManagement;
var SelectedTab;
(function (SelectedTab) {
    SelectedTab[SelectedTab["DETAIL"] = 0] = "DETAIL";
    SelectedTab[SelectedTab["USERS"] = 1] = "USERS";
    SelectedTab[SelectedTab["DEPARTMENTS"] = 2] = "DEPARTMENTS";
    SelectedTab[SelectedTab["DEVICES"] = 3] = "DEVICES";
    SelectedTab[SelectedTab["LOGGERS"] = 4] = "LOGGERS";
})(SelectedTab || (SelectedTab = {}));
