import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import ValidationRuleScope from '@/entities/notifications/ValidationRuleScope';
import DevicesSelectionComponent from './DevicesSelectionComponent.vue';
import DeviceRepository from '@/services/repository/DeviceRepository';
import VueUtilities from '@/services/VueUtilities';
var deviceRepository;
let TestSirenNotificationModal = class TestSirenNotificationModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedDevices = [];
        this.hasDevicesForSiren = false;
        this.ValidationRuleScope = ValidationRuleScope;
        this.isLoading = false;
    }
    created() {
        deviceRepository = new DeviceRepository(this);
    }
    loadDevices(pagination, currentFilter) {
        this.$emit('loadDevices', pagination, currentFilter);
    }
    closeModal() {
        this.$emit('modalClosed');
    }
    backUpSelectedDevices(selectedDevices) {
        this.selectedDevices = selectedDevices;
        this.hasDevicesForSiren = selectedDevices.length > 0 ? true : false;
    }
    async testDevicesSiren() {
        this.isLoading = true;
        let sirenPromises = new Array();
        this.selectedDevices.forEach((device) => {
            sirenPromises.push(deviceRepository.testSiren(device.deviceId));
        });
        await Promise.all(sirenPromises).then((response) => {
            if (response.find((x) => x !== true)) {
                VueUtilities.openErrorToast(this, this.$t('component.device.testSirenAlert.multipleSirenFailure'));
            }
            else {
                VueUtilities.openSuccessToast(this, this.$t('component.device.testSirenAlert.multipleSirenSuccess'));
                this.resultRetrieved(true);
            }
            this.isLoading = false;
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], TestSirenNotificationModal.prototype, "devicesList", void 0);
__decorate([
    Prop({ type: Boolean })
], TestSirenNotificationModal.prototype, "isLoadingDevices", void 0);
TestSirenNotificationModal = __decorate([
    Component({ components: { DevicesSelectionComponent } })
], TestSirenNotificationModal);
export default TestSirenNotificationModal;
