var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("h3", { staticClass: "subtitle is-4 my-2" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.notification.presets.presets_title")) +
              " "
          ),
        ]),
        _c(
          "div",
          [
            _c(
              "b-button",
              {
                staticClass: "mr-2",
                attrs: { type: "is-primary" },
                on: { click: _vm.assignPreset },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.notification.presets.assign_preset_btn_label"
                    )
                  )
                ),
              ]
            ),
            _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: { click: _vm.createPreset },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.notification.presets.create_preset_btn_label"
                    )
                  ) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _vm.isLoading
            ? _c("b-loading", {
                attrs: {
                  "is-full-page": false,
                  active: _vm.isLoading,
                  "can-cancel": false,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.isLoading = $event
                  },
                },
              })
            : _vm._e(),
          _c(
            "b-table",
            {
              attrs: { data: _vm.presets, striped: "" },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("section", { staticClass: "section" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "content has-text-grey has-text-centered",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.notification.presets.table.empty"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: "name",
                  label: _vm.$t("component.notification.presets.table.name"),
                  width: "20%",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (preset) {
                      return [_vm._v(" " + _vm._s(preset.row.name) + " ")]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "id",
                  label: _vm.$t("component.notification.presets.table.id"),
                  width: "30%",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (preset) {
                      return [_vm._v(" " + _vm._s(preset.row.id) + " ")]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "enabled",
                  label: _vm.$t("component.notification.presets.table.enabled"),
                  width: "10%",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (preset) {
                      return [
                        _c("span", [
                          preset.row.enabled
                            ? _c("i", { staticClass: "fas fa-check" })
                            : _c("i", { staticClass: "fas fa-times" }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "type",
                  label: _vm.$t("component.notification.presets.table.type"),
                  width: "10%",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (preset) {
                      return [_vm._v(" " + _vm._s(preset.row.type) + " ")]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "subscriptionCount",
                  label: _vm.$t(
                    "component.notification.presets.table.subscription_count"
                  ),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (preset) {
                      return [
                        _vm._v(
                          " " + _vm._s(preset.row.subscriptionCount) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "action",
                  label: _vm.$t("component.notification.presets.table.action"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (preset) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "is-flex is-flex-direction-row is-align-items-center",
                          },
                          [
                            _c("b-icon", {
                              staticClass: "control-icon clickable",
                              attrs: { icon: "trash" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.confirmPresetDelete(preset.row)
                                },
                              },
                            }),
                            _c("b-icon", {
                              staticClass: "control-icon clickable",
                              attrs: { icon: "pencil-alt" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.editPreset(preset.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCreateModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeCreateModal,
            "full-screen": true,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCreateModalActive = $event
            },
            "close-modal": _vm.closeCreateModal,
          },
        },
        [
          _c("CreateOrEditPreset", {
            attrs: { useDefaultPreset: true },
            on: { modalClosed: _vm.closeCreateModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isEditModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeEditModal,
            "full-screen": true,
          },
          on: {
            "update:active": function ($event) {
              _vm.isEditModalActive = $event
            },
            "close-modal": _vm.closeEditModal,
          },
        },
        [
          _c("CreateOrEditPreset", {
            attrs: { useDefaultPreset: false, preset: _vm.presetToEdit },
            on: { modalClosed: _vm.closeEditModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isAssignModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeAssignModal,
            "full-screen": true,
          },
          on: {
            "update:active": function ($event) {
              _vm.isAssignModalActive = $event
            },
            "close-modal": _vm.closeAssignModal,
          },
        },
        [
          _c("AssignPreset", {
            attrs: {
              presets: _vm.presets,
              departments: _vm.departments,
              users: _vm.users,
              userDepartments: _vm.userDepartments,
              departmentUsers: _vm.departmentUsers,
              hasAdminOrResellerRole: _vm.hasAdminOrResellerRole,
              isLoading: _vm.isLoading,
            },
            on: {
              loadDepartmentUsers: _vm.loadDepartmentUsers,
              loadUserDepartments: _vm.loadUserDepartments,
              modalClosed: _vm.closeAssignModal,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }