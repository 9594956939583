import { AdminMember } from './AdminMember';
export class AdminReseller {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminReseller.define(et));
        }
        else if (data != null) {
            return AdminReseller.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let reseller = new AdminReseller();
        reseller.id = data.Id;
        reseller.name = data.Name;
        reseller.departmentCount = data.DepartmentsCount;
        reseller.gatewayCount = data.GatewaysCount;
        reseller.loggerCount = data.LoggersCount;
        if (data.Members)
            reseller.members = AdminMember.fromApi(data.Members);
        else {
            reseller.members = [];
        }
        return reseller;
    }
}
