import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import { Department } from '@/entities/models/Department';
let AdminDeviceInstanceHistory = class AdminDeviceInstanceHistory extends Vue {
    constructor() {
        super(...arguments);
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminDeviceInstanceSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminDeviceInstanceSortField.defaultValue;
        this.tableColumns = {
            id: {
                field: 'id',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceInstanceHistoryId)
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceInstanceHistoryCreatedAt)
            },
            returnedAt: {
                field: 'returnedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceInstanceHistoryReturnedAt)
            },
            company: {
                field: 'company',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceInstanceHistoryCompany)
            },
            state: {
                field: 'state',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceInstanceHistoryState)
            }
        };
    }
    hasPermissionToViewDepartment(departmentId) {
        return Department.hasPermissionToViewDepartment(departmentId, this);
    }
    onSort(field, order) {
        this.sortField = field;
        this.sortDirection = order;
    }
    // changing default sort field
    changeDefaultSortField(sortFieldName) {
        if (this.sortField == sortFieldName) {
            this.sortField = this.tableColumns.id.field;
        }
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.createdAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.createdAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceInstanceHistoryCreatedAt, event);
                break;
            case this.tableColumns.company.field:
                if (!event) {
                    this.changeDefaultSortField('company.companyName');
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceInstanceHistoryCompany, event);
                break;
            case this.tableColumns.returnedAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.returnedAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceInstanceHistoryReturnedAt, event);
                break;
            case this.tableColumns.state.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.state.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceInstanceHistoryState, event);
                break;
        }
        this.$nextTick(() => {
            this.$refs.table.initSort();
        });
    }
};
__decorate([
    Prop({ type: Object })
], AdminDeviceInstanceHistory.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceInstanceHistory.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AdminDeviceInstanceHistory.prototype, "deviceInstances", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminDeviceInstanceHistory.prototype, "isLayoutList", void 0);
AdminDeviceInstanceHistory = __decorate([
    Component({})
], AdminDeviceInstanceHistory);
export default AdminDeviceInstanceHistory;
