import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SortDirections from '@/entities/enums/SortDirections';
import UserPreferences from '@/services/UserPreferences';
const PER_PAGE_OPTIONS = [5, 10, 20, 30, 40, 50, 100];
let AdminCompanyDeletedSources = class AdminCompanyDeletedSources extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminDeletedLoggerListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminDeletedLoggerListSortField.defaultValue;
        this.page = 1;
        this.rowsPerPage = UserPreferences.LocalStorageKeys.AdminDeletedLoggerListRowsPerPage.defaultValue;
        this.totalSize = null;
        this.isJumpToPageDisabled = false;
    }
    onDeletedSourcesChange() {
        this.totalSize = this.deletedSourcesApiResponse.getPagination().total;
        if (this.numOfPages === 0) {
            this.isJumpToPageDisabled = !this.isJumpToPageDisabled;
        }
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.rowsPerPage + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.rowsPerPage > this.totalSize ? this.totalSize : this.page * this.rowsPerPage;
    }
    created() {
        this.totalSize = this.deletedSourcesApiResponse.getPagination().total;
        if (this.numOfPages === 0) {
            this.isJumpToPageDisabled = !this.isJumpToPageDisabled;
        }
    }
    mounted() {
        this.changeOrderInBottomSlot();
    }
    updated() {
        this.changeOrderInBottomSlot();
    }
    get numOfPages() {
        return Math.ceil(this.totalSize / this.rowsPerPage);
    }
    async loadSources() {
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = {
            page: this.page,
            pageSize: this.rowsPerPage
        };
        this.$emit('loadDeletedSources', this.$route.params.departmentId, this.pagination, this.sort);
    }
    async reviveDeletedSource(key) {
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.company.detail.deletedSources.dialog.title'),
            message: this.$t('admin.component.company.detail.deletedSources.dialog.msg'),
            confirmText: this.$t('generals.yes'),
            type: 'is-primary',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('reviveSource', this.$route.params.departmentId, key);
            }
        });
    }
    onSort(field, order) {
        this.sortField = field;
        this.sortDirection = order;
        this.loadSources();
    }
    rowsPerPageSelected() {
        this.loadSources();
    }
    onPageChange(page) {
        this.page = page;
        this.loadSources();
    }
    changeOrderInBottomSlot() {
        // find every element in DOM
        let elements = document.querySelectorAll('.b-table .top');
        for (const element of [...elements]) {
            if (!element.classList.contains('table-bottom-slot')) {
                // add class for some css
                element.className += ' table-bottom-slot';
                for (const child of [...element.children]) {
                    // replace parent with child elements - useless div
                    child.replaceWith(...child.childNodes);
                }
            }
        }
    }
};
__decorate([
    Prop({ type: Object })
], AdminCompanyDeletedSources.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyDeletedSources.prototype, "deletedSourcesApiResponse", void 0);
__decorate([
    Watch('deletedSourcesApiResponse')
], AdminCompanyDeletedSources.prototype, "onDeletedSourcesChange", null);
AdminCompanyDeletedSources = __decorate([
    Component
], AdminCompanyDeletedSources);
export default AdminCompanyDeletedSources;
