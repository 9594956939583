var ApiUserRoles;
(function (ApiUserRoles) {
    ApiUserRoles["COMPANY_ADMIN"] = "companyAdmin";
    ApiUserRoles["COMPANY_OWNER"] = "companyOwner";
    ApiUserRoles["COMPANY_USER"] = "companyUser";
})(ApiUserRoles || (ApiUserRoles = {}));
export default ApiUserRoles;
export var SystemUserRoles;
(function (SystemUserRoles) {
    SystemUserRoles["CUSTOMER"] = "customer";
    SystemUserRoles["SYSTEM_ADMIN"] = "systemAdmin";
    SystemUserRoles["RESELLER_ADMIN"] = "resellerAdmin";
})(SystemUserRoles || (SystemUserRoles = {}));
