var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("h2", { staticClass: "subtitle is-4 my-2" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.notifications_list.title")) + " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _vm.canDelete
              ? _c(
                  "b-button",
                  {
                    attrs: {
                      type: "is-primary",
                      "icon-left": "trash",
                      disabled: _vm.checkedRows.length <= 0,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeSelected()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.notifications_list.table.actions.remove_selected"
                        )
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _vm.canDelete
              ? _c(
                  "b-button",
                  {
                    attrs: {
                      type: "is-primary",
                      "icon-left": "exclamation-circle",
                      disabled: _vm.totalSize <= 0,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeAll()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.notifications_list.table.actions.remove_all"
                        )
                      )
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("NotificationTableFilter", {
        attrs: {
          currentFilter: _vm.currentFilter,
          dateRange: _vm.dateRange,
          selectedDepartment: _vm.selectedDepartment,
          departmentList: _vm.departmentList,
          tableColumns: _vm.tableColumns,
          dateTimeManager: _vm.dateTimeManager,
          inNotificationDepartmentPage: _vm.inNotificationDepartmentPage,
        },
        on: {
          filterChanged: _vm.filterNotifications,
          departmentNameChanged: _vm.departmentNameChanged,
          dateRangeChanged: _vm.dateRangeChanged,
          chooseTableColumns: _vm.chooseTableColumns,
          clearFilter: _vm.clearFilter,
        },
      }),
      _c(
        "div",
        { staticClass: "pt-2" },
        [
          _vm.dateTimeManager
            ? _c(
                "b-table",
                {
                  key: _vm.tableKey,
                  staticClass: "notification-table table-wrap",
                  attrs: {
                    data: _vm.parsedNotifications,
                    striped: true,
                    bordered: false,
                    "mobile-cards": true,
                    hoverable: true,
                    "backend-sorting": "",
                    "default-sort-direction": _vm.sortDirection,
                    "default-sort": _vm.sortField,
                    paginated: "",
                    "backend-pagination": "",
                    "per-page": _vm.rowsPerPage,
                    total: _vm.totalSize,
                    "current-page": _vm.page,
                    checkable: _vm.canDelete,
                    "page-input": true,
                    "pagination-order": "is-centered",
                    "checked-rows": _vm.checkedRows,
                    "custom-is-checked": function (a, b) {
                      return a.notificationId === b.notificationId
                    },
                  },
                  on: {
                    sort: _vm.onSort,
                    "update:currentPage": function ($event) {
                      _vm.page = $event
                    },
                    "update:current-page": function ($event) {
                      _vm.page = $event
                    },
                    "page-change": _vm.onPageChange,
                    "update:checkedRows": function ($event) {
                      _vm.checkedRows = $event
                    },
                    "update:checked-rows": function ($event) {
                      _vm.checkedRows = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "bottom-left",
                        fn: function () {
                          return [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.notifications_list.table.row_per_page"
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "b-field",
                              { staticClass: "pl-2 mb-4-mobile" },
                              [
                                _c(
                                  "b-select",
                                  {
                                    on: { input: _vm.rowsPerPageSelected },
                                    model: {
                                      value: _vm.rowsPerPage,
                                      callback: function ($$v) {
                                        _vm.rowsPerPage = $$v
                                      },
                                      expression: "rowsPerPage",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PER_PAGE_OPTIONS,
                                    function (option) {
                                      return _c(
                                        "option",
                                        {
                                          key: option,
                                          domProps: { value: option },
                                        },
                                        [_vm._v(" " + _vm._s(option) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      _vm.totalSize > 0
                        ? {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "is-flex is-justify-content-flex-end",
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "pt-2 has-text-grey" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.company.list.table.results",
                                                {
                                                  from: _vm.fromTableData,
                                                  to: _vm.toTableData,
                                                  total: _vm.totalSize,
                                                }
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("b-table-column", {
                    staticClass: "cell",
                    attrs: {
                      field: _vm.tableColumns.createdAt.field,
                      label: _vm.$t(
                        "component.notifications_list.table." +
                          _vm.tableColumns.createdAt.field
                      ),
                      visible: _vm.tableColumns.createdAt.visible,
                      width: "170",
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.dateTimeManager.formatTime(
                                    new Date(props.row.createdAt * 1000)
                                  )
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      722246337
                    ),
                  }),
                  _c("b-table-column", {
                    staticClass: "cell",
                    attrs: {
                      field: _vm.tableColumns.category.field,
                      label: _vm.$t(
                        "component.notifications_list.table." +
                          _vm.tableColumns.category.field
                      ),
                      visible: _vm.tableColumns.category.visible,
                      width: "100",
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "b-tag",
                                {
                                  attrs: {
                                    rounded: "",
                                    type: props.row.category.type,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "component.notifications_list.category." +
                                          props.row.category.name
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1954503660
                    ),
                  }),
                  _c("b-table-column", {
                    staticClass: "cell",
                    attrs: {
                      field: _vm.tableColumns.title.field,
                      label: _vm.$t(
                        "component.notifications_list.table." +
                          _vm.tableColumns.title.field
                      ),
                      visible: _vm.tableColumns.title.visible,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c("div", [
                                _c("p", {
                                  staticClass: "cell-title",
                                  domProps: {
                                    innerHTML: _vm._s(props.row.title),
                                  },
                                }),
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(props.row.description),
                                  },
                                }),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1526355985
                    ),
                  }),
                  _c("b-table-column", {
                    staticClass: "cell",
                    attrs: {
                      field: _vm.tableColumns.priority.field,
                      label: _vm.$t(
                        "component.notifications_list.table." +
                          _vm.tableColumns.priority.field
                      ),
                      visible: _vm.tableColumns.priority.visible,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "b-tag",
                                {
                                  attrs: {
                                    rounded: "",
                                    type: props.row.priority.type,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "component.notifications_list.priorities." +
                                          props.row.priority.name.toLowerCase()
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2710709067
                    ),
                  }),
                  _vm.canDelete
                    ? _c("b-table-column", {
                        staticClass: "cell",
                        attrs: {
                          field: "Actions",
                          label: _vm.$t(
                            "component.notifications_list.table.action"
                          ),
                          "cell-class": "actions",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (props) {
                                return [
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.markAsRead(
                                              props.row.notificationId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "component.notifications_list.table.actions.remove"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4273909951
                        ),
                      })
                    : _vm._e(),
                  _c("template", { slot: "empty" }, [
                    _c("section", { staticClass: "section" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "content has-text-grey has-text-centered",
                        },
                        [
                          _vm.isParsing
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "component.notifications_list.table.isLoading"
                                    )
                                  )
                                ),
                              ])
                            : _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "component.notifications_list.table.empty"
                                    )
                                  )
                                ),
                              ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }