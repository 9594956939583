import { __decorate } from "tslib";
import BandwidthOptions from '@/entities/enums/BandwidthOptions';
import CodingRates from '@/entities/enums/CodingRates';
import FrequencyTypes from '@/entities/enums/FrequencyTypes';
import SpreadFactorOptions from '@/entities/enums/SpreadFactorOptions';
import TxPowerTypes from '@/entities/enums/TxPowerTypes';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DeviceRepository from '@/services/repository/DeviceRepository';
import VueUtilities from '@/services/VueUtilities';
import debounce from 'lodash.debounce';
const DEBOUNCE_TIMER = 500;
var deviceRepository;
let AdminDeviceConfigurationModal = class AdminDeviceConfigurationModal extends Vue {
    constructor() {
        super(...arguments);
        this.FrequencyTypes = FrequencyTypes;
        this.BandwidthOptions = BandwidthOptions;
        this.TxPowerTypes = TxPowerTypes;
        this.CodingRates = CodingRates;
        this.SpreadFactorOptions = SpreadFactorOptions;
        this.selectedTxSpreadFactor = SpreadFactorOptions.SIX;
        this.selectedRxSpreadFactor = SpreadFactorOptions.SIX;
        this.advertisingInterval = null;
        this.temperatureMeasurementInterval = null;
        this.humidityMeasurementInterval = null;
        this.selectedTxFrequencyType = FrequencyTypes.FORTYFOURA;
        this.selectedRxFrequencyType = FrequencyTypes.FORTYFOURA;
        this.selectedTxFrequency = null;
        this.selectedRxFrequency = null;
        this.selectedTxBandwidth = BandwidthOptions.OPTION1;
        this.selectedRxBandwidth = BandwidthOptions.OPTION1;
        this.selectedTxCodingRateOption = CodingRates.CODINGRATE_4_5;
        this.selectedRxCodingRateOption = CodingRates.CODINGRATE_4_5;
        this.selectedTxCodingRate = null;
        this.selectedRxCodingRate = null;
        this.selectedTxPowerType = TxPowerTypes.SX1261;
        this.selectedTxPower = null;
        this.currentFilter = [];
        this.page = 1;
        this.pageSize = 5;
        this.jumpToPageValue = null;
        this.serialNameSearch = '';
        this.isLoading = false;
        this.selectedAdminDevice = null;
        this.isDeviceConfigVisible = false;
        this.handleFilterSerialNameDebounce = debounce((e) => {
            this.filterDevices(e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    created() {
        deviceRepository = new DeviceRepository(this);
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadAdminDevices', this.pagination, this.currentFilter);
        this.selectTxCodingRate();
        this.selectRxCodingRate();
    }
    filterDevices(event) {
        let index = this.filterExists('SerialName@=');
        if (index !== -1)
            this.currentFilter[index] = 'SerialName@=' + event;
        else
            this.currentFilter.push('SerialName@=' + event);
        this.onOptionsChange(this.pagination);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination);
    }
    onOptionsChange(pagination) {
        this.$emit('loadAdminDevices', pagination, this.currentFilter);
    }
    back() {
        this.isDeviceConfigVisible = false;
        this.selectedAdminDevice = null;
    }
    selectDevice(device) {
        this.selectedAdminDevice = device;
        this.isDeviceConfigVisible = true;
    }
    deviceConfiguration() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (!this.selectedAdminDevice) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.deviceConfigurationModal.selectDeviceMsg'));
                    return;
                }
                let configuration = {
                    LoggerIds: [this.logger.id],
                    Config: {
                        AdvertisingInterval: this.advertisingInterval,
                        TemperatureMeasurementInterval: this.temperatureMeasurementInterval,
                        HumidityMeasurementInterval: this.humidityMeasurementInterval,
                        TxConfig: {
                            Frequency: this.selectedTxFrequency,
                            Bandwidth: this.selectedTxBandwidth,
                            SpreadFactor: this.selectedTxSpreadFactor,
                            CodingRate: this.selectedTxCodingRate,
                            TxPower: this.selectedTxPower
                        },
                        RxConfig: {
                            Frequency: this.selectedRxFrequency,
                            Bandwidth: this.selectedRxBandwidth,
                            SpreadFactor: this.selectedRxSpreadFactor,
                            CodingRate: this.selectedRxCodingRate
                        }
                    }
                };
                let result = await deviceRepository.adminDeviceConfiguration(this.selectedAdminDevice.deviceId, configuration);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.deviceConfigurationModal.success'));
                    this.resultRetrieved(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.deviceConfigurationModal.failure'));
                }
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    selectRxCodingRate(value = null) {
        if (value)
            this.selectedRxCodingRateOption = value;
        switch (this.selectedRxCodingRateOption) {
            case CodingRates.CODINGRATE_4_5:
                this.selectedRxCodingRate = 1;
                break;
            case CodingRates.CODINGRATE_4_6:
                this.selectedRxCodingRate = 2;
                break;
            case CodingRates.CODINGRATE_4_7:
                this.selectedRxCodingRate = 3;
                break;
            case CodingRates.CODINGRATE_4_8:
                this.selectedRxCodingRate = 4;
                break;
        }
    }
    selectTxCodingRate(value = null) {
        if (value)
            this.selectedTxCodingRateOption = value;
        switch (this.selectedTxCodingRateOption) {
            case CodingRates.CODINGRATE_4_5:
                this.selectedTxCodingRate = 1;
                break;
            case CodingRates.CODINGRATE_4_6:
                this.selectedTxCodingRate = 2;
                break;
            case CodingRates.CODINGRATE_4_7:
                this.selectedTxCodingRate = 3;
                break;
            case CodingRates.CODINGRATE_4_8:
                this.selectedTxCodingRate = 4;
                break;
        }
    }
};
__decorate([
    Prop({ type: Object })
], AdminDeviceConfigurationModal.prototype, "logger", void 0);
__decorate([
    Prop({ type: Number })
], AdminDeviceConfigurationModal.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminDeviceConfigurationModal.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Array })
], AdminDeviceConfigurationModal.prototype, "adminDevices", void 0);
AdminDeviceConfigurationModal = __decorate([
    Component({ components: {} })
], AdminDeviceConfigurationModal);
export default AdminDeviceConfigurationModal;
