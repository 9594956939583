import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import DeviceRepository from '@/services/repository/DeviceRepository';
import AppConfig from '@/configLoader';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import ApiLinks from '@/entities/ApiLinks';
import AxiosService from '@/services/api/AxiosService';
import LoggerRepository from '@/services/repository/LoggerRepository';
import VueUtilities from '@/services/VueUtilities';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
var deviceRepository;
var loggerRepository;
let ManufacturingProcessPage = class ManufacturingProcessPage extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.dateTimeManager = null;
        this.deviceList = new Array();
        this.selectedDevice = null;
        this.rssiLimit = -70;
        this.tempLimitUsed = false;
        this.humLimitUsed = false;
        this.minTemperature = null;
        this.maxTemperature = null;
        this.minHumidity = null;
        this.maxHumidity = null;
        this.isScanning = false;
        this.connectedDevice = null;
        this.scannedLoggers = [];
        this.registeredLoggers = [];
        this.showRegistered = false;
        this.selectedDeviceName = null;
    }
    /**
     * Check config if feature is On.
     */
    get manufacturingProcessTurnedOn() {
        return AppConfig.getConfig().features.manufacturing_process == true;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        deviceRepository = new DeviceRepository(this);
        loggerRepository = new LoggerRepository(this);
        this.loadData();
    }
    /**
     * Close websocket connection before page is closed
     */
    beforeDestroy() {
        this.stopScanning();
    }
    /**
     * Load all devices/observers from DB
     */
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(deviceRepository.getAdminDevices());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0]);
        });
    }
    processLoadedDataForPage(deviceList) {
        this.deviceList = deviceList.getData();
        this.isLoading = false;
    }
    /**
     * Check if the logger is already registered (has the LoggerId property) or
     * was registered during the current manufacturing process.
     */
    loggerIsRegistered(logger) {
        return logger.LoggerId || this.registeredLoggers.find((x) => x.MacAddress === logger.MacAddress) != null;
    }
    /**
     * Open connection
     */
    startScanning() {
        this.isScanning = true;
        this.connectedDevice = this.selectedDevice;
        this.listenForLoggers();
    }
    /**
     * Close connection
     */
    stopScanning() {
        this.connectedDevice = null;
        if (this.connection)
            this.connection.stop();
        this.isScanning = false;
    }
    /**
     * Manually create a logger if the conditions are not met.
     */
    forceCreate(logger) {
        this.registerLogger(logger);
    }
    /**
     * Automatically create logger if conditions are met.
     */
    automatedRegistration(logger) {
        if (!logger.LoggerId && logger.AdditionalValues.isValid) {
            this.registerLogger(logger);
        }
    }
    /**
     * Register and publish logger. This is done in one API call
     */
    async registerLogger(logger) {
        let loggerDto = {
            MacAddress: logger.MacAddress,
            SerialNumber: logger.SerialNumber,
            MeasuredEventTypes: logger.AdditionalValues.measuredEvents
        };
        let createResult = await loggerRepository.adminCreateLogger(loggerDto);
        if (createResult == true) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.create_logger.success') + ' ' + logger.SerialNumber);
            logger.AdditionalValues.isRegistered = true;
            logger.AdditionalValues.createdAt = new Date().getTime();
            this.registeredLoggers.push(logger);
            this.$nextTick(() => {
                const container = this.$refs.tableRegisteredContainer;
                container.scrollTop = container.scrollHeight;
            });
        }
        else if (createResult.code == ApiResponseCodes.ENTITY_ALREADY_EXISTS) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.create_logger.already_exists') + ' ' + logger.SerialNumber);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.create_logger.failure') + ' ' + logger.SerialNumber);
        }
    }
    /**
     * Websocket method
     */
    listenForLoggers() {
        let options = {
            accessTokenFactory: () => {
                return new Promise((resolve, reject) => {
                    let token = AxiosService.GetWebSocketOptions(this);
                    if (token == null || token.length === 0)
                        reject();
                    resolve(token);
                });
            }
        };
        //build connection
        this.connection = new HubConnectionBuilder()
            .withUrl(`${AppConfig.getConfig().backend.address}${ApiLinks.LoggerRegistration.Manufacture}`, options)
            .configureLogging(LogLevel.Information)
            .build();
        this.connection
            .start()
            .then(() => {
            this.connection.invoke('joinGroup', this.connectedDevice.deviceId);
            console.log('Started');
        })
            .catch((error) => console.log(error));
        //listen for logger scanned event
        this.connection.on('LoggerScanned', (data) => {
            if (this.showRegistered == false && data.LoggerId) {
                //filter out already registered loggers (registered loggers have LoggerId filled)
                return;
            }
            else {
                let logger = this.processLogger(data);
                this.scannedLoggers.push(logger);
                this.automatedRegistration(logger);
                this.$nextTick(() => {
                    const container = this.$refs.tableContainer;
                    container.scrollTop = container.scrollHeight;
                });
            }
        });
    }
    /**
     * Parse data set status
     */
    processLogger(data) {
        let logger = {
            CompanyLoggerId: data.CompanyLoggerId,
            DeviceId: data.DeviceId,
            LoggerId: data.LoggerId,
            MacAddress: data.MacAddress,
            SerialNumber: data.SerialNumber,
            Timestamp: data.Timestamp,
            MeasuredData: data.MeasuredData,
            ServiceData: data.ServiceData,
            DebugData: data.DebugData,
            AdditionalValues: {}
        };
        logger.AdditionalValues.Rssi = logger.DebugData.find((x) => x.Name == 'RSSI')?.Value;
        logger.AdditionalValues.TempVal = logger.MeasuredData.find((x) => x.Name == 'Temperature')?.Value;
        logger.AdditionalValues.HumVal = logger.MeasuredData.find((x) => x.Name == 'Humidity')?.Value;
        logger.AdditionalValues.measuredEvents = logger.MeasuredData.map((x) => x.Name.toLowerCase());
        this.checkIfLoggerIsValid(logger);
        return logger;
    }
    /**
     * Set logger status (check conditions)
     */
    checkIfLoggerIsValid(logger) {
        logger.AdditionalValues.isValid = true;
        if (logger.AdditionalValues?.Rssi < this.rssiLimit) {
            logger.AdditionalValues.isValid = false;
            logger.AdditionalValues.RssiStatus = false;
        }
        else {
            logger.AdditionalValues.RssiStatus = true;
        }
        if (this.tempLimitUsed == true) {
            if (logger.AdditionalValues.TempVal == null ||
                this.minTemperature > logger.AdditionalValues.TempVal ||
                this.maxTemperature < logger.AdditionalValues.TempVal) {
                logger.AdditionalValues.isValid = false;
                logger.AdditionalValues.TempStatus = false;
            }
            else {
                logger.AdditionalValues.TempStatus = true;
            }
        }
        if (this.humLimitUsed == true) {
            if (logger.AdditionalValues.HumVal == null ||
                this.minHumidity > logger.AdditionalValues.HumVal ||
                this.maxHumidity < logger.AdditionalValues.HumVal) {
                logger.AdditionalValues.isValid = false;
                logger.AdditionalValues.HumStatus = false;
            }
            else {
                logger.AdditionalValues.HumStatus = true;
            }
        }
    }
    /**
     * Device autocomplete method
     */
    filteredDevices(fieldValue) {
        if (fieldValue) {
            return this.deviceList.filter((device) => device.deviceName
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(fieldValue
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')));
        }
        else
            return this.deviceList;
    }
    selectDevice(device) {
        this.selectedDevice = device;
    }
};
ManufacturingProcessPage = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS) &&
                    vm.manufacturingProcessTurnedOn === true) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {}
    })
    /**
     * This component is used as a page during the logger manufacturing process.
     * The admin can define a set of conditions for loggers.
     * When the button is clicked, a WebSocket connection is opened, and all loggers
     * within the range of the selected observer are sent via this WebSocket.
     * If a logger meets all conditions, it is created and published automatically.
     */
], ManufacturingProcessPage);
export default ManufacturingProcessPage;
