import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import AdminHeader from '@/views/components/common/AdminHeader.vue';
import Footer from '@/views/components/common/Footer.vue';
import AdminSideBar from '@/views/components/common/AdminSideBar.vue';
import UserPreferences from '@/services/UserPreferences';
import BackToTop from '../components/common/BackToTop.vue';
let WithAdminSidebar = class WithAdminSidebar extends Vue {
    constructor() {
        super(...arguments);
        this.isExpanded = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.SideMenuExpanded);
    }
    menuExpanded(isExpanded) {
        this.isExpanded = isExpanded;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.SideMenuExpanded, this.isExpanded);
    }
};
WithAdminSidebar = __decorate([
    Component({
        components: {
            AdminHeader,
            Footer,
            AdminSideBar,
            BackToTop
        }
    })
], WithAdminSidebar);
export default WithAdminSidebar;
