var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level mx-3 mx-0-desktop" }, [
        _c(
          "h1",
          { staticClass: "title is-3 is-spaced has-text-centered-touch my-1" },
          [_vm._v(" " + _vm._s(_vm.$t("admin.component.reseller.title")) + " ")]
        ),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "b-button",
              {
                staticClass: "is-hidden-mobile",
                attrs: {
                  type: "is-primary",
                  "icon-pack": "fa",
                  "icon-left": "plus",
                },
                on: {
                  click: function ($event) {
                    return _vm.createReseller()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("admin.component.reseller.addReseller")))]
            ),
            _c("b-button", {
              staticClass: "is-hidden-tablet",
              attrs: {
                type: "is-primary",
                "icon-pack": "fa",
                "icon-left": "plus",
              },
              on: {
                click: function ($event) {
                  return _vm.createReseller()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical mt-2" }, [
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminResellerList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    resellerList: _vm.resellerList,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: {
                    changeRouterUrlFromReseller:
                      _vm.changeRouterUrlFromReseller,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("CreateResellerModal", {
            on: { modalClosed: _vm.closeModal, reload: _vm.loadData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }