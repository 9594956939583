import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AdminCompanyDetail from '@/views/components/admin/company/AdminCompanyDetail.vue';
import ChangeCompanyUserRoleModal from '@/views/components/admin/company/ChangeCompanyUserRoleModal.vue';
import UserRepository from '@/services/repository/UserRepository';
import Actions, { AdminAction } from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DeviceRepository from '@/services/repository/DeviceRepository';
import LoggerRepository from '@/services/repository/LoggerRepository';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import DateTimeManager from '@/services/DateTimeManager';
import TiersRepository from '@/services/repository/TiersRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import { AdminDepartment } from '@/entities/models/AdminDepartment';
import ApiResponse from '@/entities/ApiResponse';
import VueUtilities from '@/services/VueUtilities';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
import PublicDashboardOwnerRepository from '@/services/repository/PublicDashboardOwnerRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import CreateDashboardModal from '@/views/components/publicDashboard/CreateDashboardModal.vue';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import AddCompanyUsersModal from '@/views/components/admin/company/AddCompanyUsersModal.vue';
import EventDomain from '@/entities/enums/eventDomains';
import LoggedUserManager from '@/services/LoggedUserManager';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import AbilityManager from '@/services/permissions/AbilitiesManager';
import InviteUserModal from '@/views/components/administration/InviteUserModal.vue';
import AssignTierToCompanyModal from '@/views/components/admin/tiers/AssignTierToCompanyModal.vue';
import PauseNotificationsModal from '@/views/components/admin/company/PauseNotificationsModal.vue';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
// eslint-disable-next-line vue/max-len
import AdminRegisterLoggersToDepartmentModal from '@/views/components/admin/loggers/AdminRegisterLoggersToDepartmentModal.vue';
import AdminRegisterDevicesModal from '@/views/components/admin/company/AdminRegisterDevicesModal.vue';
import AdminRegisterLoggersModal from '@/views/components/admin/company/AdminRegisterLoggersModal.vue';
import IssueRepository from '@/services/repository/IssueRepository';
import QRCodeScanner from '@/views/components/common/QRCodeScanner.vue';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
var userRepository;
var deviceRepository;
var loggerRepository;
var companyLoggerRepository;
var tiersRepository;
var sourceRepository;
var departmentRepository;
var publicDashboardOwnerRepository;
var sourceGroupRepository;
var eventTypeRepository;
var userSettingsRepository;
var notificationSubscriptionRepository;
var issueRepository;
const SEC_IN_MINUTE = 60;
let AdminCompanyDetailManagement = class AdminCompanyDetailManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.isIssuesTableLoading = false;
        this.department = new AdminDepartment();
        this.userList = new Array();
        this.allUsers = new Array();
        this.availableUsersList = new Array();
        this.totalSize = 0;
        this.deviceList = new ApiResponse();
        this.companyLoggers = new Array();
        this.sources = [];
        this.dateTimeManager = null;
        this.activeTier = null;
        this.tiersHistory = [];
        this.deletedSourcesApiResponse = null;
        this.companySettings = null;
        this.departmentTags = [];
        this.dashboards = [];
        this.isAddDashboardModalActive = false;
        this.sourceGroups = [];
        this.devices = [];
        this.companyId = this.$route.params.companyId;
        this.changeUserRoleModal = false;
        this.isAddUserModalActive = false;
        this.selectedUser = null;
        this.adminDevices = new ApiResponse();
        this.adminLoggers = new ApiResponse();
        this.isInviteModalActive = false;
        this.isAssignTierModalActive = false;
        this.isPauseNotificationsModalActive = false;
        this.isRegisterLoggersModalActive = false;
        this.isScannerModalActive = false;
        this.isRegisterDevicesModalActive = false;
        this.isLoadingAdminDevices = false;
        this.isLoadingAdminLoggers = false;
        this.issuesFilters = [];
        this.departmentIssues = null;
        this.isQRScannerActive = false;
        this.isAddCompanyUsersModalLoading = false;
    }
    async created() {
        eventTypeRepository = EventTypeRepository.getInstance(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        userRepository = new UserRepository(this);
        deviceRepository = new DeviceRepository(this);
        loggerRepository = new LoggerRepository(this);
        issueRepository = new IssueRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        tiersRepository = new TiersRepository(this);
        sourceRepository = new SourceRepository(this);
        departmentRepository = new DepartmentRepository(this);
        publicDashboardOwnerRepository = new PublicDashboardOwnerRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        notificationSubscriptionRepository = new NotificationSubscriptionRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        this.loadData(this.companyId);
    }
    async loadData(companyId) {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(departmentRepository.getDepartmentAdmin(companyId));
        allPromises.push(userRepository.getCompanyUsers(companyId));
        allPromises.push(departmentRepository.getAdminDepartmentSettings(companyId));
        allPromises.push(departmentRepository.getAllDepartmentTagsAdmin());
        allPromises.push(sourceRepository.adminGetAllSources(this.companyId));
        allPromises.push(sourceGroupRepository.adminGetAllGroups(this.$route.params.companyId));
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3], response[4], response[5]);
        });
    }
    processLoadedDataForPage(department, companyUsers, settings, tags, sources, sourceGroups) {
        this.department = department;
        this.userList = companyUsers;
        this.companySettings = settings;
        this.departmentTags = tags;
        // add option to remove tag
        this.departmentTags.push({
            CompanyTagId: -1,
            Tag: 'bez označenia'
        });
        this.sources = sources.getData();
        this.sourceGroups = sourceGroups.getData();
        this.isLoading = false;
    }
    async loadAdminDevices(pagination, currentFilter) {
        this.isLoadingAdminDevices = true;
        this.adminDevices = await deviceRepository.getAdminDevices(pagination, null, currentFilter);
        this.isLoadingAdminDevices = false;
    }
    async loadAdminLoggers(pagination, currentFilter) {
        this.isLoadingAdminLoggers = true;
        this.adminLoggers = await loggerRepository.getAdminLoggers(pagination, null, currentFilter);
        this.isLoadingAdminLoggers = false;
    }
    async loadDevicesForDepartment(options) {
        this.deviceList = await deviceRepository.getAdminDevicesForDepartment(this.companyId, options.pagination, options.sort);
    }
    async loadCompanyUsers(pagination, currentFilter) {
        let sort = { direction: SortDirections.DESCENDING, field: 'RegisteredAt' };
        currentFilter.push('Email!=null');
        this.isAddCompanyUsersModalLoading = true;
        let res = await userRepository.getUsersAdmin(null, pagination, sort, currentFilter);
        this.availableUsersList = res.getData();
        this.totalSize = res.getPagination().total;
        this.isAddCompanyUsersModalLoading = false;
    }
    async loadAllUsers() {
        this.allUsers = (await userRepository.getUsersAdmin()).getData();
    }
    async loadAll() {
        this.loadDevicesAndLoggers();
        this.loadTier();
        this.loadSharedLinks();
    }
    async loadDevicesAndLoggers() {
        let allPromises = [];
        //  allPromises.push(deviceRepository.getAdminDevicesForDepartment(this.companyId));
        allPromises.push(companyLoggerRepository.adminGetCompanyLoggers(this.companyId));
        let sort = {
            field: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeletedLoggerListSortField),
            direction: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeletedLoggerListSortDirection) == 'desc'
                ? SortDirections.DESCENDING
                : SortDirections.ASCENDING
        };
        allPromises.push(sourceRepository.adminGetDeletedSources(this.companyId, null, sort));
        Promise.all(allPromises).then((response) => {
            this.processDevicesAndLoggers(response[0], response[1] /*, response[2]*/);
        });
    }
    processDevicesAndLoggers(
    //  companyDevices: ApiResponse<Array<AdminDevice>>,
    companyLoggers, deletedSources) {
        //  this.deviceList = companyDevices;
        this.companyLoggers = companyLoggers.getData();
        this.deletedSourcesApiResponse = deletedSources;
    }
    acknowledgeIssueDialog(issueId) {
        this.$buefy.dialog.confirm({
            title: this.$t('component.issues.acknowledgeModal.title'),
            message: this.$t('component.issues.acknowledgeModal.body'),
            confirmText: this.$t('component.issues.acknowledgeModal.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.acknowledgeIssue(issueId);
            }
        });
    }
    async acknowledgeIssue(issueId) {
        let result = await issueRepository.acknowledgeIssue(issueId, this.companyId);
        if (result == true) {
            VueUtilities.openSuccessToast(this, this.$t('component.issues.acknowledgeModal.success'));
            this.onIssuesTableOptionsChange(null, this.issuesFilters);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.issues.acknowledgeModal.failure'));
        }
    }
    async onIssuesTableOptionsChange(options, filters = []) {
        this.issuesFilters = filters;
        this.isIssuesTableLoading = true;
        this.departmentIssues = await issueRepository.getDepartmentIssues(options.pagination, options.sort, filters, this.companyId);
        this.isIssuesTableLoading = false;
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.adminDownloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    async loadDevices() {
        this.devices = (await deviceRepository.getDevices(null, null, [], this.companyId)).getData();
    }
    convertDateRange() {
        let today = new Date();
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        let dateRange = [yesterday, today];
        return dateRange.map((x) => this.dateTimeManager.getTimeStringForInputDate(x));
    }
    filterExists(filter) {
        return this.issuesFilters.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value) {
                this.issuesFilters.splice(index, 1);
            }
            else {
                this.issuesFilters[index] = filter + value;
            }
        }
        else if (value)
            this.issuesFilters.push(filter + value);
    }
    async loadTier() {
        let allPromises = [];
        allPromises.push(tiersRepository.getActiveCompanyTier(this.companyId));
        allPromises.push(tiersRepository.getCompanyTiersHistory(this.companyId));
        Promise.all(allPromises).then((response) => {
            this.processTier(response[0], response[1]);
        });
    }
    processTier(activeTier, tiersHistory) {
        this.activeTier = activeTier;
        this.tiersHistory = tiersHistory;
    }
    async loadSharedLinks() {
        this.dashboards = await publicDashboardOwnerRepository.getAllPublicDashboards(this.companyId);
    }
    async login(name, params) {
        LoggedUserManager.clearDepartments();
        let impersonatedAbilities = [Subjects.ALL + '.' + AdminAction];
        this.$ability.update(new AbilityManager(impersonatedAbilities).getAbilities().rules);
        LoggedUserManager.loginToCompany(this, this.department, this.$ability.rules, name, params);
        this.loginSucessfulAsync();
    }
    async deleteCompany(departmentId) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let result = await departmentRepository.deleteAdminDepartment(departmentId);
        if (result == true) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.company.detail.deleteCompanyWarning.success'));
            this.$router
                .push({
                name: 'adminCompanies',
                params: {
                    lang: this.$route.params.lang
                }
            })
                .catch((err) => err);
            return;
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.deleteCompanyWarning.failure'));
        }
        this.isLoading = false;
    }
    async loginSucessfulAsync() {
        await this.$featuresManager.loadTier();
        VueUtilities.openSuccessToast(this, this.$t('component.login.successful').toString());
        eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true, true, this.department.companyId);
        this.$router
            .push({
            name: 'dashboard',
            params: {
                lang: this.$route.params.lang,
                departmentId: this.department.companyId
            }
        })
            .catch((err) => err);
    }
    reloadTiers() {
        let companyId = this.$route.params.companyId;
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(tiersRepository.getActiveCompanyTier(companyId));
        allPromises.push(tiersRepository.getCompanyTiersHistory(companyId));
        Promise.all(allPromises).then((response) => {
            this.activeTier = response[0];
            this.tiersHistory = response[1];
            this.isLoading = false;
        });
    }
    async deactivateTier(tierToActivate) {
        if (this.isLoading === true)
            return;
        let dto = {
            NextTierId: tierToActivate.id,
            DeactivateAt: Math.floor(new Date().getTime() / 1000)
        };
        this.isLoading = true;
        await tiersRepository.deactivateTierForCompany(this.department.companyId, this.activeTier.id, dto);
        this.isLoading = false;
    }
    async deleteTier(tierToDelete) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        await tiersRepository.deleteCompanyTier(this.department.companyId, tierToDelete.id);
        this.isLoading = false;
    }
    async updateCompany(data, departmentId) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let resp = await departmentRepository.adminUpdateDepartment(departmentId, data);
        if (resp === ApiResponseCodes.NOT_REQUIRED_ROLE) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.companyInfo.responsiblePersonError'));
            this.department = await departmentRepository.getDepartmentAdmin(this.department.companyId);
        }
        else if (!(resp instanceof AdminDepartment)) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.companyInfo.error'));
        }
        this.isLoading = false;
    }
    async pauseNotifications(usersPauseNotifications) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let now = Math.floor(new Date().getTime() / 1000);
        let allPromises = new Array();
        let additionalPromises = new Array();
        usersPauseNotifications.forEach(async (userPauseData) => {
            let until = now + userPauseData.interval * SEC_IN_MINUTE;
            let pauseDto = {
                PauseForMinutes: userPauseData.interval,
                PauseByType: 'measuredDataAlarm'
            };
            allPromises.push(await notificationSubscriptionRepository.pauseNotification(pauseDto, userPauseData.apiUserId, this.department.companyId));
            additionalPromises.push(await userSettingsRepository.saveUserSettingForDepartment(userPauseData.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.PAUSE, UserSettingsKeys.UNTIL], until, this.department.companyId));
        });
        await Promise.all(allPromises)
            .then(async (response) => {
            if (response.find((x) => x === false)) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.pauseNotificationsModal.failure'));
            }
            else {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.company.pauseNotificationsModal.success'));
                // additional promises
                await Promise.all(additionalPromises).then((response) => { });
                this.closePauseNotificationsModal();
            }
        })
            .catch((error) => {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.pauseNotificationsModal.failure'));
        });
        this.isLoading = false;
    }
    async unassignUser(user) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.company.detail.companyUsers.list.unassignUserModel.title')}`,
            message: this.$t('admin.component.company.detail.companyUsers.list.unassignUserModel.message', {
                userName: user.fullName
            }),
            confirmText: `${this.$t('admin.component.company.detail.companyUsers.list.unassignUserModel.confirm')}`,
            cancelText: `${this.$t('admin.component.company.detail.companyUsers.list.unassignUserModel.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let result = await userRepository.adminUnassignUser(this.department.companyId, user.apiUserId);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.company.detail.companyUsers.list.unassignUserModel.success'));
                    this.reloadUsers();
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.companyUsers.list.unassignUserModel.failure'));
                }
            },
            onCancel: () => { }
        });
        this.isLoading = false;
    }
    openchangeUserRoleModal(user) {
        this.changeUserRoleModal = true;
        this.selectedUser = user;
    }
    closechangeUserRoleModal() {
        this.changeUserRoleModal = false;
    }
    openAddUsersModal() {
        this.isAddUserModalActive = true;
    }
    closeAddUsersModal() {
        this.isAddUserModalActive = false;
    }
    openInviteUserModal() {
        this.isInviteModalActive = true;
    }
    closeInviteUserModal() {
        this.isInviteModalActive = false;
    }
    openAssignTier() {
        this.isAssignTierModalActive = true;
    }
    closeAssignModal(res) {
        this.isAssignTierModalActive = false;
        if (res == true)
            this.reloadTiers();
    }
    openPauseNotificationsModal() {
        this.isPauseNotificationsModalActive = true;
    }
    closePauseNotificationsModal() {
        this.isPauseNotificationsModalActive = false;
    }
    openRegisterDevicesModal() {
        this.isRegisterDevicesModalActive = true;
    }
    closeRegisterDevicesModal() {
        this.isRegisterDevicesModalActive = false;
    }
    async reloadUsers() {
        this.userList = await userRepository.getCompanyUsers(this.department.companyId);
    }
    async changeRole(user, userRole) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let result = await userRepository.changeCompanyUserRole(user.apiUserId, userRole, this.department.companyId);
        if (result === ApiResponseCodes.CANNOT_CHANGE_ROLE) {
            VueUtilities.openErrorToast(this, this.$t('component.userAdministration.list.change_role.cannot_change', {
                company: this.department.companyName
            }));
            this.isLoading = false;
        }
        else if (result === false) {
            VueUtilities.openErrorToast(this, this.$t('component.userAdministration.list.change_role.failure'));
            this.isLoading = false;
        }
        else {
            VueUtilities.openSuccessToast(this, this.$t('component.userAdministration.list.change_role.success'));
            this.changeUserRoleModal = false;
            await this.reloadUsers();
        }
        this.isLoading = false;
    }
    async updateTier(dto) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        await tiersRepository.updateCompanyTier(this.department.companyId, this.activeTier.id, dto);
        this.isLoading = false;
    }
    async saveSetting(dto) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        this.companySettings = await departmentRepository.createOrUpdateAdminDepartmentSettings(this.department.companyId, dto);
        this.isLoading = false;
    }
    async deleteSetting(key) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let res = await departmentRepository.deleteAdminDepartmentSettings(this.department.companyId, key);
        if (res == true)
            this.companySettings = await departmentRepository.getAdminDepartmentSettings(this.department.companyId);
        this.isLoading = false;
    }
    async loadDeletedSources(departmentId, pagination, sort) {
        this.deletedSourcesTableSort = sort;
        this.deletedSourcesTablePagination = pagination;
        this.deletedSourcesApiResponse = await sourceRepository.adminGetDeletedSources(departmentId, this.deletedSourcesTablePagination, this.deletedSourcesTableSort);
    }
    async reviveSource(departmentId, key) {
        let res = await sourceRepository.reviveDeletedSource(departmentId, key);
        if (res) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.company.detail.deletedSources.restored').toString());
            this.deletedSourcesApiResponse = await sourceRepository.adminGetDeletedSources(departmentId, this.deletedSourcesTablePagination, this.deletedSourcesTableSort);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.deletedSources.not_restored').toString());
        }
    }
    async reloadPublicDashboard() {
        let allPromises = [];
        allPromises.push(publicDashboardOwnerRepository.getAllPublicDashboards(this.$route.params.companyId));
        Promise.all(allPromises).then((response) => {
            this.dashboards = response[0];
        });
    }
    async deleteDashboard(id) {
        let result = await publicDashboardOwnerRepository.deletePublicDashboard(id, this.department.companyId);
        if (result === true) {
            VueUtilities.openSuccessToast(this, this.$t('component.public_dashboard.delete.success'));
            this.reloadPublicDashboard();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.public_dashboard.delete.failure'));
        }
    }
    async createPublicDashboard() {
        let allPromises = [];
        allPromises.push(deviceRepository.getDevices(null, null, null, this.$route.params.companyId));
        await Promise.all(allPromises).then(async (response) => {
            this.devices = response[0].getData();
            this.isAddDashboardModalActive = true;
        });
    }
    onCreateModalClose(result) {
        this.reloadPublicDashboard();
        this.isAddDashboardModalActive = false;
    }
    async disableUserDialog(user) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.company.detail.companyUsers.list.disableUserDialog.title')}`,
            message: this.$t('admin.component.company.detail.companyUsers.list.disableUserDialog.message', {
                userName: user.fullName
            }),
            confirmText: `${this.$t('admin.component.company.detail.companyUsers.list.disableUserDialog.confirm')}`,
            cancelText: `${this.$t('admin.component.company.detail.companyUsers.list.disableUserDialog.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let result = await userRepository.adminDisableDepartmentUser(this.department.companyId, user.apiUserId);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.company.detail.companyUsers.list.disableUserDialog.success'));
                    this.reloadUsers();
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.companyUsers.list.disableUserDialog.failure'));
                }
            }
        });
        this.isLoading = false;
    }
    async enableUserDialog(user) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.company.detail.companyUsers.list.enableUserDialog.title')}`,
            message: this.$t('admin.component.company.detail.companyUsers.list.enableUserDialog.message', {
                userName: user.fullName
            }),
            confirmText: `${this.$t('admin.component.company.detail.companyUsers.list.enableUserDialog.confirm')}`,
            cancelText: `${this.$t('admin.component.company.detail.companyUsers.list.enableUserDialog.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let result = await userRepository.adminEnableDepartmentUser(this.department.companyId, user.apiUserId);
                if (result == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.company.detail.companyUsers.list.enableUserDialog.success'));
                    this.reloadUsers();
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.companyUsers.list.enableUserDialog.failure'));
                }
            }
        });
        this.isLoading = false;
    }
    toggleRegisterLoggersModal() {
        this.isRegisterLoggersModalActive = !this.isRegisterLoggersModalActive;
    }
    scannerModalActive(value) {
        this.isScannerModalActive = value;
    }
    async reloadDepartmentLoggers() {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        this.companyLoggers = (await companyLoggerRepository.adminGetCompanyLoggers(this.companyId)).getData();
        this.isLoading = false;
    }
    toggleScanner() {
        this.isQRScannerActive = !this.isQRScannerActive;
    }
};
AdminCompanyDetailManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_COMPANYLOGGERS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_LOGGERS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS_SOURCES) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS_TIERS) &&
                    vm.$ability.can(Actions.HISTORY, Subjects.ADMIN_DEPARTMENTS_TIERS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS_USERS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            localStorage.setItem(LocalStorageKeys.ADMIN_PREV_ROUTE, JSON.stringify({
                name: this.$route.name,
                params: this.$route.params
            }));
            next();
        },
        components: {
            AdminCompanyDetail,
            CreateDashboardModal,
            ChangeCompanyUserRoleModal,
            AddCompanyUsersModal,
            InviteUserModal,
            AssignTierToCompanyModal,
            PauseNotificationsModal,
            AdminRegisterLoggersToDepartmentModal,
            AdminRegisterDevicesModal,
            AdminRegisterLoggersModal,
            QRCodeScanner
        }
    })
], AdminCompanyDetailManagement);
export default AdminCompanyDetailManagement;
