import Vue from 'vue';
import Router from 'vue-router';
import i18n from '@/lang/lang';
import Login from './views/pages/authentication/Login.vue';
import DemoLogin from './views/pages/authentication/DemoLogin.vue';
import Register from './views/pages/authentication/Register.vue';
import RegistrationSuccessful from './views/pages/authentication/RegistrationSuccessful.vue';
import VerifyAccount from './views/pages/authentication/VerifyAccount.vue';
import VerifyResend from './views/pages/authentication/VerifyResend.vue';
import ForgotPassword from './views/pages/authentication/ForgotPassword.vue';
import ChangePassword from './views/pages/authentication/ChangePassword.vue';
import MasterDashboard from './views/pages/MasterDashboard.vue';
import GroupDashboard from './views/pages/GroupDashboard.vue';
import OrgHomePage from './views/pages/OrgHomePage.vue';
import Reports from './views/pages/Reports.vue';
import NotificationsBoard from './views/pages/NotificationsBoard.vue';
import DeviceManagement from './views/pages/DeviceManagement.vue';
import LoggerManagement from './views/pages/LoggerManagement.vue';
import NotificationsConfiguration from './views/pages/NotificationsConfiguration.vue';
import ProfileManagement from './views/pages/ProfileManagement.vue';
import GroupManagement from './views/pages/GroupManagement.vue';
import ReportManagement from './views/pages/ReportManagement.vue';
import CreateGroup from './views/pages/CreateGroup.vue';
import DeviceConf from './views/pages/DeviceSettings.vue';
import IssueDetail from './views/components/issues/IssueDetail.vue';
import LoggerDetail from './views/pages/LoggerDetail.vue';
import GroupDetail from './views/pages/GroupDetail.vue';
import Settings from './views/pages/Settings.vue';
import Wizard from './views/pages/Wizard.vue';
import CompanyManagement from './views/pages/CompanyManagement.vue';
import UserDetail from './views/pages/UserDetail.vue';
import PublicDashboardsManagement from './views/pages/PublicDashboardsManagement.vue';
import PublicDashboardDetail from './views/pages/PublicDashboardDetail.vue';
import PublicDashboardPage from './views/pages/PublicDashboardPage.vue';
import About from './views/pages/About.vue';
import Feedback from './views/pages/Feedback.vue';
import PrivacyPolicy from './views/pages/PrivacyPolicy.vue';
import ThermalMapPage from './views/pages/ThermalMapPage.vue';
import AdminCompanyManagement from './views/pages/admin/AdminCompanyManagement.vue';
import AdminCompanyDetailManagement from './views/pages/admin/AdminCompanyDetailManagement.vue';
import AdminUserManagement from './views/pages/admin/AdminUserManagement.vue';
import AdminUserDetailManagement from './views/pages/admin/AdminUserDetailManagement.vue';
import AdminDeviceManagement from './views/pages/admin/AdminDeviceManagement.vue';
import AdminDeviceDetailManagement from './views/pages/admin/AdminDeviceDetailManagement.vue';
import AdminLoggerManagement from './views/pages/admin/AdminLoggerManagement.vue';
import AdminResellerManagement from './views/pages/admin/AdminResellerManagement.vue';
import AdminResellerDetailManagement from './views/pages/admin/AdminResellerDetailManagement.vue';
import AdminLoggerDetailManagement from './views/pages/admin/AdminLoggerDetailManagement.vue';
import AdminRegisterLoggersManagement from './views/pages/admin/AdminRegisterLoggersManagement.vue';
import AdminNotificationManagement from './views/pages/admin/AdminNotificationManagement.vue';
import AdminTiersManagement from './views/pages/admin/AdminTiersManagement.vue';
import AdminTierCreate from './views/pages/admin/AdminTierCreate.vue';
import AdminTierDetail from './views/pages/admin/AdminTierDetail.vue';
import AdminFeedbackManagement from './views/pages/admin/AdminFeedbackManagement.vue';
import AdminFeedbackDetail from './views/pages/admin/AdminFeedbackDetail.vue';
import AdminPublicDashboardDetail from './views/pages/admin/AdminPublicDashboardDetail.vue';
import SystemSettingsManagement from './views/pages/admin/SystemSettingsManagement.vue';
import PageNotExist from './views/pages/PageNotExist.vue';
import PublicDashboardNotExist from './views/pages/PublicDashboardNotExist.vue';
import MissingPermissions from './views/pages/MissingPermissions.vue';
import LoggedUserManager from '@/services/LoggedUserManager';
import ApiUserRoles from './entities/enums/ApiUserRoles';
import UserSettingsRepository from './services/repository/UserSettingsRepository';
import AppConfig from '@/configLoader';
import CookiesStorage from './services/CookiesStorage';
import DepartmentRepository from './services/repository/DepartmentRepository';
import AdminFirmwareDetailManagement from './views/pages/admin/AdminFirmwareDetailManagement.vue';
import { vxm } from '@/store/store.vuex';
import UserRepository from './services/repository/UserRepository';
import AbilityManager from '@/services/permissions/AbilitiesManager';
import Subjects from './services/permissions/Subjects';
import { AdminAction } from './services/permissions/Actions';
import { AdminDepartment } from './entities/models/AdminDepartment';
import { FeaturesManager } from '@/entities/tiers/FeaturesManager';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import VueUtilities from './services/VueUtilities';
import ProfileOrganization from '@/views/pages/ProfileOrganization.vue';
import Invitation from '@/views/pages/Invitation.vue';
import NotificationOrganization from '@/views/pages/NotificationOrganization.vue';
var vxDepartmentStore = vxm.departmentStore;
Vue.use(Router);
let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/:lang(en|sk|cs)?',
            component: {
                render: (h) => h('router-view')
            },
            children: [
                {
                    path: '',
                    name: 'home',
                    meta: { layout: 'with-sidebar', needsLogin: true },
                    redirect: (to) => {
                        if (ApiUserRoles.SYSTEM_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey ||
                            ApiUserRoles.RESELLER_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey)
                            return { name: 'adminCompanies' };
                        else
                            return { name: 'dashboard' };
                    }
                },
                {
                    path: 'login',
                    name: 'login',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    props: true,
                    component: Login
                },
                {
                    path: 'login/demo',
                    name: 'demoLogin',
                    meta: { layout: 'default', needsLogin: false },
                    component: DemoLogin
                },
                {
                    path: 'register',
                    name: 'register',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: Register
                },
                {
                    path: 'registrationSuccessful',
                    name: 'registrationSuccessful',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: RegistrationSuccessful
                },
                {
                    path: 'verify',
                    name: 'verify',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: VerifyAccount
                },
                {
                    path: 'verifyResend',
                    name: 'verifyResend',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: VerifyResend
                },
                {
                    path: 'forgotPassword',
                    name: 'forgotPassword',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: ForgotPassword
                },
                {
                    path: 'changePassword',
                    name: 'changePassword',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: ChangePassword
                },
                {
                    path: 'invite',
                    name: 'invite',
                    meta: { layout: 'default', needsLogin: false },
                    component: Invitation
                },
                {
                    path: 'privacyPolicy',
                    name: 'privacyPolicy',
                    meta: { layout: 'default', needsLogin: false },
                    component: PrivacyPolicy
                },
                {
                    path: 'page_not_exist',
                    name: 'pageNotExist',
                    meta: { layout: 'default', needsLogin: false },
                    component: PageNotExist
                },
                {
                    path: 'public_dashboard_not_exist',
                    name: 'publicDashboardNotExist',
                    meta: { layout: 'default', needsLogin: false },
                    component: PublicDashboardNotExist
                },
                {
                    path: 'missing_permissions',
                    name: 'missingPermissions',
                    meta: { layout: 'default', needsLogin: false },
                    component: MissingPermissions
                },
                {
                    path: 'organizations',
                    name: 'organizations',
                    meta: { layout: 'organization', needsLogin: true, hiddenSelectInHeader: true },
                    component: OrgHomePage
                },
                {
                    path: 'profile',
                    name: 'profile',
                    meta: { layout: 'organization', needsLogin: true, hiddenSelectInHeader: true },
                    component: ProfileOrganization
                },
                {
                    path: 'notifications',
                    name: 'notificationsOrg',
                    meta: { layout: 'organization', needsLogin: true, hiddenSelectInHeader: true },
                    component: NotificationOrganization
                },
                {
                    path: ':departmentId/dashboard',
                    name: 'dashboard',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: MasterDashboard
                },
                {
                    path: ':departmentId/group_dashboard',
                    name: 'groupDashboard',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: GroupDashboard
                },
                {
                    path: ':departmentId/reports',
                    name: 'reports',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: Reports
                },
                {
                    path: ':departmentId/thermalMap',
                    name: 'thermalMap',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: ThermalMapPage
                },
                {
                    path: ':departmentId/notifications',
                    name: 'notifications',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: NotificationsBoard
                },
                {
                    path: ':departmentId/configuration/devices',
                    name: 'configuration_devices',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: DeviceManagement
                },
                {
                    path: ':departmentId/configuration/loggers',
                    name: 'configuration_loggers',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: LoggerManagement
                },
                {
                    path: ':departmentId/configuration/groups',
                    name: 'configuration_groups',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: GroupManagement
                },
                {
                    path: ':departmentId/configuration/reports',
                    name: 'configuration_reports',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: ReportManagement,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.email_report_settings !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else {
                            next();
                        }
                    }
                },
                {
                    path: ':departmentId/configuration/group/create',
                    name: 'create_group',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: CreateGroup
                },
                {
                    path: ':departmentId/configuration/logger/:locationId',
                    name: 'logger_detail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: LoggerDetail
                },
                {
                    path: ':departmentId/configuration/group/:groupId',
                    name: 'group_detail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: GroupDetail
                },
                {
                    path: ':departmentId/configuration/profile',
                    name: 'profile_settings',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: ProfileManagement
                },
                {
                    path: ':departmentId/configuration/notifications',
                    name: 'configuration_notifications',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: NotificationsConfiguration
                },
                {
                    path: ':departmentId/device/:deviceId',
                    name: 'deviceConf',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: DeviceConf
                },
                {
                    path: ':departmentId/settings',
                    name: 'settings',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: Settings
                },
                {
                    path: ':departmentId/wizard',
                    name: 'wizard',
                    meta: { layout: 'default', needsLogin: true, departmentScope: true },
                    component: Wizard,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.wizard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else if (ApiUserRoles.COMPANY_OWNER == vxDepartmentStore.selectedDepartmentRoleKey ||
                            ApiUserRoles.COMPANY_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey)
                            next();
                        else
                            next({ name: 'pageNotExist' });
                    }
                },
                {
                    path: ':departmentId/company',
                    name: 'companySettings',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: CompanyManagement
                },
                {
                    path: ':departmentId/company/users/:userId',
                    name: 'userDetail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: UserDetail
                },
                {
                    path: ':departmentId/publicDashboardsManagement',
                    name: 'publicDashboards',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: PublicDashboardsManagement,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.public_dashboard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: ':departmentId/publicDashboardsManagement/:dashboardId',
                    name: 'publicDashboardDetail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: PublicDashboardDetail,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.public_dashboard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: ':departmentId/publicDashboard/:dashboardKey',
                    name: 'publicDashboard',
                    meta: { layout: 'public', needsLogin: false, departmentScope: true },
                    component: PublicDashboardPage,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.public_dashboard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: ':departmentId/about',
                    name: 'about',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: About
                },
                {
                    path: ':departmentId/feedbackForm',
                    name: 'feedbackSettings',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: Feedback,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.feedback !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/companies',
                    name: 'adminCompanies',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminCompanyManagement
                },
                {
                    path: 'administration/companies/:companyId',
                    name: 'adminCompanyDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminCompanyDetailManagement
                },
                {
                    path: 'administration/companies/:companyId/issue/:issueId',
                    name: 'issueDetailAdmin',
                    meta: { layout: 'admin', needsLogin: true },
                    component: IssueDetail
                },
                {
                    path: ':departmentId/notifications/issue/:issueId',
                    name: 'issueDetail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: IssueDetail
                },
                {
                    path: 'administration/companies/:departmentId/publicDashboard/:dashboardId',
                    name: 'adminPublicDashboardDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminPublicDashboardDetail,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.public_dashboard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/users',
                    name: 'adminUsers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminUserManagement
                },
                {
                    path: 'administration/users/:userId',
                    name: 'adminUserDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminUserDetailManagement
                },
                {
                    path: 'administration/devices',
                    name: 'adminDevices',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminDeviceManagement
                },
                {
                    path: 'administration/devices/:deviceId',
                    name: 'adminDeviceDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminDeviceDetailManagement
                },
                {
                    path: 'administration/devices/firmware/:firmwareId',
                    name: 'adminFirmwareDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminFirmwareDetailManagement
                },
                {
                    path: 'administration/loggers',
                    name: 'adminLoggers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminLoggerManagement
                },
                {
                    path: 'administration/loggers/:loggerId',
                    name: 'adminLoggerDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminLoggerDetailManagement
                },
                {
                    path: 'administration/resellers',
                    name: 'adminResellers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminResellerManagement
                },
                {
                    path: 'administration/resellers/:resellerId',
                    name: 'adminResellerDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminResellerDetailManagement
                },
                {
                    path: 'administration/loggers_register',
                    name: 'adminRegisterNewLoggers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminRegisterLoggersManagement
                },
                {
                    path: 'administration/notificationsManagement',
                    props: (route) => ({ query: 'queryValue' }),
                    name: 'adminNotifications',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminNotificationManagement
                },
                {
                    path: 'administration/tiers',
                    name: 'adminTiers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminTiersManagement,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.tiers !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/tiers/create',
                    name: 'adminCreateTier',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminTierCreate,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.tiers !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/tiers/:tierId',
                    name: 'adminTierDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminTierDetail,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.tiers !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/feedback',
                    name: 'adminFeedback',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminFeedbackManagement,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.feedback !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/feedback/:feedbackId',
                    name: 'adminFeedbackDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminFeedbackDetail,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.feedback !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/system_settings',
                    name: 'systemSettings',
                    meta: { layout: 'admin', needsLogin: true },
                    component: SystemSettingsManagement
                }
            ]
        }
    ]
});
var userSettingsRepository = UserSettingsRepository.getInstance(Vue.prototype);
var departmentRepository = new DepartmentRepository(Vue.prototype);
var userRepository = new UserRepository(Vue.prototype);
var featuresManager = FeaturesManager.getInstance(Vue.prototype);
var eventTypeRepository = EventTypeRepository.getInstance(Vue.prototype);
router.beforeEach(async (to, from, next) => {
    if (to.name == 'publicDashboard') {
        next();
        return;
    }
    //TODO: if logged try to reload permissions
    var loggedIn = LoggedUserManager.storageContainLoginCredentials(Vue.$cookies);
    let langSetting = to.params.lang || 'sk';
    var user = null;
    const userId = CookiesStorage.loadData(Vue.prototype, CookiesStorage.keys.API_USER_ID);
    if (loggedIn && userId != null) {
        var isLoggedToCompany = LoggedUserManager.isLoggedToCompany(Vue.prototype);
        let settings = await userSettingsRepository.loadUserSettings();
        langSetting = settings.language;
        // if departments not in store, load them
        let departments = getDepartments();
        if (!departments) {
            if (isLoggedToCompany) {
                departments = AdminDepartment.convertToUserDepartments(await departmentRepository.getAllDepartmentsAdmin(true));
            }
            else {
                departments = (await departmentRepository.getDepartments()).getData().getData();
            }
            setDepartments(departments);
        }
        // if selectedDepartment not in store, load it
        let preselectedDepartment = getSelectedDepartment();
        if (!preselectedDepartment) {
            preselectedDepartment = setSelectedDepartment(departments, settings.preferences.favoriteDepartmentId);
        }
        if (isLoggedToCompany) {
            let impersonatedAbilities = [Subjects.ALL + '.' + AdminAction];
            setSelectedDepartmentPermissions(impersonatedAbilities);
            router.app.$ability.update(new AbilityManager(impersonatedAbilities).getAbilities().rules);
            setRole(ApiUserRoles.COMPANY_ADMIN);
        }
        else if (departments.length == 0) {
            setRole(null);
            setSelectedDepartmentPermissions(null);
        }
        else if (getUserEnabledDepartments().length === 0) {
            setRole(null);
            setSelectedDepartmentPermissions(null);
        }
        else {
            user = await userRepository.getUser(userId, preselectedDepartment.id);
            if (user.role.Key === ApiUserRoles.SYSTEM_ADMIN) {
                setRole(user.role.Key);
                setSelectedDepartmentPermissions([Subjects.ALL + '.' + AdminAction]);
                //load persisted permissions and store them to vue instance
                router.app.$ability.update(new AbilityManager(getSelectedDepartmentPermissions()).getAbilities().rules);
            }
            else if (user.role.Key === ApiUserRoles.RESELLER_ADMIN) {
                setRole(preselectedDepartment.member.Role.Key);
                setSelectedDepartmentPermissions(user.role.Permissions);
                //load persisted permissions and store them to vue instance
                router.app.$ability.update(new AbilityManager(getSelectedDepartmentPermissions()).getAbilities().rules);
            }
        }
        var loggedFromAdmin = LoggedUserManager.isAdmin(Vue.prototype);
    }
    else {
        LoggedUserManager.deleteLoginData(Vue.prototype);
        LoggedUserManager.deleteAdminLoginData(Vue.prototype);
    }
    //check for service worker update
    if (navigator.serviceWorker)
        navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
            registrationsArray[0].update();
        });
    //handle when url is changed manually in admin
    if (isLoggedToCompany && to.meta.layout === 'admin') {
        next({ name: 'organizations' });
    }
    if (loggedFromAdmin && !isLoggedToCompany && to.meta.layout !== 'admin') {
        next({ name: 'adminCompanies' });
    }
    /*Check if route requires departmentId and check its value. Sometimes there are two language
    parameters and one is evaluated as departmentId so we need to check length of departmentId and fix this state.*/
    if (to.meta.departmentScope === true && (!to.params.departmentId || to.params.departmentId.length <= 3)) {
        if (!loggedFromAdmin || isLoggedToCompany) {
            next({ name: 'organizations', query: { redirect: 'true' } });
        }
        else {
            next({
                name: 'adminCompanies'
            });
        }
    }
    else if (loggedIn && to.params.departmentId && userId) {
        // check if department id is valid
        if (!isDepartmentIdValid(to.params.departmentId)) {
            setSelectedDepartmentById(getFavoriteDepartment().id);
        }
        else {
            setSelectedDepartmentById(to.params.departmentId);
        }
        let preselectedDepartment = getSelectedDepartment();
        //if user is disabled
        if (!loggedFromAdmin && !preselectedDepartment.member?.Enabled) {
            VueUtilities.openErrorToast(Vue.prototype, i18n.t('error_messages.user_not_allowed'));
            if (getFavoriteDepartment().member?.Enabled) {
                setSelectedDepartmentById(getFavoriteDepartment().id);
            }
            else {
                let departments = getDepartments();
                let enabledDepartment = departments.find((x) => x.member?.Enabled);
                if (enabledDepartment) {
                    setSelectedDepartmentById(enabledDepartment.id);
                }
                else {
                    //is Disabled in all departments
                    //TODO: What we should do in this case? For now, message and logout
                    VueUtilities.openErrorToast(Vue.prototype, i18n.t('error_messages.user_without_department'));
                    LoggedUserManager.deleteLoginData(Vue.prototype);
                    LoggedUserManager.deleteAdminLoginData(Vue.prototype);
                }
            }
        }
        preselectedDepartment = getSelectedDepartment();
        if (!hasRoleAndPermissions()) {
            //if user is impersonated admin, dont load user
            if (isLoggedToCompany) {
                let impersonatedAbilities = [Subjects.ALL + '.' + AdminAction];
                setSelectedDepartmentPermissions(impersonatedAbilities);
                router.app.$ability.update(new AbilityManager(impersonatedAbilities).getAbilities().rules);
                setRole(ApiUserRoles.COMPANY_ADMIN);
            }
            else {
                if (!user) {
                    user = await userRepository.getUser(userId, preselectedDepartment.id);
                }
                setRole(preselectedDepartment.member.Role.Key);
                user.role.Permissions.push('Notifications.Company.Presets.Edit');
                setSelectedDepartmentPermissions(user.role.Permissions);
                //load persisted permissions and store them to vue instance
                router.app.$ability.update(new AbilityManager(getSelectedDepartmentPermissions()).getAbilities().rules);
            }
        }
        if (to.meta.departmentScope === true && from.params.departmentId !== preselectedDepartment.id) {
            //reload department data
            if (isLoggedToCompany) {
                let impersonatedAbilities = [Subjects.ALL + '.' + AdminAction];
                setSelectedDepartmentPermissions(impersonatedAbilities);
                router.app.$ability.update(new AbilityManager(impersonatedAbilities).getAbilities().rules);
                setRole(ApiUserRoles.COMPANY_ADMIN);
            }
            else {
                const userId = CookiesStorage.loadData(Vue.prototype, CookiesStorage.keys.API_USER_ID);
                user = await userRepository.getUser(userId, preselectedDepartment.id);
                setRole(user.role.Key);
                user.role.Permissions.push('Notifications.Company.Presets.Edit');
                setSelectedDepartmentPermissions(user.role.Permissions);
                router.app.$ability.update(new AbilityManager(getSelectedDepartmentPermissions()).getAbilities().rules);
            }
            let allPromises = [];
            allPromises.push(featuresManager.loadTier());
            allPromises.push(eventTypeRepository.getAllTypes(true));
            allPromises.push(userSettingsRepository.loadUserSettingsForDepartment(userId, true));
            allPromises.push(departmentRepository.getDepartmentSettings());
            await Promise.all(allPromises).then((response) => {
                if (to.name === 'dashboard') {
                    if (response[3].setupRequired.toString() === 'true') {
                        return next({
                            name: 'wizard',
                            params: {
                                lang: langSetting,
                                departmentId: getSelectedDepartment().id
                            }
                        });
                    }
                }
            });
        }
        if (to.meta.departmentScope === true && !isDepartmentIdValid(to.params.departmentId)) {
            next({
                name: 'dashboard',
                params: { departmentId: preselectedDepartment.id, lang: langSetting },
                query: { redirect: 'true' }
            });
        }
    }
    if (!to.params.lang || langSetting != to.params.lang) {
        let params = to.params;
        params.lang = langSetting;
        next({ name: to.name, params: params, query: to.query });
    }
    i18n.locale = langSetting;
    if (to.meta.needsLogin && loggedIn && userId == null) {
        next({ name: 'login', params: { lang: to.params.lang, toUrl: to.fullPath } });
    }
    else if (to.meta.authPage == true && loggedIn && userId != null) {
        if (from.name == null)
            next({ name: 'dashboard', params: to.params, query: to.query });
        else
            next(false);
    }
    else if (to.name == null) {
        next({ name: 'pageNotExist' });
    }
    else if ((to.meta.needsLogin && loggedIn && userId != null) || !to.meta.needsLogin) {
        next();
    }
    else if (to.meta.needsLogin && !loggedIn) {
        next({ name: 'login', params: { lang: to.params.lang, toUrl: to.fullPath } });
    }
});
export default router;
function getDepartments() {
    return vxDepartmentStore.departments;
}
function setDepartments(value) {
    vxDepartmentStore.departments = value;
}
/**
 * Function which checks if exists department id from url
 * @param departmentId
 * @returns true if department exists, else false
 */
function isDepartmentIdValid(departmentId) {
    let id = getDepartments().find((x) => x.id === departmentId);
    return id ? true : false;
}
function getFavoriteDepartment() {
    return vxDepartmentStore.favoriteDepartment
        ? vxDepartmentStore.favoriteDepartment
        : vxDepartmentStore.selectedDepartment;
}
function getSelectedDepartment() {
    return vxDepartmentStore.selectedDepartment;
}
// Return user enabled departments
function getUserEnabledDepartments() {
    return getDepartments().filter((department) => department.member?.Enabled);
}
// Return user enabled department
function getUserEnabledAdminDepartment() {
    return getDepartments().find((department) => department.member?.Enabled && department.member?.Role?.Key === ApiUserRoles.SYSTEM_ADMIN);
}
/**
 * Function which initializes selected and favorite department id in store
 * If user has favorite deparment in preferences, it's also his currently selected
 * If user hasn't favorite department, his currently selected department is first from list of departments
 * @param departments
 * @param favoriteDepartmentId
 * @returns currently selected department
 */
function setSelectedDepartment(departments, favoriteDepartmentId) {
    //Checking if the user is enabled in their favorite department
    let userEnabledFavDepartment = departments.find((x) => x.id === favoriteDepartmentId && x.member?.Enabled);
    let userEnabledAdminDepartment = getUserEnabledAdminDepartment();
    //Choose one department from user enabled departments
    if (userEnabledAdminDepartment) {
        vxDepartmentStore.selectedDepartment = userEnabledAdminDepartment;
    }
    else if (userEnabledFavDepartment) {
        setSelectedDepartmentById(userEnabledFavDepartment.id);
        vxDepartmentStore.setFavoriteDepartmentById(userEnabledFavDepartment.id);
    }
    else {
        vxDepartmentStore.selectedDepartment = getUserEnabledDepartments()[0];
    }
    return getSelectedDepartment();
}
function setSelectedDepartmentById(value) {
    vxDepartmentStore.setSelectedDepartmentById(value);
}
function getSelectedDepartmentPermissions() {
    return vxDepartmentStore.selectedDepartmentPermissions;
}
function setSelectedDepartmentPermissions(value) {
    vxDepartmentStore.selectedDepartmentPermissions = value;
}
function setRole(value) {
    vxDepartmentStore.selectedDepartmentRoleKey = value;
}
function hasRoleAndPermissions() {
    return vxDepartmentStore.selectedDepartmentRoleKey != null && vxDepartmentStore.selectedDepartmentPermissions != null;
}
