var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.issues.detail.notifications.title")) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: { position: "is-bottom-left", "aria-role": "list" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.company.list.actions.columns"
                                ),
                                position: "is-left",
                                type: "is-dark",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                { staticStyle: { "font-size": "1rem" } },
                                [
                                  _c("b-icon", {
                                    attrs: { type: "is-primary", icon: "list" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: column.field,
                      staticClass: "custom-dropdown-item",
                      attrs: {
                        focusable: false,
                        custom: "",
                        "aria-role": "listitem",
                      },
                    },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            disabled:
                              column.field == _vm.tableColumns.fullName.field ||
                              column.field == _vm.tableColumns.channel.field,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.chooseTableColumns(
                                column.field,
                                $event
                              )
                            },
                          },
                          model: {
                            value: column.visible,
                            callback: function ($$v) {
                              _vm.$set(column, "visible", $$v)
                            },
                            expression: "column.visible",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `component.issues.detail.notifications.table.${column.field}`
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          attrs: {
            data: _vm.issueNotifications,
            striped: "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            paginated: "",
            "current-page": _vm.page,
            "per-page": _vm.pageSize,
            total: _vm.total,
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "page-change": _vm.onPageChange,
            sort: _vm.onSort,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "empty",
                fn: function () {
                  return [
                    _c("section", { staticClass: "section" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "content has-text-grey has-text-centered",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.issues.detail.notifications.table.empty"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.issueNotifications.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.total,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.fullName.field,
              label: _vm.$t(
                `component.issues.detail.notifications.table.${_vm.tableColumns.fullName.field}`
              ),
              visible: _vm.tableColumns.fullName.visible,
              sortable: _vm.tableColumns.fullName.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (issueNotification) {
                  return [
                    _vm._v(
                      " " + _vm._s(issueNotification.row.apiUser.fullName) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.email.field,
              label: _vm.$t(
                `component.issues.detail.notifications.table.${_vm.tableColumns.email.field}`
              ),
              visible: _vm.tableColumns.email.visible,
              sortable: _vm.tableColumns.email.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (issueNotification) {
                  return [
                    _vm._v(_vm._s(issueNotification.row.apiUser.email) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdAt.field,
              label: _vm.$t(
                `component.issues.detail.notifications.table.${_vm.tableColumns.createdAt.field}`
              ),
              visible: _vm.tableColumns.createdAt.visible,
              sortable: _vm.tableColumns.createdAt.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (issueNotification) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(issueNotification.row.createdAt * 1000),
                          null,
                          null,
                          null
                        )
                      ) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.channel.field,
              label: _vm.$t(
                `component.issues.detail.notifications.table.${_vm.tableColumns.channel.field}`
              ),
              visible: _vm.tableColumns.channel.visible,
              sortable: _vm.tableColumns.channel.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (issueNotification) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "notification_channels." +
                            issueNotification.row.channel
                        )
                      ) + " "
                    ),
                    issueNotification.row.notifyOnResolve == true
                      ? _c(
                          "span",
                          [
                            _c("b-tag", { attrs: { type: "is-success" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    `component.issues.table.status.resolved`
                                  )
                                ) + " "
                              ),
                            ]),
                            _c(
                              "b-tooltip",
                              {
                                staticClass: "ml-1",
                                attrs: {
                                  type: "is-dark",
                                  label: _vm.$t(
                                    `component.issues.detail.notifications.table.status.resolved`
                                  ),
                                  position: "is-left",
                                  multilined: "",
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    size: "is-small",
                                    icon: "info-circle",
                                    type: "is-grey",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }