import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DeviceList from '@/views/components/device/DeviceList.vue';
import DeviceRepository from '@/services/repository/DeviceRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import VueUtilities from '@/services/VueUtilities';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import { DepartmentSettingsKeys } from '@/entities/models/DepartmentSettings';
import DateTimeManager from '@/services/DateTimeManager';
import { vxm } from '@/store/store.vuex';
import DevicesSettings from '../components/device/DevicesSettings.vue';
import DeviceSirenModal from '../components/device/DeviceSirenModal.vue';
var vxDepartmentStore = vxm.departmentStore;
var deviceRepository;
var departmentRepository;
const PAGE_SIZE = 10;
let DeviceManagement = class DeviceManagement extends Vue {
    constructor() {
        super(...arguments);
        this.dateTimeManager = null;
        this.PAGE_SIZE = PAGE_SIZE;
        this.deviceList = new Array();
        this.allDevicesCount = 0;
        this.SELECTED_DEPARTMENT_ID = null;
        this.departmentSettings = null;
        this.isLoading = true;
        this.isDeviceSirenModalActive = false;
    }
    async created() {
        deviceRepository = new DeviceRepository(this);
        departmentRepository = new DepartmentRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
        this.loadData();
    }
    loadData() {
        this.loadDepartmentSettings();
    }
    async loadDepartmentSettings() {
        this.isLoading = true;
        this.departmentSettings = await departmentRepository.getDepartmentSettings();
        this.isLoading = false;
    }
    async loadDevices(pagination) {
        let deviceResult;
        deviceResult = await deviceRepository.getDevices(pagination);
        this.deviceList = deviceResult.getData();
        this.allDevicesCount = deviceResult.getPagination().total;
    }
    async pageChanged(pagination) {
        this.isLoading = true;
        let deviceList = await deviceRepository.getDevices(pagination);
        this.deviceList = deviceList.getData();
        this.allDevicesCount = deviceList.getPagination().total;
        this.isLoading = false;
    }
    closeDeviceSirenModal() {
        this.isDeviceSirenModalActive = false;
    }
    async submitSirenInterval(allIntervals, deletion = false) {
        this.isLoading = true;
        let departmentSettings = await departmentRepository.saveDepartmentSetting([DepartmentSettingsKeys.NOTIFICATIONS, DepartmentSettingsKeys.BUZZER, DepartmentSettingsKeys.SEQUENCE], JSON.stringify(allIntervals));
        if (departmentSettings === null) {
            if (deletion)
                VueUtilities.openErrorToast(this, this.$t('component.device.sirenDeletionModal.failure'));
            else
                VueUtilities.openErrorToast(this, this.$t('component.device.deviceSirenModal.failure'));
        }
        else {
            this.departmentSettings = departmentSettings;
            if (deletion)
                VueUtilities.openSuccessToast(this, this.$t('component.device.sirenDeletionModal.success'));
            else
                VueUtilities.openSuccessToast(this, this.$t('component.device.deviceSirenModal.success'));
        }
        this.isLoading = false;
    }
    openDeviceSirenModal() {
        this.isDeviceSirenModalActive = true;
    }
    deleteSirenSequence() {
        this.$buefy.dialog.confirm({
            title: this.$t('component.device.sirenDeletionModal.title'),
            message: this.$t('component.device.sirenDeletionModal.body'),
            confirmText: this.$t('component.device.sirenDeletionModal.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                await this.submitSirenInterval([], true);
            }
        });
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
};
DeviceManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.DEVICE)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            if (this.SELECTED_DEPARTMENT_ID != this.getSelectedDepartmentId()) {
                this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
                this.loadData();
                this.$refs.deviceList.onPageChange(1);
            }
            else {
                next(); // to resolve hook
            }
        },
        components: { DeviceList, DevicesSettings, DeviceSirenModal }
    })
], DeviceManagement);
export default DeviceManagement;
