var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("main", { staticClass: "bsod container error-content mt-6" }, [
      _c(
        "h1",
        { staticClass: "neg title is-size-1 has-text-centered" },
        [
          _c("span", { staticClass: "bg mr-5" }, [
            _vm._v(_vm._s(_vm.$t("page_not_found.title"))),
          ]),
          _c("b-icon", { attrs: { icon: "sad-tear" } }),
        ],
        1
      ),
      _c("h2", { staticClass: "is-size-4 has-text-centered px-3" }, [
        _vm._v(_vm._s(_vm.$t("page_not_found.error_occurred"))),
      ]),
      _c("p", { staticClass: "has-text-centered px-3" }, [
        _vm._v(_vm._s(_vm.$t("page_not_found.options"))),
      ]),
      _c(
        "div",
        { staticClass: "is-flex is-justify-content-center" },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-5",
              attrs: { type: "is-primary", "icon-left": "home" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "home" })
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("page_not_found.go_home")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }