import EventType from '@/entities/EventType';
import AxiosService from '@/services/api/AxiosService';
import SessionStorage from './SessionStorage';
import ApiLinks from '@/entities/ApiLinks';
import Unit from '@/entities/Unit';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import ApiStatus from '@/entities/enums/apiStatuses';
import { ApiQueries } from '@/entities/ApiQueries';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
var userSettingsRepository;
/**
 * Singleton repository
 */
export default class EventTypeRepository {
    constructor(ctx) {
        this.LS_TAG = SessionStorage.keys.EVENT_TYPES;
        this.LS_ADMIN_TAG = SessionStorage.keys.EVENT_TYPES_ADMIN;
        this.ctx = ctx;
    }
    static getInstance(ctx, reload = false) {
        if (!this._instance || reload) {
            this._instance = new this(ctx);
        }
        return this._instance;
    }
    async adminGetAllTypes(updateCache = true) {
        let url = `${ApiLinks.CompanyLogger.EventTypes}`;
        let cachedData = SessionStorage.loadDataWithType(this.LS_ADMIN_TAG, EventType.prototype);
        if (cachedData)
            return cachedData;
        if (!this._responsePromise) {
            this._responsePromise = AxiosService.getDataFromServer(url, this.ctx);
        }
        let response = await this._responsePromise;
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else {
            let eventTypes = EventType.fromApi(response.data.data);
            if (updateCache === true) {
                SessionStorage.storeData(this.LS_ADMIN_TAG, eventTypes);
            }
            this._responsePromise = null;
            return eventTypes;
        }
    }
    //TODO: ak cheme naozaj vsetky treba sem poslat all=true;
    async getAllTypes(reload = false, all = false, departmentId = this.getSelectedDepartmentId(), updateCache = true) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Events.Keys.EventTypes}`;
        let cachedData = SessionStorage.loadDataWithType(this.LS_TAG, EventType.prototype);
        if (!reload && cachedData)
            return cachedData;
        if (!this._responsePromise) {
            let queries = new ApiQueries();
            queries.all = all;
            url += `?${queries.toQueryString()}`;
            this._responsePromise = AxiosService.getDataFromServer(url, this.ctx);
        }
        let response = await this._responsePromise;
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else {
            let eventTypes = EventType.fromApi(response.data.data);
            if (updateCache === true) {
                SessionStorage.storeData(this.LS_TAG, eventTypes);
            }
            this._responsePromise = null;
            return eventTypes;
        }
    }
    async getTypesFilteredByDomain(eventDomain, reload = false, updateCache = true, departmentId) {
        let data = await this.getAllTypes(reload, false, departmentId, updateCache);
        if (eventDomain)
            return data.filter((x) => x.domain == eventDomain);
        else
            return data;
    }
    async getAllUnits(departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Events.Keys.Units}`;
        let response = (await AxiosService.getDataFromServer(url, this.ctx));
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else {
            let data = response.data.data;
            let units = new Array();
            Object.keys(data).forEach((typeIndex) => {
                units[parseInt(typeIndex)] = Unit.fromApi(data[parseInt(typeIndex)]);
            });
            return units;
        }
    }
    async getSelectedUnits(unitSettings) {
        if (!unitSettings) {
            userSettingsRepository = UserSettingsRepository.getInstance(this.ctx);
            let settings = await userSettingsRepository.loadUserSettings();
            unitSettings = settings.events.units;
        }
        let allUnits = await this.getAllUnits();
        let units = [];
        Object.keys(unitSettings).forEach((type) => {
            units[type] = allUnits[type].find((x) => x.identifier == unitSettings[type]);
        });
        return units;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
