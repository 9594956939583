var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "is-mobile department-box is-full-height is-flex",
      class: [
        _vm.getClass(),
        _vm.isAdmin || _vm.department.member.Enabled
          ? "is-enabled"
          : "is-disabled",
      ],
    },
    [
      !_vm.isAdmin
        ? _c(
            "div",
            {
              staticClass:
                "mx-3 is-full-height is-flex is-align-items-center is-justify-content-center",
            },
            [
              _c("b-icon", {
                staticClass: "is-clickable",
                attrs: {
                  type: _vm.isFavorite ? "is-warning" : "is-light",
                  "custom-class": _vm.department.member.Enabled
                    ? "favorite-star"
                    : "favorite-star disabled",
                  size: "is-small",
                  icon: "star",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.favoriteSelected()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "column p-0 is-full-height" },
        [
          _vm.isAdmin || _vm.department.member.Enabled
            ? _c(
                "router-link",
                {
                  staticClass:
                    "is-full-height is-flex is-align-items-center is-justify-content-left",
                  attrs: {
                    to: {
                      name: "dashboard",
                      params: {
                        lang: _vm.$route.params.lang,
                        departmentId: _vm.department.id,
                      },
                    },
                  },
                },
                [
                  _vm.isMobileView
                    ? _c(
                        "h6",
                        {
                          staticClass:
                            "title is-6 has-text-black mb-0 px-1 py-5 has-text-left",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.abbreviateName(_vm.department.name)) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "h6",
                        {
                          staticClass:
                            "title is-6 has-text-black mb-0 px-1 py-5 has-text-left truncate",
                        },
                        [_vm._v(_vm._s(_vm.department.name))]
                      ),
                ]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "is-full-height is-flex is-align-items-center is-justify-content-center",
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass:
                        "title is-6 has-text-black mb-0 py-5 px-1 has-text-centered",
                    },
                    [_vm._v(" " + _vm._s(_vm.department.name) + " ")]
                  ),
                ]
              ),
        ],
        1
      ),
      _vm.isAdmin || _vm.department.member.Enabled
        ? _c(
            "div",
            {
              staticClass:
                "mx-2 is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-right",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end",
                  class:
                    _vm.fineState && !_vm.isAcknowledged ? "clickable" : "",
                  on: {
                    click: function ($event) {
                      _vm.fineState && !_vm.isAcknowledged
                        ? _vm.openModal()
                        : ""
                    },
                  },
                },
                [
                  _c("OrganizationStatus", {
                    attrs: { issuesSummary: _vm.department.issuesSummary },
                  }),
                  _vm.fineState && !_vm.isAcknowledged
                    ? _c("span", { staticClass: "is-size-7 is-underlined" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.organization_card.acknowledge")
                          )
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isAcknowledgeModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeAcknowledgeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isAcknowledgeModalActive = $event
            },
            "close-modal": _vm.closeAcknowledgeModal,
          },
        },
        [
          _c("AcknowledgeIssueModal", {
            attrs: {
              department: _vm.department,
              dateTimeManager: _vm.dateTimeManager,
              fineState: _vm.fineState,
            },
            on: { reload: _vm.reload, modalClosed: _vm.closeAcknowledgeModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }