import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ThermalMap from '@/views/components/thermalMap/ThermalMap.vue';
import DateTimeManager from '@/services/DateTimeManager';
import SourceRepository from '@/services/repository/SourceRepository';
import ReportRecordRepository from '@/services/repository/ReportRecordRepository';
import StatisticsType from '@/entities/enums/statisticsType';
import VueUtilities from '@/services/VueUtilities';
import ThermoMapRepository from '@/services/repository/ThermoMapRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
var sourceRepository;
var reportRecordRepository;
var thermoMapRepository;
let ThermalMapPage = class ThermalMapPage extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.sources = [];
        this.selectedMapImage = null;
        this.dateTimeManager = null;
        this.statistics = {};
        this.selectedMap = null;
        this.thermoMaps = [];
    }
    async created() {
        sourceRepository = new SourceRepository(this);
        reportRecordRepository = new ReportRecordRepository(this);
        thermoMapRepository = new ThermoMapRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(thermoMapRepository.getAllThermoMaps());
        allPromises.push(sourceRepository.getVisibleSources());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1]);
        });
    }
    async processLoadedDataForPage(maps, sources) {
        this.sources = sources.getData();
        this.thermoMaps = maps;
        if (this.thermoMaps.length > 0) {
            this.selectedMap = this.thermoMaps[0];
            let additionalPromises = [];
            let sourceIds = this.selectedMap.locations.map((x) => x.EntityId);
            additionalPromises.push(thermoMapRepository.getAllThermoMaps());
            additionalPromises.push(reportRecordRepository.getStatisticForSources(sourceIds, this.dateTimeManager.formatStartDateForReports(new Date(this.selectedMap.from * 1000)).text, this.dateTimeManager.formatEndDateForReports(new Date(this.selectedMap.to * 1000), true).text, StatisticsType.AVERAGE, null, null, this.dateTimeManager.getSelectedTimezone()));
            additionalPromises.push(thermoMapRepository.getThermoMapImage(this.selectedMap.id));
            Promise.all(additionalPromises).then((response) => {
                this.processAdditionalDataForPage(response[0], response[1], response[2]);
            });
        }
        else {
            this.selectedMap = null;
            this.selectedMapImage = null;
            this.isLoading = false;
        }
    }
    processAdditionalDataForPage(maps, statistics, image) {
        this.thermoMaps = maps;
        this.statistics = statistics;
        this.selectedMapImage = image;
        this.isLoading = false;
    }
    async createThermoMap(mapConfig) {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(thermoMapRepository.createThermoMap(mapConfig));
        await Promise.all(allPromises).then((response) => {
            this.loadData();
        });
    }
    async updateThermoMap(mapConfig, thermoMapId) {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(thermoMapRepository.updateThermoMap(mapConfig, thermoMapId));
        await Promise.all(allPromises).then((response) => {
            this.loadData();
        });
    }
    openDeleteDialog() {
        this.$buefy.dialog.confirm({
            title: this.$t('component.thermal_map.delete.title'),
            message: this.$t('component.thermal_map.delete.msg'),
            confirmText: this.$t('component.thermal_map.delete.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.deleteMapAndConfiguration()
        });
    }
    async deleteMapAndConfiguration() {
        this.isLoading = true;
        let resp = await thermoMapRepository.deleteThermoMap(this.selectedMap.id);
        if (resp == true) {
            VueUtilities.openSuccessToast(this, this.$t('component.thermal_map.delete.success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.thermal_map.delete.failure'));
        }
        this.loadData();
    }
};
ThermalMapPage = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.EDIT, Subjects.ADMIN_THERMOMAP)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            this.loadData();
            next(); // to resolve hook
        },
        components: { ThermalMap }
    })
], ThermalMapPage);
export default ThermalMapPage;
