import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import { Department } from '@/entities/models/Department';
import UserPreferences from '@/services/UserPreferences';
import VueUtilities from '@/services/VueUtilities';
var companyLoggerRepository;
let AdminLoggerHistory = class AdminLoggerHistory extends Vue {
    constructor() {
        super(...arguments);
        this.CalibrationStatus = CalibrationStatus;
        this.isLoading = false;
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminLoggerHistorySortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminLoggerHistorySortField.defaultValue;
        this.tableColumns = {
            companyLoggerId: {
                field: 'companyLoggerId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistoryCompanyLoggerId)
            },
            serialNumber: {
                field: 'serialNumber',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistorySerialNumber)
            },
            lastSeen: {
                field: 'lastSeen',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistoryLastSeen)
            },
            company: {
                field: 'company',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistoryCompany)
            },
            active: {
                field: 'active',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistoryActive)
            },
            calibration: {
                field: 'calibration',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistoryCalibration)
            }
        };
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.adminDownloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    onSort(field, order) {
        this.sortField = field;
        this.sortDirection = order;
    }
    hasCalibrationProtocolToDownload(logger) {
        if (logger == null || logger.calibration == null || logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
    /** Check if calibration can be copied. Req: serial numbers of both
     * loggers are same, source logger is calibrated and target logger is not calibrated */
    canCopyCalibration(logger) {
        if (this.logger?.currentlyPublishedAs?.serialNumber === logger.serialNumber &&
            logger.isCalibrated() &&
            !this.logger.isCalibrated()) {
            return true;
        }
        else
            return false;
    }
    hasPermissionToViewDepartment(departmentId) {
        return Department.hasPermissionToViewDepartment(departmentId, this);
    }
    /**Ask user if he really wants to copy calibration to current logger */
    copyCalibrationDialog(sourceCompanyLoggerID) {
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.loggers.history.copy_dialog.title'),
            message: this.$t('admin.component.loggers.history.copy_dialog.message'),
            confirmText: `${this.$t('admin.component.loggers.history.copy_dialog.confirm')}`,
            cancelText: `${this.$t('generals.close')}`,
            type: 'is-success',
            hasIcon: true,
            onConfirm: () => {
                this.copyCalibration(sourceCompanyLoggerID, this.logger?.currentlyPublishedAs?.companyLoggerId);
            },
            onCancel: () => { }
        });
    }
    /**Copy calibration from one logger to another,
     * this is available only if current logger is published and serial numbers are same */
    async copyCalibration(sourceCompanyLoggerID, targetCompanyLoggerId) {
        //this should never happen, copy calibration is available only for published loggers
        if (!targetCompanyLoggerId) {
            throw 'Logger must be published first';
        }
        this.isLoading = true;
        let res = await companyLoggerRepository.adminCopyCalibrationProtocol(sourceCompanyLoggerID, targetCompanyLoggerId);
        if (res === true) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.history.copy_dialog.success'));
            this.$emit('reloadLogger');
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.history.copy_dialog.failure'));
        }
        this.isLoading = false;
    }
    // changing default sort field
    changeDefaultSortField(sortFieldName) {
        if (this.sortField == sortFieldName) {
            this.sortField = this.tableColumns.companyLoggerId.field;
        }
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.serialNumber.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.serialNumber.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistorySerialNumber, event);
                break;
            case this.tableColumns.lastSeen.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.lastSeen.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistoryLastSeen, event);
                break;
            case this.tableColumns.company.field:
                if (!event) {
                    this.changeDefaultSortField('company.companyName');
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistoryCompany, event);
                break;
            case this.tableColumns.active.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.active.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistoryActive, event);
                break;
            case this.tableColumns.calibration.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.calibration.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerHistoryCalibration, event);
                break;
        }
        this.$nextTick(() => {
            this.$refs.table.initSort();
        });
    }
};
__decorate([
    Prop({ type: Object })
], AdminLoggerHistory.prototype, "logger", void 0);
__decorate([
    Prop({ type: Object })
], AdminLoggerHistory.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminLoggerHistory.prototype, "isLayoutList", void 0);
AdminLoggerHistory = __decorate([
    Component({ components: {} })
], AdminLoggerHistory);
export default AdminLoggerHistory;
