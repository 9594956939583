var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "multi-header is-flex is-justify-content-space-between mx-3 mx-0-desktop mb-4 mb-1-touch",
        },
        [
          _c(
            "nav",
            {
              staticClass: "breadcrumb my-1",
              attrs: { "aria-label": "breadcrumb" },
            },
            [
              _c("h1", { staticClass: "title has-text-centered-touch" }, [
                _c(
                  "ul",
                  [
                    _c("li", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [
                          _vm._m(0),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                        ]
                      ),
                    ]),
                    _vm.prevRoute !== null &&
                    _vm.prevRoute.name.includes("Company")
                      ? [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "adminCompanies",
                                      params: {
                                        lang: _vm.prevRoute.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "warehouse" },
                                  }),
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.sidebar.companies"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: _vm.prevRoute.name,
                                      params: _vm.prevRoute.params,
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.loggerCompanyBreadcrumb) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.prevRoute !== null &&
                    _vm.prevRoute.name.includes("Reseller")
                      ? [
                          _vm.prevRoute.name.includes("Reseller")
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "adminResellers",
                                          params: {
                                            lang: _vm.$route.params.lang,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "box-open" },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("admin.sidebar.resellers")
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: _vm.prevRoute.name,
                                      params: _vm.prevRoute.params,
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(_vm.prevRoute.meta.name)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm.prevRoute === null ||
                        _vm.prevRoute.name.includes("Logger")
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "adminLoggers",
                                    params: {
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "microchip" } }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("admin.sidebar.loggers"))
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("li", { staticClass: "is-active" }, [
                      _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "admin.component.loggers.detail.navigationTitle"
                            )
                          ) +
                            " " +
                            _vm._s(_vm.loggerBreadcrumb)
                        ),
                      ]),
                    ]),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "is-flex", staticStyle: { "z-index": "0" } },
            [
              _c(
                "div",
                { staticClass: "mr-3" },
                [
                  !_vm.isLayoutList
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t("admin.component.layout.listView"),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { "font-size": "1rem" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLayout()
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: { type: "is-primary", icon: "stream" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLayoutList
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t("admin.component.layout.tabView"),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { "font-size": "1rem" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLayout()
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  type: "is-primary",
                                  icon: "layer-group fa-rotate-270",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("LoggerActionsDropdown", {
                attrs: { logger: _vm.logger, hasButton: true },
                on: {
                  activateClicked: _vm.activateClicked,
                  returnFromDepartmentClicked: _vm.returnFromDepartmentClicked,
                  deactivateClicked: _vm.deactivateLogger,
                  publishClicked: _vm.publishClicked,
                  unpublishClicked: _vm.unpublishLogger,
                  openReadDataModal: _vm.openReadDataModal,
                  addVerificationKey: _vm.addVerificationKey,
                  deleteVerificationKey: _vm.deleteVerificationKey,
                  revertDevice: _vm.revertDevice,
                },
              }),
            ],
            1
          ),
        ]
      ),
      !_vm.isLayoutList
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "div",
                { staticClass: "box tile is-child" },
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "custom-layout-tabs",
                      attrs: { type: "is-boxed" },
                      on: {
                        input: function ($event) {
                          return _vm.changedActiveTab($event)
                        },
                      },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.loggers.detail.info.title"
                            ),
                          },
                        },
                        [
                          _c("AdminLoggerInfoComponent", {
                            staticClass: "mb-5",
                            attrs: {
                              isLayoutList: _vm.isLayoutList,
                              logger: _vm.logger,
                              companyLogger: _vm.companyLogger,
                              dateTimeManager: _vm.dateTimeManager,
                            },
                            on: { saveLoggerEdit: _vm.saveLoggerEdit },
                          }),
                          _c("AdminLoggerChart", {
                            attrs: {
                              dateTimeManager: _vm.dateTimeManager,
                              chartData: _vm.chartData,
                              logger: _vm.logger,
                              eventTypes: _vm.companyLogger.availableTypes,
                              initialFromDate: _vm.selectedFromDate,
                              componentIsLoading: _vm.isAdminLoggerChartLoading,
                            },
                            on: {
                              selectedFromDateChange:
                                _vm.selectedFromDateChange,
                              eventHandler: _vm.eventHandler,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.loggers.lastData.title"
                            ),
                          },
                        },
                        [
                          _c("LastDataInfoComponent", {
                            staticClass: "mb-4",
                            attrs: {
                              isLayoutList: _vm.isLayoutList,
                              companyLogger: _vm.companyLogger,
                              dateTimeManager: _vm.dateTimeManager,
                            },
                            on: { reloadLogger: _vm.reloadLogger },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.loggers.calibration.title"
                            ),
                          },
                        },
                        [
                          _c("CalibrationInfoComponent", {
                            staticClass: "mb-4",
                            attrs: {
                              isLayoutList: _vm.isLayoutList,
                              companyLogger: _vm.companyLogger,
                              dateTimeManager: _vm.dateTimeManager,
                            },
                            on: { reloadLogger: _vm.reloadLogger },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.loggers.history.title"
                            ),
                          },
                        },
                        [
                          _c("AdminLoggerHistory", {
                            staticClass: "mb-4",
                            attrs: {
                              isLayoutList: _vm.isLayoutList,
                              logger: _vm.logger,
                              dateTimeManager: _vm.dateTimeManager,
                            },
                            on: { reloadLogger: _vm.reloadLogger },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ])
        : _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminLoggerInfoComponent", {
                  staticClass: "box tile is-child",
                  attrs: {
                    logger: _vm.logger,
                    companyLogger: _vm.companyLogger,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: { saveLoggerEdit: _vm.saveLoggerEdit },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminLoggerChart", {
                  staticClass: "tile is-child box",
                  attrs: {
                    dateTimeManager: _vm.dateTimeManager,
                    chartData: _vm.chartData,
                    logger: _vm.logger,
                    eventTypes: _vm.companyLogger.availableTypes,
                    initialFromDate: _vm.selectedFromDate,
                    componentIsLoading: _vm.isAdminLoggerChartLoading,
                  },
                  on: {
                    selectedFromDateChange: _vm.selectedFromDateChange,
                    eventHandler: _vm.eventHandler,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("LastDataInfoComponent", {
                  staticClass: "box tile is-child",
                  attrs: {
                    companyLogger: _vm.companyLogger,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: { reloadLogger: _vm.reloadLogger },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("CalibrationInfoComponent", {
                  staticClass: "box tile is-child",
                  attrs: {
                    companyLogger: _vm.companyLogger,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: { reloadLogger: _vm.reloadLogger },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminLoggerHistory", {
                  staticClass: "box tile is-child",
                  attrs: {
                    logger: _vm.logger,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: { reloadLogger: _vm.reloadLogger },
                }),
              ],
              1
            ),
          ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isSerialNumberModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isSerialNumberModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("PublishLoggerModal", {
            attrs: { logger: _vm.logger },
            on: {
              modalClosed: _vm.closeModal,
              serialNumberSelected: _vm.publishLogger,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCompanyModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCompanyModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("SelectCompanyModal", {
            attrs: { logger: _vm.logger },
            on: {
              modalClosed: _vm.closeModal,
              companySelected: _vm.activateLogger,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isReadDataModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isReadDataModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("ReadDataFromLoggerModal", {
            attrs: { logger: _vm.logger },
            on: { modalClosed: _vm.closeModal, readData: _vm.readData },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isVerificationKeyModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isVerificationKeyModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("CreateVerificationKeyModal", {
            on: {
              modalClosed: _vm.closeModal,
              createVerificationKey: _vm.createVerificationKey,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }