var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "tile is-parent" }, [
        _c("div", { staticClass: "tile is-child box" }, [
          _vm.thermoMaps.length !== 0
            ? _c(
                "div",
                {
                  staticClass: "is-flex is-justify-content-flex-end mx-0 mb-5",
                },
                [
                  _c(
                    "div",
                    { staticClass: "buttons" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-0-mobile",
                          attrs: { "icon-left": "times-circle" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("generals.cancel")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          !_vm.currentImage && !_vm.imageConfirmed
            ? _c(
                "div",
                [
                  _vm.imageFile
                    ? _c(
                        "div",
                        {
                          staticClass: "is-flex is-justify-content-center mb-2",
                        },
                        [
                          _c("b-button", {
                            staticClass: "mt-2",
                            attrs: {
                              type: "is-primary",
                              expanded: "",
                              label: _vm.$t(
                                "component.thermal_map.confirm_upload"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveImage()
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-upload",
                    {
                      attrs: { accept: ".svg", "drag-drop": "", expanded: "" },
                      on: { input: _vm.setImage },
                      model: {
                        value: _vm.imageFile,
                        callback: function ($$v) {
                          _vm.imageFile = $$v
                        },
                        expression: "imageFile",
                      },
                    },
                    [
                      _c("section", { staticClass: "section" }, [
                        _c(
                          "div",
                          { staticClass: "content has-text-centered" },
                          [
                            _c(
                              "p",
                              [
                                _c("b-icon", {
                                  attrs: { icon: "upload", size: "is-large" },
                                }),
                              ],
                              1
                            ),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.isUploading
                                    ? _vm.$t("component.floorPlan.replace")
                                    : _vm.$t("component.floorPlan.upload")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  !_vm.isReading && _vm.image && _vm.image.src
                    ? _c("b-image", {
                        attrs: {
                          src: _vm.image.src,
                          alt: "map file",
                          responsive: "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column is-8", attrs: { id: "svgContainer" } },
                  [_c("svg", { attrs: { id: "svgElement" } })]
                ),
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "h2",
                        { staticClass: "subtitle has-text-weight-bold" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("component.thermal_map.settings"))
                          ),
                        ]
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("component.thermal_map.name"),
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t("component.thermal_map.name")
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t("component.thermal_map.name")
                            ),
                          },
                        },
                        [
                          _c("b-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:50",
                                expression: "'required|max:50'",
                              },
                            ],
                            attrs: {
                              placeholder: _vm.$t(
                                "component.thermal_map.placeholder"
                              ),
                              type: "text",
                              name: _vm.$t("component.thermal_map.name"),
                            },
                            model: {
                              value: _vm.thermoMapName,
                              callback: function ($$v) {
                                _vm.thermoMapName = $$v
                              },
                              expression: "thermoMapName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.from"
                            ),
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t(
                                  "component.report.configuration.modal.from"
                                )
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t(
                                "component.report.configuration.modal.from"
                              )
                            ),
                          },
                        },
                        [
                          _c("b-datetimepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              name: _vm.$t(
                                "component.report.configuration.modal.from"
                              ),
                              placeholder: _vm.$t(
                                "component.report.month_picker.placeholder"
                              ),
                              "icon-right": _vm.dateTimeFrom
                                ? "close-circle"
                                : "",
                              "icon-right-clickable": "",
                              "first-day-of-week": 1,
                              "horizontal-time-picker": "",
                              "mobile-native": false,
                              position: "is-bottom-left",
                              timepicker: { hourFormat: _vm.hourFormat },
                              "datetime-formatter": (date) =>
                                _vm.dateTimeManager.formatDateTimeInInput(date),
                            },
                            on: { "icon-right-click": _vm.clearDateTimeFrom },
                            model: {
                              value: _vm.dateTimeFrom,
                              callback: function ($$v) {
                                _vm.dateTimeFrom = $$v
                              },
                              expression: "dateTimeFrom",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.to"
                            ),
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t(
                                  "component.report.configuration.modal.to"
                                )
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t("component.report.configuration.modal.to")
                            ),
                          },
                        },
                        [
                          _c("b-datetimepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              name: _vm.$t(
                                "component.report.configuration.modal.to"
                              ),
                              placeholder: _vm.$t(
                                "component.report.month_picker.placeholder"
                              ),
                              "icon-right": _vm.dateTimeTo
                                ? "close-circle"
                                : "",
                              "icon-right-clickable": "",
                              "first-day-of-week": 1,
                              "horizontal-time-picker": "",
                              "mobile-native": false,
                              position: "is-bottom-left",
                              timepicker: { hourFormat: _vm.hourFormat },
                              "datetime-formatter": (date) =>
                                _vm.dateTimeManager.formatDateTimeInInput(date),
                            },
                            on: { "icon-right-click": _vm.clearDateTimeTo },
                            model: {
                              value: _vm.dateTimeTo,
                              callback: function ($$v) {
                                _vm.dateTimeTo = $$v
                              },
                              expression: "dateTimeTo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("h2", { staticClass: "subtitle" }, [
                            _vm._v(
                              _vm._s(_vm.$t("component.thermal_map.loggers"))
                            ),
                          ]),
                          _vm.layoutData != null
                            ? _vm._l(_vm.layoutData.sensors, function (sensor) {
                                return _c(
                                  "div",
                                  { key: sensor.id, staticClass: "mb-2" },
                                  [
                                    _c(
                                      "b-field",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "component.thermal_map.logger_label",
                                            { x: sensor.index + 1 }
                                          ),
                                          type: {
                                            "is-danger":
                                              _vm.hasDuplicatedSourceAssigned(
                                                sensor.id
                                              ),
                                          },
                                          message:
                                            _vm.hasDuplicatedSourceAssigned(
                                              sensor.id
                                            )
                                              ? _vm.$t(
                                                  "component.thermal_map.can_be_assigned_only_once"
                                                )
                                              : "",
                                        },
                                      },
                                      [
                                        _c("b-autocomplete", {
                                          attrs: {
                                            data: _vm.filteredSources(
                                              _vm.selectedNames[sensor.index]
                                            ),
                                            placeholder: _vm.$t(
                                              "component.thermal_map.loggers_placeholder"
                                            ),
                                            clearable: "",
                                            field: "name",
                                            "icon-pack": "fas",
                                            icon: "search",
                                            "open-on-focus": "",
                                          },
                                          on: {
                                            select: function ($event) {
                                              return _vm.selectLogger(
                                                $event,
                                                sensor
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "empty",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "generals.no_results"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              _vm.selectedNames[sensor.index],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedNames,
                                                sensor.index,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedNames[sensor.index]",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("h2", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.thermal_map.display_settings")
                          )
                        ),
                      ]),
                      _c("h3", { staticClass: "subtitle" }, [
                        _vm._v(
                          _vm._s(_vm.$t("component.thermal_map.color_range"))
                        ),
                      ]),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("component.thermal_map.min_value"),
                          },
                        },
                        [
                          _c("b-numberinput", {
                            attrs: { "controls-position": "compact" },
                            on: {
                              input: function ($event) {
                                return _vm.updateRenderSettings($event)
                              },
                            },
                            model: {
                              value: _vm.renderSettings.minTemp,
                              callback: function ($$v) {
                                _vm.$set(_vm.renderSettings, "minTemp", $$v)
                              },
                              expression: "renderSettings.minTemp",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("component.thermal_map.max_value"),
                          },
                        },
                        [
                          _c("b-numberinput", {
                            attrs: { "controls-position": "compact" },
                            on: {
                              input: function ($event) {
                                return _vm.updateRenderSettings($event)
                              },
                            },
                            model: {
                              value: _vm.renderSettings.maxTemp,
                              callback: function ($$v) {
                                _vm.$set(_vm.renderSettings, "maxTemp", $$v)
                              },
                              expression: "renderSettings.maxTemp",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "is-flex is-flex-direction-column" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-4",
                              attrs: { type: "is-primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveConfigurationAndLoadData()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("component.thermal_map.load"))
                              ),
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                type: "is-primary",
                                tag: "a",
                                href: _vm.link,
                                "icon-pack": "fa",
                                "icon-left": "file",
                                download: "Map",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("component.thermal_map.download"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
        ]),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isUnsavedChangesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeUnsavedChangesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isUnsavedChangesModalActive = $event
            },
            "close-modal": _vm.closeUnsavedChangesModal,
          },
        },
        [
          _c("UnsavedChangesDialog", {
            attrs: { routeToNavigate: _vm.routeToNavigate },
            on: {
              modalClosed: _vm.closeUnsavedChangesModal,
              cancel: _vm.cancel,
              discard: _vm.cancel,
              save: function ($event) {
                return _vm.saveConfigurationAndLoadData()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }