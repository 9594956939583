import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import PublicDashboardOwnerRepository from '@/services/repository/PublicDashboardOwnerRepository';
import PublicDashboardComponents from '@/entities/enums/PublicDashboardComponents';
import VueUtilities from '@/services/VueUtilities';
import QrcodeVue from 'qrcode.vue';
var publicDashboardOwnerRepository;
let DashboardInfo = class DashboardInfo extends Vue {
    constructor() {
        super(...arguments);
        this.PublicDashboardComponents = PublicDashboardComponents;
        this.isEditing = false;
        this.isPublic = true;
        this.title = null;
        this.selectedSources = [];
        this.selectedSourceGroups = [];
        this.allowedComponents = [];
        this.isLoading = false;
        this.origin = window.location.origin;
        this.showAnnotations = false;
        this.selectedUsers = [];
        this.selectedDevices = [];
    }
    getDeviceName(deviceId) {
        return this.devices.find((x) => x.deviceId == deviceId);
    }
    get filteredDashboardGroupList() {
        return this.dashboard.sourceGroupList.filter((x) => this.sourceGroups.some((y) => y.id === x));
    }
    created() {
        publicDashboardOwnerRepository = new PublicDashboardOwnerRepository(this);
        this.isPublic = this.dashboard.isPublic;
        this.title = this.dashboard.name;
        this.selectedSources = this.sources.filter((x) => this.dashboard.sourceList.includes(x.id));
        this.selectedSourceGroups = this.sourceGroups.filter((x) => this.dashboard.sourceGroupList.includes(x.id));
        this.selectedUsers = this.users.filter((x) => this.dashboard.userList.includes(x.apiUserId));
        this.selectedDevices = this.devices.filter((x) => this.dashboard.gatewayList.includes(x.deviceId));
        this.allowedComponents = this.dashboard.allowedComponents;
        this.showAnnotations = this.dashboard.allowedComponents.includes(PublicDashboardComponents.ANNOTATIONS);
    }
    downloadQrCode() {
        let canvasImage = this.$refs.qrcode.$el.children[0].toDataURL('image/png');
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = 'qrcode.png';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
        };
        xhr.open('GET', canvasImage);
        xhr.send();
    }
    async downloadPdf() {
        await publicDashboardOwnerRepository.downloadDashboardPdf(this.dashboard.id, this.dashboard.name, this.departmentId);
    }
    editPublicDashboard() {
        this.isEditing = !this.isEditing;
    }
    updatePublicDashboard() {
        let usersIds = this.selectedUsers.map((x) => x.apiUserId);
        let sourcesIds = this.selectedSources.map((x) => x.id);
        let sourceGroupsIds = this.selectedSourceGroups.map((x) => x.id);
        let deviceIds = this.selectedDevices.map((x) => x.deviceId);
        if (usersIds.length <= 0 && !this.isPublic) {
            this.$buefy.toast.open({
                duration: 5000,
                message: this.$t('component.public_dashboard.create.errors.select_user'),
                position: 'is-bottom',
                type: 'is-danger'
            });
            return;
        }
        if (sourcesIds.length <= 0 && sourceGroupsIds.length <= 0) {
            this.$buefy.toast.open({
                duration: 5000,
                message: this.$t('component.public_dashboard.create.errors.select_entity'),
                position: 'is-bottom',
                type: 'is-danger'
            });
            return;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.showAnnotations &&
                    this.allowedComponents.includes(PublicDashboardComponents.REPORTS) &&
                    !this.allowedComponents.includes(PublicDashboardComponents.ANNOTATIONS)) {
                    this.allowedComponents.push(PublicDashboardComponents.ANNOTATIONS);
                }
                else if ((!this.showAnnotations || !this.allowedComponents.includes(PublicDashboardComponents.REPORTS)) &&
                    this.allowedComponents.includes(PublicDashboardComponents.ANNOTATIONS)) {
                    this.allowedComponents = this.allowedComponents.filter((x) => x != PublicDashboardComponents.ANNOTATIONS);
                }
                let dto = {
                    Name: this.title,
                    IsPublic: this.isPublic,
                    UserList: this.isPublic ? [] : usersIds,
                    SourceGroupList: sourceGroupsIds,
                    SourceList: sourcesIds,
                    GatewayList: deviceIds,
                    Settings: null,
                    AllowedComponents: this.allowedComponents
                };
                this.isLoading = true;
                let createResult = await publicDashboardOwnerRepository.updatePublicDashboard(this.dashboard.id, dto, this.departmentId);
                this.isLoading = false;
                if (createResult !== null) {
                    VueUtilities.openSuccessToast(this, this.$t('component.public_dashboard.dashboard_detail.update_success'));
                    this.isEditing = false;
                    this.$emit('reload');
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.public_dashboard.dashboard_detail.update_failure'));
                }
            }
        });
    }
};
__decorate([
    Prop({ type: Object })
], DashboardInfo.prototype, "dashboard", void 0);
__decorate([
    Prop({ type: Array })
], DashboardInfo.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array })
], DashboardInfo.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array })
], DashboardInfo.prototype, "devices", void 0);
__decorate([
    Prop({ type: Array })
], DashboardInfo.prototype, "users", void 0);
__decorate([
    Prop({ type: Object })
], DashboardInfo.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: String, default: null })
], DashboardInfo.prototype, "departmentId", void 0);
DashboardInfo = __decorate([
    Component({ components: { QrcodeVue } })
], DashboardInfo);
export default DashboardInfo;
