var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "is-mobile department-box is-full-height is-flex",
      class: [
        _vm.getClass(),
        _vm.isAdmin || _vm.department.member.Enabled
          ? "is-enabled"
          : "is-disabled",
      ],
    },
    [
      !_vm.isAdmin
        ? _c(
            "div",
            {
              staticClass:
                "mx-3 is-full-height is-flex is-align-items-center is-justify-content-center",
            },
            [
              _c("b-icon", {
                staticClass: "is-clickable",
                attrs: {
                  type: _vm.isFavorite ? "is-warning" : "is-light",
                  "custom-class": _vm.department.member.Enabled
                    ? "favorite-star"
                    : "favorite-star disabled",
                  size: "is-small",
                  icon: "star",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.favoriteSelected()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "column p-0 is-full-height" },
        [
          _vm.isAdmin || _vm.department.member.Enabled
            ? _c(
                "router-link",
                {
                  staticClass:
                    "is-full-height is-flex is-align-items-center is-justify-content-left",
                  attrs: {
                    to: {
                      name: "dashboard",
                      params: {
                        lang: _vm.$route.params.lang,
                        departmentId: _vm.department.id,
                      },
                    },
                  },
                },
                [
                  _vm.isMobileView
                    ? _c(
                        "h6",
                        {
                          staticClass:
                            "title is-6 has-text-black mb-0 px-1 py-5 has-text-left",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.abbreviateName(_vm.department.name)) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "h6",
                        {
                          staticClass:
                            "title is-6 has-text-black mb-0 px-1 py-5 has-text-left truncate",
                        },
                        [_vm._v(_vm._s(_vm.department.name))]
                      ),
                ]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "is-full-height is-flex is-align-items-center is-justify-content-center",
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass:
                        "title is-6 has-text-black mb-0 py-5 px-1 has-text-centered",
                    },
                    [_vm._v(" " + _vm._s(_vm.department.name) + " ")]
                  ),
                ]
              ),
        ],
        1
      ),
      _vm.isAdmin || _vm.department.member.Enabled
        ? _c(
            "div",
            {
              staticClass:
                "mx-2 is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-right",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end",
                  class:
                    _vm.fineState && !_vm.isAcknowledged ? "clickable" : "",
                  on: {
                    click: function ($event) {
                      _vm.fineState && !_vm.isAcknowledged
                        ? _vm.openModal()
                        : ""
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.organization_card.tooltip.acknowledged"
                            ),
                            position: "is-top",
                            type: "is-dark",
                          },
                        },
                        [
                          _vm.isAcknowledged
                            ? _c("b-icon", {
                                attrs: {
                                  "icon-pack": "fas",
                                  icon: "check-circle",
                                  type: "is-success",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.organization_card.tooltip.maintenance"
                            ),
                            position: "is-top",
                            type: "is-dark",
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              "icon-pack": "fas",
                              icon: "wrench",
                              type: _vm.getIssueByType(
                                _vm.IssueType.MAINTENANCE
                              )
                                ? "is-info"
                                : "is-light",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.organization_card.tooltip.fine"
                            ),
                            position: "is-top",
                            type: "is-dark",
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              "icon-pack": "fas",
                              icon: "check",
                              type: _vm.fineState ? "is-light" : "is-success",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.organization_card.tooltip.warning"
                            ),
                            position: "is-top",
                            type: "is-dark",
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              "icon-pack": "fas",
                              icon: "exclamation-triangle",
                              type: _vm.getIssueByType(
                                _vm.IssueType.ALARM,
                                "Warning"
                              )
                                ? "is-warning"
                                : "is-light",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.organization_card.tooltip.alarm"
                            ),
                            position: "is-top",
                            type: "is-dark",
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              "icon-pack": "fas",
                              icon: "exclamation-circle",
                              type: _vm.getIssueByType(
                                _vm.IssueType.ALARM,
                                "Alarm"
                              )
                                ? "is-danger"
                                : "is-light",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.organization_card.tooltip.low_battery"
                            ),
                            position: "is-top",
                            type: "is-dark",
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              "icon-pack": "fas",
                              icon: "battery-quarter",
                              type: _vm.getIssueByType(
                                _vm.IssueType.LOW_BATTERY
                              )
                                ? "is-warning"
                                : "is-light",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.organization_card.tooltip.no_connection"
                            ),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              "icon-pack": "fas",
                              icon: "wifi",
                              type:
                                _vm.getIssueByType(
                                  _vm.IssueType.SOURCE_OFFLINE
                                ) ||
                                _vm.getIssueByType(_vm.IssueType.DEVICE_OFFLINE)
                                  ? "is-grey"
                                  : "is-light",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.fineState && !_vm.isAcknowledged
                    ? _c("span", { staticClass: "is-size-7 is-underlined" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.organization_card.acknowledge")
                          )
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isAcknowledgeModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeAcknowledgeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isAcknowledgeModalActive = $event
            },
            "close-modal": _vm.closeAcknowledgeModal,
          },
        },
        [
          _c("AcknowledgeIssueModal", {
            attrs: {
              department: _vm.department,
              dateTimeManager: _vm.dateTimeManager,
              fineState: _vm.fineState,
            },
            on: { reload: _vm.reload, modalClosed: _vm.closeAcknowledgeModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }