import { __decorate } from "tslib";
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NotificationRuleDisplayMode } from '@/entities/notifications/NotificationRuleDisplayMode';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import DelayOptions from '@/entities/enums/DelayOptions';
import RepeatOptions from '@/entities/enums/RepeatOptions';
import RequestState from '@/entities/enums/RequestState';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import NotificationPriority from '@/entities/enums/NotificationPriority';
import NotificationChannelType from '@/entities/notifications/NotificationChannelType';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
var notificationSubscriptionsRepository;
/**
 * This component is used for basic measured data alarm notifications management
 * Basic = scope is company/department.
 * For advanced rules (with source/sourcegroup scope) see EntityMeasuredDataNotification.vue
 */
let MeasuredDataNotification = class MeasuredDataNotification extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationChannelType = NotificationChannelType;
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.NotificationMeasuredRuleScope = NotificationMeasuredRuleScope;
        this.NotificationRuleDisplayMode = NotificationRuleDisplayMode;
        this.RepeatOptions = RepeatOptions;
        this.currentType = NotificationSubscriptionType.MEASURED_DATA_ALARM;
        this.currentEnabled = true;
        this.currentDisplayMode = NotificationRuleDisplayMode.BASIC;
        this.currentChannels = {};
        this.selectedMode = null;
        this.requestState = null;
        this.localRule = null;
        this.isLoadingMeasured = false;
    }
    /**
     * Returns true if new rule needs to be created and false if rule should be updated.
     */
    get isCreating() {
        if (this.localRule == null)
            return true;
        else
            return false;
    }
    /**
     * Return default settings of currently selected type (there are two types of measured alarm notifications)
     */
    get currentTypeSettings() {
        let currentType = JSON.parse(JSON.stringify(this.types.find((x) => x.name == this.currentType)));
        if (this.buzzerIsAllowed === false || !this.$ability.can(Actions.MANAGEOWN, Subjects.COMPANY))
            currentType.channel = currentType.channel.filter(x => x.type !== NotificationChannelType.BUZZER);
        return currentType;
    }
    /**
     * Returns true if currently selected display mode is basic
     */
    get isBasic() {
        return this.currentDisplayMode === null || this.currentDisplayMode === NotificationRuleDisplayMode.BASIC;
    }
    /**
     * Returns number of columns for table of settings. Number of columns depends on notification type,
     * allowed channels and selected display mode.
     */
    get numberOfColumns() {
        if (this.isBasic)
            return this.currentTypeSettings.channel.length;
        else
            return this.currentTypeSettings.channel.length + 1;
    }
    get delayOptions() {
        return DelayOptions.getValues();
    }
    get requestFinished() {
        return this.requestState === RequestState.SUCCESS;
    }
    get requestPending() {
        return this.requestState === RequestState.PENDING;
    }
    get requestError() {
        return this.requestState === RequestState.ERROR;
    }
    /**
     * Returns true when user has not the phone number saved in his profile
     */
    get phoneIsNotDefined() {
        return this.currentUser.detailedInfo.PhoneNumber?.Number == null;
    }
    /**
     * Returns true when user has the phone number saved in his profile but this number wasn't verified
     */
    get phoneIsNotVerified() {
        return this.currentUser.detailedInfo.PhoneNumber?.Verified == false;
    }
    /**
     * Check if user cas use phone channels for notifications (Phone/SMS)
     */
    get phoneChannelsAvailable() {
        return !this.phoneIsNotDefined && !this.phoneIsNotVerified;
    }
    /**
     * Check if buzzer is allowed in tier for currently selected department
     *
     */
    get buzzerIsAllowed() {
        return this.tier?.features.find((x) => x.Name === 'Observer.BuzzerNotifications')?.Value?.Allowed === true;
    }
    created() {
        //Create local instance of rule to avoid error about prop mutation
        this.localRule = this.rule;
        this.selectedMode = this.selectedMeasuredRulesMode;
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        this.currentEnabled = this.measuredRuleEnabled;
        // if new rule needs to be created, assign default values to channels
        if (this.isCreating == true) {
            this.currentTypeSettings.channel.forEach((channel) => {
                this.currentChannels[channel.type] = NotificationSubscription.getDefaultValuesForTypeAndChannel(this.currentType, channel.type);
            });
        }
        else {
            //If updating rules, load data from current rule
            this.currentType = this.localRule.notificationType;
            this.currentDisplayMode = this.localRule.displayMode;
            //assign default channels values
            this.currentTypeSettings.channel.forEach((channel) => {
                let existingChannel = this.localRule.channels.find((x) => x.type == channel.type);
                //load default channel settings
                let ch = NotificationSubscription.getDefaultValuesForTypeAndChannel(this.currentType, channel.type);
                if (existingChannel) {
                    //load values from existing rule
                    ch = {
                        enabled: true,
                        delay: existingChannel.settings.delay,
                        onResolve: existingChannel.settings.onResolve,
                        repeat: existingChannel.settings.repeat
                    };
                }
                else {
                    //if updating rule and channel is not used, set disabled to true
                    ch.enabled = false;
                }
                this.currentChannels[channel.type] = ch;
            });
        }
        if (this.autoSave === false) {
            this.$emit('ruleChanged', this.buildRuleForParent());
        }
    }
    changeDisplayMode(selected) {
        this.currentDisplayMode = selected;
        this.save();
    }
    /**
     * Check if given type has available onResolve setting
     */
    channelForTypeHasOnResolve(channelType) {
        let channel = this.currentTypeSettings.channel.find((x) => x.type == channelType);
        return channel.onResolve;
    }
    changedValue(event) {
        this.$emit('switchedValue', event);
        this.save();
    }
    save() {
        this.isLoadingMeasured = true;
        if (this.autoSave === false) {
            this.$emit('ruleChanged', this.buildRuleForParent());
        }
        else {
            this.requestState = RequestState.PENDING;
            if (this.isCreating) {
                this.createNewRule();
            }
            else {
                this.updateRule();
            }
        }
        this.isLoadingMeasured = false;
    }
    //for schedules
    buildRuleForParent() {
        let currentChannelsDTO = {};
        Object.keys(this.currentChannels).forEach((channelKey) => {
            if (this.currentChannels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: this.currentChannels[channelKey].delay,
                    onResolve: this.currentChannels[channelKey].onResolve,
                    repeat: this.currentChannels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let newRule = {
            ScopeType: NotificationScopeType.COMPANY,
            ScopeId: this.departmentId,
            NotificationType: this.currentType,
            Priority: NotificationPriority.NORMAL.name,
            Enabled: this.currentEnabled,
            Channels: currentChannelsDTO,
            DisplayMode: this.currentDisplayMode,
            PresetId: this.rule?.presetId,
            ScheduleId: this.scheduleId
        };
        return newRule;
    }
    async createNewRule() {
        let currentChannelsDTO = {};
        Object.keys(this.currentChannels).forEach((channelKey) => {
            if (this.currentChannels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: this.currentChannels[channelKey].delay,
                    onResolve: this.currentChannels[channelKey].onResolve,
                    repeat: this.currentChannels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let newRule = {
            ScopeType: NotificationScopeType.COMPANY,
            ScopeId: this.departmentId,
            NotificationType: this.currentType,
            Priority: NotificationPriority.NORMAL.name,
            Enabled: true,
            Channels: currentChannelsDTO,
            DisplayMode: this.currentDisplayMode,
            PresetId: null,
            ScheduleId: this.scheduleId
        };
        let result = await notificationSubscriptionsRepository.createNotificationSubscription([newRule], this.currentUser.apiUserId, this.departmentId);
        if (result instanceof (Array)) {
            this.requestState = RequestState.SUCCESS;
            this.localRule = result[0];
        }
        else {
            this.requestState = RequestState.ERROR;
        }
    }
    async updateRule() {
        let currentChannelsDTO = {};
        Object.keys(this.currentChannels).forEach((channelKey) => {
            if (this.currentChannels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: this.currentChannels[channelKey].delay,
                    onResolve: this.currentChannels[channelKey].onResolve,
                    repeat: this.currentChannels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let updateDTO = {
            Priority: this.localRule.priority.name,
            Enabled: this.currentEnabled,
            Channels: currentChannelsDTO,
            NotificationType: this.currentType,
            DisplayMode: this.currentDisplayMode,
            PresetId: this.rule?.presetId
        };
        let result = await notificationSubscriptionsRepository.updateNotificationSubscription(this.localRule.notificationSubscriptionId, updateDTO, this.currentUser.apiUserId, this.departmentId);
        if (result === true) {
            this.requestState = RequestState.SUCCESS;
        }
        else {
            this.requestState = RequestState.ERROR;
        }
    }
    isPhoneChannel(channel) {
        return channel === NotificationChannelType.SMS || channel === NotificationChannelType.CALL;
    }
    testSiren() {
        this.$emit('openDevicesSirenModal');
    }
    async modeChanged() {
        this.$emit('changeMode', this.selectedMode);
    }
};
__decorate([
    Prop({ type: Object })
], MeasuredDataNotification.prototype, "rule", void 0);
__decorate([
    Prop({ type: Object })
], MeasuredDataNotification.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Array })
], MeasuredDataNotification.prototype, "types", void 0);
__decorate([
    Prop({ type: String })
], MeasuredDataNotification.prototype, "selectedMeasuredRulesMode", void 0);
__decorate([
    Prop({ type: String })
], MeasuredDataNotification.prototype, "departmentId", void 0);
__decorate([
    Prop({ type: String })
], MeasuredDataNotification.prototype, "scheduleId", void 0);
__decorate([
    Prop({ type: Boolean })
], MeasuredDataNotification.prototype, "measuredRuleEnabled", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], MeasuredDataNotification.prototype, "autoSave", void 0);
__decorate([
    Prop({ type: Object })
], MeasuredDataNotification.prototype, "tier", void 0);
MeasuredDataNotification = __decorate([
    Component({})
], MeasuredDataNotification);
export default MeasuredDataNotification;
