var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading &&
      _vm.$ability.can(
        _vm.$permActions.READ,
        _vm.$permSubjects.ADMIN_DEPARTMENTS
      )
        ? _c(
            "div",
            [
              _c("AdminCompanyDetail", {
                attrs: {
                  company: _vm.department,
                  users: _vm.userList,
                  deviceList: _vm.deviceList.getData() || [],
                  totalDeviceListSize: _vm.deviceList.getPagination().total,
                  companyLoggers: _vm.companyLoggers,
                  activeTier: _vm.activeTier,
                  tiersHistory: _vm.tiersHistory,
                  sources: _vm.sources,
                  sourceGroups: _vm.sourceGroups,
                  deletedSourcesApiResponse: _vm.deletedSourcesApiResponse,
                  companySettings: _vm.companySettings,
                  dateTimeManager: _vm.dateTimeManager,
                  dashboards: _vm.dashboards,
                  departmentIssues: _vm.departmentIssues,
                  isIssuesTableLoading: _vm.isIssuesTableLoading,
                  devices: _vm.devices,
                  allUsers: _vm.allUsers,
                  departmentTags: _vm.departmentTags,
                },
                on: {
                  loginIntoCompany: _vm.login,
                  loadAll: _vm.loadAll,
                  openAssignTier: _vm.openAssignTier,
                  openInviteUserModal: _vm.openInviteUserModal,
                  loadDevicesAndLoggers: _vm.loadDevicesAndLoggers,
                  loadTier: _vm.loadTier,
                  loadSharedLinks: _vm.loadSharedLinks,
                  deleteCompany: _vm.deleteCompany,
                  deleteDashboard: _vm.deleteDashboard,
                  reloadTiers: _vm.reloadTiers,
                  deactivateTier: _vm.deactivateTier,
                  deleteTier: _vm.deleteTier,
                  updateTier: _vm.updateTier,
                  saveSetting: _vm.saveSetting,
                  deleteSetting: _vm.deleteSetting,
                  loadDeletedSources: _vm.loadDeletedSources,
                  reviveSource: _vm.reviveSource,
                  createPublicDashboard: _vm.createPublicDashboard,
                  updateCompany: _vm.updateCompany,
                  openchangeUserRoleModal: _vm.openchangeUserRoleModal,
                  openAddUsersModal: _vm.openAddUsersModal,
                  unassignUser: _vm.unassignUser,
                  disableUserDialog: _vm.disableUserDialog,
                  enableUserDialog: _vm.enableUserDialog,
                  openPauseNotificationsModal: _vm.openPauseNotificationsModal,
                  openRegisterDevicesModal: _vm.openRegisterDevicesModal,
                  toggleRegisterLoggersModal: _vm.toggleRegisterLoggersModal,
                  onIssuesTableOptionsChange: _vm.onIssuesTableOptionsChange,
                  acknowledgeIssue: _vm.acknowledgeIssueDialog,
                  downloadCalibration: _vm.downloadCalibration,
                  loadDevices: _vm.loadDevices,
                  loadDevicesForDepartment: _vm.loadDevicesForDepartment,
                  loadAllUsers: _vm.loadAllUsers,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isAddDashboardModalActive,
            "has-modal-card": "",
            onCancel: _vm.onCreateModalClose,
          },
          on: {
            "update:active": function ($event) {
              _vm.isAddDashboardModalActive = $event
            },
          },
        },
        [
          _c("CreateDashboardModal", {
            attrs: {
              sources: _vm.sources,
              availableSourceGroups: _vm.sourceGroups,
              availableDevices: _vm.devices,
              availableUsers: _vm.userList,
            },
            on: { modalClosed: _vm.onCreateModalClose },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.changeUserRoleModal,
            "has-modal-card": "",
            onCancel: _vm.closechangeUserRoleModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.changeUserRoleModal = $event
            },
            "close-modal": _vm.closechangeUserRoleModal,
          },
        },
        [
          _c("ChangeUserRoleModal", {
            attrs: {
              user: _vm.selectedUser,
              departmentScope: true,
              userRoles: _vm.userRoles,
            },
            on: {
              modalClosed: _vm.closechangeUserRoleModal,
              changeRole: _vm.ownerCheck,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isAddUserModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeAddUsersModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isAddUserModalActive = $event
            },
            "close-modal": _vm.closeAddUsersModal,
          },
        },
        [
          _c("AddCompanyUsersModal", {
            attrs: {
              users: _vm.userList,
              userRoles: _vm.userRoles,
              company: _vm.department,
              availableUsersForRole: _vm.availableUsersList,
              totalSize: _vm.totalSize,
              componentIsLoading: _vm.isAddCompanyUsersModalLoading,
            },
            on: {
              onOptionsChange: _vm.loadCompanyUsers,
              loadCompanyUsers: _vm.loadCompanyUsers,
              modalClosed: _vm.closeAddUsersModal,
              reloadUsers: _vm.reloadUsers,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isInviteModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeInviteUserModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isInviteModalActive = $event
            },
            "close-modal": _vm.closeInviteUserModal,
          },
        },
        [
          _c("InviteUserModal", {
            attrs: { selectedDepartmentId: _vm.companyId },
            on: {
              modalClosed: function ($event) {
                return _vm.closeInviteUserModal()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isAssignTierModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeAssignModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isAssignTierModalActive = $event
            },
            "close-modal": _vm.closeAssignModal,
          },
        },
        [
          _c("AssignTierToCompanyModal", {
            attrs: {
              company: _vm.department,
              activeTier: _vm.activeTier,
              dateTimeManager: _vm.dateTimeManager,
            },
            on: {
              modalClosed: _vm.closeAssignModal,
              loadTier: function ($event) {
                return _vm.loadTier()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isPauseNotificationsModalActive,
            "has-modal-card": "",
            onCancel: _vm.closePauseNotificationsModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isPauseNotificationsModalActive = $event
            },
            "close-modal": _vm.closePauseNotificationsModal,
          },
        },
        [
          _c("PauseNotificationsModal", {
            attrs: { users: _vm.userList, totalSize: _vm.userList.length },
            on: {
              modalClosed: _vm.closePauseNotificationsModal,
              pauseNotifications: _vm.pauseNotifications,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isRegisterDevicesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeRegisterDevicesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isRegisterDevicesModalActive = $event
            },
            "close-modal": _vm.closeRegisterDevicesModal,
          },
        },
        [
          _c("AdminRegisterDevicesModal", {
            attrs: {
              companyId: _vm.companyId,
              componentIsLoading: _vm.isLoadingAdminDevices,
              adminDevices: _vm.adminDevices.getData() || [],
              totalSize: _vm.adminDevices.getPagination().total,
              dateTimeManager: _vm.dateTimeManager,
            },
            on: {
              loadAdminDevices: _vm.loadAdminDevices,
              loadDevicesAndLoggers: _vm.loadDevicesAndLoggers,
              modalClosed: _vm.closeRegisterDevicesModal,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          class: _vm.isScannerModalActive ? "registerLoggersModal" : "",
          attrs: {
            active: _vm.isRegisterLoggersModalActive,
            "has-modal-card": "",
            onCancel: _vm.toggleRegisterLoggersModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isRegisterLoggersModalActive = $event
            },
            "close-modal": _vm.toggleRegisterLoggersModal,
          },
        },
        [
          _c("AdminRegisterLoggersToDepartmentModal", {
            attrs: {
              companyId: _vm.companyId,
              adminLoggers: _vm.adminLoggers.getData() || [],
              componentIsLoading: _vm.isLoadingAdminLoggers,
              totalSize: _vm.adminLoggers.getPagination().total,
            },
            on: {
              closeModal: _vm.toggleRegisterLoggersModal,
              scannerModalActive: _vm.scannerModalActive,
              loadAdminLoggers: _vm.loadAdminLoggers,
              loadDevicesAndLoggers: _vm.loadDevicesAndLoggers,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }