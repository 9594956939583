var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "tile is-parent" }, [
      _c("div", { staticClass: "tile is-child box" }, [
        _c(
          "div",
          { staticClass: "is-flex is-justify-content-flex-end mx-0 mb-5" },
          [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c("b-button", {
                  staticClass: "mr-0-mobile",
                  attrs: { "icon-left": "plus", type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.uploadThermalMap()
                    },
                  },
                }),
                !_vm.isLayoutList
                  ? _c(
                      "b-tooltip",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: {
                          label: _vm.$t("admin.component.layout.listView"),
                          position: "is-left",
                          type: "is-dark",
                        },
                      },
                      [
                        _c("b-button", {
                          attrs: { "icon-left": "stream" },
                          on: {
                            click: function ($event) {
                              return _vm.changeLayout()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isLayoutList
                  ? _c(
                      "b-tooltip",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: {
                          label: _vm.$t("admin.component.layout.gridView"),
                          position: "is-left",
                          type: "is-dark",
                        },
                      },
                      [
                        _c("b-button", {
                          attrs: { "icon-left": "th-large" },
                          on: {
                            click: function ($event) {
                              return _vm.changeLayout()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm.thermoMaps.length > 0
          ? _c("div", { staticClass: "columns is-multiline pb-4" }, [
              _c(
                "div",
                {
                  staticClass: "column",
                  class: _vm.isLayoutList ? "is-full" : "is-8",
                },
                [
                  _c("div", { staticClass: "columns mx-0" }, [
                    _vm.thermoMaps.length > 1
                      ? _c(
                          "div",
                          { staticClass: "is-2 column m-0 px-2 py-2" },
                          _vm._l(_vm.thermoImages, function (thermoImage, i) {
                            return _c(
                              "div",
                              {
                                key: thermoImage.thermoMapId,
                                staticClass:
                                  "is-clickable is-word-break-all mb-2 is-hidden-mobile",
                                style:
                                  thermoImage.thermoMapId === _vm.selectedMap.id
                                    ? ""
                                    : "opacity: 0.2;",
                                on: {
                                  click: function ($event) {
                                    return _vm.setSelectedThermoMap(
                                      thermoImage.thermoMapId
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { id: "svgContainer_" + i } },
                                  [
                                    _c("svg", {
                                      attrs: { id: "svgElement_" + i },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "column mx-1",
                        class: _vm.isLayoutList
                          ? _vm.thermoMaps.length > 1
                            ? "is-10"
                            : "is-full"
                          : _vm.thermoMaps.length > 1
                          ? "is-10"
                          : "is-full",
                      },
                      [
                        _c("div", { attrs: { id: _vm.SVG_CONTAINER } }, [
                          _c("svg", { attrs: { id: "svgElement" } }),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "column",
                  class: _vm.isLayoutList ? "is-full" : "is-4",
                },
                [
                  _c(
                    "b-collapse",
                    {
                      staticClass: "panel",
                      attrs: {
                        animation: "slide",
                        "aria-id": "contentIdForA11y3",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "trigger",
                            fn: function (props) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "is-flex is-justify-content-space-between",
                                    attrs: {
                                      role: "button",
                                      "aria-controls": "contentIdForA11y3",
                                      "aria-expanded": props.open,
                                    },
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "subtitle mb-2 has-text-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "component.thermal_map.customSettings"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {},
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: props.open
                                              ? "chevron-down"
                                              : "chevron-right",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2969533064
                      ),
                    },
                    [
                      _c("div", { staticClass: "notification" }, [
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "div",
                            { staticClass: "p-4" },
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "component.thermal_map.temperature"
                                    ),
                                    type: {
                                      "is-danger": _vm.errors.has(
                                        _vm.$t(
                                          "component.thermal_map.temperature"
                                        )
                                      ),
                                    },
                                    message: _vm.errors.first(
                                      _vm.$t(
                                        "component.thermal_map.temperature"
                                      )
                                    ),
                                  },
                                },
                                _vm._l(
                                  _vm.ThermoStatisticsOptions,
                                  function (el) {
                                    return _c(
                                      "b-radio-button",
                                      {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        key: el,
                                        attrs: {
                                          name: _vm.$t(
                                            "component.thermal_map.temperature"
                                          ),
                                          "native-value": el,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.updateStatisticsValue()
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedStatisticsValue,
                                          callback: function ($$v) {
                                            _vm.selectedStatisticsValue = $$v
                                          },
                                          expression: "selectedStatisticsValue",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                `component.thermal_map.${el}`
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                              _vm.layoutDataList[_vm.mainMapIndex] != null
                                ? _vm._l(
                                    _vm.layoutDataList[_vm.mainMapIndex]
                                      .sensors,
                                    function (sensor) {
                                      return _c("div", { key: sensor.id }, [
                                        _vm.selectedNames[sensor.index]
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-2" },
                                              [
                                                _c(
                                                  "b-field",
                                                  {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "component.thermal_map.logger_label",
                                                        { x: sensor.index + 1 }
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.selectedNames[
                                                              sensor.index
                                                            ]
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm.selectedStatisticsValue ==
                                                _vm.ThermoStatisticsOptions.MIN
                                                  ? _c(
                                                      "b-field",
                                                      {
                                                        staticClass: "m-0",
                                                        attrs: {
                                                          "label-position":
                                                            "on-border",
                                                          label: _vm.$t(
                                                            "component.thermal_map.temperatureMin"
                                                          ),
                                                          type: {
                                                            "is-danger":
                                                              _vm.errors.has(
                                                                _vm.$t(
                                                                  "component.thermal_map.temperatureMin"
                                                                )
                                                              ),
                                                          },
                                                          message:
                                                            _vm.errors.first(
                                                              _vm.$t(
                                                                "component.thermal_map.temperatureMin"
                                                              )
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c("b-input", {
                                                          directives: [
                                                            {
                                                              name: "validate",
                                                              rawName:
                                                                "v-validate",
                                                              value: "required",
                                                              expression:
                                                                "'required'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "component.thermal_map.temperatureMin"
                                                            ),
                                                            name: _vm.$t(
                                                              "component.thermal_map.temperatureMin"
                                                            ),
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectStatisticsValue(
                                                                $event,
                                                                sensor
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .sensorTemperatureOptions[
                                                                sensor.index
                                                              ]
                                                                .temperatureOptions
                                                                .min,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .sensorTemperatureOptions[
                                                                  sensor.index
                                                                ]
                                                                  .temperatureOptions,
                                                                "min",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "sensorTemperatureOptions[sensor.index].temperatureOptions.min",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm.selectedStatisticsValue ==
                                                    _vm.ThermoStatisticsOptions
                                                      .MAX
                                                  ? _c(
                                                      "b-field",
                                                      {
                                                        attrs: {
                                                          "label-position":
                                                            "on-border",
                                                          label: _vm.$t(
                                                            "component.thermal_map.temperatureMax"
                                                          ),
                                                          type: {
                                                            "is-danger":
                                                              _vm.errors.has(
                                                                _vm.$t(
                                                                  "component.thermal_map.temperatureMax"
                                                                )
                                                              ),
                                                          },
                                                          message:
                                                            _vm.errors.first(
                                                              _vm.$t(
                                                                "component.thermal_map.temperatureMax"
                                                              )
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c("b-input", {
                                                          directives: [
                                                            {
                                                              name: "validate",
                                                              rawName:
                                                                "v-validate",
                                                              value: "required",
                                                              expression:
                                                                "'required'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "component.thermal_map.temperatureMax"
                                                            ),
                                                            name: _vm.$t(
                                                              "component.thermal_map.temperatureMax"
                                                            ),
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectStatisticsValue(
                                                                $event,
                                                                sensor
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .sensorTemperatureOptions[
                                                                sensor.index
                                                              ]
                                                                .temperatureOptions
                                                                .max,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .sensorTemperatureOptions[
                                                                  sensor.index
                                                                ]
                                                                  .temperatureOptions,
                                                                "max",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "sensorTemperatureOptions[sensor.index].temperatureOptions.max",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.selectedStatisticsValue ==
                                                _vm.ThermoStatisticsOptions.AVG
                                                  ? _c(
                                                      "b-field",
                                                      {
                                                        attrs: {
                                                          "label-position":
                                                            "on-border",
                                                          label: _vm.$t(
                                                            "component.thermal_map.temperatureAvg"
                                                          ),
                                                          type: {
                                                            "is-danger":
                                                              _vm.errors.has(
                                                                _vm.$t(
                                                                  "component.thermal_map.temperatureAvg"
                                                                )
                                                              ),
                                                          },
                                                          message:
                                                            _vm.errors.first(
                                                              _vm.$t(
                                                                "component.thermal_map.temperatureAvg"
                                                              )
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c("b-input", {
                                                          directives: [
                                                            {
                                                              name: "validate",
                                                              rawName:
                                                                "v-validate",
                                                              value: "required",
                                                              expression:
                                                                "'required'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "component.thermal_map.temperatureAvg"
                                                            ),
                                                            name: _vm.$t(
                                                              "component.thermal_map.temperatureAvg"
                                                            ),
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectStatisticsValue(
                                                                $event,
                                                                sensor
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .sensorTemperatureOptions[
                                                                sensor.index
                                                              ]
                                                                .temperatureOptions
                                                                .avg,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .sensorTemperatureOptions[
                                                                  sensor.index
                                                                ]
                                                                  .temperatureOptions,
                                                                "avg",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "sensorTemperatureOptions[sensor.index].temperatureOptions.avg",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    }
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("hr"),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 is-flex is-justify-content-space-between is-align-items-center is-word-break-all",
                    },
                    [
                      _c(
                        "h2",
                        { staticClass: "subtitle has-text-weight-bold" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("component.thermal_map.settings"))
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            position: "is-bottom-left",
                            "append-to-body": "",
                            "aria-role": "list",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "trigger",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "admin.component.devices.detail.actions.title"
                                          ),
                                          position: "is-left",
                                          type: "is-dark",
                                        },
                                      },
                                      [
                                        _c("b-button", {
                                          staticClass: "is-dark-grey ml-1",
                                          attrs: { "icon-left": "ellipsis-v" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2780924240
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "custom-dropdown-item",
                              attrs: { "aria-role": "listitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateThermoMap()
                                },
                              },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "pencil-alt" } }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("generals.edit")) + " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "custom-dropdown-item",
                              attrs: { "aria-role": "listitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteThermoMap()
                                },
                              },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "trash" } }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("generals.delete")) + " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.thermoMaps.length > 1
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "is-hidden-tablet is-flex is-justify-content-center my-2",
                        },
                        _vm._l(_vm.thermoMaps, function (thermo) {
                          return _c("b-icon", {
                            key: thermo.id,
                            staticClass: "mx-1 is-clickable",
                            attrs: {
                              size: "is-small",
                              pack:
                                thermo.id === _vm.selectedMap.id
                                  ? "fas"
                                  : "far",
                              icon: "circle",
                              type: "is-primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.setSelectedThermoMap(thermo.id)
                              },
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("component.thermal_map.name"),
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.selectedMap.name) + " ")]
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.from"
                            ),
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dateTimeManager.formatDateTimeInInput(
                                  new Date(_vm.selectedMap.from * 1000)
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.to"
                            ),
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dateTimeManager.formatDateTimeInInput(
                                    new Date(_vm.selectedMap.to * 1000)
                                  )
                                )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "subtitle mt-5 mb-2 has-text-weight-bold",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("component.thermal_map.loggers"))
                              ),
                            ]
                          ),
                          _vm.layoutDataList[0] != null
                            ? _vm._l(
                                _vm.layoutDataList[0].sensors,
                                function (sensor) {
                                  return _c(
                                    "div",
                                    { key: sensor.id },
                                    [
                                      _vm.selectedNames[sensor.index]
                                        ? _c(
                                            "b-field",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "component.thermal_map.logger_label",
                                                  { x: sensor.index + 1 }
                                                ),
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.selectedNames[
                                                        sensor.index
                                                      ]
                                                    )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "subtitle mt-5 mb-2 has-text-weight-bold",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.thermal_map.display_settings")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("h3", { staticClass: "subtitle mb-2" }, [
                        _vm._v(
                          _vm._s(_vm.$t("component.thermal_map.color_range"))
                        ),
                      ]),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("component.thermal_map.min_value"),
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.selectedMap.minValue))])]
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("component.thermal_map.max_value"),
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.selectedMap.maxValue))])]
                      ),
                      _c(
                        "div",
                        { staticClass: "is-flex is-flex-direction-column" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                type: "is-primary",
                                tag: "a",
                                href: _vm.link,
                                "icon-pack": "fa",
                                "icon-left": "file",
                                download: "Map",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("component.thermal_map.download"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }