import EventType from '../EventType';
import { Source } from './Source';
import { Company } from './Company';
import { CalibrationInclude, FullCalibration } from './Calibration';
const LOW_BATTERY_THRESHOLD = 20;
export class CompanyLogger {
    constructor() {
        this.availableTypes = new Array();
    }
    hasLowBattery() {
        if (this.battery !== null && this.battery <= LOW_BATTERY_THRESHOLD)
            return true;
        else
            return false;
    }
    isCalibrated() {
        return this.calibration != null && this.calibration?.calibratedAt > 0;
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => CompanyLogger.define(et));
        }
        else if (data != null) {
            return CompanyLogger.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let logger = new CompanyLogger();
        logger.companyLoggerId = data.CompanyLoggerId;
        logger.serialNumber = data.SerialNumber;
        logger.macAddress = data.MacAddress;
        logger.activatedAt = data.ActivatedAt;
        logger.deactivatedAt = data.DeactivatedAt;
        logger.lastStateChange = data.LastStateChange;
        logger.active = data.Active;
        logger.lastSeen = data.LastSeen;
        logger.isOnline = data.IsOnline;
        logger.battery = data.Battery;
        logger.loggerId = data.LoggerId;
        logger.serviceState = data.ServiceState;
        logger.calibration = CalibrationInclude.fromApi(data.Calibration);
        if (data.AvailableTypes)
            logger.availableTypes = EventType.fromApi(data.AvailableTypes);
        if (data.Company)
            logger.company = Company.fromApi(data.Company);
        if (data.Source)
            logger.source = Source.fromApi(data.Source);
        return logger;
    }
    static addSources(loggers, sources) {
        loggers.forEach((logger) => {
            let source = sources.find((x) => x.logger && x.logger.serialNumber == logger.serialNumber) || new Source();
            logger.source = source;
        });
        return loggers;
    }
    static sortBySource(loggers) {
        return loggers.sort(function (a, b) {
            let aId = a.source && a.source.name ? a.source.name.toLowerCase() : '';
            let bId = b.source && b.source.name ? b.source.name.toLowerCase() : '';
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
    static sortBySerialNumber(sources) {
        return sources.sort(function (a, b) {
            let aId = a ? a.serialNumber.toLowerCase() : '';
            let bId = b ? b.serialNumber.toLowerCase() : '';
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
}
export class CompanyLoggerFullCalibration extends CompanyLogger {
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => CompanyLoggerFullCalibration.define(et));
        }
        else if (data != null) {
            return CompanyLoggerFullCalibration.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let logger = new CompanyLoggerFullCalibration();
        logger.companyLoggerId = data.CompanyLoggerId;
        logger.serialNumber = data.SerialNumber;
        logger.macAddress = data.MacAddress;
        logger.activatedAt = data.ActivatedAt;
        logger.deactivatedAt = data.DeactivatedAt;
        logger.lastStateChange = data.LastStateChange;
        logger.active = data.Active;
        logger.isOnline = data.IsOnline;
        logger.battery = data.Battery;
        logger.lastSeen = data.LastSeen;
        logger.lastData = data.LastData;
        logger.loggerId = data.LoggerId;
        logger.serviceState = data.ServiceState;
        if (data.Calibration)
            logger.calibration = FullCalibration.fromApi(data.Calibration);
        if (data.AvailableTypes)
            logger.availableTypes = EventType.fromApi(data.AvailableTypes);
        if (data.Company)
            logger.company = Company.fromApi(data.Company);
        if (data.Source)
            logger.source = Source.fromApi(data.Source);
        return logger;
    }
}
