var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("admin.component.loggers.selectCompanyModal.title")
              ) +
              " (" +
              _vm._s(
                _vm.logger.currentlyPublishedAs.serialNumber &&
                  _vm.logger.currentlyPublishedAs
                  ? _vm.logger.currentlyPublishedAs.serialNumber
                  : _vm.logger.macAddress
              ) +
              ") "
          ),
        ]),
      ]),
      _c(
        "section",
        {
          staticClass: "modal-card-body",
          style: _vm.company !== null ? "" : "min-height: 30vh",
        },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.loggers.selectCompanyModal.company"
                ),
              },
            },
            [
              _c("b-autocomplete", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.loggers.selectCompanyModal.companyPlaceholder"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.detail.deviceInfo.register.company"
                  ),
                  data: _vm.filteredCompanyList,
                  field: "companyName",
                  "open-on-focus": true,
                  clearable: "",
                },
                on: { select: _vm.select },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            [
              _c(
                "b-switch",
                {
                  staticClass: "is-justify-content-flex-end",
                  model: {
                    value: _vm.sourceCreationEnabled,
                    callback: function ($$v) {
                      _vm.sourceCreationEnabled = $$v
                    },
                    expression: "sourceCreationEnabled",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.company.registerCompanyLoggersModal.createSources"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-message",
            { staticClass: "mb-4", attrs: { type: "is-warning" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "admin.component.company.registerCompanyLoggersModal.createSourcesMessage"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _vm.sourceCreationEnabled
            ? [
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t(
                        "admin.component.loggers.selectCompanyModal.name"
                      ),
                      type: {
                        "is-danger":
                          _vm.backupLogger.additionalProperties.hasErrorField ||
                          _vm.errors.has(
                            _vm.$t(
                              "admin.component.loggers.selectCompanyModal.name"
                            )
                          ),
                      },
                      message:
                        _vm.backupLogger.additionalProperties.errorMsg ||
                        _vm.errors.first(
                          _vm.$t(
                            "admin.component.loggers.selectCompanyModal.name"
                          )
                        ),
                    },
                  },
                  [
                    _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: _vm.$t(
                          "admin.component.loggers.selectCompanyModal.name"
                        ),
                        expanded: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.checkName()
                        },
                      },
                      model: {
                        value: _vm.backupLogger.additionalProperties.name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.backupLogger.additionalProperties,
                            "name",
                            $$v
                          )
                        },
                        expression: "backupLogger.additionalProperties.name",
                      },
                    }),
                  ],
                  1
                ),
                _c("span", { staticClass: "is-flex" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.fillNamesWithSerialNumbers()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.selectCompanyModal.defaultName_btn"
                          )
                        )
                      ),
                    ]
                  ),
                  _c("p", { staticClass: "ml-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "admin.component.loggers.selectCompanyModal.defaultName_msg"
                        )
                      )
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              attrs: { type: "is-primary", disabled: _vm.company == null },
              on: {
                click: function ($event) {
                  return _vm.registerLogger()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.save")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }