import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DecimalConversion from '@/services/DecimalConversion';
let BoundaryInfo = class BoundaryInfo extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
    }
    editBoundaries() {
        this.$emit('editBoundaries');
    }
};
__decorate([
    Prop({ type: Array })
], BoundaryInfo.prototype, "boundaries", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], BoundaryInfo.prototype, "editOption", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], BoundaryInfo.prototype, "hasWarningBoundaries", void 0);
BoundaryInfo = __decorate([
    Component({})
], BoundaryInfo);
export default BoundaryInfo;
