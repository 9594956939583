import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './lang/lang';
import AppConfig from '@/configLoader';
import VueGtag from 'vue-gtag';
import VueCookies from 'vue-cookies';
import Axios from 'axios';
import Buefy from 'buefy';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import AbilityManager from '@/services/permissions/AbilitiesManager';
import { abilitiesPlugin } from '@casl/vue';
import { Workbox } from 'workbox-window';
import { ConsoleInstrumentation, ConsoleTransport, ErrorsInstrumentation, FetchTransport, getWebInstrumentations, initializeFaro, LogLevel, SessionInstrumentation, ViewInstrumentation, WebVitalsInstrumentation } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(Buefy, {
    defaultIconPack: 'fas'
});
Vue.use(abilitiesPlugin, new AbilityManager([]).getAbilities());
Vue.prototype.$axios = Axios;
Vue.prototype.$permActions = Actions;
Vue.prototype.$permSubjects = Subjects;
Vue.prototype.$features = Features;
Vue.prototype.$featuresManager = FeaturesManager.getInstance(new Vue());
Vue.prototype.$departmentId = null;
export const globalStore = new Vue({
    data: {}
});
Vue.prototype.$globals = globalStore;
Vue.prototype.$eventBus = new Vue();
Vue.directive('visible', function (el, binding) {
    el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});
import Default from './views/layouts/Default.vue';
import WithSidebar from './views/layouts/WithSidebar.vue';
import LandingPage from './views/layouts/LandingPage.vue';
import WithAdminSidebar from './views/layouts/WithAdminSidebar.vue';
import Public from './views/layouts/Public.vue';
import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);
import VeeValidate from 'vee-validate';
//Leaflet map and icons importing
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
import Actions from './services/permissions/Actions';
import Subjects from './services/permissions/Subjects';
import { Features } from './entities/tiers/Features';
import { FeaturesManager } from './entities/tiers/FeaturesManager';
import VueUtilities from './services/VueUtilities';
import WithOrganizationSideBar from '@/views/layouts/WithOrganizationSideBar.vue';
const messagesLocale = {
    sk: require('@/lang/sk.json'),
    en: require('@/lang/en.json'),
    cs: require('@/lang/cs.json')
};
Vue.use(VeeValidate);
VeeValidate.Validator.localize({
    en: {
        messages: {
            password: messagesLocale.en.validation.pswdContains,
            deviceRegistrationKey: messagesLocale.en.validation.deviceRegKey,
            phoneE164: messagesLocale.en.validation.phoneE164
        }
    },
    sk: {
        messages: {
            password: messagesLocale.sk.validation.pswdContains,
            deviceRegistrationKey: messagesLocale.sk.validation.deviceRegKey,
            phoneE164: messagesLocale.sk.validation.phoneE164
        }
    },
    cs: {
        messages: {
            password: messagesLocale.cs.validation.pswdContains,
            deviceRegistrationKey: messagesLocale.cs.validation.deviceRegKey,
            phoneE164: messagesLocale.cs.validation.phoneE164
        }
    }
});
//PWA
//refresh app in all tabs
let refreshing = false;
// detect controller change and refresh the page
if (navigator.serviceWorker)
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!refreshing) {
            window.location.reload();
            refreshing = true;
        }
    });
if ('serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js');
    let registration;
    wb.addEventListener('activated', (event) => {
        VueUtilities.openSuccessToast(Vue.prototype, i18n.t('app_updated'));
        if (!event.isUpdate) {
            console.log('Service worker activated for the first time!');
        }
    });
    const showSkipWaitingPrompt = async (event) => {
        // Assuming the user accepted the update, set up a listener
        // that will reload the page as soon as the previously waiting
        // service worker has taken control.
        wb.addEventListener('controlling', () => {
            // At this point, reloading will ensure that the current
            // tab is loaded under the control of the new service worker.
            window.location.reload();
        });
        //Ask user if he want update the app
        Vue.prototype.$buefy.snackbar.open({
            message: i18n.t('update_prompt'),
            type: 'is-success',
            position: 'is-top',
            actionText: i18n.t('update_prompt_action'),
            indefinite: true,
            queue: false,
            onAction: () => {
                wb.messageSkipWaiting();
            }
        });
    };
    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', (event) => {
        showSkipWaitingPrompt(event);
    });
    // Register the service worker after event listeners have been added.
    wb.register().then((r) => (registration = r));
}
VeeValidate.Validator.extend('password', {
    validate: (value) => value.match(/[A-Z]/g) !== null && value.match(/[0-9]/g) !== null && value.match(/[a-z]/g) !== null
});
VeeValidate.Validator.extend('deviceRegistrationKey', {
    validate: (value) => value.match('^[0-9A-Z-]*$') != null
});
VeeValidate.Validator.extend('phoneE164', {
    validate: (value) => value.match(/^\+[1-9]\d{11}$/) != null
});
VeeValidate.Validator.extend('decimalNumber', {
    validate: (value) => value.match(/^-?(0|[1-9]\d*)(\,\d{1,2})?$/) != null
});
Vue.component('default-layout', Default);
Vue.component('with-sidebar-layout', WithSidebar);
Vue.component('landing-page-layout', LandingPage);
Vue.component('organization-layout', WithOrganizationSideBar);
Vue.component('admin-layout', WithAdminSidebar);
Vue.component('public-layout', Public);
AppConfig.loadConfig().then(() => {
    if (AppConfig.getConfig().google_analytics.allowed == true) {
        Vue.use(VueGtag, {
            config: { id: AppConfig.getConfig().google_analytics.id },
            bootstrap: false,
            enabled: false
        }, router);
    }
    if (AppConfig.getConfig().features.grafana_faro == true) {
        let transports = [];
        let instrumentations = [];
        if (AppConfig.getConfig().faro.transports.fetch) {
            transports.push(new FetchTransport({
                url: AppConfig.getConfig().faro.address
            }));
        }
        if (AppConfig.getConfig().faro.transports.console) {
            transports.push(new ConsoleTransport({
                level: LogLevel.DEBUG
            }));
        }
        if (AppConfig.getConfig().faro.instruments.allWeb) {
            instrumentations.push(...getWebInstrumentations());
        }
        if (AppConfig.getConfig().faro.instruments.console) {
            instrumentations.push(new ConsoleInstrumentation({
                disabledLevels: [LogLevel.LOG, LogLevel.DEBUG, LogLevel.INFO]
            }));
        }
        if (AppConfig.getConfig().faro.instruments.tracing) {
            instrumentations.push(new TracingInstrumentation({}));
        }
        if (AppConfig.getConfig().faro.instruments.session) {
            instrumentations.push(new SessionInstrumentation());
        }
        if (AppConfig.getConfig().faro.instruments.view) {
            instrumentations.push(new ViewInstrumentation());
        }
        if (AppConfig.getConfig().faro.instruments.errors) {
            instrumentations.push(new ErrorsInstrumentation());
        }
        if (AppConfig.getConfig().faro.instruments.webVitals) {
            instrumentations.push(new WebVitalsInstrumentation());
        }
        initializeFaro({
            app: {
                name: 'Rdas.LogHub.Frontend',
                version: VERSION,
                release: BRANCH
            },
            instrumentations: instrumentations,
            transports: transports
        });
    }
    const app = new Vue({
        router,
        i18n,
        render: (h) => h(App)
    });
    router.onReady(() => {
        app.$mount('#app');
    });
});
