"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class BoundarySetManager {
    constructor(boundarySets, colorGenerator, colorFilled, pointColor) {
        this._isColorFilled = false;
        this._boundarySets = boundarySets;
        this._isColorFilled = colorFilled;
        this._pointColor = pointColor != null ? pointColor : colorGenerator.getBoundaryPointColor();
        colorGenerator.addToPickedColors(this._pointColor);
    }
    get boundarySets() {
        return this._boundarySets;
    }
    findBoundarySetByService(serviceId) {
        return this._boundarySets.find(x => x.serviceId == serviceId);
    }
    findBoundariesByServiceAxis(serviceAxis) {
        let allBoundaries = this._boundarySets.find(x => x.serviceId == serviceAxis.serviceId).values;
        let axisDomain = serviceAxis.y.domain();
        return allBoundaries.filter(x => x >= axisDomain[0] && x <= axisDomain[1]);
    }
    getWeightForServiceValue(serviceId, value) {
        let boundarySet = this.findBoundarySetByService(serviceId);
        if (!boundarySet || !boundarySet.weights || boundarySet.weights.length < 1)
            return null;
        else {
            let higherBounds = boundarySet.values.filter(x => x > value);
            let lowestBoundForValue = Math.min(...higherBounds);
            let boundIndex = boundarySet.values.indexOf(lowestBoundForValue);
            let weigthIndex = boundIndex >= 0 ? boundIndex : boundarySet.values.length;
            let weight = boundarySet.weights[weigthIndex];
            return weight;
        }
    }
    get isColorFilled() {
        return this._isColorFilled;
    }
    get pointColor() {
        return this._pointColor;
    }
}
exports.default = BoundarySetManager;
