import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ChartBuilder, LegendOrientation, LabelPosition } from 'd3Chart';
import { ChartServices } from '@/entities/enums/ChartServices';
import UserPreferences from '@/services/UserPreferences';
import { AdminLoggerChartDataConverter } from '@/services/chart-data-conversion/AdminLoggerChartDataConverter';
import { ChartComponents } from '@/entities/enums/ChartComponents';
let AdminLoggerChart = class AdminLoggerChart extends Vue {
    constructor() {
        super(...arguments);
        this.ChartServices = ChartServices;
        this.selectedChartService = ChartServices.TEMPERATURE;
        this.chartId = 'adminLoggerChart';
        this.hasDataForTimerange = false;
        this.isScaleByData = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IsScaleByData);
        this.availableChartLogger = [];
        this.isLoggersFilterApplied = false;
        this.selectedFromDate = new Date();
        this.maxDate = new Date();
        this.minDate = new Date();
        this.chartServices = [];
    }
    onLoading() {
        if (!this.componentIsLoading) {
            this.loadChartServices();
            this.createChart();
        }
        else
            this.destroyChart();
    }
    created() {
        this.selectedFromDate = this.initialFromDate;
        this.minDate.setDate(this.maxDate.getDate() - 30);
    }
    beforeDestroy() {
        this.destroyChart();
    }
    selectedFromDateChange(date) {
        this.selectedFromDate = date;
        this.$emit('selectedFromDateChange', date);
    }
    async createChart() {
        let dataConverter = new AdminLoggerChartDataConverter(this.chartData, this.logger);
        let rightMargin = dataConverter.services.length > 1 ? 40 : 15;
        let settings = {
            fullHeight: 300,
            previewChartHeight: 70,
            gapBetweenCharts: 20,
            margin: {
                left: 40,
                right: rightMargin
            },
            marginPreview: {
                left: 40,
                right: rightMargin
            }
        };
        let dateRange = {
            startTS: this.selectedFromDate.getTime(),
            stopTS: new Date().getTime()
        };
        if (dataConverter.hasData) {
            this.availableChartLogger = dataConverter.data;
            let availableChartLogger = this.availableChartLogger.filter((x, i, a) => a.findIndex((x2) => x.name === x2.name) === i);
            this.availableChartLogger = availableChartLogger;
            this.hasDataForTimerange = true;
            this.chartEntity = new ChartBuilder(this.chartId, settings, dataConverter.services, dataConverter.data)
                .makeInteractive()
                .setDateFormatter(await dataConverter.getTimeFormatter(this))
                .setChartDateFormatter(await dataConverter.getChartTimeFormatter(this))
                .setOnlyTimeFormatter(await dataConverter.getOnlyTimeFormatter(this))
                .setOnlyDateFormatter(await dataConverter.getOnlyDateFormatter(this))
                .addPreviewChart()
                .setLabelPosition(LabelPosition.UP)
                .addLegend(LegendOrientation.ROW)
                .addGridlines(true, true)
                .addZoomButtons()
                .setDateRange(dateRange)
                .addServiceButtonsHandling()
                .addLegendsFilter()
                .setFontSize('12px')
                .build();
        }
        else {
            this.hasDataForTimerange = false;
        }
        this.$emit('eventHandler', this.hasDataForTimerange, this.chartEntity, ChartComponents.ADMINLOGGERCHART);
    }
    loadChartServices() {
        this.chartServices = [];
        this.eventTypes.forEach((event) => {
            if (event.name.toLowerCase() == ChartServices.HUMIDITY ||
                event.name.toLowerCase() == ChartServices.PRESSURE ||
                event.name.toLowerCase() == ChartServices.TEMPERATURE ||
                event.name.toLowerCase() == ChartServices.UPTIME) {
                this.chartServices.push(event.name.toLowerCase());
            }
        });
        if (this.chartServices.length > 1)
            this.chartServices.unshift(ChartServices.ALL);
    }
    selectChartServices(item) {
        let tempServices = document.getElementById(ChartServices.TEMPERATURE);
        let humidityServices = document.getElementById(ChartServices.HUMIDITY);
        let pressureServices = document.getElementById(ChartServices.PRESSURE);
        let uptimeServices = document.getElementById(ChartServices.UPTIME);
        let allServices = document.getElementById(ChartServices.ALL);
        //Changing style for sevices selection tab
        if (tempServices)
            tempServices.classList.remove('is-active');
        if (humidityServices)
            humidityServices.classList.remove('is-active');
        if (pressureServices)
            pressureServices.classList.remove('is-active');
        if (uptimeServices)
            uptimeServices.classList.remove('is-active');
        if (allServices)
            allServices.classList.remove('is-active');
        switch (item) {
            case ChartServices.TEMPERATURE:
                tempServices.classList.add('is-active');
                break;
            case ChartServices.HUMIDITY:
                humidityServices.classList.add('is-active');
                break;
            case ChartServices.PRESSURE:
                pressureServices.classList.add('is-active');
                break;
            case ChartServices.UPTIME:
                uptimeServices.classList.add('is-active');
                break;
            default:
                allServices.classList.add('is-active');
                break;
        }
        this.selectedChartService = item;
    }
    destroyChart() {
        if (this.chartEntity)
            this.chartEntity.destroyChart();
    }
};
__decorate([
    Prop({ type: Object })
], AdminLoggerChart.prototype, "chartData", void 0);
__decorate([
    Prop({ type: Object })
], AdminLoggerChart.prototype, "logger", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminLoggerChart.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: Object })
], AdminLoggerChart.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Date })
], AdminLoggerChart.prototype, "initialFromDate", void 0);
__decorate([
    Prop({ type: Array })
], AdminLoggerChart.prototype, "eventTypes", void 0);
__decorate([
    Watch('componentIsLoading')
], AdminLoggerChart.prototype, "onLoading", null);
AdminLoggerChart = __decorate([
    Component({ components: {} })
], AdminLoggerChart);
export default AdminLoggerChart;
