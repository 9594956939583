var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "section-header-level my-1 mx-3 mx-0-desktop" },
        [
          _c(
            "h1",
            {
              staticClass: "title is-3 is-spaced has-text-centered-touch my-1",
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("admin.component.loggers.title")) + " "
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "is-flex is-align-items-center is-hidden-mobile" },
            [
              _c(
                "router-link",
                {
                  staticClass: "button is-primary level-item is-outlined mr-2",
                  attrs: {
                    to: {
                      name: "adminRegisterNewLoggers",
                      params: {
                        lang: _vm.$route.params.lang,
                      },
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("admin.component.loggers.registerNewLogger_btn")
                    ) + " "
                  ),
                ]
              ),
              _vm.manufacturingProcessTurnedOn
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "button is-primary level-item is-outlined mr-2",
                      attrs: {
                        to: {
                          name: "adminManufactureLoggers",
                          params: {
                            lang: _vm.$route.params.lang,
                          },
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.logger_manufacturing.title"
                          )
                        ) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.$ability.can(
                _vm.$permActions.PUBLISH,
                _vm.$permSubjects.ADMIN_LOGGERS
              )
                ? _c(
                    "b-button",
                    {
                      staticClass: "level-item mr-2",
                      attrs: { outlined: "", type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.showCvsModal()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("admin.component.loggers.csvButton"))
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.$ability.can(
                _vm.$permActions.CREATE,
                _vm.$permSubjects.ADMIN_LOGGERS
              )
                ? _c(
                    "b-button",
                    {
                      staticClass: "level-item",
                      attrs: { outlined: "", type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.showCreateModal()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("admin.component.loggers.createLogger_btn")
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              staticClass: "is-hidden-tablet",
              attrs: {
                position: "is-bottom-left",
                "append-to-body": "",
                "aria-role": "list",
              },
              scopedSlots: _vm._u([
                {
                  key: "trigger",
                  fn: function () {
                    return [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.devices.detail.actions.title"
                            ),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            { staticStyle: { "font-size": "1rem" } },
                            [
                              _c("b-icon", {
                                attrs: {
                                  type: "is-primary",
                                  icon: "ellipsis-v",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.$ability.can(
                _vm.$permActions.CREATE,
                _vm.$permSubjects.ADMIN_USERS
              )
                ? _c(
                    "b-dropdown-item",
                    {
                      staticClass: "custom-dropdown-item",
                      attrs: { "aria-role": "listitem" },
                      on: {
                        click: function ($event) {
                          return _vm.registerNewLogger()
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "plus" } }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "admin.component.loggers.registerNewLogger_btn"
                            )
                          ) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$ability.can(
                _vm.$permActions.CREATE,
                _vm.$permSubjects.ADMIN_USERS
              )
                ? _c(
                    "b-dropdown-item",
                    {
                      staticClass: "custom-dropdown-item",
                      attrs: { "aria-role": "listitem" },
                      on: {
                        click: function ($event) {
                          return _vm.showCvsModal()
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "cog" } }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("admin.component.loggers.csvButton")) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$ability.can(
                _vm.$permActions.MAILINGLIST,
                _vm.$permSubjects.ADMIN_USERS
              )
                ? _c(
                    "b-dropdown-item",
                    {
                      staticClass: "custom-dropdown-item",
                      attrs: { "aria-role": "listitem" },
                      on: {
                        click: function ($event) {
                          return _vm.showCreateModal()
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "plus" } }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("admin.component.loggers.createLogger_btn")
                          ) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tile is-ancestor is-vertical mt-2" }, [
        !_vm.isLoading &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.ADMIN_LOGGERS)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminLoggerList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    adminLoggers: _vm.adminLoggers,
                    dateTimeManager: _vm.dateTimeManager,
                    totalSize: _vm.adminLoggers.getPagination().total,
                  },
                  on: {
                    onOptionsChange: _vm.onOptionsChange,
                    loadLoggers: _vm.loadLoggers,
                    changeRouterUrlFromLogger: _vm.changeRouterUrlFromLogger,
                    createVerificationKey: _vm.createVerificationKey,
                    deleteVerificationKey: _vm.deleteVerificationKey,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.isLoading &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.ADMIN_LOGGERS)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminLoggersCalibrationExpires", {
                  staticClass: "box tile is-child",
                  attrs: {
                    adminLoggers: _vm.loggersCalibrationExpires,
                    totalSize:
                      _vm.loggersCalibrationExpires.getPagination().total,
                    dateTimeManager: _vm.dateTimeManager,
                    currentDateTime: _vm.currentDateTime,
                    calibrationExpire: _vm.selectedCalibrationExpire,
                  },
                  on: {
                    onOptionsChangeLoggersCalibrationExpires:
                      _vm.onOptionsChangeLoggersCalibrationExpires,
                    loadLoggersCalibrationExpires:
                      _vm.loadLoggersCalibrationExpires,
                    changeRouterUrlFromCalibrationExpires:
                      _vm.changeRouterUrlFromCalibrationExpires,
                    createVerificationKey: _vm.createVerificationKey,
                    deleteVerificationKey: _vm.deleteVerificationKey,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCsvModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCsvModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [_c("GenerateStickerModal", { on: { modalClosed: _vm.closeModal } })],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCreateModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeCreateLoggerModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCreateModalActive = $event
            },
            "close-modal": _vm.closeCreateLoggerModal,
          },
        },
        [
          _c("CreateLoggerModal", {
            on: { modalClosed: _vm.closeCreateLoggerModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }