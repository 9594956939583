var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card logger-modal-header" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.report.detail_table.report_export.csv_export.title"
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-tabs",
            {
              staticClass: "mb-1",
              attrs: { type: "is-boxed" },
              model: {
                value: _vm.selectedMode,
                callback: function ($$v) {
                  _vm.selectedMode = $$v
                },
                expression: "selectedMode",
              },
            },
            [
              _c("b-tab-item", {
                attrs: {
                  label: _vm.$t("reports.report_mode.default"),
                  value: _vm.CsvReportTypes.FULL,
                },
              }),
              _c("b-tab-item", {
                attrs: {
                  label: _vm.$t("reports.report_mode.raw-data"),
                  value: _vm.CsvReportTypes.RAW,
                },
              }),
            ],
            1
          ),
          _vm.selectedMode === _vm.CsvReportTypes.RAW
            ? _c("b-message", { attrs: { type: "is-info", "has-icon": "" } }, [
                _vm._v(" " + _vm._s(_vm.$t("reports.raw_data_note")) + " "),
              ])
            : _vm._e(),
          _c(
            "b-field",
            {
              staticClass: "content-full-width",
              attrs: {
                grouped: "",
                label: _vm.$t(
                  "component.report.detail_table.report_export.dateRange"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "component.report.detail_table.report_export.dateRange"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "component.report.detail_table.report_export.dateRange"
                  )
                ),
              },
            },
            [
              _vm.isMonth
                ? _c("b-datepicker", {
                    attrs: {
                      type: "month",
                      "mobile-native": false,
                      position: "is-bottom-left",
                      placeholder: _vm.$t(
                        "component.report.month_picker.placeholder"
                      ),
                      "icon-pack": "fa",
                      icon: "calendar-alt",
                      "max-date": _vm.today,
                      "date-formatter": (date) =>
                        _vm.dateTimeManager
                          ? _vm.dateTimeManager.formatMonthNameInInput(date)
                          : null,
                      "month-names": _vm.dateTimeManager
                        ? _vm.dateTimeManager.getMonthNames()
                        : [],
                    },
                    on: { input: _vm.dateMonthChanged },
                    model: {
                      value: _vm.currentlySelectedMonth,
                      callback: function ($$v) {
                        _vm.currentlySelectedMonth = $$v
                      },
                      expression: "currentlySelectedMonth",
                    },
                  })
                : _c(
                    "div",
                    { staticClass: "column p-0" },
                    [
                      _c(
                        "b-field",
                        {
                          staticClass: "mr-2 mb-2",
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.from"
                            ),
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t(
                                  "component.report.configuration.modal.from"
                                )
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t(
                                "component.report.configuration.modal.from"
                              )
                            ),
                            "label-position": "on-border",
                          },
                        },
                        [
                          _c("b-datetimepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              name: _vm.$t(
                                "component.report.configuration.modal.from"
                              ),
                              placeholder: _vm.$t(
                                "component.report.month_picker.placeholder"
                              ),
                              "icon-right": _vm.dateTimeFrom
                                ? "close-circle"
                                : "",
                              "icon-right-clickable": "",
                              "first-day-of-week": 1,
                              "mobile-native": false,
                              timepicker: { hourFormat: _vm.hourFormat },
                              "datetime-formatter": (date) =>
                                _vm.dateTimeManager.formatDateTimeInInput(date),
                              expanded: "",
                            },
                            on: { "icon-right-click": _vm.clearDateTimeFrom },
                            scopedSlots: _vm._u(
                              [
                                _vm.selectedMode === _vm.CsvReportTypes.RAW
                                  ? {
                                      key: "left",
                                      fn: function () {
                                        return [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "has-text-danger is-italic",
                                              staticStyle: {
                                                "word-break": "break-all",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "component.report.configuration.modal.range_note",
                                                      { days: _vm.reportRange }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.dateTimeFrom,
                              callback: function ($$v) {
                                _vm.dateTimeFrom = $$v
                              },
                              expression: "dateTimeFrom",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          staticClass: "mr-2 mt-2",
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.to"
                            ),
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t(
                                  "component.report.configuration.modal.to"
                                )
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t("component.report.configuration.modal.to")
                            ),
                            "label-position": "on-border",
                          },
                        },
                        [
                          _c("b-datetimepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              name: _vm.$t(
                                "component.report.configuration.modal.to"
                              ),
                              placeholder: _vm.$t(
                                "component.report.month_picker.placeholder"
                              ),
                              "icon-right": _vm.dateTimeTo
                                ? "close-circle"
                                : "",
                              "icon-right-clickable": "",
                              "first-day-of-week": 1,
                              "mobile-native": false,
                              timepicker: { hourFormat: _vm.hourFormat },
                              "datetime-formatter": (date) =>
                                _vm.dateTimeManager.formatDateTimeInInput(date),
                              expanded: "",
                            },
                            on: { "icon-right-click": _vm.clearDateTimeTo },
                            model: {
                              value: _vm.dateTimeTo,
                              callback: function ($$v) {
                                _vm.dateTimeTo = $$v
                              },
                              expression: "dateTimeTo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "b-select",
                {
                  attrs: {
                    placeholder: _vm.$t(
                      "component.report.detail_table.report_export.dateRange"
                    ),
                  },
                  on: { input: _vm.selectCalType },
                  model: {
                    value: _vm.selectedValue,
                    callback: function ($$v) {
                      _vm.selectedValue = $$v
                    },
                    expression: "selectedValue",
                  },
                },
                [
                  _vm.selectedMode !== _vm.CsvReportTypes.RAW
                    ? _c(
                        "option",
                        { domProps: { value: _vm.DateRangeType.MONTH } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `component.report.detail_table.report_export.month`
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "option",
                    { domProps: { value: _vm.DateRangeType.RANGE } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `component.report.detail_table.report_export.dateRange`
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.report.detail_table.report_export.for_entity"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "component.report.detail_table.report_export.for_entity"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "component.report.detail_table.report_export.for_entity"
                  )
                ),
              },
            },
            _vm._l(_vm.CsvScope, function (el) {
              return _c(
                "b-radio",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  key: el,
                  attrs: {
                    name: _vm.$t(
                      "component.report.detail_table.report_export.for_entity"
                    ),
                    "native-value": el,
                  },
                  model: {
                    value: _vm.selectedEntity,
                    callback: function ($$v) {
                      _vm.selectedEntity = $$v
                    },
                    expression: "selectedEntity",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `component.report.detail_table.report_export.entities.${el}`
                        )
                      ) +
                      " "
                  ),
                ]
              )
            }),
            1
          ),
          _vm.selectedEntity
            ? _c(
                "b-field",
                [
                  _vm.selectedEntity === _vm.CsvScope.LOGGER
                    ? _c(
                        "b-table",
                        {
                          staticClass:
                            "select-location-table border is-full-width",
                          attrs: {
                            data: _vm.sources,
                            "checked-rows": _vm.selectedSources,
                            paginated: true,
                            "per-page": 5,
                            narrowed: "",
                            checkable: "",
                            "mobile-cards": false,
                          },
                          on: {
                            "update:checkedRows": function ($event) {
                              _vm.selectedSources = $event
                            },
                            "update:checked-rows": function ($event) {
                              _vm.selectedSources = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "bottom-left",
                                fn: function () {
                                  return [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.notification.create_rule.entity_table.selected_count"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(
                                      " : " +
                                        _vm._s(_vm.selectedSources.length) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2007173811
                          ),
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              field: "name",
                              label: _vm.$t(
                                "component.notification.create_rule.entity_table.name"
                              ),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (sources) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(sources.row.name)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2608515585
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm.selectedEntity === _vm.CsvScope.GROUP
                    ? _c(
                        "b-table",
                        {
                          staticClass:
                            "select-location-table border is-full-width",
                          attrs: {
                            data: _vm.sourceGroups,
                            "checked-rows": _vm.selectedSourceGroups,
                            paginated: true,
                            "per-page": 5,
                            narrowed: "",
                            checkable: "",
                            "mobile-cards": false,
                          },
                          on: {
                            "update:checkedRows": function ($event) {
                              _vm.selectedSourceGroups = $event
                            },
                            "update:checked-rows": function ($event) {
                              _vm.selectedSourceGroups = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "bottom-left",
                                fn: function () {
                                  return [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.notification.create_rule.entity_table.selected_count"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.selectedSourceGroups.length
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4293191692
                          ),
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              field: "name",
                              label: _vm.$t(
                                "component.notification.create_rule.entity_table.name"
                              ),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (groups) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(groups.row.name)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2249423201
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode !== _vm.CsvReportTypes.RAW
            ? _c(
                "b-field",
                {
                  attrs: {
                    grouped: "",
                    label: _vm.$t(
                      "component.report.configuration.modal.reportingInterval"
                    ),
                  },
                },
                [
                  _c(
                    "b-select",
                    {
                      on: { input: _vm.reportingIntervalChanged },
                      model: {
                        value: _vm.interval,
                        callback: function ($$v) {
                          _vm.interval = $$v
                        },
                        expression: "interval",
                      },
                    },
                    _vm._l(_vm.allReportingIntervals, function (interval) {
                      return _c(
                        "option",
                        { key: interval[0], domProps: { value: interval[0] } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `component.report.reportingIntervals.${interval[1]}`
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode !== _vm.CsvReportTypes.RAW
            ? _c(
                "b-field",
                {
                  staticClass: "border",
                  attrs: { grouped: "", "group-multiline": "" },
                },
                [
                  _c(
                    "b-field",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-radio",
                        {
                          attrs: { "native-value": _vm.StatisticsType.AVERAGE },
                          on: { input: _vm.typeChanged },
                          model: {
                            value: _vm.selectedType,
                            callback: function ($$v) {
                              _vm.selectedType = $$v
                            },
                            expression: "selectedType",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.configuration.modal.averageValues"
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "b-tooltip",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                type: "is-dark",
                                position: "is-bottom",
                                label: _vm.$t(
                                  "component.report.configuration.modal.average_note"
                                ),
                                multilined: "",
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "has-text-grey",
                                attrs: { icon: "question-circle" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-radio",
                        {
                          attrs: { "native-value": _vm.StatisticsType.DAILY },
                          on: { input: _vm.typeChanged },
                          model: {
                            value: _vm.selectedType,
                            callback: function ($$v) {
                              _vm.selectedType = $$v
                            },
                            expression: "selectedType",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.configuration.modal.measuredValues"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  !_vm.hasOneDayIntervalSelected
                    ? _c(
                        "b-field",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.measuredAt"
                            ),
                          },
                        },
                        [
                          _vm.canHaveOffset
                            ? _c(
                                "b-select",
                                {
                                  staticStyle: { "max-width": "125px" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.measuredOffsetChanged()
                                    },
                                  },
                                  model: {
                                    value: _vm.measurementOffsetIndex,
                                    callback: function ($$v) {
                                      _vm.measurementOffsetIndex = $$v
                                    },
                                    expression: "measurementOffsetIndex",
                                  },
                                },
                                _vm._l(
                                  _vm.allMeasuredTimes,
                                  function (time, index) {
                                    return _c(
                                      "option",
                                      { key: time, domProps: { value: index } },
                                      [_vm._v(" " + _vm._s(time) + " ")]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("b-select", { attrs: { disabled: "" } }, [
                                _c("option", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.report.configuration.modal.measuredAt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                        ],
                        1
                      )
                    : _c(
                        "b-field",
                        [
                          _vm.canHaveOffset
                            ? _c("b-clockpicker", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "placeholders.click_select"
                                  ),
                                  icon: "clock",
                                  "hour-format": _vm.hourFormatInClockPicker,
                                  "hours-label": _vm.$t("placeholders.hours"),
                                  "minutes-label": _vm.$t(
                                    "placeholders.minutes"
                                  ),
                                  editable: "",
                                  position: "is-top-left",
                                },
                                on: { input: _vm.clockChanged },
                                model: {
                                  value: _vm.timeOfMeasurement,
                                  callback: function ($$v) {
                                    _vm.timeOfMeasurement = $$v
                                  },
                                  expression: "timeOfMeasurement",
                                },
                              })
                            : _c("b-clockpicker", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "placeholders.click_select"
                                  ),
                                  icon: "clock",
                                },
                                on: { input: _vm.clockChanged },
                              }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.report.detail_table.report_export.eventTypes"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "component.report.detail_table.report_export.eventTypes"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "component.report.detail_table.report_export.eventTypes"
                  )
                ),
              },
            },
            [
              _vm.hasTemp !== -1
                ? _c(
                    "b-checkbox",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "mb-2",
                      attrs: {
                        name: _vm.$t(
                          "component.report.configuration.modal.eventTypes"
                        ),
                        "native-value": "temperature",
                      },
                      model: {
                        value: _vm.selectedEventTypes,
                        callback: function ($$v) {
                          _vm.selectedEventTypes = $$v
                        },
                        expression: "selectedEventTypes",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("services.temperature")) + " ")]
                  )
                : _vm._e(),
              _vm.hasHum !== -1
                ? _c(
                    "b-checkbox",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "mb-2",
                      attrs: {
                        name: _vm.$t(
                          "component.report.configuration.modal.eventTypes"
                        ),
                        "native-value": "humidity",
                      },
                      model: {
                        value: _vm.selectedEventTypes,
                        callback: function ($$v) {
                          _vm.selectedEventTypes = $$v
                        },
                        expression: "selectedEventTypes",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("services.humidity")) + " ")]
                  )
                : _vm._e(),
              _vm.hasPress !== -1
                ? _c(
                    "b-checkbox",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "mb-2",
                      attrs: {
                        name: _vm.$t(
                          "component.report.configuration.modal.eventTypes"
                        ),
                        "native-value": "pressure",
                      },
                      model: {
                        value: _vm.selectedEventTypes,
                        callback: function ($$v) {
                          _vm.selectedEventTypes = $$v
                        },
                        expression: "selectedEventTypes",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("services.pressure")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-field",
            {
              staticClass: "flex-space-between",
              attrs: {
                label: _vm.$t(
                  "component.report.configuration.modal.show_empty_records"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "component.report.configuration.modal.show_empty_records"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "component.report.configuration.modal.show_empty_records"
                  )
                ),
              },
            },
            [
              _c("b-switch", {
                attrs: {
                  name: _vm.$t(
                    "component.report.configuration.modal.show_empty_records"
                  ),
                },
                model: {
                  value: _vm.currentShowEmpty,
                  callback: function ($$v) {
                    _vm.currentShowEmpty = $$v
                  },
                  expression: "currentShowEmpty",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("footer", { staticClass: "modal-card-foot is-block" }, [
        _c("div", { staticClass: "level is-mobile" }, [
          _c(
            "div",
            { staticClass: "level-left" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item",
                  on: {
                    click: function ($event) {
                      return _vm.resultRetrieved(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.close")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "level-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item",
                  attrs: { type: "is-primary" },
                  on: { click: _vm.exportCsv },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "component.report.detail_table.report_export.csv_export.confirm"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }