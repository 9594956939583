import { AdminDepartmentSettings } from '../AdminDepartmentSettings';
import User from '../User';
import { AdminMember } from './AdminMember';
import { AdminReseller } from './AdminReseller';
import { DepartmentArray } from './arrayModels/DepartmentArray';
import { Department } from './Department';
export class AdminDepartment {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminDepartment.define(et));
        }
        else if (data != null) {
            return AdminDepartment.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let company = new AdminDepartment();
        company.companyId = data.CompanyId;
        company.companyName = data.CompanyName;
        company.ico = data.Ico;
        company.note = data.Note;
        company.publicKey = data.PublicKey;
        company.createdAt = data.CreatedAt;
        company.owner = User.fromApi(data.Owner);
        company.loggerCount = data.LoggerCount;
        company.deviceCount = data.DeviceCount;
        company.userCount = data.UserCount;
        company.tier = data.Tier;
        company.settings = AdminDepartmentSettings.fromApi(data.Settings);
        company.isDeleted = data.IsDeleted;
        if (data.Tag) {
            company.tag = data.Tag;
        }
        if (company.tier) {
            company.tierName = data.Tier.Tier;
        }
        if (data.ResponsiblePerson) {
            company.responsiblePerson = AdminMember.fromApi(data.ResponsiblePerson);
        }
        if (data.Seller) {
            company.seller = AdminReseller.fromApi(data.Seller);
        }
        if (data.Issues) {
            company.issuesSummary = company.remapIssueSummary(data.Issues);
        }
        return company;
    }
    remapIssueSummary(issues) {
        return issues.map((issue) => ({
            type: issue.Type,
            alarmLevel: issue.AlarmLevel,
            notAcknowledged: issue.NumOfNotAcknowledged,
            notResolved: issue.NumOfUnresolved
        }));
    }
    static sortByName(companies) {
        return companies.sort(function (a, b) {
            let aId = a ? a.companyName.toLowerCase() : '';
            let bId = b ? b.companyName.toLowerCase() : '';
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
    static convertToUserDepartments(adminDepartments) {
        let userDepartments = [];
        adminDepartments.forEach((adminDepartment) => {
            userDepartments.push(AdminDepartment.convertToUserDepartment(adminDepartment));
        });
        return userDepartments;
    }
    static convertToUserDepartment(adminDepartment) {
        let department = new Department();
        department.id = adminDepartment.companyId;
        department.name = adminDepartment.companyName;
        department.createdAt = adminDepartment.createdAt;
        return department;
    }
    static convertToDepartmentsArray(adminDepartments) {
        let userDepartments = [];
        adminDepartments.forEach((adminDepartment) => {
            let department = new Department();
            department.id = adminDepartment.companyId;
            department.name = adminDepartment.companyName;
            department.createdAt = adminDepartment.createdAt;
            department.issuesSummary = adminDepartment.issuesSummary;
            userDepartments.push(department);
        });
        return new DepartmentArray(userDepartments);
    }
}
