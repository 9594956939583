import User from '@/entities/User';
import { IssueType } from '../enums/IssueType';
export default class DepartmentIssue {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => DepartmentIssue.define(et));
        }
        else if (data != null) {
            return DepartmentIssue.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let issue = new DepartmentIssue();
        issue.id = data.Id;
        issue.createdAt = data.CreatedAt;
        issue.startedAt = data.StartedAt;
        issue.updatedAt = data.UpdatedAt;
        issue.acknowledgedAt = data.AcknowledgedAt;
        issue.resolvedAt = data.ResolvedAt;
        if (data.AcknowledgedBy)
            issue.acknowledgedBy = User.fromApi(data.AcknowledgedBy);
        issue.type = data.Type;
        issue.metadata = data.Metadata;
        return issue;
    }
}
export class DepartmentIssueSummary {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => DepartmentIssueSummary.define(et));
        }
        else if (data != null) {
            return DepartmentIssueSummary.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let issueSummary = new DepartmentIssueSummary();
        issueSummary.id = data.DepartmentId;
        issueSummary.issues = data.Issues.map((issue) => ({
            type: issue.Type,
            alarmLevel: issue.AlarmLevel,
            notAcknowledged: issue.NumOfNotAcknowledged,
            notResolved: issue.NumOfUnresolved
        }));
        return issueSummary;
    }
}
export function isDepAcknowledged(departmentSummary) {
    if (departmentSummary) {
        return departmentSummary.every((issue) => issue.notAcknowledged === 0);
    }
}
export function fineState(departmentSummary) {
    let issueTypes = [
        { type: IssueType.ALARM, alarmLevel: 'Alarm' },
        { type: IssueType.ALARM, alarmLevel: 'Warning' },
        { type: IssueType.LOW_BATTERY, alarmLevel: null },
        { type: IssueType.DEVICE_OFFLINE, alarmLevel: null },
        { type: IssueType.SOURCE_OFFLINE, alarmLevel: null },
        { type: IssueType.MAINTENANCE, alarmLevel: null }
    ];
    for (let issue of issueTypes) {
        if (getIssueByType(issue.type, issue.alarmLevel, departmentSummary)) {
            return true;
        }
    }
    return false;
}
export function getIssueByType(type, alarmLevel = null, departmentSummary) {
    let totalNotAcknowledged = 0;
    let totalNotResolved = 0;
    if (departmentSummary) {
        departmentSummary.forEach((issue) => {
            if (issue.type === type && issue.alarmLevel === alarmLevel) {
                totalNotAcknowledged += issue.notAcknowledged;
                totalNotResolved += issue.notResolved;
            }
        });
    }
    else
        return false;
    return totalNotResolved >= 1;
}
