class CookiesStorage {
    static storeData(context, key, value, expires) {
        return context.$cookies.set(key, value, expires);
    }
    static loadData(context, key) {
        return context.$cookies.get(key);
    }
    static removeData(context, key) {
        return context.$cookies.remove(key);
    }
    static exists(cookies, key) {
        return cookies.isKey(key);
    }
}
CookiesStorage.keys = {
    USERNAME: 'username',
    SESSION_PASSWORD: 'sessionPassword',
    API_USER_FULL_NAME: 'apiUserFullName',
    API_USER_ID: 'apiUserId',
    API_USER_ROLE: 'apiUserRole',
    ADMIN_USERNAME: 'admin_username',
    ADMIN_SESSION_PASSWORD: 'admin_sessionPassword',
    ADMIN_API_USER_FULL_NAME: 'admin_apiUserFullName',
    ADMIN_PERMISSIONS: 'admin_permissions',
    IN_COMPANY: 'in_company'
};
export default CookiesStorage;
