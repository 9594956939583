var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("admin.component.loggers.deviceConfigurationModal.title")
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          !_vm.isDeviceConfigVisible
            ? [
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t(
                        "admin.component.loggers.deviceConfigurationModal.selectDevice"
                      ),
                    },
                  },
                  [
                    _c(
                      "b-table",
                      {
                        staticClass:
                          "select-location-table border select-user-table",
                        attrs: {
                          data: _vm.adminDevices,
                          selected: _vm.selectedAdminDevice,
                          paginated: true,
                          "backend-pagination": "",
                          "current-page": _vm.page,
                          loading: _vm.componentIsLoading,
                          "per-page": _vm.pageSize,
                          total: _vm.totalSize,
                          narrowed: "",
                          "mobile-cards": false,
                          "backend-filtering": "",
                          focusable: "",
                        },
                        on: {
                          "update:selected": function ($event) {
                            _vm.selectedAdminDevice = $event
                          },
                          "update:currentPage": function ($event) {
                            _vm.page = $event
                          },
                          "update:current-page": function ($event) {
                            _vm.page = $event
                          },
                          "page-change": _vm.onPageChange,
                          select: _vm.selectDevice,
                        },
                      },
                      [
                        _c("b-table-column", {
                          attrs: {
                            field: "SerialName",
                            label: _vm.$t(
                              "admin.component.company.registerCompanyDevicesModal.table.serialName"
                            ),
                            searchable: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "searchable",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "buttons mb-1" },
                                      [
                                        _c("b-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              _vm.$t("generals.search"),
                                            size: "is-small",
                                          },
                                          on: {
                                            input:
                                              _vm.handleFilterSerialNameDebounce,
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.filterDevices(
                                                _vm.serialNameSearch
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.serialNameSearch,
                                            callback: function ($$v) {
                                              _vm.serialNameSearch =
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                            },
                                            expression: "serialNameSearch",
                                          },
                                        }),
                                        _c("b-icon", {
                                          staticClass: "level-item clickable",
                                          attrs: { icon: "search" },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.filterDevices(
                                                _vm.serialNameSearch
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function (adminDevice) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(adminDevice.row.deviceName) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            342659643
                          ),
                        }),
                        _c("b-table-column", {
                          attrs: {
                            field: "Company.CompanyName",
                            label: _vm.$t(
                              "admin.component.company.registerCompanyDevicesModal.table.companyName"
                            ),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (adminDevice) {
                                  return [
                                    _c("p", [
                                      adminDevice.row.company &&
                                      adminDevice.row.company.companyName
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  adminDevice.row.company
                                                    .companyName
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2625912124
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c("div", { staticClass: "mb-2" }, [
                  _c("a", { on: { click: _vm.back } }, [
                    _vm._m(0),
                    _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                  ]),
                ]),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t(
                        "admin.component.loggers.deviceConfigurationModal.advertisingInterval"
                      ),
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t(
                            "admin.component.loggers.deviceConfigurationModal.advertisingInterval"
                          )
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t(
                          "admin.component.loggers.deviceConfigurationModal.advertisingInterval"
                        )
                      ),
                    },
                  },
                  [
                    _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|numeric|min_value:5|max_value:65535",
                          expression:
                            "'required|numeric|min_value:5|max_value:65535'",
                        },
                      ],
                      attrs: {
                        type: "number",
                        min: 5,
                        max: 65535,
                        placeholder: _vm.$t(
                          "admin.component.loggers.deviceConfigurationModal.advertisingInterval"
                        ),
                        name: _vm.$t(
                          "admin.component.loggers.deviceConfigurationModal.advertisingInterval"
                        ),
                      },
                      on: {
                        input: function ($event) {
                          _vm.advertisingInterval = Number(
                            _vm.advertisingInterval
                          )
                        },
                      },
                      model: {
                        value: _vm.advertisingInterval,
                        callback: function ($$v) {
                          _vm.advertisingInterval = $$v
                        },
                        expression: "advertisingInterval",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t(
                        "admin.component.loggers.deviceConfigurationModal.temperatureMeasurementInterval"
                      ),
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t(
                            "admin.component.loggers.deviceConfigurationModal.temperatureMeasurementInterval"
                          )
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t(
                          "admin.component.loggers.deviceConfigurationModal.temperatureMeasurementInterval"
                        )
                      ),
                    },
                  },
                  [
                    _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|numeric|min_value:0|max_value:65535",
                          expression:
                            "'required|numeric|min_value:0|max_value:65535'",
                        },
                      ],
                      attrs: {
                        min: 0,
                        max: 65535,
                        type: "number",
                        placeholder: _vm.$t(
                          "admin.component.loggers.deviceConfigurationModal.temperatureMeasurementInterval"
                        ),
                        name: _vm.$t(
                          "admin.component.loggers.deviceConfigurationModal.temperatureMeasurementInterval"
                        ),
                      },
                      model: {
                        value: _vm.temperatureMeasurementInterval,
                        callback: function ($$v) {
                          _vm.temperatureMeasurementInterval = $$v
                        },
                        expression: "temperatureMeasurementInterval",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t(
                        "admin.component.loggers.deviceConfigurationModal.humidityMeasurementInterval"
                      ),
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t(
                            "admin.component.loggers.deviceConfigurationModal.humidityMeasurementInterval"
                          )
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t(
                          "admin.component.loggers.deviceConfigurationModal.humidityMeasurementInterval"
                        )
                      ),
                    },
                  },
                  [
                    _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|numeric|min_value:0|max_value:65535",
                          expression:
                            "'required|numeric|min_value:0|max_value:65535'",
                        },
                      ],
                      attrs: {
                        min: 0,
                        max: 65535,
                        type: "number",
                        placeholder: _vm.$t(
                          "admin.component.loggers.deviceConfigurationModal.humidityMeasurementInterval"
                        ),
                        name: _vm.$t(
                          "admin.component.loggers.deviceConfigurationModal.humidityMeasurementInterval"
                        ),
                      },
                      model: {
                        value: _vm.humidityMeasurementInterval,
                        callback: function ($$v) {
                          _vm.humidityMeasurementInterval = $$v
                        },
                        expression: "humidityMeasurementInterval",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t(
                        "admin.component.loggers.deviceConfigurationModal.radioTxConfiguration"
                      ),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "border" },
                      [
                        _c(
                          "b-field",
                          { attrs: { grouped: "" } },
                          [
                            _c(
                              "b-field",
                              {
                                staticClass: "radio-buttons",
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.frequencyType"
                                  ),
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "admin.component.loggers.deviceConfigurationModal.frequencyType"
                                      )
                                    ),
                                  },
                                  message: _vm.errors.first(
                                    _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.frequencyType"
                                    )
                                  ),
                                  expanded: "",
                                },
                              },
                              _vm._l(_vm.FrequencyTypes, function (el) {
                                return _c(
                                  "b-radio-button",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    key: el,
                                    attrs: {
                                      name: _vm.$t(
                                        "admin.component.loggers.deviceConfigurationModal.frequencyType"
                                      ),
                                      "native-value": el,
                                      type: "is-primary",
                                      expanded: "",
                                    },
                                    model: {
                                      value: _vm.selectedTxFrequencyType,
                                      callback: function ($$v) {
                                        _vm.selectedTxFrequencyType = $$v
                                      },
                                      expression: "selectedTxFrequencyType",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              `admin.component.loggers.deviceConfigurationModal.frequencyTypes.${el}`
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              1
                            ),
                            _c(
                              "b-field",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.frequencyTx"
                                  ),
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "admin.component.loggers.deviceConfigurationModal.frequencyTx"
                                      )
                                    ),
                                  },
                                  message: _vm.errors.first(
                                    _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.frequencyTx"
                                    )
                                  ),
                                  expanded: "",
                                },
                              },
                              [
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        _vm.selectedTxFrequencyType ==
                                        _vm.FrequencyTypes.FORTYFOURA
                                          ? "required|numeric|min_value:433050000|max_value:434790000"
                                          : "required|numeric|min_value:869700000|max_value:870000000",
                                      expression:
                                        "\n                  selectedTxFrequencyType == FrequencyTypes.FORTYFOURA\n                    ? 'required|numeric|min_value:433050000|max_value:434790000'\n                    : 'required|numeric|min_value:869700000|max_value:870000000'\n                ",
                                    },
                                  ],
                                  attrs: {
                                    type: "number",
                                    min:
                                      _vm.selectedTxFrequencyType ==
                                      _vm.FrequencyTypes.FORTYFOURA
                                        ? 433050000
                                        : 869700000,
                                    max:
                                      _vm.selectedTxFrequencyType ==
                                      _vm.FrequencyTypes.FORTYFOURA
                                        ? 434790000
                                        : 870000000,
                                    placeholder: _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.frequencyTx"
                                    ),
                                    name: _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.frequencyTx"
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.selectedTxFrequency = Number(
                                        _vm.selectedTxFrequency
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedTxFrequency,
                                    callback: function ($$v) {
                                      _vm.selectedTxFrequency = $$v
                                    },
                                    expression: "selectedTxFrequency",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          [
                            _c("b-slider", {
                              attrs: {
                                lazy: "",
                                min:
                                  _vm.selectedTxFrequencyType ==
                                  _vm.FrequencyTypes.FORTYFOURA
                                    ? 433050000
                                    : 869700000,
                                max:
                                  _vm.selectedTxFrequencyType ==
                                  _vm.FrequencyTypes.FORTYFOURA
                                    ? 434790000
                                    : 870000000,
                              },
                              model: {
                                value: _vm.selectedTxFrequency,
                                callback: function ($$v) {
                                  _vm.selectedTxFrequency = $$v
                                },
                                expression: "selectedTxFrequency",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.loggers.deviceConfigurationModal.bandwidth"
                              ),
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.bandwidth"
                                  )
                                ),
                              },
                              message: _vm.errors.first(
                                _vm.$t(
                                  "admin.component.loggers.deviceConfigurationModal.bandwidth"
                                )
                              ),
                            },
                          },
                          [
                            _c(
                              "b-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.bandwidth"
                                  ),
                                  name: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.bandwidth"
                                  ),
                                  expanded: "",
                                },
                                model: {
                                  value: _vm.selectedTxBandwidth,
                                  callback: function ($$v) {
                                    _vm.selectedTxBandwidth = $$v
                                  },
                                  expression: "selectedTxBandwidth",
                                },
                              },
                              _vm._l(_vm.BandwidthOptions, function (option) {
                                return _c(
                                  "option",
                                  { key: option, domProps: { value: option } },
                                  [_vm._v(" " + _vm._s(option) + " ")]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                              ),
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                                  )
                                ),
                              },
                              message: _vm.errors.first(
                                _vm.$t(
                                  "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                                )
                              ),
                            },
                          },
                          [
                            _c(
                              "b-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                                  ),
                                  name: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                                  ),
                                  expanded: "",
                                },
                                model: {
                                  value: _vm.selectedRxSpreadFactor,
                                  callback: function ($$v) {
                                    _vm.selectedRxSpreadFactor = $$v
                                  },
                                  expression: "selectedRxSpreadFactor",
                                },
                              },
                              _vm._l(
                                _vm.SpreadFactorOptions,
                                function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option,
                                      domProps: { value: option },
                                    },
                                    [_vm._v(" " + _vm._s(option) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.loggers.deviceConfigurationModal.codingRate"
                              ),
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.codingRate"
                                  )
                                ),
                              },
                              message: _vm.errors.first(
                                _vm.$t(
                                  "admin.component.loggers.deviceConfigurationModal.codingRate"
                                )
                              ),
                            },
                          },
                          [
                            _c(
                              "b-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.codingRate"
                                  ),
                                  name: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.codingRate"
                                  ),
                                  expanded: "",
                                },
                                on: { input: _vm.selectTxCodingRate },
                                model: {
                                  value: _vm.selectedTxCodingRateOption,
                                  callback: function ($$v) {
                                    _vm.selectedTxCodingRateOption = $$v
                                  },
                                  expression: "selectedTxCodingRateOption",
                                },
                              },
                              _vm._l(_vm.CodingRates, function (option) {
                                return _c(
                                  "option",
                                  { key: option, domProps: { value: option } },
                                  [_vm._v(" " + _vm._s(option) + " ")]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          { attrs: { grouped: "" } },
                          [
                            _c(
                              "b-field",
                              {
                                staticClass: "radio-buttons",
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.txPowerDbm"
                                  ),
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "admin.component.loggers.deviceConfigurationModal.txPowerDbm"
                                      )
                                    ),
                                  },
                                  message: _vm.errors.first(
                                    _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.txPowerDbm"
                                    )
                                  ),
                                  expanded: "",
                                },
                              },
                              _vm._l(_vm.TxPowerTypes, function (el) {
                                return _c(
                                  "b-radio-button",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    key: el,
                                    attrs: {
                                      name: _vm.$t(
                                        "admin.component.loggers.deviceConfigurationModal.txPowerDbm"
                                      ),
                                      "native-value": el,
                                      type: "is-primary",
                                      expanded: "",
                                    },
                                    model: {
                                      value: _vm.selectedTxPowerType,
                                      callback: function ($$v) {
                                        _vm.selectedTxPowerType = $$v
                                      },
                                      expression: "selectedTxPowerType",
                                    },
                                  },
                                  [_c("span", [_vm._v(" " + _vm._s(el))])]
                                )
                              }),
                              1
                            ),
                            _c(
                              "b-field",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.txPower"
                                  ),
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "admin.component.loggers.deviceConfigurationModal.txPower"
                                      )
                                    ),
                                  },
                                  message: _vm.errors.first(
                                    _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.txPower"
                                    )
                                  ),
                                  expanded: "",
                                },
                              },
                              [
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        _vm.selectedTxPowerType ==
                                        _vm.TxPowerTypes.SX1261
                                          ? "required|integer|min_value:-17|max_value:14"
                                          : "required|integer|min_value:-9|max_value:22",
                                      expression:
                                        "\n                  selectedTxPowerType == TxPowerTypes.SX1261\n                    ? 'required|integer|min_value:-17|max_value:14'\n                    : 'required|integer|min_value:-9|max_value:22'\n                ",
                                    },
                                  ],
                                  attrs: {
                                    type: "number",
                                    min:
                                      _vm.selectedTxPowerType ==
                                      _vm.TxPowerTypes.SX1261
                                        ? -17
                                        : -9,
                                    max:
                                      _vm.selectedTxPowerType ==
                                      _vm.TxPowerTypes.SX1261
                                        ? 14
                                        : 22,
                                    placeholder: _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.txPower"
                                    ),
                                    name: _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.txPower"
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.selectedTxPower = _vm.selectedTxPower
                                        ? Number(_vm.selectedTxPower)
                                        : null
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedTxPower,
                                    callback: function ($$v) {
                                      _vm.selectedTxPower = $$v
                                    },
                                    expression: "selectedTxPower",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          [
                            _c("b-slider", {
                              attrs: {
                                lazy: "",
                                min:
                                  _vm.selectedTxPowerType ==
                                  _vm.TxPowerTypes.SX1261
                                    ? -17
                                    : -9,
                                max:
                                  _vm.selectedTxPowerType ==
                                  _vm.TxPowerTypes.SX1261
                                    ? 14
                                    : 22,
                              },
                              model: {
                                value: _vm.selectedTxPower,
                                callback: function ($$v) {
                                  _vm.selectedTxPower = $$v
                                },
                                expression: "selectedTxPower",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t(
                        "admin.component.loggers.deviceConfigurationModal.radioRxConfiguration"
                      ),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "border" },
                      [
                        _c(
                          "b-field",
                          { attrs: { grouped: "" } },
                          [
                            _c(
                              "b-field",
                              {
                                staticClass: "radio-buttons",
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.frequencyType"
                                  ),
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "admin.component.loggers.deviceConfigurationModal.frequencyType"
                                      )
                                    ),
                                  },
                                  message: _vm.errors.first(
                                    _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.frequencyType"
                                    )
                                  ),
                                  expanded: "",
                                },
                              },
                              _vm._l(_vm.FrequencyTypes, function (el) {
                                return _c(
                                  "b-radio-button",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    key: el,
                                    attrs: {
                                      name: _vm.$t(
                                        "admin.component.loggers.deviceConfigurationModal.frequencyType"
                                      ),
                                      "native-value": el,
                                      type: "is-primary",
                                      expanded: "",
                                    },
                                    model: {
                                      value: _vm.selectedRxFrequencyType,
                                      callback: function ($$v) {
                                        _vm.selectedRxFrequencyType = $$v
                                      },
                                      expression: "selectedRxFrequencyType",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              `admin.component.loggers.deviceConfigurationModal.frequencyTypes.${el}`
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              1
                            ),
                            _c(
                              "b-field",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.frequencyRx"
                                  ),
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "admin.component.loggers.deviceConfigurationModal.frequencyRx"
                                      )
                                    ),
                                  },
                                  message: _vm.errors.first(
                                    _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.frequencyRx"
                                    )
                                  ),
                                  expanded: "",
                                },
                              },
                              [
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        _vm.selectedRxFrequencyType ==
                                        _vm.FrequencyTypes.FORTYFOURA
                                          ? "required|numeric|min_value:433050000|max_value:434790000"
                                          : "required|numeric|min_value:869700000|max_value:870000000",
                                      expression:
                                        "\n                  selectedRxFrequencyType == FrequencyTypes.FORTYFOURA\n                    ? 'required|numeric|min_value:433050000|max_value:434790000'\n                    : 'required|numeric|min_value:869700000|max_value:870000000'\n                ",
                                    },
                                  ],
                                  attrs: {
                                    type: "number",
                                    min:
                                      _vm.selectedRxFrequencyType ==
                                      _vm.FrequencyTypes.FORTYFOURA
                                        ? 433050000
                                        : 869700000,
                                    max:
                                      _vm.selectedRxFrequencyType ==
                                      _vm.FrequencyTypes.FORTYFOURA
                                        ? 434790000
                                        : 870000000,
                                    placeholder: _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.frequencyRx"
                                    ),
                                    name: _vm.$t(
                                      "admin.component.loggers.deviceConfigurationModal.frequencyRx"
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.selectedRxFrequency = Number(
                                        _vm.selectedRxFrequency
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedRxFrequency,
                                    callback: function ($$v) {
                                      _vm.selectedRxFrequency = $$v
                                    },
                                    expression: "selectedRxFrequency",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          [
                            _c("b-slider", {
                              attrs: {
                                lazy: "",
                                min:
                                  _vm.selectedRxFrequencyType ==
                                  _vm.FrequencyTypes.FORTYFOURA
                                    ? 433050000
                                    : 869700000,
                                max:
                                  _vm.selectedRxFrequencyType ==
                                  _vm.FrequencyTypes.FORTYFOURA
                                    ? 434790000
                                    : 870000000,
                              },
                              model: {
                                value: _vm.selectedRxFrequency,
                                callback: function ($$v) {
                                  _vm.selectedRxFrequency = $$v
                                },
                                expression: "selectedRxFrequency",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.loggers.deviceConfigurationModal.bandwidth"
                              ),
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.bandwidth"
                                  )
                                ),
                              },
                              message: _vm.errors.first(
                                _vm.$t(
                                  "admin.component.loggers.deviceConfigurationModal.bandwidth"
                                )
                              ),
                            },
                          },
                          [
                            _c(
                              "b-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.bandwidth"
                                  ),
                                  name: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.bandwidth"
                                  ),
                                  expanded: "",
                                },
                                model: {
                                  value: _vm.selectedRxBandwidth,
                                  callback: function ($$v) {
                                    _vm.selectedRxBandwidth = $$v
                                  },
                                  expression: "selectedRxBandwidth",
                                },
                              },
                              _vm._l(_vm.BandwidthOptions, function (option) {
                                return _c(
                                  "option",
                                  { key: option, domProps: { value: option } },
                                  [_vm._v(" " + _vm._s(option) + " ")]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                              ),
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                                  )
                                ),
                              },
                              message: _vm.errors.first(
                                _vm.$t(
                                  "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                                )
                              ),
                            },
                          },
                          [
                            _c(
                              "b-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                                  ),
                                  name: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.spreadFactor"
                                  ),
                                  expanded: "",
                                },
                                model: {
                                  value: _vm.selectedTxSpreadFactor,
                                  callback: function ($$v) {
                                    _vm.selectedTxSpreadFactor = $$v
                                  },
                                  expression: "selectedTxSpreadFactor",
                                },
                              },
                              _vm._l(
                                _vm.SpreadFactorOptions,
                                function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option,
                                      domProps: { value: option },
                                    },
                                    [_vm._v(" " + _vm._s(option) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.loggers.deviceConfigurationModal.codingRate"
                              ),
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.codingRate"
                                  )
                                ),
                              },
                              message: _vm.errors.first(
                                _vm.$t(
                                  "admin.component.loggers.deviceConfigurationModal.codingRate"
                                )
                              ),
                            },
                          },
                          [
                            _c(
                              "b-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.codingRate"
                                  ),
                                  name: _vm.$t(
                                    "admin.component.loggers.deviceConfigurationModal.codingRate"
                                  ),
                                  expanded: "",
                                },
                                on: { input: _vm.selectRxCodingRate },
                                model: {
                                  value: _vm.selectedRxCodingRateOption,
                                  callback: function ($$v) {
                                    _vm.selectedRxCodingRateOption = $$v
                                  },
                                  expression: "selectedRxCodingRateOption",
                                },
                              },
                              _vm._l(_vm.CodingRates, function (option) {
                                return _c(
                                  "option",
                                  { key: option, domProps: { value: option } },
                                  [_vm._v(" " + _vm._s(option) + " ")]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: {
                disabled: !_vm.isDeviceConfigVisible,
                type: "is-primary",
              },
              on: {
                click: function ($event) {
                  return _vm.deviceConfiguration()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "admin.component.loggers.deviceConfigurationModal.confirm"
                  )
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }