import { __decorate } from "tslib";
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import ApiLinks from '@/entities/ApiLinks';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import DeviceRepository from '@/services/repository/DeviceRepository';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import SourceRepository from '@/services/repository/SourceRepository';
import CreateRuleWarning from '@/views/components/notification/CreateRuleWarning.vue';
import ConfirmCreateLoggerModal from '@/views/components/logger/ConfirmCreateLoggerModal.vue';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import AppConfig from '@/configLoader';
import AxiosService from '@/services/api/AxiosService';
var deviceRepository;
var companyLoggerRepository;
var sourceRepository;
var eventTypeRepository;
const STRONG_SIGNAL_BOUNDARY = -60;
const MEDIUM_SIGNAL_BOUNDARY = -80;
let AddLoggersComponent = class AddLoggersComponent extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE = 5;
        this.selectedDeviceId = null;
        this.devices = [];
        this.loggers = [];
        this.checkedLoggers = [];
        this.isScanning = false;
        this.isWaitingForScanStart = false;
        this.isStopRequestPending = false;
        this.isStartRequestPending = false;
        this.lastSeenIncrementHandler = null;
        this.reloadDevicesHandler = null;
        this.intervalDuration = 0;
        this.createRuleWarningVisible = false;
        this.createdSourcesIds = [];
        this.isConfirmModalActive = false;
    }
    deviceIdChanged() {
        if (this.deviceId && this.deviceId.length > 0) {
            this.selectedDeviceId = this.deviceId;
            if (this.isSelectedDeviceOnline)
                this.startScanning();
        }
    }
    get onlineDevices() {
        return this.devices.filter((x) => x.isOnline == true);
    }
    get offlineDevices() {
        return this.devices.filter((x) => x.isOnline == false);
    }
    get isSelectedDeviceOnline() {
        return (this.devices.find((x) => x.deviceId == this.selectedDeviceId) || {}).isOnline;
    }
    async created() {
        deviceRepository = new DeviceRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        sourceRepository = new SourceRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        await this.loadDeviceList();
        this.reloadDevicesHandler = setInterval(this.loadDeviceList, 10000);
        this.lastSeenIncrementHandler = setInterval(this.incrementLastSeen, 1000);
        this.deviceIdChanged();
    }
    destroyed() {
        this.stopScanning();
        clearInterval(this.lastSeenIncrementHandler);
        clearInterval(this.reloadDevicesHandler);
    }
    incrementLastSeen() {
        this.loggers.forEach((logger) => {
            this.intervalDuration++;
            logger.lastSeenBefore = logger.lastSeenBefore + 1;
        });
    }
    customSearch(logger, value) {
        return logger.assigned.toString() == value;
    }
    async loadDeviceList() {
        let pagination = {
            page: 1,
            pageSize: 100
        };
        let deviceResult = await deviceRepository.getDevices(pagination);
        this.devices = deviceResult.getData();
    }
    async startScanning() {
        if (this.isScanning || this.isStartRequestPending)
            return;
        if (!this.isSelectedDeviceOnline) {
            return;
        }
        clearInterval(this.reloadDevicesHandler);
        this.isStartRequestPending = true;
        if (this.selectedDeviceId && this.selectedDeviceId.length > 0) {
            let success = await deviceRepository.startScan(this.selectedDeviceId);
            if (success) {
                this.isWaitingForScanStart = true;
                this.isStartRequestPending = false;
                VueUtilities.openSuccessToast(this, this.$t('component.logger.modal.messages.start.success').toString());
                this.listenForLoggers();
            }
            else {
                this.isWaitingForScanStart = false;
                VueUtilities.openErrorToast(this, this.$t('component.logger.modal.messages.start.failure').toString());
            }
        }
    }
    async stopScanning() {
        if (!this.isScanning || this.isStopRequestPending)
            return;
        this.reloadDevicesHandler = setInterval(this.loadDeviceList, 10000);
        this.isStopRequestPending = true;
        let success = await deviceRepository.stopScan(this.selectedDeviceId);
        if (success) {
            this.isStopRequestPending = false;
            this.connection.stop();
            this.isScanning = false;
            try {
                VueUtilities.openSuccessToast(this, this.$t('component.logger.modal.messages.stop.success').toString());
            }
            catch (error) { }
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logger.modal.messages.stop.failure').toString());
        }
    }
    async validateBeforeSubmit() {
        if (this.selectedDeviceId && this.checkedLoggers.length > 0 && this.checkedLoggers.find((x) => !x.hasSource)) {
            this.connection.stop();
            this.stopScanning();
            if (this.checkedLoggers.find((x) => !x.name)) {
                this.isConfirmModalActive = true;
            }
            else {
                this.activateLoggersAndCreateSources();
            }
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logger.modal.messages.add.empty').toString());
        }
    }
    createSources() {
        this.fillNamesWithSerialNumbers();
        this.activateLoggersAndCreateSources();
    }
    onlyActivate() {
        this.activateLoggersAndCreateSources();
    }
    async activateLoggersAndCreateSources() {
        await this.checkAllNames();
        let allOk = this.checkedLoggers.filter((x) => x.hasErrorField === true).length <= 0;
        if (allOk) {
            let listToAdd = this.checkedLoggers
                .filter((x) => x.assigned == false)
                .map((logger) => logger.companyLoggerId);
            if (listToAdd.length > 0) {
                let response = await companyLoggerRepository.activateLoggers(listToAdd);
                if (!response) {
                    VueUtilities.openErrorToast(this, `${this.$t('component.logger.modal.messages.add.failure')}`);
                    return;
                }
            }
            eventTypeRepository.getAllTypes(true);
            try {
                this.createSourcesOnServer((responseSources) => {
                    this.createdSourcesIds = responseSources.map((x) => x.id);
                    if (this.createdSourcesIds.length > 0) {
                        this.showSuccessToast();
                    }
                    else {
                        VueUtilities.openSuccessToast(this, `${this.$t('component.logger.modal.messages.add.all_active')}`);
                    }
                    if (!this.inWizard && this.createdSourcesIds.length > 0) {
                        this.createRuleWarningVisible = true;
                    }
                    else {
                        this.$emit('result', true);
                    }
                });
            }
            catch (error) { }
        }
        else {
            this.closeConfirmModal(false);
            this.showExistingNameToast();
        }
    }
    warningClosed() {
        this.$emit('result', true);
    }
    showExistingNameToast() {
        VueUtilities.openErrorToast(this, this.$t('component.logger.assignSourceList.list.table.msg.unique_name').toString());
    }
    showSuccessToast() {
        VueUtilities.openSuccessToast(this, this.$t('component.logger.assignSourceList.list.table.msg.created').toString());
    }
    async checkAllNames() {
        var i = 0;
        for (i = 0; i < this.loggers.length; i++) {
            if (!this.loggers[i].hasSource && this.checkedLoggers.find((x) => x.macAddress == this.loggers[i].macAddress))
                await this.checkName(this.loggers[i], i);
        }
    }
    async checkName(logger, index) {
        let checkResponse = await sourceRepository.checkIfNameExists(this.loggers[index].name);
        if (logger.name && this.loggers.filter((x) => x.name == logger.name).length > 1)
            checkResponse.Exist = true;
        logger.hasErrorField = this.assignFieldError(index, checkResponse.Exist);
        logger.errorMsg = this.assignFieldErrorMsg(index, checkResponse.Exist);
    }
    createSourcesOnServer(completion) {
        var i = 0;
        let allPromises = [];
        for (i = 0; i < this.checkedLoggers.length; i++) {
            if (this.checkedLoggers[i].name && !this.checkedLoggers[i].hasSource)
                allPromises.push(this.createSource(i));
        }
        Promise.all(allPromises).then((response) => {
            completion(response);
        });
    }
    async createSource(index) {
        let sourceDto = {
            Name: this.checkedLoggers[index].name
        };
        sourceDto.LoggerId = this.checkedLoggers[index].companyLoggerId;
        sourceDto.Description = this.checkedLoggers[index].description;
        return sourceRepository.createSource(sourceDto);
    }
    fillNamesWithSerialNumbers() {
        var i = 0;
        this.errors.items = [];
        for (i; i < this.loggers.length; i++) {
            if (!(this.loggers[i].name && this.loggers[i].name.length > 0) &&
                this.checkedLoggers.find((x) => x.macAddress == this.loggers[i].macAddress)) {
                this.loggers[i].name = this.loggers[i].serialNumber;
            }
        }
    }
    listenForLoggers() {
        let options = {
            accessTokenFactory: () => {
                return new Promise((resolve, reject) => {
                    let token = AxiosService.GetWebSocketOptions(this);
                    if (token == null || token.length === 0)
                        reject();
                    resolve(token);
                });
            }
        };
        this.connection = new HubConnectionBuilder()
            .withUrl(`${AppConfig.getConfig().backend.address}${ApiLinks.LoggerScan.Home}`, options)
            .configureLogging(LogLevel.Information)
            .build();
        this.connection
            .start()
            .then(() => {
            this.connection.invoke('joinGroup', this.selectedDeviceId);
        })
            .catch((error) => console.log(error));
        this.connection.on('loggerFound', (data) => {
            if (this.isScanning) {
                this.addToList(data);
            }
        });
        this.connection.on('ScanStarted', (data) => {
            this.isWaitingForScanStart = false;
            this.isScanning = true;
            VueUtilities.openSuccessToast(this, this.$t('component.logger.modal.messages.start.started').toString());
        });
        this.connection.on('ScanStopped', (data) => {
            VueUtilities.openSuccessToast(this, this.$t('component.logger.modal.messages.stop.stopped').toString());
        });
    }
    addToList(data) {
        let existingLoggerIndex = this.loggers.findIndex((x) => x.companyLoggerId == data.CompanyLoggerId);
        let existingLogger = this.loggers[existingLoggerIndex];
        if (existingLogger) {
            existingLogger.rssi = data.Rssi;
            existingLogger.lastSeenBefore = 0;
        }
        else {
            let assignedLogger = this.assignedLoggers.find((x) => x.companyLoggerId == data.CompanyLoggerId);
            let isAssigned = assignedLogger != undefined;
            let processedData = {
                companyLoggerId: data.CompanyLoggerId,
                macAddress: data.MacAddress,
                rssi: data.Rssi,
                serialNumber: data.SerialNumber
            };
            if (isAssigned && assignedLogger.source.exists()) {
                this.checkedLoggers.push(processedData);
            }
            this.loggers.push(Object.assign(processedData, {
                assigned: isAssigned,
                hasSource: assignedLogger?.source?.exists() == true,
                lastSeenBefore: 0,
                name: assignedLogger?.source?.name,
                description: assignedLogger?.source?.description,
                hasErrorField: null,
                errorMsg: null
            }));
        }
    }
    getClassBasedOnRssi(rssi) {
        if (rssi >= STRONG_SIGNAL_BOUNDARY)
            return 'three-bars';
        else if (rssi >= MEDIUM_SIGNAL_BOUNDARY)
            return 'two-bars';
        else
            return 'one-bar';
    }
    assignFieldError(index, exists) {
        if (this.errors.has(this.$t('component.location.create.name') + index.toString()) || exists === true)
            return true;
        else
            return false;
    }
    assignFieldErrorMsg(index, exists) {
        if (this.errors.has(this.$t('component.location.create.name') + index.toString()))
            return this.$t('component.logger.assignSourceList.list.table.msg.empty_name').toString();
        else if (exists === true)
            return this.$t('component.logger.assignSourceList.list.table.msg.unique_name').toString();
        else
            return null;
    }
    closeConfirmModal(value) {
        this.isConfirmModalActive = false;
    }
};
__decorate([
    Prop({ type: String })
], AddLoggersComponent.prototype, "deviceId", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AddLoggersComponent.prototype, "isModal", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AddLoggersComponent.prototype, "assignedLoggers", void 0);
__decorate([
    Prop({ type: Object })
], AddLoggersComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AddLoggersComponent.prototype, "inWizard", void 0);
__decorate([
    Watch('deviceId')
], AddLoggersComponent.prototype, "deviceIdChanged", null);
AddLoggersComponent = __decorate([
    Component({ components: { CreateRuleWarning, ConfirmCreateLoggerModal } })
], AddLoggersComponent);
export default AddLoggersComponent;
