import { __decorate } from "tslib";
import UserPreferences from '@/services/UserPreferences';
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import LoggerStates from '@/entities/enums/LoggerStates';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import debounce from 'lodash.debounce';
import SourceRepository from '@/services/repository/SourceRepository';
var sourceRepository;
var companyLoggerRepository;
const DEBOUNCE_TIMER = 500;
let AdminRegisterLoggersModal = class AdminRegisterLoggersModal extends Vue {
    constructor() {
        super(...arguments);
        this.LoggerStates = LoggerStates;
        this.isLoading = false;
        this.selectedLoggers = Array();
        this.currentFilter = [];
        this.page = UserPreferences.LocalStorageKeys.AdminLoggerListPage.defaultValue;
        this.pageSize = 6;
        this.jumpToPageValue = null;
        this.serialNumber = '';
        this.existingNames = [];
        this.sourceCreationEnabled = false;
        this.handleFilterSerialNumberDebounce = debounce((e) => {
            this.filterLoggers(e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    async created() {
        sourceRepository = new SourceRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.currentFilter.push('State==' + LoggerStates.PUBLISHED.toLowerCase());
        this.$emit('loadAdminLoggers', this.pagination, this.currentFilter);
    }
    filterLoggers(event) {
        if (typeof event === 'string') {
            event = event.includes(',') ? event.replace(/,/g, '\\,') : event;
        }
        let index = this.filterExists('SerialNumber@=');
        if (index !== -1)
            this.currentFilter[index] = 'SerialNumber@=' + event;
        else
            this.currentFilter.push('SerialNumber@=' + event);
        this.onOptionsChange(this.pagination);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination);
    }
    onOptionsChange(pagination) {
        this.$emit('loadAdminLoggers', pagination, this.currentFilter);
    }
    selectLoggers(selectedLoggers) {
        this.selectedLoggers = selectedLoggers;
        this.existingNames = this.selectedLoggers.map((x) => false);
    }
    getSelectedLogger(logger) {
        return this.selectedLoggers.find((x) => x.currentlyPublishedAs.companyLoggerId == logger.currentlyPublishedAs.companyLoggerId);
    }
    getSelectedLoggerIndex(logger) {
        return this.selectedLoggers.findIndex((x) => x.currentlyPublishedAs.companyLoggerId == logger.currentlyPublishedAs.companyLoggerId);
    }
    async checkName(logger, index) {
        let checkResponse = await sourceRepository.checkIfNameExists(this.selectedLoggers[index].additionalProperties.name, this.companyId);
        if (logger.additionalProperties.name &&
            this.selectedLoggers.filter((x) => x.additionalProperties.name.trim() == logger.additionalProperties.name.trim())
                .length > 1)
            checkResponse.Exist = true;
        this.existingNames[index] = checkResponse.Exist;
        logger.additionalProperties.hasErrorField = this.assignFieldError(index);
        logger.additionalProperties.errorMsg = this.assignFieldErrorMsg(index);
    }
    assignFieldError(index) {
        if (this.errors.has(this.$t('component.location.create.name') + index.toString()) ||
            this.existingNames[index] == true)
            return true;
        else
            return false;
    }
    assignFieldErrorMsg(index) {
        if (this.errors.has(this.$t('component.location.create.name') + index.toString()))
            return this.$t('component.logger.assignSourceList.list.table.msg.empty_name').toString();
        else if (this.existingNames[index] == true)
            return this.$t('component.logger.assignSourceList.list.table.msg.unique_name').toString();
        else
            return null;
    }
    registerLoggers() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.selectedLoggers.length > 0) {
                    if (this.sourceCreationEnabled) {
                        this.validateBeforeSubmit();
                    }
                    else {
                        this.addLoggers();
                    }
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.logger.assignSourceList.list.table.msg.empty_logger').toString());
                    return;
                }
            }
        });
    }
    validateBeforeSubmit(fromAutofill = false) {
        if (this.selectedLoggers.filter((x) => !x.additionalProperties.name).length > 0 && !fromAutofill) {
            this.$buefy.dialog.confirm({
                title: `${this.$t('component.logger.assignSourceList.list.emptyNamesDialog.title')}`,
                message: this.$tc('component.logger.assignSourceList.list.emptyNamesDialog.message', this.selectedLoggers.filter((x) => x.additionalProperties.name.length <= 0).length).toString(),
                confirmText: `${this.$t('generals.yes')}`,
                cancelText: `${this.$t('component.logger.assignSourceList.list.emptyNamesDialog.skip')}`,
                type: 'is-primary',
                hasIcon: true,
                onConfirm: () => {
                    this.fillNamesWithSerialNumbers();
                    this.validateBeforeSubmit(true);
                },
                onCancel: () => {
                    this.validateBeforeSubmit(true);
                }
            });
            return;
        }
        if (this.selectedLoggers.filter((x) => x.additionalProperties.name).length > 0) {
            if (!this.existingNames.includes(true)) {
                this.checkAllNames((success) => {
                    if (success) {
                        this.addLoggers();
                    }
                    else {
                        this.showExistingNameToast();
                    }
                });
            }
            else {
                this.showExistingNameToast();
            }
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logger.assignSourceList.list.table.msg.all_names_empty').toString());
            return;
        }
    }
    async addLoggers() {
        this.isLoading = true;
        let allPromises = new Array();
        this.selectedLoggers.forEach((logger, i) => {
            if (this.sourceCreationEnabled) {
                if (this.selectedLoggers[i].additionalProperties.name)
                    allPromises.push(companyLoggerRepository.adminActivateLogger(logger.currentlyPublishedAs.companyLoggerId, this.companyId));
            }
            else {
                allPromises.push(companyLoggerRepository.adminActivateLogger(logger.currentlyPublishedAs.companyLoggerId, this.companyId));
            }
        });
        await Promise.all(allPromises).then((response) => {
            if (response.find((x) => x !== true)) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.registerCompanyLoggersModal.failure'));
            }
            else {
                if (this.sourceCreationEnabled) {
                    this.createSources();
                }
                else {
                    this.loadAfterSuccess();
                }
            }
        });
    }
    createSources() {
        this.createSourcesOnServer((responseSources) => {
            this.loadAfterSuccess();
        });
    }
    loadAfterSuccess() {
        this.showSuccessToast();
        this.$emit('loadAdminLoggers', this.pagination, this.currentFilter);
        this.isLoading = false;
    }
    createSourcesOnServer(completion) {
        var i = 0;
        let allPromises = [];
        for (i = 0; i < this.selectedLoggers.length; i++) {
            if (this.selectedLoggers[i].additionalProperties.name)
                allPromises.push(this.createSource(i));
        }
        Promise.all(allPromises).then((response) => {
            completion(response);
        });
    }
    async createSource(index) {
        let sourceDto = {
            Name: this.selectedLoggers[index].additionalProperties.name
        };
        sourceDto.LoggerId = this.selectedLoggers[index].currentlyPublishedAs.companyLoggerId;
        return sourceRepository.createSource(sourceDto, this.companyId);
    }
    showSuccessToast() {
        VueUtilities.openSuccessToast(this, this.$t('admin.component.company.registerCompanyLoggersModal.success'));
        this.$emit('closeModal');
        this.$emit('loadDevicesAndLoggers');
    }
    showExistingNameToast() {
        VueUtilities.openErrorToast(this, this.$t('component.logger.assignSourceList.list.table.msg.unique_name').toString());
    }
    checkAllNames(completion) {
        var i = 0;
        let allPromises = [];
        for (i = 0; i < this.selectedLoggers.length; i++) {
            if (this.selectedLoggers[i].additionalProperties.name)
                allPromises.push(sourceRepository.checkIfNameExists(this.selectedLoggers[i].additionalProperties.name, this.companyId));
        }
        Promise.all(allPromises).then((response) => {
            let result = response.filter((x) => x.Exist == true).length == 0;
            completion(result);
        });
    }
    fillNamesWithSerialNumbers() {
        var i = 0;
        this.errors.items = [];
        for (i; i < this.selectedLoggers.length; i++) {
            if (!(this.selectedLoggers[i].additionalProperties.name &&
                this.selectedLoggers[i].additionalProperties.name.length > 0)) {
                this.selectedLoggers[i].additionalProperties.name = this.selectedLoggers[i].currentlyPublishedAs.serialNumber;
                this.checkName(this.selectedLoggers[i], i);
            }
        }
    }
};
__decorate([
    Prop({ type: Array })
], AdminRegisterLoggersModal.prototype, "adminLoggers", void 0);
__decorate([
    Prop({ type: String })
], AdminRegisterLoggersModal.prototype, "companyId", void 0);
__decorate([
    Prop({ type: Number })
], AdminRegisterLoggersModal.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminRegisterLoggersModal.prototype, "componentIsLoading", void 0);
AdminRegisterLoggersModal = __decorate([
    Component({})
], AdminRegisterLoggersModal);
export default AdminRegisterLoggersModal;
