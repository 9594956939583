"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class EventPointSetManager {
    constructor(eventDataset, colorGenerator) {
        let colors = colorGenerator.getEventPointsColors(eventDataset.length);
        eventDataset.forEach((dataset, index) => {
            if (!dataset.color)
                dataset.color = colors[index];
            colorGenerator.addToPickedColors(dataset.color);
        });
        this._eventDataset = eventDataset;
    }
    get eventDataset() {
        return this._eventDataset;
    }
    findEventPointSetByService(serviceId) {
        return this._eventDataset.filter(x => x.serviceId == serviceId);
    }
}
exports.default = EventPointSetManager;
