var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.organization_card.title")) + " "
          ),
        ]),
      ]),
      !_vm.noAssignedDepartments
        ? [
            _c(
              "div",
              {
                staticClass:
                  "is-flex is-justify-content-space-between is-align-items-center mb-5",
              },
              [
                _c(
                  "h2",
                  { staticClass: "subtitle is-6 has-text-weight-semibold" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("component.organization_card.state.title") +
                            ": "
                        ) +
                        " "
                    ),
                    _vm.hasAlarm
                      ? _c("span", { staticClass: "mr-2" }, [
                          _c("span", { staticClass: "has-text-danger" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.organization_card.state.alarm",
                                  {
                                    count: _vm.getIssueCountByType(
                                      _vm.IssueType.ALARM,
                                      "Alarm"
                                    ),
                                  }
                                )
                              )
                            ),
                          ]),
                          _vm._v(" | "),
                        ])
                      : _vm._e(),
                    _vm.hasWarning
                      ? _c("span", { staticClass: "mr-2" }, [
                          _c("span", { staticClass: "has-text-warning" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "component.organization_card.state.warning",
                                  _vm.getIssueCountByType(
                                    _vm.IssueType.ALARM,
                                    "Warning"
                                  )
                                )
                              )
                            ),
                          ]),
                          _vm._v(" | "),
                        ])
                      : _vm._e(),
                    _vm.hasLowBattery
                      ? _c("span", { staticClass: "mr-2" }, [
                          _c("span", { staticClass: "has-text-warning" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.organization_card.state.low_battery",
                                  {
                                    count: _vm.getIssueCountByType(
                                      _vm.IssueType.LOW_BATTERY
                                    ),
                                  }
                                )
                              )
                            ),
                          ]),
                          _vm._v(" |"),
                        ])
                      : _vm._e(),
                    _vm.hasOffline
                      ? _c("span", { staticClass: "mr-2" }, [
                          _c("span", { staticClass: "has-text-grey" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.organization_card.state.no_connection",
                                  {
                                    count:
                                      _vm.getIssueCountByType(
                                        _vm.IssueType.SOURCE_OFFLINE
                                      ) +
                                      _vm.getIssueCountByType(
                                        _vm.IssueType.DEVICE_OFFLINE
                                      ),
                                  }
                                )
                              )
                            ),
                          ]),
                          _vm._v(" |"),
                        ])
                      : _vm._e(),
                    _vm.hasMaintenance
                      ? _c("span", { staticClass: "mr-2" }, [
                          _c("span", { staticClass: "has-text-info" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.organization_card.state.maintenance",
                                  {
                                    count: _vm.getIssueCountByType(
                                      _vm.IssueType.MAINTENANCE
                                    ),
                                  }
                                )
                              )
                            ),
                          ]),
                          _vm._v("|"),
                        ])
                      : _vm._e(),
                    _vm.fineStates > 0 || _vm.acknowledgedStates > 0
                      ? _c("span", { staticClass: "has-text-success" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("component.organization_card.state.fine", {
                                count: _vm.fineStates,
                              })
                            ) + " "
                          ),
                          _vm.acknowledgedStates > 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "component.organization_card.state.acknowledged",
                                      { count: _vm.acknowledgedStates }
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm.isLoggedFromAdmin
              ? _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-justify-content-space-between mb-4",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "is-flex is-full-width" },
                      [
                        _vm.isNameSearch
                          ? _c(
                              "b-field",
                              {
                                staticClass:
                                  "mt-1-mobile is-hidden-tablet is-full-width",
                                attrs: { grouped: "" },
                              },
                              [
                                _c(
                                  "b-field",
                                  [
                                    _c(
                                      "b-select",
                                      {
                                        staticStyle: { width: "80px" },
                                        on: { input: _vm.changeSearchField },
                                        model: {
                                          value: _vm.selectedValue,
                                          callback: function ($$v) {
                                            _vm.selectedValue = $$v
                                          },
                                          expression: "selectedValue",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.tableColumns,
                                          function (option) {
                                            return [
                                              option.visible && option.hasSearch
                                                ? _c(
                                                    "option",
                                                    {
                                                      key: option.field,
                                                      domProps: {
                                                        value: option.field,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              `admin.component.company.list.table.${option.field}`
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterCompanyNameDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterDepartments(
                                        _vm.tableColumns.companyName.field,
                                        _vm.companyNameSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.companyNameSearch,
                                    callback: function ($$v) {
                                      _vm.companyNameSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "companyNameSearch",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "buttons" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "level-item",
                                        attrs: { type: "is-primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "times-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-field",
                          { staticClass: "is-hidden-mobile mr-2" },
                          [
                            _c("b-input", {
                              attrs: {
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: {
                                input: _vm.handleFilterCompanyNameDebounce,
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterDepartments(
                                    _vm.tableColumns.companyName.field,
                                    _vm.companyNameSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.companyNameSearch,
                                callback: function ($$v) {
                                  _vm.companyNameSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "companyNameSearch",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.isStatusSearch
                          ? _c(
                              "b-field",
                              {
                                staticClass:
                                  "mt-1-mobile is-hidden-tablet is-full-width",
                                attrs: { grouped: "" },
                              },
                              [
                                _c(
                                  "b-field",
                                  [
                                    _c(
                                      "b-select",
                                      {
                                        staticStyle: { width: "80px" },
                                        on: { input: _vm.changeSearchField },
                                        model: {
                                          value: _vm.selectedValue,
                                          callback: function ($$v) {
                                            _vm.selectedValue = $$v
                                          },
                                          expression: "selectedValue",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.tableColumns,
                                          function (option) {
                                            return [
                                              option.visible && option.hasSearch
                                                ? _c(
                                                    "option",
                                                    {
                                                      key: option.field,
                                                      domProps: {
                                                        value: option.field,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              `admin.component.company.list.table.${option.field}`
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.filterDepartments(
                                          _vm.tableColumns.companyStatus.field,
                                          _vm.companyStatusSearch
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.companyStatusSearch,
                                      callback: function ($$v) {
                                        _vm.companyStatusSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "companyStatusSearch",
                                    },
                                  },
                                  _vm._l(_vm.issueTypes, function (issue) {
                                    return _c(
                                      "option",
                                      {
                                        key: issue,
                                        domProps: { value: issue },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.organization_card.tooltip." +
                                                  issue
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "buttons" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "level-item",
                                        attrs: { type: "is-primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "times-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-field",
                          { staticClass: "is-hidden-mobile" },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.filterDepartments(
                                      _vm.tableColumns.companyStatus.field,
                                      _vm.companyStatusSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.companyStatusSearch,
                                  callback: function ($$v) {
                                    _vm.companyStatusSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "companyStatusSearch",
                                },
                              },
                              _vm._l(_vm.issueTypes, function (issue) {
                                return _c(
                                  "option",
                                  { key: issue, domProps: { value: issue } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.organization_card.tooltip." +
                                              issue
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { type: "is-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFilter()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "department-item columns is-multiline is-mobile mb-1",
              },
              [
                _vm.departments.getData().length > 0
                  ? _vm._l(_vm.departments.getData(), function (department) {
                      return _c(
                        "div",
                        {
                          key: department.id,
                          staticClass:
                            "column is-half-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd",
                        },
                        [
                          _c("OrganizationItem", {
                            attrs: {
                              favoriteDepartmentId: _vm.favoriteDepartmentId,
                              department: department,
                            },
                            on: {
                              reload: _vm.reload,
                              favoriteSelected: _vm.favoriteSelected,
                            },
                          }),
                        ],
                        1
                      )
                    })
                  : _c("div", { staticClass: "mx-4 mt-2" }, [
                      _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                    ]),
              ],
              2
            ),
          ]
        : _c(
            "b-notification",
            {
              staticClass: "is-child column is-full p-5 mt-5",
              attrs: {
                type: "is-warning",
                "has-icon": "",
                closable: false,
                "aria-close-label": "Close notification",
                role: "alert",
              },
            },
            [
              _c("p", { staticClass: "mt-3" }, [
                _vm._v(
                  _vm._s(_vm.$t("component.organization_card.list.empty_msg"))
                ),
              ]),
            ]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }