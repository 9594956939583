class ApiLinks {
    constructor() {
        this._home_ws = '/ws/v2';
        this._home = '/api/v2';
        this._admin = '/admin';
        this._eventsHome = this._home + '/events';
        this._notificationsHome = this._home + '/notifications';
        this._notificationSubscriptionsHome = this._home + '/notifications/subscribe';
        this._statisticsHome = this._home + '/statistics';
        this._devicesHome = this._home + '/devices';
        this._reportsHome = this._home + '/reports';
        this._eventTypesHome = this._home + '/eventTypes';
        this._companyLoggerHome = this._home + '/companyLoggers';
        this._companyHome = this._home + '/companies';
        this._publicDashboardHome = this._home + '/public/dashboard';
        this._salesHome = this._home + '/sales';
        this._departmentsHome = this._home + '/departments';
        this._profileHome = this._home + '/profile/me';
        this._loggersHome = this._home + '/loggers';
        this._adminUserHome = this._home + this._admin + '/users';
        this._adminLoggersHome = this._home + this._admin + '/loggers';
        this._resellerHome = this._home + this._admin + '/resellers';
        this._adminCompanyLoggersHome = this._home + this._admin + '/companyLoggers';
        this._adminNotificationsHome = this._home + this._admin + '/notifications';
        this._adminDepartmentsHome = this._home + this._admin + '/departments';
        this._adminGatewaysHome = this._home + this._admin + '/gateways';
        this._adminSalesHome = this._home + this._admin + '/sales';
        this._adminTiersHome = this._home + this._admin + '/tiers';
        this._adminCompanyTiersHome = this._home + this._admin + '/companies';
        this._adminSystemHome = this._home + this._admin + '/system';
        this.Home = this._home;
        this.Auth = {
            Login: '/auth/login',
            Logout: '/auth/logout',
            KeyCheck: '/auth/keyCheck',
            TokenCheck: '/auth/tokencheck',
            VerifyResend: '/auth/resend',
            Verify: '/auth/verify',
            Register: '/auth/register',
            PasswordForgot: '/auth/password/forgot',
            PasswordChange: '/auth/password/change'
        };
        this.Profile = {
            Home: this._profileHome,
            Settings: this._profileHome + '/settings',
            Me: this._profileHome,
            Phone: this._profileHome + '/phone',
            SendCode: this._profileHome + '/phone/sendCode',
            VerifyNumber: this._profileHome + '/phone/verify',
            ChangePassword: '/changePassword'
        };
        this.Users = {
            AdminHome: this._adminUserHome,
            Sessions: this._profileHome + '/sessions',
            Keys: {
                Disable: '/disable',
                Enable: '/enable',
                Role: '/role',
                Users: '/users',
                Settings: '/settings',
                Notifications: '/notifications',
                PhoneNumber: '/phoneNumber',
                Departments: '/departments',
                Presets: '/settings/presets',
                PreRegister: '/preregister',
                RequestVerification: '/requestVerification'
            }
        };
        this.Gateways = {
            Home: this._devicesHome,
            AdminHome: this._adminGatewaysHome,
            Registration: this._devicesHome + '/register',
            DeviceExists: this._devicesHome + '/exists',
            Firmwares: this._adminGatewaysHome + '/firmwares',
            Keys: {
                Gateways: '/gateways',
                Scan: '/scan',
                Config: '/config',
                Update: '/update',
                Activate: '/activate',
                ImportCsv: '/import/csv',
                Instances: '/instances',
                Credentials: '/credentials',
                Revoke: '/revoke',
                Revert: '/forceRevert',
                Return: '/return',
                Publish: '/publish',
                Register: '/register',
                firmwares: '/firmwares',
                commands: '/commands',
                Updates: '/updates',
                SelfUpdate: '/self-update'
            }
        };
        this.Devices = {
            Home: this._devicesHome,
            Registration: this._devicesHome + '/register'
        };
        this.DeviceGroups = this._home + '/deviceGroups';
        this.Configs = this._home + '/configs';
        this.LoggerGroups = this._home + '/loggerGroups';
        this.Events = {
            Home: this._eventsHome,
            Graph: this._eventsHome + '/graph',
            ByLogger: this._eventsHome + '/byLogger',
            ByDevice: this._eventsHome + '/byDevice',
            ByLoggerGroup: this._eventsHome + '/byLoggerGroup',
            Last: this._eventsHome + '/last',
            Keys: {
                Last: '/data/last',
                EventTypes: '/data/types',
                Units: '/data/units',
                Chart: '/data/chart',
                Outage: '/data/outage'
            }
        };
        this.Sharelink = {
            dashboards: '/data/dashboards'
        };
        this.Loggers = {
            Home: this._loggersHome,
            AdminHome: this._adminLoggersHome,
            CSVStrickers: this._adminLoggersHome + '/publish/sequence/csv',
            Keys: {
                Publish: '/publish',
                VerificationKey: '/verificationKey'
            }
        };
        this.Company = {
            Home: this._companyHome,
            Current: this._companyHome + '/current',
            Invite: this._companyHome + '/invite',
            Settings: this._companyHome + '/settings'
        };
        this.Reseller = {
            Home: this._resellerHome,
            Keys: {
                Members: '/members',
                Departments: '/departments',
                Gateways: '/gateways',
                Loggers: '/loggers',
                ByUser: '/byUser'
            }
        };
        this.Department = {
            Home: this._departmentsHome,
            AdminHome: this._adminDepartmentsHome,
            Keys: {
                Roles: '/roles',
                Settings: '/settings',
                Users: '/users',
                Key: '/key',
                Issues: '/issues',
                Invite: '/invite',
                Invitations: '/invitations',
                Accept: '/accept',
                Decline: '/decline',
                Members: '/members',
                Enable: '/enable',
                Disable: '/disable',
                Thermomap: '/thermomap',
                Image: '/image'
            }
        };
        this.EventTypes = {
            Home: this._eventTypesHome,
            Units: this._eventTypesHome + '/units'
        };
        this.Notifications = {
            Home: this._notificationsHome,
            AdminHome: this._adminNotificationsHome,
            Subscriptions: this._notificationsHome + '/subscribe',
            Types: this._notificationsHome + '/metadata/types',
            AdminTypes: this._adminNotificationsHome + '/metadata/types',
            Priorities: this._notificationsHome + '/metadata/priorities',
            Channels: this._notificationsHome + '/metadata/channels',
            Send: this._adminNotificationsHome + '/send',
            Keys: {
                Remove: '/remove',
                RemoveAll: '/remove/all'
            }
        };
        this.NotificationSubscriptions = {
            Settings: '/settings',
            Notifications: '/notifications',
            Presets: '/presets',
            Schedules: '/schedules',
            Mode: '/schedules/mode'
        };
        this.EventValidation = {
            Validation: '/data/validation',
            Settings: '/settings',
            Triggers: '/triggers',
            Keys: { Mode: '/mode', Test: '/test', Pause: '/pause', All: '/all' }
        };
        this.Statistics = {
            Home: this._statisticsHome,
            Boundaries: this._statisticsHome + '/boundaries'
        };
        this.Issues = {
            Keys: {
                Issues: '/issues',
                Notifications: '/notifications',
                Acknowledge: '/acknowledge',
                Active: '/active'
            }
        };
        this.Reports = {
            Home: this._reportsHome,
            Configurations: {
                Home: this._reportsHome + '/configurations',
                Active: this._reportsHome + '/configurations/active'
            },
            ExportPdf: '/reports/export/pdf',
            ExportRawPdf: '/reports/export/raw/pdf',
            Records: '/reports/records',
            Statistics: '/reports/records/statistics',
            Annotations: '/reports/records/annotations',
            ExportCsv: '/reports/export/csv',
            ExportRawCsv: '/reports/export/raw/csv',
            ExportOnePagePdf: '/reports/export/pdf/one-page-report',
            Configuration: '/reports/configuration',
            ActiveConfiguration: '/reports/configuration/active',
            StatusSocket: this._home_ws + '/reports/pdf/status'
        };
        this.Sources = {
            Keys: {
                Boundaries: '/boundaries',
                Sources: '/sources',
                Groups: '/sources/groups',
                Exists: '/exists',
                Assign: '/assignments',
                Add: '/add',
                Remove: '/remove',
                All: '/all',
                Deleted: '/deleted',
                Revive: '/revive',
                FloorPlan: '/sources/floorPlan',
                Image: '/image',
                Thumbnail: '/thumbnail',
                Data: '/data',
                Quantities: '/quantities'
            }
        };
        this.CompanyLogger = {
            Home: this._companyLoggerHome,
            AdminHome: this._adminCompanyLoggersHome,
            Settings: this._companyLoggerHome + '/settings',
            Activate: this._companyLoggerHome + '/activate',
            Validate: this._adminCompanyLoggersHome + '/validate',
            Exists: this._adminCompanyLoggersHome + '/exists',
            Keys: {
                Unpublish: '/unpublish',
                Protocol: '/calibration/protocol',
                ProtocolCopy: '/calibration/copy',
                Loggers: '/loggers',
                Activate: '/activate',
                Deactivate: '/deactivate',
                Calibration: '/calibration',
                Revert: '/revert',
                Return: '/return',
                ReadData: '/readData',
                Data: '/data',
                Verify: '/verify'
            }
        };
        this.LoggerScan = {
            Home: this._home_ws + '/loggers/scan'
        };
        this.LoggerRegistration = {
            Home: this._home_ws + '/admin/loggers/registration'
        };
        this.SignalStrength = {
            Home: this._home_ws + '/gateways/signal'
        };
        this.PublicDashboard = {
            Home: this._publicDashboardHome,
            Keys: {
                Sources: '/sources',
                Chart: '/sources/graph',
                Reports: '/sources/reports',
                EventTypes: '/eventTypes',
                Outage: '/sources/outage'
            }
        };
        this.Dashboard = {
            Keys: {
                Dashboards: '/data/dashboards',
                Pdf: '/pdf'
            }
        };
        this.Tiers = {
            Home: this._departmentsHome,
            AdminHome: this._adminTiersHome,
            Keys: {
                Tiers: '/tiers',
                Active: '/tiers/active',
                Usage: '/tiers/usage',
                Request: '/tiers/request',
                Activate: '/activate',
                Deactivate: '/deactivate'
            }
        };
        this.Sales = {
            Home: this._salesHome,
            Feedback: this._salesHome + '/feedback'
        };
        this.Queries = {
            Sources: 'sourceIds',
            Time: {
                From: 'from',
                To: 'to'
            },
            Page: 'page',
            PageSize: 'pageSize',
            Sorts: 'sorts',
            Annotations: 'annotations',
            Name: 'name',
            CompanyLoggerId: 'companyLoggerId',
            CompanyId: 'companyId',
            DepartmentId: 'departmentId',
            SerialNumber: 'serialNumber',
            From: 'from',
            To: 'to',
            Duration: 'duration',
            Token: 'token',
            DeviceId: 'deviceId',
            CalibrationExpiresBefore: 'ExpiresBefore',
            Tz: 'tz',
            Since: 'since',
            AlarmOnly: 'alarmOnly',
            ShowEmpty: 'showEmpty',
            ShowChart: 'showChart',
            ShowAnnotationTable: 'showAnnotationTable',
            ShowRecordsTable: 'showRecordsTable',
            IncludeStatistics: 'includeStatistics',
            StartBefore: 'startBefore',
            StartAfter: 'startAfter',
            EndBefore: 'endBefore',
            EndAfter: 'endAfter',
            IncludeSystemNotifications: 'includeSystemNotifications',
            Filters: 'Filters',
            SourcesArray: 'sourceIds[]',
            Note: 'note',
            Model: 'model',
            Interval: 'interval',
            Offset: 'offset',
            SourceId: 'sourceId',
            All: 'all',
            Timezone: 'timezone',
            ScheduleId: 'scheduleId'
        };
        this.DeviceDebug = {
            Home: this._home_ws + '/admin/devices/monitor'
        };
        this.AdminSales = {
            Home: this._adminSalesHome,
            Analytics: this._adminSalesHome + '/analytics',
            MailingList: '/mailingList',
            Feedback: this._adminSalesHome + '/feedback'
        };
        this.AdminTiers = {
            Home: this._adminTiersHome,
            AllFeatures: this._adminTiersHome + '/allFeatures',
            Requests: this._adminTiersHome + '/requests',
            Company: {
                Home: this._adminCompanyTiersHome,
                Keys: {
                    Tiers: '/tiers',
                    Active: '/tiers/active',
                    Activate: '/activate',
                    Deactivate: '/deactivate'
                }
            }
        };
        this.AdminSystem = {
            Home: this._adminSystemHome,
            Settings: this._adminSystemHome + '/settings',
            Tasks: this._adminSystemHome + '/tasks',
            Keys: {
                Running: '/running',
                Jobs: 'execute/jobs',
                Execute: 'execute'
            }
        };
    }
}
export default new ApiLinks();
