var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.location.detail.msg.file_download_info")
                    ) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "section-header-level" }, [
        _c("h3", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.loggers_expiration.title")) + " "
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          staticClass: "expiration-table",
          attrs: {
            data: _vm.companyLoggerExpirationList,
            "default-sort": "logger.calibration.expiresAt",
            "default-sort-direction": "asc",
            "mobile-cards": false,
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("div", { staticClass: "has-text-centered" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("component.loggers_expiration.message_empty")
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "logger.serialNumber",
              label: _vm.$t("component.logger.list.table.serialNumber"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "text-wrap-anywhere",
                        attrs: {
                          to: {
                            name: "logger_detail",
                            params: {
                              locationId: props.row.id,
                              departmentId: _vm.$route.params.departmentId,
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(props.row.logger.serialNumber) + " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "name",
              label: _vm.$t("component.logger.list.table.name"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    props.row.name
                      ? _c("span", { staticClass: "text-wrap-anywhere" }, [
                          _vm._v(" " + _vm._s(props.row.name) + " "),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "logger.calibration.status.name",
              label: _vm.$t("loggers.calibration"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      { staticClass: "is-flex is-align-items-center" },
                      [
                        props.row.logger.calibration
                          ? _c(
                              "b-tag",
                              {
                                staticClass: "desktop-tag",
                                attrs: {
                                  rounded: "",
                                  type: props.row.logger.calibration.status
                                    .type,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `calibration_status.${props.row.logger.calibration.status.name}`
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        props.row.logger.calibration
                          ? _c("b-tag", {
                              staticClass: "mobile-tag",
                              attrs: {
                                rounded: "",
                                type: props.row.logger.calibration.status.type,
                                icon: _vm.calibrationIcon(
                                  props.row.logger.calibration.status.name
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm.$ability.can(
                          _vm.$permActions.READ,
                          _vm.$permSubjects.LOGGER
                        ) &&
                        _vm.hasCalibrationProtocolToDownload(props.row.logger)
                          ? [
                              _c(
                                "b-tooltip",
                                {
                                  staticClass: "is-hidden-mobile",
                                  attrs: {
                                    label: _vm.$t("generals.download"),
                                    position: "is-right",
                                    type: "is-light",
                                  },
                                },
                                [
                                  !_vm.isDemo
                                    ? _c("b-icon", {
                                        staticClass: "control-icon clickable",
                                        attrs: { icon: "file-download" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.downloadCalibration(
                                              props.row.logger.companyLoggerId
                                            )
                                          },
                                        },
                                      })
                                    : _c(
                                        "a",
                                        {
                                          staticClass: "is-black",
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadSample()
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass:
                                              "control-icon clickable",
                                            attrs: { icon: "file-download" },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "is-hidden-tablet" },
                                [
                                  !_vm.isDemo
                                    ? _c("b-icon", {
                                        staticClass: "control-icon clickable",
                                        attrs: { icon: "file-download" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.downloadCalibration(
                                              props.row.logger.companyLoggerId
                                            )
                                          },
                                        },
                                      })
                                    : _c(
                                        "a",
                                        {
                                          staticClass: "is-black",
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadSample()
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass:
                                              "control-icon clickable",
                                            attrs: { icon: "file-download" },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "logger.calibration.expiresAt",
              label: _vm.$t("loggers.expiration_date"),
              sortable: "",
              numeric: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(
                            props.row.logger.calibration.expiresAt * 1000
                          ),
                          null,
                          null,
                          ""
                        )
                      ) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }