import { __decorate } from "tslib";
import { IssueType } from '@/entities/enums/IssueType';
import { isDepAcknowledged, fineState, getIssueByType } from '@/entities/models/DepartmentIssue';
import LoggedUserManager from '@/services/LoggedUserManager';
import { Component, Prop, Vue } from 'vue-property-decorator';
let OrganizationStatus = class OrganizationStatus extends Vue {
    constructor() {
        super(...arguments);
        this.IssueType = IssueType;
    }
    get isAcknowledged() {
        return isDepAcknowledged(this.issuesSummary);
    }
    get fineState() {
        return fineState(this.issuesSummary);
    }
    get isAdmin() {
        return LoggedUserManager.isAdmin();
    }
    getIssueByType(type, alarmLevel = null) {
        return getIssueByType(type, alarmLevel, this.issuesSummary);
    }
};
__decorate([
    Prop({ type: Array })
], OrganizationStatus.prototype, "issuesSummary", void 0);
OrganizationStatus = __decorate([
    Component
], OrganizationStatus);
export default OrganizationStatus;
