var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tile is-parent" }, [
      !_vm.currentImage && !_vm.imageConfirmed
        ? _c(
            "div",
            { staticClass: "tile is-child box" },
            [
              _vm.isUploading
                ? _c(
                    "div",
                    { staticClass: "is-flex is-justify-content-center mb-2" },
                    [
                      _c("b-button", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "is-primary",
                          expanded: "",
                          label: _vm.$t("component.thermal_map.confirm_upload"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.saveImage()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-upload",
                {
                  attrs: { accept: ".svg", "drag-drop": "", expanded: "" },
                  on: { input: _vm.setImage },
                  model: {
                    value: _vm.imageFile,
                    callback: function ($$v) {
                      _vm.imageFile = $$v
                    },
                    expression: "imageFile",
                  },
                },
                [
                  _c("section", { staticClass: "section" }, [
                    _c("div", { staticClass: "content has-text-centered" }, [
                      _c(
                        "p",
                        [
                          _c("b-icon", {
                            attrs: { icon: "upload", size: "is-large" },
                          }),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.isUploading
                              ? _vm.$t("component.floorPlan.replace")
                              : _vm.$t("component.floorPlan.upload")
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              !_vm.isReading && _vm.image && _vm.image.src
                ? _c("b-image", {
                    attrs: {
                      src: _vm.image.src,
                      alt: "map file",
                      responsive: "",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "tile is-child box columns" }, [
            _c(
              "div",
              { staticClass: "column is-8", attrs: { id: "svgContainer" } },
              [_c("svg", { attrs: { id: "svgElement" } })]
            ),
            _c("div", { staticClass: "column" }, [
              _c(
                "div",
                [
                  _c("h2", { staticClass: "subtitle has-text-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("component.thermal_map.settings"))),
                  ]),
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: _vm.$t(
                          "component.report.configuration.modal.from"
                        ),
                        type: {
                          "is-danger": _vm.errors.has(
                            _vm.$t("component.report.configuration.modal.from")
                          ),
                        },
                        message: _vm.errors.first(
                          _vm.$t("component.report.configuration.modal.from")
                        ),
                      },
                    },
                    [
                      _c("b-datetimepicker", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          rounded: "",
                          name: _vm.$t(
                            "component.report.configuration.modal.from"
                          ),
                          placeholder: _vm.$t(
                            "component.report.month_picker.placeholder"
                          ),
                          "icon-right": _vm.dateTimeFrom ? "close-circle" : "",
                          "icon-right-clickable": "",
                          "first-day-of-week": 1,
                          "horizontal-time-picker": "",
                          "mobile-native": false,
                          position: "is-bottom-left",
                          timepicker: { hourFormat: _vm.hourFormat },
                          "datetime-formatter": function (date) {
                            return _vm.dateTimeManager.formatDateTimeInInput(
                              date
                            )
                          },
                        },
                        on: { "icon-right-click": _vm.clearDateTimeFrom },
                        model: {
                          value: _vm.dateTimeFrom,
                          callback: function ($$v) {
                            _vm.dateTimeFrom = $$v
                          },
                          expression: "dateTimeFrom",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: _vm.$t(
                          "component.report.configuration.modal.to"
                        ),
                        type: {
                          "is-danger": _vm.errors.has(
                            _vm.$t("component.report.configuration.modal.to")
                          ),
                        },
                        message: _vm.errors.first(
                          _vm.$t("component.report.configuration.modal.to")
                        ),
                      },
                    },
                    [
                      _c("b-datetimepicker", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          rounded: "",
                          name: _vm.$t(
                            "component.report.configuration.modal.to"
                          ),
                          placeholder: _vm.$t(
                            "component.report.month_picker.placeholder"
                          ),
                          "icon-right": _vm.dateTimeTo ? "close-circle" : "",
                          "icon-right-clickable": "",
                          "first-day-of-week": 1,
                          "horizontal-time-picker": "",
                          "mobile-native": false,
                          position: "is-bottom-left",
                          timepicker: { hourFormat: _vm.hourFormat },
                          "datetime-formatter": function (date) {
                            return _vm.dateTimeManager.formatDateTimeInInput(
                              date
                            )
                          },
                        },
                        on: { "icon-right-click": _vm.clearDateTimeTo },
                        model: {
                          value: _vm.dateTimeTo,
                          callback: function ($$v) {
                            _vm.dateTimeTo = $$v
                          },
                          expression: "dateTimeTo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("h2", { staticClass: "subtitle" }, [
                        _vm._v(_vm._s(_vm.$t("component.thermal_map.loggers"))),
                      ]),
                      _vm.layoutData != null
                        ? _vm._l(_vm.layoutData.sensors, function (sensor) {
                            return _c(
                              "div",
                              { key: sensor.id },
                              [
                                _c(
                                  "b-field",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "component.thermal_map.logger_label",
                                        { x: sensor.index + 1 }
                                      ),
                                      type: {
                                        "is-danger":
                                          _vm.hasDuplicatedSourceAssigned(
                                            sensor.id
                                          ),
                                      },
                                      message: _vm.hasDuplicatedSourceAssigned(
                                        sensor.id
                                      )
                                        ? _vm.$t(
                                            "component.thermal_map.can_be_assigned_only_once"
                                          )
                                        : "",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "component.thermal_map.loggers_placeholder"
                                          ),
                                        },
                                        model: {
                                          value: sensor.assignedSourceId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              sensor,
                                              "assignedSourceId",
                                              $$v
                                            )
                                          },
                                          expression: "sensor.assignedSourceId",
                                        },
                                      },
                                      _vm._l(_vm.sources, function (source) {
                                        return _c(
                                          "option",
                                          {
                                            key: source.id,
                                            domProps: { value: source.id },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(source.name) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("h2", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("component.thermal_map.display_settings"))
                    ),
                  ]),
                  _c("h3", { staticClass: "subtitle" }, [
                    _vm._v(_vm._s(_vm.$t("component.thermal_map.color_range"))),
                  ]),
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: _vm.$t("component.thermal_map.min_value"),
                      },
                    },
                    [
                      _c("b-numberinput", {
                        attrs: { "controls-position": "compact" },
                        on: {
                          input: function ($event) {
                            return _vm.updateRenderSettings($event)
                          },
                        },
                        model: {
                          value: _vm.renderSettings.minTemp,
                          callback: function ($$v) {
                            _vm.$set(_vm.renderSettings, "minTemp", $$v)
                          },
                          expression: "renderSettings.minTemp",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: _vm.$t("component.thermal_map.max_value"),
                      },
                    },
                    [
                      _c("b-numberinput", {
                        attrs: { "controls-position": "compact" },
                        on: {
                          input: function ($event) {
                            return _vm.updateRenderSettings($event)
                          },
                        },
                        model: {
                          value: _vm.renderSettings.maxTemp,
                          callback: function ($$v) {
                            _vm.$set(_vm.renderSettings, "maxTemp", $$v)
                          },
                          expression: "renderSettings.maxTemp",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "is-flex is-flex-direction-column" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-4",
                          attrs: { type: "is-primary" },
                          on: {
                            click: function ($event) {
                              return _vm.saveConfigurationAndLoadData()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("component.thermal_map.load")))]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            type: "is-primary",
                            tag: "a",
                            href: _vm.link,
                            "icon-pack": "fa",
                            "icon-left": "file",
                            download: "Map",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("component.thermal_map.download"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }