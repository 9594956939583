var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("h4", { staticClass: "is-size-4 mb-4" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "admin.component.company.detail.registerLoggers.availableLoggers"
            )
          )
        ),
      ]),
      _c(
        "b-field",
        [
          _c(
            "b-switch",
            {
              staticClass: "is-justify-content-flex-end",
              model: {
                value: _vm.sourceCreationEnabled,
                callback: function ($$v) {
                  _vm.sourceCreationEnabled = $$v
                },
                expression: "sourceCreationEnabled",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "admin.component.company.registerCompanyLoggersModal.createSources"
                    )
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c("b-message", { staticClass: "mb-4", attrs: { type: "is-warning" } }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "admin.component.company.registerCompanyLoggersModal.createSourcesMessage"
              )
            ) +
            " "
        ),
      ]),
      _c(
        "b-table",
        {
          staticClass: "select-location-table border select-user-table",
          attrs: {
            data: _vm.adminLoggers,
            "checked-rows": _vm.selectedLoggers,
            paginated: true,
            "backend-pagination": "",
            "current-page": _vm.page,
            "per-page": _vm.pageSize,
            total: _vm.totalSize,
            loading: _vm.componentIsLoading,
            narrowed: "",
            checkable: "",
            "mobile-cards": false,
            "backend-filtering": "",
            "row-class": (row) =>
              row.state === _vm.LoggerStates.PUBLISHED
                ? ""
                : "is-disabled has-text-grey",
            "is-row-checkable": (row) =>
              row.state === _vm.LoggerStates.PUBLISHED,
            "custom-is-checked": (a, b) => {
              return a.id === b.id
            },
          },
          on: {
            "update:checkedRows": function ($event) {
              _vm.selectedLoggers = $event
            },
            "update:checked-rows": function ($event) {
              _vm.selectedLoggers = $event
            },
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "page-change": _vm.onPageChange,
            check: _vm.selectLoggers,
          },
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "LoggerId",
              label: _vm.$t(
                "admin.component.company.registerCompanyLoggersModal.table.id"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (adminLoggers) {
                  return [_c("p", [_vm._v(_vm._s(adminLoggers.row.id))])]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "SerialNumber",
              label: _vm.$t(
                "admin.component.company.registerCompanyLoggersModal.table.serialNumber"
              ),
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "buttons mb-1" },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "admin.component.company.registerCompanyLoggersModal.table.serialNumber"
                            ),
                            size: "is-small",
                          },
                          on: { input: _vm.handleFilterSerialNumberDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterLoggers(_vm.serialNumber)
                            },
                          },
                          model: {
                            value: _vm.serialNumber,
                            callback: function ($$v) {
                              _vm.serialNumber =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "serialNumber",
                          },
                        }),
                        _c("b-icon", {
                          staticClass: "level-item clickable",
                          attrs: { icon: "search" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.filterLoggers(_vm.serialNumber)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (adminLoggers) {
                  return [
                    adminLoggers.row.currentlyPublishedAs &&
                    adminLoggers.row.currentlyPublishedAs.serialNumber
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                adminLoggers.row.currentlyPublishedAs
                                  .serialNumber
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _vm.selectedLoggers.length > 0 && _vm.sourceCreationEnabled
            ? _c("b-table-column", {
                attrs: {
                  field: "name",
                  label: _vm.$t(
                    "admin.component.company.registerCompanyLoggersModal.table.name"
                  ),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (adminLoggers) {
                        return [
                          _vm.getSelectedLogger(adminLoggers.row)
                            ? _c(
                                "b-field",
                                {
                                  attrs: {
                                    type: {
                                      "is-danger": _vm.getSelectedLogger(
                                        adminLoggers.row
                                      ).additionalProperties.hasErrorField,
                                    },
                                    message: _vm.getSelectedLogger(
                                      adminLoggers.row
                                    ).additionalProperties.errorMsg,
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      name:
                                        _vm.$t(
                                          "component.logger.assignSourceList.list.table.name"
                                        ) +
                                        _vm
                                          .getSelectedLoggerIndex(
                                            adminLoggers.row
                                          )
                                          .toString(),
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.checkName(
                                          _vm.getSelectedLogger(
                                            adminLoggers.row
                                          ),
                                          _vm.getSelectedLoggerIndex(
                                            adminLoggers.row
                                          )
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.getSelectedLogger(
                                        adminLoggers.row
                                      ).additionalProperties.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.getSelectedLogger(
                                            adminLoggers.row
                                          ).additionalProperties,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "getSelectedLogger(adminLoggers.row).additionalProperties.name",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  356637291
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("h4", { staticClass: "is-size-4 my-4" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "admin.component.company.detail.registerLoggers.choosedLoggers"
            )
          )
        ),
      ]),
      _c(
        "b-table",
        {
          staticClass: "select-location-table border select-user-table-list",
          attrs: { data: _vm.selectedLoggers, striped: "" },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.company.registerCompanyLoggersModal.table.empty"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "id",
              label: _vm.$t(
                "admin.component.company.registerCompanyLoggersModal.table.id"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (adminLoggers) {
                  return [
                    _c("p", [_vm._v(" " + _vm._s(adminLoggers.row.id) + " ")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "serialNumber",
              label: _vm.$t(
                "admin.component.company.registerCompanyLoggersModal.table.serialNumber"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (adminLoggers) {
                  return [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            adminLoggers.row.currentlyPublishedAs.serialNumber
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }