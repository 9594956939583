import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import { PdfReportTypes } from '@/entities/enums/ReportTypes';
import { ReportConfiguration } from '@/entities/models/ReportConfiguration';
import StatisticsType from '@/entities/enums/statisticsType';
import PeriodicReportPdfType from '@/entities/enums/PeriodicReportPdfType';
import ReportingInterval, { defaultReportingIntervals, getAllAvailableMeasureTimes } from '@/entities/enums/ReportingInterval';
import ReportRecordRepository from '@/services/repository/PeriodicReportsRepository';
var reportRecordRepository;
let ReportSettingsModal = class ReportSettingsModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.PdfScope = PdfScope;
        this.RecordsTableTypes = RecordsTableTypes;
        this.PdfReportTypes = PdfReportTypes;
        this.selectedEntity = PdfScope.LOGGER;
        this.selectedSourceGroups = [];
        this.selectedSources = [];
        this.selectedRecordsTableType = RecordsTableTypes.OUT_OF;
        this.showStatistics = true;
        this.today = new Date();
        this.selectedMode = PdfReportTypes.FULL;
        this.selectedPeriodic = PeriodicReportPdfType.MONTHLY;
        this.monthFilter = null;
        this.interval = null;
        this.offset = 0;
        this.measurementOffsetIndex = 0;
        this.allMeasuredTimes = [];
        this.StatisticsType = StatisticsType;
        this.PeriodicReportPdfType = PeriodicReportPdfType;
        this.selectedType = StatisticsType.AVERAGE;
        this.selectedEventTypes = [];
        this.visibleEventTypes = [];
        this.hourFormatInClockPicker = null;
        this.timeOfMeasurement = new Date();
        this.currentShowEmpty = null;
        this.selectedUser = null;
        this.selectedUserFullName = '';
    }
    get allReportingIntervals() {
        return defaultReportingIntervals;
    }
    get canHaveOffset() {
        return this.selectedType === StatisticsType.DAILY;
    }
    get hasOneDayIntervalSelected() {
        return this.interval === ReportingInterval.INTERVAL_24_00;
    }
    created() {
        this.loadData();
    }
    get filteredUsers() {
        return this.userList.filter((user) => user.username
            ?.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.selectedUserFullName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')));
    }
    changeUserName(user) {
        this.selectedUser = user;
        if (this.selectedUser)
            this.selectedUserFullName = this.selectedUser.username;
    }
    loadData() {
        reportRecordRepository = new ReportRecordRepository(this);
        if (this.isAdmin)
            this.changeUserName(this.userList.find((x) => x.apiUserId == this.selectedPeriodicReport?.author.apiUserId));
        if (this.isPeriodicReportEdit) {
            this.selectedPeriodicReport?.sources.forEach((source) => {
                let selectedSource = this.sources.find((x) => x.id == source.id);
                this.selectedSources.push(selectedSource);
            });
        }
        this.selectedPeriodic =
            this.selectedPeriodicReport?.periodicReportPdfType.toLowerCase() ||
                PeriodicReportPdfType.MONTHLY;
        this.showChart = this.selectedPeriodicReport?.toggles?.showChart;
        this.showAnnotations = this.selectedPeriodicReport?.toggles?.showAnnotationsTable;
        this.currentShowEmpty = this.selectedPeriodicReport?.toggles?.showEmpty;
        this.showStatistics = this.selectedPeriodicReport?.toggles?.showStatistics || true;
        this.interval =
            this.selectedPeriodicReport?.interval ||
                this.configuration.interval ||
                ReportConfiguration.getDefaultConfiguration().interval;
        if (typeof this.selectedPeriodicReport?.eventTypes === 'object') {
            this.selectedEventTypes = this.selectedPeriodicReport?.eventTypes || [];
        }
        else {
            this.selectedEventTypes = [];
        }
        if (this.selectedPeriodicReport?.toggles?.showRecordsTable && this.selectedPeriodicReport?.toggles?.alarmOnly)
            this.selectedRecordsTableType = RecordsTableTypes.OUT_OF;
        else if (this.selectedPeriodicReport?.toggles?.showRecordsTable && !this.selectedPeriodicReport?.toggles?.alarmOnly)
            this.selectedRecordsTableType = RecordsTableTypes.ALL;
        else
            this.selectedRecordsTableType = RecordsTableTypes.NONE;
        if (this.selectedPeriodicReport?.toggles?.showRecordsTable === null ||
            this.selectedPeriodicReport?.toggles?.alarmOnly === null)
            this.selectedRecordsTableType = RecordsTableTypes.OUT_OF;
        this.selectedType = this.formatStatModel(this.selectedPeriodicReport);
        this.hourFormatInClockPicker = this.dateTimeManager.getSelectedClockFormat().slice(0, 2);
        if (this.selectedType == StatisticsType.DAILY) {
            this.offset = this.selectedPeriodicReport?.offset || this.configuration.getModel().modelParameters.Offset;
        }
        this.assignMeasureTimes(this.interval).then(() => {
            this.measurementOffsetIndex = this.offset / 60;
        });
        this.measuredOffsetChanged();
        if (this.hasOneDayIntervalSelected == true) {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMinutes(this.selectedPeriodicReport?.offset || this.configuration.getModel().modelParameters.Offset);
            this.timeOfMeasurement = date;
        }
        this.visibleEventTypes = this.eventTypes.map((x) => x.name);
    }
    formatStatModel(periodicReport) {
        if (periodicReport && periodicReport.statModels.findIndex((x) => x.includes('daily')) !== -1)
            return StatisticsType.DAILY;
        else
            return StatisticsType.AVERAGE;
    }
    async configSettings() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let sourceIds = [];
                if (this.selectedEntity == PdfScope.LOGGER) {
                    if (this.selectedSources.length <= 0) {
                        VueUtilities.openErrorToast(this, this.$t('reports.report_settings.report_config_modal.configuration.select_entity'));
                        return;
                    }
                    sourceIds = this.selectedSources.map((x) => x.id);
                }
                else {
                    if (this.selectedSourceGroups.length <= 0) {
                        VueUtilities.openErrorToast(this, this.$t('reports.report_settings.report_config_modal.configuration.select_entity'));
                        return;
                    }
                    let groupSourcesIds = this.selectedSourceGroups.flatMap((x) => x.sources.map((y) => y.id));
                    sourceIds = sourceIds.concat(groupSourcesIds);
                }
                let config = {
                    SourceIds: sourceIds,
                    AuthorId: this.isAdmin ? this.selectedUser.apiUserId : this.currentUser.apiUserId,
                    PeriodicReportPdfType: this.selectedPeriodic,
                    Toggles: {
                        showChart: this.showChart,
                        alarmOnly: this.selectedRecordsTableType == RecordsTableTypes.OUT_OF,
                        showAnnotationsTable: this.showAnnotations,
                        showEmpty: this.currentShowEmpty,
                        showStatistics: this.showStatistics,
                        showRecordsTable: this.selectedRecordsTableType == RecordsTableTypes.OUT_OF ||
                            this.selectedRecordsTableType == RecordsTableTypes.ALL
                    },
                    StatModels: [this.selectedType],
                    Offset: this.offset,
                    Interval: this.interval,
                    EventTypes: this.selectedEventTypes,
                    Timezone: this.dateTimeManager.getSelectedTimezone()
                };
                this.isLoading = true;
                let result = null;
                if (this.isPeriodicReportEdit) {
                    result = await reportRecordRepository.putPeriodicReport(this.selectedPeriodicReport.id, config);
                }
                else {
                    result = await reportRecordRepository.postPeriodicReport(config);
                }
                if (result == true) {
                    VueUtilities.openSuccessToast(this, (this.$t(this.isPeriodicReportEdit
                        ? 'reports.report_settings.report_config_modal.configuration.update_success'
                        : 'reports.report_settings.report_config_modal.configuration.success')));
                    this.resultRetrieved(true);
                    this.$emit('reload');
                }
                else {
                    VueUtilities.openErrorToast(this, (this.$t(this.isPeriodicReportEdit
                        ? 'reports.report_settings.report_config_modal.configuration.update_failure'
                        : 'reports.report_settings.report_config_modal.configuration.failure')));
                }
                this.isLoading = false;
            }
        });
    }
    /**
     * Listener for reporting interval change
     */
    async reportingIntervalChanged(step) {
        if (this.hasOneDayIntervalSelected == true) {
            this.clockChanged(this.timeOfMeasurement);
        }
        else {
            this.measurementOffsetIndex = 0;
            this.measuredOffsetChanged();
            this.assignMeasureTimes(step);
        }
    }
    /**
     * Create array with all allowed measure times for selected interval
     */
    async assignMeasureTimes(step) {
        let allTimes = await getAllAvailableMeasureTimes(this, step);
        this.allMeasuredTimes = allTimes;
    }
    /**
     * change listener for offset
     */
    measuredOffsetChanged() {
        if (this.hasOneDayIntervalSelected == true)
            return;
        this.offset = this.measurementOffsetIndex * 60;
    }
    clockChanged(value) {
        if (this.hasOneDayIntervalSelected == false)
            return;
        this.offset = value.getHours() * 60 + value.getMinutes();
    }
    typeChanged() {
        this.measurementOffsetIndex = 0;
        this.measuredOffsetChanged();
        this.assignMeasureTimes(this.interval);
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    get hasTemp() {
        return this.visibleEventTypes.findIndex((element) => element.includes('Temperature'));
    }
    get hasHum() {
        return this.visibleEventTypes.findIndex((element) => element.includes('Humidity'));
    }
    entitySelected(selected) { }
};
__decorate([
    Prop({ type: Object })
], ReportSettingsModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportSettingsModal.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportSettingsModal.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportSettingsModal.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Object })
], ReportSettingsModal.prototype, "configuration", void 0);
__decorate([
    Prop({ type: Object })
], ReportSettingsModal.prototype, "selectedPeriodicReport", void 0);
__decorate([
    Prop({ type: Object })
], ReportSettingsModal.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Array })
], ReportSettingsModal.prototype, "userList", void 0);
__decorate([
    Prop({ type: Boolean })
], ReportSettingsModal.prototype, "isAdmin", void 0);
__decorate([
    Prop({ type: Boolean })
], ReportSettingsModal.prototype, "isPeriodicReportEdit", void 0);
ReportSettingsModal = __decorate([
    Component({ components: {} })
], ReportSettingsModal);
export default ReportSettingsModal;
var PdfScope;
(function (PdfScope) {
    PdfScope["LOGGER"] = "logger";
    PdfScope["GROUP"] = "group";
})(PdfScope || (PdfScope = {}));
var RecordsTableTypes;
(function (RecordsTableTypes) {
    RecordsTableTypes["ALL"] = "all";
    RecordsTableTypes["OUT_OF"] = "only_out_of";
    RecordsTableTypes["NONE"] = "none";
})(RecordsTableTypes || (RecordsTableTypes = {}));
