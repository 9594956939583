var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table",
        {
          staticClass: "records-table",
          attrs: {
            data: _vm.arrayForTable,
            paginated: "",
            "pagination-position": "both",
            "per-page": _vm.pageSize,
            total: _vm.records.length,
            "current-page": _vm.page,
            "mobile-cards": false,
            scrollable: true,
            checkable: _vm.annotationAllowed,
            "checked-rows": _vm.checkedTableObjectsArray,
            "is-row-checkable": function (row) {
              return row.recordType === _vm.TableObjectType.RECORD
            },
            detailed: true,
            "opened-detailed": [],
            "detail-key": "id",
            "show-detail-icon": true,
            "has-detailed-visible": function (row, index) {
              return row.recordType === _vm.TableObjectType.ANNOTATION
                ? true
                : false
            },
          },
          on: {
            "page-change": _vm.onPageChange,
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "update:checkedRows": function ($event) {
              _vm.checkedTableObjectsArray = $event
            },
            "update:checked-rows": function ($event) {
              _vm.checkedTableObjectsArray = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "detail",
              fn: function (props) {
                return [
                  _c(
                    "h3",
                    { staticClass: "is-size-6 has-text-weight-bold pb-1" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.report.record_table.records_in_annotation"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "b-table",
                    { attrs: { data: props.row.entity.points } },
                    [
                      _c("b-table-column", {
                        attrs: { label: _vm.$t("services.time") },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (recordObject) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dateTimeManager.formatTime(
                                          new Date(recordObject.row.time),
                                          null,
                                          null,
                                          null
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("b-table-column", {
                        attrs: {
                          label: _vm.$t("component.report.record_table.values"),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (recordObject) {
                                return _vm._l(
                                  recordObject.row.values,
                                  function (val) {
                                    return _c("p", { key: val }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("services." + val.eventType)
                                        ) +
                                          " " +
                                          _vm._s(val.val)
                                      ),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("b-table-column", {
                        attrs: {
                          label: _vm.$t("component.report.record_table.type"),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (recordObject) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(recordObject.row.type) + " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: { label: _vm.$t("services.time") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (record) {
                  return [
                    record.row.recordType === _vm.TableObjectType.RECORD
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dateTimeManager.formatTime(
                                  new Date(record.row.entity.time),
                                  null,
                                  null,
                                  null
                                )
                              )
                          ),
                        ])
                      : record.row.recordType === _vm.TableObjectType.ANNOTATION
                      ? _c("span", { staticClass: "has-text-weight-bold" }, [
                          _vm._v(" " + _vm._s(record.row.entity.timeString)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              label: _vm.$t("component.report.record_table.statistics"),
              visible: false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (record) {
                  return [
                    record.row.recordType === _vm.TableObjectType.RECORD
                      ? _c("span")
                      : record.row.recordType === _vm.TableObjectType.ANNOTATION
                      ? _c(
                          "div",
                          [
                            _vm.annotationsForSource.find(function (x) {
                              return x.id == record.row.entity.annotationId
                            })
                              ? _c("AnnotationStatistics", {
                                  attrs: {
                                    statistics: _vm.annotationsForSource.find(
                                      function (x) {
                                        return (
                                          x.id == record.row.entity.annotationId
                                        )
                                      }
                                    ).statistics,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: { label: _vm.$t("component.report.record_table.values") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (record) {
                  return [
                    record.row.recordType === _vm.TableObjectType.RECORD
                      ? _c("span", [
                          _vm.services.length <= 0 ||
                          record.row.entity.values.length <= 0
                            ? _c(
                                "span",
                                { staticClass: "is-italic has-text-grey" },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "reports.record_without_val_expl_note"
                                        ),
                                        position: "is-left",
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "mr-1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.record_without_val_label"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("b-icon", {
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                          type: "is-grey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                _vm._l(
                                  record.row.entity.values,
                                  function (val) {
                                    return _c("p", { key: val.eventType }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("services." + val.eventType)
                                          ) +
                                          " " +
                                          _vm._s(val.val) +
                                          " "
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                        ])
                      : record.row.recordType === _vm.TableObjectType.ANNOTATION
                      ? _c(
                          "span",
                          [
                            _vm._v(" " + _vm._s(record.row.entity.value) + " "),
                            _vm.annotationAllowed
                              ? _c("b-icon", {
                                  staticClass: "control-icon clickable",
                                  attrs: { icon: "pencil-alt" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.editAnnotation(record.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.annotationAllowed
                              ? _c("b-icon", {
                                  staticClass: "control-icon clickable",
                                  attrs: { icon: "trash" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.deleteAnnotation(record.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: { label: _vm.$t("component.report.record_table.author") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (record) {
                  return [
                    record.row.recordType === _vm.TableObjectType.RECORD
                      ? _c("span")
                      : record.row.recordType === _vm.TableObjectType.ANNOTATION
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                record.row.entity.author
                                  ? record.row.entity.author.fullName
                                  : "-"
                              )
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "( " +
                                _vm._s(
                                  _vm.dateTimeManager.formatTime(
                                    new Date(
                                      record.row.entity.createdAt * 1000
                                    ),
                                    null,
                                    null,
                                    null
                                  )
                                ) +
                                ")"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("component.report.record_table.empty"))
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm.checkedTableObjectsArray.length > 0 && _vm.annotationAllowed
        ? _c(
            "div",
            {
              staticClass: "fixed-form-footer",
              class:
                _vm.isMenuExpanded == true
                  ? "main-column-expanded"
                  : "main-column",
            },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-success" },
                  on: {
                    click: function ($event) {
                      return _vm.createAnnotation()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("component.report.record_table.actions.create")
                    )
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$ability.can(_vm.$permActions.ANNOTATE, _vm.$permSubjects.REPORTS) &&
      _vm.annotationAllowed
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isCreateModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeCreateModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isCreateModalActive = $event
                },
                "close-modal": _vm.closeCreateModal,
              },
            },
            [
              _vm.selectedReportRecords
                ? _c("CreateAnnotationModal", {
                    attrs: {
                      dateTimeManager: _vm.dateTimeManager,
                      selectedRecordsToAnnotate: _vm.selectedReportRecords,
                    },
                    on: { modalClosed: _vm.closeCreateModal },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.$ability.can(_vm.$permActions.ANNOTATE, _vm.$permSubjects.REPORTS) &&
      _vm.annotationAllowed
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isEditModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeEditModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isEditModalActive = $event
                },
                "close-modal": _vm.closeEditModal,
              },
            },
            [
              _vm.selectedTableObject
                ? _c("EditAnnotationModal", {
                    attrs: {
                      annotation: _vm.selectedTableObject.entity.rawAnnotation,
                      timeRange: _vm.selectedTableObject.entity.timeString,
                    },
                    on: { modalClosed: _vm.closeEditModal },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.$ability.can(_vm.$permActions.ANNOTATE, _vm.$permSubjects.REPORTS) &&
      _vm.annotationAllowed
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isDeleteModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeDeleteModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isDeleteModalActive = $event
                },
                "close-modal": _vm.closeDeleteModal,
              },
            },
            [
              _vm.selectedTableObject
                ? _c("DeleteAnnotationModal", {
                    attrs: {
                      annotation: _vm.selectedTableObject.entity.rawAnnotation,
                      timeRange: _vm.selectedTableObject.entity.timeString,
                    },
                    on: { modalClosed: _vm.closeDeleteModal },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }