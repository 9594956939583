import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
export default class AuthenticationCalls {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async register(dto) {
        let url = ApiLinks.Auth.Register;
        let response = await AxiosService.postDataToServer(url, dto, this.ctx);
        if (response?.status == ApiStatus.CREATED)
            return true;
        else
            return response;
    }
    async login(name, pswd) {
        let params = this.createLoginParams(name, pswd);
        let response = await AxiosService.postDataToServer(ApiLinks.Auth.Login, params, this.ctx, false);
        if (response?.status == ApiStatus.SUCCESS) {
            let loginData = {
                apiUserFullName: response.data.ApiUserFullName,
                apiUserEmail: response.data.ApiUserEmail,
                apiUserUsername: response.data.ApiUserUsername,
                session: response.data.SessionPassword,
                expires: response.data.Expires,
                created: response.data.Created,
                sessionId: response.data.SessionId,
                role: response.data.Role,
                isAdmin: response.data.IsAdmin
            };
            return loginData;
        }
        else if (response?.data)
            return response.data;
        else
            return ApiResponseCodes.UNAUTHORIZED;
    }
    async logout() {
        let response = await AxiosService.postDataToServer(ApiLinks.Auth.Logout, null, this.ctx, false);
        return response?.status == ApiStatus.SUCCESS;
    }
    async checkToken(token) {
        const url = ApiLinks.Auth.TokenCheck;
        let data = new FormData();
        data.set('token', token);
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        if (response?.status == ApiStatus.SUCCESS) {
            return response.data?.data;
        }
        else
            return null;
    }
    async resendVerificationEmail(email) {
        let data = new FormData();
        data.set('email', email);
        let response = await AxiosService.postDataToServer(ApiLinks.Auth.VerifyResend, data, this.ctx, false);
        return response?.status == ApiStatus.SUCCESS;
    }
    async verifyAccount(token, jwtToken, password) {
        let url = ApiLinks.Auth.Verify;
        let data = new FormData();
        if (token) {
            let queries = new ApiQueries();
            queries.token = token;
            url += `?${queries.toQueryString()}`;
        }
        else {
            data.set('jwtToken', jwtToken);
            data.set('password', password);
        }
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        return response?.status == ApiStatus.SUCCESS;
    }
    async passwordForgot(email) {
        let url = ApiLinks.Auth.PasswordForgot;
        let data = new FormData();
        data.set('email', email);
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        return response?.status == ApiStatus.SUCCESS;
    }
    async passwordChange(token, password, password_check) {
        let url = ApiLinks.Auth.PasswordChange;
        if (token) {
            let queries = new ApiQueries();
            queries.token = token;
            url += `?${queries.toQueryString()}`;
        }
        let data = new FormData();
        data.set('password', password);
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        if (response?.status == ApiStatus.SUCCESS) {
            return true;
        }
        else if (response?.data?.code == ApiResponseCodes.WRONG_TOKEN) {
            return false;
        }
        else {
            return null;
        }
    }
    async changeCurrentUserPassword(oldPassword, newPassword) {
        let url = `${ApiLinks.Profile.Me}${ApiLinks.Profile.ChangePassword}`;
        let data = new FormData();
        data.set('oldPassword', oldPassword);
        data.set('newPassword', newPassword);
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        return response?.status == ApiStatus.SUCCESS;
    }
    createLoginParams(name, pswd) {
        const params = new FormData();
        params.append('login', name);
        params.append('password', pswd);
        return params;
    }
    async deleteUserSessions() {
        let url = ApiLinks.Users.Sessions;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        return response?.status == ApiStatus.NO_CONTENT;
    }
}
export function isLoginData(data) {
    return data.sessionId !== undefined;
}
