import Vue from 'vue';
import Router from 'vue-router';
import i18n from '@/lang/lang';
import Login from './views/pages/authentication/Login.vue';
import DemoLogin from './views/pages/authentication/DemoLogin.vue';
import Register from './views/pages/authentication/Register.vue';
import RegistrationSuccessful from './views/pages/authentication/RegistrationSuccessful.vue';
import VerifyAccount from './views/pages/authentication/VerifyAccount.vue';
import VerifyResend from './views/pages/authentication/VerifyResend.vue';
import ForgotPassword from './views/pages/authentication/ForgotPassword.vue';
import ChangePassword from './views/pages/authentication/ChangePassword.vue';
import MasterDashboard from './views/pages/MasterDashboard.vue';
import GroupDashboard from './views/pages/GroupDashboard.vue';
import OrgHomePage from './views/pages/OrgHomePage.vue';
import Reports from './views/pages/Reports.vue';
import NotificationsBoard from './views/pages/NotificationsBoard.vue';
import DeviceManagement from './views/pages/DeviceManagement.vue';
import LoggerManagement from './views/pages/LoggerManagement.vue';
import NotificationsConfiguration from './views/pages/NotificationsConfiguration.vue';
import ProfileManagement from './views/pages/ProfileManagement.vue';
import GroupManagement from './views/pages/GroupManagement.vue';
import ReportManagement from './views/pages/ReportManagement.vue';
import CreateGroup from './views/pages/CreateGroup.vue';
import DeviceConf from './views/pages/DeviceSettings.vue';
import IssueDetail from './views/components/issues/IssueDetail.vue';
import LoggerDetail from './views/pages/LoggerDetail.vue';
import GroupDetail from './views/pages/GroupDetail.vue';
import Settings from './views/pages/Settings.vue';
import Wizard from './views/pages/Wizard.vue';
import CompanyManagement from './views/pages/CompanyManagement.vue';
import UserDetail from './views/pages/UserDetail.vue';
import PublicDashboardsManagement from './views/pages/PublicDashboardsManagement.vue';
import PublicDashboardDetail from './views/pages/PublicDashboardDetail.vue';
import PublicDashboardPage from './views/pages/PublicDashboardPage.vue';
import About from './views/pages/About.vue';
import Feedback from './views/pages/Feedback.vue';
import PrivacyPolicy from './views/pages/PrivacyPolicy.vue';
import ThermalMapPage from './views/pages/ThermalMapPage.vue';
import AdminCompanyManagement from './views/pages/admin/AdminCompanyManagement.vue';
import AdminCompanyDetailManagement from './views/pages/admin/AdminCompanyDetailManagement.vue';
import AdminUserManagement from './views/pages/admin/AdminUserManagement.vue';
import AdminUserDetailManagement from './views/pages/admin/AdminUserDetailManagement.vue';
import AdminDeviceManagement from './views/pages/admin/AdminDeviceManagement.vue';
import AdminDeviceDetailManagement from './views/pages/admin/AdminDeviceDetailManagement.vue';
import AdminLoggerManagement from './views/pages/admin/AdminLoggerManagement.vue';
import AdminResellerManagement from './views/pages/admin/AdminResellerManagement.vue';
import AdminResellerDetailManagement from './views/pages/admin/AdminResellerDetailManagement.vue';
import AdminLoggerDetailManagement from './views/pages/admin/AdminLoggerDetailManagement.vue';
import AdminRegisterLoggersManagement from './views/pages/admin/AdminRegisterLoggersManagement.vue';
import AdminNotificationManagement from './views/pages/admin/AdminNotificationManagement.vue';
import AdminTiersManagement from './views/pages/admin/AdminTiersManagement.vue';
import AdminTierCreate from './views/pages/admin/AdminTierCreate.vue';
import AdminTierDetail from './views/pages/admin/AdminTierDetail.vue';
import AdminFeedbackManagement from './views/pages/admin/AdminFeedbackManagement.vue';
import AdminFeedbackDetail from './views/pages/admin/AdminFeedbackDetail.vue';
import AdminPublicDashboardDetail from './views/pages/admin/AdminPublicDashboardDetail.vue';
import ManufacturingProcessPage from './views/pages/admin/ManufacturingProcessPage.vue';
import SystemSettingsManagement from './views/pages/admin/SystemSettingsManagement.vue';
import PageNotExist from './views/pages/PageNotExist.vue';
import PublicDashboardNotExist from './views/pages/PublicDashboardNotExist.vue';
import MissingPermissions from './views/pages/MissingPermissions.vue';
import LoggedUserManager from '@/services/LoggedUserManager';
import ApiUserRoles, { SystemUserRoles } from './entities/enums/UserRoles';
import UserSettingsRepository from './services/repository/UserSettingsRepository';
import AppConfig from '@/configLoader';
import CookiesStorage from './services/CookiesStorage';
import DepartmentRepository from './services/repository/DepartmentRepository';
import AdminFirmwareDetailManagement from './views/pages/admin/AdminFirmwareDetailManagement.vue';
import { vxm } from '@/store/store.vuex';
import UserRepository from './services/repository/UserRepository';
import AbilityManager from '@/services/permissions/AbilitiesManager';
import Subjects from './services/permissions/Subjects';
import { AdminAction } from './services/permissions/Actions';
import { AdminDepartment } from './entities/models/AdminDepartment';
import { FeaturesManager } from '@/entities/tiers/FeaturesManager';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import VueUtilities from './services/VueUtilities';
import ProfileOrganization from '@/views/pages/ProfileOrganization.vue';
import Invitation from '@/views/pages/Invitation.vue';
import NotificationOrganization from '@/views/pages/NotificationOrganization.vue';
var vxDepartmentStore = vxm.departmentStore;
Vue.use(Router);
let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/:lang(en|sk|cs)?',
            component: {
                render: (h) => h('router-view')
            },
            children: [
                {
                    path: '',
                    name: 'home',
                    meta: { layout: 'with-sidebar', needsLogin: true },
                    component: { render: (h) => h('router-view') }
                },
                {
                    path: 'login',
                    name: 'login',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    props: true,
                    component: Login
                },
                {
                    path: 'login/demo',
                    name: 'demoLogin',
                    meta: { layout: 'default', needsLogin: false },
                    component: DemoLogin
                },
                {
                    path: 'register',
                    name: 'register',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: Register
                },
                {
                    path: 'registrationSuccessful',
                    name: 'registrationSuccessful',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: RegistrationSuccessful
                },
                {
                    path: 'verify',
                    name: 'verify',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: VerifyAccount
                },
                {
                    path: 'verifyResend',
                    name: 'verifyResend',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: VerifyResend
                },
                {
                    path: 'forgotPassword',
                    name: 'forgotPassword',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: ForgotPassword
                },
                {
                    path: 'changePassword',
                    name: 'changePassword',
                    meta: { layout: 'landing-page', needsLogin: false, authPage: true },
                    component: ChangePassword
                },
                {
                    path: 'invite',
                    name: 'invite',
                    meta: { layout: 'default', needsLogin: false },
                    component: Invitation
                },
                {
                    path: 'privacyPolicy',
                    name: 'privacyPolicy',
                    meta: { layout: 'default', needsLogin: false },
                    component: PrivacyPolicy
                },
                {
                    path: 'page_not_exist',
                    name: 'pageNotExist',
                    meta: { layout: 'default', needsLogin: false },
                    component: PageNotExist
                },
                {
                    path: 'public_dashboard_not_exist',
                    name: 'publicDashboardNotExist',
                    meta: { layout: 'default', needsLogin: false },
                    component: PublicDashboardNotExist
                },
                {
                    path: 'missing_permissions',
                    name: 'missingPermissions',
                    meta: { layout: 'default', needsLogin: false },
                    component: MissingPermissions
                },
                {
                    path: 'organizations',
                    name: 'organizations',
                    meta: { layout: 'organization', needsLogin: true, hiddenSelectInHeader: true },
                    component: OrgHomePage
                },
                {
                    path: 'profile',
                    name: 'profile',
                    meta: { layout: 'organization', needsLogin: true, hiddenSelectInHeader: true },
                    component: ProfileOrganization
                },
                {
                    path: 'notifications',
                    name: 'notificationsOrg',
                    meta: { layout: 'organization', needsLogin: true, hiddenSelectInHeader: true },
                    component: NotificationOrganization
                },
                {
                    path: ':departmentId/dashboard',
                    name: 'dashboard',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: MasterDashboard
                },
                {
                    path: ':departmentId/group_dashboard',
                    name: 'groupDashboard',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: GroupDashboard
                },
                {
                    path: ':departmentId/reports',
                    name: 'reports',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: Reports
                },
                {
                    path: ':departmentId/thermalMap',
                    name: 'thermalMap',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: ThermalMapPage
                },
                {
                    path: ':departmentId/notifications',
                    name: 'notifications',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: NotificationsBoard
                },
                {
                    path: ':departmentId/configuration/devices',
                    name: 'configuration_devices',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: DeviceManagement
                },
                {
                    path: ':departmentId/configuration/loggers',
                    name: 'configuration_loggers',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: LoggerManagement
                },
                {
                    path: ':departmentId/configuration/groups',
                    name: 'configuration_groups',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: GroupManagement
                },
                {
                    path: ':departmentId/configuration/reports',
                    name: 'configuration_reports',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: ReportManagement,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.email_report_settings !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else {
                            next();
                        }
                    }
                },
                {
                    path: ':departmentId/configuration/group/create',
                    name: 'create_group',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: CreateGroup
                },
                {
                    path: ':departmentId/configuration/logger/:locationId',
                    name: 'logger_detail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: LoggerDetail
                },
                {
                    path: ':departmentId/configuration/group/:groupId',
                    name: 'group_detail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: GroupDetail
                },
                {
                    path: ':departmentId/configuration/profile',
                    name: 'profile_settings',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: ProfileManagement
                },
                {
                    path: ':departmentId/configuration/notifications',
                    name: 'configuration_notifications',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: NotificationsConfiguration
                },
                {
                    path: ':departmentId/device/:deviceId',
                    name: 'deviceConf',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: DeviceConf
                },
                {
                    path: ':departmentId/settings',
                    name: 'settings',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: Settings
                },
                {
                    path: ':departmentId/wizard',
                    name: 'wizard',
                    meta: { layout: 'default', needsLogin: true, departmentScope: true },
                    component: Wizard,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.wizard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else if (ApiUserRoles.COMPANY_OWNER == vxDepartmentStore.selectedDepartmentRoleKey ||
                            ApiUserRoles.COMPANY_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey)
                            next();
                        else
                            next({ name: 'pageNotExist' });
                    }
                },
                {
                    path: ':departmentId/company',
                    name: 'companySettings',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: CompanyManagement
                },
                {
                    path: ':departmentId/company/users/:userId',
                    name: 'userDetail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: UserDetail
                },
                {
                    path: ':departmentId/publicDashboardsManagement',
                    name: 'publicDashboards',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: PublicDashboardsManagement,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.public_dashboard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: ':departmentId/publicDashboardsManagement/:dashboardId',
                    name: 'publicDashboardDetail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: PublicDashboardDetail,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.public_dashboard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: ':departmentId/publicDashboard/:dashboardKey',
                    name: 'publicDashboard',
                    meta: { layout: 'public', needsLogin: false, departmentScope: true },
                    component: PublicDashboardPage,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.public_dashboard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: ':departmentId/about',
                    name: 'about',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: About
                },
                {
                    path: ':departmentId/feedbackForm',
                    name: 'feedbackSettings',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: Feedback,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.feedback !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/companies',
                    name: 'adminCompanies',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminCompanyManagement
                },
                {
                    path: 'administration/companies/:departmentId',
                    name: 'adminCompanyDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminCompanyDetailManagement
                },
                {
                    path: 'administration/companies/:departmentId/issue/:issueId',
                    name: 'issueDetailAdmin',
                    meta: { layout: 'admin', needsLogin: true },
                    component: IssueDetail
                },
                {
                    path: ':departmentId/notifications/issue/:issueId',
                    name: 'issueDetail',
                    meta: { layout: 'with-sidebar', needsLogin: true, departmentScope: true },
                    component: IssueDetail
                },
                {
                    path: 'administration/companies/:departmentId/publicDashboard/:dashboardId',
                    name: 'adminPublicDashboardDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminPublicDashboardDetail,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.public_dashboard !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/users',
                    name: 'adminUsers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminUserManagement
                },
                {
                    path: 'administration/users/:userId',
                    name: 'adminUserDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminUserDetailManagement
                },
                {
                    path: 'administration/devices',
                    name: 'adminDevices',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminDeviceManagement
                },
                {
                    path: 'administration/devices/:deviceId',
                    name: 'adminDeviceDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminDeviceDetailManagement
                },
                {
                    path: 'administration/devices/firmware/:firmwareId',
                    name: 'adminFirmwareDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminFirmwareDetailManagement
                },
                {
                    path: 'administration/loggers',
                    name: 'adminLoggers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminLoggerManagement
                },
                {
                    path: 'administration/loggers/:loggerId',
                    name: 'adminLoggerDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminLoggerDetailManagement
                },
                {
                    path: 'administration/resellers',
                    name: 'adminResellers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminResellerManagement
                },
                {
                    path: 'administration/resellers/:resellerId',
                    name: 'adminResellerDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminResellerDetailManagement
                },
                {
                    path: 'administration/loggers_register',
                    name: 'adminRegisterNewLoggers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminRegisterLoggersManagement
                },
                {
                    path: 'administration/loggers_manufacture',
                    name: 'adminManufactureLoggers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: ManufacturingProcessPage
                },
                {
                    path: 'administration/notificationsManagement',
                    props: (route) => ({ query: 'queryValue' }),
                    name: 'adminNotifications',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminNotificationManagement
                },
                {
                    path: 'administration/tiers',
                    name: 'adminTiers',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminTiersManagement,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.tiers !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/tiers/create',
                    name: 'adminCreateTier',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminTierCreate,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.tiers !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/tiers/:tierId',
                    name: 'adminTierDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminTierDetail,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.tiers !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/feedback',
                    name: 'adminFeedback',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminFeedbackManagement,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.feedback !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/feedback/:feedbackId',
                    name: 'adminFeedbackDetail',
                    meta: { layout: 'admin', needsLogin: true },
                    component: AdminFeedbackDetail,
                    beforeEnter: (to, from, next) => {
                        if (AppConfig.getConfig().features.feedback !== true) {
                            next({ name: 'pageNotExist' });
                        }
                        else
                            next();
                    }
                },
                {
                    path: 'administration/system_settings',
                    name: 'systemSettings',
                    meta: { layout: 'admin', needsLogin: true },
                    component: SystemSettingsManagement
                }
            ]
        }
    ]
});
var userSettingsRepository = UserSettingsRepository.getInstance(Vue.prototype);
var departmentRepository = new DepartmentRepository(Vue.prototype);
var userRepository = new UserRepository(Vue.prototype);
var featuresManager = FeaturesManager.getInstance(Vue.prototype);
var eventTypeRepository = EventTypeRepository.getInstance(Vue.prototype);
router.beforeEach(async (to, from, next) => {
    // CHECK VALID URL
    if (to.name == null) {
        return next({ name: 'pageNotExist', params: { lang: to.params.lang || 'sk' } });
    }
    // USER DOESN'T NEED TO BE LOGGED
    if (to.name == 'publicDashboard') {
        return next();
    }
    // LOAD DATA FROM STORAGE
    const loggedIn = LoggedUserManager.storageContainLoginCredentials(Vue.$cookies);
    const userId = CookiesStorage.loadData(Vue.prototype, CookiesStorage.keys.API_USER_ID);
    let userRole = CookiesStorage.loadData(Vue.prototype, CookiesStorage.keys.API_USER_ROLE);
    if (!loggedIn || userId === null) {
        if (to.name !== 'login' && to.meta.needsLogin) {
            LoggedUserManager.deleteLoginData(Vue.prototype);
            LoggedUserManager.deleteAdminLoginData(Vue.prototype);
            return router.push({ name: 'login', params: { lang: to.params.lang || 'sk', toUrl: to.fullPath } });
        }
        else {
            return next();
        }
    }
    if (userRole === null) {
        userRole = (await userRepository.getCurrentUser())?.role.Key;
        CookiesStorage.storeData(Vue.prototype, CookiesStorage.keys.API_USER_ROLE, userRole, Infinity);
    }
    let userSettings = await userSettingsRepository.loadUserSettings();
    // LANGUAGE SETUP
    let lang = userSettings.language || to.params.lang || 'sk';
    i18n.locale = lang;
    if (to.params.lang !== lang) {
        to.params.lang = lang;
        return next({
            name: to.name,
            params: to.params
        });
    }
    // SERVICE WORKER UPDATE
    if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
            registrationsArray[0].update();
        });
    }
    // GRAFANA SETUP
    if (AppConfig.getConfig().features.grafana_faro == true) {
        LoggedUserManager.setFaroUser(userId);
    }
    // LOAD DEPARTMENTS
    // We will load also disabled departments for customer, but they will be disabled in selections
    let departments = getDepartments();
    if (!departments) {
        if (userRole === SystemUserRoles.CUSTOMER) {
            departments = (await departmentRepository.getDepartments()).getData().getData();
        }
        else {
            departments = AdminDepartment.convertToUserDepartments((await departmentRepository.getAllDepartmentsAdmin(null, null, [], true)).getData());
        }
        setDepartments(departments);
    }
    // IF NO DEPARTMENTS -> REDIRECT TO ORGANIZATIONS PAGE
    if (userRole === SystemUserRoles.CUSTOMER && getEnabledDepartments(departments).length == 0) {
        if (to.name !== 'organizations' && to.meta.layout !== 'organization') {
            return router.replace({
                name: 'organizations',
                params: {
                    lang: lang
                }
            });
        }
        else {
            return next();
        }
    }
    // STORE SELECTED DEPARTMENT ID
    // If customer has selected department, they must have also favorite
    if (userRole === SystemUserRoles.CUSTOMER) {
        let selectedDepartment = getSelectedDepartment();
        if (!selectedDepartment) {
            let favoriteDepartmentId = userSettings.preferences.favoriteDepartmentId
                ? userSettings.preferences.favoriteDepartmentId
                : departments[0].id;
            let routerObj = checkDepartmentAccessAndSet(userRole, departments, favoriteDepartmentId, to.params.departmentId, lang);
            if (routerObj !== null) {
                return next(routerObj);
            }
        }
    }
    // HOME REDIRECT / ALREADY LOGGED IN
    if (to.name === 'home' || (to.meta.authPage && loggedIn)) {
        if (userRole === SystemUserRoles.CUSTOMER || userRole === null) {
            return router.replace({
                name: 'dashboard',
                params: {
                    lang: lang,
                    departmentId: getSelectedDepartment().id
                }
            });
        }
        else {
            return router.replace({
                name: 'adminCompanies',
                params: {
                    lang: lang
                }
            });
        }
    }
    // PERMISSIONS
    // Reload only when role or permissions are null, or when user changes department
    if (LoggedUserManager.getRole() === null ||
        LoggedUserManager.getPermissions() === null ||
        (userRole === SystemUserRoles.CUSTOMER &&
            to.params.departmentId &&
            to.params.departmentId !== getSelectedDepartment().id)) {
        let role = '';
        let permissions = [];
        if (userRole === SystemUserRoles.SYSTEM_ADMIN || userRole === SystemUserRoles.RESELLER_ADMIN) {
            let user = await userRepository.getCurrentUser();
            const permissionsForRole = user.role.Permissions;
            role = userRole;
            if (permissionsForRole &&
                permissionsForRole.length > 0 &&
                LoggedUserManager.isLoggedToCompany(Vue.prototype) == false) {
                permissions = permissionsForRole;
            }
            else {
                permissions = [Subjects.ALL + '.' + AdminAction];
            }
        }
        else {
            let member = await userRepository.getUserRole(userId, to.params.departmentId);
            role = member.Key;
            permissions = member.Permissions;
        }
        LoggedUserManager.setRole(role);
        LoggedUserManager.setPermissions(permissions);
        router.app.$ability.update(new AbilityManager(permissions).getAbilities().rules);
    }
    // IF DEPARTMENT ID IS IN URL, STORE IT
    if (to.params.departmentId) {
        if (isDepartmentIdValid(to.params.departmentId)) {
            let routerObj = checkDepartmentAccessAndSet(userRole, departments, to.params.departmentId, to.params.departmentId, lang);
            if (routerObj !== null) {
                return next(routerObj);
            }
        }
        else {
            VueUtilities.openSuccessToast(Vue.prototype, i18n.t('error_messages.invalid_department_id').toString());
            if (userRole === SystemUserRoles.CUSTOMER) {
                return next({
                    name: 'organizations',
                    params: {
                        lang: lang
                    }
                });
            }
            else {
                return next({
                    name: 'adminCompanies',
                    params: {
                        lang: lang
                    }
                });
            }
        }
    }
    // LOGGED IN COMPANY FLAG
    if ((userRole === SystemUserRoles.SYSTEM_ADMIN || userRole === SystemUserRoles.RESELLER_ADMIN) &&
        to.meta.departmentScope &&
        !LoggedUserManager.isLoggedToCompany(Vue.prototype)) {
        LoggedUserManager.loginToCompany(Vue.prototype, AdminDepartment.convertToAdminDepartment(getSelectedDepartment()), from.name ? from.name : 'adminCompanies', from.name ? from.params : { lang: lang });
    }
    // CACHE THINGS
    if (userRole === SystemUserRoles.CUSTOMER) {
        let allPromises = [];
        allPromises.push(featuresManager.loadTier());
        allPromises.push(eventTypeRepository.getAllTypes(true));
        allPromises.push(userSettingsRepository.loadUserSettingsForDepartment(userId, true));
        allPromises.push(departmentRepository.getDepartmentSettings());
        await Promise.all(allPromises).then((response) => {
            if (to.name === 'dashboard') {
                if (response[3].setupRequired.toString() === 'true') {
                    return next({
                        name: 'wizard',
                        params: {
                            lang: lang,
                            departmentId: getSelectedDepartment().id
                        }
                    });
                }
            }
        });
    }
    // CHECK IF DEPARTMENT ID IS PRESENT IF departmentScope: true
    if (to.meta.departmentScope && !to.params.departmentId) {
        return router.replace({
            name: to.name,
            params: {
                lang: lang,
                departmentId: getSelectedDepartment().id
            }
        });
    }
    return next();
});
export default router;
/**
 * Function serves for setting selected department.
 * It checks if customer is enabled in department. If not, it tries to finds the first suitable one
 * and redirects user there.
 * For admins it directly sets ID from URL (due to their permissions).
 * @param role logged user role
 * @param departmentId favorite department ID / ID of department from URL
 * @param departments user's assigned departments
 * @param toDepId selected department ID
 * @param lang user's preferable language
 * @returns router object if needs to redirect, otherwise null
 */
function checkDepartmentAccessAndSet(role, departments, departmentId, toDepId, lang) {
    if (role === SystemUserRoles.CUSTOMER && !isDepartmentEnabled(departments, departmentId)) {
        let enabledDepartments = getEnabledDepartments(departments);
        if (enabledDepartments?.length > 0) {
            if (departmentId === toDepId) {
                VueUtilities.openErrorToast(Vue.prototype, i18n.t('error_messages.user_not_allowed', {
                    dep: getDepartmentById(departments, departmentId).name
                }));
                return {
                    name: 'dashboard',
                    params: {
                        lang: lang,
                        departmentId: enabledDepartments[0].id
                    }
                };
            }
            else {
                setSelectedDepartmentById(enabledDepartments[0].id);
            }
        }
        else {
            VueUtilities.openErrorToast(Vue.prototype, i18n.t('error_messages.user_without_department'));
            return {
                name: 'organizations',
                params: {
                    lang: lang
                }
            };
        }
    }
    else {
        setSelectedDepartment(departments, departmentId);
    }
    return null;
}
function getDepartments() {
    return vxDepartmentStore.departments;
}
function setDepartments(value) {
    vxDepartmentStore.departments = value;
}
/**
 * Function which checks if exists department id from url
 * @param departmentId
 * @returns true if department exists, else false
 */
function isDepartmentIdValid(departmentId) {
    return getDepartments().find((x) => x.id === departmentId) ? true : false;
}
function getSelectedDepartment() {
    return vxDepartmentStore.selectedDepartment;
}
/**
 * Returns departments where user is enabled
 * @param departments user's assigned departments
 * @returns array of enabled departments
 */
function getEnabledDepartments(departments) {
    return departments ? departments.filter((department) => department.member?.Enabled) : [];
}
function getDepartmentById(departments, departmentId) {
    return departments.find((dep) => dep.id === departmentId);
}
/**
 * Checks if user is enabled in department
 * @param departments user's assigned departments
 * @param departmentId selected department's ID
 * @returns true if yes, false if no
 */
function isDepartmentEnabled(departments, departmentId) {
    let department = getDepartmentById(departments, departmentId);
    return department.member.Enabled;
}
/**
 * Function which initializes selected and favorite department id in store
 * If user has favorite deparment in preferences, it's also his currently selected
 * If user hasn't favorite department, his currently selected department is first from list of departments
 * @param departments
 * @param favoriteDepartmentId
 * @returns currently selected department
 */
function setSelectedDepartment(departments, favoriteDepartmentId) {
    let selectedDepId = favoriteDepartmentId;
    if (!selectedDepId || (selectedDepId && selectedDepId.length == 0)) {
        selectedDepId = departments[0].id;
    }
    setSelectedDepartmentById(selectedDepId);
    setFavoriteDepartmentById(selectedDepId);
}
function setSelectedDepartmentById(value) {
    vxDepartmentStore.setSelectedDepartmentById(value);
}
function setFavoriteDepartmentById(value) {
    vxDepartmentStore.setFavoriteDepartmentById(value);
}
