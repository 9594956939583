import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IssueType } from '@/entities/enums/IssueType';
let StatusCards = class StatusCards extends Vue {
    constructor() {
        super(...arguments);
        this.IssueType = IssueType;
    }
    get offlineSources() {
        return this.departmentIssues.filter((x) => x.type == IssueType.SOURCE_OFFLINE).length;
    }
    get offlineDevices() {
        return this.departmentIssues.filter((x) => x.type == IssueType.DEVICE_OFFLINE).length;
    }
    get warningSources() {
        return this.departmentIssues.filter((x) => x.type == IssueType.ALARM && x.metadata.level == 'Warning').length;
    }
    get alarmSources() {
        return this.departmentIssues.filter((x) => x.type == IssueType.ALARM && x.metadata.level == 'Alarm').length;
    }
    get outOfBoundsCount() {
        return this.alarmSources + this.warningSources;
    }
    get withBatteryUnder20() {
        return this.departmentIssues.filter((x) => x.type == IssueType.LOW_BATTERY && x.metadata.batteryLevel !== null)
            .length;
    }
    get withBatteryOk() {
        return this.sources.filter((x) => x.logger && x.logger.battery > 20 && x.logger.battery !== null).length;
    }
    get withBatteryUnknown() {
        return this.sources.filter((x) => (x.logger && x.logger.battery == null) || !x.logger).length;
    }
};
__decorate([
    Prop({ type: Number, default: 0 })
], StatusCards.prototype, "devicesCount", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], StatusCards.prototype, "sourcesCount", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], StatusCards.prototype, "activeLoggersCount", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], StatusCards.prototype, "notificationCount", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], StatusCards.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], StatusCards.prototype, "companyLoggerExpirationList", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => {
            return [];
        }
    })
], StatusCards.prototype, "departmentIssues", void 0);
StatusCards = __decorate([
    Component
], StatusCards);
export default StatusCards;
