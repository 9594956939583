import AxiosService from '@/services/api/AxiosService';
import { Notification } from '@/entities/Notification';
import { NotificationPriority } from '@/entities/notification/NotificationPriority';
import ApiLinks from '@/entities/ApiLinks';
import SortDirections from '@/entities/enums/SortDirections';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiStatus from '@/entities/enums/apiStatuses';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiResponse from '@/entities/ApiResponse';
import NotificationCategory from '@/entities/enums/NotificationCategory';
import { vxm } from '@/store/store.vuex';
var vxNotificationStore = vxm.notificationStore;
export default class NotificationRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getAdminNotificationsForUser(userId, pagination = null, sort = null, filter = [], dateRange = [], departmentId = '') {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_NOTIFICATIONS_USER)) {
            throw 'Forbidden action getAdminNotificationsForUser';
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        let allFilters = [];
        if (dateRange.length > 0) {
            allFilters.push('CreatedAt>=' + dateRange[0]);
            allFilters.push('CreatedAt<' + dateRange[1]);
        }
        /*TODO: filter keys should be somehow defined in notificationRepository,
         filter parameter should support all available filters not only category*/
        if (filter.length > 0) {
            let categories = filter.join('|');
            allFilters.push('Category==' + categories);
        }
        if (departmentId.length > 0) {
            allFilters.push('DepartmentId==' + departmentId);
        }
        if (allFilters.length > 0) {
            queries.addFilters(allFilters);
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.Users.Keys.Notifications}`;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        let apiResponse = new ApiResponse();
        if (response.status != ApiStatus.SUCCESS)
            return apiResponse;
        else {
            //Create response with pagination data
            apiResponse.setPagination(response.data);
            apiResponse.setData(Notification.fromApi(response.data.data));
            return apiResponse;
        }
    }
    async getNotificationPriorities() {
        let url = ApiLinks.Notifications.Priorities;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return NotificationPriority.fromApi(response.data.data);
    }
    async sendNotification(notification) {
        if (!this.ctx.$ability.can(Actions.SEND, Subjects.ADMIN_NOTIFICATIONS)) {
            throw 'Forbidden action sendNotification';
        }
        let url = `${ApiLinks.Notifications.Send}`;
        let response = await AxiosService.postDataToServer(url, notification, this.ctx);
        return response.status == ApiStatus.SUCCESS;
    }
    async markNotificationsAsRead(notificationIds) {
        let url = `${ApiLinks.Notifications.Home}${ApiLinks.Notifications.Keys.Remove}`;
        let data = {
            List: notificationIds
        };
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        return response;
    }
    async markAllNotificationsAsRead() {
        let url = `${ApiLinks.Notifications.Home}${ApiLinks.Notifications.Keys.RemoveAll}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        return response;
    }
    async getNotifications(pagination = { page: 1, pageSize: 10 }, sort = { direction: SortDirections.DESCENDING, field: 'CreatedAt' }, filter = [], dateRange = [], departmentId = '') {
        let apiResponse = new ApiResponse();
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        queries.addSorts(sort);
        let allFilters = [];
        if (dateRange.length > 0) {
            allFilters.push('CreatedAt>=' + dateRange[0]);
            allFilters.push('CreatedAt<' + dateRange[1]);
        }
        /*TODO: filter keys should be somehow defined in notificationRepository,
       filter parameter should support all available filters not only category*/
        if (filter.length > 0) {
            let categories = filter.join('|');
            allFilters.push('Category==' + categories);
        }
        if (departmentId.length > 0) {
            allFilters.push('DepartmentId==' + departmentId);
        }
        if (allFilters.length > 0) {
            queries.addFilters(allFilters);
        }
        let response = await AxiosService.getDataFromServer(ApiLinks.Notifications.Home + `?${queries.toQueryString()}`, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new ApiResponse();
        else {
            //Create response with pagination data
            apiResponse.setPagination(response.data);
            apiResponse.setData(Notification.fromApi(response.data.data));
            return apiResponse;
        }
    }
    // function to check notifications stored in vuex, if non presented, use api call and store them
    async loadNotificationsToStore(pagination) {
        let response = await this.getNotifications(pagination);
        vxNotificationStore.notifications = response.getData();
        vxNotificationStore.totalSize = response.getPagination().total;
        vxNotificationStore.lastLoaded = Date.now();
    }
    get availableCategoriesToFilter() {
        return NotificationCategory.getAvailableCategories();
    }
    isCategory(filter) {
        return filter.some((element) => this.availableCategoriesToFilter.map((category) => category.name).includes(element));
    }
}
