var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "is-flex is-justify-content-space-between mx-3 mx-0-desktop mb-4 mb-1-touch",
        },
        [
          _c(
            "nav",
            {
              staticClass: "breadcrumb",
              attrs: { "aria-label": "breadcrumb my-1" },
            },
            [
              _c("h1", { staticClass: "title has-text-centered-touch" }, [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-chevron-left is-size-5",
                          staticStyle: { "padding-right": "10px" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("admin.component.loggers.loggerList.title")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("li", { staticClass: "is-active" }, [
                    _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.logger_manufacturing.title"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c("div", { staticClass: "box tile is-child" }, [
                _c(
                  "div",
                  { staticClass: "columns" },
                  [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("p", { staticClass: "subtitle" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "admin.component.loggers.logger_manufacturing.main_settings"
                              )
                            )
                          ),
                        ]),
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.devices.selectDevice.selectDevice"
                              ),
                              "label-position": "on-border",
                            },
                          },
                          [
                            _c("b-autocomplete", {
                              attrs: {
                                disabled: _vm.isScanning,
                                data: _vm.filteredDevices(
                                  _vm.selectedDeviceName
                                ),
                                placeholder: _vm.$t(
                                  "admin.component.devices.selectDevice.selectDevicePlaceholder"
                                ),
                                clearable: "",
                                field: "deviceName",
                                "icon-pack": "fas",
                                icon: "search",
                                "open-on-focus": "",
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.selectDevice($event)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "empty",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          _vm._s(_vm.$t("generals.no_results"))
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3307844859
                              ),
                              model: {
                                value: _vm.selectedDeviceName,
                                callback: function ($$v) {
                                  _vm.selectedDeviceName = $$v
                                },
                                expression: "selectedDeviceName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.loggers.logger_manufacturing.rssi"
                              ),
                              "label-position": "on-border",
                            },
                          },
                          [
                            _c("b-input", {
                              attrs: {
                                type: "number",
                                disabled: _vm.isScanning,
                              },
                              model: {
                                value: _vm.rssiLimit,
                                callback: function ($$v) {
                                  _vm.rssiLimit = $$v
                                },
                                expression: "rssiLimit",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "subtitle is-size-6 has-text-weight-bold",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.loggers.logger_manufacturing.show_registered"
                                  )
                                ) +
                                " "
                            ),
                            _c("b-switch", {
                              attrs: {
                                size: "is-small",
                                disabled: _vm.isScanning,
                              },
                              model: {
                                value: _vm.showRegistered,
                                callback: function ($$v) {
                                  _vm.showRegistered = $$v
                                },
                                expression: "showRegistered",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "column" }, [
                      _c("p", { staticClass: "subtitle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "admin.component.loggers.logger_manufacturing.optional_settings"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass:
                            "subtitle is-size-6 has-text-weight-bold",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.temp_range"
                                )
                              ) +
                              " "
                          ),
                          _c("b-switch", {
                            attrs: {
                              size: "is-small",
                              disabled: _vm.isScanning,
                            },
                            model: {
                              value: _vm.tempLimitUsed,
                              callback: function ($$v) {
                                _vm.tempLimitUsed = $$v
                              },
                              expression: "tempLimitUsed",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.tempLimitUsed
                        ? _c(
                            "div",
                            { staticClass: "pb-2" },
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.loggers.logger_manufacturing.min"
                                    ),
                                    "label-position": "on-border",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      step: "0.01",
                                      type: "number",
                                      disabled: _vm.isScanning,
                                    },
                                    model: {
                                      value: _vm.minTemperature,
                                      callback: function ($$v) {
                                        _vm.minTemperature = $$v
                                      },
                                      expression: "minTemperature",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.loggers.logger_manufacturing.max"
                                    ),
                                    "label-position": "on-border",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      step: "0.01",
                                      type: "number",
                                      disabled: _vm.isScanning,
                                    },
                                    model: {
                                      value: _vm.maxTemperature,
                                      callback: function ($$v) {
                                        _vm.maxTemperature = $$v
                                      },
                                      expression: "maxTemperature",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "p",
                        {
                          staticClass:
                            "subtitle is-size-6 has-text-weight-bold",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.hum_range"
                                )
                              ) +
                              " "
                          ),
                          _c("b-switch", {
                            attrs: {
                              size: "is-small",
                              disabled: _vm.isScanning,
                            },
                            model: {
                              value: _vm.humLimitUsed,
                              callback: function ($$v) {
                                _vm.humLimitUsed = $$v
                              },
                              expression: "humLimitUsed",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.humLimitUsed
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.loggers.logger_manufacturing.min"
                                    ),
                                    "label-position": "on-border",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      step: "0.01",
                                      type: "number",
                                      disabled: _vm.isScanning,
                                    },
                                    model: {
                                      value: _vm.minHumidity,
                                      callback: function ($$v) {
                                        _vm.minHumidity = $$v
                                      },
                                      expression: "minHumidity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.loggers.logger_manufacturing.max"
                                    ),
                                    "label-position": "on-border",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      step: "0.01",
                                      type: "number",
                                      disabled: _vm.isScanning,
                                    },
                                    model: {
                                      value: _vm.maxHumidity,
                                      callback: function ($$v) {
                                        _vm.maxHumidity = $$v
                                      },
                                      expression: "maxHumidity",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    !_vm.isScanning
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: !_vm.selectedDevice,
                              type: "is-primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.startScanning()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.loggers.logger_manufacturing.start"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.stopScanning()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.loggers.logger_manufacturing.stop"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-three-quarters" }, [
                    _c("p", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.logger_manufacturing.log"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        ref: "tableContainer",
                        staticStyle: {
                          "max-height": "80vh",
                          "overflow-y": "auto",
                        },
                      },
                      [
                        _c(
                          "b-table",
                          { attrs: { data: _vm.scannedLoggers } },
                          [
                            _c("b-table-column", {
                              attrs: {
                                field: "Timestamp",
                                label: _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.table.time"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.dateTimeManager.formatTimeOnly(
                                                new Date(
                                                  props.row.Timestamp * 1000
                                                ),
                                                true
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3493273364
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "MacAddress",
                                label: _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.table.mac"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row.MacAddress) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3272587268
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "SerialNumber",
                                label: _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.table.serial"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row.SerialNumber) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                236775486
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "AdditionalValues.Rssi",
                                label: _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.table.rssi"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.row.AdditionalValues.Rssi
                                            ) +
                                            " dBm "
                                        ),
                                        props.row.AdditionalValues.RssiStatus ==
                                        true
                                          ? _c("b-icon", {
                                              attrs: {
                                                "icon-pack": "fas",
                                                icon: "check",
                                                type: "is-success",
                                              },
                                            })
                                          : _c("b-icon", {
                                              attrs: {
                                                "icon-pack": "fas",
                                                icon: "times",
                                                type: "is-danger",
                                              },
                                            }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                918436480
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "AdditionalValues.TempVal",
                                label: _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.table.temp_val"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        props.row.AdditionalValues.TempVal !=
                                        null
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.row.AdditionalValues
                                                      .TempVal
                                                  ) +
                                                  " °C "
                                              ),
                                            ])
                                          : _c("span", [_vm._v("N/A")]),
                                        props.row.AdditionalValues
                                          .TempStatus === true
                                          ? _c("b-icon", {
                                              attrs: {
                                                "icon-pack": "fas",
                                                icon: "check",
                                                type: "is-success",
                                              },
                                            })
                                          : props.row.AdditionalValues
                                              .TempStatus === false
                                          ? _c("b-icon", {
                                              attrs: {
                                                "icon-pack": "fas",
                                                icon: "times",
                                                type: "is-danger",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                209239973
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "AdditionalValues.HumVal",
                                label: _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.table.hum_val"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        props.row.AdditionalValues.HumVal !=
                                        null
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.row.AdditionalValues
                                                      .HumVal
                                                  ) +
                                                  " % "
                                              ),
                                            ])
                                          : _c("span", [_vm._v("N/A")]),
                                        props.row.AdditionalValues.HumStatus ===
                                        true
                                          ? _c("b-icon", {
                                              attrs: {
                                                "icon-pack": "fas",
                                                icon: "check",
                                                type: "is-success",
                                              },
                                            })
                                          : props.row.AdditionalValues
                                              .HumStatus === false
                                          ? _c("b-icon", {
                                              attrs: {
                                                "icon-pack": "fas",
                                                icon: "times",
                                                type: "is-danger",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3684287731
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: { field: "action" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        props.row.AdditionalValues.isValid ==
                                          false &&
                                        _vm.loggerIsRegistered(props.row) ===
                                          false
                                          ? _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "is-primary",
                                                  size: "is-small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.forceCreate(
                                                      props.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.logger_manufacturing.force_create_btn"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        props.row.AdditionalValues
                                          .isRegistered == true
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "has-text-weight-bold has-text-success",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.logger_manufacturing.registered"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : props.row.LoggerId
                                          ? _c(
                                              "p",
                                              {
                                                staticClass: "has-text-warning",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.logger_manufacturing.registered_before"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3842679298
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "column" }, [
                    _c("p", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.logger_manufacturing.registered"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        ref: "tableRegisteredContainer",
                        staticStyle: {
                          "max-height": "80vh",
                          "overflow-y": "auto",
                        },
                      },
                      [
                        _c(
                          "b-table",
                          { attrs: { data: _vm.registeredLoggers } },
                          [
                            _c("b-table-column", {
                              attrs: {
                                field: "AdditionalValues.createdAt",
                                label: _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.table.createdAt"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.dateTimeManager.formatTimeOnly(
                                                new Date(
                                                  props.row.AdditionalValues.createdAt
                                                ),
                                                true
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                931977099
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "MacAddress",
                                label: _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.table.mac"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row.MacAddress) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3272587268
                              ),
                            }),
                            _c("b-table-column", {
                              attrs: {
                                field: "SerialNumber",
                                label: _vm.$t(
                                  "admin.component.loggers.logger_manufacturing.table.serial"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row.SerialNumber) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                236775486
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }