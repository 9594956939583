import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserRepository from '@/services/repository/UserRepository';
import UserPreferences from '@/services/UserPreferences';
import ChangeUserRoleModal from '@/views/components/administration/ChangeUserRoleModal.vue';
import VueUtilities from '@/services/VueUtilities';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import CookiesStorage from '@/services/CookiesStorage';
import SortDirections from '@/entities/enums/SortDirections';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
var userRepository;
const COMPANY_ADMIN_KEY = 'companyOwner';
let UserAdministration = class UserAdministration extends Vue {
    constructor() {
        super(...arguments);
        this.COMPANY_ADMIN_KEY = COMPANY_ADMIN_KEY;
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.ApiUserRoles = ApiUserRoles;
        this.isDisableModalActive = false;
        this.isChangeRoleModalActive = false;
        this.selectedUser = null;
        this.username = CookiesStorage.loadData(this, CookiesStorage.keys.USERNAME);
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.total = 0;
        this.componentName = 'userList';
        this.tableQueries = null;
        this.tableColumns = {
            fullname: {
                field: 'fullName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.UserAdministrationFullname)
            },
            username: {
                field: 'username',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.UserAdministrationUsername)
            },
            role: {
                field: 'role',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.UserAdministrationRole)
            },
            email: {
                field: 'email',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.UserAdministrationEmail)
            },
            enabled: {
                field: 'enabled',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.UserAdministrationEnabled)
            }
        };
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    get isOwner() {
        return vxDepartmentStore.selectedDepartmentRoleKey === ApiUserRoles.COMPANY_OWNER;
    }
    async created() {
        userRepository = new UserRepository(this);
        this.fillFilterFromRoute();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
    }
    fillFilterFromRoute() {
        try {
            this.tableQueries = JSON.parse(this.$router.currentRoute.query.userList);
            this.sortField = this.tableQueries.sortField;
            this.sortDirection = this.tableQueries.sortDirection;
            this.page = this.tableQueries.page;
            this.pageSize = this.tableQueries.pageSize;
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.UserAdministrationSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.UserAdministrationSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.UserAdministrationPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.UserAdministrationRowsPerPage.defaultValue;
    }
    onSort(field, order) {
        this.sortField = field;
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange();
    }
    onPageChange(page) {
        this.page = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange();
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange();
    }
    onOptionsChange() {
        let tableQueries = {
            sortField: this.sort.field,
            sortDirection: this.sort.direction == '-' ? 'desc' : 'asc',
            page: this.page,
            pageSize: this.pageSize,
            filters: null
        };
        this.changeRouterUrl(JSON.stringify(tableQueries));
    }
    changeRouterUrl(tableQueries) {
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                [this.componentName]: tableQueries
            })
        })
            .catch((err) => err);
    }
    mounted() {
        this.total = this.$refs.table._data.newData.length;
    }
    inviteUser() {
        this.$emit('openInviteModal', true);
    }
    closeModal(data) {
        this.isDisableModalActive = false;
        this.isChangeRoleModalActive = false;
        this.$emit('reload');
    }
    async enableUser(user) {
        let response = await userRepository.enableUser(user.apiUserId);
        if (!response) {
            VueUtilities.openErrorToast(this, this.$t('users.enable_failure'));
        }
        else {
            VueUtilities.openSuccessToast(this, this.$t('users.successfully_enabled'));
            this.$emit('reload');
        }
    }
    async disableUserDialog(user) {
        this.$buefy.dialog.confirm({
            title: `${this.$t('component.userAdministration.list.deptDisable.title')}`,
            message: this.$t('component.userAdministration.list.deptDisable.body', {
                userName: user.fullName
            }),
            confirmText: `${this.$t('component.userAdministration.list.deptDisable.confirm')}`,
            cancelText: `${this.$t('component.userAdministration.list.deptDisable.deny')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let resp = await userRepository.disableUser(user.apiUserId);
                if (resp === false) {
                    VueUtilities.openErrorToast(this, this.$t('users.disable_failure'));
                }
                else {
                    VueUtilities.openSuccessToast(this, this.$t('users.successfully_disabled').toString());
                    this.selectedUser = null;
                    this.$emit('reload');
                }
            }
        });
    }
    openChangeRoleModal(user) {
        this.selectedUser = user;
        this.isChangeRoleModalActive = true;
    }
    async changeRole(role, user) {
        if (role === ApiUserRoles.COMPANY_OWNER) {
            await this.openChangeOwnerDialog(role, user);
        }
        else {
            let result = await userRepository.changeUserRole(user.apiUserId, role);
            if (result.role.Key === role) {
                VueUtilities.openSuccessToast(this, this.$t('users.change_user_role.success'));
                this.isChangeRoleModalActive = false;
                this.selectedUser = null;
                this.$emit('reload');
            }
            else {
                VueUtilities.openSuccessToast(this, this.$t('users.change_user_role.failure'));
            }
        }
    }
    async openChangeOwnerDialog(role, user) {
        this.$buefy.dialog.confirm({
            title: `${this.$t('component.userAdministration.list.changeOwnerRole.title')}`,
            message: this.$t('component.userAdministration.list.changeOwnerRole.body', {
                userName: user.fullName
            }),
            confirmText: `${this.$t('component.userAdministration.list.changeOwnerRole.confirm')}`,
            cancelText: `${this.$t('component.userAdministration.list.changeOwnerRole.deny')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let response = await userRepository.changeDepartmentOwner(user.apiUserId);
                if (response) {
                    VueUtilities.openSuccessToast(this, this.$t('component.userAdministration.list.changeOwnerRole.success').toString());
                    this.isChangeRoleModalActive = false;
                    this.selectedUser = null;
                    this.$emit('reload');
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.userAdministration.list.changeOwnerRole.error'));
                }
            }
        });
    }
    removeUserFromDepartment(user) {
        this.$buefy.dialog.confirm({
            title: `${this.$t('component.userAdministration.list.removeUser.title')}`,
            message: this.$t('component.userAdministration.list.removeUser.body', {
                userName: user.fullName
            }),
            confirmText: `${this.$t('component.userAdministration.list.removeUser.confirm')}`,
            cancelText: `${this.$t('component.userAdministration.list.removeUser.deny')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let resp = await userRepository.removeUserFromDepartment(user.apiUserId);
                if (resp === false) {
                    VueUtilities.openErrorToast(this, this.$t('component.userAdministration.list.removeUser.fail_remove'));
                }
                else {
                    VueUtilities.openSuccessToast(this, this.$t('component.userAdministration.list.removeUser.successfully_removed').toString());
                    this.selectedUser = null;
                    this.$emit('reload');
                }
            }
        });
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.role.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.UserAdministrationRole, event);
                break;
            case this.tableColumns.username.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.UserAdministrationUsername, event);
                break;
            case this.tableColumns.email.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.UserAdministrationEmail, event);
                break;
            case this.tableColumns.enabled.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.UserAdministrationEnabled, event);
                break;
        }
    }
};
__decorate([
    Prop({ type: Array })
], UserAdministration.prototype, "users", void 0);
UserAdministration = __decorate([
    Component({
        components: { ChangeUserRoleModal }
    })
], UserAdministration);
export default UserAdministration;
