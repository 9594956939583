import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import IssueRepository from '@/services/repository/IssueRepository';
import { IssueType } from '@/entities/enums/IssueType';
import VueUtilities from '@/services/VueUtilities';
import SourceRepository from '@/services/repository/SourceRepository';
import DeviceRepository from '@/services/repository/DeviceRepository';
import SortDirections from '@/entities/enums/SortDirections';
var issueRepository;
var sourceRepository;
var deviceRepository;
let AcknowledgeIssueModal = class AcknowledgeIssueModal extends Vue {
    constructor() {
        super(...arguments);
        this.IssueType = IssueType;
        this.issueIds = [];
        this.isLoading = true;
        this.sources = [];
        this.devices = [];
        this.departmentIssues = [];
    }
    get alarmIssues() {
        return this.departmentIssues.filter((x) => x.metadata.level === 'Alarm').length > 0;
    }
    get warningIssues() {
        return this.departmentIssues.filter((x) => x.metadata.level === 'Warning').length > 0;
    }
    issueCountByType(type) {
        return this.departmentIssues.filter((x) => x.type === type).length > 0;
    }
    get alarmDepartmentIssues() {
        return this.departmentIssues.filter((x) => x.metadata.level === 'Alarm');
    }
    get warningDepartmentIssues() {
        return this.departmentIssues.filter((x) => x.metadata.level === 'Warning');
    }
    created() {
        issueRepository = new IssueRepository(this);
        sourceRepository = new SourceRepository(this);
        deviceRepository = new DeviceRepository(this);
        let allPromises = [];
        allPromises.push(sourceRepository.getVisibleSources(null, null, this.department.id));
        allPromises.push(deviceRepository.getDevices(null, null, [], this.department.id));
        issueRepository = new IssueRepository(this);
        if (this.fineState) {
            let sort = {
                field: 'Type',
                direction: SortDirections.DESCENDING
            };
            allPromises.push(issueRepository.getDepartmentCurrentIssues(null, sort, [], this.department.id));
            Promise.all(allPromises).then((response) => {
                this.loadSourcesAndDevices(response[0], response[1], response[2]);
            });
        }
    }
    async loadSourcesAndDevices(sources, devices, issues) {
        this.sources = sources.getData();
        this.devices = devices.getData();
        this.departmentIssues = issues.getData();
        this.issueIds = this.departmentIssues.map((issue) => issue.id);
        this.isLoading = false;
    }
    departmentIssueByType(type) {
        return this.departmentIssues.filter((x) => x.type === type);
    }
    getLoggerName(entityId) {
        let source = this.sources?.find((x) => x.id === entityId);
        return source ? source.name : 'Unknown';
    }
    getCompanyLoggerName(companyLoggerId) {
        let source = this.sources?.find((x) => x.logger && x.logger.companyLoggerId === companyLoggerId);
        return source ? source.name : 'Unknown';
    }
    getDeviceName(entityId) {
        let device = this.devices?.find((x) => x.deviceId === entityId);
        return device ? device.deviceName : 'Unknown';
    }
    closeModal() {
        this.$emit('modalClosed');
    }
    async acknowledge() {
        let result = await issueRepository.acknowledgeIssues(this.issueIds, this.department.id);
        if (result == true) {
            VueUtilities.openSuccessToast(this, this.$t('component.acknowledge_modal.success'));
            this.closeModal();
            this.$emit('reload');
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.acknowledge_modal.failure'));
        }
    }
};
__decorate([
    Prop({ type: Object })
], AcknowledgeIssueModal.prototype, "department", void 0);
__decorate([
    Prop({ type: Object })
], AcknowledgeIssueModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean })
], AcknowledgeIssueModal.prototype, "fineState", void 0);
AcknowledgeIssueModal = __decorate([
    Component({ components: {} })
], AcknowledgeIssueModal);
export default AcknowledgeIssueModal;
