import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import Languages from '@/entities/enums/languages';
import UserRepository from '@/services/repository/UserRepository';
import ApiUserRoles from '@/entities/enums/UserRoles';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
var departmentRepository;
var userSettingsRepository;
var userRepository;
var notificationSubscriptionRepository;
let InviteUserModal = class InviteUserModal extends Vue {
    constructor() {
        super(...arguments);
        this.userList = [];
        this.Languages = Languages;
        this.isLoading = false;
        this.selectedLang = Languages.ENGLISH;
        this.inviteMessage = '';
        this.availableRoles = [];
        this.availablePresets = [];
        this.emailIndex = null;
        this.currentUser = null;
    }
    get userRole() {
        return this.availableRoles.find((x) => x.Key === ApiUserRoles.COMPANY_USER);
    }
    async created() {
        departmentRepository = new DepartmentRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        userRepository = new UserRepository(this);
        notificationSubscriptionRepository = new NotificationSubscriptionRepository(this);
        this.currentUser = await userRepository.getCurrentUser();
        this.settings = await userSettingsRepository.loadUserSettings();
        this.selectedLang = this.settings.language;
        this.availableRoles = (await userRepository.getUserRoles(this.selectedDepartmentId)).filter((x) => x.Key !== ApiUserRoles.COMPANY_OWNER);
        this.availablePresets = await notificationSubscriptionRepository.getNotificationPresets(this.currentUser.apiUserId);
        this.addNewUser();
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    selectLang(event) {
        this.selectedLang = event;
    }
    addNewUser() {
        this.userList.push({
            email: '',
            role: this.userRole,
            preset: null
        });
    }
    remove(index) {
        this.userList.splice(index, 1);
    }
    prefillMessage() {
        this.inviteMessage = this.$t('component.userAdministration.list.invite.form.write').toString();
    }
    validateBeforeSubmit() {
        if (this.userList.length > 0) {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.submitInvitations();
                }
            });
        }
    }
    emailExists() {
        if (!this.users)
            return true;
        let emails = this.userList.map((user) => {
            return user.email;
        });
        this.emailIndex = this.users?.findIndex((user) => emails.includes(user.email));
        return this.users?.findIndex((user) => emails.includes(user.email)) === -1;
    }
    async submitInvitations() {
        this.isLoading = true;
        let invitedUsers = [];
        if (this.emailExists()) {
            this.userList.forEach((user) => {
                invitedUsers.push({
                    Email: user.email,
                    Role: user.role.Key,
                    NotificationPreset: user.preset
                });
            });
            let config = {
                Users: invitedUsers,
                Text: this.inviteMessage,
                Locale: this.selectedLang
            };
            let response = await departmentRepository.inviteUsers(config, this.selectedDepartmentId);
            if (response) {
                VueUtilities.openSuccessToast(this, this.$t('component.userAdministration.list.invite.success'));
                this.resultRetrieved(true);
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.userAdministration.list.invite.failure'));
            }
        }
        else
            VueUtilities.openErrorToast(this, this.$t('component.userAdministration.list.invite.email_error', {
                email: this.users[this.emailIndex]?.email
            }));
        this.isLoading = false;
    }
    errorMessage(id) {
        var message = this.errors.first(this.$t('component.userAdministration.list.invite.form.table.email') + id.toString());
        if (message != undefined) {
            message = message.replace(id.toString(), '');
        }
        return message;
    }
};
__decorate([
    Prop({ type: Object })
], InviteUserModal.prototype, "userSettings", void 0);
__decorate([
    Prop({ type: Object, default: null })
], InviteUserModal.prototype, "resendInvitation", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => {
            [];
        }
    })
], InviteUserModal.prototype, "users", void 0);
__decorate([
    Prop({ type: String, default: undefined })
], InviteUserModal.prototype, "selectedDepartmentId", void 0);
InviteUserModal = __decorate([
    Component({ components: {} })
], InviteUserModal);
export default InviteUserModal;
