import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import UserDetailAdministration from '@/views/components/administration/UserDetailAdministration.vue';
import UserRepository from '@/services/repository/UserRepository';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import VueUtilities from '@/services/VueUtilities';
import WarningModal from '@/views/components/administration/WarningModal.vue';
import ChangeUserRoleModal from '@/views/components/administration/ChangeUserRoleModal.vue';
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import { vxm } from '@/store/store.vuex';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import { DepartmentSettingsKeys } from '@/entities/models/DepartmentSettings';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import NotificationSubscriptionCategory from '@/entities/notifications/NotificationSubscriptionCategory';
// eslint-disable-next-line vue/max-len
import MeasuredDataNotification from '@/views/components/notification/configurationPerType/MeasuredDataNotification.vue';
// eslint-disable-next-line vue/max-len
import EntityMeasuredDataNotification from '@/views/components/notification/configurationPerType/EntityMeasuredDataNotification.vue';
// eslint-disable-next-line vue/max-len
import GeneralNotificationComponent from '@/views/components/notification/configurationPerType/GeneralNotificationComponent.vue';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import OtherNotificationSettings from '@/views/components/notification/OtherNotificationSettings.vue';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import TiersRepository from '@/services/repository/TiersRepository';
import UnsavedChangesDialog from '@/views/components/common/UnsavedChangesDialog.vue';
import NotificationTabs from '@/entities/notifications/NotificationTabs';
import NonWorkingHours from '@/views/components/notification/NonWorkingHours.vue';
import Timetable from '@/views/components/notification/Timetable.vue';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import NotificationScheduleType from '@/entities/notifications/NotificationScheduleType';
import LoggedUserManager from '@/services/LoggedUserManager';
var vxDepartmentStore = vxm.departmentStore;
var userRepository;
var notificationSubscriptionRepository;
var departmentRepository;
var tiersRepository;
var userSettingsRepository;
let UserDetail = class UserDetail extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationTabs = NotificationTabs;
        this.user = null;
        this.dateTimeManager = null;
        this.isLoading = true;
        this.isChangeRoleModalActive = false;
        this.notificationSubscriptions = [];
        this.availableTypes = [];
        this.selectedUserId = null;
        this.departmentSettings = null;
        this.presets = [];
        this.selectedPreset = null;
        this.isChangingMode = false;
        this.selectedMeasuredRulesMode = null;
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.tier = null;
        this.timetableEnabled = false;
        this.haveChangesToSave = false;
        this.isUnsavedChangesModalActive = false;
        this.routeToNavigateOnExit = null;
        this.activeTab = NotificationTabs.GENERAL;
        this.defaultScheduleId = null;
        this.defaultSchedule = null;
        this.subscriptionsToCreate = [];
        this.timetableSchedules = [];
        this.schedules = [];
        this.departmentUserSettings = null;
        this.measuredRuleEnabled = null;
        this.userRoles = [];
    }
    get singleMeasuredRule() {
        return this.notificationSubscriptions.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredRules() {
        return this.notificationSubscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredTypes() {
        return this.availableTypes.filter((x) => x.category == NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get systemRules() {
        return this.notificationSubscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.SYSTEM_RULE);
    }
    get hasRulesForEntities() {
        return this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.SOURCE_OR_GROUP;
    }
    get selectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    get hasPresetSelected() {
        return this.selectedPreset != null;
    }
    get nonWorkingSchedules() {
        return this.schedules.filter((x) => x.type == NotificationScheduleType.SPECIAL_DAYS);
    }
    get isSystemAdmin() {
        return LoggedUserManager.isSystemAdmin();
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        userRepository = new UserRepository(this);
        notificationSubscriptionRepository = new NotificationSubscriptionRepository(this);
        departmentRepository = new DepartmentRepository(this);
        tiersRepository = new TiersRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        this.selectedUserId = this.$route.params.userId;
        if (!this.selectedUserId) {
            this.$router.replace({
                name: 'pageNotExist',
                params: {
                    lang: this.$route.params.lang,
                    departmentId: this.$route.params.departmentId
                }
            });
        }
        else {
            this.loadData();
        }
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        this.subscriptionsToCreate = [];
        allPromises.push(userRepository.getUser(this.selectedUserId));
        allPromises.push(notificationSubscriptionRepository.getNotificationTypes());
        allPromises.push(departmentRepository.getDepartment());
        allPromises.push(notificationSubscriptionRepository.getNotificationPresets(this.selectedUserId));
        allPromises.push(tiersRepository.getActiveTier());
        allPromises.push(notificationSubscriptionRepository.getNotificationSchedules(this.selectedUserId, this.selectedDepartmentId));
        allPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.selectedUserId, true, this.selectedDepartmentId));
        allPromises.push(userRepository.getUserRoles());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3], response[4], response[5], response[6], response[7]);
        });
    }
    processLoadedDataForPage(user, types, department, presets, tier, schedules, departmentUserSettings, roles) {
        let timetableMode = departmentUserSettings.notifications.schedule.mode;
        if (timetableMode === NotificationScheduleType.TIMETABLE) {
            this.timetableEnabled = true;
            this.notificationSubscriptions = [];
            this.timetableSchedules = schedules.filter((x) => x.type === NotificationScheduleType.TIMETABLE);
        }
        else {
            this.timetableEnabled = false;
            this.defaultSchedule = schedules.find((x) => x.type === NotificationScheduleType.DEFAULT);
            this.defaultScheduleId = this.defaultSchedule?.id;
            this.notificationSubscriptions = this.defaultSchedule?.subscriptions;
            this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
        }
        this.user = user;
        this.userRoles = roles;
        this.availableTypes = types;
        this.selectedMeasuredRulesMode =
            departmentUserSettings.notifications.rules.mode ||
                NotificationMeasuredRuleScope.COMPANY;
        this.departmentSettings = department.settings;
        this.presets = presets;
        this.tier = tier;
        this.schedules = schedules;
        this.departmentUserSettings = departmentUserSettings;
        this.isLoading = false;
    }
    async reloadSchedules() {
        this.isLoading = true;
        this.schedules = await notificationSubscriptionRepository.getNotificationSchedules(this.user.apiUserId, this.selectedDepartmentId);
        this.departmentUserSettings = await userSettingsRepository.loadUserSettingsForDepartment(this.user.apiUserId, true, this.selectedDepartmentId);
        this.timetableSchedules = this.schedules.filter((x) => x.type === NotificationScheduleType.TIMETABLE);
        this.isLoading = false;
    }
    async updateOptimizeNotificationsSetting(value) {
        this.isLoading = true;
        this.departmentSettings = await departmentRepository.saveDepartmentSetting([DepartmentSettingsKeys.SOURCE, DepartmentSettingsKeys.DO_NOT_NOTIFY], value);
        this.isLoading = false;
    }
    closeModal(data) {
        this.isChangeRoleModalActive = false;
    }
    openChangeRoleModal() {
        this.isChangeRoleModalActive = true;
    }
    async enableUser() {
        this.isLoading = true;
        let response = await userRepository.enableUser(this.user.apiUserId);
        this.isLoading = false;
        if (!response) {
            VueUtilities.openErrorToast(this, this.$t('users.enable_failure'));
        }
        else {
            VueUtilities.openSuccessToast(this, this.$t('users.successfully_enabled'));
            this.loadData();
        }
    }
    async disableUserDialog(user) {
        this.$buefy.dialog.confirm({
            title: `${this.$t('component.userAdministration.list.deptDisable.title')}`,
            message: this.$t('component.userAdministration.list.deptDisable.body', {
                userName: user.fullName
            }),
            confirmText: `${this.$t('component.userAdministration.list.deptDisable.confirm')}`,
            cancelText: `${this.$t('component.userAdministration.list.deptDisable.deny')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let resp = await userRepository.disableUser(user.apiUserId);
                if (resp === false) {
                    VueUtilities.openErrorToast(this, this.$t('users.disable_failure'));
                }
                else {
                    VueUtilities.openSuccessToast(this, this.$t('users.successfully_disabled').toString());
                    this.loadData();
                }
            }
        });
    }
    async changeRole(role, user) {
        let result = await userRepository.changeUserRole(user.apiUserId, role);
        if (result.role.Key === role) {
            VueUtilities.openSuccessToast(this, this.$t('users.change_user_role.success'));
            this.isChangeRoleModalActive = false;
            this.loadData();
        }
        else {
            VueUtilities.openSuccessToast(this, this.$t('users.change_user_role.failure'));
        }
    }
    //triggered when measured rules mode is changed
    async changeMode(selectedMode) {
        this.isChangingMode = true;
        this.measuredRuleEnabled = true;
        this.selectedMeasuredRulesMode = selectedMode;
        this.subscriptionsToCreate = this.subscriptionsToCreate.filter((x) => x.NotificationType !== NotificationSubscriptionType.MEASURED_DATA_ALARM &&
            x.NotificationType !== NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
        this.isChangingMode = false;
    }
    switchedValue(event) {
        this.measuredRuleEnabled = event;
    }
    getMeasuredRuleEnabled() {
        return this.singleMeasuredRule ? this.singleMeasuredRule.enabled : false;
    }
    async applyPreset(preset) {
        this.selectedPreset = preset;
        this.isLoading = true;
        this.notificationSubscriptions = [];
        this.selectedPreset.subscriptions.forEach((subs) => {
            this.notificationSubscriptions.push(NotificationSubscription.convertFromPreset(subs, this.selectedDepartmentId));
        });
        this.measuredRuleEnabled = this.getMeasuredRuleEnabled();
        this.$nextTick(() => {
            this.isLoading = false;
        });
    }
    async saveDefault() {
        let errors = [];
        this.isLoading = true;
        //check if preset is used
        //check if preset was selected and compare current subscriptions with preset
        if (this.selectedPreset) {
            let presetIsSameAsSubs = this.selectedPreset.comparePresetWithSubscriptions(this.subscriptionsToCreate);
            //if subscriptions are same, set preset id
            if (presetIsSameAsSubs == true) {
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = this.selectedPreset.id;
                });
            }
            else {
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = null;
                });
            }
        }
        else {
            // if subscriptions are not same, check if there was some preset defined before and if yes,
            //compare subscriptions from this preset (if exists), otherwise set presetId to null
            let presetIds = [...new Set(this.subscriptionsToCreate.map((x) => x.PresetId))];
            if (presetIds.length === 1 && presetIds[0] != null) {
                let loadedPreset = this.presets.find((x) => x.id === presetIds[0]);
                if (!loadedPreset) {
                    //if preset does not exists
                    this.subscriptionsToCreate.forEach((sub) => {
                        sub.PresetId = null;
                    });
                }
                else {
                    //if preset exists, compare subscriptions
                    let presetIsSameAsSubs = loadedPreset.comparePresetWithSubscriptions(this.subscriptionsToCreate);
                    if (presetIsSameAsSubs == true) {
                        //if subs are same
                        this.subscriptionsToCreate.forEach((sub) => {
                            sub.PresetId = loadedPreset.id;
                        });
                    }
                    else {
                        //if not, set preset id to null
                        this.subscriptionsToCreate.forEach((sub) => {
                            sub.PresetId = null;
                        });
                    }
                }
            }
            else {
                //if there is multiple presets in subscriptions or is null
                this.subscriptionsToCreate.forEach((sub) => {
                    sub.PresetId = null;
                });
            }
        }
        let promises = [];
        //check if type of measured rule was updated
        if (this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.COMPANY) {
            let measuredForEntity = this.defaultSchedule.subscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                [NotificationScopeType.SOURCE, NotificationScopeType.SOURCE_GROUP].includes(x.scopeType));
            measuredForEntity.forEach((subToDel) => {
                promises.push(notificationSubscriptionRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.user.apiUserId, this.selectedDepartmentId));
            });
        }
        else if (this.selectedMeasuredRulesMode === NotificationMeasuredRuleScope.SOURCE_OR_GROUP) {
            let measuredForCompany = this.defaultSchedule.subscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                x.scopeType === NotificationScopeType.COMPANY);
            measuredForCompany.forEach((subToDel) => {
                promises.push(notificationSubscriptionRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.user.apiUserId, this.selectedDepartmentId));
            });
        }
        //update or create subscription
        let rulesToCreate = [];
        this.subscriptionsToCreate.forEach((subs) => {
            let idOfExisting = null;
            if (this.availableTypes.find((x) => x.name === subs.NotificationType).category ==
                NotificationSubscriptionCategory.MEASUREMENT_RULE) {
                idOfExisting = this.defaultSchedule.subscriptions.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                    x.scopeType === subs.ScopeType &&
                    x.scopeId === subs.ScopeId)?.notificationSubscriptionId;
            }
            else {
                idOfExisting = this.defaultSchedule.subscriptions.find((x) => x.notificationType === subs.NotificationType)?.notificationSubscriptionId;
            }
            if (idOfExisting) {
                let subsUpdateDto = {
                    Channels: subs.Channels,
                    NotificationType: subs.NotificationType,
                    PresetId: subs.PresetId,
                    Priority: subs.Priority,
                    DisplayMode: subs.DisplayMode,
                    Enabled: subs.Enabled
                };
                promises.push(notificationSubscriptionRepository.updateNotificationSubscription(idOfExisting, subsUpdateDto, this.user.apiUserId, this.selectedDepartmentId));
            }
            else {
                let newRule = {
                    ScopeType: subs.ScopeType,
                    ScopeId: subs.ScopeId,
                    NotificationType: subs.NotificationType,
                    Enabled: subs.Enabled,
                    Channels: subs.Channels,
                    DisplayMode: subs.DisplayMode,
                    PresetId: subs.PresetId
                };
                rulesToCreate.push(newRule);
            }
        });
        if (rulesToCreate.length > 0) {
            promises.push(notificationSubscriptionRepository.createNotificationSubscription(rulesToCreate, this.user.apiUserId, this.selectedDepartmentId, null));
        }
        //check responses
        await Promise.all(promises).then((response) => {
            errors = response.filter((x) => typeof x === 'string');
        });
        if (errors.length <= 0) {
            await userSettingsRepository.saveUserSettingForDepartment(this.user.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.RULES, UserSettingsKeys.MODE], this.selectedMeasuredRulesMode);
        }
        if (errors.length <= 0) {
            VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.non_working_list.success'));
            this.loadData();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('notifications.configuration.non_working_list.failure', {
                error: errors.join(', ')
            }));
            this.isLoading = false;
        }
        this.isUnsavedChangesModalActive = false;
    }
    //triggered when some subscriptions is changed
    ruleChanged(newRule) {
        if (newRule.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            newRule.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
            let indexOfMeasured = this.subscriptionsToCreate.findIndex((x) => (x.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                x.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) &&
                x.ScopeId === newRule.ScopeId);
            if (indexOfMeasured >= 0) {
                //remove this measured rule by index
                this.subscriptionsToCreate.splice(indexOfMeasured, 1);
            }
        }
        else {
            //remove current subscription from list
            this.subscriptionsToCreate = this.subscriptionsToCreate.filter((x) => x.NotificationType !== newRule.NotificationType);
        }
        this.subscriptionsToCreate.push(newRule);
        this.haveChangesToSave = !NotificationSubscription.compareSubscriptionsWithSubscriptionsCreateDto(this.defaultSchedule.subscriptions, this.subscriptionsToCreate);
    }
    async onEnableTimetable() {
        this.isLoading = true;
        await notificationSubscriptionRepository.changeTimetableModeForUser(this.user.apiUserId, this.selectedDepartmentId);
        VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.use_timetable_success'));
        this.loadData();
    }
    getRule(name) {
        return this.systemRules.find((x) => x.notificationType === name);
    }
    getType(name) {
        return this.availableTypes.find((x) => x.name === name);
    }
    closeUnsavedChangesModal() {
        this.isUnsavedChangesModalActive = false;
    }
    saveUnsavedChanges() {
        this.closeUnsavedChangesModal();
        this.saveDefault();
    }
    tabChanged(tab) {
        //set active tab in url;
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: tab
            })
        })
            .catch((err) => err);
    }
};
UserDetail = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.LIST, Subjects.USERS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {
            UserDetailAdministration,
            WarningModal,
            ChangeUserRoleModal,
            MeasuredDataNotification,
            EntityMeasuredDataNotification,
            GeneralNotificationComponent,
            OtherNotificationSettings,
            UnsavedChangesDialog,
            Timetable,
            NonWorkingHours
        }
    })
], UserDetail);
export default UserDetail;
