var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(
        "b-table",
        {
          attrs: { data: _vm.loggerList },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.loggers.registerLoggers.loggersTable.empty"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "registeredAt",
              label: _vm.$t(
                "admin.component.loggers.registerLoggers.loggersTable.registeredAt"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(loggerList.row.registeredAt),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "macAddress",
              label: _vm.$t(
                "admin.component.loggers.registerLoggers.loggersTable.mac"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [_vm._v(_vm._s(loggerList.row.macAddress))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "loggerId",
              label: _vm.$t(
                "admin.component.loggers.registerLoggers.loggersTable.loggerId"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [_vm._v(_vm._s(loggerList.row.loggerId))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "rssi",
              label: _vm.$t(
                "admin.component.loggers.registerLoggers.loggersTable.rssi"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [_vm._v(_vm._s(loggerList.row.rssi))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "action",
              "cell-class": "actions",
              label: _vm.$t(
                "admin.component.loggers.registerLoggers.loggersTable.action"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _vm.$ability.can(
                      _vm.$permActions.DELETE,
                      _vm.$permSubjects.ADMIN_LOGGERS
                    )
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.deleteClicked(loggerList.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "admin.component.loggers.registerLoggers.loggersTable.actions.delete"
                                )
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }