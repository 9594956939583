import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let AllowedTypesModal = class AllowedTypesModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedEventTypes = [];
        this.selectedComputedEventTypes = [];
        this.isLoading = true;
    }
    async created() {
        this.selectedEventTypes = this.companyLogger.allowedTypes
            .filter((x) => !this.computedEventTypes.some((type) => type.producesType.slug === x.slug))
            .map((x) => x.slug);
        this.selectedComputedEventTypes = this.companyLogger.computedTypes.map((type) => type.key);
    }
    async selected() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (!this.selectedEventTypes.includes('pressure'))
                    this.selectedComputedEventTypes = [];
                this.$emit('typesSelected', this.selectedEventTypes, this.selectedComputedEventTypes);
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], AllowedTypesModal.prototype, "companyLogger", void 0);
__decorate([
    Prop({ type: Array })
], AllowedTypesModal.prototype, "computedEventTypes", void 0);
AllowedTypesModal = __decorate([
    Component({})
], AllowedTypesModal);
export default AllowedTypesModal;
