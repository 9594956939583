var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "multi-header is-flex is-justify-content-space-between mx-3 mx-0-desktop mb-4 mb-1-touch",
      },
      [
        _c(
          "nav",
          {
            staticClass: "breadcrumb my-1",
            attrs: { "aria-label": "breadcrumb" },
          },
          [
            _c("h1", { staticClass: "title has-text-centered-touch" }, [
              _c(
                "ul",
                [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.back()
                          },
                        },
                      },
                      [
                        _vm._m(0),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                      ]
                    ),
                  ]),
                  _vm.prevRoute !== null
                    ? [
                        _vm.prevRoute.name.includes("Device")
                          ? _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "adminDevices",
                                        params: {
                                          lang: _vm.prevRoute.params.lang,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", { attrs: { icon: "hdd" } }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("admin.sidebar.devices"))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.prevRoute.name.includes("User")
                          ? _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "adminUsers",
                                        params: {
                                          lang: _vm.prevRoute.params.lang,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", { attrs: { icon: "users" } }),
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("admin.sidebar.users"))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.prevRoute.name.includes("Logger")
                          ? _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "adminLoggers",
                                        params: {
                                          lang: _vm.prevRoute.params.lang,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "microchip" },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("admin.sidebar.loggers"))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.prevRoute.name.includes("Reseller")
                          ? _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "adminResellers",
                                        params: {
                                          lang: _vm.$route.params.lang,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "box-open" },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("admin.sidebar.resellers")
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.prevRoute.name.includes("User") ||
                        _vm.prevRoute.name.includes("Device") ||
                        _vm.prevRoute.name.includes("Logger") ||
                        _vm.prevRoute.name.includes("Reseller")
                          ? _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: _vm.prevRoute.name,
                                        params: _vm.prevRoute.params,
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(_vm._s(_vm.prevRoute.meta.name)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.prevRoute === null ||
                  _vm.prevRoute.name.includes("Company")
                    ? _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "adminCompanies",
                                  params: {
                                    lang: _vm.$route.params.lang,
                                  },
                                },
                              },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "warehouse" } }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.sidebar.companies"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("li", { staticClass: "is-active" }, [
                    _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                      _vm._v(_vm._s(_vm.company.companyName)),
                    ]),
                  ]),
                ],
                2
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "is-flex" },
          [
            _c(
              "div",
              { staticClass: "mr-3" },
              [
                !_vm.isLayoutList
                  ? _c(
                      "b-tooltip",
                      {
                        attrs: {
                          label: _vm.$t("admin.component.layout.listView"),
                          position: "is-left",
                          type: "is-dark",
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "font-size": "1rem" },
                            on: {
                              click: function ($event) {
                                return _vm.changeLayout()
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { type: "is-primary", icon: "stream" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isLayoutList
                  ? _c(
                      "b-tooltip",
                      {
                        attrs: {
                          label: _vm.$t("admin.component.layout.tabView"),
                          position: "is-left",
                          type: "is-dark",
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "font-size": "1rem" },
                            on: {
                              click: function ($event) {
                                return _vm.changeLayout()
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                type: "is-primary",
                                icon: "layer-group fa-rotate-270",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "b-dropdown",
              {
                attrs: {
                  position: "is-bottom-left",
                  "append-to-body": "",
                  "aria-role": "list",
                },
                scopedSlots: _vm._u([
                  {
                    key: "trigger",
                    fn: function () {
                      return [
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.devices.detail.actions.title"
                              ),
                              position: "is-left",
                              type: "is-dark",
                            },
                          },
                          [
                            _c(
                              "b-button",
                              { staticStyle: { "font-size": "1rem" } },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    type: "is-primary",
                                    icon: "ellipsis-v",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "b-dropdown-item",
                  {
                    staticClass: "custom-dropdown-item",
                    attrs: { "aria-role": "listitem" },
                    on: {
                      click: function ($event) {
                        return _vm.loginToCompany()
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "sign-in-alt" } }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "admin.component.company.detail.companyInfo.loginIntoCompany"
                          )
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
                !_vm.isResellerAdmin &&
                _vm.$ability.can(
                  _vm.$permActions.ADD,
                  _vm.$permSubjects.ADMIN_DEPARTMENTS_USERS
                ) &&
                _vm.$ability.can(
                  _vm.$permActions.LIST,
                  _vm.$permSubjects.ADMIN_USERS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.openAddUsersModal()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "user-plus" } }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.company.detail.companyUsers.addUsers"
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isResellerAdmin
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.openInviteUserModal()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "envelope" } }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.userAdministration.list.inviteUser_btn"
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.UPDATE,
                  _vm.$permSubjects.ADMIN_DEPARTMENTS_SETTINGS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.addNewSetting()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "sliders-h" } }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("company_settings.add_new")) + " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.ADMIN_TIERS
                ) &&
                _vm.$ability.can(
                  _vm.$permActions.CREATE,
                  _vm.$permSubjects.ADMIN_DEPARTMENTS_TIERS
                ) &&
                _vm.$ability.can(
                  _vm.$permActions.ACTIVATE,
                  _vm.$permSubjects.ADMIN_DEPARTMENTS_TIERS
                ) &&
                _vm.$ability.can(
                  _vm.$permActions.DEACTIVATE,
                  _vm.$permSubjects.ADMIN_DEPARTMENTS_TIERS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.openAssignTier()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "layer-group" } }),
                        _vm._v(" " + _vm._s(_vm.$t("tiers.assign_tier")) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.ADMIN_DEPARTMENTS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.createPublicDashboard()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "tachometer-alt" } }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("component.public_dashboard.create.title")
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.UPDATE,
                  _vm.$permSubjects.ADMIN_DEPARTMENTS_SETTINGS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.openPauseNotificationsModal()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "pause" } }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.devices.detail.actions.pauseNotifications"
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.REGISTER,
                  _vm.$permSubjects.ADMIN_GATEWAYS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.openRegisterDevicesModal()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "hdd" } }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.devices.detail.actions.registerDevices"
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.ACTIVATE,
                  _vm.$permSubjects.ADMIN_COMPANYLOGGERS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.openRegisterLoggersModal()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "microchip" } }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.devices.detail.actions.registerLoggers"
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    !_vm.isLayoutList
      ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
          _c("div", { staticClass: "tile is-parent" }, [
            _c(
              "div",
              { staticClass: "box tile is-child" },
              [
                _c(
                  "b-tabs",
                  {
                    staticClass: "custom-layout-tabs",
                    attrs: { type: "is-boxed" },
                    on: {
                      input: function ($event) {
                        return _vm.changedActiveTab($event)
                      },
                    },
                    model: {
                      value: _vm.activeTab,
                      callback: function ($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab",
                    },
                  },
                  [
                    _c(
                      "b-tab-item",
                      {
                        attrs: {
                          label: _vm.$t(
                            "admin.component.company.detail.tabs.overview"
                          ),
                        },
                      },
                      [
                        _c("AdminCompanyInfoComponent", {
                          staticClass: "mb-5",
                          attrs: {
                            company: _vm.company,
                            dateTimeManager: _vm.dateTimeManager,
                            allUsers: _vm.allUsers,
                            departmentTags: _vm.departmentTags,
                          },
                          on: {
                            loginIntoCompany: _vm.loginToCompany,
                            updateCompany: _vm.updateCompany,
                            loadAllUsers: _vm.loadAllUsers,
                          },
                        }),
                        _c("AdminCompanyUsersComponent", {
                          staticClass: "mb-5",
                          attrs: {
                            users: _vm.users,
                            dateTimeManager: _vm.dateTimeManager,
                            company: _vm.company,
                          },
                          on: {
                            changeRole: _vm.changeRole,
                            unassignUser: _vm.unassignUser,
                            openAddUsersModal: _vm.openAddUsersModal,
                            disableUserDialog: _vm.disableUserDialog,
                            enableUserDialog: _vm.enableUserDialog,
                          },
                        }),
                        _vm.$ability.can(
                          _vm.$permActions.READ,
                          _vm.$permSubjects.ADMIN_DEPARTMENTS_SETTINGS
                        )
                          ? _c("AdminDepartmentSettingsList", {
                              staticClass: "mb-5",
                              attrs: {
                                id: "departmentSetting",
                                isAdding: _vm.isSettingAdding,
                                companySettings: _vm.companySettings,
                                dateTimeManager: _vm.dateTimeManager,
                              },
                              on: {
                                changeAddSettingState:
                                  _vm.changeAddSettingState,
                                saveSetting: _vm.saveSetting,
                                deleteSetting: _vm.deleteSetting,
                              },
                            })
                          : _vm._e(),
                        _c("hr"),
                        _vm.$ability.can(
                          _vm.$permActions.DELETE,
                          _vm.$permSubjects.ADMIN_DEPARTMENTS
                        )
                          ? _c(
                              "div",
                              { staticClass: "section-header-level mt-2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "title-with-edit" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        icon: "exclamation-triangle",
                                        type: "is-primary",
                                        size: "is-medium",
                                      },
                                    }),
                                    _c("h2", { staticClass: "subtitle is-4" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "admin.component.company.detail.deleteCompany",
                                              {
                                                departmentName:
                                                  _vm.company.companyName,
                                              }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "is-flex mt-5 mb-3" }, [
                          _c("p", { attrs: { type: "is-danger" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("admin.component.company.detail.message")
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "is-primary",
                              "icon-pack": "fa",
                              "icon-left": "trash",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCompany()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.company.detail.deleteCompany_Btn"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tab-item",
                      {
                        attrs: {
                          label: _vm.$t(
                            "admin.component.company.detail.tabs.devicesAndLoggers"
                          ),
                        },
                      },
                      [
                        _vm.$ability.can(
                          _vm.$permActions.READ,
                          _vm.$permSubjects.ADMIN_GATEWAYS
                        )
                          ? _c("AdminCompanyDevicesComponent", {
                              staticClass: "mb-5",
                              attrs: {
                                devices: _vm.deviceList,
                                dateTimeManager: _vm.dateTimeManager,
                                company: _vm.company,
                                totalDeviceListSize: _vm.totalDeviceListSize,
                              },
                              on: { loadDevices: _vm.loadDevicesForDepartment },
                            })
                          : _vm._e(),
                        _c("AdminCompanyLoggersComponent", {
                          staticClass: "mb-5",
                          attrs: {
                            companyLoggers: _vm.companyLoggers,
                            dateTimeManager: _vm.dateTimeManager,
                            company: _vm.company,
                            sources: _vm.sources,
                          },
                          on: { downloadCalibration: _vm.downloadCalibration },
                        }),
                        _vm.deletedSourcesApiResponse
                          ? _c("AdminCompanyDeletedSources", {
                              attrs: {
                                dateTimeManager: _vm.dateTimeManager,
                                deletedSourcesApiResponse:
                                  _vm.deletedSourcesApiResponse,
                              },
                              on: {
                                loadDeletedSources: _vm.loadDeletedSources,
                                reviveSource: _vm.reviveSource,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-tab-item",
                      {
                        attrs: {
                          label: _vm.$t(
                            "admin.component.company.detail.tabs.tier"
                          ),
                        },
                      },
                      [
                        _vm.currentConfig.features.tiers === true
                          ? _c("ActiveCompanyTierDetail", {
                              staticClass: "mb-5",
                              attrs: {
                                activeTier: _vm.activeTier,
                                company: _vm.company,
                                dateTimeManager: _vm.dateTimeManager,
                              },
                              on: {
                                reloadTiers: _vm.reloadTiers,
                                updateTier: _vm.updateTier,
                              },
                            })
                          : _vm._e(),
                        _vm.currentConfig.features.tiers === true
                          ? _c("CompanyTiersHistory", {
                              attrs: {
                                tiersHistory: _vm.tiersHistory,
                                dateTimeManager: _vm.dateTimeManager,
                              },
                              on: {
                                deactivateTier: _vm.deactivateTier,
                                deleteTier: _vm.deleteTier,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-tab-item",
                      {
                        attrs: {
                          label: _vm.$t(
                            "admin.component.company.detail.tabs.sharedLinks"
                          ),
                        },
                      },
                      [
                        _c("DashboardsList", {
                          attrs: {
                            dashboards: _vm.dashboards,
                            totalSize: _vm.dashboards.length,
                          },
                          on: { deleteDashboard: _vm.deleteDashboard },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-tab-item",
                      { attrs: { label: _vm.$t("component.issues.title") } },
                      [
                        _c("IssuesTable", {
                          attrs: {
                            dateTimeManager: _vm.dateTimeManager,
                            departmentIssues: _vm.departmentIssues,
                            devices: _vm.devices,
                            companyLoggers: _vm.companyLoggers,
                            sources: _vm.sources,
                            sourceGroups: _vm.sourceGroups,
                            issuesLoaded: _vm.issuesLoaded,
                            fromAdmin: true,
                            isIssuesTableLoading: _vm.isIssuesTableLoading,
                          },
                          on: {
                            onOptionsChange: _vm.onIssuesTableOptionsChange,
                            acknowledgeIssue: _vm.acknowledgeIssue,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      : _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
          _c(
            "div",
            { staticClass: "tile is-parent" },
            [
              _c("AdminCompanyInfoComponent", {
                staticClass: "box tile is-child",
                attrs: {
                  company: _vm.company,
                  dateTimeManager: _vm.dateTimeManager,
                  allUsers: _vm.allUsers,
                  departmentTags: _vm.departmentTags,
                },
                on: {
                  loginIntoCompany: _vm.loginToCompany,
                  updateCompany: _vm.updateCompany,
                  loadAllUsers: _vm.loadAllUsers,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tile is-parent" },
            [
              _c("AdminCompanyUsersComponent", {
                staticClass: "box tile is-child",
                attrs: {
                  users: _vm.users,
                  dateTimeManager: _vm.dateTimeManager,
                  company: _vm.company,
                },
                on: {
                  changeRole: _vm.changeRole,
                  unassignUser: _vm.unassignUser,
                  openAddUsersModal: _vm.openAddUsersModal,
                  disableUserDialog: _vm.disableUserDialog,
                  enableUserDialog: _vm.enableUserDialog,
                },
              }),
            ],
            1
          ),
          _vm.$ability.can(
            _vm.$permActions.READ,
            _vm.$permSubjects.ADMIN_DEPARTMENTS_SETTINGS
          )
            ? _c(
                "div",
                { staticClass: "tile is-parent" },
                [
                  _c("AdminDepartmentSettingsList", {
                    staticClass: "box tile is-child",
                    attrs: {
                      id: "departmentSetting",
                      isAdding: _vm.isSettingAdding,
                      companySettings: _vm.companySettings,
                      dateTimeManager: _vm.dateTimeManager,
                    },
                    on: {
                      changeAddSettingState: _vm.changeAddSettingState,
                      saveSetting: _vm.saveSetting,
                      deleteSetting: _vm.deleteSetting,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.currentConfig.features.tiers === true
            ? _c(
                "div",
                { staticClass: "tile is-parent" },
                [
                  _c("ActiveCompanyTierDetail", {
                    staticClass: "box tile is-child",
                    attrs: {
                      activeTier: _vm.activeTier,
                      company: _vm.company,
                      dateTimeManager: _vm.dateTimeManager,
                    },
                    on: {
                      reloadTiers: _vm.reloadTiers,
                      updateTier: _vm.updateTier,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.currentConfig.features.tiers === true
            ? _c(
                "div",
                { staticClass: "tile is-parent" },
                [
                  _c("CompanyTiersHistory", {
                    staticClass: "box tile is-child",
                    attrs: {
                      tiersHistory: _vm.tiersHistory,
                      dateTimeManager: _vm.dateTimeManager,
                    },
                    on: {
                      deactivateTier: _vm.deactivateTier,
                      deleteTier: _vm.deleteTier,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.$ability.can(
            _vm.$permActions.READ,
            _vm.$permSubjects.ADMIN_GATEWAYS
          )
            ? _c(
                "div",
                { staticClass: "tile is-parent" },
                [
                  _c("AdminCompanyDevicesComponent", {
                    staticClass: "box tile is-child",
                    attrs: {
                      devices: _vm.deviceList,
                      dateTimeManager: _vm.dateTimeManager,
                      company: _vm.company,
                      totalDeviceListSize: _vm.totalDeviceListSize,
                    },
                    on: { loadDevices: _vm.loadDevicesForDepartment },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "tile is-parent" },
            [
              _c("AdminCompanyLoggersComponent", {
                staticClass: "box tile is-child",
                attrs: {
                  companyLoggers: _vm.companyLoggers,
                  dateTimeManager: _vm.dateTimeManager,
                  company: _vm.company,
                  sources: _vm.sources,
                },
                on: { downloadCalibration: _vm.downloadCalibration },
              }),
            ],
            1
          ),
          _vm.deletedSourcesApiResponse
            ? _c(
                "div",
                { staticClass: "tile is-parent" },
                [
                  _c("AdminCompanyDeletedSources", {
                    staticClass: "box tile is-child",
                    attrs: {
                      dateTimeManager: _vm.dateTimeManager,
                      deletedSourcesApiResponse: _vm.deletedSourcesApiResponse,
                    },
                    on: {
                      loadDeletedSources: _vm.loadDeletedSources,
                      reviveSource: _vm.reviveSource,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DASHBOARD)
            ? _c(
                "div",
                { staticClass: "tile is-parent" },
                [
                  _c("DashboardsList", {
                    staticClass: "box tile is-child",
                    attrs: {
                      dashboards: _vm.dashboards,
                      totalSize: _vm.dashboards.length,
                    },
                    on: { deleteDashboard: _vm.deleteDashboard },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.ISSUES)
            ? _c(
                "div",
                { staticClass: "tile is-parent" },
                [
                  _c("IssuesTable", {
                    staticClass: "box tile is-child",
                    attrs: {
                      departmentIssues: _vm.departmentIssues,
                      devices: _vm.devices,
                      companyLoggers: _vm.companyLoggers,
                      dateTimeManager: _vm.dateTimeManager,
                      sources: _vm.sources,
                      sourceGroups: _vm.sourceGroups,
                      issuesLoaded: _vm.issuesLoaded,
                      fromAdmin: true,
                      isIssuesTableLoading: _vm.isIssuesTableLoading,
                    },
                    on: {
                      onOptionsChange: _vm.onIssuesTableOptionsChange,
                      acknowledgeIssue: _vm.acknowledgeIssue,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("hr", { staticClass: "mx-3" }),
          _vm.$ability.can(
            _vm.$permActions.DELETE,
            _vm.$permSubjects.ADMIN_DEPARTMENTS
          )
            ? _c("div", { staticClass: "tile is-parent" }, [
                _c(
                  "div",
                  { staticClass: "box tile is-child" },
                  [
                    _c("div", { staticClass: "section-header-level" }, [
                      _c(
                        "div",
                        { staticClass: "title-with-edit" },
                        [
                          _c("b-icon", {
                            staticClass: "mr-1",
                            attrs: {
                              icon: "exclamation-triangle",
                              type: "is-primary",
                              size: "is-medium",
                            },
                          }),
                          _c("h2", { staticClass: "subtitle is-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.company.detail.deleteCompany",
                                    { departmentName: _vm.company.companyName }
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "is-flex mt-5 mb-3" }, [
                      _c("p", { attrs: { type: "is-danger" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("admin.component.company.detail.message")
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          type: "is-primary",
                          "icon-pack": "fa",
                          "icon-left": "trash",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteCompany()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.company.detail.deleteCompany_Btn"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }