var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading && !_vm.isChangingMode && _vm.dateTimeManager
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c("div", { staticClass: "tile is-child" }, [
                _c("h2", { staticClass: "subtitle is-4 mb-0 pb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("notifications.configuration.non_working_title")
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass:
                      "is-size-7 is-italic has-text-grey has-text-grey-dark-mode pb-5",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "notifications.configuration.non_working_subtitle"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column is-one-third-desktop" },
                    [
                      _vm.nonWorkingSchedules.length > 0
                        ? _c(
                            "b-table",
                            {
                              attrs: {
                                data: _vm.nonWorkingSchedules,
                                narrowed: "",
                                "mobile-cards": false,
                                "default-sort-direction": "asc",
                                "default-sort": "validFrom",
                              },
                            },
                            [
                              _c("b-table-column", {
                                attrs: {
                                  field: "validFrom",
                                  label: _vm.$t(
                                    "notifications.configuration.non_working_list.date"
                                  ),
                                  sortable: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (props) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getDateRange(props.row)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  395584591
                                ),
                              }),
                              _c("b-table-column", {
                                attrs: {
                                  field: "name",
                                  label: _vm.$t(
                                    "notifications.configuration.non_working_list.name"
                                  ),
                                  sortable: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (props) {
                                        return [
                                          _vm._v(
                                            " " + _vm._s(props.row.name) + " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2982927002
                                ),
                              }),
                              _c("b-table-column", {
                                attrs: {
                                  field: "action",
                                  label: _vm.$t(
                                    "notifications.configuration.non_working_list.action"
                                  ),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "is-flex is-flex-direction-row is-align-items-center",
                                            },
                                            [
                                              props.row.additionalProperties
                                                .isEditing === false
                                                ? _c("b-icon", {
                                                    staticClass:
                                                      "control-icon clickable",
                                                    attrs: {
                                                      icon: "pencil-alt",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.editNonWorking(
                                                          props.row
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _c("b-icon", {
                                                    staticClass:
                                                      "control-icon clickable has-text-primary",
                                                    attrs: {
                                                      icon: "times-circle",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.cancelEditNonWorking(
                                                          props.row
                                                        )
                                                      },
                                                    },
                                                  }),
                                              _c("b-icon", {
                                                staticClass:
                                                  "control-icon clickable",
                                                attrs: { icon: "trash" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.confirmSpecialScheduleDelete(
                                                      props.row
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1997404658
                                ),
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "notifications.configuration.non_working_list.empty"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                      !_vm.isCreating && !_vm.scheduleWhatIsEditing
                        ? _c(
                            "b-button",
                            {
                              staticClass: "button is-primary mt-4",
                              attrs: {
                                outlined: "",
                                expanded: "",
                                size: "is-small",
                                "icon-left": "plus",
                              },
                              on: { click: _vm.addNew },
                            },
                            [_vm._v(_vm._s(_vm.$t("generals.add")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.isCreating || _vm.scheduleWhatIsEditing
                  ? _c(
                      "div",
                      [
                        _c("hr"),
                        _c(
                          "div",
                          {
                            staticClass:
                              "multi-header has-bottom-margin mx-3 mx-0-desktop",
                          },
                          [
                            _vm.scheduleWhatIsEditing
                              ? _c("h3", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "notifications.configuration.non_working_list.editing_title",
                                          {
                                            name: _vm.scheduleWhatIsEditing
                                              .name,
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("h3", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "notifications.configuration.non_working_list.creating_title"
                                      )
                                    )
                                  ),
                                ]),
                            _c("div", { staticClass: "is-flex-grow-2" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "buttons is-pulled-right is-justify-content-end",
                                },
                                [
                                  _vm.scheduleWhatIsEditing
                                    ? [
                                        !_vm.copiedNotificationSchedule ||
                                        _vm.copiedNotificationSchedule ==
                                          null ||
                                        (_vm.copiedNotificationSchedule &&
                                          _vm.scheduleWhatIsEditing.id !==
                                            _vm.copiedNotificationSchedule.id)
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass: "mr-0",
                                                attrs: {
                                                  "icon-left": "clone",
                                                  type: "is-primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.copySchedule()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.non_working_list.copy_schedule"
                                                    )
                                                  ) + " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                  (_vm.scheduleWhatIsEditing &&
                                    _vm.copiedNotificationSchedule &&
                                    _vm.scheduleWhatIsEditing.id !==
                                      _vm.copiedNotificationSchedule.id) ||
                                  (_vm.isCreating &&
                                    _vm.copiedNotificationSchedule)
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            "icon-left": "clipboard",
                                            type: "is-primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.pasteSchedule()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "notifications.configuration.non_working_list.paste_schedule"
                                              )
                                            ) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "columns" }, [
                          _c(
                            "div",
                            { staticClass: "column is-one-half-desktop" },
                            [
                              _vm._l(
                                _vm.nonWorkingDateList,
                                function (nonWorking, index) {
                                  return [
                                    _c(
                                      "b-field",
                                      {
                                        key: index,
                                        staticClass: "is-flex",
                                        attrs: { grouped: "" },
                                      },
                                      [
                                        _c(
                                          "b-field",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "notifications.configuration.non_working_list.date_select"
                                              ),
                                              type: {
                                                "is-danger": _vm.errors.has(
                                                  _vm.$t(
                                                    "notifications.configuration.non_working_list.date"
                                                  )
                                                ),
                                              },
                                              message: _vm.errors.first(
                                                _vm.$t(
                                                  "notifications.configuration.non_working_list.date"
                                                )
                                              ),
                                              "label-position": "on-border",
                                            },
                                          },
                                          [
                                            _c("b-datepicker", {
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "placeholders.click_select"
                                                ),
                                                range: "",
                                                "first-day-of-week": 1,
                                                name: _vm.$t(
                                                  "notifications.configuration.non_working_list.date"
                                                ),
                                                "date-formatter": function (
                                                  date
                                                ) {
                                                  return _vm.dateTimeManager.formatTimeRangeInInput(
                                                    date
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.nonWorkingDateList[index]
                                                    .dateRange,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.nonWorkingDateList[
                                                      index
                                                    ],
                                                    "dateRange",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "nonWorkingDateList[index].dateRange",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-field",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "notifications.configuration.non_working_list.name"
                                              ),
                                              grouped: "",
                                              type: {
                                                "is-danger": _vm.errors.has(
                                                  _vm.$t(
                                                    "notifications.configuration.non_working_list.name"
                                                  )
                                                ),
                                              },
                                              message: _vm.errors.first(
                                                _vm.$t(
                                                  "notifications.configuration.non_working_list.name"
                                                )
                                              ),
                                              "label-position": "on-border",
                                            },
                                          },
                                          [
                                            _c("b-input", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required|max:50",
                                                  expression:
                                                    "'required|max:50'",
                                                },
                                              ],
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "notifications.configuration.non_working_list.name_placeholder"
                                                ),
                                                type: "text",
                                                name: _vm.$t(
                                                  "notifications.configuration.non_working_list.name"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.nonWorkingDateList[index]
                                                    .name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.nonWorkingDateList[
                                                      index
                                                    ],
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "nonWorkingDateList[index].name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.nonWorkingDateList.length > 1
                                          ? _c("button", {
                                              staticClass:
                                                "delete is-vcentered mt-2",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.remove(index)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                }
                              ),
                              _vm.isCreating
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "button is-primary mt-4",
                                      attrs: {
                                        outlined: "",
                                        "icon-left": "plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.newNonWorkingDate()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "notifications.configuration.non_working_list.addMore"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _c("hr"),
                        _c(
                          "p",
                          {
                            staticClass:
                              "pt-4 pb-5 subtitle has-text-weight-semibold",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "notifications.configuration.general_or_preset"
                                  )
                                ) +
                                " "
                            ),
                            _c(
                              "b-dropdown",
                              {
                                attrs: { "aria-role": "list" },
                                on: { change: _vm.applyPresetNonWorking },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "trigger",
                                      fn: function (ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "is-italic is-underlined clickable-text",
                                              attrs: { role: "button" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.general_or_preset_link"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: active
                                                    ? "caret-up"
                                                    : "caret-down",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1251524264
                                ),
                                model: {
                                  value: _vm.selectedPresetNonWorking,
                                  callback: function ($$v) {
                                    _vm.selectedPresetNonWorking = $$v
                                  },
                                  expression: "selectedPresetNonWorking",
                                },
                              },
                              _vm._l(_vm.presets, function (preset) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: preset.id,
                                    attrs: {
                                      "aria-role": "listitem",
                                      value: preset,
                                    },
                                  },
                                  [_vm._v(_vm._s(preset.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.hasRulesForEntitiesNonWorking
                          ? _c("MeasuredDataNotification", {
                              attrs: {
                                rule: _vm.singleMeasuredRuleNonWorking,
                                types: _vm.measuredTypes,
                                currentUser: _vm.currentUser,
                                selectedMeasuredRulesMode:
                                  _vm.selectedMeasuredRulesModeNonWorking,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                measuredRuleEnabled: _vm.measuredRuleEnabled,
                                tier: _vm.tier,
                              },
                              on: {
                                changeMode: _vm.changeModeNonWorking,
                                ruleChanged: _vm.nonWorkingChanged,
                                switchedValue: _vm.switchedValue,
                              },
                            })
                          : _c("EntityMeasuredDataNotification", {
                              attrs: {
                                rules: _vm.measuredRulesNonWorking,
                                types: _vm.measuredTypes,
                                currentUser: _vm.currentUser,
                                selectedMeasuredRulesMode:
                                  _vm.selectedMeasuredRulesModeNonWorking,
                                departmentId: _vm.departmentId,
                                autoSave: false,
                                tier: _vm.tier,
                              },
                              on: {
                                changeMode: _vm.changeModeNonWorking,
                                ruleChanged: _vm.nonWorkingChanged,
                              },
                            }),
                        _c("hr"),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                            ),
                            currentUser: _vm.currentUser,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("hr"),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                            ),
                            currentUser: _vm.currentUser,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("hr"),
                        _c("h2", { staticClass: "subtitle has-text-dark" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "notifications.configuration.other_rules_title"
                              )
                            )
                          ),
                        ]),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.LOW_BATTERY
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.LOW_BATTERY
                            ),
                            currentUser: _vm.currentUser,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType
                                .CUSTOMER_ENDING_CALIBRATION
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType
                                .CUSTOMER_ENDING_CALIBRATION
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType
                                .PDF_RECORDS_REGENERATED
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType
                                .PDF_RECORDS_REGENERATED
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType
                                .AUTOMATIC_REPORTS_GENERATED
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType
                                .AUTOMATIC_REPORTS_GENERATED
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.RECORDS_UPDATED
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.RECORDS_UPDATED
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                        _c("GeneralNotificationComponent", {
                          attrs: {
                            rule: _vm.getRuleForNonWorking(
                              _vm.NotificationSubscriptionType.LOGGER_STATE
                            ),
                            type: _vm.getType(
                              _vm.NotificationSubscriptionType.LOGGER_STATE
                            ),
                            currentUser: _vm.currentUser,
                            advancedAllowed: false,
                            departmentId: _vm.departmentId,
                            autoSave: false,
                          },
                          on: { ruleChanged: _vm.nonWorkingChanged },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.isCreating || _vm.scheduleWhatIsEditing
        ? _c("div", { staticClass: "fixed-form-footer" }, [
            _c("span", { staticClass: "pb-2 has-text-weight-bold" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "notifications.configuration.timetable.save_description"
                  )
                )
              ),
            ]),
            _c(
              "div",
              { staticClass: "buttons is-centered" },
              [
                _c(
                  "b-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.cancel()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("generals.cancel")))]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-success" },
                    on: {
                      click: function ($event) {
                        return _vm.saveNonWorkingHours()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("generals.save")))]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }