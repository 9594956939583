var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "columns is-mobile",
      class: _vm.data.isVisible ? "is-table-row-selected-mobile" : "",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "column is-3-desktop is-5-touch is-flex is-align-items-center",
        },
        [
          _c("p", { staticClass: "text-wrap-anywhere" }, [
            _vm._v(_vm._s(_vm.data.source.name)),
          ]),
          _c(
            "div",
            { staticClass: "status-container mt-1 mx-2" },
            [
              _c(
                "b-tooltip",
                {
                  attrs: {
                    label: _vm.$t("error_messages.low_battery"),
                    position: "is-right",
                    type: "is-dark",
                  },
                },
                [
                  _vm.data.source.logger &&
                  _vm.data.source.logger.hasLowBattery()
                    ? _c("b-icon", {
                        staticClass: "status-item",
                        attrs: {
                          icon: "battery-quarter",
                          size: "is-small",
                          type: "is-primary",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-tooltip",
                {
                  attrs: {
                    label: _vm.$t("error_messages.signal_lost"),
                    position: "is-right",
                    type: "is-dark",
                  },
                },
                [
                  !_vm.data.source.logger ||
                  _vm.data.source.logger.isOnline == false
                    ? _c("img", {
                        staticClass: "signal",
                        attrs: { src: require("@/assets/img/no_signal.svg") },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "column is-3-desktop is-hidden-touch is-flex is-align-items-center",
        },
        [
          _vm.data.source.logger
            ? _c("p", { staticClass: "text-wrap-anywhere" }, [
                _vm._v(_vm._s(_vm.data.source.logger.serialNumber)),
              ])
            : _c("p"),
        ]
      ),
      _c("div", { staticClass: "column is-6-touch is-6-desktop" }, [
        _c(
          "div",
          { staticClass: "columns is-mobile is-flex is-align-items-center" },
          [
            _vm.hasErrorOrActiveService
              ? _c("div", { staticClass: "column is-4-desktop is-12-touch" }, [
                  _vm.data.source.logger &&
                  _vm.data.source.logger.serviceState == _vm.ServiceState.ERROR
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-tag",
                            { attrs: { rounded: "", type: "is-danger" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("loggers.logger_states.Error")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.data.source.activeService
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-tag",
                            { attrs: { rounded: "", type: "is-info" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("loggers.logger_states.Service")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "column is-12-touch",
                class: _vm.hasErrorOrActiveService
                  ? "is-8-desktop"
                  : "is-12-desktop",
              },
              _vm._l(_vm.data.events, function (event) {
                return _c(
                  "div",
                  {
                    key: event.eventType.id,
                    staticClass:
                      "columns is-mobile is-flex is-align-items-center",
                  },
                  [
                    !_vm.hasErrorOrActiveService
                      ? _c(
                          "div",
                          { staticClass: "column is-6-desktop is-12-touch" },
                          [
                            event.eventType.id == _vm.UPTIME_ID
                              ? _c("div", [
                                  _vm.data.source.checkIsOnline()
                                    ? _c(
                                        "span",
                                        { staticClass: "has-text-success" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("generals.online"))
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "has-text-primary" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("generals.offline"))
                                          ),
                                        ]
                                      ),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "is-flex is-flex-wrap-wrap" },
                                  [
                                    _c("div", { staticClass: "mr-2" }, [
                                      _c(
                                        "p",
                                        {
                                          class: {
                                            [event.status.textClass]:
                                              event.status.name ===
                                              _vm.MeasurementStatuses.ALARM
                                                .name,
                                            "has-text-weight-bold":
                                              event.eventType.type == 0,
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass:
                                              "is-justify-content-flex-start",
                                            attrs: {
                                              icon: event.eventType.icon,
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.DecimalConversion.toCommaString(
                                                  event.value
                                                ) + event.unit
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "b-tooltip",
                                          {
                                            staticClass: "is-hidden-touch",
                                            attrs: {
                                              type: "is-dark",
                                              position: "is-left",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "content",
                                                  fn: function () {
                                                    return [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getTooltipText(
                                                              _vm.getBoundaryForService(
                                                                event,
                                                                _vm.data
                                                                  .measuredBoundaries
                                                              )
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            event.boundaryDiff.Difference
                                              .length > 0
                                              ? _c(
                                                  "b-tag",
                                                  {
                                                    staticClass: "status-item",
                                                    attrs: {
                                                      rounded: "",
                                                      type: event.boundaryDiff
                                                        .Status.class,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          event.boundaryDiff
                                                            .Difference +
                                                            event.unit
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        event.boundaryDiff.Difference.length > 0
                                          ? _c(
                                              "b-tag",
                                              {
                                                staticClass:
                                                  "status-item is-hidden-desktop",
                                                attrs: {
                                                  rounded: "",
                                                  type: event.boundaryDiff
                                                    .Status.class,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      event.boundaryDiff
                                                        .Difference + event.unit
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ]
                        )
                      : _vm._e(),
                    _vm.isOneDay(new Date(event.measuredAt * 1000))
                      ? _c(
                          "div",
                          {
                            staticClass: "column is-hidden-touch",
                            class: _vm.hasErrorOrActiveService
                              ? "is-12"
                              : "is-6",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getTimeDifference(
                                    new Date(event.measuredAt * 1000)
                                  )
                                ) +
                                " "
                            ),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  type: "is-dark",
                                  label: _vm.dateTimeManager.formatTime(
                                    new Date(event.measuredAt * 1000)
                                  ),
                                  position: "is-left",
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    size: "is-small",
                                    icon: "question-circle",
                                    type: "is-grey",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "column is-hidden-touch",
                            class: _vm.hasErrorOrActiveService
                              ? "is-12"
                              : "is-6",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dateTimeManager.formatTime(
                                    new Date(event.measuredAt * 1000),
                                    null,
                                    null,
                                    null
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "column is-1 is-flex is-justify-content-end is-align-items-center is-hidden-desktop",
        },
        [
          _vm.data.isVisible
            ? _c("b-icon", { attrs: { icon: "chevron-down" } })
            : _c("b-icon", { attrs: { icon: "chevron-right" } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }