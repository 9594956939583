var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "section-header-level" },
        [
          _c("div", { staticClass: "title-with-edit" }, [
            _c("h2", { staticClass: "subtitle is-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("component.public_dashboard.dashboard_list.title")
                  ) +
                  " "
              ),
            ]),
          ]),
          _c(
            "b-dropdown",
            {
              attrs: { position: "is-bottom-left", "aria-role": "list" },
              scopedSlots: _vm._u([
                {
                  key: "trigger",
                  fn: function () {
                    return [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.company.list.actions.columns"
                            ),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            { staticStyle: { "font-size": "1rem" } },
                            [
                              _c("b-icon", {
                                attrs: { type: "is-primary", icon: "list" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            _vm._l(_vm.tableColumns, function (column) {
              return _c(
                "b-dropdown-item",
                {
                  key: column.field,
                  staticClass: "custom-dropdown-item",
                  attrs: {
                    focusable: false,
                    custom: "",
                    "aria-role": "listitem",
                  },
                },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: {
                        disabled: column.field == _vm.tableColumns.name.field,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.chooseTableColumns(column.field, $event)
                        },
                      },
                      model: {
                        value: column.visible,
                        callback: function ($$v) {
                          _vm.$set(column, "visible", $$v)
                        },
                        expression: "column.visible",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.public_dashboard.dashboard_list." +
                                column.field
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "table-wrap",
          attrs: {
            data: _vm.dashboards,
            paginated: "",
            "current-page": _vm.page,
            total: _vm.totalSize,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            striped: "",
            "per-page": _vm.pageSize,
            "page-input": true,
            "pagination-order": "is-centered",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "page-change": _vm.onPageChange,
            sort: _vm.onSort,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.dashboards && _vm.dashboards.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.totalSize,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.name.field,
              label: _vm.$t(
                "component.public_dashboard.dashboard_list." +
                  _vm.tableColumns.name.field
              ),
              visible: _vm.tableColumns.name.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (dashboards) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.getRouterName(),
                            params: {
                              dashboardId: dashboards.row.id,
                              departmentId: _vm.getDepartmentId(),
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [
                        dashboards.row.name
                          ? _c("p", [
                              _vm._v(" " + _vm._s(dashboards.row.name) + " "),
                            ])
                          : _c(
                              "p",
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.public_dashboard.dashboard.title"
                                      )
                                    ) +
                                    " "
                                ),
                                _vm.dateTimeManager
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dateTimeManager.formatTime(
                                              new Date(
                                                dashboards.row.createdAt * 1000
                                              ),
                                              null,
                                              null,
                                              null
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdBy.field,
              label: _vm.$t(
                "component.public_dashboard.dashboard_list." +
                  _vm.tableColumns.createdBy.field
              ),
              visible: _vm.tableColumns.createdBy.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (dashboards) {
                  return [
                    _vm._v(" " + _vm._s(dashboards.row.createdBy.email) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.sourceList.field,
              label: _vm.$t(
                "component.public_dashboard.dashboard_list." +
                  _vm.tableColumns.sourceList.field
              ),
              visible: _vm.tableColumns.sourceList.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (dashboards) {
                  return [
                    _vm._v(
                      " " + _vm._s(dashboards.row.sourceList.length) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.gatewayList.field,
              label: _vm.$t(
                "component.public_dashboard.dashboard_list." +
                  _vm.tableColumns.gatewayList.field
              ),
              visible: _vm.tableColumns.gatewayList.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (dashboards) {
                  return [
                    _vm._v(
                      " " + _vm._s(dashboards.row.gatewayList.length) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.sourceGroupList.field,
              label: _vm.$t(
                "component.public_dashboard.dashboard_list." +
                  _vm.tableColumns.sourceGroupList.field
              ),
              visible: _vm.tableColumns.sourceGroupList.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (dashboards) {
                  return [
                    _vm._v(
                      " " + _vm._s(dashboards.row.sourceGroupList.length) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.isPublic.field,
              label: _vm.$t(
                "component.public_dashboard.dashboard_list." +
                  _vm.tableColumns.isPublic.field
              ),
              visible: _vm.tableColumns.isPublic.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (dashboards) {
                  return [
                    dashboards.row.isPublic
                      ? _c("i", { staticClass: "fas fa-check" })
                      : _c("i", { staticClass: "fas fa-times" }),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              label: _vm.$t("component.public_dashboard.dashboard_list.action"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (dashboards) {
                  return [
                    _c("b-icon", {
                      staticClass: "control-icon clickable",
                      attrs: { icon: "trash" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.confirmDashboardDelete(dashboards.row.id)
                        },
                      },
                    }),
                    dashboards.row.hasSourcesOrGroups()
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "publicDashboard",
                                params: {
                                  departmentId: _vm.getDepartmentId(),
                                  dashboardKey: dashboards.row.dashboardKey,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _c(
                              "b-button",
                              { attrs: { type: "is-info", size: "is-small" } },
                              [_vm._v(_vm._s(_vm.$t("generals.view")))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.public_dashboard.dashboard_list.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }