var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "header",
        { staticClass: "modal-card-head" },
        [
          _c("b-icon", { attrs: { size: "is-medium", icon: "volume-up" } }),
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.device.deviceSirenModal.title")) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-table",
            {
              staticClass: "siren-intervals-table",
              attrs: { data: _vm.sirenIntervals, "mobile-cards": false },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "content has-text-grey" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.device.deviceSirenModal.table.empty"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.device.deviceSirenModal.table.interval",
                                { index: row.index + 1 }
                              )
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "my-2" },
                          [
                            _c(
                              "b-field",
                              {
                                attrs: {
                                  horizontal: "",
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "component.device.deviceSirenModal.table.activeSound"
                                      ) + row.index
                                    ),
                                  },
                                  message: _vm.errorMessage(row.index, true),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "p",
                                            { staticClass: "text-no-wrap" },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      size: "is-medium",
                                                      icon: "volume-up",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "component.device.deviceSirenModal.table.activeSound"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("b-numberinput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        "required|numeric|min_value:1|max_value:60",
                                      expression:
                                        "'required|numeric|min_value:1|max_value:60'",
                                    },
                                  ],
                                  attrs: {
                                    name:
                                      _vm.$t(
                                        "component.device.deviceSirenModal.table.activeSound"
                                      ) + row.index,
                                    type: "is-light",
                                    min: 1,
                                    max: 60,
                                    expanded: "",
                                    exponential: "",
                                  },
                                  model: {
                                    value: _vm.sirenIntervals[row.index].active,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sirenIntervals[row.index],
                                        "active",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "sirenIntervals[row.index].active",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "b-field",
                              {
                                attrs: {
                                  horizontal: "",
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "component.device.deviceSirenModal.table.pauseSound"
                                      ) + row.index
                                    ),
                                  },
                                  message: _vm.errorMessage(row.index, false),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "p",
                                            { staticClass: "text-no-wrap" },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      size: "is-medium",
                                                      icon: "volume-mute",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "component.device.deviceSirenModal.table.pauseSound"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("b-numberinput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        "required|numeric|min_value:1|max_value:60",
                                      expression:
                                        "'required|numeric|min_value:1|max_value:60'",
                                    },
                                  ],
                                  attrs: {
                                    name:
                                      _vm.$t(
                                        "component.device.deviceSirenModal.table.pauseSound"
                                      ) + row.index,
                                    type: "is-light",
                                    min: 1,
                                    max: 60,
                                    expanded: "",
                                    exponential: "",
                                  },
                                  model: {
                                    value: _vm.sirenIntervals[row.index].pause,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sirenIntervals[row.index],
                                        "pause",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "sirenIntervals[row.index].pause",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.sirenIntervals.length > 1
                ? _c("b-table-column", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("b-button", {
                                attrs: {
                                  "icon-right": "times",
                                  size: "is-small",
                                  type: "is-primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeInterval(row.index)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3567503614
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.totalSirenIntervalSum() > 60
            ? _c("b-message", { attrs: { type: "is-danger" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.device.deviceSirenModal.table.maxIntervalSumError"
                      )
                    ) +
                    " "
                ),
              ])
            : _c("p", { staticClass: "is-6" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.device.deviceSirenModal.table.maxIntervalSum"
                      )
                    ) +
                    " "
                ),
              ]),
          _c(
            "div",
            { staticClass: "has-text-centered mt-5" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary", outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.addInterval()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "component.device.deviceSirenModal.table.addInterval"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: {
                type: "is-primary",
                disabled: _vm.sirenIntervals.length == 0,
              },
              on: {
                click: function ($event) {
                  return _vm.validateBeforeSubmit()
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("component.device.deviceSirenModal.configure"))
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }