var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("admin.component.user.createUserModal.title")) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t("admin.component.user.createUserModal.email"),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t("admin.component.user.createUserModal.email")
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.user.createUserModal.email")
                ),
                "label-position": "on-border",
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email",
                    expression: "'required|email'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.user.createUserModal.email"
                  ),
                  name: _vm.$t("admin.component.user.createUserModal.email"),
                  type: "email",
                },
                on: { input: _vm.setvalue },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t("admin.component.user.createUserModal.username"),
                "label-position": "on-border",
              },
            },
            [
              _c("b-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.user.createUserModal.username"
                  ),
                  name: _vm.$t("admin.component.user.createUserModal.username"),
                  type: "text",
                  disabled: "",
                },
                model: {
                  value: _vm.username,
                  callback: function ($$v) {
                    _vm.username = $$v
                  },
                  expression: "username",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t("admin.component.user.createUserModal.password"),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t("admin.component.user.createUserModal.password")
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.user.createUserModal.password")
                ),
                "label-position": "on-border",
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|password|min:8",
                    expression: "'required|password|min:8'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.user.createUserModal.password"
                  ),
                  name: _vm.$t("admin.component.user.createUserModal.password"),
                  type: "password",
                  "password-reveal": "",
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t("admin.component.user.createUserModal.firstName"),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t("admin.component.user.createUserModal.firstName")
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.user.createUserModal.firstName")
                ),
                "label-position": "on-border",
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:20",
                    expression: "'required|min:2|max:20'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.user.createUserModal.firstName"
                  ),
                  name: _vm.$t(
                    "admin.component.user.createUserModal.firstName"
                  ),
                  type: "text",
                },
                model: {
                  value: _vm.firstName,
                  callback: function ($$v) {
                    _vm.firstName = $$v
                  },
                  expression: "firstName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t("admin.component.user.createUserModal.lastName"),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t("admin.component.user.createUserModal.lastName")
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.user.createUserModal.lastName")
                ),
                "label-position": "on-border",
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:20",
                    expression: "'required|min:2|max:20'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.user.createUserModal.lastName"
                  ),
                  name: _vm.$t("admin.component.user.createUserModal.lastName"),
                  type: "text",
                },
                model: {
                  value: _vm.lastName,
                  callback: function ($$v) {
                    _vm.lastName = $$v
                  },
                  expression: "lastName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.user.createUserModal.phoneNumber"
                ),
                type: {
                  "is-danger":
                    _vm.errors.has(
                      _vm.$t("admin.component.user.createUserModal.phoneNumber")
                    ) || _vm.hasErrorMsg("PhoneNumber"),
                },
                message:
                  _vm.errors.first(
                    _vm.$t("admin.component.user.createUserModal.phoneNumber")
                  ) || _vm.getErrorMsg("PhoneNumber"),
                "label-position": "on-border",
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "phoneE164",
                    expression: "'phoneE164'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.user.createUserModal.phoneNumberField"
                  ),
                  name: _vm.$t(
                    "admin.component.user.createUserModal.phoneNumber"
                  ),
                  type: "tel",
                },
                on: { input: _vm.phoneNumberChanged },
                model: {
                  value: _vm.phoneNumber,
                  callback: function ($$v) {
                    _vm.phoneNumber = $$v
                  },
                  expression: "phoneNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.user.createUserModal.keyValuePairs"
                ),
              },
            },
            [
              _c(
                "b-table",
                {
                  staticClass: "invite-user-table",
                  attrs: { data: _vm.keyValueArray },
                  scopedSlots: _vm._u([
                    {
                      key: "empty",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.user.createUserModal.empty"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (keyValueArray) {
                          return [
                            _c(
                              "b-field",
                              {
                                staticClass: "is-flex-grow-2 mr-1 mt-2",
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.user.createUserModal.properties.key"
                                  ),
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "admin.component.user.createUserModal.properties.key"
                                      ) + keyValueArray.row[0]
                                    ),
                                  },
                                  message: _vm.errorMessageKey(
                                    keyValueArray.row[0]
                                  ),
                                  "label-position": "on-border",
                                },
                              },
                              [
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t(
                                      "admin.component.user.createUserModal.properties.key"
                                    ),
                                    expanded: "",
                                    name:
                                      _vm.$t(
                                        "admin.component.user.createUserModal.properties.key"
                                      ) + keyValueArray.row[0],
                                  },
                                  model: {
                                    value: keyValueArray.row[0],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        keyValueArray.row,
                                        0,
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "keyValueArray.row[0]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (keyValueArray) {
                          return [
                            _c(
                              "b-field",
                              {
                                staticClass: "is-flex-grow-3 mt-2",
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.user.createUserModal.properties.value"
                                  ),
                                  type: {
                                    "is-danger": _vm.errors.has(
                                      _vm.$t(
                                        "admin.component.user.createUserModal.properties.value"
                                      ) + keyValueArray.row[1]
                                    ),
                                  },
                                  message: _vm.errorMessageValue(
                                    keyValueArray.row[1]
                                  ),
                                  "label-position": "on-border",
                                },
                              },
                              [
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t(
                                      "admin.component.user.createUserModal.properties.value"
                                    ),
                                    expanded: "",
                                    name:
                                      _vm.$t(
                                        "admin.component.user.createUserModal.properties.value"
                                      ) + keyValueArray.row[1],
                                  },
                                  model: {
                                    value: keyValueArray.row[1],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        keyValueArray.row,
                                        1,
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "keyValueArray.row[1]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("button", {
                              staticClass: "delete is-vcentered mt-2",
                              on: {
                                click: function ($event) {
                                  return _vm.removeKeyValue(
                                    _vm.keyValueArray[row.index]
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "has-text-centered" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary", outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.addKeyValue()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("admin.component.user.createUserModal.addBtn")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.createUser()
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("admin.component.user.createUserModal.confirm"))
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }