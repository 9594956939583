var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card logger-modal-header" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.report.detail_table.report_export.pdf_export.title"
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-tabs",
            {
              staticClass: "mb-1",
              attrs: { type: "is-boxed" },
              model: {
                value: _vm.selectedMode,
                callback: function ($$v) {
                  _vm.selectedMode = $$v
                },
                expression: "selectedMode",
              },
            },
            [
              _c("b-tab-item", {
                attrs: {
                  label: _vm.$t("reports.report_mode.default"),
                  value: _vm.PdfReportTypes.FULL,
                },
              }),
              _c("b-tab-item", {
                attrs: {
                  label: _vm.$t("reports.report_mode.one-page"),
                  value: _vm.PdfReportTypes.ONE_PAGE,
                },
              }),
              _c("b-tab-item", {
                attrs: {
                  label: _vm.$t("reports.report_mode.raw-data"),
                  value: _vm.PdfReportTypes.RAW,
                },
              }),
            ],
            1
          ),
          _vm.selectedMode === _vm.PdfReportTypes.RAW
            ? _c("p", { staticClass: "pb-2 has-text-centered is-italic" }, [
                _vm._v(" " + _vm._s(_vm.$t("reports.raw_data_note")) + " "),
              ])
            : _vm._e(),
          _vm.selectedMode !== _vm.PdfReportTypes.RAW
            ? _c(
                "b-field",
                {
                  staticClass: "content-full-width",
                  attrs: {
                    grouped: "",
                    label: _vm.$t(
                      "component.report.detail_table.report_export.range"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "component.report.detail_table.report_export.range"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "component.report.detail_table.report_export.range"
                      )
                    ),
                  },
                },
                [
                  _vm.isDay
                    ? _c("b-datepicker", {
                        staticClass: "not-clickable",
                        attrs: {
                          "mobile-native": false,
                          position: "is-bottom-left",
                          placeholder: _vm.$t(
                            "component.report.month_picker.placeholder"
                          ),
                          "first-day-of-week": 1,
                          "max-date": _vm.today,
                          "date-formatter": function (date) {
                            return _vm.dateTimeManager.formatDate(date)
                          },
                        },
                        model: {
                          value: _vm.currentlySelectedDay,
                          callback: function ($$v) {
                            _vm.currentlySelectedDay = $$v
                          },
                          expression: "currentlySelectedDay",
                        },
                      })
                    : _vm.isTwoDay
                    ? _c("b-datepicker", {
                        staticClass: "not-clickable",
                        attrs: {
                          "mobile-native": false,
                          position: "is-bottom-left",
                          placeholder: _vm.$t(
                            "component.report.month_picker.placeholder"
                          ),
                          range: "",
                          "first-day-of-week": 1,
                          "max-date": _vm.today,
                          "date-formatter": function (date) {
                            return _vm.dateTimeManager.formatTimeRangeInInput(
                              date
                            )
                          },
                        },
                        model: {
                          value: _vm.currentlySelectedDays,
                          callback: function ($$v) {
                            _vm.currentlySelectedDays = $$v
                          },
                          expression: "currentlySelectedDays",
                        },
                      })
                    : _vm.isWeek
                    ? _c("b-datepicker", {
                        staticClass: "not-clickable",
                        attrs: {
                          "mobile-native": false,
                          position: "is-bottom-left",
                          placeholder: _vm.$t(
                            "component.report.month_picker.placeholder"
                          ),
                          range: "",
                          "first-day-of-week": 1,
                          "max-date": _vm.today,
                          "date-formatter": function (date) {
                            return _vm.dateTimeManager.formatTimeRangeInInput(
                              date
                            )
                          },
                        },
                        model: {
                          value: _vm.currentlySelectedWeek,
                          callback: function ($$v) {
                            _vm.currentlySelectedWeek = $$v
                          },
                          expression: "currentlySelectedWeek",
                        },
                      })
                    : _vm.isTwoWeek
                    ? _c("b-datepicker", {
                        staticClass: "not-clickable",
                        attrs: {
                          "mobile-native": false,
                          position: "is-bottom-left",
                          placeholder: _vm.$t(
                            "component.report.month_picker.placeholder"
                          ),
                          range: "",
                          "first-day-of-week": 1,
                          "max-date": _vm.today,
                          "date-formatter": function (date) {
                            return _vm.dateTimeManager.formatTimeRangeInInput(
                              date
                            )
                          },
                        },
                        model: {
                          value: _vm.currentlySelectedWeeks,
                          callback: function ($$v) {
                            _vm.currentlySelectedWeeks = $$v
                          },
                          expression: "currentlySelectedWeeks",
                        },
                      })
                    : _vm.isLastMonth
                    ? _c("b-datepicker", {
                        staticClass: "not-clickable",
                        attrs: {
                          type: "month",
                          "mobile-native": false,
                          position: "is-bottom-left",
                          placeholder: _vm.$t(
                            "component.report.month_picker.placeholder"
                          ),
                          "icon-pack": "fa",
                          icon: "calendar-alt",
                          "max-date": _vm.today,
                          "date-formatter": function (date) {
                            return _vm.dateTimeManager
                              ? _vm.dateTimeManager.formatMonthNameInInput(date)
                              : null
                          },
                          "month-names": _vm.dateTimeManager
                            ? _vm.dateTimeManager.getMonthNames()
                            : [],
                        },
                        model: {
                          value: _vm.currentlySelectedLastMonth,
                          callback: function ($$v) {
                            _vm.currentlySelectedLastMonth = $$v
                          },
                          expression: "currentlySelectedLastMonth",
                        },
                      })
                    : _vm.isMonth
                    ? _c("b-datepicker", {
                        attrs: {
                          type: "month",
                          "mobile-native": false,
                          position: "is-bottom-left",
                          placeholder: _vm.$t(
                            "component.report.month_picker.placeholder"
                          ),
                          "icon-pack": "fa",
                          icon: "calendar-alt",
                          "max-date": _vm.today,
                          "date-formatter": function (date) {
                            return _vm.dateTimeManager
                              ? _vm.dateTimeManager.formatMonthNameInInput(date)
                              : null
                          },
                          "month-names": _vm.dateTimeManager
                            ? _vm.dateTimeManager.getMonthNames()
                            : [],
                        },
                        on: { input: _vm.dateMonthChanged },
                        model: {
                          value: _vm.currentlySelectedMonth,
                          callback: function ($$v) {
                            _vm.currentlySelectedMonth = $$v
                          },
                          expression: "currentlySelectedMonth",
                        },
                      })
                    : _c(
                        "b-datepicker",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            name: _vm.$t(
                              "component.report.detail_table.report_export.range"
                            ),
                            "mobile-native": false,
                            range: "",
                            "date-formatter": function (date) {
                              return _vm.dateTimeManager.formatTimeRangeInInput(
                                date
                              )
                            },
                            "month-names": _vm.dateTimeManager.getMonthNames(),
                            "nearby-selectable-month-days": true,
                            "max-date": _vm.today,
                            "first-day-of-week": 1,
                          },
                          on: { input: _vm.dateRangeChanged },
                          model: {
                            value: _vm.dateRange,
                            callback: function ($$v) {
                              _vm.dateRange = $$v
                            },
                            expression: "dateRange",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "has-text-danger is-italic has-text-centered",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.report.configuration.modal.range_note",
                                      { days: _vm.reportRange }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                  _c(
                    "b-select",
                    {
                      attrs: {
                        placeholder: _vm.$t(
                          "component.report.detail_table.report_export.range"
                        ),
                      },
                      on: { input: _vm.selectCalType },
                      model: {
                        value: _vm.selectedValue,
                        callback: function ($$v) {
                          _vm.selectedValue = $$v
                        },
                        expression: "selectedValue",
                      },
                    },
                    [
                      _c(
                        "option",
                        { domProps: { value: _vm.DateRangeType.ONE_DAY } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.detail_table.report_export.one_day"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "option",
                        { domProps: { value: _vm.DateRangeType.TWO_DAYS } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.detail_table.report_export.two_days"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "option",
                        { domProps: { value: _vm.DateRangeType.ONE_WEEK } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.detail_table.report_export.one_week"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "option",
                        { domProps: { value: _vm.DateRangeType.TWO_WEEKS } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.detail_table.report_export.two_weeks"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "option",
                        { domProps: { value: _vm.DateRangeType.LAST_MONTH } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.detail_table.report_export.last_month"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "option",
                        { domProps: { value: _vm.DateRangeType.MONTH } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.detail_table.report_export.month"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "option",
                        { domProps: { value: _vm.DateRangeType.RANGE } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.detail_table.report_export.fromto"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-flex-wrap-wrap is-align-items-center",
                  },
                  [
                    _c(
                      "b-field",
                      {
                        staticClass: "mr-3",
                        attrs: {
                          label: _vm.$t(
                            "component.report.detail_table.report_export.dateFrom"
                          ),
                        },
                      },
                      [
                        _c("b-datepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            "mobile-native": false,
                            "month-names": _vm.dateTimeManager.getMonthNames(),
                            "date-formatter": function (date) {
                              return _vm.dateTimeManager.formatDateInInput(date)
                            },
                            "nearby-selectable-month-days": true,
                            "max-date": _vm.today,
                            "first-day-of-week": 1,
                          },
                          on: { input: _vm.checkWholeDay },
                          model: {
                            value: _vm.rawMinDate,
                            callback: function ($$v) {
                              _vm.rawMinDate = $$v
                            },
                            expression: "rawMinDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      {
                        staticClass: "time-input mr-5",
                        attrs: {
                          label: _vm.$t(
                            "component.report.detail_table.report_export.timeFrom"
                          ),
                        },
                      },
                      [
                        _c("b-timepicker", {
                          attrs: { "hour-format": "24", inline: "" },
                          model: {
                            value: _vm.rawMinDate,
                            callback: function ($$v) {
                              _vm.rawMinDate = $$v
                            },
                            expression: "rawMinDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      { staticClass: "is-align-self-flex-end mb-4" },
                      [
                        _c(
                          "b-checkbox",
                          {
                            on: { input: _vm.setWholeDay },
                            model: {
                              value: _vm.wholeDayReport,
                              callback: function ($$v) {
                                _vm.wholeDayReport = $$v
                              },
                              expression: "wholeDayReport",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.report.detail_table.report_export.24hours"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-flex-wrap-wrap is-align-items-center",
                  },
                  [
                    _c(
                      "b-field",
                      {
                        staticClass: "mr-3",
                        attrs: {
                          label: _vm.$t(
                            "component.report.detail_table.report_export.dateTo"
                          ),
                        },
                      },
                      [
                        _c("b-datepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            disabled: _vm.wholeDayReport,
                            "mobile-native": false,
                            "month-names": _vm.dateTimeManager.getMonthNames(),
                            "date-formatter": function (date) {
                              return _vm.dateTimeManager.formatDateInInput(date)
                            },
                            "nearby-selectable-month-days": true,
                            "max-date": _vm.today,
                            "first-day-of-week": 1,
                          },
                          model: {
                            value: _vm.rawMaxDate,
                            callback: function ($$v) {
                              _vm.rawMaxDate = $$v
                            },
                            expression: "rawMaxDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      {
                        staticClass: "time-input mr-5",
                        attrs: {
                          label: _vm.$t(
                            "component.report.detail_table.report_export.timeTo"
                          ),
                        },
                      },
                      [
                        _c("b-timepicker", {
                          attrs: {
                            disabled: _vm.wholeDayReport,
                            "hour-format": "24",
                            inline: "",
                          },
                          model: {
                            value: _vm.rawMaxDate,
                            callback: function ($$v) {
                              _vm.rawMaxDate = $$v
                            },
                            expression: "rawMaxDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.dateRangeCheck,
                            expression: "!dateRangeCheck",
                          },
                        ],
                        staticClass:
                          "is-align-self-flex-end mb-4 has-text-danger",
                      },
                      [_vm._v(_vm._s(_vm.wrongRangeMsg))]
                    ),
                  ],
                  1
                ),
              ],
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.report.detail_table.report_export.for_entity"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "component.report.detail_table.report_export.for_entity"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "component.report.detail_table.report_export.for_entity"
                  )
                ),
              },
            },
            _vm._l(_vm.PdfScope, function (el) {
              return _c(
                "b-radio",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  key: el,
                  attrs: {
                    name: _vm.$t(
                      "component.report.detail_table.report_export.for_entity"
                    ),
                    "native-value": el,
                  },
                  model: {
                    value: _vm.selectedEntity,
                    callback: function ($$v) {
                      _vm.selectedEntity = $$v
                    },
                    expression: "selectedEntity",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.report.detail_table.report_export.entities." +
                            el
                        )
                      ) +
                      " "
                  ),
                ]
              )
            }),
            1
          ),
          _vm.selectedEntity
            ? _c(
                "b-field",
                [
                  _vm.selectedEntity == _vm.PdfScope.LOGGER
                    ? _c(
                        "b-table",
                        {
                          staticClass:
                            "select-location-table border is-full-width",
                          attrs: {
                            data: _vm.sources,
                            "checked-rows": _vm.selectedSources,
                            paginated: true,
                            "per-page": 5,
                            narrowed: "",
                            checkable: "",
                            "mobile-cards": false,
                          },
                          on: {
                            "update:checkedRows": function ($event) {
                              _vm.selectedSources = $event
                            },
                            "update:checked-rows": function ($event) {
                              _vm.selectedSources = $event
                            },
                          },
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              field: "name",
                              label: _vm.$t(
                                "component.notification.create_rule.entity_table.name"
                              ),
                              "cell-class": "text-wrap-anywhere",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (sources) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(sources.row.name)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2608515585
                            ),
                          }),
                          _c("template", { slot: "bottom-left" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.notification.create_rule.entity_table.selected_count"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(
                              " : " + _vm._s(_vm.selectedSources.length) + " "
                            ),
                          ]),
                        ],
                        2
                      )
                    : _vm.selectedEntity === _vm.PdfScope.GROUP
                    ? _c(
                        "b-table",
                        {
                          staticClass:
                            "select-location-table border is-full-width",
                          attrs: {
                            data: _vm.sourceGroups,
                            "checked-rows": _vm.selectedSourceGroups,
                            paginated: true,
                            "per-page": 5,
                            narrowed: "",
                            checkable: "",
                            "mobile-cards": false,
                          },
                          on: {
                            "update:checkedRows": function ($event) {
                              _vm.selectedSourceGroups = $event
                            },
                            "update:checked-rows": function ($event) {
                              _vm.selectedSourceGroups = $event
                            },
                          },
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              field: "name",
                              label: _vm.$t(
                                "component.notification.create_rule.entity_table.name"
                              ),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (groups) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(groups.row.name)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2249423201
                            ),
                          }),
                          _c("template", { slot: "bottom-left" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.notification.create_rule.entity_table.selected_count"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(
                              " : " +
                                _vm._s(_vm.selectedSourceGroups.length) +
                                " "
                            ),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode === _vm.PdfReportTypes.FULL
            ? _c(
                "b-field",
                {
                  staticClass: "radio-buttons",
                  attrs: {
                    grouped: "",
                    "group-multiline": "",
                    label: _vm.$t(
                      "component.report.detail_table.report_export.pdf_export.records"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "component.report.detail_table.report_export.pdf_export.records"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.records"
                      )
                    ),
                  },
                },
                _vm._l(_vm.RecordsTableTypes, function (el) {
                  return _c(
                    "b-radio-button",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      key: el,
                      attrs: {
                        name: _vm.$t(
                          "component.report.detail_table.report_export.pdf_export.records"
                        ),
                        "native-value": el,
                        type: "is-primary",
                      },
                      model: {
                        value: _vm.selectedRecordsTableType,
                        callback: function ($$v) {
                          _vm.selectedRecordsTableType = $$v
                        },
                        expression: "selectedRecordsTableType",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.detail_table.report_export.pdf_export.records_table." +
                                  el
                              )
                            )
                        ),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "b-field",
            {
              attrs: {
                grouped: "",
                label: _vm.$t(
                  "component.report.configuration.modal.reportingInterval"
                ),
              },
            },
            [
              _c(
                "b-select",
                {
                  on: { input: _vm.reportingIntervalChanged },
                  model: {
                    value: _vm.interval,
                    callback: function ($$v) {
                      _vm.interval = $$v
                    },
                    expression: "interval",
                  },
                },
                _vm._l(_vm.allReportingIntervals, function (interval) {
                  return _c(
                    "option",
                    { key: interval[0], domProps: { value: interval[0] } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.report.reportingIntervals." +
                                interval[1]
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              staticClass: "border",
              attrs: { grouped: "", "group-multiline": "" },
            },
            [
              _c(
                "b-field",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-radio",
                    {
                      attrs: { "native-value": _vm.StatisticsType.AVERAGE },
                      on: { input: _vm.typeChanged },
                      model: {
                        value: _vm.selectedType,
                        callback: function ($$v) {
                          _vm.selectedType = $$v
                        },
                        expression: "selectedType",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.report.configuration.modal.averageValues"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "b-tooltip",
                        {
                          staticClass: "pl-2",
                          attrs: {
                            type: "is-dark",
                            position: "is-bottom",
                            label: _vm.$t(
                              "component.report.configuration.modal.average_note"
                            ),
                            multilined: "",
                          },
                        },
                        [
                          _c("b-icon", {
                            staticClass: "has-text-grey",
                            attrs: { icon: "question-circle" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-field",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-radio",
                    {
                      attrs: { "native-value": _vm.StatisticsType.DAILY },
                      on: { input: _vm.typeChanged },
                      model: {
                        value: _vm.selectedType,
                        callback: function ($$v) {
                          _vm.selectedType = $$v
                        },
                        expression: "selectedType",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.report.configuration.modal.measuredValues"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.hasOneDayIntervalSelected == false
                ? _c(
                    "b-field",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        label: _vm.$t(
                          "component.report.configuration.modal.measuredAt"
                        ),
                      },
                    },
                    [
                      _vm.canHaveOffset
                        ? _c(
                            "b-select",
                            {
                              staticStyle: { "max-width": "125px" },
                              on: {
                                input: function ($event) {
                                  return _vm.measuredOffsetChanged()
                                },
                              },
                              model: {
                                value: _vm.measurementOffsetIndex,
                                callback: function ($$v) {
                                  _vm.measurementOffsetIndex = $$v
                                },
                                expression: "measurementOffsetIndex",
                              },
                            },
                            _vm._l(
                              _vm.allMeasuredTimes,
                              function (time, index) {
                                return _c(
                                  "option",
                                  { key: time, domProps: { value: index } },
                                  [_vm._v(" " + _vm._s(time) + " ")]
                                )
                              }
                            ),
                            0
                          )
                        : _c("b-select", { attrs: { disabled: "" } }, [
                            _c("option", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.report.configuration.modal.measuredAt"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                    ],
                    1
                  )
                : _c(
                    "b-field",
                    [
                      _vm.canHaveOffset
                        ? _c("b-clockpicker", {
                            attrs: {
                              placeholder: _vm.$t("placeholders.click_select"),
                              icon: "clock",
                              "hour-format": _vm.hourFormatInClockPicker,
                              "hours-label": _vm.$t("placeholders.hours"),
                              "minutes-label": _vm.$t("placeholders.minutes"),
                              editable: "",
                              position: "is-top-left",
                            },
                            on: { input: _vm.clockChanged },
                            model: {
                              value: _vm.timeOfMeasurement,
                              callback: function ($$v) {
                                _vm.timeOfMeasurement = $$v
                              },
                              expression: "timeOfMeasurement",
                            },
                          })
                        : _c("b-clockpicker", {
                            attrs: {
                              placeholder: _vm.$t("placeholders.click_select"),
                              icon: "clock",
                            },
                            on: { input: _vm.clockChanged },
                          }),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.report.detail_table.report_export.eventTypes"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "component.report.detail_table.report_export.eventTypes"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "component.report.detail_table.report_export.eventTypes"
                  )
                ),
              },
            },
            [
              _vm.hasTemp !== -1
                ? _c(
                    "b-checkbox",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "mb-2",
                      attrs: {
                        name: _vm.$t(
                          "component.report.configuration.modal.eventTypes"
                        ),
                        "native-value": "temperature",
                      },
                      model: {
                        value: _vm.selectedEventTypes,
                        callback: function ($$v) {
                          _vm.selectedEventTypes = $$v
                        },
                        expression: "selectedEventTypes",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("services.temperature")) + " ")]
                  )
                : _vm._e(),
              _vm.hasHum !== -1
                ? _c(
                    "b-checkbox",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "mb-2",
                      attrs: {
                        name: _vm.$t(
                          "component.report.configuration.modal.eventTypes"
                        ),
                        "native-value": "humidity",
                      },
                      model: {
                        value: _vm.selectedEventTypes,
                        callback: function ($$v) {
                          _vm.selectedEventTypes = $$v
                        },
                        expression: "selectedEventTypes",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("services.humidity")) + " ")]
                  )
                : _vm._e(),
              _vm.hasPress !== -1
                ? _c(
                    "b-checkbox",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "mb-2",
                      attrs: {
                        name: _vm.$t(
                          "component.report.configuration.modal.eventTypes"
                        ),
                        "native-value": "pressure",
                      },
                      model: {
                        value: _vm.selectedEventTypes,
                        callback: function ($$v) {
                          _vm.selectedEventTypes = $$v
                        },
                        expression: "selectedEventTypes",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("services.pressure")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.selectedMode === _vm.PdfReportTypes.FULL
            ? _c(
                "b-field",
                {
                  staticClass: "flex-space-between",
                  attrs: {
                    label: _vm.$t(
                      "component.report.detail_table.report_export.pdf_export.chart"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "component.report.detail_table.report_export.pdf_export.chart"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.chart"
                      )
                    ),
                  },
                },
                [
                  _c("b-switch", {
                    attrs: {
                      name: _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.chart"
                      ),
                    },
                    model: {
                      value: _vm.showChart,
                      callback: function ($$v) {
                        _vm.showChart = $$v
                      },
                      expression: "showChart",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showChart && _vm.selectedMode !== _vm.PdfReportTypes.RAW
            ? _c("div", { staticClass: "border mb-2" }, [
                _c("div", [
                  _c("p", { staticClass: "p-1" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.report.detail_table.report_export.pdf_export.chartOptions.options"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm.temperatureService
                  ? _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "px-1 py-1",
                          staticStyle: { color: "#c2c2c2" },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.report.detail_table.report_export.pdf_export.chartOptions.temperature"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.temperatureService
                  ? _c("div", { staticClass: "columns m-0" }, [
                      _c(
                        "div",
                        { staticClass: "column px-2 py-0" },
                        [
                          _c(
                            "b-field",
                            {
                              staticClass: "is-flex",
                              attrs: {
                                label: _vm.$t(
                                  "component.report.detail_table.report_export.pdf_export.chartOptions.y_axis"
                                ),
                                type: {
                                  "is-danger": _vm.errors.has(
                                    _vm.$t(
                                      "component.report.detail_table.report_export.pdf_export.chartOptions.y_axis"
                                    )
                                  ),
                                },
                                message: _vm.errors.first(
                                  _vm.$t(
                                    "component.report.detail_table.report_export.pdf_export.chartOptions.y_axis"
                                  )
                                ),
                              },
                            },
                            [
                              _c("b-switch", {
                                staticClass: "ml-5",
                                attrs: {
                                  name: _vm.$t(
                                    "component.report.detail_table.report_export.pdf_export.chartOptions.y_axis"
                                  ),
                                },
                                model: {
                                  value: _vm.isTempRange,
                                  callback: function ($$v) {
                                    _vm.isTempRange = $$v
                                  },
                                  expression: "isTempRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isTempRange
                        ? _c(
                            "div",
                            { staticClass: "column is-flex px-2 py-0" },
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    type: {
                                      "is-danger": _vm.errors.has(
                                        _vm.$t(
                                          "component.report.detail_table.report_export.pdf_export.chartOptions.min"
                                        )
                                      ),
                                    },
                                    message: _vm.errors.first(
                                      _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.min"
                                      )
                                    ),
                                  },
                                },
                                [
                                  _c("b-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      type: "number",
                                      name: _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.min"
                                      ),
                                      placeholder: _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.min"
                                      ),
                                    },
                                    model: {
                                      value: _vm.temperatureCrop.min,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.temperatureCrop,
                                          "min",
                                          $$v
                                        )
                                      },
                                      expression: "temperatureCrop.min",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  staticClass: "pl-2",
                                  attrs: {
                                    type: {
                                      "is-danger": _vm.errors.has(
                                        _vm.$t(
                                          "component.report.detail_table.report_export.pdf_export.chartOptions.max"
                                        )
                                      ),
                                    },
                                    message: _vm.errors.first(
                                      _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.max"
                                      )
                                    ),
                                  },
                                },
                                [
                                  _c("b-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      type: "number",
                                      name: _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.max"
                                      ),
                                      placeholder: _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.max"
                                      ),
                                    },
                                    model: {
                                      value: _vm.temperatureCrop.max,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.temperatureCrop,
                                          "max",
                                          $$v
                                        )
                                      },
                                      expression: "temperatureCrop.max",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.humidityService
                  ? _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "px-1 py-1",
                          staticStyle: { color: "#c2c2c2" },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.report.detail_table.report_export.pdf_export.chartOptions.humidity"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.humidityService
                  ? _c("div", { staticClass: "columns m-0" }, [
                      _c(
                        "div",
                        { staticClass: "column px-2 py-0" },
                        [
                          _c(
                            "b-field",
                            {
                              staticClass: "is-flex",
                              attrs: {
                                label: _vm.$t(
                                  "component.report.detail_table.report_export.pdf_export.chartOptions.y_axis"
                                ),
                                type: {
                                  "is-danger": _vm.errors.has(
                                    _vm.$t(
                                      "component.report.detail_table.report_export.pdf_export.chartOptions.y_axis"
                                    )
                                  ),
                                },
                                message: _vm.errors.first(
                                  _vm.$t(
                                    "component.report.detail_table.report_export.pdf_export.chartOptions.y_axis"
                                  )
                                ),
                              },
                            },
                            [
                              _c("b-switch", {
                                staticClass: "ml-5",
                                attrs: {
                                  name: _vm.$t(
                                    "component.report.detail_table.report_export.pdf_export.chartOptions.y_axis"
                                  ),
                                },
                                model: {
                                  value: _vm.isHumidityRange,
                                  callback: function ($$v) {
                                    _vm.isHumidityRange = $$v
                                  },
                                  expression: "isHumidityRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isHumidityRange
                        ? _c(
                            "div",
                            { staticClass: "column is-flex px-2 py-0" },
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    type: {
                                      "is-danger": _vm.errors.has(
                                        _vm.$t(
                                          "component.report.detail_table.report_export.pdf_export.chartOptions.min"
                                        )
                                      ),
                                    },
                                    message: _vm.errors.first(
                                      _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.min"
                                      )
                                    ),
                                  },
                                },
                                [
                                  _c("b-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      type: "number",
                                      name: _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.min"
                                      ),
                                      placeholder: _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.min"
                                      ),
                                    },
                                    model: {
                                      value: _vm.humidityCrop.min,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.humidityCrop, "min", $$v)
                                      },
                                      expression: "humidityCrop.min",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  staticClass: "pl-2",
                                  attrs: {
                                    type: {
                                      "is-danger": _vm.errors.has(
                                        _vm.$t(
                                          "component.report.detail_table.report_export.pdf_export.chartOptions.max"
                                        )
                                      ),
                                    },
                                    message: _vm.errors.first(
                                      _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.max"
                                      )
                                    ),
                                  },
                                },
                                [
                                  _c("b-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      type: "number",
                                      name: _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.max"
                                      ),
                                      placeholder: _vm.$t(
                                        "component.report.detail_table.report_export.pdf_export.chartOptions.max"
                                      ),
                                    },
                                    model: {
                                      value: _vm.humidityCrop.max,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.humidityCrop, "max", $$v)
                                      },
                                      expression: "humidityCrop.max",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.selectedMode === _vm.PdfReportTypes.FULL
            ? _c(
                "b-field",
                {
                  staticClass: "flex-space-between",
                  attrs: {
                    label: _vm.$t(
                      "component.report.detail_table.report_export.pdf_export.annotations"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "component.report.detail_table.report_export.pdf_export.annotations"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.annotations"
                      )
                    ),
                  },
                },
                [
                  _c("b-switch", {
                    attrs: {
                      name: _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.annotations"
                      ),
                    },
                    model: {
                      value: _vm.showAnnotations,
                      callback: function ($$v) {
                        _vm.showAnnotations = $$v
                      },
                      expression: "showAnnotations",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode !== _vm.PdfReportTypes.RAW
            ? _c(
                "b-field",
                {
                  staticClass: "flex-space-between",
                  attrs: {
                    label: _vm.$t(
                      "component.report.detail_table.report_export.pdf_export.show_empty_records"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "component.report.detail_table.report_export.pdf_export.show_empty_records"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.show_empty_records"
                      )
                    ),
                  },
                },
                [
                  _c("b-switch", {
                    attrs: {
                      name: _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.show_empty_records"
                      ),
                    },
                    model: {
                      value: _vm.currentShowEmpty,
                      callback: function ($$v) {
                        _vm.currentShowEmpty = $$v
                      },
                      expression: "currentShowEmpty",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("footer", { staticClass: "modal-card-foot is-block" }, [
        _c("div", { staticClass: "level is-mobile" }, [
          _c(
            "div",
            { staticClass: "level-left" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item",
                  on: {
                    click: function ($event) {
                      return _vm.resultRetrieved(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.close")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "level-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item",
                  attrs: { type: "is-primary" },
                  on: { click: _vm.exportPdf },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.confirm"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }