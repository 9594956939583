var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "multi-header is-flex is-justify-content-space-between mx-3 mx-0-desktop mb-4 mb-1-touch",
        },
        [
          _c(
            "nav",
            {
              staticClass: "breadcrumb my-1",
              attrs: { "aria-label": "breadcrumb" },
            },
            [
              _c("h1", { staticClass: "title has-text-centered-touch" }, [
                _c(
                  "ul",
                  [
                    _c("li", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [
                          _vm._m(0),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                        ]
                      ),
                    ]),
                    _vm.prevRoute !== null &&
                    _vm.prevRoute.name.includes("Company") &&
                    _vm.device.company
                      ? [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "adminCompanies",
                                      params: {
                                        lang: _vm.prevRoute.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "warehouse" },
                                  }),
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.sidebar.companies"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: _vm.prevRoute.name,
                                      params: _vm.prevRoute.params,
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(
                                      _vm._s(_vm.device.company.companyName)
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.prevRoute !== null &&
                    _vm.prevRoute.name.includes("Reseller")
                      ? [
                          _vm.prevRoute.name.includes("Reseller")
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "adminResellers",
                                          params: {
                                            lang: _vm.$route.params.lang,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "box-open" },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("admin.sidebar.resellers")
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: _vm.prevRoute.name,
                                      params: _vm.prevRoute.params,
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(_vm.prevRoute.meta.name)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm.prevRoute == null ||
                        _vm.prevRoute.name.includes("Device")
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "adminDevices",
                                    params: {
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "hdd" } }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("admin.sidebar.devices"))
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("li", { staticClass: "is-active" }, [
                      _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                        _vm._v(_vm._s(_vm.device.deviceName)),
                      ]),
                    ]),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "is-flex" },
            [
              _c(
                "div",
                { staticClass: "mr-3" },
                [
                  !_vm.isLayoutList
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t("admin.component.layout.listView"),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { "font-size": "1rem" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLayout()
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: { type: "is-primary", icon: "stream" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLayoutList
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t("admin.component.layout.tabView"),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { "font-size": "1rem" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLayout()
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  type: "is-primary",
                                  icon: "layer-group fa-rotate-270",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("DeviceActionsDropdown", {
                attrs: { adminDevice: _vm.device, hasButton: true },
                on: {
                  returnDeviceFromCompany: _vm.returnDeviceFromCompany,
                  reuseDevice: _vm.reuseDevice,
                  registerToCompany: _vm.registerToCompany,
                  openDeviceMonitor: _vm.openDeviceMonitor,
                  configureDevice: _vm.configureDevice,
                  otaUpdateDevice: _vm.otaUpdateDevice,
                  keyUpdate: _vm.keyUpdate,
                  revertDevice: _vm.revertDevice,
                  activateDevice: _vm.activateDevice,
                  publishDevice: _vm.publishDevice,
                },
              }),
            ],
            1
          ),
        ]
      ),
      !_vm.isLayoutList
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "div",
                { staticClass: "box tile is-child" },
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "custom-layout-tabs",
                      attrs: { type: "is-boxed" },
                      on: {
                        input: function ($event) {
                          return _vm.changedActiveTab($event)
                        },
                      },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.devices.detail.deviceInfo.title"
                            ),
                          },
                        },
                        [
                          _c("AdminDeviceInfoComponent", {
                            attrs: {
                              isLayoutList: _vm.isLayoutList,
                              device: _vm.device,
                              dateTimeManager: _vm.dateTimeManager,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.$ability.can(
                        _vm.$permActions.READ,
                        _vm.$permSubjects.ADMIN_GATEWAYS_CREDENTIALS
                      )
                        ? _c(
                            "b-tab-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.devices.detail.credentials"
                                ),
                              },
                            },
                            [
                              _c("AdminDeviceHistoryCredentials", {
                                attrs: {
                                  isLayoutList: _vm.isLayoutList,
                                  device: _vm.device,
                                  currentCredentials: _vm.currentCredentials,
                                  historyCredentials: _vm.historyCredentials,
                                  dateTimeManager: _vm.dateTimeManager,
                                },
                                on: {
                                  revokeCredential: function ($event) {
                                    return _vm.revokeCredential()
                                  },
                                  newCredential: function ($event) {
                                    return _vm.newCredential()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$ability.can(
                        _vm.$permActions.READ,
                        _vm.$permSubjects.ADMIN_GATEWAYS_COMMANDS
                      )
                        ? _c(
                            "b-tab-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.devices.detail.commands_updates"
                                ),
                              },
                            },
                            [
                              _c("AdminDeviceCommands", {
                                staticClass: "mb-6",
                                attrs: {
                                  commands: _vm.commands,
                                  dateTimeManager: _vm.dateTimeManager,
                                },
                                on: { openDisableModal: _vm.openDisableModal },
                              }),
                              _c("AdminDeviceUpdates", {
                                attrs: {
                                  deviceUpdates: _vm.deviceUpdates,
                                  dateTimeManager: _vm.dateTimeManager,
                                },
                                on: { openDisableModal: _vm.openDisableModal },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.devices.detail.history"
                            ),
                          },
                        },
                        [
                          _c("AdminDeviceConfigHistoryComponent", {
                            staticClass: "mb-6",
                            attrs: {
                              device: _vm.device,
                              dateTimeManager: _vm.dateTimeManager,
                            },
                          }),
                          _vm.$ability.can(
                            _vm.$permActions.READ,
                            _vm.$permSubjects.ADMIN_GATEWAYS_FIRMWARES
                          )
                            ? _c("AdminDeviceFirmwareHistory", {
                                staticClass: "mb-6",
                                attrs: {
                                  device: _vm.device,
                                  dateTimeManager: _vm.dateTimeManager,
                                },
                              })
                            : _vm._e(),
                          _c("AdminDeviceInstanceHistory", {
                            attrs: {
                              device: _vm.device,
                              deviceInstances: _vm.deviceInstances,
                              dateTimeManager: _vm.dateTimeManager,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ])
        : _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminDeviceInfoComponent", {
                  staticClass: "box tile is-child",
                  attrs: {
                    device: _vm.device,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-6" }, [
                _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("AdminDeviceConfigHistoryComponent", {
                      staticClass: "box tile is-child",
                      attrs: {
                        device: _vm.device,
                        dateTimeManager: _vm.dateTimeManager,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "column is-6" }, [
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.ADMIN_GATEWAYS_FIRMWARES
                )
                  ? _c(
                      "div",
                      { staticClass: "tile is-parent" },
                      [
                        _c("AdminDeviceFirmwareHistory", {
                          staticClass: "box tile is-child",
                          attrs: {
                            device: _vm.device,
                            dateTimeManager: _vm.dateTimeManager,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_GATEWAYS_CREDENTIALS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("AdminDeviceHistoryCredentials", {
                      staticClass: "box tile is-child",
                      attrs: {
                        device: _vm.device,
                        currentCredentials: _vm.currentCredentials,
                        historyCredentials: _vm.historyCredentials,
                        dateTimeManager: _vm.dateTimeManager,
                      },
                      on: {
                        revokeCredential: function ($event) {
                          return _vm.revokeCredential()
                        },
                        newCredential: function ($event) {
                          return _vm.newCredential()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_GATEWAYS_COMMANDS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("AdminDeviceCommands", {
                      staticClass: "box tile is-child",
                      attrs: {
                        commands: _vm.commands,
                        dateTimeManager: _vm.dateTimeManager,
                      },
                      on: { openDisableModal: _vm.openDisableModal },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_GATEWAYS_UPDATES
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("AdminDeviceUpdates", {
                      staticClass: "box tile is-child",
                      attrs: {
                        deviceUpdates: _vm.deviceUpdates,
                        dateTimeManager: _vm.dateTimeManager,
                      },
                      on: { openDisableModal: _vm.openDisableModal },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminDeviceInstanceHistory", {
                  staticClass: "box tile is-child",
                  attrs: {
                    device: _vm.device,
                    deviceInstances: _vm.deviceInstances,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                }),
              ],
              1
            ),
          ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isConfigModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeConfigureModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isConfigModalActive = $event
            },
            "close-modal": _vm.closeConfigureModal,
          },
        },
        [
          _c("AdminConfigureDeviceModal", {
            attrs: { deviceList: _vm.selectedDevices },
            on: {
              modalClosed: _vm.closeConfigureModal,
              configured: _vm.configured,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isDeviceUpdateModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeDeviceUpdateModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isDeviceUpdateModalActive = $event
            },
            "close-modal": _vm.closeDeviceUpdateModal,
          },
        },
        [
          _c("DeviceUpdateModal", {
            attrs: {
              deviceFirmwareList: _vm.deviceFirmwareList,
              device: _vm.device,
            },
            on: { reload: _vm.reload, modalClosed: _vm.closeDeviceUpdateModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isActivateDeviceModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeActivateDeviceModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isActivateDeviceModalActive = $event
            },
            "close-modal": _vm.closeActivateDeviceModal,
          },
        },
        [
          _c("ActivateDeviceModal", {
            attrs: { device: _vm.device },
            on: { modalClosed: _vm.closeActivateDeviceModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCredentialModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeNewCredentialModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCredentialModalActive = $event
            },
            "close-modal": _vm.closeNewCredentialModal,
          },
        },
        [
          _c("CredentialModal", {
            attrs: {
              currentCredentials: _vm.currentCredentials,
              device: _vm.device,
            },
            on: {
              modalClosed: _vm.closeNewCredentialModal,
              loadNewCredential: function ($event) {
                return _vm.loadNewCredential()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isPublishModalActive,
            "has-modal-card": "",
            onCancel: _vm.closePublishModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isPublishModalActive = $event
            },
            "close-modal": _vm.closePublishModal,
          },
        },
        [
          _c("PublishDeviceModal", {
            attrs: { device: _vm.device, isReuse: _vm.isReuse },
            on: { modalClosed: _vm.closePublishModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isRegisterModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeRegisterModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isRegisterModalActive = $event
            },
            "close-modal": _vm.closeRegisterModal,
          },
        },
        [
          _c("RegisterToCompany", {
            attrs: { device: _vm.device },
            on: { modalClosed: _vm.closeRegisterModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isMonitorModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeMonitorModal,
            "full-screen": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.isMonitorModalActive = $event
            },
            "close-modal": _vm.closeMonitorModal,
          },
        },
        [
          _c("DeviceMonitor", {
            attrs: { device: _vm.device, dateTimeManager: _vm.dateTimeManager },
            on: { modalClosed: _vm.closeMonitorModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isKeyUpdateModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeKeyUpdate,
          },
          on: {
            "update:active": function ($event) {
              _vm.isKeyUpdateModalActive = $event
            },
            "close-modal": _vm.closeKeyUpdate,
          },
        },
        [
          _c("KeyUpdateModal", {
            attrs: { device: _vm.device },
            on: { modalClosed: _vm.closeKeyUpdate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }