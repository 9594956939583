import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import PublicDashboardRepository from '@/services/repository/PublicDashboardRepository';
import { Source } from '@/entities/models/Source';
import { PublicDashboard } from '@/entities/models/PublicDashboard';
import PublicDashboardComponents from '@/entities/enums/PublicDashboardComponents';
import { Event } from '@/entities/models/Event';
import LastEventsComponent from '@/views/components/events/LastEventsComponent.vue';
import DaysBeforeOptions from '@/entities/enums/DaysBeforeOptions';
import DateTimeManager from '@/services/DateTimeManager';
import ChartComponent from '@/views/components/ChartComponent.vue';
import SourceTabs from '@/views/components/reports/SourceTabs.vue';
import ReportsOverallChart from '@/views/components/reports/ReportsOverallChart.vue';
import EventDomain from '@/entities/enums/eventDomains';
import { Boundary } from '@/entities/models/Boundary';
import ReportConfigurationComponent from '@/views/components/reports/ReportConfigurationComponent.vue';
import { ReportConfiguration } from '@/entities/models/ReportConfiguration';
import StatesComponent from '@/views/components/publicDashboard/StatesComponent.vue';
import RefreshSettingsModal from '@/views/components/publicDashboard/RefreshSettingsModal.vue';
import SessionStorage from '@/services/repository/SessionStorage';
import ApiStatus from '@/entities/enums/apiStatuses';
import { ChartHeight } from '@/entities/enums/ChartHeight';
import LoggedUserManager from '@/services/LoggedUserManager';
import { ChartComponents } from '@/entities/enums/ChartComponents';
var publicDashboardRepository;
const REFRESH_INTERVAL = 5;
let PublicDashboardPage = class PublicDashboardPage extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = {
            StatesComponent: true,
            LastEventsComponent: true,
            ChartComponent: true,
            Reports: true
        };
        this.dashboardData = null;
        this.measurements = {};
        this.allSources = [];
        this.allSourceGroupEvents = [];
        this.allDevices = [];
        this.selectedSourceGroupId = '';
        this.sourceGroupIds = [];
        this.sourceGroupSources = [];
        this.sourceGroupEvents = [];
        this.dashboardId = null;
        this.refreshLastEvents = true;
        this.dateTimeManager = null;
        this.eventTypes = [];
        this.selectedDayOption = DaysBeforeOptions.ONE;
        this.title = null;
        this.sourceGroupStatistics = {};
        this.reportRecordsForTable = {};
        this.reportRecordsForChart = {};
        this.axisRanges = [];
        this.axisRangesLoaded = false;
        this.monthFilter = null;
        this.dateRange = [];
        this.refreshIntervalHandler = null;
        this.filterRecords = false;
        this.PublicDashboardComponents = PublicDashboardComponents;
        this.configuration = ReportConfiguration.getDefaultConfiguration();
        this.showEmpty = true;
        this.byGroup = true;
        this.isOpenRefreshSettingsModal = false;
        this.refreshInterval = null;
        this.tag = SessionStorage.keys.REFRESH_INTERVAL;
        this.lastRefresh = new Date();
        this.upTimeServiceSources = [];
        this.otherServiceSources = [];
        this.upTimeSourceschartData = {};
        this.otherSourcesChartData = {};
        this.isAuthorizedDashboard = true;
        this.chartHeight = ChartHeight.LARGE;
        this.selectedTimeZone = null;
        this.chartEntityReport = null;
        this.chartEntitySourceTabs = null;
        this.chartEntityChartComponent = null;
    }
    get hasNoSources() {
        return this.dashboardData != null && !this.dashboardData.hasSourcesOrGroups();
    }
    get isLoggedIn() {
        return LoggedUserManager.storageContainLoginCredentials(this.$cookies);
    }
    async created() {
        publicDashboardRepository = new PublicDashboardRepository(this);
        if (!SessionStorage.loadData(this.tag[this.$route.params.dashboardKey]))
            SessionStorage.storeData(this.tag[this.$route.params.dashboardKey], [
                REFRESH_INTERVAL,
                this.refreshLastEvents
            ]);
        this.refreshInterval = SessionStorage.loadData(this.tag[this.$route.params.dashboardKey]).data[0];
        this.refreshLastEvents = SessionStorage.loadData(this.tag[this.$route.params.dashboardKey]).data[1];
        this.dateTimeManager = await DateTimeManager.CreateManager(this, this.isLoggedIn ? false : true);
        this.selectedTimeZone = this.dateTimeManager.getSelectedTimezone();
        this.loadData();
    }
    beforeDestroy() {
        clearInterval(this.refreshIntervalHandler);
        SessionStorage.clearStorageForSession();
    }
    loadData() {
        this.dashboardId = this.$route.params.dashboardKey;
        this.isLoading.StatesComponent = true;
        this.isLoading.LastEventsComponent = true;
        this.isLoading.ChartComponent = true;
        this.isLoading.Reports = true;
        //read TS from route
        try {
            let givenTS = this.$route.query.dateRange;
            let dateRange = JSON.parse(givenTS);
            if (dateRange.find((date) => date && !isNaN(date))) {
                this.dateRange = [new Date(dateRange[0]), new Date(dateRange[1])];
            }
            else {
                this.dateRange = this.loadInitDateRange();
            }
        }
        catch (error) {
            this.dateRange = this.loadInitDateRange();
        }
        let allPromises = [];
        allPromises.push(publicDashboardRepository.getPublicDashboardData(this.dashboardId));
        allPromises.push(publicDashboardRepository.getPublicDashboardEventTypes(this.dashboardId));
        Promise.all(allPromises).then((response) => {
            if (response[0].status === ApiStatus.UNAUTHORIZED || response[1].status === ApiStatus.UNAUTHORIZED) {
                this.isAuthorizedDashboard = false;
            }
            else {
                let dashboardData = response[0];
                let eventTypes = response[1];
                if (!(dashboardData instanceof PublicDashboard)) {
                    this.$router.replace({
                        name: 'publicDashboardNotExist',
                        params: {
                            lang: this.$route.params.lang
                        }
                    });
                    return;
                }
                this.dashboardData = dashboardData;
                this.eventTypes = eventTypes.filter((x) => x.domain == EventDomain.MEASURED);
                for (let i in dashboardData.sourceGroups)
                    this.sourceGroupIds.push(dashboardData.sourceGroups[i].id);
                this.sourceGroupIds.forEach((x) => {
                    this.allSources = this.allSources.concat(this.assignSourcesForSelectedGroup(x));
                });
                if (dashboardData.sources.length !== 0)
                    this.allSources = this.allSources.concat(dashboardData.sources);
                if (dashboardData.gateways.length !== 0)
                    this.allDevices = dashboardData.gateways;
                this.title = dashboardData.name;
                let groupIdFromRoute = this.$route.query.groupId;
                if (!this.dashboardData.hasSourcesOrGroups()) {
                    this.isLoading.StatesComponent = false;
                    this.isLoading.LastEventsComponent = false;
                    this.isLoading.ChartComponent = false;
                    this.isLoading.Reports = false;
                    return;
                }
                else if (groupIdFromRoute && this.dashboardData.sourceGroups.find((x) => x.id === groupIdFromRoute)) {
                    this.selectedSourceGroupId = groupIdFromRoute;
                }
                else if (Source.getUnassignedSources(this.dashboardData.sources).length > 0)
                    this.selectedSourceGroupId = null;
                else {
                    this.selectedSourceGroupId = this.dashboardData.sourceGroups[0].id;
                }
                this.sourceGroupSources = this.assignSourcesForSelectedGroup(this.selectedSourceGroupId);
                this.loadAdditionalData();
                if (!this.refreshIntervalHandler)
                    this.refreshIntervalHandler = setInterval(this.loadAdditionalData, this.refreshInterval * 60 * 1000 //min to ms
                    );
                this.isLoading.StatesComponent = false;
            }
        });
    }
    async loadAdditionalData() {
        this.lastRefresh = new Date(Date.now());
        this.loadLastMeasurements();
        this.loadSourcesByEventType();
        this.loadChartData();
        this.loadReportData();
    }
    loadInitDateRange() {
        let today = new Date();
        let yesterday = new Date();
        yesterday.setHours(0, 0, 0, 0);
        yesterday.setDate(today.getDate() - 1);
        return [yesterday, today];
    }
    eventHandler(hasDataForTimerange, chartEntity, chartComponent) {
        if (hasDataForTimerange) {
            if (chartComponent == ChartComponents.CHARTCOMPONENT) {
                this.chartEntityChartComponent = chartEntity;
                window.addEventListener('resize', this.resizeHandlerChartComponent);
            }
            else if (chartComponent == ChartComponents.REPORTSOVERALLCHART) {
                this.chartEntityReport = chartEntity;
                window.addEventListener('resize', this.resizeHandlerReport);
            }
            else if (chartComponent == ChartComponents.SOURCETABS) {
                this.chartEntitySourceTabs = chartEntity;
                window.addEventListener('resize', this.resizeHandlerSourceTabs);
            }
        }
        else {
            if (chartComponent == ChartComponents.CHARTCOMPONENT) {
                this.chartEntityChartComponent = chartEntity;
                window.removeEventListener('resize', this.resizeHandlerChartComponent);
            }
            else if (chartComponent == ChartComponents.REPORTSOVERALLCHART) {
                this.chartEntityReport = chartEntity;
                window.removeEventListener('resize', this.resizeHandlerReport);
            }
            else if (chartComponent == ChartComponents.SOURCETABS) {
                this.chartEntitySourceTabs = chartEntity;
                window.removeEventListener('resize', this.resizeHandlerSourceTabs);
            }
        }
    }
    //Seperate function for remove 'resize' event listener
    resizeHandlerChartComponent() {
        if (this.chartEntityChartComponent)
            this.chartEntityChartComponent.makeResponsive();
    }
    resizeHandlerReport() {
        if (this.chartEntityReport)
            this.chartEntityReport.makeResponsive();
    }
    resizeHandlerSourceTabs() {
        if (this.chartEntitySourceTabs)
            this.chartEntitySourceTabs.makeResponsive();
    }
    loadLastMeasurements() {
        if (!this.dashboardData.allowedComponents.includes(PublicDashboardComponents.LAST_MEASUREMENTS) ||
            this.refreshLastEvents == false) {
            this.isLoading.LastEventsComponent = false;
            return;
        }
        this.isLoading.LastEventsComponent = true;
        publicDashboardRepository.getPublicDashboardMeasurements(this.dashboardId).then((resp) => {
            this.measurements = resp;
            this.filterEventsForSourceGroup();
            this.filterEventsForAllGroups();
            this.isLoading.LastEventsComponent = false;
        });
    }
    loadReportData() {
        if (!this.dashboardData.allowedComponents.includes(PublicDashboardComponents.REPORTS)) {
            this.isLoading.Reports = false;
            return;
        }
        this.isLoading.Reports = true;
        this.monthSelected(this.dateRange);
        let sourceIds = this.otherServiceSources.map((x) => x.id);
        publicDashboardRepository
            .getPublicDashboardReports(this.dashboardId, sourceIds, this.dashboardData.allowedComponents.includes(PublicDashboardComponents.ANNOTATIONS), this.monthFilter.start, this.monthFilter.stop, this.configuration.getModel().model, this.configuration.interval, this.configuration.getOffsetForConfiguration(), this.showEmpty, this.dateTimeManager.getSelectedTimezone())
            .then((resp) => {
            this.sourceGroupStatistics = resp.statistics;
            this.reportRecordsForTable = resp.records;
            this.reportRecordsForChart = resp.records;
            this.isLoading.Reports = false;
        });
    }
    changeChartHeight(chartHeight) {
        this.chartHeight = chartHeight;
    }
    /**
     * This method is triggered when user changes the report configuration. New data are loaded when configuration
     * change is submitted. When user changes date of records, this change is saved in DB and new timestamp
     * is inserted to route.
     */
    async applyConfiguration(configuration, dateRange, showEmpty) {
        this.isLoading.Reports = true;
        this.showEmpty = showEmpty;
        let serializedConfiguration = JSON.stringify(configuration);
        this.configuration = ReportConfiguration.fromApi(serializedConfiguration);
        this.dateRange = dateRange;
        this.monthSelected(this.dateRange);
        this.updateDateRouteQuery(this.dateRange);
        this.loadReportData();
    }
    loadSourcesByEventType() {
        if (this.byGroup === true) {
            this.filterSourcesByEventType(this.sourceGroupSources, this.sourceGroupEvents);
        }
        else {
            this.filterSourcesByEventType(this.allSources, this.allSourceGroupEvents);
        }
    }
    loadChartData() {
        if (!this.dashboardData.allowedComponents.includes(PublicDashboardComponents.CHART)) {
            this.isLoading.ChartComponent = false;
            return;
        }
        this.isLoading.ChartComponent = true;
        let additionalPromises = [];
        additionalPromises.push(this.loadOtherChartData());
        additionalPromises.push(this.loadUptimeChartData());
        return Promise.all(additionalPromises).then((response) => {
            this.processAdditionalDataForChart(response[0], response[1]);
        });
    }
    processAdditionalDataForChart(otherSourcesChartData, upTimeSourcesChartData) {
        this.otherSourcesChartData = otherSourcesChartData;
        this.upTimeSourceschartData = upTimeSourcesChartData;
        this.assignBoundariesToSources(this.otherSourcesChartData, this.otherServiceSources);
        this.isLoading.ChartComponent = false;
    }
    loadOtherChartData() {
        let sourceIds = this.otherServiceSources.map((x) => x.id);
        let date = new Date();
        date.setDate(date.getDate() - this.selectedDayOption);
        let startDate = this.dateTimeManager.getTimeStringForInputDate(date);
        return publicDashboardRepository.getPublicDashboardChart(this.dashboardId, sourceIds, startDate);
    }
    loadUptimeChartData() {
        let date = new Date();
        date.setDate(date.getDate() - this.selectedDayOption);
        let startDate = this.dateTimeManager.getTimeStringForInputDate(date);
        return publicDashboardRepository.getUptimeChartDataForSources(this.upTimeServiceSources.map((x) => x.id), startDate, this.dashboardId);
    }
    filterSourcesByEventType(sources, sourceEvents) {
        this.upTimeServiceSources = [];
        this.otherServiceSources = [];
        sources.filter((x) => {
            if (sourceEvents.find((y) => y.sourceId == x.id && y.eventType.type == 5)) {
                this.upTimeServiceSources.push(x);
            }
            else {
                this.otherServiceSources.push(x);
            }
        });
    }
    monthSelected(dateRange) {
        this.axisRanges = [];
        this.axisRangesLoaded = false;
        let convertedDateRange = this.dateTimeManager.formatDateRange(dateRange);
        this.monthFilter = {
            start: this.dateTimeManager.formatStartDateForReports(convertedDateRange[0]).text,
            stop: this.dateTimeManager.formatEndDateForReports(this.dateRange[1], true).text,
            startTS: this.dateTimeManager.formatStartDateForReports(convertedDateRange[0]).ts,
            stopTS: this.dateTimeManager.formatEndDateForReports(this.dateRange[1], true).ts
        };
    }
    filterEventsForSourceGroup() {
        let eventsForGroupSources = {};
        eventsForGroupSources = Event.calculateBoundaryDifference(this.measurements, this.sourceGroupSources);
        this.sourceGroupEvents = Event.getEventsFromEventsRecord(eventsForGroupSources);
    }
    filterEventsForAllGroups() {
        let eventsForGroupSources = {};
        eventsForGroupSources = Event.calculateBoundaryDifference(this.measurements, this.allSources);
        this.allSourceGroupEvents = Event.getEventsFromEventsRecord(eventsForGroupSources);
    }
    assignBoundariesToSources(chartData, sources) {
        Object.keys(chartData).forEach((key) => {
            let currentSource = sources.find((x) => x.id == key);
            let currentData = chartData[key];
            Object.values(currentData).forEach((rawData) => {
                if (rawData.boundaries) {
                    let parsedBoundaries = Boundary.fromApi(rawData.boundaries);
                    currentSource.boundaries.push(parsedBoundaries);
                }
            });
        });
    }
    assignSourcesForSelectedGroup(groupId) {
        if (groupId == null)
            return Source.getUnassignedSources(this.dashboardData.sources);
        else
            return this.dashboardData.sourceGroups.find((x) => x.id === groupId).sources;
    }
    async sourceGroupSelected(sourceGroupId) {
        this.byGroup = true;
        this.selectedSourceGroupId = sourceGroupId;
        this.sourceGroupSources = this.assignSourcesForSelectedGroup(this.selectedSourceGroupId);
        this.isLoading.LastEventsComponent = true;
        this.filterEventsForSourceGroup();
        this.loadSourcesByEventType();
        this.loadChartData();
        this.loadReportData();
        this.axisRanges = [];
        this.axisRangesLoaded = false;
        this.isLoading.LastEventsComponent = false;
    }
    async allSelected() {
        this.byGroup = false;
        this.isLoading.LastEventsComponent = true;
        this.filterEventsForAllGroups();
        this.loadSourcesByEventType();
        this.loadChartData();
        this.loadReportData();
        this.axisRanges = [];
        this.axisRangesLoaded = false;
        this.isLoading.LastEventsComponent = false;
    }
    async dayOptionSelected(option) {
        this.selectedDayOption = option;
        this.loadChartData();
    }
    axisRangeSelected(axisRanges) {
        this.axisRanges = axisRanges;
        this.axisRangesLoaded = true;
    }
    updateDateRouteQuery(dateRange) {
        let dateRangeTs = [dateRange[0].getTime(), dateRange[1].getTime()];
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, { dateRange: JSON.stringify(dateRangeTs) }),
            params: {
                lang: this.$route.params.lang,
                departmentId: this.$route.params.departmentId
            }
        })
            .catch((err) => err);
    }
    saveRefreshSettings() {
        this.refreshInterval = SessionStorage.loadData(this.tag[this.dashboardId]).data[0];
        this.refreshLastEvents = SessionStorage.loadData(this.tag[this.dashboardId]).data[1];
        if (this.refreshLastEvents === true) {
            clearInterval(this.refreshIntervalHandler);
            this.refreshIntervalHandler = setInterval(this.loadAdditionalData, this.refreshInterval * 60 * 1000);
        }
        else {
            clearInterval(this.refreshIntervalHandler);
        }
        this.isOpenRefreshSettingsModal = false;
    }
    closeRefreshSettingsModal() {
        this.isOpenRefreshSettingsModal = false;
    }
};
PublicDashboardPage = __decorate([
    Component({
        components: {
            RefreshSettingsModal,
            StatesComponent,
            LastEventsComponent,
            ChartComponent,
            SourceTabs,
            ReportsOverallChart,
            ReportConfigurationComponent
        }
    })
], PublicDashboardPage);
export default PublicDashboardPage;
