var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "nav",
        { staticClass: "breadcrumb", attrs: { "aria-label": "breadcrumb" } },
        [
          _c("h1", { staticClass: "title has-text-centered-touch" }, [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [
                    _vm._m(0),
                    _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                  ]
                ),
              ]),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "dashboard",
                          params: {
                            lang: _vm.$route.params.lang,
                            departmentId: _vm.$route.params.departmentId,
                          },
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "home" } }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("generals.home")))]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "settings",
                          params: {
                            lang: _vm.$route.params.lang,
                            departmentId: _vm.$route.params.departmentId,
                          },
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("component.settings.title")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.replace({
                          name: "companySettings",
                          params: {
                            lang: _vm.$route.params.lang,
                            departmentId: _vm.$route.params.departmentId,
                          },
                        })
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.settings.administration.administration_settings"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
              !_vm.isLoading
                ? _c("li", { staticClass: "is-active" }, [
                    _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("users.user_detail_title")) +
                          ": " +
                          _vm._s(_vm.user.email)
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _vm.$ability.can(_vm.$permActions.LIST, _vm.$permSubjects.USERS)
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("UserDetailAdministration", {
                      staticClass: "box tile is-child",
                      attrs: {
                        dateTimeManager: _vm.dateTimeManager,
                        user: _vm.user,
                      },
                      on: {
                        disableUserDialog: _vm.disableUserDialog,
                        enableUser: _vm.enableUser,
                        openChangeRoleModal: _vm.openChangeRoleModal,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "div",
                { staticClass: "box tile is-child" },
                [
                  _c(
                    "b-tabs",
                    {
                      attrs: { type: "is-boxed" },
                      on: { input: _vm.tabChanged },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            value: _vm.NotificationTabs.GENERAL,
                            label: _vm.$t(
                              "notifications.configuration.tab_general"
                            ),
                          },
                        },
                        [
                          !_vm.isSystemAdmin
                            ? _c("div", { staticClass: "pb-4" }, [
                                _c("h2", { staticClass: "subtitle mb-1" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "notifications.configuration.use_timetable_title"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    [
                                      _c("b-switch", {
                                        attrs: { size: "is-small" },
                                        on: { input: _vm.onEnableTimetable },
                                        model: {
                                          value: _vm.timetableEnabled,
                                          callback: function ($$v) {
                                            _vm.timetableEnabled = $$v
                                          },
                                          expression: "timetableEnabled",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "is-size-7 is-italic has-text-grey",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "notifications.configuration.use_timetable_subtitle"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("hr"),
                          _vm.timetableEnabled
                            ? _c(
                                "div",
                                [
                                  _c("Timetable", {
                                    ref: "timetableComponent",
                                    attrs: {
                                      availableTypes: _vm.availableTypes,
                                      currentUser: _vm.user,
                                      schedules: _vm.timetableSchedules,
                                      presets: _vm.presets,
                                      tier: _vm.tier,
                                      departmentUserSettings:
                                        _vm.departmentUserSettings,
                                      departmentId: _vm.selectedDepartmentId,
                                      dateTimeManager: _vm.dateTimeManager,
                                    },
                                    on: {
                                      reloadSchedules: _vm.reloadSchedules,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "title has-text-dark is-4 mb-0 pb-0",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "notifications.configuration.rules_title"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "is-size-7 is-italic has-text-grey pb-5",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "notifications.configuration.rules_subtitle"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pt-4 pb-5 subtitle has-text-weight-semibold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "notifications.configuration.general_or_preset"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "b-dropdown",
                                        {
                                          attrs: { "aria-role": "list" },
                                          on: { change: _vm.applyPreset },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "trigger",
                                                fn: function ({ active }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "is-italic is-underlined clickable-text",
                                                        attrs: {
                                                          role: "button",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.general_or_preset_link"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: active
                                                              ? "caret-up"
                                                              : "caret-down",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1251524264
                                          ),
                                          model: {
                                            value: _vm.selectedPreset,
                                            callback: function ($$v) {
                                              _vm.selectedPreset = $$v
                                            },
                                            expression: "selectedPreset",
                                          },
                                        },
                                        _vm._l(_vm.presets, function (preset) {
                                          return _c(
                                            "b-dropdown-item",
                                            {
                                              key: preset.id,
                                              attrs: {
                                                "aria-role": "listitem",
                                                value: preset,
                                              },
                                            },
                                            [_vm._v(_vm._s(preset.name))]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isChangingMode
                                    ? _c(
                                        "div",
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              width: "20%",
                                              animated: true,
                                            },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: {
                                              width: "40%",
                                              animated: true,
                                            },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: {
                                              width: "80%",
                                              animated: true,
                                            },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: { animated: true },
                                          }),
                                        ],
                                        1
                                      )
                                    : [
                                        !_vm.hasRulesForEntities
                                          ? _c("MeasuredDataNotification", {
                                              attrs: {
                                                rule: _vm.singleMeasuredRule,
                                                types: _vm.measuredTypes,
                                                currentUser: _vm.user,
                                                selectedMeasuredRulesMode:
                                                  _vm.selectedMeasuredRulesMode,
                                                tier: _vm.tier,
                                                scheduleId:
                                                  _vm.defaultScheduleId,
                                                departmentId:
                                                  _vm.selectedDepartmentId,
                                                autoSave: false,
                                                measuredRuleEnabled:
                                                  _vm.measuredRuleEnabled,
                                              },
                                              on: {
                                                ruleChanged: _vm.ruleChanged,
                                                changeMode: _vm.changeMode,
                                                switchedValue:
                                                  _vm.switchedValue,
                                              },
                                            })
                                          : _c(
                                              "EntityMeasuredDataNotification",
                                              {
                                                attrs: {
                                                  rules: _vm.measuredRules,
                                                  types: _vm.measuredTypes,
                                                  currentUser: _vm.user,
                                                  selectedMeasuredRulesMode:
                                                    _vm.selectedMeasuredRulesMode,
                                                  departmentId:
                                                    _vm.selectedDepartmentId,
                                                  tier: _vm.tier,
                                                  autoSave: false,
                                                },
                                                on: {
                                                  changeMode: _vm.changeMode,
                                                  ruleChanged: _vm.ruleChanged,
                                                },
                                              }
                                            ),
                                      ],
                                  _c("hr"),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .DEVICE_OFFLINE
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .DEVICE_OFFLINE
                                      ),
                                      currentUser: _vm.user,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("hr"),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .SOURCE_OFFLINE
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .SOURCE_OFFLINE
                                      ),
                                      currentUser: _vm.user,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("hr"),
                                  _c(
                                    "h2",
                                    { staticClass: "subtitle has-text-dark" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "notifications.configuration.other_rules_title"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .LOW_BATTERY
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .LOW_BATTERY
                                      ),
                                      currentUser: _vm.user,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .CUSTOMER_ENDING_CALIBRATION
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .CUSTOMER_ENDING_CALIBRATION
                                      ),
                                      currentUser: _vm.user,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .PDF_RECORDS_REGENERATED
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .PDF_RECORDS_REGENERATED
                                      ),
                                      currentUser: _vm.user,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .AUTOMATIC_REPORTS_GENERATED
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .AUTOMATIC_REPORTS_GENERATED
                                      ),
                                      currentUser: _vm.user,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .RECORDS_UPDATED
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .RECORDS_UPDATED
                                      ),
                                      currentUser: _vm.user,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("GeneralNotificationComponent", {
                                    attrs: {
                                      rule: _vm.getRule(
                                        _vm.NotificationSubscriptionType
                                          .LOGGER_STATE
                                      ),
                                      type: _vm.getType(
                                        _vm.NotificationSubscriptionType
                                          .LOGGER_STATE
                                      ),
                                      currentUser: _vm.user,
                                      advancedAllowed: false,
                                      departmentId: _vm.selectedDepartmentId,
                                      autoSave: false,
                                    },
                                    on: { ruleChanged: _vm.ruleChanged },
                                  }),
                                  _c("hr"),
                                  _c("OtherNotificationSettings", {
                                    attrs: {
                                      departmentSettings:
                                        _vm.departmentSettings,
                                    },
                                    on: {
                                      updateOptimizeNotificationsSetting:
                                        _vm.updateOptimizeNotificationsSetting,
                                    },
                                  }),
                                ],
                                2
                              ),
                        ]
                      ),
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            value: _vm.NotificationTabs.NON_WORKING_DAYS,
                            label: _vm.$t(
                              "notifications.configuration.tab_non_working"
                            ),
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("NonWorkingHours", {
                                attrs: {
                                  availableTypes: _vm.availableTypes,
                                  notificationSubscriptions:
                                    _vm.notificationSubscriptions,
                                  presets: _vm.presets,
                                  schedules: _vm.nonWorkingSchedules,
                                  departmentUserSettings:
                                    _vm.departmentUserSettings,
                                  departmentId: _vm.selectedDepartmentId,
                                  currentUser: _vm.user,
                                  tier: _vm.tier,
                                },
                                on: { reloadSchedules: _vm.reloadSchedules },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isChangeRoleModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isChangeRoleModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("ChangeUserRoleModal", {
            attrs: {
              user: _vm.user,
              departmentScope: true,
              userRoles: _vm.userRoles,
            },
            on: { modalClosed: _vm.closeModal, changeRole: _vm.changeRole },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isUnsavedChangesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeUnsavedChangesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isUnsavedChangesModalActive = $event
            },
            "close-modal": _vm.closeUnsavedChangesModal,
          },
        },
        [
          _c("UnsavedChangesDialog", {
            attrs: { routeToNavigate: _vm.routeToNavigateOnExit },
            on: {
              modalClosed: _vm.closeUnsavedChangesModal,
              cancel: _vm.closeUnsavedChangesModal,
              save: _vm.saveUnsavedChanges,
            },
          }),
        ],
        1
      ),
      !_vm.timetableEnabled && _vm.haveChangesToSave
        ? _c(
            "div",
            { staticClass: "fixed-form-footer" },
            [
              _c("span", { staticClass: "pb-2 has-text-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "notifications.configuration.timetable.save_description"
                    )
                  )
                ),
              ]),
              _c(
                "b-button",
                {
                  attrs: { type: "is-success", disabled: _vm.isLoading },
                  on: {
                    click: function ($event) {
                      return _vm.saveDefault()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("notifications.configuration.timetable.save"))
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }