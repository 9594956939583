import { __decorate } from "tslib";
import { DEFAULT_MIME_TYPE, MAX_IMG_SIZE, MAX_THUMBNAIL_SIZE } from '@/entities/models/FloorPlan';
import { dataUrlToFile, extractExt, resizeImage } from '@/services/FileManager';
import VueUtilities from '@/services/VueUtilities';
import { Component, Prop, Vue } from 'vue-property-decorator';
let ImageUploader = class ImageUploader extends Vue {
    constructor() {
        super(...arguments);
        this.DEFAULT_MIME_TYPE = DEFAULT_MIME_TYPE;
        this.MAX_IMG_SIZE = MAX_IMG_SIZE;
        this.MAX_THUMBNAIL_SIZE = MAX_THUMBNAIL_SIZE;
        this.fileTypesArr = [];
        this.uploadedFile = null;
        this.thumbnailFile = null;
        this.imgFile = null;
        this.image = new Image();
        this.isDragging = false;
    }
    created() {
        this.fileTypesArr = this.acceptFileTypes.split(',');
    }
    triggerFileInput() {
        this.$refs.fileInput.click();
    }
    handleFileUpload(event) {
        if (!(event.target && event.target.files))
            return;
        this.uploadedFile = event.target.files[0];
        this.processImg();
    }
    dragOver() {
        this.isDragging = true;
    }
    dragLeave() {
        this.isDragging = false;
    }
    handleDrop(event) {
        if (!(event.dataTransfer && event.dataTransfer.files))
            return;
        if (event.dataTransfer.files.length > 1) {
            VueUtilities.openErrorToast(this, this.$t('component.floorPlan.numOfFilesErr'));
            return;
        }
        this.isDragging = false;
        this.uploadedFile = event.dataTransfer.files[0];
        this.processImg();
    }
    async processImg() {
        // Accept file types check
        if (!this.acceptFileTypes.includes(extractExt(this.uploadedFile.type))) {
            VueUtilities.openErrorToast(this, this.$t('component.floorPlan.invalidFileType'));
            return;
        }
        // create Image()
        let imageSrc = '';
        try {
            imageSrc = await this.getImageSrc(this.uploadedFile);
        }
        catch (error) {
            VueUtilities.openErrorToast(this, this.$t('component.floorPlan.failure'));
            return;
        }
        await new Promise((resolve, reject) => {
            this.image.src = imageSrc;
            this.image.onload = resolve;
            this.image.onerror = reject;
        });
        this.image.setAttribute('crossOrigin', 'anonymous');
        this.$emit('imageCreated', this.image);
        // resize file
        let img = await resizeImage(this.image, this.uploadedFile.size, MAX_IMG_SIZE, DEFAULT_MIME_TYPE, this.quality);
        this.imgFile = dataUrlToFile(img, this.uploadedFile.name, DEFAULT_MIME_TYPE);
        this.$emit('imageFileCreated', this.imgFile);
        // create thumbnail
        if (this.createThumbnail) {
            let thumb = await resizeImage(this.image, this.uploadedFile.size, MAX_THUMBNAIL_SIZE, DEFAULT_MIME_TYPE);
            this.thumbnailFile = dataUrlToFile(thumb, 'thumb_' + this.uploadedFile.name, DEFAULT_MIME_TYPE);
            this.$emit('thumbnailCreated', this.thumbnailFile);
        }
        this.$emit('loadImage');
    }
    async getImageSrc(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = () => {
                reject(new Error('Error reading file'));
            };
            reader.readAsDataURL(file);
        });
    }
};
__decorate([
    Prop({ type: String, default: '*' })
], ImageUploader.prototype, "acceptFileTypes", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ImageUploader.prototype, "createThumbnail", void 0);
__decorate([
    Prop({ type: Number, default: 1 })
], ImageUploader.prototype, "quality", void 0);
ImageUploader = __decorate([
    Component
], ImageUploader);
export default ImageUploader;
