import User from '../User';
import { Boundary } from './Boundary';
import { CompanyLogger } from './CompanyLogger';
import { SourceGroup } from './SourceGroup';
import Quantity from '../Quantity';
import { SourceService } from './SourceService';
export class Source {
    constructor() {
        this.additionalProperties = {
            hasErrorField: false,
            errorMsg: ''
        };
        this.boundaries = new Array();
    }
    exists() {
        if (this.id && this.name)
            return true;
        else
            return false;
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Source.define(et));
        }
        else if (data != null) {
            return Source.define(data);
        }
        else
            return null;
    }
    setService(activeService) {
        this.activeService = activeService;
    }
    static define(data) {
        let source = new Source();
        source.id = data.Id;
        source.name = data.Name;
        source.description = data.Description;
        source.lastSeen = data.LastSeen;
        source.createdAt = data.CreatedAt;
        source.createdBy = data.CreatedBy;
        source.deletedAt = data.DeletedAt;
        source.visible = data.Visible;
        source.isOnline = data.IsOnline;
        source.pausedMeasurement = data.PausedMeasurement;
        source.pausedAt = data.PausedAt;
        if (data.DeletedByUser)
            source.deletedBy = User.fromApi(data.DeletedByUser);
        if (data.Logger)
            source.logger = CompanyLogger.fromApi(data.Logger);
        if (data.SourceGroup)
            source.sourceGroup = SourceGroup.fromApi(data.SourceGroup);
        if (data.Boundaries)
            source.boundaries = Boundary.fromApi(data.Boundaries);
        if (data.EnabledVirtualQuantities)
            source.enabledVirtualQuantities = Quantity.fromApi(data.EnabledVirtualQuantities);
        if (data.ActiveService)
            source.activeService = SourceService.fromApi(data.ActiveService);
        return source;
    }
    static getUnassignedSources(sources) {
        return sources.filter((x) => !x.sourceGroup);
    }
    static getBoundariesForService(source, serviceSlug) {
        if (!source.boundaries)
            return '';
        let boundaries = source.boundaries.find((x) => x.eventTypeSlug == serviceSlug);
        if (!boundaries)
            return '';
        else
            return boundaries;
    }
    static getSourceByLogger(sources, loggerId) {
        let source = sources.find((x) => x.logger && x.logger.serialNumber == loggerId);
        return source || new Source();
    }
    static sortByGroup(sources) {
        return sources.sort(function (a, b) {
            let aId = a.sourceGroup ? a.sourceGroup.name.toLowerCase() : '';
            let bId = b.sourceGroup ? b.sourceGroup.name.toLowerCase() : '';
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
    static sortByName(sources) {
        return sources.sort(function (a, b) {
            let aId = a ? a.name.toLowerCase() : '';
            let bId = b ? b.name.toLowerCase() : '';
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
    static sortByNotificationRule(sources, notificationSubscriptions) {
        return sources.sort(function (a, b) {
            let aId = notificationSubscriptions.find((x) => x.scopeId == a.id) ? 1 : 0;
            let bId = notificationSubscriptions.find((x) => x.scopeId == b.id) ? 1 : 0;
            return aId < bId ? -1 : aId > bId ? 1 : 0;
        });
    }
    static createArrayCopy(array) {
        let arrCopy = [];
        let jsonCopy = JSON.parse(JSON.stringify(array));
        jsonCopy.forEach((el) => {
            let obj = Object.assign(new Source(), el);
            arrCopy.push(obj);
        });
        return arrCopy;
    }
    getBoundariesAsString() {
        if (this.boundaries.length <= 0)
            return [];
        let stringBoundaries = [];
        this.boundaries.forEach((boundary) => {
            stringBoundaries.push(boundary.toString());
        });
        return stringBoundaries;
    }
    checkIsOnline() {
        return this.logger?.isOnline === true || this.isOnline === true;
    }
}
