var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.organization_card.title")) + " "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "is-flex is-justify-content-space-between is-align-items-center mb-5",
        },
        [
          _c("h2", { staticClass: "subtitle is-6 has-text-weight-semibold" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("component.organization_card.state.title") + ": "
                ) +
                " "
            ),
            _vm.hasAlarm
              ? _c("span", { staticClass: "mr-2" }, [
                  _c("span", { staticClass: "has-text-danger" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("component.organization_card.state.alarm", {
                          count: _vm.getIssueCountByType(
                            _vm.IssueType.ALARM,
                            "Alarm"
                          ),
                        })
                      )
                    ),
                  ]),
                  _vm._v(" | "),
                ])
              : _vm._e(),
            _vm.hasWarning
              ? _c("span", { staticClass: "mr-2" }, [
                  _c("span", { staticClass: "has-text-warning" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$tc(
                          "component.organization_card.state.warning",
                          _vm.getIssueCountByType(
                            _vm.IssueType.ALARM,
                            "Warning"
                          )
                        )
                      )
                    ),
                  ]),
                  _vm._v(" | "),
                ])
              : _vm._e(),
            _vm.hasLowBattery
              ? _c("span", { staticClass: "mr-2" }, [
                  _c("span", { staticClass: "has-text-warning" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.organization_card.state.low_battery",
                          {
                            count: _vm.getIssueCountByType(
                              _vm.IssueType.LOW_BATTERY
                            ),
                          }
                        )
                      )
                    ),
                  ]),
                  _vm._v(" |"),
                ])
              : _vm._e(),
            _vm.hasOffline
              ? _c("span", { staticClass: "mr-2" }, [
                  _c("span", { staticClass: "has-text-grey" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.organization_card.state.no_connection",
                          {
                            count:
                              _vm.getIssueCountByType(
                                _vm.IssueType.SOURCE_OFFLINE
                              ) +
                              _vm.getIssueCountByType(
                                _vm.IssueType.DEVICE_OFFLINE
                              ),
                          }
                        )
                      )
                    ),
                  ]),
                  _vm._v(" |"),
                ])
              : _vm._e(),
            _vm.hasMaintenance
              ? _c("span", { staticClass: "mr-2" }, [
                  _c("span", { staticClass: "has-text-info" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.organization_card.state.maintenance",
                          {
                            count: _vm.getIssueCountByType(
                              _vm.IssueType.MAINTENANCE
                            ),
                          }
                        )
                      )
                    ),
                  ]),
                  _vm._v("|"),
                ])
              : _vm._e(),
            _vm.fineStates > 0 || _vm.acknowledgedStates > 0
              ? _c("span", { staticClass: "has-text-success" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("component.organization_card.state.fine", {
                        count: _vm.fineStates,
                      })
                    ) + " "
                  ),
                  _vm.acknowledgedStates > 0
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.organization_card.state.acknowledged",
                              { count: _vm.acknowledgedStates }
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "div",
        [
          _vm.departments.getData().length > 0 && _vm.isLoggedFromAdmin
            ? _c(
                "b-field",
                { staticClass: "mx-1 mb-5" },
                [
                  _c("b-input", {
                    attrs: {
                      placeholder: _vm.$t("generals.search"),
                      "icon-pack": "fas",
                      icon: "search",
                      "icon-right": "close-circle",
                      "icon-right-clickable": "",
                    },
                    on: {
                      "icon-right-click": function ($event) {
                        _vm.departmentName = ""
                      },
                    },
                    model: {
                      value: _vm.departmentName,
                      callback: function ($$v) {
                        _vm.departmentName = $$v
                      },
                      expression: "departmentName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.departments.getData().length > 0
        ? [
            _c(
              "div",
              {
                staticClass:
                  "department-item columns is-multiline is-mobile mb-1",
              },
              [
                _vm.filteredDepartments.length > 0
                  ? _vm._l(_vm.filteredDepartments, function (department) {
                      return _c(
                        "div",
                        {
                          key: department.id,
                          staticClass:
                            "column is-half-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd",
                        },
                        [
                          _c("OrganizationItem", {
                            attrs: {
                              favoriteDepartmentId: _vm.favoriteDepartmentId,
                              department: department,
                              issuesSummary: _vm.issuesSummary,
                            },
                            on: {
                              reload: _vm.reload,
                              favoriteSelected: _vm.favoriteSelected,
                            },
                          }),
                        ],
                        1
                      )
                    })
                  : _c("div", { staticClass: "mx-4 mt-2" }, [
                      _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                    ]),
              ],
              2
            ),
          ]
        : [
            _c(
              "b-notification",
              {
                staticClass: "is-child column is-full p-5 mt-5",
                attrs: {
                  type: "is-warning",
                  "has-icon": "",
                  closable: false,
                  "aria-close-label": "Close notification",
                  role: "alert",
                },
              },
              [
                _c("p", { staticClass: "mt-3" }, [
                  _vm._v(
                    _vm._s(_vm.$t("component.organization_card.list.empty_msg"))
                  ),
                ]),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }