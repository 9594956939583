var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("contact_form.feedback")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item is-hidden-mobile",
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clearFilter()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
              ),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.company.list.actions.columns"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    { staticStyle: { "font-size": "1rem" } },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "list",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.tableColumns, function (column) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: column.field,
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-checkbox",
                            {
                              attrs: {
                                disabled:
                                  column.field == _vm.tableColumns.id.field,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.chooseTableColumns(
                                    column.field,
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: column.visible,
                                callback: function ($$v) {
                                  _vm.$set(column, "visible", $$v)
                                },
                                expression: "column.visible",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "contact_form.feedback_table." +
                                        column.field
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "mobile-filter res-table",
          attrs: {
            data: _vm.feedbackList,
            paginated: "",
            "current-page": _vm.page,
            "per-page": _vm.pageSize,
            total: _vm.totalSize,
            "backend-sorting": "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            "page-input": true,
            "pagination-order": "is-centered",
            "backend-filtering": "",
            striped: "",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            sort: _vm.onSort,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.feedbackList.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.totalSize,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.id.field,
              label: _vm.$t(
                "contact_form.feedback_table." + _vm.tableColumns.id.field
              ),
              visible: _vm.tableColumns.id.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isIdSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "contact_form.feedback_table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: { input: _vm.handleFilterIdDebounce },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.filterFeedback(
                                        _vm.tableColumns.id.field,
                                        _vm.idSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.idSearch,
                                    callback: function ($$v) {
                                      _vm.idSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "idSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile is-full-width",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "b-field",
                          { attrs: { expanded: "" } },
                          [
                            _c("b-input", {
                              attrs: {
                                type: "text",
                                name: _vm.$t("contact_form.feedback_table.Id"),
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: { input: _vm.handleFilterIdDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.filterFeedback(
                                    _vm.tableColumns.id.field,
                                    _vm.idSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.idSearch,
                                callback: function ($$v) {
                                  _vm.idSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "idSearch",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (feedbackList) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "is-family-monospace",
                        attrs: {
                          to: {
                            name: "adminFeedbackDetail",
                            params: {
                              feedbackId: feedbackList.row.id,
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [_vm._v(_vm._s(feedbackList.row.id))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.subject.field,
              label: _vm.$t(
                "contact_form.feedback_table." + _vm.tableColumns.subject.field
              ),
              visible: _vm.tableColumns.subject.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isSubjectSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "contact_form.feedback_table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterSubjectDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.filterFeedback(
                                        _vm.tableColumns.subject.field,
                                        _vm.subjectSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.subjectSearch,
                                    callback: function ($$v) {
                                      _vm.subjectSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "subjectSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile is-full-width",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "b-field",
                          { attrs: { expanded: "" } },
                          [
                            _c("b-input", {
                              attrs: {
                                type: "text",
                                name: _vm.$t(
                                  "contact_form.feedback_table.Subject"
                                ),
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: { input: _vm.handleFilterSubjectDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.filterFeedback(
                                    _vm.tableColumns.subject.field,
                                    _vm.subjectSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.subjectSearch,
                                callback: function ($$v) {
                                  _vm.subjectSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "subjectSearch",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (feedbackList) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "adminFeedbackDetail",
                            params: {
                              feedbackId: feedbackList.row.id,
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [_vm._v(_vm._s(feedbackList.row.subject))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.category.field,
              label: _vm.$t(
                "contact_form.feedback_table." + _vm.tableColumns.category.field
              ),
              visible: _vm.tableColumns.category.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isCategorySearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "contact_form.feedback_table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.filterFeedback(
                                          _vm.tableColumns.category.field,
                                          _vm.categorySearch.toString()
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.categorySearch,
                                      callback: function ($$v) {
                                        _vm.categorySearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "categorySearch",
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          selected: "",
                                          hidden: "",
                                          disabled: "",
                                        },
                                        domProps: { value: null },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("generals.search"))
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.FeedbackCategories,
                                      function (option) {
                                        return _c(
                                          "option",
                                          {
                                            key: option.name,
                                            domProps: { value: option.name },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(option.name) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.filterFeedback(
                                      _vm.tableColumns.category.field,
                                      _vm.categorySearch.toString()
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.categorySearch,
                                  callback: function ($$v) {
                                    _vm.categorySearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "categorySearch",
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      selected: "",
                                      hidden: "",
                                      disabled: "",
                                    },
                                    domProps: { value: null },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("generals.search")))]
                                ),
                                _vm._l(
                                  _vm.FeedbackCategories,
                                  function (option) {
                                    return _c(
                                      "option",
                                      {
                                        key: option.name,
                                        domProps: { value: option.name },
                                      },
                                      [_vm._v(" " + _vm._s(option.name) + " ")]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (feedbackList) {
                  return [
                    feedbackList.row.category
                      ? _c(
                          "b-tag",
                          {
                            attrs: {
                              type: feedbackList.row.category.type,
                              rounded: "",
                            },
                          },
                          [_vm._v(_vm._s(feedbackList.row.category.name))]
                        )
                      : _c(
                          "b-tag",
                          {
                            attrs: {
                              type: _vm.FeedbackCategories.UNKNOWN.type,
                              rounded: "",
                            },
                          },
                          [_vm._v(_vm._s(_vm.FeedbackCategories.UNKNOWN.name))]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdAt.field,
              label: _vm.$t(
                "contact_form.feedback_table." +
                  _vm.tableColumns.createdAt.field
              ),
              visible: _vm.tableColumns.createdAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (feedbackList) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(feedbackList.row.createdAt * 1000),
                            null,
                            null,
                            null
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.status.field,
              label: _vm.$t(
                "contact_form.feedback_table." + _vm.tableColumns.status.field
              ),
              visible: _vm.tableColumns.status.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isStatusSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "contact_form.feedback_table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.filterFeedback(
                                          _vm.tableColumns.status.field,
                                          _vm.statusSearch.toString()
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.statusSearch,
                                      callback: function ($$v) {
                                        _vm.statusSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "statusSearch",
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          selected: "",
                                          hidden: "",
                                          disabled: "",
                                        },
                                        domProps: { value: null },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("generals.search"))
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.FeedbackStatus,
                                      function (option) {
                                        return _c(
                                          "option",
                                          {
                                            key: option.name,
                                            domProps: { value: option.name },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(option.name) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.filterFeedback(
                                      _vm.tableColumns.status.field,
                                      _vm.statusSearch.toString()
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.statusSearch,
                                  callback: function ($$v) {
                                    _vm.statusSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "statusSearch",
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      hidden: "",
                                      selected: "",
                                      disabled: "",
                                    },
                                    domProps: { value: null },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("generals.search")))]
                                ),
                                _vm._l(_vm.FeedbackStatus, function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.name,
                                      domProps: { value: option.name },
                                    },
                                    [_vm._v(" " + _vm._s(option.name) + " ")]
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (feedbackList) {
                  return [
                    feedbackList.row.status
                      ? _c(
                          "b-tag",
                          {
                            attrs: {
                              type: feedbackList.row.status.type,
                              rounded: "",
                            },
                          },
                          [_vm._v(_vm._s(feedbackList.row.status.name))]
                        )
                      : _c(
                          "b-tag",
                          {
                            attrs: {
                              type: _vm.FeedbackStatus.UNKNOWN.type,
                              rounded: "",
                            },
                          },
                          [_vm._v(_vm._s(_vm.FeedbackStatus.UNKNOWN.name))]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.sentById.field,
              label: _vm.$t(
                "contact_form.feedback_table." + _vm.tableColumns.sentById.field
              ),
              visible: _vm.tableColumns.sentById.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (feedbackList) {
                  return [
                    feedbackList.row.sentBy.email !== null
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminUserDetail",
                                params: {
                                  userId: feedbackList.row.sentBy.apiUserId,
                                  title: _vm.$t("contact_form.feedback_list"),
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(feedbackList.row.sentBy.username))]
                        )
                      : _c("span", [
                          _vm._v(_vm._s(feedbackList.row.sentBy.username)),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("contact_form.feedback_table.empty")) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }