import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
import NotificationTableFilter from '@/views/components/notification/NotificationTableFilter.vue';
import { AdminDepartment } from '@/entities/models/AdminDepartment';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import { vxm } from '@/store/store.vuex';
var departmentRepository;
var vxDepartmentStore = vxm.departmentStore;
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let NotificationsTable = class NotificationsTable extends Vue {
    constructor() {
        super(...arguments);
        this.tableKey = 0;
        this.isInitiallyRerendered = false;
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.tableName = 'notification';
        this.checkedRows = [];
        this.currentFilter = [];
        this.departmentList = [];
        this.selectedDepartment = null;
        this.isParsing = true;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.rowsPerPage = null;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.dateRange = [];
        this.tableColumns = {
            createdAt: {
                field: 'CreatedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.NotificationsTableCreatedAt)
            },
            category: {
                field: 'Category',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.NotificationsTableCategory)
            },
            title: {
                field: 'Title',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.NotificationsTableTitle)
            },
            priority: {
                field: 'Priority',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.NotificationsTablePriority)
            }
        };
    }
    get parsedNotifications() {
        if (this.notifications) {
            this.isParsing = true;
            let parsedData = this.notifications.getData();
            this.isParsing = false;
            return parsedData;
        }
    }
    get totalSize() {
        if (this.notifications)
            return this.notifications.getPagination().total;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.rowsPerPage + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.rowsPerPage > this.totalSize ? this.totalSize : this.page * this.rowsPerPage;
    }
    getDepartmentById(departmentId) {
        return this.departmentList.find((x) => x.id === departmentId);
    }
    updated() {
        if (!this.isInitiallyRerendered && this.sortDirection.length > 0 && this.sortField.length > 0) {
            this.tableKey += 1;
            this.isInitiallyRerendered = true;
        }
    }
    async created() {
        departmentRepository = new DepartmentRepository(this);
        this.departmentList = AdminDepartment.convertToUserDepartments(await departmentRepository.getAllDepartmentsAdmin());
        this.$root.$on('reloadNotifications', async () => {
            this.isParsing = true;
            this.$emit('loadNotifications', this.pagination, this.sort, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
        });
        this.loadNotifications();
    }
    loadNotifications() {
        this.fillFilterFromRoute();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.rowsPerPage };
        if (this.departmentFilter)
            this.selectedDepartment = this.departmentFilter;
        this.isParsing = true;
        this.$emit('loadNotifications', this.pagination, this.sort, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.notification);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.rowsPerPage = this.tableQueriesfromUrl.count;
            this.currentFilter = this.tableQueriesfromUrl.categories ? JSON.parse(this.tableQueriesfromUrl.categories) : [];
            this.selectedDepartment = this.tableQueriesfromUrl.departmentId
                ? this.getDepartmentById(JSON.parse(this.tableQueriesfromUrl.departmentId))
                : null;
            if (this.tableQueriesfromUrl.dateRange) {
                let convertedDateRange = JSON.parse(this.tableQueriesfromUrl.dateRange);
                this.dateRange = convertedDateRange.map((x) => new Date(x));
            }
            if (!this.sortField || !this.sortDirection || !this.page || !this.rowsPerPage) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.NotificationsListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.NotificationsListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.NotificationsListPage.defaultValue;
        this.rowsPerPage = UserPreferences.LocalStorageKeys.NotificationsListRowsPerPage.defaultValue;
    }
    rowsPerPageSelected() {
        this.reloadNotifications();
    }
    reloadNotifications() {
        this.pagination = { page: this.page, pageSize: this.rowsPerPage };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    markAsRead(notifId) {
        this.isParsing = true;
        this.notifications.setData(this.parsedNotifications.filter((x) => x.notificationId !== notifId));
        this.$emit('notificationRemoved', [notifId], this.pagination, this.sort, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
    }
    removeSelected() {
        this.isParsing = true;
        let list = this.checkedRows.map((x) => x.notificationId);
        this.notifications.setData(this.parsedNotifications.filter((x) => !list.includes(x.notificationId)));
        this.$emit('notificationRemoved', list, this.pagination, this.sort, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
    }
    removeAll() {
        this.$buefy.dialog.confirm({
            title: this.$t('component.notifications_list.remove_all_dialog.title'),
            message: this.$t('component.notifications_list.remove_all_dialog.message'),
            confirmText: this.$t('component.notifications_list.remove_all_dialog.confirm'),
            cancelText: this.$t('generals.no'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.isParsing = true;
                this.notifications.setData(null);
                this.$emit('removeAllNotifications');
            }
        });
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.rowsPerPage };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onOptionsChange(pagination, sort) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
        this.isParsing = true;
        this.$emit('onOptionsChange', options, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
    }
    filterNotifications(selectedCategories) {
        this.currentFilter = selectedCategories;
        let options = {
            pagination: { page: this.page, pageSize: this.rowsPerPage },
            sort: this.sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
        this.isParsing = true;
        this.$emit('onOptionsChange', options, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
    }
    changeRouterUrl(pagination, sort, currentFilter, dateRange, departmentId = '') {
        let convertedDateRange = dateRange.map((x) => x.getTime());
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize
        };
        if (currentFilter.length > 0)
            this.tableQueries.categories = JSON.stringify(currentFilter);
        if (convertedDateRange.length > 0)
            this.tableQueries.dateRange = JSON.stringify(convertedDateRange);
        if (departmentId.length > 0)
            this.tableQueries.departmentId = JSON.stringify(departmentId);
        let objstringify = JSON.stringify(this.tableQueries);
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, { [this.tableName]: objstringify })
        })
            .catch((err) => err);
    }
    clearFilter() {
        this.dateRange = [];
        this.currentFilter = [];
        this.selectedDepartment = this.inNotificationDepartmentPage ? this.getSelectedDepartment() : null;
        let options = {
            pagination: { page: this.page, pageSize: this.rowsPerPage },
            sort: this.sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
        this.isParsing = true;
        this.$emit('onOptionsChange', options, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
    }
    getSelectedDepartment() {
        return vxDepartmentStore.selectedDepartment;
    }
    dateRangeChanged(data) {
        //duplicate date (fix problem in buefy when one date is selected in range picker)
        data[1] = new Date(data[1].getTime());
        this.dateRange = this.dateTimeManager.formatDateRange(data);
        let options = {
            pagination: { page: this.page, pageSize: this.rowsPerPage },
            sort: this.sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
        this.isParsing = true;
        this.$emit('onOptionsChange', options, this.currentFilter, this.dateRange, this.selectedDepartment?.id);
    }
    departmentNameChanged(department) {
        this.selectedDepartment = department;
        let options = {
            pagination: { page: this.page, pageSize: this.rowsPerPage },
            sort: this.sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter, this.dateRange, department?.id);
        this.isParsing = true;
        this.$emit('onOptionsChange', options, this.currentFilter, this.dateRange, department?.id);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.priority.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.priority.field)
                        this.sort.field = this.tableColumns.createdAt.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.NotificationsTablePriority, event);
                break;
            case this.tableColumns.category.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.category.field)
                        this.sort.field = this.tableColumns.createdAt.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.NotificationsTableCategory, event);
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Object })
], NotificationsTable.prototype, "notifications", void 0);
__decorate([
    Prop({ type: Object })
], NotificationsTable.prototype, "departmentFilter", void 0);
__decorate([
    Prop({ type: Object })
], NotificationsTable.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean })
], NotificationsTable.prototype, "canDelete", void 0);
__decorate([
    Prop({ type: Boolean })
], NotificationsTable.prototype, "inNotificationDepartmentPage", void 0);
NotificationsTable = __decorate([
    Component({ components: { NotificationTableFilter } })
], NotificationsTable);
export default NotificationsTable;
