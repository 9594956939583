import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import ReportRecordRepository from '@/services/repository/ReportRecordRepository';
import { PdfReportTypes } from '@/entities/enums/ReportTypes';
import StatisticsType from '@/entities/enums/statisticsType';
import ReportingInterval, { defaultReportingIntervals, getAllAvailableMeasureTimes } from '@/entities/enums/ReportingInterval';
import { DateRangeType } from '@/entities/enums/DateRangeType';
import AppConfig from '@/configLoader';
var reportRecordRepository;
let ExportPdfModal = class ExportPdfModal extends Vue {
    constructor() {
        super(...arguments);
        this.PdfScope = PdfScope;
        this.DateRangeType = DateRangeType;
        this.RecordsTableTypes = RecordsTableTypes;
        this.PdfReportTypes = PdfReportTypes;
        this.dateRange = [];
        this.selectedEntity = PdfScope.GROUP;
        this.selectedSourceGroups = [];
        this.selectedSources = [];
        this.selectedEventTypes = [];
        this.temperatureCrop = null;
        this.humidityCrop = null;
        this.selectedRecordsTableType = RecordsTableTypes.OUT_OF;
        this.showChart = true;
        this.showAnnotations = true;
        this.isLoading = false;
        this.today = new Date();
        this.selectedMode = PdfReportTypes.FULL;
        this.currentlySelectedDay = null;
        this.currentlySelectedDays = [];
        this.currentlySelectedWeek = [];
        this.currentlySelectedWeeks = [];
        this.currentlySelectedLastMonth = null;
        this.currentlySelectedMonth = null;
        this.isDay = false;
        this.isTwoDay = false;
        this.isWeek = false;
        this.isTwoWeek = false;
        this.isLastMonth = false;
        this.isMonth = false;
        this.selectedValue = DateRangeType.RANGE;
        this.dayFilter = null;
        this.monthFilter = null;
        this.interval = null;
        this.offset = 0;
        this.measurementOffsetIndex = 0;
        this.allMeasuredTimes = [];
        this.StatisticsType = StatisticsType;
        this.selectedType = StatisticsType.AVERAGE;
        this.hourFormatInClockPicker = null;
        this.timeOfMeasurement = new Date();
        this.currentShowEmpty = null;
        this.isTempRange = false;
        this.isHumidityRange = false;
        this.reportExportSetting = null;
        this.rawMinDate = new Date();
        this.rawMaxDate = new Date();
        this.wholeDayReport = false;
        this.dayInMilisecs = 24 * 60 * 60 * 1000;
        this.wrongRangeMsg = '';
    }
    get reportRange() {
        return AppConfig.getConfig().attributes?.max_report_range_days || 30;
    }
    selectCalType(event) {
        if (event === DateRangeType.ONE_DAY) {
            this.isTwoDay = false;
            this.isWeek = false;
            this.isTwoWeek = false;
            this.isLastMonth = false;
            this.isMonth = false;
            this.dateDayChanged(this.currentlySelectedDay);
            return (this.isDay = true);
        }
        else if (event === DateRangeType.TWO_DAYS) {
            this.isDay = false;
            this.isWeek = false;
            this.isTwoWeek = false;
            this.isLastMonth = false;
            this.isMonth = false;
            this.dateRange = this.currentlySelectedDays;
            return (this.isTwoDay = true);
        }
        else if (event === DateRangeType.ONE_WEEK) {
            this.isDay = false;
            this.isTwoDay = false;
            this.isTwoWeek = false;
            this.isLastMonth = false;
            this.isMonth = false;
            this.dateRange = this.currentlySelectedWeek;
            return (this.isWeek = true);
        }
        else if (event === DateRangeType.TWO_WEEKS) {
            this.isDay = false;
            this.isTwoDay = false;
            this.isWeek = false;
            this.isLastMonth = false;
            this.isMonth = false;
            this.dateRange = this.currentlySelectedWeeks;
            return (this.isTwoWeek = true);
        }
        else if (event === DateRangeType.LAST_MONTH) {
            this.isDay = false;
            this.isTwoDay = false;
            this.isWeek = false;
            this.isTwoWeek = false;
            this.isMonth = false;
            this.dateMonthChanged(this.currentlySelectedLastMonth);
            return (this.isLastMonth = true);
        }
        else if (event === DateRangeType.MONTH) {
            this.isDay = false;
            this.isTwoDay = false;
            this.isWeek = false;
            this.isTwoWeek = false;
            this.isLastMonth = false;
            this.dateMonthChanged(this.currentlySelectedMonth);
            return (this.isMonth = true);
        }
        else if (event === DateRangeType.RANGE) {
            this.isDay = false;
            this.isTwoDay = false;
            this.isWeek = false;
            this.isTwoWeek = false;
            this.isLastMonth = false;
            return (this.isMonth = false);
        }
    }
    get allReportingIntervals() {
        return defaultReportingIntervals;
    }
    get canHaveOffset() {
        return this.selectedType === StatisticsType.DAILY;
    }
    get hasOneDayIntervalSelected() {
        return this.interval === ReportingInterval.INTERVAL_24_00;
    }
    get temperatureService() {
        return this.eventTypes.find((x) => x.slug == 'temperature');
    }
    get humidityService() {
        return this.eventTypes.find((x) => x.slug == 'humidity');
    }
    get pressureService() {
        return this.eventTypes.find((x) => x.slug == 'pressure');
    }
    get hasTemp() {
        return this.eventTypes.findIndex((element) => element.name === 'Temperature');
    }
    get hasHum() {
        return this.eventTypes.findIndex((element) => element.name === 'Humidity');
    }
    get hasPress() {
        return this.eventTypes.findIndex((element) => element.name === 'Pressure');
    }
    /* Only temperature and humidity services are allowed in reports */
    get reportsEventTypes() {
        return this.eventTypes.filter((x) => x.slug == 'temperature' || x.slug == 'humidity' || x.slug == 'pressure');
    }
    get dateRangeCheck() {
        let max = this.rawMaxDate?.getTime();
        let min = this.rawMinDate?.getTime();
        if (max - min <= 0) {
            this.wrongRangeMsg = this.$t(`component.report.detail_table.report_export.rawReportWrongRangeMsg.invalidRange`).toString();
            return false;
        }
        else if (max - min > this.dayInMilisecs) {
            this.wrongRangeMsg = this.$t(`component.report.detail_table.report_export.rawReportWrongRangeMsg.moreThanDay`).toString();
            return false;
        }
        else if (max - this.today.getTime() > 0) {
            this.wrongRangeMsg = this.$t(`component.report.detail_table.report_export.rawReportWrongRangeMsg.selectedFuture`).toString();
            return false;
        }
        return true;
    }
    created() {
        reportRecordRepository = new ReportRecordRepository(this);
        this.rawMinDate.setHours(0, 0, 0, 0);
        this.temperatureCrop = {
            serviceId: this.temperatureService?.id,
            min: null,
            max: null
        };
        this.humidityCrop = {
            serviceId: this.humidityService?.id,
            min: null,
            max: null
        };
        this.currentlySelectedDay = this.today;
        this.currentlySelectedDays = [
            new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 2),
            this.today
        ];
        this.currentlySelectedWeek = [
            new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7),
            this.today
        ];
        this.currentlySelectedWeeks = [
            new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 14),
            this.today
        ];
        this.currentlySelectedLastMonth = new Date(this.today.getFullYear(), this.today.getMonth() - 1);
        this.currentlySelectedMonth = this.selectedMonth;
        this.monthSelected(this.selectedMonth);
        if (this.refDateRange.length == 2) {
            this.dateRange = this.refDateRange;
            let dateFrom = this.refDateRange[0];
            let dateTo = this.refDateRange[1];
            dateTo.setHours(23, 59, 59, 999);
            this.dateRange = [dateFrom, dateTo];
        }
        else {
            this.getDateRange();
        }
        if (this.storedReportExportSetting) {
            this.fillStoredSetting();
        }
        else {
            this.currentShowEmpty = this.showEmpty;
            this.interval = this.configuration.interval;
            this.selectedType = this.configuration.getModel().model;
            if (this.configuration.getModel().model == StatisticsType.DAILY) {
                this.offset = this.configuration.getModel().modelParameters.Offset;
            }
            if (this.hasOneDayIntervalSelected == true) {
                let date = new Date();
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMinutes(this.configuration.getModel().modelParameters.Offset);
                this.timeOfMeasurement = date;
            }
            if (this.selectedSourceGroupId === null) {
                this.selectedEntity = PdfScope.LOGGER;
                this.selectedSources = this.sources.filter((x) => this.sourceGroupSources.find((y) => y.id == x.id) != null);
            }
            else {
                this.selectedSourceGroups = this.sourceGroups.filter((x) => x.id == this.selectedSourceGroupId);
            }
            this.selectedEventTypes = this.reportsEventTypes.map((x) => x.slug);
        }
        this.hourFormatInClockPicker = this.dateTimeManager.getSelectedClockFormat().slice(0, 2);
        this.assignMeasureTimes(this.interval).then(() => {
            this.measurementOffsetIndex = this.offset / 60;
        });
    }
    fillStoredSetting() {
        this.selectedMode = this.storedReportExportSetting.mode;
        this.isDay = this.storedReportExportSetting.isDay;
        this.isTwoDay = this.storedReportExportSetting.isTwoDay;
        this.isWeek = this.storedReportExportSetting.isWeek;
        this.isTwoWeek = this.storedReportExportSetting.isTwoWeek;
        this.isLastMonth = this.storedReportExportSetting.isLastMonth;
        this.isMonth = this.storedReportExportSetting.isMonth;
        if (this.storedReportExportSetting.isDay)
            this.selectedValue = DateRangeType.ONE_DAY;
        else if (this.storedReportExportSetting.isTwoDay)
            this.selectedValue = DateRangeType.TWO_DAYS;
        else if (this.storedReportExportSetting.isWeek)
            this.selectedValue = DateRangeType.ONE_WEEK;
        else if (this.storedReportExportSetting.isTwoWeek)
            this.selectedValue = DateRangeType.TWO_WEEKS;
        else if (this.storedReportExportSetting.isLastMonth)
            this.selectedValue = DateRangeType.LAST_MONTH;
        else if (this.storedReportExportSetting.isMonth) {
            this.currentlySelectedMonth = new Date(this.storedReportExportSetting.from);
            this.selectedValue = DateRangeType.MONTH;
        }
        else {
            this.dateRange = [new Date(this.storedReportExportSetting.from), new Date(this.storedReportExportSetting.to)];
            this.selectedValue = DateRangeType.RANGE;
        }
        this.selectedEntity = this.storedReportExportSetting.entity;
        this.selectedRecordsTableType = this.storedReportExportSetting.recordsTableType;
        let tempSources = [];
        this.storedReportExportSetting.sources?.forEach((x) => {
            let source = this.sources.find((y) => y.id === x);
            if (source)
                tempSources.push(source);
        });
        if (tempSources.length === 0)
            tempSources = this.sources.filter((y) => this.sourceGroupSources.some((z) => z.id == y.id));
        this.selectedSources = tempSources;
        let tempGroups = [];
        this.storedReportExportSetting.sourceGroups?.forEach((x) => {
            let group = this.sourceGroups.find((y) => y.id === x);
            if (group)
                tempGroups.push(group);
        });
        if (tempGroups.length === 0 && this.selectedSourceGroupId) {
            const selectedGroup = this.sourceGroups.find((y) => y.id === this.selectedSourceGroupId);
            if (selectedGroup)
                tempGroups.push(selectedGroup);
        }
        this.selectedSourceGroups = tempGroups;
        this.interval = this.storedReportExportSetting.interval;
        this.selectedType = this.storedReportExportSetting.statisticsType;
        this.measurementOffsetIndex = this.storedReportExportSetting.measurementOffsetIndex;
        this.offset = this.measurementOffsetIndex * 60;
        this.timeOfMeasurement = this.storedReportExportSetting.timeOfMeasurement;
        this.selectedEventTypes = this.storedReportExportSetting.eventTypes;
        this.showChart = this.storedReportExportSetting.showChart;
        if (this.storedReportExportSetting.temperature.isCrop) {
            this.isTempRange = this.storedReportExportSetting.temperature.isCrop;
            this.temperatureCrop.min = this.storedReportExportSetting.temperature.min;
            this.temperatureCrop.max = this.storedReportExportSetting.temperature.max;
        }
        if (this.storedReportExportSetting.humidity.isCrop) {
            this.isHumidityRange = this.storedReportExportSetting.humidity.isCrop;
            this.humidityCrop.min = this.storedReportExportSetting.humidity.min;
            this.humidityCrop.max = this.storedReportExportSetting.humidity.max;
        }
        this.showAnnotations = this.storedReportExportSetting.showAnnotations;
        this.currentShowEmpty = this.storedReportExportSetting.currentShowEmpty;
        this.rawMinDate = new Date(this.storedReportExportSetting.rawMinDate);
        this.rawMaxDate = new Date(this.storedReportExportSetting.rawMaxDate);
        this.wholeDayReport = this.storedReportExportSetting.wholeDayReport;
    }
    async exportPdf() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let sourceIds = [];
                let createdcrop = [];
                if (this.isTempRange) {
                    createdcrop.push(this.temperatureCrop);
                }
                if (this.isHumidityRange) {
                    createdcrop.push(this.humidityCrop);
                }
                if (this.selectedEntity == PdfScope.LOGGER) {
                    if (this.selectedSources.length <= 0) {
                        VueUtilities.openErrorToast(this, this.$t('component.report.detail_table.report_export.select_entity'));
                        return;
                    }
                    sourceIds = this.selectedSources.map((x) => x.id);
                }
                else {
                    if (this.selectedSourceGroups.length <= 0) {
                        VueUtilities.openErrorToast(this, this.$t('component.report.detail_table.report_export.select_entity'));
                        return;
                    }
                    let groupSourcesIds = this.selectedSourceGroups.flatMap((x) => x.sources.map((y) => y.id));
                    if (groupSourcesIds.length <= 0) {
                        //handle case when selected groups are empty (without loggers)
                        VueUtilities.openErrorToast(this, this.$t('component.report.detail_table.report_export.empty_groups_error'));
                        return;
                    }
                    sourceIds = sourceIds.concat(groupSourcesIds);
                }
                if (this.selectedMode == PdfReportTypes.RAW) {
                    if (!this.dateRangeCheck) {
                        VueUtilities.openErrorToast(this, this.wrongRangeMsg);
                        return;
                    }
                }
                //for onePage report, do not manipulate with time
                const useEndOfDay = this.selectedMode !== PdfReportTypes.RAW;
                this.reportExportSetting = {
                    mode: this.selectedMode,
                    isDay: this.isDay,
                    isTwoDay: this.isTwoDay,
                    isWeek: this.isWeek,
                    isTwoWeek: this.isTwoWeek,
                    isMonth: this.isMonth,
                    isLastMonth: this.isLastMonth,
                    from: this.dateTimeManager.formatStartDateForReports(this.dateRange[0]).text,
                    to: this.dateTimeManager.formatEndDateForReports(this.dateRange[1], useEndOfDay).text,
                    entity: this.selectedEntity,
                    sources: this.selectedSources.map((x) => x.id),
                    sourceGroups: this.selectedSourceGroups.map((x) => x.id),
                    recordsTableType: this.selectedRecordsTableType,
                    interval: this.interval,
                    statisticsType: this.selectedType,
                    measurementOffsetIndex: this.measurementOffsetIndex,
                    timeOfMeasurement: this.timeOfMeasurement,
                    eventTypes: this.selectedEventTypes,
                    showChart: this.showChart,
                    temperature: { isCrop: this.isTempRange, min: this.temperatureCrop.min, max: this.temperatureCrop.max },
                    humidity: { isCrop: this.isHumidityRange, min: this.humidityCrop.min, max: this.humidityCrop.max },
                    showAnnotations: this.showAnnotations,
                    currentShowEmpty: this.currentShowEmpty,
                    rawMinDate: this.rawMinDate,
                    rawMaxDate: this.rawMaxDate,
                    wholeDayReport: this.wholeDayReport
                };
                this.isLoading = true;
                let res = await this.createReport(sourceIds, createdcrop);
                if (!res) {
                    VueUtilities.openErrorToast(this, this.$t('error_messages.file_download_failed'));
                }
                else {
                    VueUtilities.openSuccessToast(this, this.$t('reports.report_requested'));
                    this.resultRetrieved(true);
                    await this.$emit('sendingRequest', this.reportExportSetting);
                }
                this.isLoading = false;
            }
        });
    }
    async createReport(sourceIds, createdcrop) {
        let res = null;
        let config = null;
        switch (this.selectedMode) {
            case PdfReportTypes.ONE_PAGE:
                config = {
                    from: this.dateTimeManager.formatStartDateForReports(this.dateRange[0]).text,
                    to: this.dateTimeManager.formatEndDateForReports(this.dateRange[1], true).text,
                    sourceIds: sourceIds,
                    models: this.selectedType,
                    offset: this.offset,
                    interval: this.interval,
                    showEmpty: this.currentShowEmpty,
                    crop: createdcrop,
                    types: this.selectedEventTypes,
                    timezone: this.dateTimeManager.getSelectedTimezone()
                };
                res = await reportRecordRepository.createOnePagePdfReport(config);
                break;
            case PdfReportTypes.FULL:
                config = {
                    from: this.dateTimeManager.formatStartDateForReports(this.dateRange[0]).text,
                    to: this.dateTimeManager.formatEndDateForReports(this.dateRange[1], true).text,
                    sourceIds: sourceIds,
                    showChart: this.showChart,
                    showAnnotationTable: this.showAnnotations,
                    showRecordsTable: this.selectedRecordsTableType == RecordsTableTypes.OUT_OF ||
                        this.selectedRecordsTableType == RecordsTableTypes.ALL,
                    alarmOnly: this.selectedRecordsTableType == RecordsTableTypes.OUT_OF,
                    models: this.selectedType,
                    crop: createdcrop,
                    offset: this.offset,
                    interval: this.interval,
                    showEmpty: this.currentShowEmpty,
                    types: this.selectedEventTypes,
                    timezone: this.dateTimeManager.getSelectedTimezone()
                };
                res = await reportRecordRepository.createPdfReport(config);
                break;
            case PdfReportTypes.RAW:
                config = {
                    from: this.dateTimeManager.formatStartDateForReports(this.rawMinDate).text,
                    to: this.dateTimeManager.formatEndDateForReports(this.rawMaxDate, false).text,
                    sourceIds: sourceIds,
                    types: this.selectedEventTypes,
                    timezone: this.dateTimeManager.getSelectedTimezone()
                };
                res = await reportRecordRepository.createRawReport(config);
                break;
            default:
                res = null;
                break;
        }
        return res;
    }
    /**
     * Listener for reporting interval change
     */
    async reportingIntervalChanged(step) {
        if (this.hasOneDayIntervalSelected == true) {
            this.clockChanged(this.timeOfMeasurement);
        }
        else {
            this.measurementOffsetIndex = 0;
            this.measuredOffsetChanged();
            this.assignMeasureTimes(step);
        }
    }
    /**
     * Create array with all allowed measure times for selected interval
     */
    async assignMeasureTimes(step) {
        let allTimes = await getAllAvailableMeasureTimes(this, step);
        this.allMeasuredTimes = allTimes;
    }
    /**
     * change listener for offset
     */
    measuredOffsetChanged() {
        if (this.hasOneDayIntervalSelected == true)
            return;
        this.offset = this.measurementOffsetIndex * 60;
    }
    clockChanged(value) {
        if (this.hasOneDayIntervalSelected == false)
            return;
        this.offset = value.getHours() * 60 + value.getMinutes();
    }
    typeChanged() {
        this.measurementOffsetIndex = 0;
        this.measuredOffsetChanged();
        this.assignMeasureTimes(this.interval);
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    dateRangeChanged(data) {
        //duplicate date (fix problem in buefy when one date is selected in range picker)
        //    data[1] = new Date(data[1].getTime());
        //  data[1].setHours(23, 59, 59, 999);
        this.dateRange = this.dateTimeManager.formatDateRange(data);
        let diff_Time = this.dateRange[1].getTime() - this.dateRange[0].getTime();
        let diff_Days = Math.round(diff_Time / (1000 * 3600 * 24));
        if (diff_Days > this.reportRange) {
            VueUtilities.openErrorToast(this, this.$t('component.report.configuration.modal.range_alert', {
                selectedDays: diff_Days,
                maxDays: this.reportRange
            }).toString());
            let end = new Date(this.dateRange[1].getTime());
            let start = new Date(this.dateRange[1].setDate(this.dateRange[1].getDate() - this.reportRange));
            this.dateRange = [start, end];
        }
    }
    dateDayChanged(data) {
        this.daySelected(data);
        this.dateRange = [new Date(this.dayFilter.start), new Date(this.dayFilter.stop)];
    }
    dateMonthChanged(data) {
        this.monthSelected(data);
        this.getDateRange();
    }
    getDateRange() {
        let defaultFrom = new Date(this.monthFilter.startTS * 1000);
        defaultFrom.setDate(1);
        let defaultTo = this.today.getTime() < this.monthFilter.stopTS * 1000 ? this.today : new Date(this.monthFilter.stopTS * 1000);
        this.dateRange = [defaultFrom, defaultTo];
    }
    daySelected(filter) {
        this.dayFilter = this.dateTimeManager.getDayFilter(filter);
    }
    monthSelected(filter) {
        this.monthFilter = this.dateTimeManager.getMonthFilter(filter);
    }
    checkWholeDay() {
        if (this.wholeDayReport) {
            this.setWholeDay(true);
        }
    }
    setWholeDay(value) {
        if (value) {
            this.rawMaxDate = new Date();
            let nextDay = this.rawMinDate.getTime() + this.dayInMilisecs - 1;
            if (nextDay > this.today.getTime()) {
                this.rawMaxDate.setTime(this.today.getTime());
            }
            else {
                this.rawMaxDate.setTime(nextDay);
            }
        }
    }
};
__decorate([
    Prop({ type: Object })
], ExportPdfModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ExportPdfModal.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ExportPdfModal.prototype, "sources", void 0);
__decorate([
    Prop({ type: String })
], ExportPdfModal.prototype, "selectedSourceGroupId", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ExportPdfModal.prototype, "sourceGroupSources", void 0);
__decorate([
    Prop({ type: Object })
], ExportPdfModal.prototype, "configuration", void 0);
__decorate([
    Prop({ type: Date })
], ExportPdfModal.prototype, "selectedMonth", void 0);
__decorate([
    Prop({ type: Boolean })
], ExportPdfModal.prototype, "showEmpty", void 0);
__decorate([
    Prop({ type: Array })
], ExportPdfModal.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Array })
], ExportPdfModal.prototype, "refDateRange", void 0);
__decorate([
    Prop({ type: Object })
], ExportPdfModal.prototype, "storedReportExportSetting", void 0);
ExportPdfModal = __decorate([
    Component({ components: {} })
], ExportPdfModal);
export default ExportPdfModal;
var PdfScope;
(function (PdfScope) {
    PdfScope["LOGGER"] = "logger";
    PdfScope["GROUP"] = "group";
})(PdfScope || (PdfScope = {}));
var RecordsTableTypes;
(function (RecordsTableTypes) {
    RecordsTableTypes["ALL"] = "all";
    RecordsTableTypes["OUT_OF"] = "only_out_of";
    RecordsTableTypes["NONE"] = "none";
})(RecordsTableTypes || (RecordsTableTypes = {}));
