import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import { DeviceInstanceState } from '@/entities/enums/DeviceInstanceState';
import SortDirections from '@/entities/enums/SortDirections';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminCompanyDevicesComponent = class AdminCompanyDevicesComponent extends Vue {
    constructor() {
        super(...arguments);
        this.DeviceInstanceState = DeviceInstanceState;
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminCompanyDevicesSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminCompanyDevicesSortField.defaultValue;
        this.page = null;
        this.pageSize = null;
        this.tableQueries = null;
        this.tableQueriesFromUrl = null;
        this.tableColumns = {
            deviceName: {
                field: 'serialName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesDeviceName)
            },
            deviceId: {
                field: 'deviceId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesDeviceId)
            },
            macAddress: {
                field: 'macAddress',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesMacAddress)
            },
            interval: {
                field: 'interval',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesInterval)
            },
            description: {
                field: 'description',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesDescription)
            },
            isOnline: {
                field: 'isOnline',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesIsOnline)
            }
        };
    }
    created() {
        this.fillFilterFromPref();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadDevices', this.pagination, this.sort);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.deviceId.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesDeviceId, event);
                break;
            case this.tableColumns.macAddress.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesMacAddress, event);
                break;
            case this.tableColumns.interval.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesInterval, event);
                break;
            case this.tableColumns.description.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesDescription, event);
                break;
            case this.tableColumns.isOnline.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyDevicesIsOnline, event);
                break;
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminCompanyDevicesListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminCompanyDevicesListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminCompanyDevicesListPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminCompanyDevicesListRowsPerPage.defaultValue;
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onOptionsChange(pagination, sort) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.$emit('loadDevices', options);
    }
};
__decorate([
    Prop({ type: Array })
], AdminCompanyDevicesComponent.prototype, "devices", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyDevicesComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyDevicesComponent.prototype, "company", void 0);
__decorate([
    Prop({ type: Number })
], AdminCompanyDevicesComponent.prototype, "totalDeviceListSize", void 0);
AdminCompanyDevicesComponent = __decorate([
    Component({})
], AdminCompanyDevicesComponent);
export default AdminCompanyDevicesComponent;
