var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "box" },
    _vm._l(_vm.tableData, function (sourceGroup, index) {
      return _c(
        "div",
        { key: sourceGroup.id, staticClass: "custom-table mb-5" },
        [
          _c(
            "div",
            { staticClass: "header is-flex is-justify-content-space-between" },
            [
              _c(
                "div",
                [
                  sourceGroup.name
                    ? [
                        _vm.$ability.can(
                          _vm.$permActions.MANAGE,
                          _vm.$permSubjects.SOURCE_GROUP
                        ) &&
                        _vm.$ability.can(
                          _vm.$permActions.MANAGE,
                          _vm.$permSubjects.SOURCE
                        )
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "group_detail",
                                    params: {
                                      groupId: sourceGroup.id,
                                      lang: _vm.$route.params.lang,
                                      departmentId:
                                        _vm.$route.params.departmentId,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-wrap-anywhere" },
                                  [_vm._v(_vm._s(sourceGroup.name))]
                                ),
                                _c("b-icon", {
                                  attrs: {
                                    icon: "pencil-alt",
                                    type: "is-black",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(sourceGroup.name))]),
                      ]
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.source_group_picker.default_group_name"
                              )
                            ) +
                            " "
                        ),
                      ]),
                  _c(
                    "span",
                    [
                      _vm._v(" (" + _vm._s(sourceGroup.sources.length) + " "),
                      _c("b-icon", {
                        attrs: { icon: "microchip", size: "is-small" },
                      }),
                      _vm._v(") "),
                    ],
                    1
                  ),
                ],
                2
              ),
              sourceGroup.name
                ? _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label: sourceGroup.visible
                          ? _vm.$t("component.logger.list.table.visible")
                          : _vm.$t("component.logger.list.table.hidden"),
                        type: "is-light",
                        position: "is-left",
                      },
                    },
                    [
                      _c("b-icon", {
                        class: _vm.isLoggedFromAdmin ? "clickable" : "",
                        attrs: {
                          icon: sourceGroup.visible ? "eye" : "eye-slash",
                        },
                        nativeOn: {
                          click: function ($event) {
                            _vm.isLoggedFromAdmin
                              ? _vm.changeVisibility(sourceGroup)
                              : null
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-table",
            {
              ref: "sourceGroupTable",
              refInFor: true,
              staticClass: "table-wrap is-table-detailed-mobile",
              attrs: {
                data: sourceGroup.sources,
                "mobile-cards": false,
                hoverable: "",
                narrowed: "",
                detailed: "",
                "detail-transition": "fade",
                "show-detail-icon": false,
                "default-sort": "name",
                "default-sort-direction": "asc",
                "row-class": (row, index) =>
                  row.isVisible && "is-table-row-selected-mobile",
              },
              on: {
                click: function ($event) {
                  return _vm.toggle($event, index)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "detail",
                    fn: function (props) {
                      return [
                        _c("GroupListItemMobile", {
                          staticClass: "custom-table-mobile",
                          attrs: {
                            data: props.row.source,
                            dateTimeManager: _vm.dateTimeManager,
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: "source.name",
                  label: _vm.$t("component.location.list.table.name"),
                  sortable: "",
                  "cell-class": "is-table-50-mobile-smaller",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "p",
                            { staticClass: "has-text-weight-medium" },
                            [
                              _vm._v(" " + _vm._s(props.row.source.name) + " "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "is-hidden-mobile",
                                  attrs: {
                                    to: {
                                      name: "logger_detail",
                                      params: {
                                        locationId: props.row.source.id,
                                        departmentId:
                                          _vm.$route.params.departmentId,
                                        lang: _vm.$route.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "clickable",
                                    attrs: { icon: "fas fa-external-link-alt" },
                                  }),
                                ],
                                1
                              ),
                              props.row.source.logger &&
                              props.row.source.logger.state ==
                                _vm.ServiceState.ERROR
                                ? _c(
                                    "b-tag",
                                    {
                                      attrs: { rounded: "", type: "is-danger" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "loggers.logger_states.Error"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              props.row.source.activeService
                                ? _c(
                                    "b-tag",
                                    { attrs: { rounded: "", type: "is-info" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "loggers.logger_states.Service"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "source.logger.serialNumber",
                  label: _vm.$t("component.location.list.table.logger_name"),
                  sortable: "",
                  "cell-class": "is-table-40-mobile-smaller",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.source.logger &&
                          props.row.source.logger.serialNumber
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.row.source.logger.serialNumber
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v(" - ")]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "source.logger.isOnline",
                  label: _vm.$t("component.location.list.table.state"),
                  sortable: "",
                  "header-class": "pl-0-mobile",
                  "cell-class": "pl-1-mobile is-table-5-mobile-smaller",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("b-icon", {
                            staticClass: "is-size-7",
                            attrs: {
                              type:
                                props.row.source.logger &&
                                props.row.source.logger.isOnline == true
                                  ? "is-success"
                                  : "is-danger",
                              icon: "circle",
                            },
                          }),
                          props.row.source.logger &&
                          props.row.source.logger.isOnline == true
                            ? _c(
                                "span",
                                { staticClass: "is-connected-hidden" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("generals.online")) +
                                      " "
                                  ),
                                ]
                              )
                            : _c(
                                "span",
                                { staticClass: "is-connected-hidden" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("generals.offline")) +
                                      " "
                                  ),
                                ]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "source.lastSeen",
                  label: _vm.$t("component.location.list.table.last_seen"),
                  sortable: "",
                  "cell-class": "is-hidden-mobile",
                  "header-class": "is-hidden-mobile",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dateTimeManager.formatTime(
                                  new Date(props.row.source.lastSeen * 1000),
                                  null,
                                  null,
                                  null
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "source.boundaries",
                  label: _vm.$t("component.location.list.table.boundaries"),
                  "cell-class": "is-hidden-mobile",
                  "header-class": "is-hidden-mobile",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.source.boundaries &&
                          props.row.source.boundaries.length > 0
                            ? _c(
                                "div",
                                _vm._l(
                                  props.row.source.boundaries,
                                  function (boundary) {
                                    return _c(
                                      "div",
                                      {
                                        key: boundary.eventTypeSlug,
                                        staticClass:
                                          "is-flex is-align-items-center",
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: _vm.getBoundaryIcon(
                                              boundary.eventTypeSlug
                                            ),
                                            "custom-class": "mr-3",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "is-size-7" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.DecimalConversion.toCommaString(
                                                    boundary.lowerAlarmBoundary
                                                      .Value
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "is-size-7" },
                                          [_vm._v("  |  ")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "is-size-7" },
                                          [
                                            boundary.lowerWarningBoundary
                                              .Value != null
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.DecimalConversion.toCommaString(
                                                          boundary
                                                            .lowerWarningBoundary
                                                            .Value
                                                        )
                                                      )
                                                  ),
                                                ])
                                              : _c("span", [_vm._v("-")]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "is-size-7" },
                                          [_vm._v("  |  ")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "is-size-7" },
                                          [
                                            boundary.upperWarningBoundary
                                              .Value != null
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.DecimalConversion.toCommaString(
                                                          boundary
                                                            .upperWarningBoundary
                                                            .Value
                                                        )
                                                      )
                                                  ),
                                                ])
                                              : _c("span", [_vm._v("-")]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "is-size-7" },
                                          [_vm._v("  |  ")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "is-size-7" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.DecimalConversion.toCommaString(
                                                    boundary.upperAlarmBoundary
                                                      .Value
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "is-size-7" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                " (" +
                                                  _vm._s(
                                                    boundary.upperAlarmBoundary
                                                      .Unit
                                                  ) +
                                                  ")"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "is-italic is-grey is-size-7",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          `measurement_status.NotAvailable`
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "isVisible",
                  "cell-class": "is-hidden-tablet",
                  "header-class": "is-hidden-tablet",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("b-icon", {
                            attrs: {
                              icon: _vm.chevronIcon(props.row.isVisible),
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }