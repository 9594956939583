import { __decorate } from "tslib";
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import NotificationSubscriptionType, { typeHasDelay, typeHasOnResolve, typeHasRepeat } from '@/entities/notifications/NotificationSubscriptionType';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NotificationRuleDisplayMode } from '@/entities/notifications/NotificationRuleDisplayMode';
import DelayOptions from '@/entities/enums/DelayOptions';
import RepeatOptions from '@/entities/enums/RepeatOptions';
import RequestState from '@/entities/enums/RequestState';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import NotificationPriority from '@/entities/enums/NotificationPriority';
import NotificationChannelType from '@/entities/notifications/NotificationChannelType';
var notificationSubscriptionsRepository;
let GeneralNotificationComponent = class GeneralNotificationComponent extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.NotificationRuleDisplayMode = NotificationRuleDisplayMode;
        this.RepeatOptions = RepeatOptions;
        this.typeHasOnResolve = typeHasOnResolve;
        this.typeHasRepeat = typeHasRepeat;
        this.typeHasDelay = typeHasDelay;
        this.currentEnabled = true;
        this.currentDisplayMode = NotificationRuleDisplayMode.BASIC;
        this.currentChannels = {};
        this.requestState = null;
        this.localRule = null;
        this.isLoadingGeneral = false;
    }
    /**
     * Returns true if new rule needs to be created and false if rule should be updated.
     */
    get isCreating() {
        if (this.localRule == null)
            return true;
        else
            return false;
    }
    /**
     * Returns true if currently selected display mode is basic
     */
    get isBasic() {
        return this.currentDisplayMode === null || this.currentDisplayMode === NotificationRuleDisplayMode.BASIC;
    }
    /**
     * Returns number of columns for table of settings. Number of columns depends on notification type,
     * allowed channels and selected display mode.
     */
    get numberOfColumns() {
        if (this.isBasic)
            return this.type.channel.length;
        else
            return this.type.channel.length + 1;
    }
    get delayOptions() {
        return DelayOptions.getValues();
    }
    get requestFinished() {
        return this.requestState === RequestState.SUCCESS;
    }
    get requestPending() {
        return this.requestState === RequestState.PENDING;
    }
    get requestError() {
        return this.requestState === RequestState.ERROR;
    }
    /**
     * Returns true when user has not the phone number saved in his profile
     */
    get phoneIsNotDefined() {
        return this.currentUser.detailedInfo.PhoneNumber == null;
    }
    /**
     * Returns true when user has the phone number saved in his profile but this number wasn't verified
     */
    get phoneIsNotVerified() {
        return this.currentUser.detailedInfo.PhoneNumber.Verified == false;
    }
    /**
     * Check if user cas use phone channels for notifications (Phone/SMS)
     */
    get phoneChannelsAvailable() {
        return !this.phoneIsNotDefined && !this.phoneIsNotVerified;
    }
    get titleText() {
        switch (this.type.name) {
            case NotificationSubscriptionType.DEVICE_OFFLINE:
                return this.$t('notifications.configuration.device_offline_title');
                break;
            case NotificationSubscriptionType.SOURCE_OFFLINE:
                return this.$t('notifications.configuration.source_offline_title');
                break;
            case NotificationSubscriptionType.LOW_BATTERY:
                return this.$t('notifications.configuration.low_battery_title');
                break;
            case NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION:
                return this.$t('notifications.configuration.calibration_title');
                break;
            case NotificationSubscriptionType.PDF_RECORDS_REGENERATED:
                return this.$t('notifications.configuration.pdf_generated_title');
                break;
            case NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED:
                return this.$t('notifications.configuration.auto_pdf_generated_title');
                break;
            case NotificationSubscriptionType.RECORDS_UPDATED:
                return this.$t('notifications.configuration.records_updated_title');
                break;
            case NotificationSubscriptionType.LOGGER_STATE:
                return this.$t('notifications.configuration.logger_state_title');
                break;
            default:
                return this.$t('notifications.configuration.format_title');
                break;
        }
    }
    get descriptionText() {
        switch (this.type.name) {
            case NotificationSubscriptionType.DEVICE_OFFLINE:
                return this.$t('notifications.configuration.device_offline_desc');
                break;
            case NotificationSubscriptionType.SOURCE_OFFLINE:
                return this.$t('notifications.configuration.source_offline_desc');
                break;
            case NotificationSubscriptionType.LOW_BATTERY:
                return this.$t('notifications.configuration.low_battery_desc');
                break;
            case NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION:
                return this.$t('notifications.configuration.calibration_desc');
                break;
            case NotificationSubscriptionType.PDF_RECORDS_REGENERATED:
                return this.$t('notifications.configuration.pdf_generated_desc');
                break;
            case NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED:
                return this.$t('notifications.configuration.auto_pdf_generated_desc');
                break;
            case NotificationSubscriptionType.RECORDS_UPDATED:
                return this.$t('notifications.configuration.records_updated_desc');
                break;
            case NotificationSubscriptionType.LOGGER_STATE:
                return this.$t('notifications.configuration.logger_state_desc');
                break;
            default:
                return '';
                break;
        }
    }
    get turnedOffText() {
        switch (this.type.name) {
            case NotificationSubscriptionType.DEVICE_OFFLINE:
                return this.$t('notifications.configuration.device_offline_turned_off');
                break;
            case NotificationSubscriptionType.SOURCE_OFFLINE:
                return this.$t('notifications.configuration.source_offline_turned_off');
                break;
            case NotificationSubscriptionType.LOW_BATTERY:
                return this.$t('notifications.configuration.low_battery_turned_off');
                break;
            case NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION:
                return this.$t('notifications.configuration.calibration_turned_off');
                break;
            case NotificationSubscriptionType.PDF_RECORDS_REGENERATED:
                return this.$t('notifications.configuration.pdf_generated_turned_off');
                break;
            case NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED:
                return this.$t('notifications.configuration.auto_pdf_generated_turned_off');
                break;
            case NotificationSubscriptionType.RECORDS_UPDATED:
                return this.$t('notifications.configuration.records_updated_turned_off');
                break;
            case NotificationSubscriptionType.LOGGER_STATE:
                return this.$t('notifications.configuration.logger_state_off');
                break;
            default:
                return '';
                break;
        }
    }
    get onResolveDesc() {
        switch (this.type.name) {
            case NotificationSubscriptionType.DEVICE_OFFLINE:
                return this.$t('notifications.configuration.settings.resolve_desc_device');
                break;
            case NotificationSubscriptionType.SOURCE_OFFLINE:
                return this.$t('notifications.configuration.settings.resolve_desc_logger');
                break;
            case NotificationSubscriptionType.LOW_BATTERY:
            case NotificationSubscriptionType.CUSTOMER_ENDING_CALIBRATION:
            case NotificationSubscriptionType.PDF_RECORDS_REGENERATED:
            case NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED:
            case NotificationSubscriptionType.RECORDS_UPDATED:
            case NotificationSubscriptionType.LOGGER_STATE:
                return this.$t('notifications.configuration.settings.resolve_desc_general');
                break;
            default:
                return this.$t('notifications.configuration.settings.resolve_desc_general');
                break;
        }
    }
    created() {
        //Create local instance of rule to avoid error about prop mutation
        this.localRule = this.rule;
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        if (this.isCreating == true) {
            this.currentEnabled = false;
            this.type.channel.forEach((channel) => {
                this.currentChannels[channel.type] = NotificationSubscription.getDefaultValuesForTypeAndChannel(this.type.name, channel.type);
            });
        }
        else {
            this.currentEnabled = this.localRule.enabled;
            this.currentDisplayMode = this.localRule.displayMode;
            //assign default channels values
            this.type.channel.forEach((channel) => {
                let existingChannel = this.localRule.channels.find((x) => x.type == channel.type);
                //create default channel settings
                let ch = NotificationSubscription.getDefaultValuesForTypeAndChannel(this.type.name, channel.type);
                if (existingChannel) {
                    //load values from existing rule
                    ch = {
                        enabled: true,
                        delay: existingChannel.settings.delay,
                        onResolve: existingChannel.settings.onResolve,
                        repeat: existingChannel.settings.repeat
                    };
                }
                else {
                    //if updating rule and channel is not used, set disabled to true
                    ch.enabled = false;
                }
                this.currentChannels[channel.type] = ch;
            });
        }
        if (this.autoSave === false) {
            this.$emit('ruleChanged', this.buildRuleForParent());
        }
    }
    changeDisplayMode(selected) {
        this.currentDisplayMode = selected;
        this.save();
    }
    /**
     * Check if given type has available onResolve setting
     */
    channelForTypeHasOnResolve(channelType) {
        let channel = this.type.channel.find((x) => x.type == channelType);
        return channel.onResolve;
    }
    save() {
        this.isLoadingGeneral = true;
        if (this.autoSave === false) {
            this.$emit('ruleChanged', this.buildRuleForParent());
        }
        else {
            this.requestState = RequestState.PENDING;
            if (this.isCreating) {
                this.createNewRule();
            }
            else {
                this.updateRule();
            }
        }
        this.isLoadingGeneral = false;
    }
    buildRuleForParent() {
        let currentChannelsDTO = {};
        Object.keys(this.currentChannels).forEach((channelKey) => {
            if (this.currentChannels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: this.currentChannels[channelKey].delay,
                    onResolve: this.currentChannels[channelKey].onResolve,
                    repeat: this.currentChannels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let newRule = {
            ScopeType: NotificationScopeType.COMPANY,
            ScopeId: this.departmentId,
            NotificationType: this.type.name,
            Priority: NotificationPriority.NORMAL.name,
            Enabled: this.currentEnabled,
            Channels: currentChannelsDTO,
            DisplayMode: this.currentDisplayMode,
            PresetId: this.rule?.presetId,
            ScheduleId: this.scheduleId
        };
        return newRule;
    }
    async createNewRule() {
        let currentChannelsDTO = {};
        Object.keys(this.currentChannels).forEach((channelKey) => {
            if (this.currentChannels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: this.currentChannels[channelKey].delay,
                    onResolve: this.currentChannels[channelKey].onResolve,
                    repeat: this.currentChannels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let newRule = {
            ScopeType: NotificationScopeType.COMPANY,
            ScopeId: this.departmentId,
            NotificationType: this.type.name,
            Priority: NotificationPriority.NORMAL.name,
            Enabled: true,
            Channels: currentChannelsDTO,
            DisplayMode: this.currentDisplayMode,
            PresetId: null,
            ScheduleId: this.scheduleId
        };
        let result = await notificationSubscriptionsRepository.createNotificationSubscription([newRule], this.currentUser.apiUserId, this.departmentId);
        if (result instanceof (Array)) {
            this.requestState = RequestState.SUCCESS;
            this.localRule = result[0];
        }
        else {
            this.requestState = RequestState.ERROR;
        }
    }
    async updateRule() {
        let currentChannelsDTO = {};
        Object.keys(this.currentChannels).forEach((channelKey) => {
            if (this.currentChannels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: this.currentChannels[channelKey].delay,
                    onResolve: this.currentChannels[channelKey].onResolve,
                    repeat: this.currentChannels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let updateDTO = {
            Priority: this.localRule.priority.name,
            Enabled: this.currentEnabled,
            Channels: currentChannelsDTO,
            NotificationType: this.type.name,
            DisplayMode: this.currentDisplayMode,
            PresetId: this.rule?.presetId
        };
        let result = await notificationSubscriptionsRepository.updateNotificationSubscription(this.localRule.notificationSubscriptionId, updateDTO, this.currentUser.apiUserId, this.departmentId);
        if (result === true) {
            this.requestState = RequestState.SUCCESS;
        }
        else {
            this.requestState = RequestState.ERROR;
        }
    }
    isPhoneChannel(channel) {
        return channel === NotificationChannelType.SMS || channel === NotificationChannelType.CALL;
    }
};
__decorate([
    Prop({ type: Object })
], GeneralNotificationComponent.prototype, "rule", void 0);
__decorate([
    Prop({ type: Object })
], GeneralNotificationComponent.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Object })
], GeneralNotificationComponent.prototype, "type", void 0);
__decorate([
    Prop({ type: String })
], GeneralNotificationComponent.prototype, "departmentId", void 0);
__decorate([
    Prop({ type: String })
], GeneralNotificationComponent.prototype, "scheduleId", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], GeneralNotificationComponent.prototype, "autoSave", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], GeneralNotificationComponent.prototype, "advancedAllowed", void 0);
GeneralNotificationComponent = __decorate([
    Component({})
], GeneralNotificationComponent);
export default GeneralNotificationComponent;
