import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import { DeviceInstanceState } from '@/entities/enums/DeviceInstanceState';
import { Department } from '@/entities/models/Department';
import VueUtilities from '@/services/VueUtilities';
import AppConfig from '@/configLoader';
import SortDirections from '@/entities/enums/SortDirections';
import debounce from 'lodash.debounce';
const DEBOUNCE_TIMER = 500;
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminResellerDevicesComponent = class AdminResellerDevicesComponent extends Vue {
    constructor() {
        super(...arguments);
        this.Action = Action;
        this.DeviceInstanceState = DeviceInstanceState;
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.selectedValue = '';
        this.isNameSearch = true;
        this.isIdSearch = false;
        this.isCompanyNameSearch = false;
        this.isConfigIntervalSearch = false;
        this.isMacAddressSearch = false;
        this.isFirmwareVersionSearch = false;
        this.isDescriptionSearch = false;
        this.isOfflineSearch = false;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.currentFilters = [];
        this.nameSearch = '';
        this.companyNameSearch = '';
        this.configIntervalSearch = '';
        this.idSearch = '';
        this.macSearch = '';
        this.firmwareVersionSearch = '';
        this.descriptionSearch = '';
        this.offlineSearch = null;
        this.urlQueriesLoaded = false;
        this.tableColumns = {
            serialName: {
                field: 'SerialName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListSerialName),
                hasSearch: true
            },
            macAddress: {
                field: 'MacAddress',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListMacAddress),
                hasSearch: true
            },
            deviceId: {
                field: 'Id',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListDeviceId),
                hasSearch: true
            },
            company: {
                field: 'CompanyName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListCompany),
                hasSearch: true
            },
            currentInterval: {
                field: 'ConfigInterval',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListInterval),
                hasSearch: true
            },
            fwVersion: {
                field: 'FirmwareVersion',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListVersion),
                hasSearch: true
            },
            lastSeen: {
                field: 'LastSeen',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListLastSeen),
                hasSearch: false
            },
            description: {
                field: 'Description',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListDescription),
                hasSearch: true
            },
            isOffline: {
                field: 'IsOffline',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceListIsOffline),
                hasSearch: true
            }
        };
        this.handleFilterNameDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.serialName.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterCompanyNameDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.company.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterIntervalDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.currentInterval.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterMacDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.macAddress.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterFirmwareVersionDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.fwVersion.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterDescriptionDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.description.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterIdDebounce = debounce((e) => {
            this.filterDevices(this.tableColumns.deviceId.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    loadDevices() {
        if (this.devicesLoaded) {
            if (!this.urlQueriesLoaded) {
                this.loadQueries();
            }
            this.resellerDevices(this.pagination, this.sort);
        }
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    created() {
        this.selectedValue = this.tableColumns.serialName.field;
        this.loadQueries();
        if (this.devicesLoaded && this.devices.length == 0) {
            this.resellerDevices(this.pagination, this.sort);
        }
    }
    loadQueries() {
        this.fillFilterFromRoute();
        this.fillSearches();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.urlQueriesLoaded = true;
    }
    get hasCopyIconAllowed() {
        return AppConfig.getConfig().features.copy_to_clipboard_btn == true;
    }
    fillSearches() {
        if (this.currentFilters.length !== 0) {
            this.nameSearch =
                this.filterExists('SerialName@=') !== -1
                    ? this.currentFilters[this.filterExists('SerialName@=')].substring(12)
                    : '';
            this.idSearch =
                this.filterExists('Id==') !== -1 ? this.currentFilters[this.filterExists('Id==')].substring(4) : '';
            this.macSearch =
                this.filterExists('MacAddress@=') !== -1
                    ? this.currentFilters[this.filterExists('MacAddress@=')].substring(12)
                    : '';
            this.companyNameSearch =
                this.filterExists('CompanyName@=') !== -1
                    ? this.currentFilters[this.filterExists('CompanyName@=')].substring(13)
                    : '';
            this.configIntervalSearch =
                this.filterExists('ConfigInterval@=') !== -1
                    ? this.currentFilters[this.filterExists('ConfigInterval@=')].substring(16)
                    : '';
            this.firmwareVersionSearch =
                this.filterExists('FirmwareVersion@=') !== -1
                    ? this.currentFilters[this.filterExists('FirmwareVersion@=')].substring(17)
                    : '';
            this.descriptionSearch =
                this.filterExists('Description@=') !== -1
                    ? this.currentFilters[this.filterExists('Description@=')].substring(13)
                    : '';
            this.offlineSearch =
                this.filterExists('IsOffline==true') !== -1
                    ? true
                    : this.filterExists('IsOffline==false') !== -1
                        ? false
                        : null;
        }
    }
    filterExists(filter) {
        return this.currentFilters.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value) {
                this.currentFilters.splice(index, 1);
            }
            else {
                this.currentFilters[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilters.push(filter + value);
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminDevice);
            this.sortField = this.tableQueriesfromUrl.field.charAt(0).toLowerCase() + this.tableQueriesfromUrl.field.slice(1);
            this.sortDirection = this.tableQueriesfromUrl.direction;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.pageSize;
            this.currentFilters = JSON.parse(this.tableQueriesfromUrl.filters);
            this.changeRouterUrl('adminDevice', JSON.stringify(this.tableQueriesfromUrl));
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    actionSelect(user) {
        this.$emit('unassignDevice', user);
    }
    hasPermissionToViewDepartment(departmentId) {
        return Department.hasPermissionToViewDepartment(departmentId, this);
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminResellerDeviceListSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminResellerDeviceListSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminResellerDeviceListPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminResellerDeviceListRowsPerPage.defaultValue;
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    sorted(field, order) {
        this.sortField = field;
        this.sortDirection = order;
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    filterDevices(searchField, value, isLoadingRequired = true) {
        value = value.includes(',') ? value.replace(/,/g, '\\,') : value;
        switch (searchField) {
            case this.tableColumns.deviceId.field:
                this.$validator.validateAll().then(async (result) => {
                    if (result) {
                        this.replaceFilter('Id==', value);
                    }
                    else {
                        return;
                    }
                });
                break;
            case this.tableColumns.serialName.field:
                this.replaceFilter('SerialName@=', value);
                break;
            case this.tableColumns.company.field:
                this.replaceFilter('CompanyName@=', value);
                break;
            case this.tableColumns.currentInterval.field:
                this.replaceFilter('ConfigInterval@=', value);
                break;
            case this.tableColumns.macAddress.field:
                this.replaceFilter('MacAddress@=', value);
                break;
            case this.tableColumns.fwVersion.field:
                this.replaceFilter('FirmwareVersion@=', value);
                break;
            case this.tableColumns.description.field:
                this.replaceFilter('Description@=', value);
                break;
            case this.tableColumns.isOffline.field:
                this.replaceFilter('IsOffline==', value);
                break;
        }
        if (isLoadingRequired) {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    this.onOptionsChange(this.pagination, this.sort);
                }
            });
        }
    }
    clearFilter(isLoadingRequired = true) {
        this.nameSearch = '';
        this.companyNameSearch = '';
        this.configIntervalSearch = '';
        this.idSearch = '';
        this.macSearch = '';
        this.firmwareVersionSearch = '';
        this.descriptionSearch = '';
        this.offlineSearch = null;
        this.currentFilters = [];
        if (isLoadingRequired)
            this.onPageChange(1);
    }
    changeRouterUrl(componentName, queries) {
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                [componentName]: queries
            })
        })
            .catch((err) => err);
    }
    // changing default mobile search field
    changeDefaultFieldMobile() {
        this.isNameSearch = true;
        this.selectedValue = this.tableColumns.serialName.field;
        this.changeSearchField(this.tableColumns.serialName.field, false);
    }
    // changing default sort field
    changeDefaultSortField(sortFieldName) {
        if (this.sort.field == sortFieldName) {
            this.sort.field = this.tableColumns.serialName.field;
            this.sortField = this.sort.field;
        }
    }
    isMobileView() {
        return window.innerWidth <= 768;
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.deviceId.field:
                if (!event) {
                    if (this.isMobileView && this.isIdSearch)
                        this.changeDefaultFieldMobile();
                    this.idSearch = '';
                    this.filterDevices(this.tableColumns.deviceId.field, this.idSearch, false);
                    this.changeDefaultSortField(this.tableColumns.deviceId.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListDeviceId, event);
                break;
            case this.tableColumns.macAddress.field:
                if (!event) {
                    if (this.isMobileView && this.isMacAddressSearch)
                        this.changeDefaultFieldMobile();
                    this.macSearch = '';
                    this.filterDevices(this.tableColumns.macAddress.field, this.macSearch, false);
                    this.changeDefaultSortField(this.tableColumns.macAddress.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListMacAddress, event);
                break;
            case this.tableColumns.company.field:
                if (!event) {
                    if (this.isMobileView && this.isCompanyNameSearch)
                        this.changeDefaultFieldMobile();
                    this.companyNameSearch = '';
                    this.filterDevices(this.tableColumns.company.field, this.companyNameSearch, false);
                    this.changeDefaultSortField(this.tableColumns.company.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListCompany, event);
                break;
            case this.tableColumns.currentInterval.field:
                if (!event) {
                    if (this.isMobileView && this.isConfigIntervalSearch)
                        this.changeDefaultFieldMobile();
                    this.configIntervalSearch = '';
                    this.filterDevices(this.tableColumns.currentInterval.field, this.configIntervalSearch, false);
                    this.changeDefaultSortField(this.tableColumns.currentInterval.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListInterval, event);
                break;
            case this.tableColumns.fwVersion.field:
                if (!event) {
                    if (this.isMobileView && this.isFirmwareVersionSearch)
                        this.changeDefaultFieldMobile();
                    this.firmwareVersionSearch = '';
                    this.filterDevices(this.tableColumns.fwVersion.field, this.firmwareVersionSearch, false);
                    this.changeDefaultSortField(this.tableColumns.fwVersion.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListVersion, event);
                break;
            case this.tableColumns.lastSeen.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.lastSeen.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListLastSeen, event);
                break;
            case this.tableColumns.description.field:
                if (!event) {
                    if (this.isMobileView && this.isDescriptionSearch)
                        this.changeDefaultFieldMobile();
                    this.descriptionSearch = '';
                    this.filterDevices(this.tableColumns.description.field, this.descriptionSearch, false);
                    this.changeDefaultSortField(this.tableColumns.description.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListDescription, event);
                break;
            case this.tableColumns.isOffline.field:
                if (!event) {
                    if (this.isMobileView && this.isOfflineSearch)
                        this.changeDefaultFieldMobile();
                    this.offlineSearch = null;
                    this.filterDevices(this.tableColumns.isOffline.field, '', false);
                    this.changeDefaultSortField(this.tableColumns.isOffline.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceListIsOffline, event);
                break;
        }
        this.$nextTick(() => {
            this.$refs.table.initSort();
        });
    }
    changeSearchField(event, isLoadingRequired = true) {
        this.isNameSearch = false;
        this.isCompanyNameSearch = false;
        this.isConfigIntervalSearch = false;
        this.isMacAddressSearch = false;
        this.isFirmwareVersionSearch = false;
        this.isDescriptionSearch = false;
        this.isOfflineSearch = false;
        this.isIdSearch = false;
        switch (event) {
            case this.tableColumns.serialName.field:
                this.isNameSearch = true;
                break;
            case this.tableColumns.deviceId.field:
                this.isIdSearch = true;
                break;
            case this.tableColumns.company.field:
                this.isCompanyNameSearch = true;
                break;
            case this.tableColumns.currentInterval.field:
                this.isConfigIntervalSearch = true;
                break;
            case this.tableColumns.fwVersion.field:
                this.isFirmwareVersionSearch = true;
                break;
            case this.tableColumns.macAddress.field:
                this.isMacAddressSearch = true;
                break;
            case this.tableColumns.description.field:
                this.isDescriptionSearch = true;
                break;
            case this.tableColumns.isOffline.field:
                this.isOfflineSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter(isLoadingRequired);
    }
    onOptionsChange(pagination, sort) {
        this.tableQueries = {
            field: sort.field,
            direction: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            pageSize: pagination.pageSize,
            filters: JSON.stringify(this.currentFilters.filter((x) => x != null))
        };
        this.changeRouterUrl('adminDevice', JSON.stringify(this.tableQueries));
        this.resellerDevices(this.pagination, this.sort);
    }
    resellerDevices(pagination, sort) {
        sort.field = sort.field.charAt(0).toUpperCase() + sort.field.slice(1);
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.$emit('resellerDevices', options, this.currentFilters);
    }
    initLoadResellerDevices(pagination, sort) {
        sort.field = sort.field.charAt(0).toUpperCase() + sort.field.slice(1);
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.$emit('initLoadResellerDevices', options, this.currentFilters);
    }
    copyLinkToClipboard(id) {
        try {
            navigator.clipboard.writeText(id);
            VueUtilities.openSuccessToast(this, this.$t('generals.copy_success'));
        }
        catch (err) {
            VueUtilities.openErrorToast(this, this.$t('generals.copy_failure'));
        }
    }
};
__decorate([
    Prop({ type: Array })
], AdminResellerDevicesComponent.prototype, "devices", void 0);
__decorate([
    Prop({ type: Object })
], AdminResellerDevicesComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminResellerDevicesComponent.prototype, "isLayoutList", void 0);
__decorate([
    Prop({ type: Number })
], AdminResellerDevicesComponent.prototype, "totalSize", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminResellerDevicesComponent.prototype, "devicesLoaded", void 0);
__decorate([
    Watch('devicesLoaded')
], AdminResellerDevicesComponent.prototype, "loadDevices", null);
AdminResellerDevicesComponent = __decorate([
    Component({})
], AdminResellerDevicesComponent);
export default AdminResellerDevicesComponent;
var Action;
(function (Action) {
    Action["REMOVE"] = "remove";
})(Action || (Action = {}));
