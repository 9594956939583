var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _vm.deviceIsActiveFlag == true
      ? _c("div", [
          _c(
            "section",
            [
              _c("p", { staticClass: "pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.device.register.modal.description")
                    ) +
                    " "
                ),
              ]),
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t(
                      "component.device.register.modal.installation_code"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t("component.device.register.modal.device_key")
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t("component.device.register.modal.device_key")
                    ),
                  },
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:14|deviceRegistrationKey",
                        expression: "'required|max:14|deviceRegistrationKey'",
                      },
                    ],
                    attrs: {
                      id: "reg-key",
                      type: "text",
                      placeholder: _vm.$t(
                        "component.device.register.modal.device_key_placeholder"
                      ),
                      name: _vm.$t(
                        "component.device.register.modal.device_key"
                      ),
                    },
                    on: { input: _vm.formatKey },
                    model: {
                      value: _vm.registrationKey,
                      callback: function ($$v) {
                        _vm.registrationKey = $$v
                      },
                      expression: "registrationKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "has-text-right" },
                [
                  !_vm.isModal && _vm.deviceIsActiveFlag == true
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            type: "is-primary",
                            expanded: "",
                            disabled: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.validateBeforeSubmit()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("component.device.register.modal.register")
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("h3", { staticClass: "subtitle has-text-weight-semibold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.device.register.modal.sticker_render_title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("b-image", {
                  attrs: {
                    src: require("@/assets/img/stickerLayout.png"),
                    alt: "stickerRender",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column is-two-fifths" },
              [
                _c("h3", { staticClass: "subtitle has-text-weight-semibold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "component.device.register.modal.device_render_title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("b-image", {
                  attrs: {
                    src: require("@/assets/img/deviceRender.png"),
                    alt: "stickerRender",
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _c(
          "div",
          [
            _c(
              "h2",
              {
                staticClass:
                  "is-size-4 has-text-centered has-text-weight-semibold has-text-primary",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.device.register.modal.device_not_active.title"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c("DeviceOfflineTemplate"),
            _c(
              "div",
              { staticClass: "has-text-centered pt-3" },
              [
                !_vm.isModal && _vm.deviceIsActiveFlag == false
                  ? _c(
                      "b-button",
                      {
                        attrs: { type: "is-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.changeActiveState(true)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.device.register.modal.device_not_active.btn"
                            )
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }