import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserRepository from '@/services/repository/UserRepository';
import UserPreferences from '@/services/UserPreferences';
var userRepository;
let AdminResellerUsersComponent = class AdminResellerUsersComponent extends Vue {
    constructor() {
        super(...arguments);
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminResellerUsersSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminResellerUsersSortField.defaultValue;
        this.Action = Action;
        this.currentUser = null;
        this.currentUserId = null;
        this.tableColumns = {
            fullName: {
                field: 'fullName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListFullName)
            },
            email: {
                field: 'email',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListEmail)
            },
            username: {
                field: 'username',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminUserListUsername)
            }
        };
    }
    async created() {
        userRepository = new UserRepository(this);
        this.loadData();
    }
    async loadData() {
        this.currentUser = await userRepository.getCurrentUser();
        this.currentUserId = this.currentUser.apiUserId;
    }
    actionSelectedUser(user) {
        this.$emit('unassignUser', user);
    }
    onSort(field, order) {
        this.sortField = field;
        this.sortDirection = order;
    }
    // changing default sort field
    changeDefaultSortField(sortFieldName) {
        if (this.sortField == sortFieldName) {
            this.sortField = this.tableColumns.fullName.field;
        }
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.email.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.email.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListEmail, event);
                break;
            case this.tableColumns.username.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.username.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminUserListUsername, event);
                break;
        }
        this.$nextTick(() => {
            this.$refs.table.initSort();
        });
    }
};
__decorate([
    Prop({ type: Array })
], AdminResellerUsersComponent.prototype, "users", void 0);
__decorate([
    Prop({ type: Object })
], AdminResellerUsersComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminResellerUsersComponent.prototype, "isLayoutList", void 0);
AdminResellerUsersComponent = __decorate([
    Component({})
], AdminResellerUsersComponent);
export default AdminResellerUsersComponent;
var Action;
(function (Action) {
    Action["REMOVE"] = "remove";
})(Action || (Action = {}));
