import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
import VueUtilities from '@/services/VueUtilities';
import AppConfig from '@/configLoader';
import LoggedUserManager from '@/services/LoggedUserManager';
import debounce from 'lodash.debounce';
import OrganizationStatus from '../../organization/OrganizationStatus.vue';
import { getIssueTypes, IssueType } from '@/entities/enums/IssueType';
import { getTagColor } from '@/entities/enums/DepartmentTags';
const DEBOUNCE_TIMER = 500;
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminCompanyList = class AdminCompanyList extends Vue {
    constructor() {
        super(...arguments);
        this.IssueType = IssueType;
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.isModalActive = false;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
        this.selectedCompanyToLogin = null;
        this.selectedValue = '';
        this.isNameSearch = true;
        this.isResponsiblePersonSearch = false;
        this.isTagSearch = false;
        this.isTierSearch = false;
        this.isIdSearch = false;
        this.isStatusSearch = false;
        this.isIcoSearch = false;
        this.isResellerNameSearch = false;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.currentFilters = [];
        this.tableColumns = {
            companyId: {
                field: 'companyId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyId),
                hasSearch: true
            },
            companyName: {
                field: 'companyName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyName),
                hasSearch: true
            },
            companyStatus: {
                field: 'companyStatus',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyStatus),
                hasSearch: true
            },
            ico: {
                field: 'ico',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListIco),
                hasSearch: true
            },
            responsiblePerson: {
                field: 'responsiblePerson',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListResponsiblePerson),
                hasSearch: false
            },
            resellerName: {
                field: 'resellerName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListResponsiblePerson),
                hasSearch: false
            },
            tag: {
                field: 'tag',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListTag),
                hasSearch: true
            },
            note: {
                field: 'note',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListNote)
            },
            tierName: {
                field: 'tierName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListTierName),
                hasSearch: true
            },
            userCount: {
                field: 'userCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListUserCount),
                hasSearch: false
            },
            deviceCount: {
                field: 'deviceCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListDeviceCount),
                hasSearch: false
            },
            loggerCount: {
                field: 'loggerCount',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListLoggerCount),
                hasSearch: false
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCreatedAt),
                hasSearch: false
            }
        };
        this.idSearch = '';
        this.companyNameSearch = '';
        this.responsiblePersonSearch = '';
        this.resellerNameSearch = '';
        this.icoSearch = '';
        this.tierSearch = '';
        this.tagSearch = '';
        this.companyStatusSearch = '';
        this.handleFilterIdDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.companyId.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterCompanyNameDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.companyName.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterCompanyStatusDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.companyStatus.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterResponsiblePersonDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.responsiblePerson.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterResellerNameDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.resellerName.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterIcoDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.ico.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterTierNameDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.tierName.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterTagDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.tag.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    get hasCopyIconAllowed() {
        return AppConfig.getConfig().features.copy_to_clipboard_btn == true;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    get issueTypes() {
        return getIssueTypes();
    }
    get isMobileView() {
        return window.innerWidth <= 768;
    }
    getTagType(tag) {
        return getTagColor(tag);
    }
    created() {
        //remove note field for reseller
        if (this.isResellerAdmin) {
            delete this.tableColumns.note;
        }
        this.selectedValue = this.tableColumns.companyName.field;
        this.fillFilterFromRoute();
        this.fillSearches();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    fillSearches() {
        if (this.currentFilters.length !== 0) {
            this.idSearch =
                this.filterExists('CompanyId==') !== -1
                    ? this.currentFilters[this.filterExists('CompanyId==')].substring(11)
                    : '';
            this.companyNameSearch =
                this.filterExists('CompanyName@=') !== -1
                    ? this.currentFilters[this.filterExists('CompanyName@=')].substring(13)
                    : '';
            this.icoSearch =
                this.filterExists('Ico@=') !== -1 ? this.currentFilters[this.filterExists('Ico@=')].substring(5) : '';
            this.tierSearch =
                this.filterExists('TierName@=') !== -1
                    ? this.currentFilters[this.filterExists('TierName@=')].substring(10)
                    : '';
            this.companyStatusSearch =
                this.filterExists('UnresolvedIssues>0|') !== -1
                    ? this.currentFilters[this.filterExists('UnresolvedIssues>0|')].substring(19)
                    : '';
            this.tagSearch =
                this.filterExists('Tag@=') !== -1 ? this.currentFilters[this.filterExists('Tag@=')].substring(5) : '';
            this.responsiblePersonSearch =
                this.filterExists('Company.ResponsiblePerson.FullName@=') !== -1
                    ? this.currentFilters[this.filterExists('Company.ResponsiblePerson.FullName@=')].substring(36)
                    : '';
            this.resellerNameSearch =
                this.filterExists('Seller.Name@=') !== -1
                    ? this.currentFilters[this.filterExists('Seller.Name@=')].substring(13)
                    : '';
        }
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminCompany);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.pageSize = this.tableQueriesfromUrl.count;
            this.currentFilters = JSON.parse(this.tableQueriesfromUrl.filters);
            // send queries to parent component
            let objStringAdminCompany = JSON.stringify(this.tableQueriesfromUrl);
            this.$emit('changeRouterUrlFromCompany', objStringAdminCompany);
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminCompanySortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminCompanySortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminCompanyPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminCompanyRowsPerPage.defaultValue;
    }
    onSort(field, order) {
        this.sortField = field;
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onPageChange(page) {
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onOptionsChange(pagination, sort) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilters);
        this.$emit('onOptionsChange', options, this.currentFilters);
    }
    changeRouteUrl() {
        this.onOptionsChange(this.pagination, this.sort);
    }
    rowsPerPageSelected() {
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    loginIntoCompany(company) {
        this.selectedCompanyToLogin = company;
        this.$emit('loginIntoCompany', this.selectedCompanyToLogin, this.$route.name, this.$route.params);
    }
    changeRouterUrl(pagination, sort, currentFilters) {
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize,
            filters: JSON.stringify(currentFilters.filter((x) => x != null))
        };
        let objStringAdminCompany = JSON.stringify(this.tableQueries);
        this.$emit('changeRouterUrlFromCompany', objStringAdminCompany);
    }
    filterExists(filter) {
        return this.currentFilters.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        value = value.trim();
        if (index !== -1) {
            if (!value || value == null || value == '') {
                this.currentFilters.splice(index, 1);
            }
            else {
                this.currentFilters[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilters.push(filter + value);
    }
    filterDepartments(searchField, value) {
        value = value.includes(',') ? value.replace(/,/g, '\\,') : value;
        switch (searchField) {
            case this.tableColumns.companyId.field:
                this.$validator.validateAll().then(async (result) => {
                    if (result) {
                        this.replaceFilter('CompanyId==', value);
                    }
                    else {
                        return;
                    }
                });
                break;
            case this.tableColumns.companyName.field:
                this.replaceFilter('CompanyName@=', value);
                break;
            case this.tableColumns.ico.field:
                this.replaceFilter('Ico@=', value);
                break;
            case this.tableColumns.tierName.field:
                this.replaceFilter('TierName@=', value);
                break;
            case this.tableColumns.companyStatus.field:
                this.replaceFilter('UnresolvedIssues>0|', value);
                break;
            case this.tableColumns.tag.field:
                this.replaceFilter('Tag@=', value);
                break;
            case this.tableColumns.responsiblePerson.field:
                this.replaceFilter('Company.ResponsiblePerson.FullName@=', value);
                break;
            case this.tableColumns.resellerName.field:
                this.replaceFilter('Seller.Name@=', value);
                break;
            default:
                break;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.onOptionsChange(this.pagination, this.sort);
            }
        });
    }
    clearFilter() {
        this.idSearch = '';
        this.companyNameSearch = '';
        this.companyStatusSearch = '';
        this.icoSearch = '';
        this.tagSearch = '';
        this.tierSearch = '';
        this.resellerNameSearch = '';
        this.currentFilters = [];
        this.onPageChange(1);
    }
    changeSearchField(event) {
        this.isTierSearch = false;
        this.isNameSearch = false;
        this.isIdSearch = false;
        this.isIcoSearch = false;
        this.isStatusSearch = false;
        this.isTagSearch = false;
        this.isResellerNameSearch = false;
        this.isResponsiblePersonSearch = false;
        switch (event) {
            case this.tableColumns.companyId.field:
                this.isIdSearch = true;
                break;
            case this.tableColumns.companyName.field:
                this.isNameSearch = true;
                break;
            case this.tableColumns.ico.field:
                this.isIcoSearch = true;
                break;
            case this.tableColumns.tierName.field:
                this.isTierSearch = true;
                break;
            case this.tableColumns.companyStatus.field:
                this.isStatusSearch = true;
                break;
            case this.tableColumns.tag.field:
                this.isTagSearch = true;
                break;
            case this.tableColumns.responsiblePerson.field:
                this.isResponsiblePersonSearch = true;
                break;
            case this.tableColumns.resellerName.field:
                this.isResellerNameSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    copyLinkToClipboard(id) {
        try {
            navigator.clipboard.writeText(id);
            VueUtilities.openSuccessToast(this, this.$t('generals.copy_success'));
        }
        catch (err) {
            VueUtilities.openErrorToast(this, this.$t('generals.copy_failure'));
        }
    }
    saveTagChanges(depId, name, ico, tag, note, respPerson) {
        let data = {
            CompanyName: name,
            Ico: ico,
            Note: note,
            ResponsiblePersonId: respPerson?.apiUserId,
            Tag: tag.CompanyTagId === -1 ? null : tag.Tag
        };
        this.$emit('saveTagChanges', data, depId);
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.companyName.field;
        this.changeSearchField(this.tableColumns.companyName.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.companyId.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.companyId = '';
                    if (this.sort.field == this.tableColumns.companyId.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyId, event);
                break;
            case this.tableColumns.companyStatus.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.companyStatus = '';
                    if (this.sort.field == this.tableColumns.companyStatus.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyStatus, event);
                break;
            case this.tableColumns.ico.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.ico = '';
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListIco, event);
                break;
            case this.tableColumns.responsiblePerson.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.responsiblePerson = '';
                    if (this.sort.field == this.tableColumns.responsiblePerson.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListResponsiblePerson, event);
                break;
            case this.tableColumns.resellerName.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.resellerName = '';
                    if (this.sort.field == this.tableColumns.resellerName.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListResellerName, event);
                break;
            case this.tableColumns.tag.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.tag = '';
                    if (this.sort.field == this.tableColumns.tag.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListTag, event);
                break;
            case this.tableColumns.note.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.note.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListNote, event);
                break;
            case this.tableColumns.tierName.field:
                if (!event) {
                    this.changeDefaultField();
                    this.$refs.table.filters.tierName = '';
                    if (this.sort.field == this.tableColumns.tierName.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListTierName, event);
                break;
            case this.tableColumns.userCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.userCount.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListUserCount, event);
                break;
            case this.tableColumns.note.field:
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListNote, event);
                break;
            case this.tableColumns.deviceCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.deviceCount.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListDeviceCount, event);
                break;
            case this.tableColumns.loggerCount.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.loggerCount.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListLoggerCount, event);
                break;
            case this.tableColumns.createdAt.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.createdAt.field)
                        this.sort.field = this.tableColumns.companyName.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCreatedAt, event);
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Array })
], AdminCompanyList.prototype, "companies", void 0);
__decorate([
    Prop({ type: Array })
], AdminCompanyList.prototype, "departmentTags", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Number })
], AdminCompanyList.prototype, "totalSize", void 0);
AdminCompanyList = __decorate([
    Component({
        components: {
            OrganizationStatus
        }
    })
], AdminCompanyList);
export default AdminCompanyList;
