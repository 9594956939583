import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import { ReportRecord } from '@/entities/models/ReportRecord';
import { ApiQueries } from '@/entities/ApiQueries';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiStatus from '@/entities/enums/apiStatuses';
import FileDownloader from '../FileDownloader';
import { Report } from '@/entities/models/Report';
import { Statistics } from '@/entities/Statistics';
import { Annotation } from '@/entities/models/Annotation';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
const DEFAULT_PDF_REPORT_FILENAME = 'report.pdf';
const DEFAULT_CSV_REPORT_FILENAME = 'report.csv';
export default class ReportRecordRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getReportRequests(departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EXPORTPDF, Subjects.REPORTS)) {
            return new Array();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.ExportPdf}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return Report.fromApi(response.data.data);
    }
    async getReportRecordsForSources(sourceIds = [], startTimeFilter, stopTimeFilter, hasAnnotations, alarmOnly, showEmpty, model, interval, offset, timezone, departmentId = this.getSelectedDepartmentId(), signal = undefined) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.REPORTS)) {
            return {};
        }
        if (sourceIds.length <= 0)
            return {};
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Records}`;
        let queries = new ApiQueries();
        queries.sourcesArray = sourceIds;
        queries.timeFrom = startTimeFilter;
        queries.timeTo = stopTimeFilter;
        queries.annotations = hasAnnotations;
        queries.alarmOnly = alarmOnly;
        queries.model = model;
        queries.interval = interval;
        queries.offset = offset;
        queries.showEmpty = showEmpty;
        queries.timezone = timezone;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, true, signal);
        if (response.status != ApiStatus.SUCCESS)
            throw new Error(response.status);
        else
            return ReportRecord.fromApi(response.data.data);
    }
    async getStatisticForSources(sourceIds = [], startTimeFilter, stopTimeFilter, model, interval, offset, timezone, departmentId = this.getSelectedDepartmentId(), signal = undefined) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.REPORTS)) {
            return {};
        }
        if (sourceIds.length <= 0)
            return {};
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Statistics}`;
        let queries = new ApiQueries();
        queries.sourcesArray = sourceIds;
        queries.timeFrom = startTimeFilter;
        queries.timeTo = stopTimeFilter;
        queries.model = model;
        queries.interval = interval;
        queries.offset = offset;
        queries.timezone = timezone;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, true, signal);
        if (response.status != ApiStatus.SUCCESS)
            return {};
        else
            return Statistics.fromApi(response.data.data);
    }
    async getAnnotationsForSource(sourceId, includeStatistics, startBefore, startAfter, endBefore, endAfter, model, interval, offset, timezone, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.ANNOTATE, Subjects.REPORTS)) {
            return [];
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Annotations}`;
        let queries = new ApiQueries();
        queries.sourceId = sourceId;
        queries.includeStatistics = includeStatistics;
        queries.startBefore = startBefore;
        queries.startAfter = startAfter;
        queries.endBefore = endBefore;
        queries.endAfter = endAfter;
        queries.model = model;
        queries.interval = interval;
        queries.offset = offset;
        queries.timezone = timezone;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return Annotation.fromApi(response.data.data);
    }
    async createAnnotation(annotation, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.ANNOTATE, Subjects.REPORTS)) {
            throw 'Forbidden action createAnnotationsForSource';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Annotations}`;
        let response = await AxiosService.postDataToServer(url, annotation, this.ctx);
        if (response.status === ApiStatus.NOT_ALLOWED) {
            return response.data.code;
        }
        else if (response.status != ApiStatus.CREATED)
            return new Annotation();
        else
            return Annotation.fromApi(response.data.data);
    }
    async deleteAnnotation(id, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.ANNOTATE, Subjects.REPORTS)) {
            throw 'Forbidden action deleteAnnotation';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Annotations}/${id}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        return response.status === ApiStatus.NO_CONTENT;
    }
    async editAnnotation(annotation, id, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.ANNOTATE, Subjects.REPORTS)) {
            throw 'Forbidden action editAnnotation';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Annotations}/${id}`;
        let response = await AxiosService.putDataToServer(url, annotation, this.ctx);
        //TODO: overit response ked to opravia na backende
        if (response.status != ApiStatus.SUCCESS)
            return new Annotation();
        else
            return Annotation.fromApi(response.data.data);
    }
    async downloadCsv(config, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.REPORTS)) {
            return false;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.ExportCsv}`;
        let data = new FormData();
        data.set('from', config.from);
        data.set('to', config.to);
        data.set('timezone', config.timezone);
        config.sourceIds.forEach((id) => {
            data.append('sourceIds[]', id);
        });
        config.types.forEach((type) => {
            data.append('types[]', type);
        });
        if (config.offset) {
            data.set('offset', config.offset.toString());
        }
        data.set('models[]', config.models);
        data.set('interval', config.interval.toString());
        data.set('showEmpty', config.showEmpty.toString());
        let response = await AxiosService.downloadFile_POST(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS) {
            FileDownloader.downloadFile(response, DEFAULT_CSV_REPORT_FILENAME);
            return true;
        }
        else
            return false;
    }
    async downloadRawCsv(config, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.REPORTS)) {
            return false;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.ExportRawCsv}`;
        let data = new FormData();
        data.set('from', config.from);
        data.set('to', config.to);
        data.set('timezone', config.timezone);
        data.set('showEmpty', config.showEmpty.toString());
        config.sourceIds.forEach((id) => {
            data.append('sourceIds[]', id);
        });
        config.types.forEach((type) => {
            data.append('types[]', type);
        });
        let response = await AxiosService.downloadFile_POST(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS) {
            FileDownloader.downloadFile(response, DEFAULT_CSV_REPORT_FILENAME);
            return true;
        }
        else
            return false;
    }
    async downloadPdfReport(name, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EXPORTPDF, Subjects.REPORTS)) {
            return false;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.ExportPdf}/${name}`;
        let response = await AxiosService.downloadFile_GET(url, this.ctx);
        if (response.status === ApiStatus.SUCCESS) {
            FileDownloader.downloadFile(response, DEFAULT_PDF_REPORT_FILENAME);
            return true;
        }
        else
            return false;
    }
    async deletePdfReport(id, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EXPORTPDF, Subjects.REPORTS)) {
            return new Array();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.ExportPdf}/${id}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status != ApiStatus.NO_CONTENT)
            return false;
        else
            return true;
    }
    async createPdfReport(config, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EXPORTPDF, Subjects.REPORTS)) {
            return false;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.ExportPdf}`;
        let data = new FormData();
        data.set('from', config.from);
        data.set('to', config.to);
        data.set('timezone', config.timezone);
        data.set('showChart', config.showChart.toString());
        data.set('showAnnotationTable', config.showAnnotationTable.toString());
        config.types.forEach((type) => {
            data.append('types[]', type);
        });
        data.set('showRecordsTable', config.showRecordsTable);
        data.set('alarmOnly', config.alarmOnly.toString());
        data.set('models[]', config.models);
        if (config.offset) {
            data.set('offset', config.offset.toString());
        }
        data.set('interval', config.interval.toString());
        config.sourceIds.forEach((id) => {
            data.append('sourceIds[]', id);
        });
        data.set('showEmpty', config.showEmpty.toString());
        data.set('showStatistics', config.showStatistics.toString());
        config.crop.forEach((crop, index) => {
            data.set(`crop[${index}].serviceId`, crop.serviceId.toString());
            data.set(`crop[${index}].min`, crop.min.toString());
            data.set(`crop[${index}].max`, crop.max.toString());
        });
        if (config.boundaries) {
            config.boundaries.forEach((boundary, index) => {
                data.set(`boundaries[${index}].customLowerValue`, boundary.CustomLowerValue.toString());
                data.set(`boundaries[${index}].customUpperValue`, boundary.CustomUpperValue.toString());
                data.set(`boundaries[${index}].eventType`, boundary.EventType.toString());
            });
        }
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status === ApiStatus.SUCCESS) {
            return response.data.data;
        }
        else
            return false;
    }
    async createOnePagePdfReport(config, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EXPORTPDF, Subjects.REPORTS)) {
            return false;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.ExportOnePagePdf}`;
        let data = new FormData();
        data.set('from', config.from);
        data.set('to', config.to);
        data.set('timezone', config.timezone);
        data.set('showChart', 'true');
        config.types.forEach((type) => {
            data.append('types[]', type);
        });
        data.set('models[]', config.models);
        if (config.offset) {
            data.set('offset', config.offset.toString());
        }
        data.set('interval', config.interval.toString());
        data.set('showEmpty', config.showEmpty.toString());
        data.set('showStatistics', config.showStatistics.toString());
        config.sourceIds.forEach((id) => {
            data.append('sourceIds[]', id);
        });
        config.crop.forEach((crop, index) => {
            data.set(`crop[${index}].serviceId`, crop.serviceId.toString());
            data.set(`crop[${index}].min`, crop.min.toString());
            data.set(`crop[${index}].max`, crop.max.toString());
        });
        if (config.boundaries) {
            config.boundaries.forEach((boundary, index) => {
                data.set(`boundaries[${index}].customLowerValue`, boundary.CustomLowerValue.toString());
                data.set(`boundaries[${index}].customUpperValue`, boundary.CustomUpperValue.toString());
                data.set(`boundaries[${index}].eventType`, boundary.EventType.toString());
            });
        }
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        return response.status === ApiStatus.SUCCESS;
    }
    async createRawReport(config, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EXPORTPDF, Subjects.REPORTS)) {
            return false;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.ExportRawPdf}`;
        let data = new FormData();
        data.set('from', config.from);
        data.set('to', config.to);
        data.set('timezone', config.timezone);
        config.types.forEach((type) => {
            data.append('types[]', type);
        });
        config.sourceIds.forEach((id) => {
            data.append('sourceIds[]', id);
        });
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        return response.status === ApiStatus.SUCCESS;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
