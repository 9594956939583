import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import VueUtilities from '@/services/VueUtilities';
var departmentRepository;
var sourceRepository;
let SelectCompanyModal = class SelectCompanyModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.companyList = new Array();
        this.company = null;
        this.name = '';
        this.backupLogger = null;
        this.existingName = false;
        this.sourceCreationEnabled = true;
    }
    created() {
        departmentRepository = new DepartmentRepository(this);
        sourceRepository = new SourceRepository(this);
        this.backupLogger = this.logger;
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.companyList = (await departmentRepository.getAllDepartmentsAdmin()).getData();
        this.isLoading = false;
    }
    async checkName() {
        if (this.company) {
            let checkResponse = await sourceRepository.checkIfNameExists(this.backupLogger.additionalProperties.name, this.company.companyId);
            this.existingName = checkResponse.Exist;
            this.backupLogger.additionalProperties.hasErrorField = this.assignFieldError();
            this.backupLogger.additionalProperties.errorMsg = this.assignFieldErrorMsg();
        }
    }
    assignFieldError() {
        if (this.errors.has(this.$t('admin.component.loggers.selectCompanyModal.name')) || this.existingName == true)
            return true;
        else
            return false;
    }
    assignFieldErrorMsg() {
        if (this.errors.has(this.$t('admin.component.loggers.selectCompanyModal.name')))
            return this.$t('component.logger.assignSourceList.list.table.msg.empty_name').toString();
        else if (this.existingName == true)
            return this.$t('component.logger.assignSourceList.list.table.msg.unique_name').toString();
        else
            return null;
    }
    get filteredCompanyList() {
        return this.companyList.filter((option) => {
            return option.companyName.toLowerCase().indexOf(this.name.toLowerCase()) >= 0;
        });
    }
    select(company) {
        this.company = company;
        this.checkName();
    }
    registerLogger() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.sourceCreationEnabled) {
                    if (!this.existingName == true) {
                        this.$emit('registerLogger', this.company, this.backupLogger, this.sourceCreationEnabled);
                    }
                    else {
                        this.showExistingNameToast();
                    }
                }
                else {
                    this.$emit('registerLogger', this.company, this.backupLogger, this.sourceCreationEnabled);
                }
            }
        });
    }
    showExistingNameToast() {
        VueUtilities.openErrorToast(this, this.$t('component.logger.assignSourceList.list.table.msg.unique_name').toString());
    }
    fillNamesWithSerialNumbers() {
        this.errors.items = [];
        this.backupLogger.additionalProperties.name = this.logger.currentlyPublishedAs.serialNumber;
        this.checkName();
    }
};
__decorate([
    Prop({ type: Object })
], SelectCompanyModal.prototype, "logger", void 0);
SelectCompanyModal = __decorate([
    Component({})
], SelectCompanyModal);
export default SelectCompanyModal;
