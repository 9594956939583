var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("h3", { staticClass: "subtitle is-4" }, [
          _vm._v(" " + _vm._s(_vm.$t("component.issues.title")) + " "),
        ]),
      ]),
      _c(
        "div",
        [
          _c("IssueTableFilter", {
            attrs: {
              dateTimeManager: _vm.dateTimeManager,
              tableColumns: _vm.tableColumns,
              devices: _vm.devices,
              sources: _vm.sources,
              companyLoggers: _vm.companyLoggers,
              fromAdmin: _vm.fromAdmin,
              isIncludeResolvedUrl: _vm.isIncludeResolved,
              selectedTypeUrl: _vm.selectedType,
              selectedDateRangeUrl: _vm.selectedDateRange,
              entityIdUrl: _vm.entityId,
              dateRangeValueUrl: _vm.dateRangeValue,
            },
            on: {
              issueTableCheckbox: _vm.issueTableCheckbox,
              issueTypeSelected: _vm.issueTypeSelected,
              clearFilter: _vm.clearFilter,
              entitySelected: _vm.entitySelected,
              dateRangeSelected: _vm.dateRangeSelected,
              chooseTableColumns: _vm.chooseTableColumns,
            },
          }),
        ],
        1
      ),
      _vm.departmentIssues
        ? _c(
            "b-table",
            {
              ref: "table",
              staticClass: "table-wrap",
              attrs: {
                data: _vm.departmentIssues.getData(),
                paginated: "",
                "current-page": _vm.page,
                "per-page": _vm.rowsPerPage,
                total: _vm.totalSize,
                "default-sort-direction": _vm.sortDirection,
                "default-sort": _vm.sortField,
                loading: _vm.isIssuesTableLoading,
                striped: "",
                "page-input": true,
                "pagination-order": "is-centered",
                detailed: "",
                "detail-key": "id",
                "show-detail-icon": true,
                "backend-pagination": "",
                "backend-sorting": "",
                "backend-filtering": "",
              },
              on: {
                "update:currentPage": function ($event) {
                  _vm.page = $event
                },
                "update:current-page": function ($event) {
                  _vm.page = $event
                },
                "page-change": _vm.onPageChange,
                sort: _vm.sorted,
                "details-open": _vm.loadIssueNotification,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "detail",
                    fn: function (issue) {
                      return [
                        _c("article", [
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("component.issues.detail.title")
                                ) +
                                " "
                            ),
                            _c("hr", { staticClass: "dropdown-divider" }),
                            _c("div", { staticClass: "columns" }, [
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "info-table table-layout-equal table-borderless card-table",
                                  },
                                  [
                                    _c("tr", [
                                      _c("td", { staticClass: "row-label" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.issues.table.type"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "notification_rule_types." +
                                                      issue.row.type
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          issue.row.type ==
                                            _vm.NotificationSubscriptionType
                                              .MEASURED_DATA_ALARM &&
                                          _vm.MeasurementStatuses.ALARM.name ===
                                            issue.row.metadata.level
                                            ? _c(
                                                "b-tag",
                                                {
                                                  attrs: { type: "is-danger" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        `measurement_status.${issue.row.metadata.level}`
                                                      )
                                                    ) + " "
                                                  ),
                                                ]
                                              )
                                            : issue.row.type ==
                                                _vm.NotificationSubscriptionType
                                                  .MEASURED_DATA_ALARM &&
                                              _vm.MeasurementStatuses.WARN
                                                .name ===
                                                issue.row.metadata.level
                                            ? _c(
                                                "b-tag",
                                                {
                                                  attrs: { type: "is-warning" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        `measurement_status.${issue.row.metadata.level}`
                                                      )
                                                    ) + " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("tr", [
                                      _c("td", { staticClass: "row-label" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.issues.table.startedAt"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dateTimeManager.formatTime(
                                              new Date(
                                                issue.row.startedAt * 1000
                                              )
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c("td", { staticClass: "row-label" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.issues.table.createdAt"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateTimeManager.formatTime(
                                                  new Date(
                                                    issue.row.createdAt * 1000
                                                  )
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c("td", { staticClass: "row-label" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.issues.table.updatedAt"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateTimeManager.formatTime(
                                                  new Date(
                                                    issue.row.updatedAt * 1000
                                                  )
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c("td", { staticClass: "row-label" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.issues.table.resolved"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dateTimeManager.formatTime(
                                              new Date(
                                                issue.row.resolvedAt * 1000
                                              )
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "info-table table-layout-equal table-borderless card-table",
                                  },
                                  [
                                    _c("tr", [
                                      _c("td", { staticClass: "row-label" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.issues.table.acknowledgedAt"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        issue.row.acknowledgedAt
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.dateTimeManager.formatTime(
                                                      new Date(
                                                        issue.row
                                                          .acknowledgedAt * 1000
                                                      )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [_vm._v(" - ")]),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c("td", { staticClass: "row-label" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.issues.table.acknowledgedBy"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        issue.row.acknowledgedBy
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    issue.row.acknowledgedBy
                                                      .fullName
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [_vm._v(" - ")]),
                                      ]),
                                    ]),
                                    issue.row.type ==
                                    _vm.NotificationSubscriptionType
                                      .DEVICE_OFFLINE
                                      ? [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "row-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "component.issues.table.name"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _vm.fromAdmin &&
                                                _vm.getDevice(
                                                  issue.row.metadata
                                                    .deviceInstanceId
                                                )
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "adminDeviceDetail",
                                                            params: {
                                                              deviceId:
                                                                _vm.getDevice(
                                                                  issue.row
                                                                    .metadata
                                                                    .deviceInstanceId
                                                                ).deviceId,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getDevice(
                                                              issue.row.metadata
                                                                .deviceInstanceId
                                                            ).deviceName
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.getDevice(
                                                      issue.row.metadata
                                                        .deviceInstanceId
                                                    )
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "deviceConf",
                                                            params: {
                                                              deviceId:
                                                                issue.row
                                                                  .metadata
                                                                  .deviceInstanceId,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getDevice(
                                                                issue.row
                                                                  .metadata
                                                                  .deviceInstanceId
                                                              ).deviceName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "component.issues.table.unknown"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                    issue.row.type ==
                                    _vm.NotificationSubscriptionType
                                      .SOURCE_OFFLINE
                                      ? [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "row-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "component.issues.table.name"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _vm.fromAdmin &&
                                                _vm.getAdminLogger(
                                                  issue.row.metadata.sourceId
                                                )
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "adminLoggerDetail",
                                                            params: {
                                                              loggerId:
                                                                _vm.getAdminLogger(
                                                                  issue.row
                                                                    .metadata
                                                                    .sourceId
                                                                ).loggerId,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getAdminLogger(
                                                              issue.row.metadata
                                                                .sourceId
                                                            ).source.name
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.getSource(
                                                      issue.row.metadata
                                                        .sourceId
                                                    )
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "logger_detail",
                                                            params: {
                                                              locationId:
                                                                issue.row
                                                                  .metadata
                                                                  .sourceId,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getSource(
                                                                issue.row
                                                                  .metadata
                                                                  .sourceId
                                                              ).name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "component.issues.table.unknown"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                    issue.row.type ==
                                    _vm.NotificationSubscriptionType.LOW_BATTERY
                                      ? [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "row-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "component.issues.table.name"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _vm.fromAdmin &&
                                                _vm.getAdminCompanyLogger(
                                                  issue.row.metadata
                                                    .companyLoggerId
                                                )
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "adminLoggerDetail",
                                                            params: {
                                                              loggerId:
                                                                _vm.getAdminCompanyLogger(
                                                                  issue.row
                                                                    .metadata
                                                                    .companyLoggerId
                                                                ).loggerId,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getAdminCompanyLogger(
                                                              issue.row.metadata
                                                                .companyLoggerId
                                                            ).source.name
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.getCompanyLogger(
                                                      issue.row.metadata
                                                        .companyLoggerId
                                                    )
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "logger_detail",
                                                            params: {
                                                              locationId:
                                                                _vm.getCompanyLogger(
                                                                  issue.row
                                                                    .metadata
                                                                    .companyLoggerId
                                                                ).id,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getCompanyLogger(
                                                                issue.row
                                                                  .metadata
                                                                  .companyLoggerId
                                                              ).name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "component.issues.table.unknown"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "row-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "component.issues.table.batteryLevel"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    issue.row.metadata
                                                      .batteryLevel
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      : _vm._e(),
                                    issue.row.type ==
                                    _vm.NotificationSubscriptionType
                                      .MEASURED_DATA_ALARM
                                      ? [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "row-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "component.issues.table.name"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _vm.fromAdmin &&
                                                _vm.getAdminLogger(
                                                  issue.row.metadata.sourceId
                                                )
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "adminLoggerDetail",
                                                            params: {
                                                              loggerId:
                                                                _vm.getAdminLogger(
                                                                  issue.row
                                                                    .metadata
                                                                    .sourceId
                                                                ).loggerId,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getAdminLogger(
                                                              issue.row.metadata
                                                                .sourceId
                                                            ).source.name
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.getSource(
                                                      issue.row.metadata
                                                        .sourceId
                                                    )
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "logger_detail",
                                                            params: {
                                                              locationId:
                                                                issue.row
                                                                  .metadata
                                                                  .sourceId,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getSource(
                                                                issue.row
                                                                  .metadata
                                                                  .sourceId
                                                              ).name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "component.issues.table.unknown"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                    issue.row.type ==
                                    _vm.NotificationSubscriptionType
                                      .GROUP_MEASURED_DATA_ALARM
                                      ? [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "row-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "component.issues.table.name"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              [
                                                !_vm.fromAdmin &&
                                                _vm.getSourceGroup(
                                                  issue.row.metadata
                                                    .sourceGroupId
                                                )
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "group_detail",
                                                            params: {
                                                              groupId:
                                                                issue.row
                                                                  .metadata
                                                                  .sourceGroupId,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getSourceGroup(
                                                                issue.row
                                                                  .metadata
                                                                  .sourceGroupId
                                                              ).name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "component.issues.table.unknown"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                    _c("tr", [
                                      _c("td", { staticClass: "row-label" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.issues.table.notifications"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm.getIssueNotificationsLength(
                                          issue.row
                                        )
                                          ? _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.redirectChecker(
                                                      issue.row,
                                                      1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getIssueNotificationsLength(
                                                        issue.row
                                                      ).notifications.length
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _c("span", [_vm._v("-")]),
                                      ]),
                                    ]),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "level-right" }, [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectChecker(issue.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("component.issues.moreDetails")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("section", { staticClass: "section" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.issues.table.empty")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "bottom-left",
                    fn: function () {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.notifications_list.table.row_per_page"
                              )
                            )
                          ),
                        ]),
                        _c(
                          "b-field",
                          { staticClass: "pl-2 mb-4-mobile" },
                          [
                            _c(
                              "b-select",
                              {
                                on: { input: _vm.rowsPerPageSelected },
                                model: {
                                  value: _vm.rowsPerPage,
                                  callback: function ($$v) {
                                    _vm.rowsPerPage = $$v
                                  },
                                  expression: "rowsPerPage",
                                },
                              },
                              _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                                return _c(
                                  "option",
                                  { key: option, domProps: { value: option } },
                                  [_vm._v(" " + _vm._s(option) + " ")]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  _vm.totalSize > 0
                    ? {
                        key: "footer",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "is-flex is-justify-content-flex-end",
                              },
                              [
                                _c("p", { staticClass: "pt-2 has-text-grey" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.company.list.table.results",
                                          {
                                            from: _vm.fromTableData,
                                            to: _vm.toTableData,
                                            total: _vm.totalSize,
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.type.field,
                  label: _vm.$t(
                    `component.issues.table.${_vm.tableColumns.type.field}`
                  ),
                  visible: _vm.tableColumns.type.visible,
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.redirectChecker(props.row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "notification_rule_types." +
                                        props.row.type
                                    )
                                  ) +
                                  " (" +
                                  _vm._s(_vm.getName(props.row)) +
                                  ") "
                              ),
                            ]
                          ),
                          props.row.type ==
                            _vm.NotificationSubscriptionType
                              .MEASURED_DATA_ALARM &&
                          _vm.MeasurementStatuses.ALARM.name ===
                            props.row.metadata.level
                            ? _c("b-tag", { attrs: { type: "is-danger" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `measurement_status.${props.row.metadata.level}`
                                    )
                                  ) + " "
                                ),
                              ])
                            : props.row.type ==
                                _vm.NotificationSubscriptionType
                                  .MEASURED_DATA_ALARM &&
                              _vm.MeasurementStatuses.WARN.name ===
                                props.row.metadata.level
                            ? _c("b-tag", { attrs: { type: "is-warning" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `measurement_status.${props.row.metadata.level}`
                                    )
                                  ) + " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  134935010
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.createdAt.field,
                  label: _vm.$t(
                    `component.issues.table.${_vm.tableColumns.createdAt.field}`
                  ),
                  visible: _vm.tableColumns.createdAt.visible,
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "span",
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.dateTimeManager.formatTime(
                                    new Date(props.row.createdAt * 1000),
                                    null,
                                    null,
                                    null
                                  )
                                ) + " "
                              ),
                              !props.row.resolvedAt
                                ? _c("b-tag", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getTimeDifferenceBySegment(
                                          new Date(props.row.createdAt * 1000)
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3938999257
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.resolvedAt.field,
                  label: _vm.$t(
                    `component.issues.table.${_vm.tableColumns.resolvedAt.field}`
                  ),
                  visible: _vm.tableColumns.resolvedAt.visible,
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.resolvedAt
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "b-tag",
                                    { attrs: { type: "is-success" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            `component.issues.table.status.resolved`
                                          )
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-tooltip",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.dateTimeManager.formatTime(
                                          new Date(props.row.resolvedAt * 1000)
                                        ),
                                        position: "is-left",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          size: "is-small",
                                          icon: "info-circle",
                                          type: "is-grey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c(
                                    "b-tag",
                                    { attrs: { type: "is-primary" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            `component.issues.table.status.unresolved`
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1283355175
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.acknowledgedBy.field,
                  label: _vm.$t(
                    `component.issues.table.${_vm.tableColumns.acknowledgedBy.field}`
                  ),
                  visible: _vm.tableColumns.acknowledgedBy.visible,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.acknowledgedBy
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(props.row.acknowledgedBy.fullName)
                                ),
                              ])
                            : _vm.$ability.can(
                                _vm.$permActions.READ,
                                _vm.$permSubjects.ISSUES
                              ) && _vm.acknowledgeable(props.row)
                            ? _c("span", [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.acknowledgeIssue(
                                          props.row.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "clipboard",
                                        type: "is-primary",
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.issues.table.acknowledge"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  784262795
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }