import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OrganizationItem from '@/views/components/organization/OrganizationItem.vue';
import OrganizationItemList from '@/views/components/organization/OrganizationItemList.vue';
import UserPreferences from '@/services/UserPreferences';
import LoggedUserManager from '@/services/LoggedUserManager';
import { getIssueTypes, IssueType } from '@/entities/enums/IssueType';
import debounce from 'lodash.debounce';
const DEBOUNCE_TIMER = 500;
let OrganizationCard = class OrganizationCard extends Vue {
    constructor() {
        super(...arguments);
        this.IssueType = IssueType;
        this.companyNameSearch = '';
        this.companyStatusSearch = '';
        this.isNameSearch = true;
        this.isStatusSearch = false;
        this.currentFilters = [];
        this.selectedValue = '';
        this.noAssignedDepartments = false;
        this.states = {
            OK: { id: 0, name: 'fine', class: 'is-success' },
            ACKNOWLEDGED: { id: 0, name: 'acknowledged', class: 'is-success' },
            MAINTENANCE: { id: 1, name: IssueType.MAINTENANCE, class: 'is-info' },
            OFF_SOURCE: { id: 2, name: IssueType.SOURCE_OFFLINE, class: 'is-grey' },
            OFF_DEVICE: { id: 2, name: IssueType.DEVICE_OFFLINE, class: 'is-grey' },
            LOW: { id: 3, name: IssueType.LOW_BATTERY, class: 'is-warning' },
            WARN: { id: 4, name: IssueType.ALARM, alarmLevel: 'Warning', class: 'is-warning' },
            ALARM: { id: 5, name: IssueType.ALARM, alarmLevel: 'Alarm', class: 'is-danger' }
        };
        this.cardViewActive = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.cardViewActive);
        this.tableColumns = {
            companyName: {
                field: 'companyName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyName),
                hasSearch: true
            },
            companyStatus: {
                field: 'companyStatus',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyListCompanyName),
                hasSearch: true
            }
        };
        this.handleFilterCompanyNameDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.companyName.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterCompanyStatusDebounce = debounce((e) => {
            this.filterDepartments(this.tableColumns.companyStatus.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    onLoading() {
        this.noAssignedDepartments = this.currentFilters.length == 0 && this.departments.getData().length == 0;
    }
    get issueTypes() {
        return getIssueTypes();
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    get hasAlarm() {
        return this.getIssueCountByType(IssueType.ALARM, 'Alarm') > 0;
    }
    get hasWarning() {
        return this.getIssueCountByType(IssueType.ALARM, 'Warning') > 0;
    }
    get hasLowBattery() {
        return this.getIssueCountByType(IssueType.LOW_BATTERY) > 0;
    }
    get hasOffline() {
        return (this.getIssueCountByType(IssueType.SOURCE_OFFLINE) > 0 || this.getIssueCountByType(IssueType.DEVICE_OFFLINE) > 0);
    }
    get hasMaintenance() {
        return this.getIssueCountByType(IssueType.MAINTENANCE) > 0;
    }
    get acknowledgedStates() {
        let acknowledgedCount = 0;
        this.departments.getData().forEach((dep) => {
            const allAcknowledged = dep.issuesSummary.every((issue) => issue.notAcknowledged === 0);
            if (allAcknowledged) {
                acknowledgedCount++;
            }
        });
        return acknowledgedCount;
    }
    get fineStates() {
        let fineDepartmentsCount = 0;
        this.departments.getData().forEach((dep) => {
            if (!dep.issuesSummary) {
                fineDepartmentsCount++;
            }
            else {
                const allAcknowledged = dep.issuesSummary.every((issue) => issue.notAcknowledged === 0);
                if (allAcknowledged) {
                    fineDepartmentsCount++;
                }
            }
        });
        return fineDepartmentsCount;
    }
    getIssueCountByType(type, alarmLevel = null) {
        let departmentsWithNotAcknowledgedIssues = 0;
        this.departments.getData().forEach((dep) => {
            let highestPriorityStatus = this.states.OK;
            dep.issuesSummary.forEach((issue) => {
                let currentState = Object.values(this.states).find((state) => (Array.isArray(state.name) ? state.name.includes(issue.type) : state.name === issue.type) &&
                    (!state.alarmLevel || state.alarmLevel === issue.alarmLevel));
                if (currentState && currentState.id > highestPriorityStatus.id && issue.notAcknowledged > 0)
                    highestPriorityStatus = currentState;
            });
            let matchesPriority = (Array.isArray(highestPriorityStatus.name)
                ? highestPriorityStatus.name.includes(type)
                : highestPriorityStatus.name === type) &&
                (!alarmLevel || highestPriorityStatus.alarmLevel === alarmLevel);
            if (matchesPriority) {
                let hasNotAcknowledgedIssues = dep.issuesSummary.some((issue) => issue.type === type && (alarmLevel === null || issue.alarmLevel === alarmLevel) && issue.notAcknowledged > 0);
                if (hasNotAcknowledgedIssues)
                    departmentsWithNotAcknowledgedIssues++;
            }
        });
        return departmentsWithNotAcknowledgedIssues;
    }
    created() {
        if (this.isLoggedFromAdmin) {
            this.selectedValue = this.tableColumns.companyName.field;
            this.fillFilterFromRoute();
            this.fillSearches();
            this.onOptionsChange();
        }
    }
    fillSearches() {
        if (this.currentFilters.length !== 0) {
            this.companyNameSearch = this.companyNameSearch =
                this.filterExists('CompanyName@=') !== -1
                    ? this.currentFilters[this.filterExists('CompanyName@=')].substring(13)
                    : '';
            this.companyStatusSearch =
                this.filterExists('UnresolvedIssues>0|') !== -1
                    ? this.currentFilters[this.filterExists('UnresolvedIssues>0|')].substring(19)
                    : '';
        }
    }
    fillFilterFromRoute() {
        try {
            let tableQueries = JSON.parse(this.$router.currentRoute.query.adminCompany);
            this.currentFilters = JSON.parse(tableQueries.filters);
        }
        catch (error) {
            this.currentFilters = [];
        }
    }
    onOptionsChange() {
        this.changeRouterUrl(this.currentFilters);
        this.$emit('onOptionsChange', this.currentFilters);
    }
    changeRouterUrl(currentFilters) {
        let tableQueries = {
            filters: JSON.stringify(currentFilters.filter((x) => x != null))
        };
        let objStringAdminCompany = JSON.stringify(tableQueries);
        this.$emit('changeRouterUrlFromCompany', objStringAdminCompany);
    }
    filterExists(filter) {
        return this.currentFilters.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        value = value.trim();
        if (index !== -1) {
            if (!value || value == null || value == '') {
                this.currentFilters.splice(index, 1);
            }
            else {
                this.currentFilters[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilters.push(filter + value);
    }
    filterDepartments(searchField, value) {
        switch (searchField) {
            case this.tableColumns.companyName.field:
                this.replaceFilter('CompanyName@=', value);
                break;
            case this.tableColumns.companyStatus.field:
                this.replaceFilter('UnresolvedIssues>0|', value);
                break;
            default:
                break;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.onOptionsChange();
            }
        });
    }
    clearFilter() {
        this.companyNameSearch = '';
        this.companyStatusSearch = '';
        this.currentFilters = [];
        this.onOptionsChange();
    }
    changeSearchField(event) {
        this.isNameSearch = false;
        this.isStatusSearch = false;
        switch (event) {
            case this.tableColumns.companyName.field:
                this.isNameSearch = true;
                break;
            case this.tableColumns.companyStatus.field:
                this.isStatusSearch = true;
                break;
            default:
                break;
        }
        this.clearFilter();
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.companyName.field;
        this.changeSearchField(this.tableColumns.companyName.field);
    }
    reload() {
        this.$emit('reload');
    }
    favoriteSelected(id) {
        this.$emit('favoriteSelected', id);
    }
    cardView() {
        this.cardViewActive = true;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.cardViewActive, this.cardViewActive);
    }
    tableView() {
        this.cardViewActive = false;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.cardViewActive, this.cardViewActive);
    }
};
__decorate([
    Prop({ type: Object })
], OrganizationCard.prototype, "departments", void 0);
__decorate([
    Prop({ type: String, default: null })
], OrganizationCard.prototype, "favoriteDepartmentId", void 0);
__decorate([
    Watch('departments')
], OrganizationCard.prototype, "onLoading", null);
OrganizationCard = __decorate([
    Component({
        components: {
            OrganizationItem,
            OrganizationItemList
        }
    })
], OrganizationCard);
export default OrganizationCard;
