var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "multi-header mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
          },
          [
            _c("h2", { staticClass: "level-item subtitle is-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.firmwareList.detail.firmwareInfo.title"
                    )
                  ) +
                  " "
              ),
            ]),
            _vm.$ability.can(
              _vm.$permActions.UPDATE,
              _vm.$permSubjects.ADMIN_GATEWAYS_FIRMWARES
            )
              ? _c(
                  "div",
                  [
                    !_vm.isEditing
                      ? _c("b-button", {
                          attrs: {
                            "icon-right": "pencil-alt",
                            type: "is-white",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editFw()
                            },
                          },
                        })
                      : _c(
                          "b-button",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              "icon-left": "times-circle",
                              type: "is-white",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editFw()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("generals.cancel_edit")) + " ")]
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        !_vm.isEditing
          ? _c(
              "div",
              { staticClass: "level-right buttons is-flex-mobile" },
              [
                _vm.$ability.can(
                  _vm.$permActions.DELETE,
                  _vm.$permSubjects.ADMIN_GATEWAYS_FIRMWARES
                )
                  ? _c(
                      "b-button",
                      {
                        staticClass: "level-item",
                        attrs: { type: "is-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteFirmware()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "admin.component.devices.firmwareList.detail.firmwareInfo.actions.delete"
                            )
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.SCHEDULE,
                  _vm.$permSubjects.ADMIN_GATEWAYS_UPDATES
                )
                  ? _c(
                      "b-button",
                      {
                        staticClass: "level-item",
                        attrs: { type: "is-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.openScheduleFirmware()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "admin.component.devices.firmwareList.detail.firmwareInfo.actions.schedule"
                            )
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "columns info-fields" }, [
        _c("div", { staticClass: "column" }, [
          _c("table", { staticClass: "info-table table-layout-equal" }, [
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.firmwareList.detail.firmwareInfo.id"
                      )
                    )
                  ),
                ]),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.firmware.id))]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.firmwareList.detail.firmwareInfo.fileName"
                      )
                    )
                  ),
                ]),
              ]),
              _vm.firmware.assets.find((x) => x)
                ? _c("td", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.firmware.assets.map((x) => x.name).join(", ")
                        )
                      ),
                    ]),
                  ])
                : _c("td", [_vm._v("-")]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.firmwareList.detail.firmwareInfo.file"
                      )
                    )
                  ),
                ]),
              ]),
              _vm.firmware.assets.find((x) => x)
                ? _c("td", [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.firmware.assets
                              .map((x) => x.fileName)
                              .join(", ")
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _c("td", [_vm._v("-")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "column" }, [
          _c("table", { staticClass: "info-table table-layout-equal" }, [
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.firmwareList.detail.firmwareInfo.version"
                      )
                    )
                  ),
                ]),
              ]),
              _c("td", [_vm._v(" " + _vm._s(_vm.firmware.version) + " ")]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.firmwareList.detail.firmwareInfo.createdBy"
                      )
                    )
                  ),
                ]),
              ]),
              _c(
                "td",
                [
                  _vm.firmware.createdBy.email !== null
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminUserDetail",
                              params: {
                                userId: _vm.firmware.createdBy.apiUserId,
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm.firmware.createdBy.fullName)),
                          ]),
                        ]
                      )
                    : _c("span", [
                        _vm._v(_vm._s(_vm.firmware.createdBy.fullName)),
                      ]),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.firmwareList.detail.firmwareInfo.createdAt"
                      )
                    )
                  ),
                ]),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(_vm.firmware.createdAt * 1000),
                        null,
                        null,
                        null
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "columns info-fields" }, [
        _c(
          "div",
          { staticClass: "column is-half-widescreen" },
          [
            _c("div", [
              _c("h2", { staticClass: "subtitle mb-4 is-5" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.firmwareList.detail.firmwareInfo.keyValuePairs"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "table",
              { staticClass: "info-table table-layout-equal" },
              [
                Object.keys(_vm.firmware.data).length > 0 && !_vm.isEditing
                  ? _vm._l(_vm.firmware.data, function (value, key) {
                      return _c("tr", { key: key }, [
                        _c("td", { staticClass: "row-label" }, [
                          _c("strong", [_vm._v(_vm._s(key))]),
                        ]),
                        _c("td", [_vm._v(_vm._s(value))]),
                      ])
                    })
                  : !_vm.isEditing
                  ? [_vm._m(0)]
                  : _vm._e(),
              ],
              2
            ),
            _vm.isEditing
              ? [
                  _c(
                    "b-field",
                    [
                      _c(
                        "b-table",
                        {
                          staticClass: "invite-user-table",
                          attrs: { data: _vm.fwKeyValueArray },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "empty",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "content has-text-grey has-text-centered",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.devices.firmwareList.detail.firmwareInfo.empty"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1281513708
                          ),
                        },
                        [
                          _c("b-table-column", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (fwKeyValueArray) {
                                    return [
                                      _c(
                                        "b-field",
                                        {
                                          staticClass:
                                            "is-flex-grow-2 mr-1 mt-2",
                                          attrs: {
                                            label: _vm.$t(
                                              "admin.component.devices.firmwareList.detail.firmwareInfo.properties.key"
                                            ),
                                            type: {
                                              "is-danger": _vm.errors.has(
                                                _vm.$t(
                                                  "admin.component.devices.firmwareList.detail.firmwareInfo.properties.key"
                                                ) + fwKeyValueArray.row[0]
                                              ),
                                            },
                                            message: _vm.errorMessageKey(
                                              fwKeyValueArray.row[0]
                                            ),
                                            "label-position": "on-border",
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              placeholder: _vm.$t(
                                                "admin.component.devices.firmwareList.detail.firmwareInfo.properties.key"
                                              ),
                                              expanded: "",
                                              name:
                                                _vm.$t(
                                                  "admin.component.devices.firmwareList.detail.firmwareInfo.properties.key"
                                                ) + fwKeyValueArray.row[0],
                                            },
                                            model: {
                                              value: fwKeyValueArray.row[0],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  fwKeyValueArray.row,
                                                  0,
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "fwKeyValueArray.row[0]",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3354877836
                            ),
                          }),
                          _c("b-table-column", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (fwKeyValueArray) {
                                    return [
                                      _c(
                                        "b-field",
                                        {
                                          staticClass: "is-flex-grow-3 mt-2",
                                          attrs: {
                                            label: _vm.$t(
                                              "admin.component.devices.firmwareList.detail.firmwareInfo.properties.value"
                                            ),
                                            type: {
                                              "is-danger": _vm.errors.has(
                                                _vm.$t(
                                                  "admin.component.devices.firmwareList.detail.firmwareInfo.properties.value"
                                                ) + fwKeyValueArray.row[1]
                                              ),
                                            },
                                            message: _vm.errorMessageValue(
                                              fwKeyValueArray.row[1]
                                            ),
                                            "label-position": "on-border",
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              placeholder: _vm.$t(
                                                "admin.component.devices.firmwareList.detail.firmwareInfo.properties.value"
                                              ),
                                              expanded: "",
                                              name:
                                                _vm.$t(
                                                  "admin.component.devices.firmwareList.detail.firmwareInfo.properties.value"
                                                ) + fwKeyValueArray.row[1],
                                            },
                                            model: {
                                              value: fwKeyValueArray.row[1],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  fwKeyValueArray.row,
                                                  1,
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "fwKeyValueArray.row[1]",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2654640082
                            ),
                          }),
                          _c("b-table-column", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("button", {
                                        staticClass: "delete is-vcentered mt-2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeKeyValue(
                                              _vm.fwKeyValueArray[row.index]
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3536455984
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "has-text-centered" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-primary", outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.addKeyValue()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "admin.component.devices.firmwareList.detail.firmwareInfo.addBtn"
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm.isEditing
        ? _c(
            "div",
            { staticClass: "has-text-right" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary", disabled: !_vm.hasChanges },
                  on: {
                    click: function ($event) {
                      return _vm.saveChanges()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("generals.save_changes")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isScheduleUpdateModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeScheduleUpdateModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isScheduleUpdateModalActive = $event
            },
            "close-modal": _vm.closeScheduleUpdateModal,
          },
        },
        [
          _c("ScheduleUpdateModal", {
            attrs: { firmware: _vm.firmware, deviceList: _vm.deviceList },
            on: {
              reload: _vm.reload,
              modalClosed: _vm.closeScheduleUpdateModal,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [_c("td", [_vm._v("-")]), _c("td", [_vm._v("-")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }