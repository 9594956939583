var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("component.device.register.modal.title")) + " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c("DeviceRegistrationComponent", {
          ref: "component",
          attrs: { isModal: true, deviceIsActiveFlag: _vm.deviceIsActiveFlag },
          on: {
            result: function ($event) {
              return _vm.resultRetrieved($event)
            },
            deviceIsActive: _vm.deviceIsActive,
          },
        }),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _vm.deviceIsActiveFlag
          ? _c(
              "b-button",
              {
                attrs: {
                  type: "is-primary",
                  disabled:
                    _vm.$refs.component && _vm.$refs.component.isLoading,
                },
                on: {
                  click: function ($event) {
                    return _vm.$refs.component.validateBeforeSubmit()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("component.device.register.modal.register"))
                ),
              ]
            )
          : _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    _vm.deviceIsActiveFlag = true
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.device.register.modal.device_not_active.btn"
                    )
                  )
                ),
              ]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }