var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.settings.device.device_settings")) +
              " "
          ),
        ]
      ),
      _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
        _vm.$ability.can(
          _vm.$permActions.MANAGEOWN,
          _vm.$permSubjects.COMPANY
        ) && _vm.dateTimeManager
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _vm.departmentSettings
                  ? _c("DevicesSettings", {
                      staticClass: "box tile is-child",
                      attrs: { departmentSettings: _vm.departmentSettings },
                      on: {
                        openDeviceSirenModal: _vm.openDeviceSirenModal,
                        deleteSirenSequence: _vm.deleteSirenSequence,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.DEVICE) &&
        _vm.dateTimeManager
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("DeviceList", {
                  ref: "deviceList",
                  staticClass: "box tile is-child",
                  attrs: {
                    dateTimeManager: _vm.dateTimeManager,
                    pageSize: _vm.PAGE_SIZE,
                    deviceList: _vm.deviceList,
                    allDevicesCount: _vm.allDevicesCount,
                  },
                  on: { pageChanged: _vm.pageChanged },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isDeviceSirenModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeDeviceSirenModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isDeviceSirenModalActive = $event
            },
            "close-modal": _vm.closeDeviceSirenModal,
          },
        },
        [
          _c("DeviceSirenModal", {
            attrs: { departmentSettings: _vm.departmentSettings },
            on: {
              modalClosed: _vm.closeDeviceSirenModal,
              submitSirenInterval: _vm.submitSirenInterval,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }