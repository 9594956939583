import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AdminCompanyList from '@/views/components/admin/company/AdminCompanyList.vue';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import { AdminDepartment } from '@/entities/models/AdminDepartment';
import EventDomain from '@/entities/enums/eventDomains';
import LoggedUserManager from '@/services/LoggedUserManager';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import VueUtilities from '@/services/VueUtilities';
import { vxm } from '@/store/store.vuex';
import CreateCompanyModal from '@/views/components/admin/company/CreateCompanyModal.vue';
import UserRepository from '@/services/repository/UserRepository';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
import ApiResponse from '@/entities/ApiResponse';
var departmentRepository;
var eventTypeRepository;
var vxDepartmentStore = vxm.departmentStore;
var userRepository;
let AdminCompanyManagement = class AdminCompanyManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.isModalActive = false;
        this.departmentsList = new ApiResponse();
        this.dateTimeManager = null;
        this.userList = new Array();
        this.selectedCompanyToLogin = null;
        this.tableAdminCompanyQueries = 'adminCompany';
        this.objStringAdminCompany = null;
        this.totalSize = 0;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin();
        this.currentFilters = [];
        this.departmentTags = [];
    }
    async created() {
        departmentRepository = new DepartmentRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        localStorage.removeItem(LocalStorageKeys.ADMIN_PREV_ROUTE);
        userRepository = new UserRepository(this);
        this.loadTags();
    }
    async loadTags() {
        this.departmentTags = await departmentRepository.getAllDepartmentTagsAdmin();
        // add option to remove tag
        this.departmentTags.push({
            CompanyTagId: -1,
            Tag: 'bez označenia'
        });
    }
    createCompany() {
        this.isModalActive = true;
    }
    closeModal() {
        this.isModalActive = false;
    }
    async loadCompanies(options, currentFilters) {
        if (options)
            this.callOptions = options;
        if (currentFilters)
            this.currentFilters = currentFilters;
        this.isLoading = true;
        this.departmentsList = await departmentRepository.getAllDepartmentsAdmin(options ? options.pagination : this.callOptions.pagination, options ? options.sort : this.callOptions.sort, currentFilters ? currentFilters : this.currentFilters);
        this.isLoading = false;
    }
    async loadUsers(pagination, currentFilter) {
        let res = await userRepository.getUsersAdmin(null, pagination, null, currentFilter);
        this.userList = res.getData();
        this.totalSize = res.getPagination().total;
    }
    async saveTagChanges(data, depId) {
        this.isLoading = true;
        let resp = await departmentRepository.adminUpdateDepartment(depId, data);
        if (!(resp instanceof AdminDepartment)) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.detail.companyInfo.error'));
        }
        this.loadCompanies();
        this.isLoading = false;
    }
    async login(company, name, params) {
        this.selectedCompanyToLogin = company;
        LoggedUserManager.loginToCompany(this, this.selectedCompanyToLogin, name, params);
        this.loginSucessfulAsync();
    }
    async loginSucessfulAsync() {
        await this.$featuresManager.loadTier();
        VueUtilities.openSuccessToast(this, this.$t('component.login.successful').toString());
        eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true, true, this.selectedCompanyToLogin.companyId);
        this.$router
            .push({
            name: 'dashboard',
            params: {
                lang: this.$route.params.lang,
                departmentId: this.selectedCompanyToLogin.companyId
            }
        })
            .catch((err) => err);
    }
    changeRouterUrlFromCompany(objStringAdminCompany) {
        this.objStringAdminCompany = objStringAdminCompany;
        this.changeRouterUrl(this.objStringAdminCompany);
    }
    changeRouterUrl(objStringAdminCompany) {
        this.$router
            .push({
            name: this.$route.name,
            params: this.$route.params,
            query: {
                [this.tableAdminCompanyQueries]: objStringAdminCompany
            }
        })
            .catch((err) => err);
    }
    setDepartments(value) {
        vxDepartmentStore.departments = value;
    }
};
AdminCompanyManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_USERS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {
            AdminCompanyList,
            CreateCompanyModal
        }
    })
], AdminCompanyManagement);
export default AdminCompanyManagement;
