import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import UserRepository from '@/services/repository/UserRepository';
import AppConfig from '@/configLoader';
var companyLoggerRepository;
var userRepository;
var pdf = require('@/assets/other/loghub_sample_calibration_protocol.pdf');
let LoggerExpirationList = class LoggerExpirationList extends Vue {
    constructor() {
        super(...arguments);
        this.demo = AppConfig.getConfig().demo.name;
        this.isLoading = false;
        this.pdf = pdf;
        this.currentUser = null;
        this.currentUserName = null;
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        userRepository = new UserRepository(this);
        this.loadUser();
    }
    async loadUser() {
        this.currentUser = await userRepository.getCurrentUser();
        this.currentUserName = this.currentUser.username;
    }
    get isDemo() {
        return this.currentUserName == this.demo;
    }
    get companyLoggerExpirationList() {
        let currentTime = new Date();
        return this.sources.filter((x) => x.logger &&
            x.logger.active == true &&
            (x.logger.calibration.status.name == 'not_calibrated' ||
                x.logger.calibration.status.name == 'expired' ||
                (x.logger.calibration.status.name == 'calibrated' &&
                    currentTime.getSeconds() - x.logger.calibration.expiresAt < 7890000)));
    }
    calibrationIcon(status) {
        let icon = '';
        switch (status) {
            case 'not_calibrated':
                icon = 'fa-solid fa-times';
                break;
            case 'expired':
                icon = 'fa-solid fa-exclamation';
                break;
            case 'calibrated':
                icon = 'fa-solid fa-check';
                break;
        }
        return icon;
    }
    hasCalibrationProtocolToDownload(companyLogger) {
        if (companyLogger.calibration == null || companyLogger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.downloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    downloadSample() {
        this.$emit('downloadSample', pdf);
    }
};
__decorate([
    Prop({ type: Array })
], LoggerExpirationList.prototype, "sources", void 0);
__decorate([
    Prop({ type: Object })
], LoggerExpirationList.prototype, "dateTimeManager", void 0);
LoggerExpirationList = __decorate([
    Component({})
], LoggerExpirationList);
export default LoggerExpirationList;
