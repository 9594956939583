import { __decorate } from "tslib";
import Languages from '@/entities/enums/languages';
import LoggedUserManager from '@/services/LoggedUserManager';
import VueUtilities from '@/services/VueUtilities';
import SessionStorage from '@/services/repository/SessionStorage';
import { Component, Vue, Watch } from 'vue-property-decorator';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import DateTimeManager from '@/services/DateTimeManager';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import NotificationItem from '@/views/components/notification/NotificationItem.vue';
import NotificationRepository from '@/services/repository/NotificationRepository';
import CookiesStorage from '@/services/CookiesStorage';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import { vxm } from '@/store/store.vuex';
import UserPreferences from '@/services/UserPreferences';
var userSettingsRepository;
var authenticationCalls;
var notificationRepository;
var vxDepartmentStore = vxm.departmentStore;
var vxNotificationStore = vxm.notificationStore;
const BURGER_BTN_ID = 'navbar-burger';
const MORE_BTN_ID = 'ignoreClickOutside';
let AdminHeader = class AdminHeader extends Vue {
    constructor() {
        super(...arguments);
        this.MORE_BTN_ID = MORE_BTN_ID;
        this.showNavMenu = false;
        this.showUserMenu = false;
        this.showNotificationMenu = false;
        this.BURGER_BTN_ID = BURGER_BTN_ID;
        this.isSideMenuVisible = false;
        this.selectedTheme = '';
        this.selectedDarkMode = false;
        this.Languages = Languages;
        this.DateFormats = [];
        this.TimeZones = [];
        this.ClockFormats = [];
        this.selectedDateFormat = '';
        this.selectedTimezone = '';
        this.selectedClockFormat = '';
        this.actualTimeFormatted = '';
        this.actualTime = new Date();
        this.shownNotificationCount = 5;
        this.notifications = [];
        this.notificationsCount = 0;
        this.selectedShowTzInfo = false;
        this.usedShowTzInfo = false;
        this.sessionPassword = CookiesStorage.loadData(this, CookiesStorage.keys.SESSION_PASSWORD);
        this.username = CookiesStorage.loadData(this, CookiesStorage.keys.USERNAME);
    }
    onRouteChanges() {
        this.loadData();
    }
    async created() {
        this.$root.$off('reloadNotifications');
        this.$root.$on('reloadNotifications', async () => {
            vxNotificationStore.clearData();
            await this.loadNotifications(false);
            this.loadNotificationsConfig();
        });
        this.selectedTheme = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.Theme);
        this.setTheme(this.selectedTheme);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.selectedDateFormat = this.dateTimeManager.getSelectedDateFormat();
        this.usedDateFormat = this.dateTimeManager.getSelectedDateFormat();
        this.selectedClockFormat = this.dateTimeManager.getSelectedClockFormat();
        this.usedClockFormat = this.dateTimeManager.getSelectedClockFormat();
        this.selectedShowTzInfo = this.dateTimeManager.getTzInClockFormatPreference();
        this.usedShowTzInfo = this.dateTimeManager.getTzInClockFormatPreference();
        this.selectedTimezone = this.dateTimeManager.getSelectedTimezone();
        this.usedTimeZone = this.dateTimeManager.getSelectedTimezone();
        this.showSelectedTimeFormat();
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        authenticationCalls = new AuthenticationCalls(this);
        notificationRepository = new NotificationRepository(this);
        this.settings = await userSettingsRepository.loadUserSettings();
        this.$i18n.locale = this.settings.language;
        this.$validator.localize(this.settings.language);
        this.DateFormats = this.dateTimeManager.getAllDateFormats();
        this.TimeZones = this.dateTimeManager.getLimitedTimezones();
        this.ClockFormats = this.dateTimeManager.getAllClockFormats();
        this.$eventBus.$on('closeBurgerMenu', (value) => {
            this.isSideMenuVisible = value;
        });
        this.handleOutsideClick();
        this.loadData();
        this.loadCssLang();
    }
    async loadNotifications(loadMore) {
        if (!notificationRepository)
            return;
        if (loadMore) {
            this.shownNotificationCount = this.shownNotificationCount + 3;
        }
        let pagination = {
            page: 1,
            pageSize: this.shownNotificationCount
        };
        await notificationRepository.loadNotificationsToStore(pagination);
        this.loadNotificationsConfig();
    }
    async notificationRemoved(notifIds) {
        await notificationRepository.markNotificationsAsRead(notifIds);
        this.$root.$emit('reloadNotifications');
    }
    loadNotificationsConfig() {
        this.notifications = vxNotificationStore.notifications;
        this.notificationsCount = vxNotificationStore.totalSize;
    }
    async dateFormatSelected(val) {
        this.showSelectedTimeFormat();
        await this.dateTimeManager.setSelectedDateFormat(this.selectedDateFormat);
    }
    async clockFormatSelected(val) {
        this.showSelectedTimeFormat();
        await this.dateTimeManager.setSelectedClockFormat(this.selectedClockFormat);
    }
    async timezoneSelected(val) {
        this.showSelectedTimeFormat();
        await this.dateTimeManager.setSelectedTimezone(this.selectedTimezone);
    }
    async showTzChanged(val) {
        this.showSelectedTimeFormat();
        await this.dateTimeManager.setTzInClockFormatPreference(this.selectedShowTzInfo.toString());
    }
    showSelectedTimeFormat() {
        this.actualTimeFormatted = this.dateTimeManager.formatTime(new Date(), this.selectedTimezone, this.selectedDateFormat, this.selectedClockFormat, this.selectedShowTzInfo);
    }
    setTheme(value) {
        this.selectedTheme = value;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.Theme, value);
        if (value === 'dark') {
            this.selectedDarkMode = true;
            document.documentElement.setAttribute('data-theme', 'dark');
            document.body.classList.add('dark-mode');
        }
        else {
            this.selectedDarkMode = false;
            document.documentElement.setAttribute('data-theme', 'light');
            document.body.classList.remove('dark-mode');
        }
    }
    closeNavMenu(event) {
        this.showNavMenu = false;
    }
    closeUserMenu(event) {
        this.showUserMenu = false;
        if (this.needsReload()) {
            location.reload();
        }
    }
    mobileExpand() {
        this.isSideMenuVisible = !this.isSideMenuVisible;
        this.$eventBus.$emit('isSideMenuVisible', this.isSideMenuVisible);
    }
    closeNotificationMenu(event) {
        if (event.srcElement.id != MORE_BTN_ID)
            this.showNotificationMenu = false;
    }
    async changeLanguage(language) {
        this.settings = await userSettingsRepository.saveUserSetting([UserSettingsKeys.LANGUAGE], language);
        this.$i18n.locale = language;
        this.$validator.localize(language);
        this.loadCssLang();
    }
    isCurrentLanguage(lang) {
        if (!this.settings)
            return false;
        else if (this.settings.language == lang)
            return true;
        return false;
    }
    needsReload() {
        if (this.selectedDateFormat != this.usedDateFormat ||
            this.selectedTimezone != this.usedTimeZone ||
            this.selectedClockFormat != this.usedClockFormat ||
            this.selectedShowTzInfo != this.usedShowTzInfo) {
            return true;
        }
        else {
            return false;
        }
    }
    async logoutAsync() {
        let success = await authenticationCalls.logout();
        if (success) {
            VueUtilities.openSuccessToast(this, this.$t('component.logout.successful').toString());
            LoggedUserManager.deleteLoginData(this);
            LoggedUserManager.deleteAdminLoginData(this);
            SessionStorage.clearStorageForSession();
            vxDepartmentStore.clearData();
            vxNotificationStore.clearData();
            this.$router.push({ name: 'login', params: { lang: this.$route.params.lang } }).catch((err) => err);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logout.failed').toString());
        }
    }
    loadData() {
        this.loadNotifications(false);
    }
    handleOutsideClick() {
        document.addEventListener('click', (event) => {
            if (event.target.id != 'side-menu' && event.target.id != BURGER_BTN_ID) {
                if (this.isSideMenuVisible) {
                    this.isSideMenuVisible = false;
                    this.$eventBus.$emit('isSideMenuVisible', this.isSideMenuVisible);
                }
            }
        });
    }
    loadCssLang() {
        // change css language dynamic content
        document.styleSheets[0].deleteRule(0);
        document.styleSheets[0].insertRule('.mobile-filter .table-mobile-sort:before { content:  "' + this.$t('sort.sort_order') + '"  }', 0);
    }
};
__decorate([
    Watch('$route')
], AdminHeader.prototype, "onRouteChanges", null);
AdminHeader = __decorate([
    Component({ components: { NotificationItem } })
], AdminHeader);
export default AdminHeader;
