import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import FeedbackCategories from '@/entities/enums/FeedbackCategories';
import FeedbackStatus from '@/entities/enums/FeedbackStatus';
import UserPreferences from '@/services/UserPreferences';
import SortDirections from '@/entities/enums/SortDirections';
import debounce from 'lodash.debounce';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
const DEBOUNCE_TIMER = 500;
let FeedbackList = class FeedbackList extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.FeedbackCategories = FeedbackCategories;
        this.FeedbackStatus = FeedbackStatus;
        this.selectedValue = '';
        this.isIdSearch = true;
        this.isSubjectSearch = false;
        this.isCategorySearch = false;
        this.isStatusSearch = false;
        this.idSearch = '';
        this.subjectSearch = '';
        this.categorySearch = null;
        this.statusSearch = null;
        this.sortDirection = '';
        this.sortField = '';
        this.page = null;
        this.pageSize = null;
        this.jumpToPageValue = null;
        this.tableQueries = null;
        this.tableQueriesfromUrl = null;
        this.currentFilter = [];
        this.tableColumns = {
            id: {
                field: 'Id',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FeedbackListId),
                hasSearch: true
            },
            subject: {
                field: 'Subject',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FeedbackListSubject),
                hasSearch: true
            },
            category: {
                field: 'Category',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FeedbackListCategory),
                hasSearch: true
            },
            createdAt: {
                field: 'CreatedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FeedbackListCreatedAt),
                hasSearch: false
            },
            status: {
                field: 'Status',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FeedbackListStatus),
                hasSearch: true
            },
            sentById: {
                field: 'SentById',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FeedbackListSentById),
                hasSearch: false
            }
        };
        this.handleFilterIdDebounce = debounce((e) => {
            this.filterFeedback(this.tableColumns.id.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
        this.handleFilterSubjectDebounce = debounce((e) => {
            this.filterFeedback(this.tableColumns.subject.field, e);
        }, DEBOUNCE_TIMER, { maxWait: 1000 });
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.totalSize ? this.totalSize : this.page * this.pageSize;
    }
    created() {
        this.selectedValue = this.tableColumns.id.field;
        this.fillFilterFromRoute();
        this.fillSearches();
        this.sort = {
            field: this.sortField,
            direction: this.sortDirection == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadFeedback', this.sort, this.currentFilter);
    }
    fillFilterFromRoute() {
        try {
            this.tableQueriesfromUrl = JSON.parse(this.$router.currentRoute.query.adminFeedback);
            this.sortField = this.tableQueriesfromUrl.col;
            this.sortDirection = this.tableQueriesfromUrl.dir;
            this.page = this.tableQueriesfromUrl.page;
            this.jumpToPageValue = this.page;
            this.pageSize = this.tableQueriesfromUrl.count;
            this.currentFilter = JSON.parse(this.tableQueriesfromUrl.Filter);
            // send queries to parent component
            let objStringAdminFeedback = JSON.stringify(this.tableQueriesfromUrl);
            this.$emit('changeRouterUrlFromFeedback', objStringAdminFeedback);
            if (!this.sortField || !this.sortDirection || !this.page || !this.pageSize) {
                this.fillFilterFromPref();
            }
        }
        catch (error) {
            this.fillFilterFromPref();
        }
    }
    fillFilterFromPref() {
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminFeedbackSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminFeedbackSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminFeedbackListPage.defaultValue;
        this.jumpToPageValue = this.page;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminFeedbackRowsPerPage.defaultValue;
    }
    fillSearches() {
        if (this.currentFilter.length !== 0) {
            this.idSearch =
                this.filterExists('Id==') !== -1 ? this.currentFilter[this.filterExists('Id==')].substring(4) : '';
            this.subjectSearch =
                this.filterExists('Subject@=') !== -1 ? this.currentFilter[this.filterExists('Subject@=')].substring(9) : '';
            this.categorySearch =
                this.filterExists('Category==') !== -1
                    ? this.currentFilter[this.filterExists('Category==')].substring(10)
                    : null;
            this.statusSearch =
                this.filterExists('Status==') !== -1 ? this.currentFilter[this.filterExists('Status==')].substring(8) : null;
        }
    }
    filterFeedback(searchField, value) {
        value = value.includes(',') ? value.replace(/,/g, '\\,') : value;
        switch (searchField) {
            case this.tableColumns.id.field:
                this.replaceFilter('Id==', value);
                break;
            case this.tableColumns.subject.field:
                this.replaceFilter('Subject@=', value);
                break;
            case this.tableColumns.category.field:
                this.replaceFilter('Category==', value);
                break;
            case this.tableColumns.createdAt.field:
                this.replaceFilter('CreatedAt@=', value);
                break;
            case this.tableColumns.status.field:
                this.replaceFilter('Status==', value);
                break;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.onOptionsChange(this.pagination, this.sort);
            }
        });
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value) {
                this.currentFilter.splice(index, 1);
            }
            else {
                this.currentFilter[index] = filter + value;
            }
        }
        else if (value)
            this.currentFilter.push(filter + value);
    }
    clearFilter() {
        this.idSearch = '';
        this.subjectSearch = '';
        this.categorySearch = null;
        this.statusSearch = null;
        this.currentFilter = [];
        this.onPageChange(1);
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onSort(field, order) {
        this.sort = {
            field: field,
            direction: order == 'desc' ? SortDirections.DESCENDING : SortDirections.ASCENDING
        };
        this.onOptionsChange(this.pagination, this.sort);
    }
    onOptionsChange(pagination, sort) {
        let options = {
            pagination: pagination,
            sort: sort
        };
        this.changeRouterUrl(options.pagination, options.sort, this.currentFilter);
        this.$emit('onOptionsChange', options, this.currentFilter);
    }
    jumpToPageSelected(page) {
        if (Number.isInteger(page) && page > 0) {
            this.page = page;
            this.onPageChange(page);
        }
    }
    changeRouterUrl(pagination, sort, currentFilter) {
        this.tableQueries = {
            col: sort.field,
            dir: sort.direction == '-' ? 'desc' : 'asc',
            page: pagination.page,
            count: pagination.pageSize,
            Filter: JSON.stringify(currentFilter.filter((x) => x != null))
        };
        let objStringAdminFeedback = JSON.stringify(this.tableQueries);
        this.$emit('changeRouterUrlFromFeedback', objStringAdminFeedback);
    }
    changeSearchField(event) {
        this.isIdSearch = false;
        this.isSubjectSearch = false;
        this.isCategorySearch = false;
        this.isStatusSearch = false;
        this.clearFilter();
        switch (event) {
            case this.tableColumns.id.field:
                this.isIdSearch = true;
                break;
            case this.tableColumns.subject.field:
                this.isSubjectSearch = true;
                break;
            case this.tableColumns.category.field:
                this.isCategorySearch = true;
                break;
            case this.tableColumns.status.field:
                this.isStatusSearch = true;
                break;
        }
    }
    // changing default mobile search field
    changeDefaultField() {
        this.selectedValue = this.tableColumns.id.field;
        this.changeSearchField(this.tableColumns.id.field);
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.subject.field:
                if (!event) {
                    this.subjectSearch = '';
                    this.changeDefaultField();
                    this.filterFeedback(this.tableColumns.subject.field, this.subjectSearch);
                    if (this.sort.field == this.tableColumns.subject.field)
                        this.sort.field = this.tableColumns.id.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.FeedbackListSubject, event);
                break;
            case this.tableColumns.category.field:
                if (!event) {
                    this.categorySearch = null;
                    this.changeDefaultField();
                    this.filterFeedback(this.tableColumns.category.field, '');
                    if (this.sort.field == this.tableColumns.category.field)
                        this.sort.field = this.tableColumns.id.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.FeedbackListCategory, event);
                break;
            case this.tableColumns.createdAt.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.createdAt.field)
                        this.sort.field = this.tableColumns.id.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.FeedbackListCreatedAt, event);
                break;
            case this.tableColumns.status.field:
                if (!event) {
                    this.statusSearch = null;
                    this.changeDefaultField();
                    this.filterFeedback(this.tableColumns.status.field, '');
                    if (this.sort.field == this.tableColumns.status.field)
                        this.sort.field = this.tableColumns.id.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.FeedbackListStatus, event);
                break;
            case this.tableColumns.sentById.field:
                if (!event) {
                    if (this.sort.field == this.tableColumns.sentById.field)
                        this.sort.field = this.tableColumns.id.field;
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.FeedbackListSentById, event);
                break;
        }
        this.onOptionsChange(this.pagination, this.sort);
    }
};
__decorate([
    Prop({ type: Array })
], FeedbackList.prototype, "feedbackList", void 0);
__decorate([
    Prop({ type: Object })
], FeedbackList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Number })
], FeedbackList.prototype, "totalSize", void 0);
FeedbackList = __decorate([
    Component({
        components: {}
    })
], FeedbackList);
export default FeedbackList;
