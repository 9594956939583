import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import EventsTableItem from '@/views/components/events/EventsTableItem.vue';
import DecimalConversion from '@/services/DecimalConversion';
import EventType from '@/entities/EventType';
import { ServiceState } from '@/entities/enums/ServiceState';
const UPTIME_ID = EventType.UPTIME_ID;
let EventsTable = class EventsTable extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
        this.ServiceState = ServiceState;
        this.UPTIME_ID = UPTIME_ID;
    }
    getTimeDifference(date) {
        let timeUnit = this.dateTimeManager.getTimeDifference(date);
        return `${this.$t('component.last_events.table.before', {
            time: timeUnit.time,
            part: this.$t('component.last_events.table.timeUnits.' + timeUnit.unit)
        })}.
    `;
    }
    isOneDay(date) {
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (date.getTime() > yesterday.getTime()) {
            return true;
        }
        else {
            return false;
        }
    }
    getBoundaryForService(event, boundaries) {
        return boundaries.find((x) => x.eventTypeSlug === event.eventType.slug);
    }
    getTooltipText(boundary) {
        if (boundary) {
            let text = '';
            text += this.$t('component.boundaries.lower_alarm').toString();
            text += ': ';
            boundary.lowerAlarmBoundary.Value != null
                ? (text += boundary.lowerAlarmBoundary.Value + boundary.lowerAlarmBoundary.Unit)
                : (text += '-');
            text += '<br>';
            text += this.$t('component.boundaries.lower_warning').toString();
            text += ': ';
            boundary.lowerWarningBoundary.Value != null
                ? (text += boundary.lowerWarningBoundary.Value + boundary.lowerWarningBoundary.Unit)
                : (text += '-');
            text += '<br>';
            text += this.$t('component.boundaries.upper_warning').toString();
            text += ': ';
            boundary.upperWarningBoundary.Value != null
                ? (text += boundary.upperWarningBoundary.Value + boundary.upperWarningBoundary.Unit)
                : (text += '-');
            text += '<br>';
            text += this.$t('component.boundaries.upper_alarm').toString();
            text += ': ';
            boundary.upperAlarmBoundary.Value != null
                ? (text += boundary.upperAlarmBoundary.Value + boundary.upperAlarmBoundary.Unit)
                : (text += '-');
            return text;
        }
    }
};
__decorate([
    Prop({ type: Object })
], EventsTable.prototype, "data", void 0);
__decorate([
    Prop({ type: Object })
], EventsTable.prototype, "dateTimeManager", void 0);
EventsTable = __decorate([
    Component({ components: { EventsTableItem } })
], EventsTable);
export default EventsTable;
