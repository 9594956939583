import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import LoggerConfigurationList from '@/views/components/logger/LoggerConfigurationList.vue';
import IdleLoggersList from '@/views/components/logger/IdleLoggersList.vue';
import LoggerSettings from '@/views/components/logger/LoggerSettings.vue';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import { CompanyLogger } from '@/entities/models/CompanyLogger';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import { DepartmentSettingsKeys } from '@/entities/models/DepartmentSettings';
import { vxm } from '@/store/store.vuex';
import TiersRepository from '@/services/repository/TiersRepository';
var companyLoggerRepository;
var sourceRepository;
var departmentRepository;
var vxDepartmentStore = vxm.departmentStore;
var tiersRepository;
let LoggerManagement = class LoggerManagement extends Vue {
    constructor() {
        super(...arguments);
        this.companyLoggerList = [];
        this.sourceList = [];
        this.isLoading = true;
        this.dateTimeManager = null;
        this.departmentSettings = null;
        this.SELECTED_DEPARTMENT_ID = null;
        this.tier = null;
    }
    showAllLoggers() {
        return this.$ability.can(Actions.MANAGE, Subjects.SOURCE);
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        sourceRepository = new SourceRepository(this);
        departmentRepository = new DepartmentRepository(this);
        tiersRepository = new TiersRepository(this);
        await this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
        this.createApiCalls();
    }
    createApiCalls() {
        let allPromises = [];
        allPromises.push(companyLoggerRepository.getCompanyLoggers());
        if (this.showAllLoggers())
            allPromises.push(sourceRepository.getAllSources());
        else
            allPromises.push(sourceRepository.getVisibleSources());
        allPromises.push(departmentRepository.getDepartment());
        allPromises.push(tiersRepository.getActiveTier());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3]);
        });
    }
    async processLoadedDataForPage(companyLoggerList, sourceList, department, tier) {
        CompanyLogger.addSources(companyLoggerList.getData(), sourceList.getData());
        this.companyLoggerList = companyLoggerList.getData();
        this.sourceList = sourceList.getData();
        this.departmentSettings = department.settings;
        this.tier = tier;
        this.isLoading = false;
    }
    async reloadLoggerList() {
        let sourceList = await sourceRepository.getAllSources();
        this.sourceList = sourceList.getData();
    }
    async updateLoggerThreshold(value) {
        this.isLoading = true;
        this.departmentSettings = await departmentRepository.saveDepartmentSetting([DepartmentSettingsKeys.SOURCE, DepartmentSettingsKeys.OFFLINE_THRESHOLD], value);
        this.isLoading = false;
    }
    async updateLoggerNotification(value) {
        this.isLoading = true;
        this.departmentSettings = await departmentRepository.saveDepartmentSetting([DepartmentSettingsKeys.SOURCE, DepartmentSettingsKeys.DO_NOT_NOTIFY], value);
        this.isLoading = false;
    }
    async updateCustomExpiration(months) {
        this.isLoading = true;
        let res = await companyLoggerRepository.saveLoggersCalibrationExpiration(months);
        if (res === true) {
            await this.reloadDepartmentSettings();
        }
        this.isLoading = false;
    }
    async reloadDepartmentSettings() {
        this.departmentSettings = await departmentRepository.getDepartmentSettings();
    }
    async changeVisibility(source, vis) {
        let sourceDTO = {
            Name: source.name,
            Description: source.description,
            Visible: vis,
            Coordinates: source.coordinates
        };
        let res = await sourceRepository.updateSource(source.id, sourceDTO);
        await this.reloadLoggerList();
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
};
LoggerManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.LOGGER)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            if (this.SELECTED_DEPARTMENT_ID != this.getSelectedDepartmentId()) {
                this.loadData();
            }
            next(); // to resolve hook
        },
        components: { LoggerConfigurationList, IdleLoggersList, LoggerSettings }
    })
], LoggerManagement);
export default LoggerManagement;
