import * as AxiosErrorProcessing from '@/services/api/AxiosErrorProcessing';
import Axios from 'axios';
import AppConfig from '@/configLoader';
import CookiesStorage from '../CookiesStorage';
import { faro } from '@grafana/faro-web-sdk';
export default class AxiosService {
    static getDataFromServer(ajaxUrl, ctx, processError = true, abortSignal = null) {
        return this.getAxiosInstance(ctx)
            .get(ajaxUrl, abortSignal ? { signal: abortSignal } : {})
            .then((response) => response)
            .catch((error) => {
            if (AxiosService.useRemoteLogging()) {
                faro.api.pushError(error, {
                    type: 'network',
                    context: {
                        message: JSON.stringify(error)
                    }
                });
            }
            return processError ? AxiosErrorProcessing.processError(error, ctx) : error.response;
        });
    }
    static postDataToServer(ajaxUrl, data, ctx, processError = true) {
        return this.getAxiosInstance(ctx)
            .post(ajaxUrl, data)
            .then((response) => response)
            .catch((error) => {
            if (AxiosService.useRemoteLogging()) {
                faro.api.pushError(error, {
                    type: 'network',
                    context: {
                        message: JSON.stringify(error)
                    }
                });
            }
            return processError ? AxiosErrorProcessing.processError(error, ctx) : error.response;
        });
    }
    static deleteDataFromServer(ajaxUrl, formData, ctx, processError = true) {
        return this.getAxiosInstance(ctx)
            .delete(ajaxUrl, { data: formData })
            .then((response) => response)
            .catch((error) => {
            if (AxiosService.useRemoteLogging()) {
                faro.api.pushError(error, {
                    type: 'network',
                    context: {
                        message: JSON.stringify(error)
                    }
                });
            }
            return processError ? AxiosErrorProcessing.processError(error, ctx) : error.response;
        });
    }
    static downloadFile_POST(ajaxUrl, data, ctx, processError = true) {
        return this.getAxiosInstance(ctx, true)
            .post(ajaxUrl, data)
            .then((response) => response)
            .catch((error) => {
            if (AxiosService.useRemoteLogging()) {
                faro.api.pushError(error, {
                    type: 'network',
                    context: {
                        message: JSON.stringify(error)
                    }
                });
            }
            return processError ? AxiosErrorProcessing.processError(error, ctx) : error.response;
        });
    }
    static downloadFile_GET(ajaxUrl, ctx, processError = true) {
        return this.getAxiosInstance(ctx, true)
            .get(ajaxUrl)
            .then((response) => response)
            .catch((error) => {
            if (AxiosService.useRemoteLogging()) {
                faro.api.pushError(error, {
                    type: 'network',
                    context: {
                        message: JSON.stringify(error)
                    }
                });
            }
            return processError ? AxiosErrorProcessing.processError(error, ctx) : error.response;
        });
    }
    static putDataToServer(ajaxUrl, data, ctx, processError = true) {
        return this.getAxiosInstance(ctx)
            .put(ajaxUrl, data)
            .then((response) => response)
            .catch((error) => {
            if (AxiosService.useRemoteLogging()) {
                faro.api.pushError(error, {
                    type: 'network',
                    context: {
                        message: JSON.stringify(error)
                    }
                });
            }
            return processError ? AxiosErrorProcessing.processError(error, ctx) : error.response;
        });
    }
    static getAxiosInstance(ctx, downloadingFile = false) {
        let options = {
            baseURL: AppConfig.getConfig()?.backend?.address,
            headers: this.createRequestHeader(ctx)
        };
        if (downloadingFile === true) {
            options.responseType = 'arraybuffer';
        }
        return Axios.create(options);
    }
    static GetWebSocketOptions(ctx) {
        const username = CookiesStorage.loadData(ctx, CookiesStorage.keys.USERNAME);
        const sessionPassword = CookiesStorage.loadData(ctx, CookiesStorage.keys.SESSION_PASSWORD);
        if (username == null && sessionPassword == null)
            return null;
        const credentials = `${username}:${sessionPassword}`;
        return btoa(credentials);
    }
    static getEncodedCredentials(ctx) {
        const username = CookiesStorage.loadData(ctx, CookiesStorage.keys.USERNAME);
        const sessionPassword = CookiesStorage.loadData(ctx, CookiesStorage.keys.SESSION_PASSWORD);
        if (username == null && sessionPassword == null)
            return false;
        const credentials = `${username}:${sessionPassword}`;
        return `Basic ${btoa(credentials)}`;
    }
    static createRequestHeader(ctx) {
        const encodedCredentials = this.getEncodedCredentials(ctx);
        return {
            'X-Authorization': encodedCredentials
        };
    }
    static useRemoteLogging() {
        return AppConfig.getConfig().features.grafana_faro == true;
    }
}
