var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [_vm._v(" " + _vm._s(_vm.$t("component.dashboard.title")) + " ")]
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tile is-ancestor" }, [
        _c(
          "div",
          { staticClass: "tile is-vertical" },
          [
            !_vm.isLoading
              ? [
                  _vm.noLoggerOrDevice && _vm.isWizardAllowed
                    ? _c(
                        "div",
                        { staticClass: "tile is-parent" },
                        [
                          _c(
                            "b-message",
                            {
                              staticClass: "tile is-child",
                              attrs: { type: "is-info" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.dashboard.wizard_message")
                                  ) +
                                  " "
                              ),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "wizard",
                                      params: {
                                        lang: _vm.$route.params.lang,
                                        departmentId:
                                          _vm.$route.params.departmentId,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("component.dashboard.wizard")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.pauseComponentVisible
                    ? _c(
                        "div",
                        { staticClass: "tile is-parent" },
                        [
                          _c("PauseNotificationsMessage", {
                            staticClass: "tile is-child",
                            on: { reloadPause: _vm.reloadNotificationPause },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.invitationNoticeVisible
                    ? _c(
                        "div",
                        { staticClass: "tile is-parent" },
                        [
                          _c(
                            "b-message",
                            { attrs: { type: "is-warning" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.invitation.inv_notice")
                                  ) +
                                  " "
                              ),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "organizations",
                                      params: {
                                        lang: _vm.$route.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("generals.view_all"))),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            !_vm.isLoading
              ? _c("div", { staticClass: "tile" }, [
                  _c("div", { staticClass: "tile is-parent" }, [
                    _c(
                      "div",
                      { staticClass: "tile is-child" },
                      [
                        _c("StatusCards", {
                          staticClass: "is-hidden-mobile",
                          attrs: {
                            departmentIssues: _vm.departmentIssues,
                            devicesCount: _vm.deviceList.length,
                            sourcesCount: _vm.sources.length,
                            activeLoggersCount: _vm.activeLoggersCount,
                            notificationCount:
                              _vm.lastDaynotificationsResult.getPagination()
                                .total,
                            sources: _vm.sources,
                            companyLoggerExpirationList:
                              _vm.companyLoggerExpirationList,
                          },
                        }),
                        _c("StatusCardsMobile", {
                          staticClass: "is-hidden-tablet",
                          attrs: {
                            departmentIssues: _vm.departmentIssues,
                            devicesCount: _vm.deviceList.length,
                            sourcesCount: _vm.sources.length,
                            activeLoggersCount: _vm.activeLoggersCount,
                            notificationCount:
                              _vm.lastDaynotificationsResult.getPagination()
                                .total,
                            sources: _vm.sources,
                            companyLoggerExpirationList:
                              _vm.companyLoggerExpirationList,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "tile" }, [
              _c("div", { staticClass: "tile is-parent is-6" }, [
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.MEASURED_DATA_READ
                ) && !_vm.isLoading
                  ? _c("div", { staticClass: "tile is-child" }, [
                      !_vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "is-hidden-mobile" },
                            [
                              _c("LastEventsTable", {
                                attrs: {
                                  lastEvents: _vm.lastEvents,
                                  sourceGroups: _vm.sourceGroups,
                                  sources: _vm.sources,
                                  refreshAllowed: _vm.refreshAllowed,
                                  favoriteGroupId: _vm.favoriteGroupId,
                                },
                                on: {
                                  refresh: _vm.refreshMeasurements,
                                  favoriteSelected: _vm.favoriteSelected,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "is-hidden-mobile" },
                            [
                              _vm.$ability.can(
                                _vm.$permActions.READ,
                                _vm.$permSubjects.MEASURED_DATA_READ
                              ) && !_vm.isLoading
                                ? _c("DevicesState", {
                                    staticClass: "box",
                                    attrs: {
                                      dateTimeManager: _vm.dateTimeManager,
                                      deviceList: _vm.deviceList,
                                    },
                                  })
                                : _vm._e(),
                              _c("NotificationCard", {
                                staticClass: "box",
                                attrs: {
                                  notifications:
                                    _vm.notificationsResult.getData(),
                                  allNotificationCount:
                                    _vm.notificationsResult.getPagination()
                                      .total,
                                  paused: _vm.notificationsPaused,
                                  pausedTill: _vm.pauseNotificationsUntil,
                                  dateTimeManager: _vm.dateTimeManager,
                                  buttonEnabled: true,
                                },
                                on: {
                                  notificationRemoved: _vm.notificationRemoved,
                                },
                              }),
                              _c("LoggerExpirationList", {
                                staticClass: "box",
                                attrs: {
                                  companyLoggerExpirationList:
                                    _vm.companyLoggerExpirationList,
                                  dateTimeManager: _vm.dateTimeManager,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "is-hidden-tablet" },
                            [
                              _c("LastEventsTable", {
                                attrs: {
                                  lastEvents: _vm.lastEvents,
                                  sourceGroups: _vm.sourceGroups,
                                  sources: _vm.sources,
                                  refreshAllowed: _vm.refreshAllowed,
                                  favoriteGroupId: _vm.favoriteGroupId,
                                },
                                on: {
                                  refresh: _vm.refreshMeasurements,
                                  favoriteSelected: _vm.favoriteSelected,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "tile is-parent is-6 is-vertical" }, [
                _vm.floorplanEnabled &&
                _vm.floorPlans.length > 0 &&
                !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "box" },
                      [
                        _c(
                          "b-collapse",
                          {
                            staticClass: "panel outdated",
                            attrs: { animation: "slide" },
                            on: {
                              open: function ($event) {
                                return _vm.floorPlanCollapseChanged(true)
                              },
                              close: function ($event) {
                                return _vm.floorPlanCollapseChanged(false)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "trigger",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "panel-heading m-0 p-0",
                                          attrs: {
                                            role: "button",
                                            id: "refers",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "has-text-weight-medium is-flex is-justify-content-space-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "is-flex" },
                                                [
                                                  _c(
                                                    "h2",
                                                    {
                                                      staticClass:
                                                        "subtitle is-4 m-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .selectedFloorPlan
                                                              .name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.floorPlanVisible
                                                ? _c("b-icon", {
                                                    attrs: {
                                                      icon: "chevron-down mt-1",
                                                    },
                                                  })
                                                : _c("b-icon", {
                                                    attrs: {
                                                      icon: "chevron-right mt-1",
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3772741979
                            ),
                            model: {
                              value: _vm.floorPlanVisible,
                              callback: function ($$v) {
                                _vm.floorPlanVisible = $$v
                              },
                              expression: "floorPlanVisible",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("FloorPlanMeasurements", {
                                  ref: "floorplanMeasurements",
                                  attrs: {
                                    floorPlans: _vm.floorPlans,
                                    selectedFloorPlan: _vm.selectedFloorPlan,
                                    selectedFloorPlanImage:
                                      _vm.selectedFloorPlanImage,
                                    selectedFloorPlanLocationData:
                                      _vm.selectedFloorPlanLocationData,
                                    imageType: _vm.imageType,
                                    lastEvents: _vm.getAliveEvents(
                                      _vm.lastEventsFloorPlan
                                    ),
                                    sources: _vm.sources,
                                    allLastEvents: _vm.lastEventsFloorPlan,
                                    sourceGroups: _vm.sourceGroups,
                                    devices: _vm.deviceList,
                                    isFullscreen:
                                      _vm.isFullScreenFloorPlanActive,
                                    canvasId: "mainCanvas",
                                  },
                                  on: {
                                    toggleFloorplan: _vm.toggleFloorplan,
                                    setSelectedFloorplan:
                                      _vm.setSelectedFloorplan,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "tile is-child is-hidden-tablet" },
                      [
                        _vm.$ability.can(
                          _vm.$permActions.READ,
                          _vm.$permSubjects.MEASURED_DATA_READ
                        ) && !_vm.isLoading
                          ? _c("DevicesState", {
                              staticClass: "box",
                              attrs: {
                                dateTimeManager: _vm.dateTimeManager,
                                deviceList: _vm.deviceList,
                              },
                            })
                          : _vm._e(),
                        _c("NotificationCard", {
                          staticClass: "box",
                          attrs: {
                            notifications: _vm.notificationsResult.getData(),
                            allNotificationCount:
                              _vm.notificationsResult.getPagination().total,
                            paused: _vm.notificationsPaused,
                            pausedTill: _vm.pauseNotificationsUntil,
                            dateTimeManager: _vm.dateTimeManager,
                            buttonEnabled: true,
                          },
                          on: { notificationRemoved: _vm.notificationRemoved },
                        }),
                        _c("LoggerExpirationList", {
                          staticClass: "box",
                          attrs: {
                            companyLoggerExpirationList:
                              _vm.companyLoggerExpirationList,
                            dateTimeManager: _vm.dateTimeManager,
                          },
                          on: { downloadSample: _vm.downloadSample },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ],
          2
        ),
      ]),
      _vm.floorPlans.length > 0
        ? _c(
            "b-modal",
            {
              staticClass: "pt-0 fullscreen-modal-cross",
              attrs: {
                active: _vm.isFullScreenFloorPlanActive,
                "has-modal-card": "",
                onCancel: _vm.closeFullScreenFloorPlan,
                "full-screen": "",
              },
              on: {
                "update:active": function ($event) {
                  _vm.isFullScreenFloorPlanActive = $event
                },
                "close-modal": _vm.closeFullScreenFloorPlan,
              },
            },
            [
              _c("div", { staticClass: "modal-card" }, [
                _c("header", { staticClass: "modal-card-head" }, [
                  _c("p", { staticClass: "modal-card-title" }, [
                    _vm._v(" " + _vm._s(_vm.selectedFloorPlan.name) + " "),
                  ]),
                ]),
                _c(
                  "section",
                  { staticClass: "modal-card-body" },
                  [
                    _c("FloorPlanMeasurements", {
                      ref: "floorplanMeasurementsFullScreen",
                      attrs: {
                        floorPlans: _vm.floorPlans,
                        selectedFloorPlan: _vm.selectedFloorPlan,
                        selectedFloorPlanImage: _vm.selectedFloorPlanImage,
                        selectedFloorPlanLocationData:
                          _vm.selectedFloorPlanLocationData,
                        imageType: _vm.imageType,
                        lastEvents: _vm.getAliveEvents(_vm.lastEventsFloorPlan),
                        sources: _vm.sources,
                        allLastEvents: _vm.lastEventsFloorPlan,
                        sourceGroups: _vm.sourceGroups,
                        devices: _vm.deviceList,
                        isFullscreen: _vm.isFullScreenFloorPlanActive,
                        canvasId: "modalCanvas",
                      },
                      on: {
                        toggleFloorplan: _vm.toggleFloorplan,
                        setSelectedFloorplan: _vm.setSelectedFloorplan,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }