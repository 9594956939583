"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataVisualStyle = void 0;
var DataVisualStyle;
(function (DataVisualStyle) {
    DataVisualStyle[DataVisualStyle["LINE"] = 0] = "LINE";
    DataVisualStyle[DataVisualStyle["POINTS"] = 1] = "POINTS";
    DataVisualStyle[DataVisualStyle["UPTIMERECTANGLE"] = 2] = "UPTIMERECTANGLE";
    DataVisualStyle[DataVisualStyle["ANNOTATIONRECTANGLE"] = 3] = "ANNOTATIONRECTANGLE";
})(DataVisualStyle = exports.DataVisualStyle || (exports.DataVisualStyle = {}));
