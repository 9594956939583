var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "upload-container",
        class: { dragover: _vm.isDragging },
        on: {
          click: _vm.triggerFileInput,
          dragover: function ($event) {
            $event.preventDefault()
            return _vm.dragOver.apply(null, arguments)
          },
          dragleave: _vm.dragLeave,
          drop: function ($event) {
            $event.preventDefault()
            return _vm.handleDrop.apply(null, arguments)
          },
        },
      },
      [
        _c("i", { staticClass: "fas fa-upload fa-lg" }),
        _c("p", [_vm._v(_vm._s(_vm.$t("component.floorPlan.upload")))]),
        _c("input", {
          ref: "fileInput",
          staticClass: "file-input",
          attrs: { type: "file", required: "", accept: _vm.acceptFileTypes },
          on: { change: _vm.handleFileUpload },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }