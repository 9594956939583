var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card logger-modal-header" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "reports.report_settings.report_config_modal.configuration.title"
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-tabs",
            {
              staticClass: "mb-1",
              attrs: { type: "is-boxed" },
              model: {
                value: _vm.selectedMode,
                callback: function ($$v) {
                  _vm.selectedMode = $$v
                },
                expression: "selectedMode",
              },
            },
            [
              _c("b-tab-item", {
                attrs: {
                  label: _vm.$t("reports.report_mode.default"),
                  value: _vm.PdfReportTypes.FULL,
                },
              }),
            ],
            1
          ),
          _vm.isAdmin
            ? _c(
                "b-field",
                {
                  staticClass: "mr-2-tablet",
                  attrs: {
                    label: _vm.$t(
                      "reports.report_settings.report_config_modal.configuration.author"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "reports.report_settings.report_config_modal.configuration.author"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "reports.report_settings.report_config_modal.configuration.author"
                      )
                    ),
                  },
                },
                [
                  _c("b-autocomplete", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "content-full-width",
                    attrs: {
                      grouped: "",
                      data: _vm.filteredUsers,
                      field: "username",
                      placeholder: _vm.$t(
                        "reports.report_settings.report_config_modal.configuration.select_user"
                      ),
                      name: _vm.$t(
                        "reports.report_settings.report_config_modal.configuration.author"
                      ),
                      "icon-pack": "fas",
                      icon: "search",
                      clearable: "",
                      "open-on-focus": "",
                    },
                    on: { select: _vm.changeUserName },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "empty",
                          fn: function () {
                            return [
                              _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3307844859
                    ),
                    model: {
                      value: _vm.selectedUserFullName,
                      callback: function ($$v) {
                        _vm.selectedUserFullName = $$v
                      },
                      expression: "selectedUserFullName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-field",
            {
              staticClass: "content-full-width",
              attrs: {
                grouped: "",
                label: _vm.$t(
                  "reports.report_settings.report_config_modal.configuration.range"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "reports.report_settings.report_config_modal.configuration.range"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "reports.report_settings.report_config_modal.configuration.range"
                  )
                ),
              },
            },
            [
              _c(
                "b-select",
                {
                  attrs: {
                    expanded: "",
                    placeholder: _vm.$t(
                      "reports.report_settings.report_config_modal.configuration.range"
                    ),
                  },
                  model: {
                    value: _vm.selectedPeriodic,
                    callback: function ($$v) {
                      _vm.selectedPeriodic = $$v
                    },
                    expression: "selectedPeriodic",
                  },
                },
                _vm._l(_vm.PeriodicReportPdfType, function (option) {
                  return _c(
                    "option",
                    { key: option, domProps: { value: option } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `reports.report_settings.report_config_modal.configuration.frequent_range.${option}`
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "reports.report_settings.report_config_modal.configuration.for_entity"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "reports.report_settings.report_config_modal.configuration.for_entity"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "reports.report_settings.report_config_modal.configuration.for_entity"
                  )
                ),
              },
            },
            _vm._l(_vm.PdfScope, function (el) {
              return _c(
                "b-radio",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  key: el,
                  attrs: {
                    name: _vm.$t(
                      "reports.report_settings.report_config_modal.configuration.for_entity"
                    ),
                    "native-value": el,
                  },
                  on: { input: _vm.entitySelected },
                  model: {
                    value: _vm.selectedEntity,
                    callback: function ($$v) {
                      _vm.selectedEntity = $$v
                    },
                    expression: "selectedEntity",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `reports.report_settings.report_config_modal.configuration.entities.${el}`
                        )
                      ) +
                      " "
                  ),
                ]
              )
            }),
            1
          ),
          _vm.selectedEntity
            ? _c(
                "b-field",
                [
                  _vm.selectedEntity === _vm.PdfScope.LOGGER
                    ? _c(
                        "b-table",
                        {
                          staticClass: "select-location-table border",
                          attrs: {
                            data: _vm.sources,
                            "checked-rows": _vm.selectedSources,
                            paginated: true,
                            "per-page": 5,
                            narrowed: "",
                            checkable: "",
                            "mobile-cards": false,
                          },
                          on: {
                            "update:checkedRows": function ($event) {
                              _vm.selectedSources = $event
                            },
                            "update:checked-rows": function ($event) {
                              _vm.selectedSources = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "bottom-left",
                                fn: function () {
                                  return [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.notification.create_rule.entity_table.selected_count"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(
                                      " : " +
                                        _vm._s(_vm.selectedSources.length) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2007173811
                          ),
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              field: "name",
                              label: _vm.$t(
                                "component.notification.create_rule.entity_table.name"
                              ),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (sources) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(sources.row.name)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2608515585
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm.selectedEntity == _vm.PdfScope.GROUP
                    ? _c(
                        "b-table",
                        {
                          staticClass: "select-location-table border",
                          attrs: {
                            data: _vm.sourceGroups,
                            "checked-rows": _vm.selectedSourceGroups,
                            paginated: true,
                            "per-page": 5,
                            narrowed: "",
                            checkable: "",
                            "mobile-cards": false,
                          },
                          on: {
                            "update:checkedRows": function ($event) {
                              _vm.selectedSourceGroups = $event
                            },
                            "update:checked-rows": function ($event) {
                              _vm.selectedSourceGroups = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "bottom-left",
                                fn: function () {
                                  return [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.notification.create_rule.entity_table.selected_count"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.selectedSourceGroups.length
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4293191692
                          ),
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              field: "name",
                              label: _vm.$t(
                                "component.notification.create_rule.entity_table.name"
                              ),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (groups) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(groups.row.name)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2249423201
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode === _vm.PdfReportTypes.FULL
            ? _c(
                "b-field",
                {
                  staticClass: "radio-buttons",
                  attrs: {
                    grouped: "",
                    "group-multiline": "",
                    label: _vm.$t(
                      "reports.report_settings.report_config_modal.configuration.records"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "reports.report_settings.report_config_modal.configuration.records"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "reports.report_settings.report_config_modal.configuration.records"
                      )
                    ),
                  },
                },
                _vm._l(_vm.RecordsTableTypes, function (el) {
                  return _c(
                    "b-radio-button",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      key: el,
                      attrs: {
                        name: _vm.$t(
                          "reports.report_settings.report_config_modal.configuration.records"
                        ),
                        "native-value": el,
                        type: "is-primary",
                      },
                      model: {
                        value: _vm.selectedRecordsTableType,
                        callback: function ($$v) {
                          _vm.selectedRecordsTableType = $$v
                        },
                        expression: "selectedRecordsTableType",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                `reports.report_settings.report_config_modal.configuration.records_table.${el}`
                              )
                            )
                        ),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.selectedMode === _vm.PdfReportTypes.FULL
            ? _c(
                "b-field",
                {
                  attrs: {
                    grouped: "",
                    label: _vm.$t(
                      "component.report.configuration.modal.reportingInterval"
                    ),
                  },
                },
                [
                  _c(
                    "b-select",
                    {
                      on: { input: _vm.reportingIntervalChanged },
                      model: {
                        value: _vm.interval,
                        callback: function ($$v) {
                          _vm.interval = $$v
                        },
                        expression: "interval",
                      },
                    },
                    _vm._l(_vm.allReportingIntervals, function (interval) {
                      return _c(
                        "option",
                        { key: interval[0], domProps: { value: interval[0] } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `component.report.reportingIntervals.${interval[1]}`
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode === _vm.PdfReportTypes.FULL
            ? _c(
                "b-field",
                {
                  staticClass: "border",
                  attrs: { grouped: "", "group-multiline": "" },
                },
                [
                  _c(
                    "b-field",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-radio",
                        {
                          attrs: { "native-value": _vm.StatisticsType.AVERAGE },
                          on: { input: _vm.typeChanged },
                          model: {
                            value: _vm.selectedType,
                            callback: function ($$v) {
                              _vm.selectedType = $$v
                            },
                            expression: "selectedType",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.configuration.modal.averageValues"
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "b-tooltip",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                type: "is-dark",
                                position: "is-bottom",
                                label: _vm.$t(
                                  "component.report.configuration.modal.average_note"
                                ),
                                multilined: "",
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "has-text-grey",
                                attrs: { icon: "question-circle" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-radio",
                        {
                          attrs: { "native-value": _vm.StatisticsType.DAILY },
                          on: { input: _vm.typeChanged },
                          model: {
                            value: _vm.selectedType,
                            callback: function ($$v) {
                              _vm.selectedType = $$v
                            },
                            expression: "selectedType",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.report.configuration.modal.measuredValues"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.hasOneDayIntervalSelected === false
                    ? _c(
                        "b-field",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.measuredAt"
                            ),
                          },
                        },
                        [
                          _vm.canHaveOffset
                            ? _c(
                                "b-select",
                                {
                                  staticStyle: { "max-width": "125px" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.measuredOffsetChanged()
                                    },
                                  },
                                  model: {
                                    value: _vm.measurementOffsetIndex,
                                    callback: function ($$v) {
                                      _vm.measurementOffsetIndex = $$v
                                    },
                                    expression: "measurementOffsetIndex",
                                  },
                                },
                                _vm._l(
                                  _vm.allMeasuredTimes,
                                  function (time, index) {
                                    return _c(
                                      "option",
                                      { key: time, domProps: { value: index } },
                                      [_vm._v(" " + _vm._s(time) + " ")]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("b-select", { attrs: { disabled: "" } }, [
                                _c("option", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.report.configuration.modal.measuredAt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                        ],
                        1
                      )
                    : _c(
                        "b-field",
                        [
                          _vm.canHaveOffset
                            ? _c("b-clockpicker", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "placeholders.click_select"
                                  ),
                                  icon: "clock",
                                  "hour-format": _vm.hourFormatInClockPicker,
                                  "hours-label": _vm.$t("placeholders.hours"),
                                  "minutes-label": _vm.$t(
                                    "placeholders.minutes"
                                  ),
                                  editable: "",
                                  position: "is-top-left",
                                },
                                on: { input: _vm.clockChanged },
                                model: {
                                  value: _vm.timeOfMeasurement,
                                  callback: function ($$v) {
                                    _vm.timeOfMeasurement = $$v
                                  },
                                  expression: "timeOfMeasurement",
                                },
                              })
                            : _c("b-clockpicker", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "placeholders.click_select"
                                  ),
                                  icon: "clock",
                                },
                                on: { input: _vm.clockChanged },
                              }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode === _vm.PdfReportTypes.FULL &&
          (_vm.hasTemp !== -1 || _vm.hasHum !== -1)
            ? _c(
                "b-field",
                {
                  staticClass: "mb-5",
                  attrs: {
                    label: _vm.$t(
                      "component.report.configuration.modal.eventTypes"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "component.report.configuration.modal.eventTypes"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t("component.report.configuration.modal.eventTypes")
                    ),
                    grouped: "",
                    "group-multiline": "",
                  },
                },
                [
                  _vm.hasTemp !== -1
                    ? _c(
                        "b-checkbox",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "mb-2",
                          attrs: {
                            name: _vm.$t(
                              "component.report.configuration.modal.eventTypes"
                            ),
                            "native-value": "temperature",
                          },
                          model: {
                            value: _vm.selectedEventTypes,
                            callback: function ($$v) {
                              _vm.selectedEventTypes = $$v
                            },
                            expression: "selectedEventTypes",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("services.temperature")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.hasHum !== -1
                    ? _c(
                        "b-checkbox",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "mb-2",
                          attrs: {
                            name: _vm.$t(
                              "component.report.configuration.modal.eventTypes"
                            ),
                            "native-value": "humidity",
                          },
                          model: {
                            value: _vm.selectedEventTypes,
                            callback: function ($$v) {
                              _vm.selectedEventTypes = $$v
                            },
                            expression: "selectedEventTypes",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("services.humidity")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode === _vm.PdfReportTypes.FULL
            ? _c(
                "b-field",
                {
                  staticClass: "flex-space-between",
                  attrs: {
                    label: _vm.$t(
                      "reports.report_settings.report_config_modal.configuration.chart"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "reports.report_settings.report_config_modal.configuration.chart"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "reports.report_settings.report_config_modal.configuration.chart"
                      )
                    ),
                  },
                },
                [
                  _c("b-switch", {
                    attrs: {
                      name: _vm.$t(
                        "reports.report_settings.report_config_modal.configuration.chart"
                      ),
                    },
                    model: {
                      value: _vm.showChart,
                      callback: function ($$v) {
                        _vm.showChart = $$v
                      },
                      expression: "showChart",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode === _vm.PdfReportTypes.FULL
            ? _c(
                "b-field",
                {
                  staticClass: "flex-space-between",
                  attrs: {
                    label: _vm.$t(
                      "reports.report_settings.report_config_modal.configuration.annotations"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "reports.report_settings.report_config_modal.configuration.annotations"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "reports.report_settings.report_config_modal.configuration.annotations"
                      )
                    ),
                  },
                },
                [
                  _c("b-switch", {
                    attrs: {
                      name: _vm.$t(
                        "reports.report_settings.report_config_modal.configuration.annotations"
                      ),
                    },
                    model: {
                      value: _vm.showAnnotations,
                      callback: function ($$v) {
                        _vm.showAnnotations = $$v
                      },
                      expression: "showAnnotations",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedMode === _vm.PdfReportTypes.FULL
            ? _c(
                "b-field",
                {
                  staticClass: "flex-space-between",
                  attrs: {
                    label: _vm.$t(
                      "component.report.detail_table.report_export.pdf_export.statistics"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "component.report.detail_table.report_export.pdf_export.statistics"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.statistics"
                      )
                    ),
                  },
                },
                [
                  _c("b-switch", {
                    attrs: {
                      name: _vm.$t(
                        "component.report.detail_table.report_export.pdf_export.statistics"
                      ),
                    },
                    model: {
                      value: _vm.showStatistics,
                      callback: function ($$v) {
                        _vm.showStatistics = $$v
                      },
                      expression: "showStatistics",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-field",
            {
              staticClass: "flex-space-between",
              attrs: {
                label: _vm.$t(
                  "reports.report_settings.report_config_modal.configuration.show_empty_records"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "reports.report_settings.report_config_modal.configuration.show_empty_records"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "reports.report_settings.report_config_modal.configuration.show_empty_records"
                  )
                ),
              },
            },
            [
              _c("b-switch", {
                attrs: {
                  name: _vm.$t(
                    "reports.report_settings.report_config_modal.configuration.show_empty_records"
                  ),
                },
                model: {
                  value: _vm.currentShowEmpty,
                  callback: function ($$v) {
                    _vm.currentShowEmpty = $$v
                  },
                  expression: "currentShowEmpty",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("footer", { staticClass: "modal-card-foot is-block" }, [
        _c("div", { staticClass: "level is-mobile" }, [
          _c(
            "div",
            { staticClass: "level-left" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item",
                  on: {
                    click: function ($event) {
                      return _vm.resultRetrieved(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.close")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "level-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item",
                  attrs: { type: "is-primary" },
                  on: { click: _vm.configSettings },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "reports.report_settings.report_config_modal.configuration.confirm"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }