var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-flex is-justify-content-space-between" },
        [
          _c("p", { staticClass: "mr-2" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("component.last_events.table.name")) + " "
            ),
          ]),
          _c(
            "router-link",
            {
              staticClass: "has-text-right",
              attrs: {
                to: {
                  name: "logger_detail",
                  params: {
                    locationId: _vm.data.source.id,
                    departmentId: _vm.$route.params.departmentId,
                    lang: _vm.$route.params.lang,
                  },
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.data.source.name) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column is-flex is-justify-content-space-between" },
        [
          _c("p", { staticClass: "mr-2" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.last_events.table.serialNumber")) +
                " "
            ),
          ]),
          _vm.data.source.logger
            ? _c("p", [
                _vm._v(" " + _vm._s(_vm.data.source.logger.serialNumber) + " "),
              ])
            : _c("p"),
        ]
      ),
      _c(
        "div",
        { staticClass: "column is-flex is-justify-content-space-between" },
        [
          _c("p", [
            _vm._v(
              " " + _vm._s(_vm.$t("component.last_events.table.measured")) + " "
            ),
          ]),
          _vm.isOneDay(new Date(_vm.data.events[0].measuredAt * 1000))
            ? _c(
                "p",
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.getTimeDifference(
                          new Date(_vm.data.events[0].measuredAt * 1000)
                        )
                      ) +
                      " "
                  ),
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        type: "is-dark",
                        label: _vm.dateTimeManager.formatTime(
                          new Date(_vm.data.events[0].measuredAt * 1000)
                        ),
                        position: "is-left",
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          size: "is-small",
                          icon: "question-circle",
                          type: "is-grey",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(_vm.data.events[0].measuredAt * 1000),
                        null,
                        null,
                        null
                      )
                    ) +
                    " "
                ),
              ]),
        ]
      ),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "columns is-mobile" }, [
          _c("div", { staticClass: "column is-label" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("component.last_events.table.value")) + " "
            ),
          ]),
          _c("div", { staticClass: "column is-value" }, [
            _c(
              "div",
              { staticClass: "columns" },
              [
                _vm.data.source.logger &&
                _vm.data.source.logger.state == _vm.ServiceState.ERROR
                  ? _c(
                      "div",
                      { staticClass: "column has-text-right" },
                      [
                        _c(
                          "b-tag",
                          { attrs: { rounded: "", type: "is-danger" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("loggers.logger_states.Error")) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.data.source.activeService
                  ? _c(
                      "div",
                      { staticClass: "column has-text-right" },
                      [
                        _c(
                          "b-tag",
                          { attrs: { rounded: "", type: "is-info" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("loggers.logger_states.Service")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._l(_vm.data.events, function (event) {
                      return _c(
                        "div",
                        {
                          key: event.eventType.id,
                          staticClass: "column has-text-right",
                        },
                        [
                          event.eventType.id == _vm.UPTIME_ID
                            ? _c("div", [
                                _vm.data.source.checkIsOnline()
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.$t("generals.online"))),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("generals.offline"))
                                      ),
                                    ]),
                              ])
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "columns is-mobile has-text-right",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "column is-6 is-italic" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "services." + event.eventType.slug
                                            )
                                          ) + ":"
                                        ),
                                      ]
                                    ),
                                    event.boundaryDiff.Difference.length === 0
                                      ? _c(
                                          "div",
                                          { staticClass: "column is-6" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.DecimalConversion.toCommaString(
                                                    event.value
                                                  ) + event.unit
                                                ) +
                                                " "
                                            ),
                                            _c("b-icon", {
                                              staticClass: "ml-1",
                                              attrs: {
                                                icon: "fas fa-circle",
                                                type: event.status.class,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "b-tooltip",
                                          {
                                            staticClass: "column is-6",
                                            attrs: { type: "is-dark" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "content",
                                                  fn: function () {
                                                    return [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getTooltipText(
                                                              _vm.getBoundaryForService(
                                                                event,
                                                                _vm.data
                                                                  .measuredBoundaries
                                                              )
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.DecimalConversion.toCommaString(
                                                    event.value
                                                  ) + event.unit
                                                ) +
                                                " "
                                            ),
                                            _c("b-icon", {
                                              staticClass: "ml-1",
                                              attrs: {
                                                icon: "fas fa-circle",
                                                type: event.status.class,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ],
                        ],
                        2
                      )
                    }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }