export var IssueType;
(function (IssueType) {
    IssueType["ALARM"] = "measuredDataAlarm";
    IssueType["SOURCE_OFFLINE"] = "sourceOffline";
    IssueType["DEVICE_OFFLINE"] = "deviceOffline";
    IssueType["LOW_BATTERY"] = "lowBattery";
    IssueType["MAINTENANCE"] = "plannedMaintenance";
})(IssueType || (IssueType = {}));
export function getIssueTypes() {
    return Object.values(IssueType);
}
