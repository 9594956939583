var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.location.detail.msg.file_download_info")
                    ) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.noCoordinates
        ? _c(
            "b-message",
            {
              attrs: {
                type: "is-warning",
                "has-icon": "",
                "icon-size": "is-medium",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("admin.component.loggers.detail.info.warning")
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "multi-header mb-4" }, [
        _vm.isLayoutList
          ? _c(
              "div",
              {
                staticClass:
                  "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
              },
              [
                _c("h2", { staticClass: "subtitle is-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("admin.component.loggers.detail.info.title")
                      ) +
                      " "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm.$ability.can(
          _vm.$permActions.UPDATE,
          _vm.$permSubjects.ADMIN_LOGGERS
        )
          ? _c(
              "div",
              { staticClass: "ml-auto buttons" },
              [
                !_vm.isEditing
                  ? _c("b-button", {
                      attrs: { "icon-right": "pencil-alt" },
                      on: {
                        click: function ($event) {
                          return _vm.openEdit()
                        },
                      },
                    })
                  : _c(
                      "b-button",
                      {
                        staticClass: "ml-1",
                        attrs: { "icon-left": "times-circle" },
                        on: {
                          click: function ($event) {
                            return _vm.closeEdit()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("generals.cancel_edit")) + " "
                        ),
                      ]
                    ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("section", [
        _c("div", { staticClass: "columns info-fields" }, [
          _c(
            "div",
            { staticClass: "column px-0-mobile py-0-mobile" },
            [
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.loggers.detail.info.serialNumber"
                    ),
                  },
                },
                [
                  _vm.logger.currentlyPublishedAs
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.logger.currentlyPublishedAs.serialNumber
                            ) +
                            " "
                        ),
                      ])
                    : _c("p", { staticClass: "is-italic" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.loggers.detail.info.not_published"
                              )
                            ) +
                            " "
                        ),
                      ]),
                ]
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: { horizontal: "", label: _vm.$t("loggers.state") },
                },
                [
                  _vm.logger.serviceState == _vm.ServiceState.ERROR
                    ? _c(
                        "b-tag",
                        { attrs: { rounded: "", type: "is-danger" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("loggers.logger_states.Error")) +
                              " "
                          ),
                        ]
                      )
                    : _vm.logger.serviceState === null
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("loggers.logger_states.UNKNOWN")) +
                            " "
                        ),
                      ])
                    : _vm.logger.serviceState
                    ? _c("p", [_vm._v(_vm._s(_vm.logger.serviceState))])
                    : _c("p", [_vm._v("-")]),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.loggers.detail.info.isOnline"
                    ),
                  },
                },
                [
                  !_vm.logger.currentlyPublishedAs ||
                  _vm.logger.currentlyPublishedAs.isOnline == false
                    ? _c(
                        "b-tag",
                        { attrs: { type: "is-primary", rounded: "" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("component.last_events.table.no_signal")
                            )
                          ),
                        ]
                      )
                    : _vm.logger.currentlyPublishedAs.isOnline == true
                    ? _c(
                        "b-tag",
                        { attrs: { type: "is-success", rounded: "" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("component.last_events.table.has_signal")
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.loggers.detail.info.company"
                    ),
                  },
                },
                [
                  _vm.logger.currentlyPublishedAs &&
                  _vm.logger.currentlyPublishedAs.company &&
                  _vm.logger.currentlyPublishedAs.company.isDeleted == true
                    ? _c(
                        "span",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.logger.currentlyPublishedAs.company
                                  .companyName
                              ) +
                              " "
                          ),
                          _c(
                            "b-tag",
                            { attrs: { type: "is-primary", rounded: "" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.devices.detail.deviceInfo.deleted"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.logger.currentlyPublishedAs &&
                      _vm.logger.currentlyPublishedAs.company &&
                      _vm.logger.currentlyPublishedAs.company.companyId
                    ? [
                        _vm.hasPermissionToViewDepartment(
                          _vm.logger.currentlyPublishedAs.company.companyId
                        )
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "adminCompanyDetail",
                                    params: {
                                      departmentId:
                                        _vm.logger.currentlyPublishedAs.company
                                          .companyId,
                                      title: _vm.logger.macAddress,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.logger.currentlyPublishedAs.company
                                      .companyName
                                  )
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.logger.currentlyPublishedAs.company
                                    .companyName
                                )
                              ),
                            ]),
                      ]
                    : _c("p", { staticClass: "is-italic" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.loggers.detail.info.not_in_company"
                              )
                            ) +
                            " "
                        ),
                      ]),
                ],
                2
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.loggers.detail.info.calibration"
                    ),
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "is-align-items-center ml-3-mobile" },
                    [
                      _vm.companyLogger.calibration != null
                        ? _c(
                            "b-tag",
                            {
                              attrs: {
                                type: _vm.companyLogger.calibration.status.type,
                                rounded: "",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    `calibration_status.${_vm.companyLogger.calibration.status.name}`
                                  )
                                )
                              ),
                            ]
                          )
                        : _c(
                            "b-tag",
                            {
                              attrs: {
                                type: _vm.CalibrationStatus.NOT_CALIBRATED.type,
                                rounded: "",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    `calibration_status.${_vm.CalibrationStatus.NOT_CALIBRATED.name}`
                                  )
                                )
                              ),
                            ]
                          ),
                      _vm.hasCalibrationProtocolToDownload(_vm.companyLogger) &&
                      _vm.$ability.can(
                        _vm.$permActions.PROTOCOL,
                        _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
                      )
                        ? [
                            _c(
                              "b-tooltip",
                              {
                                staticClass: "is-hidden-mobile",
                                attrs: {
                                  label: _vm.$t("generals.download"),
                                  position: "is-left",
                                  type: "is-light",
                                },
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "control-icon clickable",
                                  attrs: { icon: "file-download" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.downloadCalibration(
                                        _vm.companyLogger.companyLoggerId
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "is-hidden-tablet" },
                              [
                                _c("b-icon", {
                                  staticClass: "control-icon clickable",
                                  attrs: { icon: "file-download" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.downloadCalibration(
                                        _vm.companyLogger.companyLoggerId
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: { horizontal: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.loggers.detail.info.available_types"
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                type: "is-dark",
                                label: _vm.$t(
                                  "admin.component.loggers.detail.info.available_types_note"
                                ),
                                position: "is-right",
                                multilined: "",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  size: "is-small",
                                  icon: "question-circle",
                                  type: "is-grey",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("p", [_vm._v(_vm._s(_vm.availableTypes))])]
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: { horizontal: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.loggers.detail.info.allowed_types"
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                type: "is-dark",
                                label: _vm.$t(
                                  "admin.component.loggers.detail.info.allowed_types_note"
                                ),
                                position: "is-right",
                                multilined: "",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  size: "is-small",
                                  icon: "question-circle",
                                  type: "is-grey",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "p",
                    [
                      _vm._v(" " + _vm._s(_vm.allowedTypes) + " "),
                      _vm.logger.currentlyPublishedAs
                        ? _c("b-icon", {
                            staticClass: "clickable",
                            attrs: { icon: "pencil-alt", size: "is-small" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openTypesModal()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.loggers.detail.info.coordinates"
                    ),
                  },
                },
                [
                  _vm.companyLogger.coordinates
                    ? [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "admin.component.loggers.detail.info.latitude"
                              )
                            ) +
                              ": " +
                              _vm._s(_vm.companyLogger.coordinates.Latitude)
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.loggers.detail.info.longitude"
                                )
                              ) +
                              ": " +
                              _vm._s(_vm.companyLogger.coordinates.Longitude) +
                              " "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.loggers.detail.info.altitude"
                                )
                              ) +
                              ": " +
                              _vm._s(_vm.companyLogger.coordinates.Altitude) +
                              " "
                          ),
                        ]),
                      ]
                    : _c("p", [_vm._v("-")]),
                ],
                2
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("admin.component.loggers.detail.info.note"),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t("admin.component.loggers.detail.info.note")
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t("admin.component.loggers.detail.info.note")
                    ),
                  },
                },
                [
                  !_vm.isEditing
                    ? _c("div", [
                        _vm.logger.note
                          ? _c("pre", [_vm._v(_vm._s(_vm.formattedJsonString))])
                          : _c(
                              "a",
                              {
                                staticClass: "is-italic",
                                on: {
                                  click: function ($event) {
                                    return _vm.openEdit()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.loggers.detail.info.add_note"
                                      )
                                    ) +
                                    "... "
                                ),
                              ]
                            ),
                      ])
                    : _c("b-input", {
                        attrs: {
                          type: "textarea",
                          name: _vm.$t(
                            "admin.component.loggers.detail.info.note"
                          ),
                          expanded: "",
                        },
                        model: {
                          value: _vm.noteEdit,
                          callback: function ($$v) {
                            _vm.noteEdit = $$v
                          },
                          expression: "noteEdit",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column px-0-mobile py-0-mobile" },
            [
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("admin.component.loggers.detail.info.mac"),
                  },
                },
                [_c("p", [_vm._v(" " + _vm._s(_vm.logger.macAddress) + " ")])]
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.loggers.detail.info.battery"
                    ),
                  },
                },
                [
                  _vm.logger.battery && _vm.logger.battery.unit
                    ? _c("p", [
                        _vm._v(
                          ">" +
                            _vm._s(_vm.logger.battery.value) +
                            _vm._s(_vm.logger.battery.unit)
                        ),
                      ])
                    : _vm.logger.rawBattery
                    ? _c("p", [_vm._v(_vm._s(_vm.logger.rawBattery) + "%")])
                    : _c("p", [_vm._v("-")]),
                ]
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("admin.component.loggers.detail.info.state"),
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `admin.component.loggers.state.${_vm.logger.state.toLowerCase()}`
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.loggers.detail.info.activated_at"
                    ),
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(_vm.companyLogger.activatedAt * 1000),
                            null,
                            null,
                            null
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.loggers.detail.info.deactivated_at"
                    ),
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(_vm.companyLogger.deactivatedAt * 1000),
                            null,
                            null,
                            null
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm.isEditing
          ? _c(
              "div",
              { staticClass: "has-text-centered" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary", disabled: !_vm.hasChanges },
                    on: {
                      click: function ($event) {
                        return _vm.saveChanges()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("generals.save_changes")) + " ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isTypesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isTypesModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("AllowedTypesModal", {
            attrs: {
              companyLogger: _vm.companyLogger,
              computedEventTypes: _vm.computedEventTypes,
            },
            on: {
              modalClosed: _vm.closeModal,
              typesSelected: _vm.typesSelected,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }