import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
let DevicesSettings = class DevicesSettings extends Vue {
    constructor() {
        super(...arguments);
        this.sequenceInterval = [];
    }
    loadData() {
        this.parseSequenceInterval();
    }
    created() {
        this.parseSequenceInterval();
    }
    parseSequenceInterval() {
        if (this.departmentSettings.Notifications.Buzzer.Sequence && this.departmentSettings) {
            let sequenceInterval = JSON.parse(this.departmentSettings.Notifications.Buzzer.Sequence);
            if (sequenceInterval && sequenceInterval.length > 0) {
                this.sequenceInterval = sequenceInterval;
            }
            else {
                this.sequenceInterval = [];
            }
        }
    }
    editSirenSequence() {
        this.$emit('openDeviceSirenModal');
    }
    deleteSirenSequence() {
        this.$emit('deleteSirenSequence');
    }
    sumArray(numbers) {
        return numbers.reduce((sum, num) => sum + num, 0);
    }
};
__decorate([
    Prop({ type: Object })
], DevicesSettings.prototype, "departmentSettings", void 0);
__decorate([
    Watch('departmentSettings')
], DevicesSettings.prototype, "loadData", null);
DevicesSettings = __decorate([
    Component({
        components: {}
    })
], DevicesSettings);
export default DevicesSettings;
