var SpreadFactorOptions;
(function (SpreadFactorOptions) {
    SpreadFactorOptions["SIX"] = "6";
    SpreadFactorOptions["SEVEN"] = "7";
    SpreadFactorOptions["EIGHT"] = "8";
    SpreadFactorOptions["NINE"] = "9";
    SpreadFactorOptions["TEN"] = "10";
    SpreadFactorOptions["ELEVEN"] = "11";
    SpreadFactorOptions["TWELVE"] = "12";
})(SpreadFactorOptions || (SpreadFactorOptions = {}));
export default SpreadFactorOptions;
