var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("h2", { staticClass: "subtitle is-4" }, [
      _vm._v(" " + _vm._s(_vm.$t("users.user_detail_title")) + " "),
    ]),
    _c("div", { staticClass: "columns columns-info-form label-grow-4" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-field",
            { attrs: { horizontal: "", label: _vm.$t("users.email") } },
            [_c("p", [_vm._v(" " + _vm._s(_vm.user.email) + " ")])]
          ),
          _c(
            "b-field",
            { attrs: { horizontal: "", label: _vm.$t("users.full_name") } },
            [_c("p", [_vm._v(" " + _vm._s(_vm.user.fullName) + " ")])]
          ),
          _c(
            "b-field",
            { attrs: { horizontal: "", label: _vm.$t("users.phone_number") } },
            [
              _vm.user.phoneNumber
                ? _c("p", [
                    _vm._v(" " + _vm._s(_vm.user.phoneNumber.Number) + " "),
                  ])
                : _c("p", [_vm._v("-")]),
            ]
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("users.phone_number_verified"),
              },
            },
            [
              _vm.user.phoneNumber && _vm.user.phoneNumber.Verified
                ? _c("i", { staticClass: "fas fa-check" })
                : _c("i", { staticClass: "fas fa-times" }),
            ]
          ),
          _c(
            "b-field",
            {
              staticClass: "combine-fields",
              attrs: { horizontal: "", label: _vm.$t("users.role") },
            },
            [
              _c("p", { staticClass: "mr-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `users.roles.department_roles.${_vm.user.role.Key}`
                      )
                    ) +
                    " "
                ),
              ]),
              _vm.user.role.Key != _vm.ApiUserRoles.COMPANY_OWNER &&
              _vm.user.role.Key != _vm.SystemUserRoles.SYSTEM_ADMIN &&
              _vm.user.email != _vm.username
                ? _c(
                    "b-button",
                    {
                      staticClass: "level-item",
                      attrs: { size: "is-small", type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.openChangeRoleModal(_vm.user)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.userAdministration.list.table.action_change_role"
                          )
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-field",
            {
              staticClass: "combine-fields",
              attrs: { horizontal: "", label: _vm.$t("users.enabled") },
            },
            [
              _vm.user.enabled
                ? _c("i", { staticClass: "fas fa-check mr-4 mt-1" })
                : _c("i", { staticClass: "fas fa-times mr-4 mt-1" }),
              _vm.user.email != _vm.username
                ? [
                    _vm.user.enabled &&
                    _vm.$ability.can(
                      _vm.$permActions.DISABLE,
                      _vm.$permSubjects.USERS
                    )
                      ? _c(
                          "b-button",
                          {
                            attrs: { size: "is-small", type: "is-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.openDisableDialog(_vm.user)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.userAdministration.list.table.action_disable"
                                )
                              )
                            ),
                          ]
                        )
                      : _vm.$ability.can(
                          _vm.$permActions.ENABLE,
                          _vm.$permSubjects.USERS
                        )
                      ? _c(
                          "b-button",
                          {
                            attrs: { size: "is-small", type: "is-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.enableUser(_vm.user)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.userAdministration.list.table.action_enable"
                                )
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "b-field",
            { attrs: { horizontal: "", label: _vm.$t("users.assigned_at") } },
            [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(_vm.user.assignedAt * 1000),
                        null,
                        null,
                        null
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }