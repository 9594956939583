var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _vm.isLayoutList
            ? _c("h2", { staticClass: "level-item subtitle is-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.detail.configHistory.title"
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: { position: "is-bottom-left", "aria-role": "list" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.company.list.actions.columns"
                                ),
                                position: "is-left",
                                type: "is-dark",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                { staticStyle: { "font-size": "1rem" } },
                                [
                                  _c("b-icon", {
                                    attrs: { type: "is-primary", icon: "list" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: column.field,
                      staticClass: "custom-dropdown-item",
                      attrs: {
                        focusable: false,
                        custom: "",
                        "aria-role": "listitem",
                      },
                    },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            disabled:
                              column.field == _vm.tableColumns.configId.field,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.chooseTableColumns(
                                column.field,
                                $event
                              )
                            },
                          },
                          model: {
                            value: column.visible,
                            callback: function ($$v) {
                              _vm.$set(column, "visible", $$v)
                            },
                            expression: "column.visible",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `admin.component.devices.detail.configHistory.table.${column.field}`
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "table-wrap",
          attrs: {
            data: _vm.device.configs,
            striped: "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
          },
          on: { sort: _vm.onSort },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.devices.detail.configHistory.table.empty"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.configId.field,
              label: _vm.$t(
                `admin.component.devices.detail.configHistory.table.${_vm.tableColumns.configId.field}`
              ),
              visible: _vm.tableColumns.configId.visible,
              sortable: _vm.tableColumns.configId.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (configs) {
                  return [
                    _c("span", { staticClass: "is-family-monospace" }, [
                      _vm._v(_vm._s(configs.row.configId)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.configHistory.table.${_vm.tableColumns.createdAt.field}`
              ),
              visible: _vm.tableColumns.createdAt.visible,
              sortable: _vm.tableColumns.createdAt.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (configs) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(configs.row.createdAt * 1000)
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.configuredAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.configHistory.table.${_vm.tableColumns.configuredAt.field}`
              ),
              visible: _vm.tableColumns.configuredAt.visible,
              sortable: _vm.tableColumns.configuredAt.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (configs) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(configs.row.configuredAt * 1000)
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.interval.field,
              label: _vm.$t(
                `admin.component.devices.detail.configHistory.table.${_vm.tableColumns.interval.field}`
              ),
              visible: _vm.tableColumns.interval.visible,
              sortable: _vm.tableColumns.interval.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (configs) {
                  return [_vm._v(_vm._s(configs.row.interval))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.current.field,
              label: _vm.$t(
                `admin.component.devices.detail.configHistory.table.${_vm.tableColumns.current.field}`
              ),
              visible: _vm.tableColumns.current.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (configs) {
                  return [
                    _c("b-icon", {
                      attrs: {
                        icon: _vm.isCurrentConfig(configs.row)
                          ? "check"
                          : "times",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }