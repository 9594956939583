import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import DecimalConversion from '@/services/DecimalConversion';
import MeasurementStatuses from '@/entities/enums/measurementStatuses';
import EventTypes from '@/entities/enums/EventTypes';
import FloorPlanEntities from '@/entities/enums/FloorPlanEntities';
import { arrayBufferToBase64 } from '@/services/FileManager';
import UserPreferences from '@/services/UserPreferences';
let FloorPlanMeasurements = class FloorPlanMeasurements extends Vue {
    constructor() {
        super(...arguments);
        this.MeasurementStatuses = MeasurementStatuses;
        this.image = new Image();
        this.ctx = null;
        this.changedFloorPlanData = [];
        this.keys = [];
        this.outOfBoundsmeasuredEvents = {};
        this.iconWidth = 16;
        this.touchStartX = 0;
        this.touchEndX = 0;
        this.minSwipeDistance = 50;
        this.xPoint = 0;
        this.yPoint = 0;
        this.textBox = null;
        this.maxTextBoxWidth = 400;
        this.canvasContainer = null;
    }
    async mounted() {
        await this.loadFloorPlan();
    }
    getNextFloorPlanId(direction) {
        let i = this.floorPlans.map((x) => x.id).indexOf(this.selectedFloorPlan.id);
        let id = this.floorPlans[this.getIndex(i, direction)].id;
        this.setSelectedFloorplan(id);
    }
    async loadImage() {
        if (this.selectedFloorPlan && this.selectedFloorPlanImage) {
            await new Promise((resolve, reject) => {
                this.image.src =
                    'data:' +
                        this.selectedFloorPlan.fileDetails.mimeType +
                        ';base64, ' +
                        arrayBufferToBase64(this.selectedFloorPlanImage);
                this.image.onload = resolve;
                this.image.onerror = reject;
            });
            this.image.setAttribute('crossOrigin', 'anonymous');
        }
    }
    async loadFloorPlan() {
        await this.loadImage();
        if (this.selectedFloorPlanLocationData && this.selectedFloorPlanImage) {
            this.canvas = document.getElementById(this.canvasId);
            this.ctx = this.canvas.getContext('2d');
            await new Promise((resolve, reject) => {
                this.image.src =
                    'data:' +
                        this.selectedFloorPlan.fileDetails.mimeType +
                        ';base64, ' +
                        arrayBufferToBase64(this.selectedFloorPlanImage);
                this.image.onload = resolve;
                this.image.onerror = reject;
            });
            this.image.setAttribute('crossOrigin', 'anonymous');
            // Get ref width to set canvas and image width
            let refWidth = null;
            if (this.canvas.clientWidth > 0) {
                refWidth = this.canvas.clientWidth;
            }
            else {
                let refElement = this.canvas;
                while (refElement.clientWidth == 0) {
                    refElement = refElement.parentElement;
                    if (refElement.clientWidth > 0) {
                        refWidth = refElement.clientWidth;
                        break;
                    }
                }
            }
            // Getting and apply percentage for creating responsive image
            let percentage = this.getPercentage(refWidth, this.image.width);
            let percentageForWidth = (this.image.width / 100) * percentage;
            let percentageForHeight = (this.image.height / 100) * percentage;
            this.canvas.width = this.image.width + percentageForWidth;
            this.canvas.height = this.image.height + percentageForHeight;
            this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
            this.changedFloorPlanData = JSON.parse(JSON.stringify(this.selectedFloorPlanLocationData));
            this.keys = Object.keys(this.lastEvents);
            if (this.isOldFormat()) {
                this.changedFloorPlanData.forEach((x) => {
                    x.coordinates[0] = (x.coordinates[0] / this.image.width) * this.canvas.width;
                    x.coordinates[1] = (x.coordinates[1] / this.image.height) * this.canvas.height;
                    this.drawData(x.entity, x.entityId, x.coordinates[0], x.coordinates[1]);
                });
            }
            else {
                this.changedFloorPlanData.forEach((x) => {
                    x.coordinates[0] = x.coordinates[0] * this.canvas.width;
                    x.coordinates[1] = x.coordinates[1] * this.canvas.height;
                    this.drawData(x.entity, x.entityId, x.coordinates[0], x.coordinates[1]);
                });
            }
        }
    }
    getPercentage(a, b) {
        return ((a - b) / b) * 100;
    }
    setSelectedFloorplan(id) {
        this.$emit('setSelectedFloorplan', id, this.isFullscreen);
    }
    /**
     * Check if coordinates are in old format
     */
    isOldFormat() {
        return this.changedFloorPlanData.filter((x) => x.coordinates[0] > 1).length > 0;
    }
    getTemperatureLabel(sourceId) {
        if (this.keys.includes(sourceId)) {
            const temperatureEvent = this.lastEvents[sourceId].find((t) => t.eventType.name.toLowerCase() === EventTypes.TEMPERATURE);
            if (temperatureEvent) {
                return [temperatureEvent.value.toString(), temperatureEvent.unit];
            }
        }
        return ['0', ''];
    }
    drawData(entity, entityId, posX, posY) {
        let sourceDeleted = false;
        let hiddenSource = false;
        let isRed = false;
        let eventData = [];
        let entityData = { name: '', width: 0 };
        if (this.isSourceEntity(entity)) {
            if (this.sources.filter((s) => s.id == entityId).length == 0) {
                let eventKeys = Object.keys(this.allLastEvents);
                hiddenSource = eventKeys.find((key) => key == entityId) ? true : false;
                sourceDeleted = !hiddenSource ? true : false;
            }
            eventData = this.getTemperatureLabel(entityId);
            isRed = this.checkOutOfBounds(entityId);
            entityData.name =
                hiddenSource || sourceDeleted
                    ? '?'
                    : eventData[0] !== '0'
                        ? (DecimalConversion.toCommaString(eventData[0]) + eventData[1]).toString()
                        : '-';
            entityData.width = this.ctx.measureText(entityData.name).width;
        }
        else {
            let device = this.getDevice(entityId);
            isRed = device?.isOnline ? false : true;
            entityData.width = this.iconWidth;
        }
        this.ctx.font = '15px Arial';
        this.ctx.fillStyle = this.getAppThemeColor(isRed);
        this.ctx.beginPath();
        this.ctx.roundRect(posX - entityData.width / 2 - 7, posY - 15, entityData.width + 14, parseInt(this.ctx.font, 10) + 13, 4);
        this.ctx.fill();
        this.ctx.fillStyle = 'white';
        this.ctx.textAlign = 'center';
        if (this.isSourceEntity(entity)) {
            this.ctx.fillText(entityData.name, posX, posY + 3);
        }
        else {
            const FontAwesome = new FontFace('FontAwesome', 
            // pass the url to the file in CSS url() notation
            'url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2")');
            document.fonts.add(FontAwesome); // add it to the document's FontFaceSet
            // wait the font loads
            FontAwesome.load()
                .then(() => {
                // we're good to use it
                this.ctx.fillStyle = '#ffffff';
                this.ctx.font = '15px FontAwesome';
                this.ctx.fillText('\uf2db', posX, posY + 5);
            })
                .catch(console.error);
        }
    }
    getAppThemeColor(isRed) {
        let theme = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.Theme);
        if (theme === 'dark')
            return this.getColor('#ff6d5a', '#9BDEAE', isRed);
        else
            return this.getColor('#be2528', '#48c78e', isRed);
    }
    getColor(red, green, isRed) {
        return isRed ? red : green;
    }
    redirect(e) {
        let pos = this.getCursorPosition(this.canvas, e);
        this.changedFloorPlanData.forEach((x) => {
            let eventData = [];
            let textWidth = null;
            let device = null;
            if (this.isSourceEntity(x.entity)) {
                eventData = this.getTemperatureLabel(x.entityId);
                textWidth = this.ctx.measureText((DecimalConversion.toCommaString(eventData[0]) + eventData[1]).toString()).width;
            }
            else {
                device = this.getDevice(x.entityId);
                textWidth = this.iconWidth;
            }
            if (pos.x > x.coordinates[0] - textWidth / 2 - 7 &&
                pos.x < x.coordinates[0] + textWidth / 2 + 7 &&
                pos.y > x.coordinates[1] - 15 &&
                pos.y < x.coordinates[1] + parseInt(this.ctx.font, 10)) {
                if (this.isSourceEntity(x.entity)) {
                    let sourceGroup = this.sourceGroups.find((group) => group.sources.find((source) => source.id == x.entityId));
                    this.$router
                        .push({
                        name: 'groupDashboard',
                        query: { groupId: sourceGroup ? (sourceGroup.id == null ? 'U' : sourceGroup.id) : 'A' },
                        params: { lang: this.$route.params.lang }
                    })
                        .catch((err) => err);
                }
                else {
                    this.$router
                        .push({
                        name: 'deviceConf',
                        params: {
                            deviceId: device.deviceId,
                            departmentId: this.$route.params.departmentId,
                            lang: this.$route.params.lang
                        }
                    })
                        .catch((err) => err);
                }
            }
        });
    }
    async displayName(e) {
        let pos = this.getCursorPosition(this.canvas, e);
        await this.loadFloorPlan();
        let isPointer = false;
        this.changedFloorPlanData.forEach((x) => {
            let sourceDeleted = false;
            let hiddenSource = false;
            let source = null;
            let eventData = [];
            let textWidth = null;
            let isRed = false;
            let device = null;
            this.ctx.font = '15px Arial';
            let boxPadding = 20;
            this.canvasContainer = document.getElementById('canvas-container' + this.canvasId);
            this.textBox = document.querySelector('.text-box' + this.canvasId);
            if (!this.textBox) {
                let newTextBox = document.createElement('div');
                newTextBox.classList.add('text-box', 'text-box' + this.canvasId);
                this.canvasContainer.appendChild(newTextBox);
            }
            if (this.isSourceEntity(x.entity)) {
                eventData = this.getTemperatureLabel(x.entityId);
                textWidth = this.ctx.measureText((DecimalConversion.toCommaString(eventData[0]) + eventData[1]).toString()).width;
                isRed = this.checkOutOfBounds(x.entityId);
                if (this.sources.find((s) => s.id == x.entityId)) {
                    source = this.sources.find((s) => s.id == x.entityId);
                }
                else {
                    let eventKeys = Object.keys(this.allLastEvents);
                    hiddenSource = eventKeys.find((key) => key == x.entityId) ? true : false;
                    sourceDeleted = hiddenSource ? false : true;
                }
            }
            else {
                device = this.devices.find((device) => device.deviceId == x.entityId);
                isRed = device?.isOnline ? false : true;
                textWidth = this.iconWidth;
            }
            if (pos.x > x.coordinates[0] - textWidth / 2 - 7 &&
                pos.x < x.coordinates[0] + textWidth / 2 + 7 &&
                pos.y > x.coordinates[1] - 15 &&
                pos.y < x.coordinates[1] + parseInt(this.ctx.font, 10)) {
                let entity = { name: '', width: 0 };
                if (this.isSourceEntity(x.entity)) {
                    if (sourceDeleted) {
                        entity.name = this.$t('component.floorPlan.deletedSource').toString();
                        entity.width = this.ctx.measureText(entity.name).width;
                    }
                    else if (hiddenSource) {
                        entity.name = this.$t('component.floorPlan.hiddenSource').toString();
                        entity.width = this.ctx.measureText(entity.name).width;
                    }
                    else {
                        entity.name = source.name;
                        entity.width = this.ctx.measureText(entity.name).width;
                    }
                }
                else {
                    entity.name = device.description ? device.description + ' (' + device.deviceName + ')' : device.deviceName;
                    entity.width = this.ctx.measureText(entity.name).width;
                }
                this.ctx.fillStyle = this.getAppThemeColor(isRed);
                this.ctx.beginPath();
                this.ctx.fill();
                this.ctx.fillStyle = 'white';
                if (this.maxTextBoxWidth > this.canvasContainer.clientWidth) {
                    this.maxTextBoxWidth = this.canvasContainer.clientWidth;
                }
                const lines = this.breakTextIntoLines(entity.name, this.maxTextBoxWidth);
                this.xPoint = x.coordinates[0];
                this.yPoint = x.coordinates[1] + 20;
                const maxTextWidth = Math.max(...lines.map((line) => this.ctx.measureText(line).width));
                if (this.textBox) {
                    this.textBox.textContent = lines.join('\n');
                    this.textBox.style.left = `${this.xPoint - boxPadding / 2 - maxTextWidth / 2}px`;
                    this.textBox.style.top = `${this.yPoint}px`;
                    this.textBox.style.width = `${maxTextWidth + boxPadding}px`;
                    this.textBox.style.display = 'block';
                }
                isPointer = true;
                this.adjustBoxPosition();
            }
            if (isPointer) {
                this.canvas.style.cursor = 'pointer';
            }
            else {
                this.canvas.style.cursor = 'auto';
                if (this.textBox)
                    this.textBox.style.display = 'none';
            }
        });
    }
    // Prevent the box from overlapping outside the screen width or height
    adjustBoxPosition() {
        const boxRect = this.textBox.getBoundingClientRect();
        // Adjust horizontal position
        if (boxRect.right > window.innerWidth) {
            this.xPoint = 0; // Set a minimum margin from the left edge
            this.textBox.style.right = `${this.xPoint}px`;
            this.textBox.style.left = `unset`;
        }
        if (boxRect.left < 0) {
            this.xPoint = 0; // Set a minimum margin from the left edge
            this.textBox.style.left = `${this.xPoint}px`;
            this.textBox.style.right = `unset`;
        }
        // Adjust vertical position
        if (boxRect.bottom > window.innerHeight) {
            this.yPoint = 0; // Set a minimum margin from the top edge
            this.textBox.style.bottom = `${this.yPoint}px`;
            this.textBox.style.top = `unset`;
        }
        if (boxRect.top < 0) {
            this.yPoint = 0; // Set a minimum margin from the top edge
            this.textBox.style.top = `${this.yPoint}px`;
            this.textBox.style.bottom = `unset`;
        }
    }
    breakTextIntoLines(text, maxWidth) {
        const words = text.split(' ');
        const lines = [];
        let currentLine = '';
        words.forEach((word) => {
            const testLine = currentLine ? currentLine + ' ' + word : word;
            const testWidth = this.ctx.measureText(testLine).width;
            // If the line exceeds maxWidth, start a new line
            if (testWidth > maxWidth && currentLine !== '') {
                lines.push(currentLine);
                currentLine = word;
            }
            else {
                currentLine = testLine;
            }
        });
        // Push the last line
        if (currentLine !== '') {
            lines.push(currentLine);
        }
        return lines;
    }
    // Record the starting touch position
    handleTouchStart(event) {
        this.touchStartX = event.touches[0].clientX;
    }
    // Record the ending touch position
    handleTouchEnd(event) {
        this.touchEndX = event.changedTouches[0].clientX;
        this.detectSwipe();
    }
    getIndex(i, direction) {
        let nextIndex = -1;
        if (direction === 'right') {
            nextIndex = (i + 1) % this.floorPlans.length;
        }
        else {
            nextIndex = (i - 1 + this.floorPlans.length) % this.floorPlans.length;
        }
        return nextIndex;
    }
    detectSwipe() {
        if (this.floorPlans.length > 1) {
            let deltaX = this.touchEndX - this.touchStartX;
            if (Math.abs(deltaX) > this.minSwipeDistance) {
                let i = this.floorPlans.indexOf(this.selectedFloorPlan);
                i = this.getIndex(i, deltaX > 0 ? 'right' : 'left');
                this.setSelectedFloorplan(this.floorPlans[i].id);
            }
        }
    }
    isSourceEntity(entity) {
        return entity == FloorPlanEntities.SOURCE ? true : false;
    }
    getDevice(entityId) {
        return this.devices.find((device) => device.deviceId == entityId);
    }
    checkOutOfBounds(sourceId) {
        if (this.keys.includes(sourceId)) {
            return this.lastEvents[sourceId].find((event) => event.status.name === MeasurementStatuses.ALARM.name &&
                event.eventType.type == 0 &&
                event.eventType.name.toLowerCase() === EventTypes.TEMPERATURE)
                ? true
                : false;
        }
        return true;
    }
    getCursorPosition(canvas, e) {
        let rect = canvas.getBoundingClientRect();
        return {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        };
    }
    openFullScreen() {
        this.$emit('toggleFloorplan', !this.isFullscreen);
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], FloorPlanMeasurements.prototype, "floorPlans", void 0);
__decorate([
    Prop({ type: Object })
], FloorPlanMeasurements.prototype, "selectedFloorPlan", void 0);
__decorate([
    Prop({ default: null })
], FloorPlanMeasurements.prototype, "selectedFloorPlanImage", void 0);
__decorate([
    Prop({ default: null })
], FloorPlanMeasurements.prototype, "selectedFloorPlanLocationData", void 0);
__decorate([
    Prop({ type: String })
], FloorPlanMeasurements.prototype, "canvasId", void 0);
__decorate([
    Prop({ type: Object })
], FloorPlanMeasurements.prototype, "lastEvents", void 0);
__decorate([
    Prop({ type: Object })
], FloorPlanMeasurements.prototype, "allLastEvents", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], FloorPlanMeasurements.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], FloorPlanMeasurements.prototype, "devices", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], FloorPlanMeasurements.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Boolean })
], FloorPlanMeasurements.prototype, "isFullscreen", void 0);
FloorPlanMeasurements = __decorate([
    Component({ components: {} })
], FloorPlanMeasurements);
export default FloorPlanMeasurements;
