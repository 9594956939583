import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UserPreferences from '@/services/UserPreferences';
import { DeviceState } from '@/entities/enums/DeviceState';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let AdminDeviceHistoryCredentials = class AdminDeviceHistoryCredentials extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.DeviceState = DeviceState;
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminDeviceCredentialsSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminDeviceCredentialsSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.AdminDeviceCredentialsPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminDeviceCredentialsRowsPerPage.defaultValue;
        this.total = 0;
        this.tableColumns = {
            publicKey: {
                field: 'publicKey',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCredentialsPublicKey)
            },
            keyId: {
                field: 'keyId',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCredentialsKeyId)
            },
            keyType: {
                field: 'keyType',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCredentialsKeyType)
            },
            createdAt: {
                field: 'createdAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCredentialsCreatedAt)
            },
            revokedAt: {
                field: 'revokedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminDeviceCredentialsRevokedAt)
            }
        };
    }
    historyCredentialsChanged() {
        this.total = this.historyCredentials.length;
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    onSort(field, order) {
        this.sortField = field;
        this.sortDirection = order;
    }
    revokeCredential() {
        this.$emit('revokeCredential');
    }
    newCredential() {
        this.$emit('newCredential');
    }
    clearFilter() {
        this.$refs.table.filters = {};
        this.page = 1;
    }
    // changing default sort field
    changeDefaultSortField(sortFieldName) {
        if (this.sortField == sortFieldName) {
            this.sortField = this.tableColumns.publicKey.field;
        }
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.keyId.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.keyId.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCredentialsKeyId, event);
                break;
            case this.tableColumns.keyType.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.keyType.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCredentialsKeyType, event);
                break;
            case this.tableColumns.createdAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.createdAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCredentialsCreatedAt, event);
                break;
            case this.tableColumns.revokedAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.revokedAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceCredentialsRevokedAt, event);
                break;
        }
        this.$nextTick(() => {
            this.$refs.table.initSort();
        });
    }
};
__decorate([
    Prop({ type: Object })
], AdminDeviceHistoryCredentials.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceHistoryCredentials.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceHistoryCredentials.prototype, "currentCredentials", void 0);
__decorate([
    Prop({ type: Array })
], AdminDeviceHistoryCredentials.prototype, "historyCredentials", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminDeviceHistoryCredentials.prototype, "isLayoutList", void 0);
__decorate([
    Watch('historyCredentials')
], AdminDeviceHistoryCredentials.prototype, "historyCredentialsChanged", null);
AdminDeviceHistoryCredentials = __decorate([
    Component({})
], AdminDeviceHistoryCredentials);
export default AdminDeviceHistoryCredentials;
