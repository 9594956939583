var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "level section-header-level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("h2", { staticClass: "level-item subtitle is-4" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("reports.request_list_title")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "level-item",
                      attrs: {
                        type: "is-primary",
                        disabled: _vm.selectedReports.length == 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteSelectedReports()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "reports.reports_remove_dialog.delete_selected"
                          )
                        )
                      ),
                    ]
                  ),
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.company.list.actions.columns"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    { staticStyle: { "font-size": "1rem" } },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "list",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.tableColumns, function (column) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: column.field,
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-checkbox",
                            {
                              attrs: {
                                disabled:
                                  column.field ==
                                    _vm.tableColumns.fileName.field ||
                                  column.field ==
                                    _vm.tableColumns.generatedAt.field,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.chooseTableColumns(
                                    column.field,
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: column.visible,
                                callback: function ($$v) {
                                  _vm.$set(column, "visible", $$v)
                                },
                                expression: "column.visible",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      `reports.request_list.${column.field}`
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "b-table",
            {
              ref: "table",
              staticClass: "table-wrap",
              attrs: {
                paginated: "",
                "per-page": _vm.pageSize,
                "current-page": _vm.page,
                data: _vm.reports,
                total: _vm.total,
                "default-sort-direction": _vm.sortDirection,
                "default-sort": _vm.sortField,
                striped: "",
                "row-class": (row, index) =>
                  row.isUpdated == true ? "new-row" : "",
                "page-input": true,
                "pagination-order": "is-centered",
                loading: _vm.tableIsLoading,
                "checked-rows": _vm.selectedReports,
                checkable: "",
              },
              on: {
                "update:currentPage": function ($event) {
                  _vm.page = $event
                },
                "update:current-page": function ($event) {
                  _vm.page = $event
                },
                "update:checkedRows": function ($event) {
                  _vm.selectedReports = $event
                },
                "update:checked-rows": function ($event) {
                  _vm.selectedReports = $event
                },
                sort: _vm.onSort,
              },
              scopedSlots: _vm._u([
                {
                  key: "bottom-left",
                  fn: function () {
                    return [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.notifications_list.table.row_per_page"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "b-field",
                        { staticClass: "pl-2 mb-4-mobile" },
                        [
                          _c(
                            "b-select",
                            {
                              model: {
                                value: _vm.pageSize,
                                callback: function ($$v) {
                                  _vm.pageSize = $$v
                                },
                                expression: "pageSize",
                              },
                            },
                            _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                              return _c(
                                "option",
                                { key: option, domProps: { value: option } },
                                [_vm._v(" " + _vm._s(option) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.from.field,
                  label: _vm.$t(
                    `reports.request_list.${_vm.tableColumns.from.field}`
                  ),
                  visible: _vm.tableColumns.from.visible,
                  sortable: _vm.tableColumns.from.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(props.row.from * 1000),
                              null,
                              null,
                              ""
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.to.field,
                  label: _vm.$t(
                    `reports.request_list.${_vm.tableColumns.to.field}`
                  ),
                  visible: _vm.tableColumns.to.visible,
                  sortable: _vm.tableColumns.to.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(props.row.to * 1000),
                              null,
                              null,
                              ""
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.fileName.field,
                  label: _vm.$t(
                    `reports.request_list.${_vm.tableColumns.fileName.field}`
                  ),
                  visible: _vm.tableColumns.fileName.visible,
                  sortable: _vm.tableColumns.fileName.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        props.row.state == _vm.ReportGenerationStatus.DONE
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadReport(
                                      props.row.fileName
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(props.row.fileName))]
                            )
                          : _c("span", [_vm._v(_vm._s(props.row.fileName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.reportType.field,
                  label: _vm.$t(
                    `reports.request_list.${_vm.tableColumns.reportType.field}`
                  ),
                  visible: _vm.tableColumns.reportType.visible,
                  sortable: _vm.tableColumns.reportType.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "b-tag",
                          {
                            attrs: {
                              rounded: "",
                              icon: _vm.getIcon(props.row.reportType),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `reports.report_mode.${props.row.reportType}`
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "stateTag.key",
                  label: _vm.$t(
                    `reports.request_list.${_vm.tableColumns.state.field}`
                  ),
                  visible: _vm.tableColumns.state.visible,
                  sortable: _vm.tableColumns.state.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "b-tag",
                          {
                            attrs: {
                              type: props.row.stateTag.type,
                              rounded: "",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `reports.request_types.${props.row.stateTag.key}`
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.generatedAt.field,
                  label: _vm.$t(
                    `reports.request_list.${_vm.tableColumns.generatedAt.field}`
                  ),
                  visible: _vm.tableColumns.generatedAt.visible,
                  sortable: _vm.tableColumns.generatedAt.visible,
                  "custom-sort": _vm.sortByGeneratedAt,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(props.row.generatedAt * 1000),
                              null,
                              null,
                              null
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "generatedBy.fullName",
                  label: _vm.$t(
                    `reports.request_list.${_vm.tableColumns.generatedBy.field}`
                  ),
                  visible: _vm.tableColumns.generatedBy.visible,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_vm._v(_vm._s(props.row.generatedBy.fullName))]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: { label: _vm.$t("reports.request_list.action") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t("reports.request_list.download"),
                              position: "is-left",
                              type: "is-light",
                              active: !_vm.isMobileView,
                            },
                          },
                          [
                            props.row.state === _vm.ReportGenerationStatus.DONE
                              ? _c("b-icon", {
                                  staticClass: "control-icon clickable",
                                  attrs: { icon: "file-download" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.downloadReport(
                                        props.row.fileName
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t("reports.request_list.regenerate"),
                              position: "is-left",
                              type: "is-light",
                            },
                          },
                          [
                            props.row.state ===
                              _vm.ReportGenerationStatus.INVALID &&
                            _vm.hasAllMetadata(props.row)
                              ? _c("b-icon", {
                                  staticClass: "control-icon clickable",
                                  attrs: { icon: "redo-alt" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.regenerateReport(props.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t("reports.request_list.more_info"),
                              position: "is-left",
                              type: "is-light",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "control-icon clickable",
                              attrs: { icon: "info" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.showDetailedInfo(props.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t("generals.remove"),
                              position: "is-left",
                              type: "is-light",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "control-icon clickable",
                              attrs: { icon: "trash" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.removeReport(props.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("section", { staticClass: "section" }, [
                  _c(
                    "div",
                    { staticClass: "content has-text-grey has-text-centered" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("reports.request_list.empty")) + " "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isReportDetailModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeReportDetailModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isReportDetailModalActive = $event
            },
            "close-modal": _vm.closeReportDetailModal,
          },
        },
        [
          _c("ReportDetailModal", {
            attrs: {
              report: _vm.selectedReport,
              sources: _vm.sources,
              dateTimeManager: _vm.dateTimeManager,
              eventTypes: _vm.eventTypes,
            },
            on: {
              downloadReport: _vm.downloadReport,
              removeReport: _vm.removeReport,
              modalClosed: _vm.closeReportDetailModal,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }