"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceNames = void 0;
var ServiceNames;
(function (ServiceNames) {
    ServiceNames["TEMPERATURE"] = "temperature";
    ServiceNames["DEWPOINT"] = "dewpoint";
    ServiceNames["HUMIDITY"] = "humidity";
    ServiceNames["UPTIME"] = "uptime";
    ServiceNames["ANNOTATION"] = "annotation";
    ServiceNames["ATMOSPHERICPRESSURE"] = "atmosphericpressure";
})(ServiceNames || (exports.ServiceNames = ServiceNames = {}));
