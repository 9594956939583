import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
let BackToTop = class BackToTop extends Vue {
    constructor() {
        super(...arguments);
        this.isScreenOnTop = true;
    }
    mounted() {
        // Listen for scroll events
        window.addEventListener('scroll', this.updateScrollY);
    }
    beforeDestroy() {
        // Clean up the listener to avoid memory leaks
        window.removeEventListener('scroll', this.updateScrollY);
    }
    updateScrollY() {
        this.isScreenOnTop = window.scrollY < window.innerHeight ? true : false;
    }
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Adds a smooth scrolling effect
        });
    }
};
BackToTop = __decorate([
    Component({
        components: {}
    })
], BackToTop);
export default BackToTop;
