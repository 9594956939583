var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "columns",
        class: { "is-desktop is-multiline": !_vm.isBasic },
      },
      [
        _c(
          "div",
          {
            staticClass: "column is-one-quarter is-one-quarter-widescreen",
            class: { "is-two-thirds-desktop": !_vm.isBasic },
          },
          [
            _c("h2", { staticClass: "subtitle mb-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("notifications.configuration.measured_title")) +
                  " "
              ),
            ]),
            _c(
              "p",
              {
                staticClass:
                  "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("notifications.configuration.measured_desc")
                    ) +
                    " "
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "column is-three-quarters-widescreen",
            class: { "is-full-desktop": !_vm.isBasic },
          },
          [
            _c(
              "b-field",
              { staticClass: "pr-3" },
              [
                _c(
                  "b-radio-button",
                  {
                    attrs: {
                      "native-value": _vm.NotificationMeasuredRuleScope.COMPANY,
                      size: "is-small",
                      type: "is-primary",
                    },
                    on: { input: _vm.modeChanged },
                    model: {
                      value: _vm.selectedMode,
                      callback: function ($$v) {
                        _vm.selectedMode = $$v
                      },
                      expression: "selectedMode",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("notifications.configuration.scope.department")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "b-radio-button",
                  {
                    attrs: {
                      "native-value":
                        _vm.NotificationMeasuredRuleScope.SOURCE_OR_GROUP,
                      size: "is-small",
                      type: "is-primary",
                    },
                    on: { input: _vm.modeChanged },
                    model: {
                      value: _vm.selectedMode,
                      callback: function ($$v) {
                        _vm.selectedMode = $$v
                      },
                      expression: "selectedMode",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "notifications.configuration.scope.loggers_groups"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "is-hidden-tablet" }, [
              _vm.isBasic
                ? _c(
                    "span",
                    {
                      staticClass:
                        "is-size-7 has-text-primary clickable-text-small",
                      on: {
                        click: function ($event) {
                          return _vm.changeDisplayMode(
                            _vm.NotificationRuleDisplayMode.ADVANCED
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("notifications.configuration.show_advanced")
                        )
                      ),
                      _c("b-icon", { attrs: { icon: "chevron-down" } }),
                    ],
                    1
                  )
                : _c(
                    "span",
                    {
                      staticClass:
                        "is-size-7 has-text-primary clickable-text-small",
                      on: {
                        click: function ($event) {
                          return _vm.changeDisplayMode(
                            _vm.NotificationRuleDisplayMode.BASIC
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("notifications.configuration.hide_advanced")
                        )
                      ),
                      _c("b-icon", { attrs: { icon: "chevron-up" } }),
                    ],
                    1
                  ),
            ]),
            _c("div", { staticClass: "b-table" }, [
              _c("div", { staticClass: "table-wrapper has-mobile-cards" }, [
                _c(
                  "table",
                  { staticClass: "table custom-notifications-table" },
                  [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "notifications.configuration.settings.entity"
                                )
                              )
                            ),
                          ]),
                          !_vm.isBasic
                            ? _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "notifications.configuration.settings.head"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.measuredType.channel, function (channel) {
                            return _c(
                              "th",
                              { key: channel.type + "_head" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "notification_channels." + channel.type
                                      )
                                    ) +
                                    " "
                                ),
                                _vm.isPhoneChannel(channel.type) &&
                                _vm.phoneIsNotDefined
                                  ? _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "notifications.configuration.alert_phone_not_defined"
                                          ),
                                          position: "is-top",
                                          type: "is-dark",
                                          multilined: "",
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            type: "is-primary",
                                            icon: "exclamation-triangle",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm.isPhoneChannel(channel.type) &&
                                    _vm.phoneIsNotVerified
                                  ? _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "notifications.configuration.alert_phone_not_verified"
                                          ),
                                          position: "is-top",
                                          type: "is-dark",
                                          multilined: "",
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            type: "is-primary",
                                            icon: "exclamation-triangle",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("tfoot", { staticClass: "is-hidden-mobile" }, [
                      _c("tr", [
                        _c("td", { attrs: { colspan: _vm.numberOfColumns } }, [
                          _c("div", { staticClass: "has-text-right" }, [
                            _vm.isBasic
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "is-size-7 has-text-primary clickable-text-small",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeDisplayMode(
                                          _vm.NotificationRuleDisplayMode
                                            .ADVANCED
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "notifications.configuration.show_advanced"
                                        )
                                      )
                                    ),
                                    _c("b-icon", {
                                      attrs: { icon: "chevron-down" },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass:
                                      "is-size-7 has-text-primary clickable-text-small",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeDisplayMode(
                                          _vm.NotificationRuleDisplayMode.BASIC
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "notifications.configuration.hide_advanced"
                                        )
                                      )
                                    ),
                                    _c("b-icon", {
                                      attrs: { icon: "chevron-up" },
                                    }),
                                  ],
                                  1
                                ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.measuredRules, function (currentRule) {
                          return [
                            _c(
                              "div",
                              {
                                key: currentRule.scopeId + "_title",
                                staticClass:
                                  "sticky-header is-hidden-tablet is-flex is-justify-content-space-between",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(currentRule.scopeName) + " "
                                ),
                                _c("b-switch", {
                                  attrs: { type: "is-primary" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.save(currentRule)
                                    },
                                  },
                                  model: {
                                    value: currentRule.enabled,
                                    callback: function ($$v) {
                                      _vm.$set(currentRule, "enabled", $$v)
                                    },
                                    expression: "currentRule.enabled",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "tr",
                              { key: currentRule.scopeId + "_ch" },
                              [
                                _c("td", { staticClass: "is-hidden-mobile" }, [
                                  _c(
                                    "div",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(currentRule.scopeName) +
                                          " "
                                      ),
                                      _c("b-switch", {
                                        attrs: {
                                          type: "is-primary",
                                          size: "is-small",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.save(currentRule)
                                          },
                                        },
                                        model: {
                                          value: currentRule.enabled,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              currentRule,
                                              "enabled",
                                              $$v
                                            )
                                          },
                                          expression: "currentRule.enabled",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                !_vm.isBasic
                                  ? _c("td", [
                                      _c("div", [
                                        _c(
                                          "h3",
                                          {
                                            staticClass: "has-text-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "notifications.configuration.settings.channel_title"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "notifications.configuration.settings.channel_desc"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._l(
                                  currentRule.typeSettings.channel,
                                  function (channel) {
                                    return _c(
                                      "td",
                                      {
                                        key: channel.type + "_switch",
                                        attrs: {
                                          "data-label": _vm.$t(
                                            "notification_channels." +
                                              channel.type
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-switch", {
                                          attrs: {
                                            type: "is-primary",
                                            disabled:
                                              _vm.isPhoneChannel(
                                                channel.type
                                              ) && !_vm.phoneChannelsAvailable,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.save(currentRule)
                                            },
                                          },
                                          model: {
                                            value:
                                              currentRule.channels[channel.type]
                                                .enabled,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                currentRule.channels[
                                                  channel.type
                                                ],
                                                "enabled",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentRule.channels[channel.type].enabled",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            !_vm.isBasic
                              ? _c(
                                  "tr",
                                  { key: currentRule.scopeId + "_d" },
                                  [
                                    _c("td"),
                                    _c("td", [
                                      _c("div", [
                                        _c(
                                          "h3",
                                          {
                                            staticClass: "has-text-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "notifications.configuration.settings.delay_title"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "notifications.configuration.settings.delay_desc"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._l(
                                      currentRule.typeSettings.channel,
                                      function (channel) {
                                        return _c(
                                          "td",
                                          {
                                            key: channel.type + "_delay",
                                            attrs: {
                                              "data-label": _vm.$t(
                                                "notification_channels." +
                                                  channel.type
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-field",
                                              [
                                                _c(
                                                  "b-select",
                                                  {
                                                    staticClass:
                                                      "fullwidth-select",
                                                    attrs: {
                                                      name:
                                                        _vm.$t(
                                                          "notifications.configuration.settings.delay_title"
                                                        ) +
                                                        " (" +
                                                        _vm.$t(
                                                          "notification_channels." +
                                                            channel.type
                                                        ) +
                                                        ")",
                                                      placeholder: _vm.$t(
                                                        "notifications.configuration.settings.delay_title"
                                                      ),
                                                      disabled:
                                                        !currentRule.channels[
                                                          channel.type
                                                        ].enabled ||
                                                        (_vm.isPhoneChannel(
                                                          channel.type
                                                        ) &&
                                                          !_vm.phoneChannelsAvailable),
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.save(
                                                          currentRule
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        currentRule.channels[
                                                          channel.type
                                                        ].delay,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          currentRule.channels[
                                                            channel.type
                                                          ],
                                                          "delay",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "currentRule.channels[channel.type].delay",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 0 },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.values.delay_off"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.delayOptions,
                                                      function (option) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: option,
                                                            domProps: {
                                                              value: option,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "notifications.configuration.values." +
                                                                      option
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !_vm.isBasic
                              ? _c(
                                  "tr",
                                  { key: currentRule.scopeId + "_r" },
                                  [
                                    _c("td"),
                                    _c("td", [
                                      _c("div", [
                                        _c(
                                          "h3",
                                          {
                                            staticClass: "has-text-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "notifications.configuration.settings.repeat_title"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "notifications.configuration.settings.repeat_desc"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._l(
                                      currentRule.typeSettings.channel,
                                      function (channel) {
                                        return _c(
                                          "td",
                                          {
                                            key: channel.type + "_repeat",
                                            attrs: {
                                              "data-label": _vm.$t(
                                                "notification_channels." +
                                                  channel.type
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-field",
                                              [
                                                _c(
                                                  "b-select",
                                                  {
                                                    staticClass:
                                                      "fullwidth-select",
                                                    attrs: {
                                                      name:
                                                        _vm.$t(
                                                          "notifications.configuration.settings.repeat_title"
                                                        ) +
                                                        " (" +
                                                        _vm.$t(
                                                          "notification_channels." +
                                                            channel.type
                                                        ) +
                                                        ")",
                                                      placeholder: _vm.$t(
                                                        "notifications.configuration.settings.repeat_title"
                                                      ),
                                                      disabled:
                                                        !currentRule.channels[
                                                          channel.type
                                                        ].enabled ||
                                                        (_vm.isPhoneChannel(
                                                          channel.type
                                                        ) &&
                                                          !_vm.phoneChannelsAvailable),
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.save(
                                                          currentRule
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        currentRule.channels[
                                                          channel.type
                                                        ].repeat,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          currentRule.channels[
                                                            channel.type
                                                          ],
                                                          "repeat",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "currentRule.channels[channel.type].repeat",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: -1 },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.values.repeat_off"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.RepeatOptions.getValues(),
                                                      function (option) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: option,
                                                            domProps: {
                                                              value: option,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "notifications.configuration.values." +
                                                                      option
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !_vm.isBasic
                              ? _c(
                                  "tr",
                                  { key: currentRule.scopeId + "_onR" },
                                  [
                                    _c("td"),
                                    _c(
                                      "td",
                                      {
                                        class: {
                                          "pb-6":
                                            currentRule.scope !==
                                            _vm.NotificationScopeType
                                              .SOURCE_GROUP,
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "has-text-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.settings.resolve_title"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.settings.resolve_desc_measured"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._l(
                                      currentRule.typeSettings.channel,
                                      function (channel) {
                                        return _c(
                                          "td",
                                          {
                                            key: channel.type + "_resolve",
                                            attrs: {
                                              "data-label": _vm.$t(
                                                "notification_channels." +
                                                  channel.type
                                              ),
                                            },
                                          },
                                          [
                                            _vm.channelForTypeHasOnResolve(
                                              currentRule.typeSettings,
                                              channel.type
                                            )
                                              ? _c("b-switch", {
                                                  attrs: {
                                                    type: "is-primary",
                                                    disabled:
                                                      !currentRule.channels[
                                                        channel.type
                                                      ].enabled ||
                                                      (_vm.isPhoneChannel(
                                                        channel.type
                                                      ) &&
                                                        !_vm.phoneChannelsAvailable),
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.save(
                                                        currentRule
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      currentRule.channels[
                                                        channel.type
                                                      ].onResolve,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        currentRule.channels[
                                                          channel.type
                                                        ],
                                                        "onResolve",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "currentRule.channels[channel.type].onResolve",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !_vm.isBasic &&
                            currentRule.scope ===
                              _vm.NotificationScopeType.SOURCE_GROUP
                              ? _c(
                                  "tr",
                                  { key: currentRule.scopeId + "_format" },
                                  [
                                    _c("td"),
                                    _c("td", [
                                      _c("div", [
                                        _c(
                                          "h3",
                                          {
                                            staticClass: "has-text-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "notifications.configuration.format_title"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "notifications.configuration.format_desc"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("td", { staticClass: "pb-6" }, [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "b-field",
                                            { staticClass: "pr-3" },
                                            [
                                              _c(
                                                "b-radio-button",
                                                {
                                                  attrs: {
                                                    "native-value":
                                                      _vm
                                                        .NotificationSubscriptionType
                                                        .MEASURED_DATA_ALARM,
                                                    type: "is-primary ",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.save(
                                                        currentRule
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: currentRule.type,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        currentRule,
                                                        "type",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "currentRule.type",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "notifications.configuration.format.single"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-radio-button",
                                                {
                                                  attrs: {
                                                    "native-value":
                                                      _vm
                                                        .NotificationSubscriptionType
                                                        .GROUP_MEASURED_DATA_ALARM,
                                                    type: "is-primary",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.save(
                                                        currentRule
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: currentRule.type,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        currentRule,
                                                        "type",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "currentRule.type",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "notifications.configuration.format.group"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "exclamation-triangle",
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.format_note"
                                                    )
                                                  )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._l(
                                      currentRule.typeSettings.channel.length -
                                        1,
                                      function (n) {
                                        return _c("td", {
                                          key: n + "_empty",
                                          staticClass: "is-hidden-mobile",
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "has-text-right" }, [
      _vm.requestPending
        ? _c(
            "span",
            {
              staticClass:
                "has-text-warning has-text-weight-semibold is-size-4",
            },
            [_vm._v(" " + _vm._s(_vm.$t("generals.saving_changes")) + " ")]
          )
        : _vm.requestFinished
        ? _c(
            "span",
            {
              staticClass:
                "has-text-success successfully-saved has-text-weight-semibold is-size-4",
              class: { "hide-opacity": _vm.requestFinished },
            },
            [_vm._v(" " + _vm._s(_vm.$t("generals.changes_saved")) + " ")]
          )
        : _vm.requestError
        ? _c(
            "span",
            {
              staticClass: "has-text-danger has-text-weight-semibold is-size-4",
              class: { "hide-opacity": _vm.requestFinished },
            },
            [_vm._v(" " + _vm._s(_vm.$t("generals.changes_saved_error")) + " ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }