var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "div",
      [
        _c("div", { staticClass: "level section-header-level" }, [
          _c("div", { staticClass: "level-left" }, [
            _vm.isLayoutList
              ? _c("h2", { staticClass: "level-item subtitle is-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.reseller.detail.resellerDepartments.title"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "level-right" }, [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "level-item is-hidden-mobile",
                    attrs: { type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.clearFilter()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "aria-role": "list",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "trigger",
                            fn: function () {
                              return [
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "admin.component.company.list.actions.columns"
                                      ),
                                      position: "is-left",
                                      type: "is-dark",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      { staticStyle: { "font-size": "1rem" } },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            type: "is-primary",
                                            icon: "list",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      _vm._l(_vm.tableColumns, function (column) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: column.field,
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              focusable: false,
                              custom: "",
                              "aria-role": "listitem",
                            },
                          },
                          [
                            _c(
                              "b-checkbox",
                              {
                                attrs: {
                                  disabled:
                                    column.field ==
                                    _vm.tableColumns.companyName.field,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.chooseTableColumns(
                                      column.field,
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: column.visible,
                                  callback: function ($$v) {
                                    _vm.$set(column, "visible", $$v)
                                  },
                                  expression: "column.visible",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `admin.component.reseller.detail.resellerDepartments.list.${column.field}`
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "b-table",
          {
            ref: "table",
            staticClass: "mobile-filter res-table",
            attrs: {
              data: _vm.departments,
              paginated: "",
              "backend-pagination": "",
              "pagination-order": "is-centered",
              striped: "",
              "per-page": _vm.pageSize,
              total: _vm.totalSize,
              "current-page": _vm.page,
              "backend-sorting": "",
              "default-sort-direction": _vm.sortDirection,
              "default-sort": _vm.sortField,
              "page-input": true,
              "backend-filtering": "",
            },
            on: {
              "page-change": _vm.onPageChange,
              "update:currentPage": function ($event) {
                _vm.page = $event
              },
              "update:current-page": function ($event) {
                _vm.page = $event
              },
              sort: _vm.onSort,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("section", { staticClass: "section" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "content has-text-grey has-text-centered",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.reseller.detail.resellerDepartments.list.empty"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "bottom-left",
                  fn: function () {
                    return [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.notifications_list.table.row_per_page"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "b-field",
                        { staticClass: "pl-2 mb-4-mobile" },
                        [
                          _c(
                            "b-select",
                            {
                              on: { input: _vm.rowsPerPageSelected },
                              model: {
                                value: _vm.pageSize,
                                callback: function ($$v) {
                                  _vm.pageSize = $$v
                                },
                                expression: "pageSize",
                              },
                            },
                            _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                              return _c(
                                "option",
                                { key: option, domProps: { value: option } },
                                [_vm._v(" " + _vm._s(option) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.departments.length > 0
                  ? {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "is-flex is-justify-content-flex-end",
                            },
                            [
                              _c("p", { staticClass: "pt-2 has-text-grey" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.company.list.table.results",
                                        {
                                          from: _vm.fromTableData,
                                          to: _vm.toTableData,
                                          total: _vm.totalSize,
                                        }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          [
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.companyId.field,
                label: _vm.$t(
                  `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.companyId.field}`
                ),
                visible: _vm.tableColumns.companyId.visible,
                sortable: _vm.tableColumns.companyId.visible,
                searchable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isIdSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.reseller.detail.resellerDepartments.list.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    expanded: "",
                                    type: {
                                      "is-danger": _vm.errors.has(
                                        _vm.$t(
                                          `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.companyId.field}`
                                        )
                                      ),
                                    },
                                    message: _vm.errors.first(
                                      _vm.$t(
                                        `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.companyId.field}`
                                      )
                                    ),
                                  },
                                },
                                [
                                  _c("b-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "min:36",
                                        expression: "'min:36'",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      name: _vm.$t(
                                        `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.companyId.field}`
                                      ),
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.filterTable(
                                          _vm.tableColumns.companyId.field,
                                          _vm.idSearch
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.idSearch,
                                      callback: function ($$v) {
                                        _vm.idSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "idSearch",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "buttons" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "level-item",
                                          attrs: { type: "is-primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearFilter()
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "times-circle" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-hidden-mobile is-full-width",
                          attrs: { grouped: "" },
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                expanded: "",
                                type: {
                                  "is-danger": _vm.errors.has(
                                    _vm.$t(
                                      `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.companyId.field}`
                                    )
                                  ),
                                },
                                message: _vm.errors.first(
                                  _vm.$t(
                                    `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.companyId.field}`
                                  )
                                ),
                              },
                            },
                            [
                              _c("b-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "min:36",
                                    expression: "'min:36'",
                                  },
                                ],
                                attrs: {
                                  name: _vm.$t(
                                    `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.companyId.field}`
                                  ),
                                  type: "text",
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.filterTable(
                                      _vm.tableColumns.companyId.field,
                                      _vm.idSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.idSearch,
                                  callback: function ($$v) {
                                    _vm.idSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "idSearch",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (departments) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "is-family-monospace",
                          attrs: {
                            to: {
                              name: "adminCompanyDetail",
                              params: {
                                departmentId: departments.row.companyId,
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                          nativeOn: {
                            contextmenu: function ($event) {
                              return _vm.copyLinkToClipboard(
                                departments.row.companyId
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(departments.row.companyId))]
                      ),
                      _vm.hasCopyIconAllowed
                        ? _c("b-icon", {
                            staticClass: "clickable is-size-7 is-hidden-touch",
                            attrs: { icon: "clipboard" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.copyLinkToClipboard(
                                  departments.row.companyId
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.companyName.field,
                label: _vm.$t(
                  `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.companyName.field}`
                ),
                visible: _vm.tableColumns.companyName.visible,
                sortable: _vm.tableColumns.companyName.visible,
                searchable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isNameSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.reseller.detail.resellerDepartments.list.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("b-input", {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.filterTable(
                                      _vm.tableColumns.companyName.field,
                                      _vm.nameSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.nameSearch,
                                  callback: function ($$v) {
                                    _vm.nameSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "nameSearch",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        { staticClass: "is-hidden-mobile" },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: _vm.$t("generals.search"),
                              "icon-pack": "fas",
                              icon: "search",
                              expanded: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.filterTable(
                                  _vm.tableColumns.companyName.field,
                                  _vm.nameSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.nameSearch,
                              callback: function ($$v) {
                                _vm.nameSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "nameSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (departments) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminCompanyDetail",
                              params: {
                                departmentId: departments.row.companyId,
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(departments.row.companyName))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.ico.field,
                label: _vm.$t(
                  `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.ico.field}`
                ),
                visible: _vm.tableColumns.ico.visible,
                sortable: _vm.tableColumns.ico.visible,
                searchable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isIcoSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.reseller.detail.resellerDepartments.list.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("b-input", {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.filterTable(
                                      _vm.tableColumns.ico.field,
                                      _vm.icoSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.icoSearch,
                                  callback: function ($$v) {
                                    _vm.icoSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "icoSearch",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        { staticClass: "is-hidden-mobile" },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: _vm.$t("generals.search"),
                              "icon-pack": "fas",
                              icon: "search",
                              expanded: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.filterTable(
                                  _vm.tableColumns.ico.field,
                                  _vm.icoSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.icoSearch,
                              callback: function ($$v) {
                                _vm.icoSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "icoSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (companies) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(companies.row.ico ? companies.row.ico : "-") +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.tierName.field,
                label: _vm.$t(
                  `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.tierName.field}`
                ),
                visible: _vm.tableColumns.tierName.visible,
                sortable: _vm.tableColumns.tierName.visible,
                searchable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isTierSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.reseller.detail.resellerDepartments.list.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("b-input", {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.filterTable(
                                      _vm.tableColumns.tierName.field,
                                      _vm.tierSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.tierSearch,
                                  callback: function ($$v) {
                                    _vm.tierSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "tierSearch",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        { staticClass: "is-hidden-mobile" },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: _vm.$t("generals.search"),
                              "icon-pack": "fas",
                              icon: "search",
                              expanded: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.filterTable(
                                  _vm.tableColumns.tierName.field,
                                  _vm.tierSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.tierSearch,
                              callback: function ($$v) {
                                _vm.tierSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "tierSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (companies) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            companies.row.tierName
                              ? companies.row.tierName
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.userCount.field,
                label: _vm.$t(
                  `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.userCount.field}`
                ),
                visible: _vm.tableColumns.userCount.visible,
                sortable: _vm.tableColumns.userCount.visible,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (companies) {
                    return [_vm._v(_vm._s(companies.row.userCount))]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.deviceCount.field,
                label: _vm.$t(
                  `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.deviceCount.field}`
                ),
                visible: _vm.tableColumns.deviceCount.visible,
                sortable: _vm.tableColumns.deviceCount.visible,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (companies) {
                    return [_vm._v(_vm._s(companies.row.deviceCount))]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.loggerCount.field,
                label: _vm.$t(
                  `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.loggerCount.field}`
                ),
                visible: _vm.tableColumns.loggerCount.visible,
                sortable: _vm.tableColumns.loggerCount.visible,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (companies) {
                    return [_vm._v(_vm._s(companies.row.loggerCount))]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.createdAt.field,
                label: _vm.$t(
                  `admin.component.reseller.detail.resellerDepartments.list.${_vm.tableColumns.createdAt.field}`
                ),
                visible: _vm.tableColumns.createdAt.visible,
                sortable: _vm.tableColumns.createdAt.visible,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (companies) {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(companies.row.createdAt * 1000),
                            null,
                            null,
                            null
                          )
                        )
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "action",
                label: _vm.$t(
                  "admin.component.reseller.detail.resellerDepartments.list.action"
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (departments) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "is-flex is-flex-direction-row is-align-items-center",
                        },
                        [
                          _c("b-icon", {
                            staticClass: "control-icon clickable",
                            attrs: { icon: "trash" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.actionSelect(departments.row)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }