import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IssueType } from '@/entities/enums/IssueType';
let StatusCardsMobile = class StatusCardsMobile extends Vue {
    constructor() {
        super(...arguments);
        this.IssueType = IssueType;
    }
    get offlineSources() {
        return this.departmentIssues.filter((x) => x.type == IssueType.SOURCE_OFFLINE).length;
    }
    get offlineDevices() {
        return this.departmentIssues.filter((x) => x.type == IssueType.DEVICE_OFFLINE).length;
    }
    get warningSources() {
        return this.departmentIssues.filter((x) => x.type == IssueType.ALARM && x.metadata.level == 'Warning').length;
    }
    get alarmSources() {
        return this.departmentIssues.filter((x) => x.type == IssueType.ALARM && x.metadata.level == 'Alarm').length;
    }
    get outOfBoundsCount() {
        return this.alarmSources + this.warningSources;
    }
    get withBatteryUnder20() {
        return this.departmentIssues.filter((x) => x.type == IssueType.LOW_BATTERY && x.metadata.batteryLevel !== null)
            .length;
    }
    get withBatteryOk() {
        return this.sources.filter((x) => x.logger && !x.logger.hasLowBattery() && x.logger.battery !== null).length;
    }
    get withBatteryUnknown() {
        return this.sources.filter((x) => (x.logger && x.logger.battery == null) || !x.logger).length;
    }
};
__decorate([
    Prop({ type: Number, default: 0 })
], StatusCardsMobile.prototype, "devicesCount", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], StatusCardsMobile.prototype, "sourcesCount", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], StatusCardsMobile.prototype, "activeLoggersCount", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], StatusCardsMobile.prototype, "notificationCount", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], StatusCardsMobile.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], StatusCardsMobile.prototype, "companyLoggerExpirationList", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => {
            return [];
        }
    })
], StatusCardsMobile.prototype, "departmentIssues", void 0);
StatusCardsMobile = __decorate([
    Component
], StatusCardsMobile);
export default StatusCardsMobile;
