var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card logger-modal-header" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(" " + _vm._s(_vm.$t("reports.request_detail_title")) + " "),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "component.report.detail_table.report_export.range"
              ),
            },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.dateTimeManager.formatTime(
                    new Date(_vm.report.from * 1000),
                    null,
                    null,
                    null
                  )
                ) +
                  " - " +
                  _vm._s(
                    _vm.dateTimeManager.formatTime(
                      new Date(_vm.report.to * 1000),
                      null,
                      null,
                      null
                    )
                  )
              ),
            ]),
            _c(
              "b-tooltip",
              {
                attrs: {
                  label: _vm.$t("generals.remove"),
                  position: "is-left",
                  type: "is-light",
                },
              },
              [
                _c("b-icon", {
                  staticClass: "control-icon clickable",
                  attrs: { icon: "trash" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.removeReport(_vm.report)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t("reports.request_list.fileName"),
            },
          },
          [_c("span", [_vm._v(" " + _vm._s(_vm.report.fileName) + " ")])]
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t("reports.request_list.generatedAt"),
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.dateTimeManager.formatTime(
                    new Date(_vm.report.generatedAt * 1000),
                    null,
                    null,
                    null
                  )
                ) +
                " "
            ),
          ]
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t("reports.request_list.state"),
            },
          },
          [
            _c(
              "span",
              [
                _c(
                  "b-tag",
                  { attrs: { type: _vm.report.stateTag.type, rounded: "" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          `reports.request_types.${_vm.report.stateTag.key}`
                        )
                      )
                    ),
                  ]
                ),
                _vm.report.state == _vm.ReportGenerationStatus.INVALID
                  ? _c("span", { staticClass: "is-italic has-text-grey" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("reports.records_regenerated")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t("reports.request_list.generatedBy"),
            },
          },
          [_vm._v(" " + _vm._s(_vm.report.generatedBy.fullName) + " ")]
        ),
        _vm.report.metadata.toggles
          ? [
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "component.report.detail_table.report_export.pdf_export.records"
                    ),
                  },
                },
                [
                  _vm.report.metadata.toggles.showRecordsTable === true &&
                  _vm.report.metadata.toggles.alarmOnlyRecords === false
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.detail_table.report_export.pdf_export.records_table.all"
                              )
                            )
                        ),
                      ])
                    : _vm.report.metadata.toggles.showRecordsTable === true &&
                      _vm.report.metadata.toggles.alarmOnlyRecords === true
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.detail_table.report_export.pdf_export.records_table.only_out_of"
                              )
                            )
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.report.detail_table.report_export.pdf_export.records_table.none"
                              )
                            )
                        ),
                      ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "component.report.detail_table.report_export.pdf_export.chart"
                    ),
                  },
                },
                [
                  _vm.report.metadata.toggles.showChart == true
                    ? _c("b-icon", { attrs: { icon: "check" } })
                    : _c("b-icon", { attrs: { icon: "times" } }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "component.report.detail_table.report_export.pdf_export.annotations"
                    ),
                  },
                },
                [
                  _vm.report.metadata.toggles.showAnnotationsTable
                    ? _c("b-icon", { attrs: { icon: "check" } })
                    : _c("b-icon", { attrs: { icon: "times" } }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "component.report.detail_table.report_export.pdf_export.show_empty_records"
                    ),
                  },
                },
                [
                  _vm.report.metadata.toggles.showEmpty
                    ? _c("b-icon", { attrs: { icon: "check" } })
                    : _c("b-icon", { attrs: { icon: "times" } }),
                ],
                1
              ),
            ]
          : _vm._e(),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "component.report.detail_table.report_export.eventTypes"
              ),
            },
          },
          [
            _vm.typesAreObjects
              ? _c(
                  "ul",
                  _vm._l(_vm.report.metadata.types, function (type) {
                    return _c("li", { key: type.Slug }, [
                      _vm._v(_vm._s(_vm.$t("services." + type.Slug))),
                    ])
                  }),
                  0
                )
              : _c(
                  "ul",
                  _vm._l(_vm.report.metadata.types, function (type) {
                    return _c("li", { key: type }, [
                      _vm._v(_vm._s(_vm.$t("services." + type))),
                    ])
                  }),
                  0
                ),
          ]
        ),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t("reports.request_list.sources"),
              horizontal: "",
            },
          },
          [
            _c(
              "b-table",
              {
                staticClass: "select-location-table",
                attrs: {
                  data: _vm.sourcesInReport,
                  paginated: true,
                  "per-page": 5,
                  narrowed: "",
                  "mobile-cards": false,
                },
              },
              [
                _c("b-table-column", {
                  attrs: {
                    field: "name",
                    label: _vm.$t(
                      "component.notification.create_rule.entity_table.name"
                    ),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (sources) {
                        return [_c("span", [_vm._v(_vm._s(sources.row.name))])]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      2
    ),
    _c(
      "footer",
      { staticClass: "modal-card-foot is-block has-text-centered" },
      [
        _c("div", { staticClass: "level is-mobile" }, [
          _c(
            "div",
            { staticClass: "level-left" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item",
                  on: {
                    click: function ($event) {
                      return _vm.resultRetrieved(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.close")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "level-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item",
                  attrs: {
                    disabled:
                      _vm.report.state !== _vm.ReportGenerationStatus.DONE,
                    type: "is-primary",
                  },
                  on: { click: _vm.downloadReport },
                },
                [_vm._v(_vm._s(_vm.$t("reports.request_list.download")))]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }