var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
      },
      [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.device.devicesSettings.title")) + " "
          ),
        ]),
      ]
    ),
    _c(
      "section",
      { staticClass: "pt-4" },
      [
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t("component.device.devicesSettings.sirenIntervals"),
            },
          },
          [
            _c(
              "div",
              { staticClass: "is-flex" },
              [
                _vm.sequenceInterval.length > 1
                  ? [
                      _c(
                        "p",
                        _vm._l(_vm.sequenceInterval, function (value, index) {
                          return _c("span", { key: index }, [
                            _c(
                              "span",
                              { staticClass: "mx-1" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon:
                                      index % 2 === 0
                                        ? "volume-up"
                                        : "volume-mute",
                                  },
                                }),
                                _c("span", [_vm._v(_vm._s(value) + "s")]),
                              ],
                              1
                            ),
                            index % 2 === 1 &&
                            index != _vm.sequenceInterval.length - 1
                              ? _c("span", { staticClass: "mx-1" }, [
                                  _vm._v("|"),
                                ])
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                      _c("b-tag", { staticClass: "ml-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.device.devicesSettings.total", {
                              count: _vm.sumArray(_vm.sequenceInterval),
                            })
                          )
                        ),
                      ]),
                    ]
                  : [_c("p", [_vm._v("-")])],
                _c("b-icon", {
                  staticClass: "ml-2 clickable",
                  attrs: { icon: "pencil-alt" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.editSirenSequence()
                    },
                  },
                }),
                _vm.sequenceInterval.length > 1
                  ? _c("b-icon", {
                      staticClass: "ml-2 clickable",
                      attrs: { icon: "trash" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.deleteSirenSequence()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }