import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import PublicHeader from '@/views/components/common/PublicHeader.vue';
import Footer from '@/views/components/common/Footer.vue';
import BackToTop from '../components/common/BackToTop.vue';
let Public = class Public extends Vue {
};
Public = __decorate([
    Component({
        components: { PublicHeader, Footer, BackToTop }
    })
], Public);
export default Public;
