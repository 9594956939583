var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "header",
        { staticClass: "modal-card-head" },
        [
          _c("b-icon", {
            staticClass: "mr-1",
            attrs: { icon: "bell", size: "is-medium" },
          }),
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("component.notification.settings.test_notification")
                ) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c("section", { staticClass: "modal-card-body" }, [
        !_vm.sirenContent
          ? _c(
              "div",
              [
                _vm.buzzerIsAllowed
                  ? [
                      _c("p", { staticClass: "subtitle" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("component.notification.settings.loggers")
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "buttons" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "level-item",
                              attrs: { type: "is-primary" },
                              on: {
                                click: function ($event) {
                                  _vm.sirenContent = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("notification_channels.buzzer"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _c("p", { staticClass: "subtitle" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("component.notification.settings.devices")
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "level-item",
                        attrs: { type: "is-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.sendTestNotification(
                              _vm.ValidationRuleScope.SOURCE
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.notification.notification_format.measuredDataAlarm"
                            )
                          )
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "level-item",
                        attrs: { type: "is-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.sendTestNotification(
                              _vm.ValidationRuleScope.SOURCE_GROUP
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.notification.notification_format.groupMeasuredDataAlarm"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          : _c(
              "div",
              [
                _c("div", { staticClass: "mb-3" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          _vm.sirenContent = false
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "chevron-left" } }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("notification_channels.buzzer"))),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("DevicesSelectionComponent", {
                  attrs: {
                    componentIsLoading: _vm.isLoadingDevices,
                    devices: _vm.devicesList.getData() || [],
                    totalSize: _vm.devicesList.getPagination().total,
                  },
                  on: {
                    loadDevices: _vm.loadDevices,
                    backUpSelectedDevices: _vm.backUpSelectedDevices,
                  },
                }),
              ],
              1
            ),
      ]),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _vm.sirenContent
            ? _c(
                "b-button",
                {
                  attrs: {
                    type: "is-primary",
                    disabled: !_vm.hasDevicesForSiren,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.testDevicesSiren()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.company.registerCompanyDevicesModal.confirm"
                      )
                    )
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }