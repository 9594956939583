import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import ValidationRuleScope from '@/entities/notifications/ValidationRuleScope';
import DevicesSelectionComponent from './DevicesSelectionComponent.vue';
import DeviceRepository from '@/services/repository/DeviceRepository';
import VueUtilities from '@/services/VueUtilities';
var deviceRepository;
let TestNotificationModal = class TestNotificationModal extends Vue {
    constructor() {
        super(...arguments);
        this.hasDevicesForSiren = false;
        this.selectedDevices = [];
        this.ValidationRuleScope = ValidationRuleScope;
        this.sirenContent = false;
        this.isLoading = false;
    }
    /*
     * Check if buzzer is allowed in tier for currently selected department
     */
    get buzzerIsAllowed() {
        return this.tier?.features.find((x) => x.Name === 'Observer.BuzzerNotifications')?.Value?.Allowed === true;
    }
    created() {
        deviceRepository = new DeviceRepository(this);
    }
    loadDevices(pagination, currentFilter) {
        this.$emit('loadDevices', pagination, currentFilter);
    }
    closeModal() {
        this.$emit('modalClosed');
    }
    backUpSelectedDevices(selectedDevices) {
        this.selectedDevices = selectedDevices;
        this.hasDevicesForSiren = selectedDevices.length > 0 ? true : false;
    }
    async testDevicesSiren() {
        this.isLoading = true;
        let sirenPromises = new Array();
        this.selectedDevices.forEach((device) => {
            sirenPromises.push(deviceRepository.testSiren(device.deviceId));
        });
        await Promise.all(sirenPromises).then((response) => {
            if (response.find((x) => x !== true)) {
                VueUtilities.openErrorToast(this, this.$t('component.device.testSirenAlert.multipleSirenFailure'));
            }
            else {
                VueUtilities.openSuccessToast(this, this.$t('component.device.testSirenAlert.multipleSirenSuccess'));
                this.sirenContent = false;
            }
            this.isLoading = false;
        });
    }
    sendTestNotification(val = ValidationRuleScope.SOURCE) {
        this.$emit('sendTestNotification', val);
    }
};
__decorate([
    Prop({ type: Object })
], TestNotificationModal.prototype, "devicesList", void 0);
__decorate([
    Prop({ type: Boolean })
], TestNotificationModal.prototype, "isLoadingDevices", void 0);
__decorate([
    Prop({ type: Object })
], TestNotificationModal.prototype, "tier", void 0);
TestNotificationModal = __decorate([
    Component({ components: { DevicesSelectionComponent } })
], TestNotificationModal);
export default TestNotificationModal;
