import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReportGenerationStatus } from '@/entities/enums/ReportGenerationStatus';
import BoundaryInfo from '../boundary/BoundaryInfo.vue';
import { Boundary } from '@/entities/models/Boundary';
let ReportDetailModal = class ReportDetailModal extends Vue {
    constructor() {
        super(...arguments);
        this.ReportGenerationStatus = ReportGenerationStatus;
    }
    get sourcesInReport() {
        return this.sources.filter((x) => this.report.metadata.sourceIds.includes(x.id));
    }
    get typesAreObjects() {
        if (this.report.metadata?.types) {
            return this.report.metadata.types[0]?.Name != null;
        }
        else
            return false;
    }
    get hasCustomBoundaries() {
        return this.report.metadata.customBoundaries
            ? JSON.parse(this.report.metadata.customBoundaries)?.length > 0
            : null;
    }
    get customBoundaries() {
        let boundaries = [];
        if (this.hasCustomBoundaries) {
            let parsedBoundaries = JSON.parse(this.report.metadata.customBoundaries);
            parsedBoundaries.forEach((boundary) => {
                let b = new Boundary();
                b.eventTypeSlug = boundary.EventType;
                b.lowerAlarmBoundary = {
                    Value: boundary.CustomLowerValue,
                    Unit: this.getUnitBySlug(boundary.EventType),
                    Quantity: null
                };
                b.upperAlarmBoundary = {
                    Value: boundary.CustomUpperValue,
                    Unit: this.getUnitBySlug(boundary.EventType),
                    Quantity: null
                };
                boundaries.push(b);
            });
        }
        return boundaries;
    }
    getUnitBySlug(slug) {
        return this.eventTypes ? this.eventTypes.find((et) => et.slug === slug).unit.unit : '';
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    downloadReport() {
        this.$emit('downloadReport', this.report.fileName);
    }
    removeReport(report) {
        this.$emit('removeReport', report);
    }
};
__decorate([
    Prop({ type: Object })
], ReportDetailModal.prototype, "report", void 0);
__decorate([
    Prop({ type: Object })
], ReportDetailModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportDetailModal.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array })
], ReportDetailModal.prototype, "eventTypes", void 0);
ReportDetailModal = __decorate([
    Component({ components: { BoundaryInfo } })
], ReportDetailModal);
export default ReportDetailModal;
