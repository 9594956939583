var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.location.detail.msg.file_download_info")
                    ) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "multi-header mb-4" }, [
        _vm.isLayoutList
          ? _c("h2", { staticClass: "subtitle is-4" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("admin.component.loggers.calibration.title")) +
                  " "
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "buttons ml-auto" },
          [
            _vm.companyLogger.calibration &&
            _vm.$ability.can(
              _vm.$permActions.EDIT,
              _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
            )
              ? [
                  !_vm.isEditing
                    ? _c("b-button", {
                        attrs: { "icon-right": "pencil-alt" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleEdit()
                          },
                        },
                      })
                    : _c(
                        "b-button",
                        {
                          staticClass: "ml-1",
                          attrs: { "icon-left": "times-circle" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleEdit()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("generals.cancel_edit")) + " "
                          ),
                        ]
                      ),
                ]
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.EDIT,
              _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
            )
              ? _c(
                  "b-button",
                  {
                    attrs: {
                      type: "is-primary",
                      outlined: "",
                      disabled: !_vm.companyLogger.calibration,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openConfirmDelete()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "admin.component.loggers.calibration.actions.delete"
                        )
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.EDIT,
              _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
            )
              ? _c(
                  "b-button",
                  {
                    attrs: {
                      type: "is-primary",
                      disabled:
                        !_vm.companyLogger.companyLoggerId ||
                        _vm.companyLogger.calibration,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openCalibrationModal()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "admin.component.loggers.calibration.actions.create"
                        )
                      )
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm.companyLogger.calibration
        ? _c("section", [
            _c("div", { staticClass: "columns info-fields" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "b-field",
                    {
                      attrs: {
                        horizontal: "",
                        label: _vm.$t(
                          "admin.component.loggers.detail.info.calibration"
                        ),
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "is-align-items-center ml-3-mobile" },
                        [
                          _vm.companyLogger.calibration != null
                            ? _c(
                                "b-tag",
                                {
                                  attrs: {
                                    type: _vm.companyLogger.calibration.status
                                      .type,
                                    rounded: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "calibration_status." +
                                          _vm.companyLogger.calibration.status
                                            .name
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _c(
                                "b-tag",
                                {
                                  attrs: {
                                    type: _vm.CalibrationStatus.NOT_CALIBRATED
                                      .type,
                                    rounded: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "calibration_status." +
                                          _vm.CalibrationStatus.NOT_CALIBRATED
                                            .name
                                      )
                                    )
                                  ),
                                ]
                              ),
                          _vm.hasCalibrationProtocolToDownload(
                            _vm.companyLogger
                          ) &&
                          _vm.$ability.can(
                            _vm.$permActions.PROTOCOL,
                            _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
                          )
                            ? [
                                _c(
                                  "b-tooltip",
                                  {
                                    staticClass: "is-hidden-mobile",
                                    attrs: {
                                      label: _vm.$t("generals.download"),
                                      position: "is-left",
                                      type: "is-light",
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "control-icon clickable",
                                      attrs: { icon: "file-download" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.downloadCalibration(
                                            _vm.companyLogger.companyLoggerId
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  { staticClass: "is-hidden-tablet" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "control-icon clickable",
                                      attrs: { icon: "file-download" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.downloadCalibration(
                                            _vm.companyLogger.companyLoggerId
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c(
                    "b-field",
                    {
                      attrs: {
                        horizontal: "",
                        label: _vm.$t(
                          "admin.component.loggers.calibration.calibration_date"
                        ),
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.dateTimeManager.formatTime(
                                new Date(
                                  _vm.companyLogger.calibration.calibratedAt *
                                    1000
                                ),
                                "UTC",
                                null,
                                ""
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "b-field",
                    {
                      attrs: {
                        horizontal: "",
                        label: _vm.$t(
                          "admin.component.loggers.calibration.verification_date"
                        ),
                      },
                    },
                    [
                      _vm.companyLogger.calibration.verifiedAt
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dateTimeManager.formatTime(
                                    new Date(
                                      _vm.companyLogger.calibration.verifiedAt *
                                        1000
                                    ),
                                    "UTC",
                                    null,
                                    ""
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("p", [_vm._v("-")]),
                    ]
                  ),
                  !_vm.isEditing
                    ? _c(
                        "b-field",
                        {
                          attrs: {
                            horizontal: "",
                            label: _vm.$t(
                              "admin.component.loggers.calibration.expiration_date"
                            ),
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dateTimeManager.formatTime(
                                    new Date(
                                      _vm.companyLogger.calibration.expiresAt *
                                        1000
                                    ),
                                    "UTC",
                                    null,
                                    ""
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "b-field",
                        {
                          attrs: {
                            horizontal: "",
                            label: _vm.$t(
                              "admin.component.loggers.calibration.expiration_date"
                            ),
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t(
                                  "admin.component.loggers.calibration.expiration_date"
                                )
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t(
                                "admin.component.loggers.calibration.expiration_date"
                              )
                            ),
                          },
                        },
                        [
                          _c("b-datepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              name: _vm.$t(
                                "admin.component.loggers.calibration.expiration_date"
                              ),
                              "mobile-native": false,
                              "first-day-of-week": 1,
                              "date-formatter": function (date) {
                                return _vm.dateTimeManager.formatTimeInInput(
                                  date
                                )
                              },
                              "month-names":
                                _vm.dateTimeManager.getMonthNames(),
                            },
                            model: {
                              value: _vm.expireAt,
                              callback: function ($$v) {
                                _vm.expireAt = $$v
                              },
                              expression: "expireAt",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "buttons is-right" },
              [
                _vm.isEditing
                  ? _c(
                      "b-button",
                      {
                        attrs: { "icon-left": "save", type: "is-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("generals.save")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "empty-calibration-data" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("admin.component.loggers.calibration.empty"))
              ),
            ]),
          ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("CreateCalibrationModal", {
            attrs: {
              dateTimeManager: _vm.dateTimeManager,
              logger: _vm.companyLogger,
            },
            on: {
              modalClosed: _vm.closeModal,
              saveCalibration: _vm.saveCalibration,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }