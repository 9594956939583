import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import LoggedUserManager from '@/services/LoggedUserManager';
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import MeasuredDataNotification from '@/views/components/notification/configurationPerType/MeasuredDataNotification.vue';
import EntityMeasuredDataNotification from '@/views/components/notification/configurationPerType/EntityMeasuredDataNotification.vue';
import GeneralNotificationComponent from '@/views/components/notification/configurationPerType/GeneralNotificationComponent.vue';
import NotificationSubscriptionCategory from '@/entities/notifications/NotificationSubscriptionCategory';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import NotificationTabs from '@/entities/notifications/NotificationTabs';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import VueUtilities from '@/services/VueUtilities';
import DateTimeManager from '@/services/DateTimeManager';
import { vxm } from '@/store/store.vuex';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import NotificationSchedule from '@/entities/notifications/NotificationSchedule';
import NotificationScheduleType from '@/entities/notifications/NotificationScheduleType';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
var notificationSubscriptionsRepository;
var vxDepartmentStore = vxm.departmentStore;
var userSettingsRepository;
let NonWorkingHours = class NonWorkingHours extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.NotificationTabs = NotificationTabs;
        this.isLoading = false;
        this.isChangingMode = false;
        this.nonWorkingSubscriptions = [];
        this.selectedMeasuredRulesModeNonWorking = null;
        this.dateTimeManager = null;
        this.selectedPresetNonWorking = null;
        this.subscriptionsForNewNonWorking = [];
        this.loadedPreset = null;
        this.isCreating = false;
        this.measuredRuleEnabled = null;
        this.nonWorkingDateList = [];
        this.copiedNotificationSchedule = null;
        this.copiedSchedules = [];
    }
    get measuredTypes() {
        return this.availableTypes.filter((x) => x.category == NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get hasRulesForEntitiesNonWorking() {
        return this.selectedMeasuredRulesModeNonWorking === NotificationMeasuredRuleScope.SOURCE_OR_GROUP;
    }
    get hasPresetSelectedNonWorking() {
        return this.selectedPresetNonWorking != null;
    }
    get presetIsUsed() {
        return this.hasPresetSelectedNonWorking && this.selectedPresetNonWorking.id === this.loadedPreset.id;
    }
    get scheduleWhatIsEditing() {
        return this.nonWorkingSchedules.find((x) => x.additionalProperties.isEditing === true);
    }
    get nonWorkingSchedules() {
        return this.copiedSchedules.filter((x) => x.type == NotificationScheduleType.SPECIAL_DAYS);
    }
    get singleMeasuredRuleNonWorking() {
        return this.nonWorkingSubscriptions.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get measuredRulesNonWorking() {
        return this.nonWorkingSubscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    //assign default mode for measured rules (based on existing subscriptions)
    assignSelectedMeasuredRulesModeNonWorking() {
        let measuredRule = this.nonWorkingSubscriptions.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE);
        if (!measuredRule) {
            return NotificationMeasuredRuleScope.COMPANY;
        }
        else if (measuredRule.scopeType === NotificationScopeType.COMPANY) {
            return NotificationMeasuredRuleScope.COMPANY;
        }
        else {
            return NotificationMeasuredRuleScope.SOURCE_OR_GROUP;
        }
    }
    cancel() {
        this.isCreating = false;
        if (this.scheduleWhatIsEditing)
            this.scheduleWhatIsEditing.additionalProperties.isEditing = false;
        this.copiedNotificationSchedule = null;
    }
    getDateRange(notificationSchedule) {
        let fromDateString = this.dateTimeManager.formatTime(new Date(notificationSchedule.validFrom * 1000), null, null, '');
        let toDateString = this.dateTimeManager.formatTime(new Date(notificationSchedule.validTo * 1000), null, null, '');
        //is same date
        if (fromDateString === toDateString) {
            return fromDateString;
        }
        else {
            return fromDateString + ' - ' + toDateString;
        }
    }
    newNonWorkingDate() {
        this.nonWorkingDateList.push({
            name: this.$t('notifications.configuration.non_working_list.new_name'),
            dateRange: [new Date(), new Date()]
        });
    }
    remove(index) {
        this.nonWorkingDateList.splice(index, 1);
    }
    addNew() {
        this.nonWorkingDateList = [];
        this.nonWorkingDateList.push({
            name: this.$t('notifications.configuration.non_working_list.new_name'),
            dateRange: [new Date(), new Date()]
        });
        this.isCreating = true;
    }
    async created() {
        //load active tab from url
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        this.copiedSchedules = JSON.parse(JSON.stringify(this.schedules));
        this.notificationSubscriptions.forEach((subs) => {
            this.nonWorkingSubscriptions.push(subs.makeCopy(true));
        });
        this.measuredRuleEnabled = this.getmeasuredRuleEnabled();
        this.selectedMeasuredRulesModeNonWorking = this.assignSelectedMeasuredRulesModeNonWorking();
        this.nonWorkingDateList.push({
            name: this.$t('notifications.configuration.non_working_list.new_name'),
            dateRange: [new Date(), new Date()]
        });
    }
    getmeasuredRuleEnabled() {
        return this.singleMeasuredRuleNonWorking ? this.singleMeasuredRuleNonWorking.enabled : false;
    }
    getRuleForNonWorking(name) {
        return this.nonWorkingSubscriptions.find((x) => x.notificationType === name);
    }
    getType(name) {
        return this.availableTypes.find((x) => x.name === name);
    }
    //triggered when measured rules mode is changed
    async changeModeNonWorking(selectedMode) {
        this.isChangingMode = true;
        this.measuredRuleEnabled = true;
        this.selectedMeasuredRulesModeNonWorking = selectedMode;
        this.subscriptionsForNewNonWorking = this.subscriptionsForNewNonWorking.filter((x) => x.NotificationType !== NotificationSubscriptionType.MEASURED_DATA_ALARM &&
            x.NotificationType !== NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
        this.isChangingMode = false;
    }
    switchedValue(event) {
        this.measuredRuleEnabled = event;
    }
    /**
     * This methods compare given date range with ranges in all subscriptions and returns true,
     * if given date range is overlapping with existing schedule range
     */
    checkIfDateRangeIsTaken() {
        let dateIsTaken = false;
        this.nonWorkingDateList.forEach((nonWorkingDate) => {
            this.copiedSchedules.forEach((x) => {
                const from1 = x.validFrom * 1000;
                const to1 = x.validTo * 1000;
                const from2 = nonWorkingDate.dateRange[0].getTime();
                const to2 = nonWorkingDate.dateRange[1].getTime();
                if (from1 <= to2 && from2 <= to1 && x.additionalProperties.isEditing !== true) {
                    dateIsTaken = true;
                }
            });
        });
        return dateIsTaken;
    }
    /**
     * Checking duplicate date ranges in new dates
     */
    checkIfDateRangeIsDuplicate() {
        let dateIsDuplicate = false;
        this.nonWorkingDateList.forEach((nonWorkingDate, index) => {
            this.nonWorkingDateList.forEach((x, i) => {
                if (index !== i) {
                    const from1 = x.dateRange[0].getTime();
                    const to1 = x.dateRange[0].getTime();
                    const from2 = nonWorkingDate.dateRange[0].getTime();
                    const to2 = nonWorkingDate.dateRange[1].getTime();
                    if (from1 <= to2 && from2 <= to1) {
                        dateIsDuplicate = true;
                    }
                }
            });
        });
        return dateIsDuplicate;
    }
    copySchedule() {
        this.copiedNotificationSchedule = this.scheduleWhatIsEditing;
        VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.non_working_list.copied'));
    }
    pasteSchedule() {
        if (this.scheduleWhatIsEditing) {
            this.copiedNotificationSchedule.subscriptions.forEach((copiedSubscription) => {
                let subscription = this.scheduleWhatIsEditing.subscriptions.find((x) => x.notificationType == copiedSubscription.notificationType ||
                    ((x.notificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                        x.notificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) &&
                        (copiedSubscription.notificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                            copiedSubscription.notificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM)));
                if (subscription) {
                    subscription.channels = copiedSubscription.channels;
                    subscription.displayMode = copiedSubscription.displayMode;
                    subscription.enabled = copiedSubscription.enabled;
                    subscription.notificationType = copiedSubscription.notificationType;
                    subscription.priority = copiedSubscription.priority;
                    subscription.scopeType = copiedSubscription.scopeType;
                    subscription.subscriptionCategory = copiedSubscription.subscriptionCategory;
                }
            });
            this.editNonWorking(this.scheduleWhatIsEditing, true);
        }
        else {
            this.editNewNonWorking(this.copiedNotificationSchedule);
        }
        VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.non_working_list.pasted'));
    }
    //triggered when save button is clicked
    saveNonWorkingHours() {
        this.nonWorkingDateList.forEach((nonWorkingDate) => {
            //create copy of Date object to remove reference between date objects when one day range is selected
            nonWorkingDate.dateRange[1] = new Date(nonWorkingDate.dateRange[1].getTime());
            //set hours in selected range from start of first date to end of last date
            nonWorkingDate.dateRange[0].setHours(0, 0, 0, 0);
            nonWorkingDate.dateRange[1].setHours(23, 59, 59, 999);
        });
        //check date range
        const dateIsTaken = this.checkIfDateRangeIsTaken();
        const dateIsDuplicate = this.checkIfDateRangeIsDuplicate();
        if (dateIsTaken === true) {
            VueUtilities.openErrorToast(this, this.$t('notifications.configuration.non_working_list.date_taken'));
            return;
        }
        if (dateIsDuplicate === true) {
            VueUtilities.openErrorToast(this, this.$t('notifications.configuration.non_working_list.date_duplicate'));
            return;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let errors = [];
                this.isLoading = true;
                //check if preset is used
                let usedPresetId = null;
                //check if preset was selected and compare current subscriptions with preset
                if (this.presetIsUsed) {
                    let presetIsSameAsSubs = this.selectedPresetNonWorking.comparePresetWithSubscriptions(this.subscriptionsForNewNonWorking);
                    //if subscriptions are same, set preset id
                    if (presetIsSameAsSubs == true) {
                        this.subscriptionsForNewNonWorking.forEach((sub) => {
                            sub.PresetId = this.selectedPresetNonWorking.id;
                        });
                    }
                    else {
                        //if not, set preset id to null
                        this.subscriptionsForNewNonWorking.forEach((sub) => {
                            sub.PresetId = null;
                        });
                    }
                }
                else {
                    // if subscriptions are not same, check if there was some preset defined before and if yes,
                    //compare subscriptions from this preset (if exists), otherwise set presetId to null
                    let presetIds = [...new Set(this.subscriptionsForNewNonWorking.map((x) => x.PresetId))];
                    if (presetIds.length === 1 && presetIds[0] != null) {
                        //Load preset with subscriptions
                        let response = null;
                        if (!LoggedUserManager.isSystemAdmin()) {
                            response = this.presets.find((x) => x.id === presetIds[0]);
                        }
                        else {
                            response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(presetIds[0]);
                        }
                        //handle error response
                        if (typeof response == 'string') {
                            VueUtilities.openErrorToast(this, response);
                            return;
                        }
                        let loadedPreset = response;
                        if (!loadedPreset) {
                            //if preset does not exists
                            this.subscriptionsForNewNonWorking.forEach((sub) => {
                                sub.PresetId = null;
                            });
                        }
                        else {
                            //if preset exists, compare subscriptions
                            let presetIsSameAsSubs = loadedPreset.comparePresetWithSubscriptions(this.subscriptionsForNewNonWorking);
                            if (presetIsSameAsSubs == true) {
                                //if subs are same
                                this.subscriptionsForNewNonWorking.forEach((sub) => {
                                    sub.PresetId = loadedPreset.id;
                                });
                            }
                            else {
                                //if not, set preset id to null
                                this.subscriptionsForNewNonWorking.forEach((sub) => {
                                    sub.PresetId = null;
                                });
                            }
                        }
                    }
                    else {
                        //if there is multiple presets in subscriptions or is null
                        this.subscriptionsForNewNonWorking.forEach((sub) => {
                            sub.PresetId = null;
                        });
                    }
                }
                let callResult = null;
                // if editing, update schedule and rules
                if (this.scheduleWhatIsEditing) {
                    let dto = {
                        Name: this.nonWorkingDateList[0].name,
                        Type: NotificationScheduleType.SPECIAL_DAYS,
                        ValidFrom: this.nonWorkingDateList[0].dateRange[0],
                        ValidTo: this.nonWorkingDateList[0].dateRange[1],
                        WeekMinutes: []
                    };
                    callResult = await notificationSubscriptionsRepository.updateNotificationSchedule(this.scheduleWhatIsEditing.id, dto, this.currentUser.apiUserId, this.departmentId);
                    if (callResult !== true) {
                        errors.push(callResult);
                    }
                    let promises = [];
                    //check if type of measured rule was updated
                    if (this.selectedMeasuredRulesModeNonWorking === NotificationMeasuredRuleScope.COMPANY) {
                        let measuredForEntity = this.scheduleWhatIsEditing.subscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                            [NotificationScopeType.SOURCE, NotificationScopeType.SOURCE_GROUP].includes(x.scopeType));
                        measuredForEntity.forEach((subToDel) => {
                            promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.currentUser.apiUserId, this.departmentId));
                        });
                    }
                    else if (this.selectedMeasuredRulesModeNonWorking === NotificationMeasuredRuleScope.SOURCE_OR_GROUP) {
                        let measuredForCompany = this.scheduleWhatIsEditing.subscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                            x.scopeType === NotificationScopeType.COMPANY);
                        measuredForCompany.forEach((subToDel) => {
                            promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.currentUser.apiUserId, this.departmentId));
                        });
                    }
                    //update or create subscription
                    let rulesToCreate = [];
                    this.subscriptionsForNewNonWorking.forEach((subs) => {
                        let idOfExisting = null;
                        if (this.availableTypes.find((x) => x.name === subs.NotificationType).category ==
                            NotificationSubscriptionCategory.MEASUREMENT_RULE) {
                            idOfExisting = this.scheduleWhatIsEditing.subscriptions.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                                x.scopeType === subs.ScopeType &&
                                x.scopeId === subs.ScopeId)?.notificationSubscriptionId;
                        }
                        else {
                            idOfExisting = this.scheduleWhatIsEditing.subscriptions.find((x) => x.notificationType === subs.NotificationType)?.notificationSubscriptionId;
                        }
                        if (idOfExisting) {
                            let subsUpdateDto = {
                                Channels: subs.Channels,
                                NotificationType: subs.NotificationType,
                                PresetId: usedPresetId || subs.PresetId,
                                Priority: subs.Priority,
                                DisplayMode: subs.DisplayMode,
                                Enabled: subs.Enabled
                            };
                            promises.push(notificationSubscriptionsRepository.updateNotificationSubscription(idOfExisting, subsUpdateDto, this.currentUser.apiUserId, this.departmentId));
                        }
                        else {
                            let newRule = {
                                ScopeType: subs.ScopeType,
                                ScopeId: subs.ScopeId,
                                NotificationType: subs.NotificationType,
                                Enabled: subs.Enabled,
                                Channels: subs.Channels,
                                DisplayMode: subs.DisplayMode,
                                PresetId: subs.PresetId,
                                ScheduleId: this.scheduleWhatIsEditing.id
                            };
                            rulesToCreate.push(newRule);
                        }
                    });
                    if (rulesToCreate.length > 0) {
                        promises.push(notificationSubscriptionsRepository.createNotificationSubscription(rulesToCreate, this.currentUser.apiUserId, this.departmentId, this.scheduleWhatIsEditing.id));
                    }
                    //check responses
                    await Promise.all(promises).then((response) => {
                        errors = response.filter((x) => typeof x === 'string');
                    });
                }
                else {
                    //create schedules
                    let allPromises = new Array();
                    this.nonWorkingDateList.forEach((nonWorking) => {
                        let dto = {
                            Name: nonWorking.name,
                            Type: NotificationScheduleType.SPECIAL_DAYS,
                            ValidFrom: nonWorking.dateRange[0],
                            ValidTo: nonWorking.dateRange[1],
                            Subscriptions: this.subscriptionsForNewNonWorking,
                            WeekMinutes: []
                        };
                        allPromises.push(notificationSubscriptionsRepository.createNotificationSchedule(dto, this.currentUser.apiUserId, this.departmentId));
                    });
                    await Promise.all(allPromises).then((response) => {
                        callResult = response;
                        response.forEach((schedule) => {
                            if (!(schedule instanceof NotificationSchedule)) {
                                errors.push(schedule);
                            }
                        });
                    });
                }
                //save measured subscriptions mode to user settings
                let scheduleId = this.scheduleWhatIsEditing
                    ? this.scheduleWhatIsEditing.id
                    : callResult[0].id;
                if (errors.length <= 0) {
                    await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.RULES, UserSettingsKeys.SCHEDULE_MODE, scheduleId], this.selectedMeasuredRulesModeNonWorking, this.departmentId);
                }
                if (errors.length <= 0) {
                    VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.non_working_list.success'));
                    this.$emit('reloadSchedules');
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('notifications.configuration.non_working_list.failure', {
                        error: errors.join(', ')
                    }));
                }
                this.isLoading = false;
            }
            else {
                //scroll view to first error field
                if (this.$validator.errors.items)
                    this.$el
                        .querySelector('[name="' + this.$validator.errors.items[0].field + '"]')
                        .scrollIntoView({ block: 'center' });
            }
        });
    }
    //triggered when edit button is clicked
    editNonWorking(schedule, isPaste = false) {
        this.nonWorkingDateList = [];
        this.nonWorkingDateList.push({
            name: this.$t('notifications.configuration.non_working_list.new_name'),
            dateRange: [new Date(), new Date()]
        });
        this.isLoading = true;
        this.isCreating = false;
        this.nonWorkingSchedules.forEach((sch) => {
            sch.additionalProperties.isEditing = false;
        });
        this.subscriptionsForNewNonWorking = [];
        this.nonWorkingDateList[0].name = schedule.name;
        schedule.additionalProperties.isEditing = true;
        this.nonWorkingDateList[0].dateRange = [new Date(schedule.validFrom * 1000), new Date(schedule.validTo * 1000)];
        this.nonWorkingSubscriptions = schedule.subscriptions;
        this.measuredRuleEnabled = this.getmeasuredRuleEnabled();
        if (isPaste) {
            this.selectedMeasuredRulesModeNonWorking =
                this.departmentUserSettings.notifications.rules.scheduleMode?.[this.copiedNotificationSchedule.id] || NotificationMeasuredRuleScope.COMPANY;
        }
        else {
            this.selectedMeasuredRulesModeNonWorking =
                this.departmentUserSettings.notifications.rules.scheduleMode?.[schedule.id] || NotificationMeasuredRuleScope.COMPANY;
        }
        this.$nextTick(() => {
            this.isLoading = false;
        });
    }
    //triggered when paste button is clicked from New Non working schedule
    editNewNonWorking(schedule) {
        this.isLoading = true;
        this.nonWorkingSchedules.forEach((sch) => {
            sch.additionalProperties.isEditing = false;
        });
        this.subscriptionsForNewNonWorking = [];
        this.nonWorkingSubscriptions = schedule.subscriptions;
        this.measuredRuleEnabled = this.getmeasuredRuleEnabled();
        this.selectedMeasuredRulesModeNonWorking =
            this.departmentUserSettings.notifications.rules.scheduleMode?.[schedule.id] ||
                NotificationMeasuredRuleScope.COMPANY;
        this.$nextTick(() => {
            this.isLoading = false;
        });
    }
    isBundariesEnables() {
        return this.singleMeasuredRuleNonWorking ? this.singleMeasuredRuleNonWorking.enabled : false;
    }
    //triggered when editing is canceled
    cancelEditNonWorking(schedule) {
        this.copiedSchedules = JSON.parse(JSON.stringify(this.schedules));
        this.isLoading = true;
        this.isCreating = false;
        this.nonWorkingDateList[0].name = this.$t('notifications.configuration.non_working_list.new_name');
        this.nonWorkingDateList[0].dateRange = [new Date(), new Date()];
        schedule.additionalProperties.isEditing = false;
        this.subscriptionsForNewNonWorking = [];
        this.nonWorkingSubscriptions = [];
        this.selectedMeasuredRulesModeNonWorking = NotificationMeasuredRuleScope.COMPANY;
        this.notificationSubscriptions.forEach((subs) => {
            this.nonWorkingSubscriptions.push(subs.makeCopy());
        });
        this.measuredRuleEnabled = this.getmeasuredRuleEnabled();
        this.$nextTick(() => {
            this.isLoading = false;
        });
    }
    //confirmation dialog when user clicks delete button/icon
    confirmSpecialScheduleDelete(schedule) {
        this.$buefy.dialog.confirm({
            title: this.$t('notifications.configuration.non_working_list.delete_dialog.title'),
            message: (this.$t('notifications.configuration.non_working_list.delete_dialog.msg', { name: schedule.name })),
            confirmText: this.$t('notifications.configuration.non_working_list.delete_dialog.confirm'),
            cancelText: this.$t('generals.no'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                let result = await notificationSubscriptionsRepository.deleteNotificationSchedules(schedule.id, this.currentUser.apiUserId, this.departmentId);
                if (result === true) {
                    this.$emit('reloadSchedules');
                }
            }
        });
    }
    //triggered when some subscriptions is changed
    nonWorkingChanged(newRule) {
        //check if there is some measured subscription (type of this subscription can be changed)
        if (newRule.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            newRule.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
            let indexOfMeasured = this.subscriptionsForNewNonWorking.findIndex((x) => (x.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                x.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) &&
                x.ScopeId === newRule.ScopeId);
            if (indexOfMeasured >= 0) {
                //remove this measured rule by index
                this.subscriptionsForNewNonWorking.splice(indexOfMeasured, 1);
            }
        }
        else {
            //remove current subscription from list
            this.subscriptionsForNewNonWorking = this.subscriptionsForNewNonWorking.filter((x) => x.NotificationType !== newRule.NotificationType);
        }
        this.subscriptionsForNewNonWorking.push(newRule);
    }
    //load subscriptions from preset
    async applyPresetNonWorking(preset) {
        let presetWithSubscriptions = null;
        //Load preset with subscriptions
        let response = null;
        if (!LoggedUserManager.isSystemAdmin()) {
            response = preset;
        }
        else {
            response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(preset.id);
        }
        //handle error response
        if (typeof response == 'string') {
            VueUtilities.openErrorToast(this, response);
            return;
        }
        else {
            presetWithSubscriptions = response;
        }
        this.selectedPresetNonWorking = presetWithSubscriptions;
        this.isLoading = true;
        this.nonWorkingSubscriptions = [];
        this.selectedPresetNonWorking.subscriptions.forEach((subs) => {
            this.nonWorkingSubscriptions.push(NotificationSubscription.convertFromPreset(subs, this.departmentId));
        });
        this.measuredRuleEnabled = this.getmeasuredRuleEnabled();
        this.loadedPreset = this.selectedPresetNonWorking;
        this.$nextTick(() => {
            this.isLoading = false;
        });
    }
};
__decorate([
    Prop({ type: Array })
], NonWorkingHours.prototype, "availableTypes", void 0);
__decorate([
    Prop({ type: Array })
], NonWorkingHours.prototype, "notificationSubscriptions", void 0);
__decorate([
    Prop({ type: Array })
], NonWorkingHours.prototype, "presets", void 0);
__decorate([
    Prop({ type: Array })
], NonWorkingHours.prototype, "schedules", void 0);
__decorate([
    Prop({ type: Object })
], NonWorkingHours.prototype, "departmentUserSettings", void 0);
__decorate([
    Prop({ type: Object })
], NonWorkingHours.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: String })
], NonWorkingHours.prototype, "departmentId", void 0);
__decorate([
    Prop({ type: Object })
], NonWorkingHours.prototype, "tier", void 0);
NonWorkingHours = __decorate([
    Component({
        components: {
            MeasuredDataNotification,
            GeneralNotificationComponent,
            EntityMeasuredDataNotification
        }
    })
], NonWorkingHours);
export default NonWorkingHours;
