import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import LoggedUserManager from '../LoggedUserManager';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
export default class EventValidationRepository {
    constructor(ctx) {
        this.ctx = ctx;
        this.isAdmin = LoggedUserManager.isAdmin();
    }
    async changeNotificationsMode(mode, userId, departmentId = this.getSelectedDepartmentId(), scheduleId = null) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.MEASURED_DATA_VALIDATE)) {
            throw 'Forbidden action changeNotificationsMode';
        }
        let data = new FormData();
        data.set('mode', mode);
        if (scheduleId)
            data.set('scheduleId', scheduleId);
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.EventValidation.Validation}/${userId}${ApiLinks.EventValidation.Keys.Mode}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status === ApiStatus.NOT_ALLOWED) {
            return response.data.code;
        }
        else if (response.status != ApiStatus.SUCCESS)
            return JSON.stringify(response.data);
        else
            return true;
    }
    async testSingleNotification(userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.MEASURED_DATA_VALIDATE)) {
            throw 'Forbidden action testSingleNotification';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.EventValidation.Validation}/${userId}${ApiLinks.EventValidation.Keys.Test}`;
        let data = new FormData();
        data.set('type', 'source');
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return JSON.stringify(response.data);
        else
            return true;
    }
    async testGroupNotification(userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.MEASURED_DATA_VALIDATE)) {
            throw 'Forbidden action testGroupNotification';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.EventValidation.Validation}/${userId}${ApiLinks.EventValidation.Keys.Test}`;
        let data = new FormData();
        data.set('type', 'sourceGroup');
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return JSON.stringify(response.data);
        else
            return true;
    }
    async readNotificationModeForUser(userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.VALIDATE_COMPANY)) {
            return null;
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.EventValidation.Validation}/${userId}${ApiLinks.EventValidation.Keys.Mode}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return response.data.data.Mode;
    }
    async changeNotificationsModeForUser(mode, userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.MEASURED_DATA_VALIDATE) && !this.isAdmin) {
            throw 'Forbidden action changeNotificationsModeForUser';
        }
        let data = new FormData();
        data.set('mode', mode);
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.EventValidation.Validation}/${userId}${ApiLinks.EventValidation.Keys.Mode}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        if (response.status != ApiStatus.SUCCESS)
            return JSON.stringify(response.data);
        else
            return response.data.Code;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
