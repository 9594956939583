import { __decorate } from "tslib";
import ApiUserRoles, { SystemUserRoles } from '@/entities/enums/UserRoles';
import { vxm } from '@/store/store.vuex';
import { Component, Vue, Prop } from 'vue-property-decorator';
var vxDepartmentStore = vxm.departmentStore;
let ChangeUserRoleModal = class ChangeUserRoleModal extends Vue {
    constructor() {
        super(...arguments);
        this.ApiUserRoles = ApiUserRoles;
        this.SystemUserRoles = SystemUserRoles;
        this.selectedRole = null;
    }
    get availableUserRoles() {
        if (this.departmentScope && !this.canChangeOwner) {
            return this.userRoles.filter((x) => x.Key !== ApiUserRoles.COMPANY_OWNER);
        }
        return this.userRoles;
    }
    get canChangeOwner() {
        return (vxDepartmentStore.selectedDepartmentRoleKey === ApiUserRoles.COMPANY_OWNER ||
            vxDepartmentStore.selectedDepartmentRoleKey === SystemUserRoles.SYSTEM_ADMIN);
    }
    created() {
        this.selectedRole = this.user.role.Key;
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    save() {
        this.$emit('changeRole', this.selectedRole, this.user);
    }
};
__decorate([
    Prop({ type: Object })
], ChangeUserRoleModal.prototype, "user", void 0);
__decorate([
    Prop({ type: Array })
], ChangeUserRoleModal.prototype, "userRoles", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], ChangeUserRoleModal.prototype, "departmentScope", void 0);
ChangeUserRoleModal = __decorate([
    Component({ components: {} })
], ChangeUserRoleModal);
export default ChangeUserRoleModal;
