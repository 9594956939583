var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "wide" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        [
          _vm.isInvited
            ? _c("b-message", { attrs: { type: "is-info" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.invitation.message", {
                        email: _vm.registrationDto.Email,
                      })
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("h2", { staticClass: "title is-2 has-text-centered" }, [
            _vm._v(" " + _vm._s(_vm.$t("component.register.title")) + " "),
          ]),
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column" }, [
              _c(
                "div",
                [
                  _c(
                    "b-field",
                    {
                      staticClass: "required",
                      attrs: {
                        label: _vm.$t("component.register.email"),
                        type: {
                          "is-danger":
                            _vm.errors.has(
                              _vm.$t("component.register.email")
                            ) || _vm.hasErrorMsg("Email"),
                        },
                        message:
                          _vm.errors.first(
                            _vm.$t("component.register.email")
                          ) || _vm.getErrorMsg("Email"),
                      },
                    },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|email",
                            expression: "'required|email'",
                          },
                        ],
                        attrs: {
                          name: _vm.$t("component.register.email"),
                          type: "text",
                          placeholder: _vm.$t("component.register.email"),
                          disabled: _vm.isInvited,
                        },
                        model: {
                          value: _vm.registrationDto.Email,
                          callback: function ($$v) {
                            _vm.$set(_vm.registrationDto, "Email", $$v)
                          },
                          expression: "registrationDto.Email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("ValidationObserver", { ref: "passwordFields" }, [
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            name: _vm.$t("component.register.password"),
                            rules: "required|password|min:8",
                            vid: "confirmation",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c(
                                    "b-field",
                                    {
                                      staticClass: "required",
                                      attrs: {
                                        label: _vm.$t(
                                          "component.register.password"
                                        ),
                                        type: {
                                          "is-danger":
                                            errors.length > 0 ||
                                            _vm.hasErrorMsg("Password"),
                                        },
                                        message:
                                          _vm.getErrorMsg("Password") || errors,
                                      },
                                    },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          type: "password",
                                          placeholder: _vm.$t(
                                            "component.register.password"
                                          ),
                                          "password-reveal": "",
                                        },
                                        model: {
                                          value: _vm.registrationDto.Password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.registrationDto,
                                              "Password",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "registrationDto.Password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            name: _vm.$t("component.register.confirm_pswd"),
                            rules: "required|confirmed:confirmation",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c(
                                    "b-field",
                                    {
                                      staticClass: "required",
                                      attrs: {
                                        label: _vm.$t(
                                          "component.register.confirm_pswd"
                                        ),
                                        type: {
                                          "is-danger": errors.length > 0,
                                        },
                                        message: errors[0],
                                      },
                                    },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          type: "password",
                                          placeholder: _vm.$t(
                                            "component.register.confirm_pswd"
                                          ),
                                          "password-reveal": "",
                                        },
                                        model: {
                                          value:
                                            _vm.registrationDto.PasswordConfirm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.registrationDto,
                                              "PasswordConfirm",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "registrationDto.PasswordConfirm",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "div",
                [
                  _c(
                    "b-field",
                    {
                      staticClass: "required",
                      attrs: {
                        label: _vm.$t("component.register.first_name"),
                        type: {
                          "is-danger":
                            _vm.errors.has(
                              _vm.$t("component.register.first_name")
                            ) || _vm.hasErrorMsg("FirstName"),
                        },
                        message:
                          _vm.errors.first(
                            _vm.$t("component.register.first_name")
                          ) || _vm.getErrorMsg("FirstName"),
                      },
                    },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|min:2|max:20",
                            expression: "'required|min:2|max:20'",
                          },
                        ],
                        attrs: {
                          name: _vm.$t("component.register.first_name"),
                          type: "text",
                          placeholder: _vm.$t("component.register.first_name"),
                        },
                        model: {
                          value: _vm.registrationDto.FirstName,
                          callback: function ($$v) {
                            _vm.$set(_vm.registrationDto, "FirstName", $$v)
                          },
                          expression: "registrationDto.FirstName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      staticClass: "required",
                      attrs: {
                        label: _vm.$t("component.register.last_name"),
                        type: {
                          "is-danger":
                            _vm.errors.has(
                              _vm.$t("component.register.last_name")
                            ) || _vm.hasErrorMsg("LastName"),
                        },
                        message:
                          _vm.errors.first(
                            _vm.$t("component.register.last_name")
                          ) || _vm.getErrorMsg("LastName"),
                      },
                    },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|min:2|max:20",
                            expression: "'required|min:2|max:20'",
                          },
                        ],
                        attrs: {
                          name: _vm.$t("component.register.last_name"),
                          type: "text",
                          placeholder: _vm.$t("component.register.last_name"),
                        },
                        model: {
                          value: _vm.registrationDto.LastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.registrationDto, "LastName", $$v)
                          },
                          expression: "registrationDto.LastName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      class: _vm.isFlutter ? "" : "required",
                      attrs: {
                        label: _vm.$t(
                          "component.register.additional_info.tel_format"
                        ),
                        type: {
                          "is-danger":
                            _vm.errors.has(
                              _vm.$t("component.register.additional_info.tel")
                            ) || _vm.hasErrorMsg("PhoneNumber"),
                        },
                        message:
                          _vm.errors.first(
                            _vm.$t("component.register.additional_info.tel")
                          ) || _vm.getErrorMsg("PhoneNumber"),
                      },
                    },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.isFlutter
                              ? "phoneE164"
                              : "required|phoneE164",
                            expression:
                              "isFlutter ? 'phoneE164' : 'required|phoneE164'",
                          },
                        ],
                        attrs: {
                          name: _vm.$t(
                            "component.register.additional_info.tel"
                          ),
                          type: "text",
                          placeholder: _vm.$t(
                            "component.register.additional_info.tel_format"
                          ),
                        },
                        on: { input: _vm.phoneNumberChanged },
                        model: {
                          value: _vm.registrationDto.PhoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.registrationDto, "PhoneNumber", $$v)
                          },
                          expression: "registrationDto.PhoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      staticClass: "required",
                      attrs: {
                        label: _vm.$t("component.register.company_name"),
                        type: {
                          "is-danger":
                            _vm.errors.has(
                              _vm.$t("component.register.company_name")
                            ) || _vm.hasErrorMsg("CompanyName"),
                        },
                        message:
                          _vm.errors.first(
                            _vm.$t("component.register.company_name")
                          ) || _vm.getErrorMsg("CompanyName"),
                      },
                    },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: !_vm.isInvited ? "required" : "",
                            expression: "!isInvited ? 'required' : ''",
                          },
                        ],
                        attrs: {
                          name: _vm.$t("component.register.company_name"),
                          type: "text",
                          placeholder: _vm.$t(
                            "component.register.company_name"
                          ),
                          disabled: _vm.isInvited,
                        },
                        model: {
                          value: _vm.companyName,
                          callback: function ($$v) {
                            _vm.companyName = $$v
                          },
                          expression: "companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          !_vm.isInvited
            ? _c("h3", { staticClass: "title is-3 has-text-centered" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("component.register.additional_info.title")) +
                    " "
                ),
              ])
            : _vm._e(),
          !_vm.isInvited
            ? _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c(
                      "b-field",
                      {
                        class: _vm.isFlutter ? "" : "required",
                        attrs: {
                          label: _vm.$t(
                            "component.register.additional_info.address"
                          ),
                          type: {
                            "is-danger": _vm.errors.has(
                              _vm.$t(
                                "component.register.additional_info.address"
                              )
                            ),
                          },
                          message: _vm.errors.first(
                            _vm.$t("component.register.additional_info.address")
                          ),
                        },
                      },
                      [
                        _c("b-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.isFlutter ? "" : "required",
                              expression: "isFlutter ? '' : 'required'",
                            },
                          ],
                          attrs: {
                            name: _vm.$t(
                              "component.register.additional_info.address"
                            ),
                            type: "text",
                            placeholder: _vm.$t(
                              "component.register.additional_info.address"
                            ),
                          },
                          model: {
                            value: _vm.additionalData.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.additionalData, "address", $$v)
                            },
                            expression: "additionalData.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "label" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.register.additional_info.connection"
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              type: "is-dark",
                              label: _vm.$t(
                                "component.register.additional_info.connection_note"
                              ),
                              multilined: "",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "has-text-grey",
                              attrs: {
                                size: "is-small",
                                icon: "question-circle",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      [
                        _c(
                          "b-radio-button",
                          {
                            attrs: { "native-value": _vm.connectionTypes.wifi },
                            on: {
                              input: function ($event) {
                                _vm.additionalData.connectionRange = null
                              },
                            },
                            model: {
                              value: _vm.additionalData.connection,
                              callback: function ($$v) {
                                _vm.$set(_vm.additionalData, "connection", $$v)
                              },
                              expression: "additionalData.connection",
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { size: "is-small", icon: "wifi" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.register.additional_info.connection_type.wifi"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-radio-button",
                          {
                            attrs: { "native-value": _vm.connectionTypes.eth },
                            on: {
                              input: function ($event) {
                                _vm.additionalData.connectionRange = null
                              },
                            },
                            model: {
                              value: _vm.additionalData.connection,
                              callback: function ($$v) {
                                _vm.$set(_vm.additionalData, "connection", $$v)
                              },
                              expression: "additionalData.connection",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "has-text-grey",
                              attrs: { size: "is-small", icon: "ethernet" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.register.additional_info.connection_type.ethernet"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-radio-button",
                          {
                            attrs: { "native-value": _vm.connectionTypes.none },
                            on: {
                              input: function ($event) {
                                _vm.additionalData.connectionRange = null
                              },
                            },
                            model: {
                              value: _vm.additionalData.connection,
                              callback: function ($$v) {
                                _vm.$set(_vm.additionalData, "connection", $$v)
                              },
                              expression: "additionalData.connection",
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { size: "is-small", icon: "times-circle" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.register.additional_info.connection_type.none"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "label" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.register.additional_info.signal_range"
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              type: "is-dark",
                              label: _vm.$t(
                                "component.register.additional_info.signal_range_note"
                              ),
                              multilined: "",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "has-text-grey",
                              attrs: {
                                size: "is-small",
                                icon: "question-circle",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      [
                        _c(
                          "b-radio-button",
                          {
                            attrs: {
                              "native-value": "true",
                              disabled:
                                _vm.additionalData.connection !=
                                _vm.connectionTypes.wifi,
                            },
                            model: {
                              value: _vm.additionalData.connectionRange,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.additionalData,
                                  "connectionRange",
                                  $$v
                                )
                              },
                              expression: "additionalData.connectionRange",
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { size: "is-small", icon: "check" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.register.additional_info.connection_range_value.yes"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-radio-button",
                          {
                            attrs: {
                              "native-value": "false",
                              disabled:
                                _vm.additionalData.connection !=
                                _vm.connectionTypes.wifi,
                            },
                            model: {
                              value: _vm.additionalData.connectionRange,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.additionalData,
                                  "connectionRange",
                                  $$v
                                )
                              },
                              expression: "additionalData.connectionRange",
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { size: "is-small", icon: "times" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.register.additional_info.connection_range_value.no"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          type: {
                            "is-danger": _vm.errors.has(
                              _vm.$t(
                                "component.register.additional_info.logger_no"
                              )
                            ),
                          },
                          message: _vm.errors.first(
                            _vm.$t(
                              "component.register.additional_info.logger_no"
                            )
                          ),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.register.additional_info.logger_no"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "component.register.additional_info.logger_no_note"
                                        ),
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "has-text-grey",
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3444725125
                        ),
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            name: _vm.$t(
                              "component.register.additional_info.logger_no"
                            ),
                            placeholder: _vm.$t(
                              "component.register.additional_info.logger_no"
                            ),
                          },
                          model: {
                            value: _vm.additionalData.loggerCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.additionalData, "loggerCount", $$v)
                            },
                            expression: "additionalData.loggerCount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          type: {
                            "is-danger": _vm.errors.has(
                              _vm.$t(
                                "component.register.additional_info.room_no"
                              )
                            ),
                          },
                          message: _vm.errors.first(
                            _vm.$t("component.register.additional_info.room_no")
                          ),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.register.additional_info.room_no"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "component.register.additional_info.room_no_note"
                                        ),
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "has-text-grey",
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2251075877
                        ),
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            name: _vm.$t(
                              "component.register.additional_info.room_no"
                            ),
                            placeholder: _vm.$t(
                              "component.register.additional_info.room_no"
                            ),
                          },
                          model: {
                            value: _vm.additionalData.roomCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.additionalData, "roomCount", $$v)
                            },
                            expression: "additionalData.roomCount",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "b-field",
            {
              staticClass: "required-control",
              attrs: {
                type: {
                  "is-danger": _vm.errors.has("privacy"),
                },
                message: _vm.errors.has("privacy")
                  ? _vm.$t("error_messages.required_field")
                  : null,
              },
            },
            [
              _c(
                "b-checkbox",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: { name: "privacy" },
                  model: {
                    value: _vm.privacyPolicyFlag,
                    callback: function ($$v) {
                      _vm.privacyPolicyFlag = $$v
                    },
                    expression: "privacyPolicyFlag",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "component.register.additional_info.privacy_policy"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "field pt-3" },
            [
              _c(
                "b-checkbox",
                {
                  model: {
                    value: _vm.additionalData.newsletter,
                    callback: function ($$v) {
                      _vm.$set(_vm.additionalData, "newsletter", $$v)
                    },
                    expression: "additionalData.newsletter",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("component.register.additional_info.newsletter")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c("p", { staticClass: "is-italic pb-3" }, [
            _c("span", { staticClass: "required only-asterisk" }),
            _vm._v(
              " - " +
                _vm._s(
                  _vm.$t("component.register.additional_info.required_note")
                ) +
                " "
            ),
          ]),
          _c(
            "b-button",
            {
              attrs: { expanded: "", type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.submitRegistration()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("component.login.register")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }