import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReportGenerationStatus } from '@/entities/enums/ReportGenerationStatus';
let ReportDetailModal = class ReportDetailModal extends Vue {
    constructor() {
        super(...arguments);
        this.ReportGenerationStatus = ReportGenerationStatus;
    }
    get sourcesInReport() {
        return this.sources.filter((x) => this.report.metadata.sourceIds.includes(x.id));
    }
    get typesAreObjects() {
        if (this.report.metadata?.types) {
            return this.report.metadata.types[0]?.Name != null;
        }
        else
            return false;
    }
    created() { }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    downloadReport() {
        this.$emit('downloadReport', this.report.fileName);
    }
    removeReport(report) {
        this.$emit('removeReport', report);
    }
};
__decorate([
    Prop({ type: Object })
], ReportDetailModal.prototype, "report", void 0);
__decorate([
    Prop({ type: Object })
], ReportDetailModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportDetailModal.prototype, "sources", void 0);
ReportDetailModal = __decorate([
    Component({ components: {} })
], ReportDetailModal);
export default ReportDetailModal;
