import { __decorate } from "tslib";
import VueUtilities from '@/services/VueUtilities';
import { Component, Vue, Prop } from 'vue-property-decorator';
const MAX_SECONDS = 60;
let DeviceSirenModal = class DeviceSirenModal extends Vue {
    constructor() {
        super(...arguments);
        this.sirenIntervals = [];
        this.MAX_SECONDS = MAX_SECONDS;
        this.isLoading = false;
    }
    created() {
        this.loadData();
    }
    loadData() {
        let parsedInterval = [];
        if (this.departmentSettings && this.departmentSettings.Notifications.Buzzer.Sequence) {
            parsedInterval = JSON.parse(this.departmentSettings.Notifications.Buzzer.Sequence);
            if (parsedInterval && parsedInterval.length > 0) {
                parsedInterval.forEach((num, index) => {
                    if (index % 2 === 0) {
                        this.sirenIntervals.push({
                            active: num,
                            pause: parsedInterval[index + 1] ?? 0 // Default to 0 if there's no pair
                        });
                    }
                });
            }
            else {
                this.sirenIntervals = [];
                this.addInterval();
            }
        }
        else {
            this.sirenIntervals = [];
            this.addInterval();
        }
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    addInterval() {
        this.sirenIntervals.push({
            active: 0,
            pause: 0
        });
    }
    removeInterval(index) {
        this.sirenIntervals.splice(index, 1);
    }
    totalSirenIntervalSum() {
        return this.sirenIntervals.reduce((sum, obj) => sum + obj.active + obj.pause, 0);
    }
    validateBeforeSubmit() {
        this.$validator.validateAll().then((result) => {
            if (result) {
                this.isLoading = true;
                if (this.totalSirenIntervalSum() <= MAX_SECONDS) {
                    const allIntervls = this.sirenIntervals.flatMap(({ active, pause }) => [active, pause]);
                    this.resultRetrieved(true);
                    this.$emit('submitSirenInterval', allIntervls);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.device.deviceSirenModal.table.maxIntervalSumError').toString());
                }
                this.isLoading = false;
            }
        });
    }
    errorMessage(id, isActiveSound) {
        var message;
        if (isActiveSound)
            message = this.errors.first(this.$t('component.device.deviceSirenModal.table.activeSound') + id.toString());
        else
            message = this.errors.first(this.$t('component.device.deviceSirenModal.table.pauseSound') + id.toString());
        if (message != undefined) {
            message = message.replace(id.toString(), '');
        }
        return message;
    }
};
__decorate([
    Prop({ type: Object })
], DeviceSirenModal.prototype, "departmentSettings", void 0);
DeviceSirenModal = __decorate([
    Component({ components: {} })
], DeviceSirenModal);
export default DeviceSirenModal;
