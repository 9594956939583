import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import LoggedUserManager from '@/services/LoggedUserManager';
import VueUtilities from '@/services/VueUtilities';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import AuthenticationCalls, { isLoginData } from '@/services/AuthenticationCalls';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import AppConfig from '@/configLoader';
import UserRepository from '@/services/repository/UserRepository';
var userSettingsRepository;
var authenticationCalls;
var userRepository;
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.name = 'Login';
        this.password = null;
        this.username = null;
        this.inputSize = '';
        this.loginButtonDisabled = false;
        this.toUrl = null;
        this.gitBranchString = process.env.BRANCH;
        this.gitVersionString = process.env.VERSION;
        this.selectedDepartmentId = null;
    }
    get demoAllowed() {
        return AppConfig.getConfig().features.demo == true;
    }
    async created() {
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
        userRepository = new UserRepository(this);
    }
    mounted() {
        this.$refs.email.focus();
    }
    async loginSucessfulAsync() {
        VueUtilities.openSuccessToast(this, this.$t('component.login.successful').toString());
        userSettingsRepository = UserSettingsRepository.getInstance(this, true);
        let me = await userRepository.getCurrentUser();
        LoggedUserManager.saveIdOfLoggedUser(this, me.apiUserId);
        this.$router.push({ name: 'home' }).catch((err) => err);
    }
    async submitCredentialsAsync() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.loginButtonDisabled = true;
                let loginData = await authenticationCalls.login(this.username, this.password);
                if (isLoginData(loginData)) {
                    const sessionPassword = loginData.session;
                    const apiUserFullName = loginData.apiUserFullName;
                    const authName = loginData.apiUserUsername;
                    LoggedUserManager.saveLoginData(this, authName, sessionPassword, apiUserFullName);
                    this.loginSucessfulAsync();
                }
                else {
                    let loginFailedResponse = loginData;
                    if (loginFailedResponse.code) {
                        switch (loginFailedResponse.code) {
                            case ApiResponseCodes.UNAUTHORIZED:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.unauthorized').toString()}`);
                                break;
                            case ApiResponseCodes.WRONG_CREDENTIALS:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.wrong_credentials').toString()}`);
                                break;
                            case ApiResponseCodes.ACCOUNT_DISABLED:
                                let reasonParts = loginFailedResponse.message.split(':');
                                reasonParts.shift();
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_disabled', {
                                    reason: reasonParts.join(':')
                                }).toString()}`);
                                break;
                            case ApiResponseCodes.ACCOUNT_NOT_VERIFIED:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_not_verified')}`);
                                break;
                            default:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
                                break;
                        }
                    }
                    else {
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
                    }
                }
                this.loginButtonDisabled = false;
            }
        });
    }
};
Login = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.toUrl = to.params.toUrl;
            });
        }
    })
], Login);
export default Login;
