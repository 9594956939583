var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.settings.administration.administration_settings"
                )
              ) +
              " "
          ),
        ]
      ),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("CompanyAdministration", {
                  staticClass: "box tile is-child",
                  attrs: {
                    currentDepartment: _vm.currentDepartment,
                    dateTimeManager: _vm.dateTimeManager,
                    isFavorite: _vm.isFavorite,
                  },
                  on: {
                    saveName: _vm.saveName,
                    saveFavorite: _vm.saveFavorite,
                    reload: _vm.loadData,
                  },
                }),
              ],
              1
            ),
            _vm.floorplanEnabled
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _vm.isUpdatingFloorPlan || _vm.isUploadingFloorPlan
                      ? _c("FloorPlanUpload", {
                          ref: "floorPlanUploadComp",
                          staticClass: "box tile is-child",
                          attrs: {
                            sources: _vm.sources,
                            devices: _vm.devices,
                            selectedFloorPlan: _vm.selectedFloorPlan,
                            selectedFloorPlanImage: _vm.selectedFloorPlanImage,
                            selectedFloorPlanLocationData:
                              _vm.selectedFloorPlanLocationData,
                            isUploading: _vm.isUploadingFloorPlan,
                            isUpdating: _vm.isUpdatingFloorPlan,
                          },
                          on: {
                            uploadFloorPlan: _vm.uploadFloorPlan,
                            updateFloorPlan: _vm.updateFloorPlan,
                            cancelEditingFloorplan: _vm.cancelEditingFloorplan,
                          },
                        })
                      : _c("FloorPlanOverview", {
                          ref: "floorplanOverviewComp",
                          staticClass: "box tile is-child",
                          attrs: {
                            sources: _vm.sources,
                            devices: _vm.devices,
                            floorPlans: _vm.floorPlans,
                            floorPlanThumbnails: _vm.floorPlanThumbnails,
                            selectedFloorPlan: _vm.selectedFloorPlan,
                            selectedFloorPlanImage: _vm.selectedFloorPlanImage,
                            selectedFloorPlanLocationData:
                              _vm.selectedFloorPlanLocationData,
                          },
                          on: {
                            deleteFloorPlan: _vm.deleteFloorPlan,
                            updateFloorPlan: _vm.updateFloorPlan,
                            uploadFloorPlan: _vm.uploadFloorPlan,
                            setSelectedFloorplan: _vm.setSelectedFloorplan,
                          },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.currentConfig.features.tiers === true &&
            _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.FEATURES)
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("TierDetail", {
                      staticClass: "box tile is-child",
                      attrs: {
                        dateTimeManager: _vm.dateTimeManager,
                        tier: _vm.tier,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _vm.$ability.can(_vm.$permActions.LIST, _vm.$permSubjects.USERS)
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("UserAdministration", {
                      staticClass: "box tile is-child",
                      attrs: { users: _vm.userList, userRoles: _vm.userRoles },
                      on: {
                        reload: _vm.loadData,
                        openInviteModal: _vm.openInviteModal,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("SentInvitationsManagement", {
                  staticClass: "box tile is-child",
                  attrs: {
                    sentInvitations: _vm.sentInvitations,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: {
                    revokeInvitation: _vm.revokeInvitation,
                    openResendInviteModal: _vm.openResendInviteModal,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor" }, [
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("ManageUserPresets", {
                  staticClass: "box tile is-child",
                  attrs: { presets: _vm.departmentPresets, types: _vm.types },
                  on: {
                    createPreset: _vm.createPreset,
                    updatePreset: _vm.updatePreset,
                    deletePreset: _vm.deletePreset,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isInviteModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeInviteUserModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isInviteModalActive = $event
            },
            "close-modal": _vm.closeInviteUserModal,
          },
        },
        [
          _c("InviteUserModal", {
            attrs: { users: _vm.userList },
            on: { modalClosed: _vm.closeInviteUserModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isResendInvitationModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeResendInviteModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isResendInvitationModalActive = $event
            },
            "close-modal": _vm.closeResendInviteModal,
          },
        },
        [
          _c("ResendInvitationModal", {
            attrs: { invitation: _vm.resendInvitation },
            on: { modalClosed: _vm.closeResendInviteModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }