import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import UserRepository from '@/services/repository/UserRepository';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import UserPreferences from '@/services/UserPreferences';
import LoggedUserManager from '@/services/LoggedUserManager';
var departmentRepository;
var userRepository;
let CreateCompanyModal = class CreateCompanyModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.companyName = null;
        this.companyIco = null;
        this.selectedUsers = new Array();
        this.assignUsers = new Array();
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
        this.currentFilter = [];
        this.page = null;
        this.pageSize = null;
        this.jumpToPageValue = null;
        this.emailSearch = '';
    }
    async created() {
        departmentRepository = new DepartmentRepository(this);
        userRepository = new UserRepository(this);
        this.fillFromPref();
        this.pagination = { page: this.page, pageSize: this.pageSize };
        this.$emit('loadUsers', this.pagination, this.currentFilter);
    }
    fillFromPref() {
        this.page = UserPreferences.LocalStorageKeys.AdminAddCompanyUsersPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.AdminAddCompanyUsersRowsPerPage.defaultValue;
    }
    onPageChange(page) {
        this.jumpToPageValue = page;
        this.pagination = { page: page, pageSize: this.pageSize };
        this.onOptionsChange(this.pagination);
    }
    onOptionsChange(pagination) {
        this.$emit('loadUsers', pagination, this.currentFilter);
    }
    filterUsers(event) {
        if (typeof event === 'string') {
            event = event.includes(',') ? event.replace(/,/g, '\\,') : event;
        }
        let index = this.filterExists('Email@=');
        if (index !== -1)
            this.currentFilter[index] = 'Email@=' + event;
        else
            this.currentFilter.push('Email@=' + event);
        this.onOptionsChange(this.pagination);
    }
    filterExists(filter) {
        return this.currentFilter.findIndex((element) => element.includes(filter));
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    deleteUnChecked(user) {
        if (user.length > 0) {
            this.assignUsers.forEach((element) => {
                if (user.find((x) => element.userId == x.id) == undefined) {
                    this.assignUsers.splice(this.assignUsers.indexOf(element), 1);
                }
            });
        }
        else {
            this.assignUsers = [];
        }
    }
    get availableUserRoles() {
        if (this.isResellerAdmin) {
            return Object.values(ApiUserRoles).filter((x) => x != ApiUserRoles.SYSTEM_ADMIN && x != ApiUserRoles.RESELLER_ADMIN);
        }
        else {
            return Object.values(ApiUserRoles);
        }
    }
    //Assign user role
    selectRole(user, role) {
        let usera = this.assignUsers.find((x) => user.id == x.userId);
        if (usera != undefined) {
            //changing user role
            usera.userRole = role;
        }
        else {
            this.assignUsers.push({
                userId: user.id,
                fullName: user.fullName,
                userRole: role,
                email: user.email
            });
        }
    }
    async newCompany() {
        if (this.selectedUsers.length > this.assignUsers.length) {
            VueUtilities.openErrorToast(this, this.$t('admin.component.company.createCompanyModal.errorMissingRoles'));
            return;
        }
        this.$validator.validateAll().then(async (result) => {
            let ownerRoleCount = this.assignUsers.filter((x) => x.userRole == ApiUserRoles.COMPANY_OWNER).length;
            if (ownerRoleCount >= 2) {
                VueUtilities.openErrorToast(this, this.$t('admin.component.company.createCompanyModal.errorOwnerCount'));
            }
            else {
                if (result) {
                    this.isLoading = true;
                    let fresult;
                    let data = {
                        CompanyName: this.companyName,
                        Ico: this.companyIco
                    };
                    this.newCompanyCreated = await departmentRepository.adminCreateDepartment(data);
                    if (result) {
                        let usersPromises = new Array();
                        this.assignUsers.forEach((user) => {
                            usersPromises.push(this.assignCompanyUsers(this.newCompanyCreated.CompanyId, user.userId, user.userRole));
                        });
                        fresult = await Promise.all(usersPromises)
                            .then((response) => {
                            return true;
                        })
                            .catch((error) => {
                            VueUtilities.openErrorToast(this, `${this.$t('admin.component.company.createCompanyModal.failure').toString()} ${error.response.data.message}`);
                            this.isLoading = false;
                            return false;
                        });
                    }
                    if (fresult) {
                        VueUtilities.openSuccessToast(this, this.$t('admin.component.company.createCompanyModal.success'));
                        this.resultRetrieved(true);
                        this.$emit('reload');
                    }
                    else {
                        VueUtilities.openErrorToast(this, this.$t('admin.component.company.createCompanyModal.failure'));
                        this.resultRetrieved(false);
                    }
                    this.isLoading = false;
                }
            }
        });
    }
    async assignCompanyUsers(departmentId, userId, userRole) {
        return userRepository.assignAdminUserRole(departmentId, userId, userRole);
    }
};
__decorate([
    Prop({ type: Array, default: [] })
], CreateCompanyModal.prototype, "users", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], CreateCompanyModal.prototype, "usersAllowed", void 0);
__decorate([
    Prop({ type: Number })
], CreateCompanyModal.prototype, "totalSize", void 0);
CreateCompanyModal = __decorate([
    Component({})
], CreateCompanyModal);
export default CreateCompanyModal;
