var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading &&
      _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.ADMIN_LOGGERS)
        ? _c(
            "div",
            [
              _c("AdminLoggerDetail", {
                attrs: {
                  logger: _vm.logger,
                  companyLogger: _vm.companyLogger,
                  dateTimeManager: _vm.dateTimeManager,
                  chartData: _vm.chartData,
                  isAdminLoggerChartLoading: _vm.isAdminLoggerChartLoading,
                  selectedFromDate: _vm.selectedFromDate,
                },
                on: {
                  reloadLogger: _vm.reloadLogger,
                  revertDevice: _vm.revertDevice,
                  returnFromDepartment: _vm.returnFromDepartment,
                  readData: _vm.readData,
                  selectedFromDateChange: _vm.selectedFromDateChange,
                  createVerificationKey: _vm.createVerificationKey,
                  deleteVerificationKey: _vm.deleteVerificationKey,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }