import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import { Department } from '@/entities/models/Department';
import { ServiceState } from '@/entities/enums/ServiceState';
import AllowedTypesModal from './AllowedTypesModal.vue';
var companyLoggerRepository;
let AdminLoggerInfoComponent = class AdminLoggerInfoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.CalibrationStatus = CalibrationStatus;
        this.ServiceState = ServiceState;
        this.isEditing = false;
        this.isLoading = true;
        this.noteEdit = null;
        this.noteObj = null;
        this.noteJSON = null;
        this.formattedJsonString = null;
        this.isTypesModalActive = false;
    }
    get availableTypes() {
        return this.companyLogger.availableTypes.map((x) => this.$t('services.' + x.slug)).join(', ');
    }
    get allowedTypes() {
        return this.companyLogger.allowedTypes.map((x) => this.$t('services.' + x.slug)).join(', ');
    }
    get noCoordinates() {
        let hasAtmosphericPressure = this.companyLogger.computedTypes?.some((x) => x.producesType.slug === 'atmosphericpressure');
        let hasCoordinates = this.companyLogger.coordinates?.Altitude == null ||
            this.companyLogger.coordinates?.Latitude == null ||
            this.companyLogger.coordinates?.Longitude == null;
        return hasAtmosphericPressure && hasCoordinates;
    }
    get hasChanges() {
        return this.noteEdit !== this.logger.note;
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.loadData();
    }
    loadData() {
        this.isLoading = true;
        this.noteEdit = this.logger.note;
        this.formattedJsonString = this.checkJsonString();
        this.isLoading = false;
    }
    //Checking if the note is already a JSON object/string convert to formatted JSON String
    checkJsonString() {
        this.noteJSON = this.logger.note;
        if (this.noteJSON != null) {
            try {
                this.noteObj = JSON.parse(this.noteJSON);
                this.noteJSON = JSON.stringify(this.noteObj, null, 1);
                return this.noteJSON;
            }
            catch (e) {
                return this.convertJSON();
            }
        }
    }
    //Checking if the note is Js object, Convert Js object to JSON string
    convertJSON() {
        this.noteJSON = this.noteJSON
            .replaceAll('  ', ' ')
            .replaceAll(', ', ',')
            .replaceAll('{ ', '{')
            .replaceAll(' :', ':');
        for (let i = 0; i <= this.noteJSON.length; i++) {
            if (this.noteJSON[i] == ':') {
                this.changenote(i);
                i++;
            }
        }
        for (let i = 0; i <= this.noteJSON.length; i++) {
            if (this.noteJSON[i] == '{') {
                this.changenote(i + 1);
            }
        }
        for (let i = 0; i <= this.noteJSON.length; i++) {
            if (this.noteJSON[i] == ',') {
                this.changenote(i + 1);
            }
        }
        try {
            this.noteObj = JSON.parse(this.noteJSON);
            this.noteJSON = JSON.stringify(this.noteObj, null, 1);
            return this.noteJSON;
        }
        catch (e) {
            // If the note is not a JSON object or Js object so return normal string as a note
            return this.logger.note;
        }
    }
    changenote(i) {
        this.noteJSON = [this.noteJSON.slice(0, i), '"', this.noteJSON.slice(i)].join('');
    }
    openEdit() {
        this.isEditing = true;
    }
    closeEdit() {
        this.isEditing = false;
    }
    saveChanges() {
        this.$emit('saveLoggerEdit', this.noteEdit);
    }
    typesSelected(selectedTypes, selectedComputedTypes) {
        this.$emit('typesSelected', selectedTypes, selectedComputedTypes);
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.adminDownloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    hasCalibrationProtocolToDownload(logger) {
        if (logger == null || logger.calibration == null || logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
    hasPermissionToViewDepartment(departmentId) {
        return Department.hasPermissionToViewDepartment(departmentId, this);
    }
    openTypesModal() {
        this.isTypesModalActive = true;
    }
    closeModal() {
        this.isTypesModalActive = false;
    }
};
__decorate([
    Prop({ type: Object })
], AdminLoggerInfoComponent.prototype, "logger", void 0);
__decorate([
    Prop({ type: Object })
], AdminLoggerInfoComponent.prototype, "companyLogger", void 0);
__decorate([
    Prop({ type: Array })
], AdminLoggerInfoComponent.prototype, "computedEventTypes", void 0);
__decorate([
    Prop({ type: Object })
], AdminLoggerInfoComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AdminLoggerInfoComponent.prototype, "isLayoutList", void 0);
AdminLoggerInfoComponent = __decorate([
    Component({
        components: { AllowedTypesModal }
    })
], AdminLoggerInfoComponent);
export default AdminLoggerInfoComponent;
