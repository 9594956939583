import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import AuthenticationCalls, { isLoginData } from '@/services/AuthenticationCalls';
import LoggedUserManager from '@/services/LoggedUserManager';
import VueUtilities from '@/services/VueUtilities';
import AppConfig from '@/configLoader';
import UserRepository from '@/services/repository/UserRepository';
var authenticationCalls;
var userRepository;
let DemoLogin = class DemoLogin extends Vue {
    constructor() {
        super(...arguments);
        this.selectedDepartmentId = null;
        this.isLoading = false;
    }
    async created() {
        this.isLoading = true;
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
        userRepository = new UserRepository(this);
        await this.submitDemoCredentialsAsync();
        this.isLoading = false;
    }
    async submitDemoCredentialsAsync() {
        let loginData = await authenticationCalls.login(AppConfig.getConfig().demo.name, AppConfig.getConfig().demo.pswd);
        if (isLoginData(loginData)) {
            const sessionPassword = loginData.session;
            const apiUserFullName = loginData.apiUserFullName;
            const authName = loginData.apiUserUsername;
            LoggedUserManager.saveLoginData(this, authName, sessionPassword, apiUserFullName);
            this.loginSucessfulAsync();
        }
        else {
            let loginFailedResponse = loginData;
            if (loginFailedResponse.code) {
                switch (loginFailedResponse.code) {
                    case ApiResponseCodes.UNAUTHORIZED:
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.unauthorized').toString()}`);
                        break;
                    case ApiResponseCodes.WRONG_CREDENTIALS:
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.wrong_credentials').toString()}`);
                        break;
                    case ApiResponseCodes.ACCOUNT_DISABLED:
                        let reasonParts = loginFailedResponse.message.split(':');
                        reasonParts.shift();
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_disabled', {
                            reason: reasonParts.join(':')
                        }).toString()}`);
                        break;
                    case ApiResponseCodes.ACCOUNT_NOT_VERIFIED:
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_not_verified')}`);
                        break;
                    default:
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
                        break;
                }
            }
            else {
                VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
            }
        }
    }
    async loginSucessfulAsync() {
        VueUtilities.openSuccessToast(this, this.$t('component.login.successful').toString());
        let me = await userRepository.getCurrentUser();
        LoggedUserManager.saveIdOfLoggedUser(this, me.apiUserId);
        this.$router.push({ name: 'home' }).catch((err) => err);
    }
};
DemoLogin = __decorate([
    Component
], DemoLogin);
export default DemoLogin;
