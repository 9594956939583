var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "is-flex is-align-items-flex-start is-justify-content-flex-end",
        staticStyle: { position: "relative" },
      },
      [
        _c(
          "div",
          {
            staticClass: "canvas-container",
            attrs: { id: "canvas-container" + _vm.canvasId },
          },
          [
            _c("canvas", {
              staticStyle: { display: "block" },
              attrs: { id: _vm.canvasId, width: "2px", height: "2px" },
              on: {
                mousemove: function ($event) {
                  return _vm.displayName($event)
                },
                click: function ($event) {
                  return _vm.redirect($event)
                },
                touchstart: _vm.handleTouchStart,
                touchend: _vm.handleTouchEnd,
              },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "is-flex floorplan-buttons",
            staticStyle: { position: "absolute" },
          },
          [
            _vm.floorPlans.length > 1
              ? _c("b-button", {
                  staticClass: "mr-1",
                  attrs: { inverted: "", "icon-left": "chevron-left" },
                  on: {
                    click: function ($event) {
                      return _vm.getNextFloorPlanId("left")
                    },
                  },
                })
              : _vm._e(),
            _vm.floorPlans.length > 1
              ? _c("b-button", {
                  staticClass: "mr-1",
                  attrs: { inverted: "", "icon-left": "chevron-right" },
                  on: {
                    click: function ($event) {
                      return _vm.getNextFloorPlanId("right")
                    },
                  },
                })
              : _vm._e(),
            !_vm.isFullscreen
              ? _c("b-button", {
                  attrs: { inverted: "", "icon-left": "expand-arrows-alt" },
                  on: {
                    click: function ($event) {
                      return _vm.openFullScreen()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _vm.floorPlans.length > 1 && _vm.selectedFloorPlan
      ? _c(
          "div",
          {
            staticClass:
              "is-flex is-justify-content-center is-flex-wrap-wrap mt-2",
          },
          _vm._l(_vm.floorPlans, function (floorPlan) {
            return _c("b-icon", {
              key: floorPlan.id,
              staticClass: "mx-1 mb-2 is-clickable",
              attrs: {
                size: "is-small",
                pack: floorPlan.id === _vm.selectedFloorPlan.id ? "fas" : "far",
                icon: "circle",
                type: "is-primary",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.setSelectedFloorplan(floorPlan.id)
                },
              },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }