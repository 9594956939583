export default class User {
    constructor() {
        this.detailedInfo = {};
    }
    isAdmin() {
        return this.role?.IsAdmin === true;
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => User.define(et));
        }
        else if (data != null) {
            return User.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let user = new User();
        user.apiUserId = data.ApiUserId;
        user.id = data.ApiUserId;
        user.email = data.Email;
        user.fullName = data.FullName;
        user.username = data.Username;
        user.role = data.Role;
        user.detailedInfo = data.DetailedInfo;
        return user;
    }
}
export class DepartmentUser extends User {
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => DepartmentUser.define(et));
        }
        else if (data != null) {
            return DepartmentUser.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let user = new DepartmentUser();
        user.apiUserId = data.ApiUserId;
        user.id = data.ApiUserId;
        user.email = data.Email;
        user.fullName = data.FullName;
        user.username = data.Username;
        user.enabled = data.Enabled;
        user.role = data.Role;
        user.phoneNumber = data.PhoneNumber;
        user.assignedAt = data.AssignedAt;
        return user;
    }
}
