var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "section-header-level" },
        [
          _c("div", { staticClass: "title-with-edit" }, [
            _c("h2", { staticClass: "subtitle is-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "admin.component.company.detail.companyLoggers.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c(
            "b-dropdown",
            {
              attrs: { position: "is-bottom-left", "aria-role": "list" },
              scopedSlots: _vm._u([
                {
                  key: "trigger",
                  fn: function () {
                    return [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.company.list.actions.columns"
                            ),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            { staticStyle: { "font-size": "1rem" } },
                            [
                              _c("b-icon", {
                                attrs: { type: "is-primary", icon: "list" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            _vm._l(_vm.tableColumns, function (column) {
              return _c(
                "b-dropdown-item",
                {
                  key: column.field,
                  staticClass: "custom-dropdown-item",
                  attrs: {
                    focusable: false,
                    custom: "",
                    "aria-role": "listitem",
                  },
                },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: {
                        disabled:
                          column.field == _vm.tableColumns.serialNumber.field,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.chooseTableColumns(column.field, $event)
                        },
                      },
                      model: {
                        value: column.visible,
                        callback: function ($$v) {
                          _vm.$set(column, "visible", $$v)
                        },
                        expression: "column.visible",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `admin.component.company.detail.companyLoggers.list.${column.field}`
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "table-wrap",
          attrs: {
            data: _vm.loggerList,
            paginated: "",
            "per-page": _vm.pageSize,
            total: _vm.loggerList.length,
            "current-page": _vm.page,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            "row-class": (row) =>
              !row.active ? "is-disabled has-text-grey" : "",
            "pagination-order": "is-centered",
          },
          on: {
            "page-change": _vm.onPageChange,
            sort: _vm.onSort,
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.company.detail.companyLoggers.list.empty"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "bottom-left",
              fn: function () {
                return [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.notifications_list.table.row_per_page"
                        )
                      )
                    ),
                  ]),
                  _c(
                    "b-field",
                    { staticClass: "pl-2 mb-4-mobile" },
                    [
                      _c(
                        "b-select",
                        {
                          model: {
                            value: _vm.pageSize,
                            callback: function ($$v) {
                              _vm.pageSize = $$v
                            },
                            expression: "pageSize",
                          },
                        },
                        _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                          return _c(
                            "option",
                            { key: option, domProps: { value: option } },
                            [_vm._v(" " + _vm._s(option) + " ")]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.loggerId.field,
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.loggerId.field}`
              ),
              visible: _vm.tableColumns.loggerId.visible,
              sortable: _vm.tableColumns.loggerId.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.active
                      ? _c(
                          "router-link",
                          {
                            staticClass: "is-family-monospace",
                            attrs: {
                              to: {
                                name: "adminLoggerDetail",
                                params: {
                                  loggerId: loggerList.row.loggerId,
                                  title: _vm.company.companyName,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(loggerList.row.loggerId))]
                        )
                      : _c("p", { staticClass: "is-family-monospace" }, [
                          _vm._v(" " + _vm._s(loggerList.row.loggerId) + " "),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.macAddress.field,
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.macAddress.field}`
              ),
              visible: _vm.tableColumns.macAddress.visible,
              sortable: _vm.tableColumns.macAddress.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.active
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminLoggerDetail",
                                params: {
                                  loggerId: loggerList.row.loggerId,
                                  title: _vm.company.companyName,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(loggerList.row.macAddress))]
                        )
                      : _c("p", [
                          _vm._v(" " + _vm._s(loggerList.row.macAddress) + " "),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.serialNumber.field,
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.serialNumber.field}`
              ),
              visible: _vm.tableColumns.serialNumber.visible,
              sortable: _vm.tableColumns.serialNumber.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.serialNumber && loggerList.row.active
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminLoggerDetail",
                                params: {
                                  loggerId: loggerList.row.loggerId,
                                  title: _vm.company.companyName,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(loggerList.row.serialNumber))]
                        )
                      : _c("p", [
                          _vm._v(
                            " " + _vm._s(loggerList.row.serialNumber) + " "
                          ),
                        ]),
                    loggerList.row.serviceState == _vm.ServiceState.ERROR
                      ? _c(
                          "b-tag",
                          {
                            staticClass: "ml-1",
                            attrs: { rounded: "", type: "is-danger" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("loggers.logger_states.Error")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "source.name",
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.sourceName.field}`
              ),
              visible: _vm.tableColumns.sourceName.visible,
              sortable: _vm.tableColumns.sourceName.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.source
                      ? _c("span", [_vm._v(_vm._s(loggerList.row.source.name))])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.lastSeen.field,
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.lastSeen.field}`
              ),
              visible: _vm.tableColumns.lastSeen.visible,
              sortable: _vm.tableColumns.lastSeen.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(loggerList.row.lastSeen * 1000),
                            null,
                            null,
                            null
                          )
                        )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "calibration.status.name",
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.calibration.field}`
              ),
              visible: _vm.tableColumns.calibration.visible,
              sortable: _vm.tableColumns.calibration.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-align-items-center ml-3-mobile",
                      },
                      [
                        loggerList.row.calibration
                          ? _c(
                              "b-tag",
                              {
                                attrs: {
                                  rounded: "",
                                  type: loggerList.row.calibration.status.type,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `calibration_status.${loggerList.row.calibration.status.name}`
                                    )
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "b-tag",
                              {
                                attrs: {
                                  type: _vm.CalibrationStatus.UNKNOWN.type,
                                  rounded: "",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `calibration_status.${_vm.CalibrationStatus.UNKNOWN.name}`
                                    )
                                  )
                                ),
                              ]
                            ),
                        _vm.hasCalibrationProtocolToDownload(loggerList.row) &&
                        _vm.$ability.can(
                          _vm.$permActions.PROTOCOL,
                          _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
                        )
                          ? [
                              _c(
                                "b-tooltip",
                                {
                                  staticClass: "is-hidden-mobile",
                                  attrs: {
                                    label: _vm.$t("generals.download"),
                                    position: "is-left",
                                    type: "is-light",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "control-icon clickable",
                                    attrs: { icon: "file-download" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.downloadCalibration(
                                          loggerList.row.companyLoggerId
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "is-hidden-tablet" },
                                [
                                  _c("b-icon", {
                                    staticClass: "control-icon clickable",
                                    attrs: { icon: "file-download" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.downloadCalibration(
                                          loggerList.row.companyLoggerId
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "calibration.expiresAt",
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.expiration.field}`
              ),
              visible: _vm.tableColumns.expiration.visible,
              sortable: _vm.tableColumns.expiration.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.calibration
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.dateTimeManager.formatTime(
                                new Date(
                                  loggerList.row.calibration.expiresAt * 1000
                                ),
                                null,
                                null,
                                null
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.isOnline.field,
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.isOnline.field}`
              ),
              visible: _vm.tableColumns.isOnline.visible,
              sortable: _vm.tableColumns.isOnline.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.isOnline === false
                      ? _c(
                          "b-tag",
                          { attrs: { type: "is-primary", rounded: "" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("component.last_events.table.no_signal")
                              )
                            ),
                          ]
                        )
                      : loggerList.row.isOnline === true
                      ? _c(
                          "b-tag",
                          { attrs: { type: "is-success", rounded: "" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("component.last_events.table.has_signal")
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.active.field,
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.active.field}`
              ),
              visible: _vm.tableColumns.active.visible,
              sortable: _vm.tableColumns.active.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _c("b-icon", {
                      attrs: {
                        icon: loggerList.row.active ? "check" : "times",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.battery.field,
              label: _vm.$t(
                `admin.component.company.detail.companyLoggers.list.${_vm.tableColumns.battery.field}`
              ),
              visible: _vm.tableColumns.battery.visible,
              sortable: _vm.tableColumns.battery.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.battery
                      ? _c("p", [
                          _vm._v(" " + _vm._s(loggerList.row.battery) + " "),
                        ])
                      : _c("p", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }