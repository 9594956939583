import i18n from '@/lang/lang';
import { ServiceNames } from 'd3Chart';
import GeneralChartDataConverter from './GeneralChartDataConverter';
import UnitNames from '@/entities/enums/UnitNames';
export class ChartDataConverter extends GeneralChartDataConverter {
    constructor(entityArray, uptimeEntityArray, chartData, uptimeChartData, eventTypes, annotationsForSources) {
        super();
        this._hasData = false;
        this._services = this.createServices(chartData, uptimeChartData, uptimeEntityArray, annotationsForSources);
        this._data = this.convertData(chartData, entityArray, uptimeChartData, uptimeEntityArray, annotationsForSources);
        this._boundaries = this.convertBoundaries(entityArray, eventTypes);
        this._hasData = this.checkIfHasData(chartData, uptimeChartData, annotationsForSources);
    }
    get services() {
        return this._services;
    }
    get data() {
        return this._data;
    }
    get boundaries() {
        return this._boundaries;
    }
    get hasData() {
        return this._hasData;
    }
    /**
     * If there is multiple boundaries for one service, this method returns empty array.
     * (We don't want to draw so many lines in chart)
     */
    convertBoundaries(entityArray, eventTypes) {
        let convertedBoundaries = new Array();
        let hasMoreBoundariesForOneService = false;
        entityArray.forEach((entity) => {
            entity.boundaries.forEach((boundary) => {
                let boundaryEventType = eventTypes.find((x) => x.slug == boundary.eventTypeSlug);
                if (!boundaryEventType)
                    return;
                let newBoundary = {
                    serviceId: boundaryEventType.type,
                    values: [boundary.lowerAlarmBoundary.Value, boundary.upperAlarmBoundary.Value]
                };
                let alreadyExists = convertedBoundaries.filter((x) => x.serviceId == newBoundary.serviceId &&
                    x.values[0] == newBoundary.values[0] &&
                    x.values[1] == newBoundary.values[1]).length >= 1;
                if (!alreadyExists)
                    convertedBoundaries.push(newBoundary);
                hasMoreBoundariesForOneService =
                    convertedBoundaries.filter((x) => x.serviceId == newBoundary.serviceId).length > 1;
            });
        });
        if (hasMoreBoundariesForOneService)
            return [];
        else
            return convertedBoundaries;
    }
    checkIfHasData(chartData, uptimeChartData, annotationsForSources) {
        let hasData = false;
        if (chartData != null) {
            Object.keys(chartData).forEach((sourceId) => {
                Object.keys(chartData[sourceId]).forEach((serviceName) => {
                    if (serviceName)
                        hasData = true;
                });
            });
        }
        if (uptimeChartData != null) {
            Object.keys(uptimeChartData).forEach((sourceId) => {
                if (sourceId)
                    hasData = true;
            });
        }
        if (annotationsForSources.length > 0) {
            annotationsForSources.forEach((sourceAnnotation) => {
                if (sourceAnnotation.length > 0)
                    hasData = true;
            });
        }
        return hasData;
    }
    static compare(a, b) {
        if (a.time < b.time) {
            return -1;
        }
        if (a.time > b.time) {
            return 1;
        }
        return 0;
    }
    convertData(chartData, entityArray, uptimeChartData, uptimeEntityArray, annotationsForSources) {
        let timeMin = 0;
        let timeMax = 0;
        let convertedData = new Array();
        if (chartData != null) {
            Object.keys(chartData).forEach((sourceId) => {
                Object.keys(chartData[sourceId]).forEach((serviceName) => {
                    timeMin = chartData[sourceId][serviceName].info.timeMin;
                    timeMax = chartData[sourceId][serviceName].info.timeMax;
                    let borderValues = {
                        max_value: chartData[sourceId][serviceName].info.valueMax,
                        min_value: chartData[sourceId][serviceName].info.valueMin,
                        min_time: chartData[sourceId][serviceName].info.timeMin * 1000,
                        max_time: chartData[sourceId][serviceName].info.timeMax * 1000
                    };
                    let data = new Array();
                    chartData[sourceId][serviceName].data.forEach((dataUnit) => {
                        data.push({ time: dataUnit[0] * 1000, value: dataUnit[1] });
                    });
                    let loggerData = {
                        data: data,
                        metadata: borderValues,
                        serviceId: chartData[sourceId][serviceName].unit.id,
                        name: entityArray.find((x) => x.id == sourceId).name,
                        serviceName: i18n.t(`services.${serviceName.toLowerCase()}`)
                    };
                    convertedData.push(loggerData);
                });
            });
        }
        if (uptimeChartData != null) {
            Object.keys(uptimeChartData).forEach((sourceId) => {
                uptimeEntityArray.find((uptimeEntity) => {
                    if (uptimeEntity.id == sourceId) {
                        let data = new Array();
                        uptimeChartData[sourceId].forEach((dataUnit) => {
                            data.push({ time: dataUnit[0] * 1000, value: dataUnit[1] * 1000 });
                        });
                        let borderValues = {
                            max_value: 0,
                            min_value: 0,
                            min_time: timeMin == 0 ? uptimeChartData[sourceId][0][0] * 1000 : timeMin * 1000,
                            max_time: timeMax == 0 ? new Date().getTime() : timeMax * 1000
                        };
                        let loggerData = {
                            data: data,
                            metadata: borderValues,
                            serviceId: 5,
                            name: uptimeEntity.name,
                            serviceName: i18n.t(`services.uptime`)
                        };
                        convertedData.push(loggerData);
                    }
                });
            });
        }
        if (annotationsForSources.length > 0) {
            annotationsForSources.forEach((sourceAnnotation) => {
                if (sourceAnnotation.length > 0) {
                    let data = new Array();
                    sourceAnnotation.forEach((annotation) => {
                        data.push({
                            time: annotation.startDate * 1000,
                            toTime: annotation.endDate * 1000,
                            value: annotation.text
                        });
                    });
                    data = data.sort(ChartDataConverter.compare);
                    let borderValues = {
                        max_value: 0,
                        min_value: 0,
                        min_time: timeMin == 0 ? sourceAnnotation[0].startDate * 1000 : timeMin * 1000,
                        max_time: timeMax == 0 ? new Date().getTime() : timeMax * 1000
                    };
                    let loggerData = {
                        data: data,
                        metadata: borderValues,
                        serviceId: 101,
                        name: entityArray.find((x) => x.id == sourceAnnotation[0].sourceId).name,
                        serviceName: i18n.t(`services.annotation`)
                    };
                    convertedData.push(loggerData);
                }
            });
        }
        return convertedData;
    }
    getServiceDataByName(service) {
        switch (service) {
            case UnitNames.TEMPERATURE.name:
                return UnitNames.TEMPERATURE;
            case UnitNames.HUMIDITY.name:
                return UnitNames.HUMIDITY;
            case UnitNames.DEWPOINT.name:
                return UnitNames.DEWPOINT;
        }
    }
    createServices(chartData, uptimeChartData, uptimeEntityArray, annotationsForSources) {
        let services = new Array();
        if (chartData != null) {
            Object.keys(chartData).forEach((sourceId) => {
                Object.keys(chartData[sourceId]).forEach((serviceName) => {
                    if (serviceName.toLowerCase() != ServiceNames.DEWPOINT) {
                        let existService = services.find((x) => x.name == i18n.t(`services.${serviceName.toLowerCase()}`));
                        if (existService == null) {
                            let service = {
                                name: i18n.t(`services.${serviceName.toLowerCase()}`),
                                originalName: serviceName.toLowerCase(),
                                sign: chartData[sourceId][serviceName].unit.unit,
                                id: chartData[sourceId][serviceName].unit.id
                            };
                            services.push(service);
                        }
                    }
                });
            });
        }
        if (uptimeChartData != null) {
            Object.keys(uptimeChartData).forEach((sourceId) => {
                uptimeEntityArray.find((uptimeEntity) => {
                    if (uptimeEntity.id == sourceId) {
                        let existService = services.find((x) => x.name == i18n.t(`services.uptime`));
                        if (existService == null) {
                            let service = {
                                name: i18n.t(`services.uptime`),
                                originalName: ServiceNames.UPTIME,
                                sign: 's',
                                id: 5
                            };
                            services.push(service);
                        }
                    }
                });
            });
        }
        if (annotationsForSources.length > 0) {
            annotationsForSources.forEach((sourceAnnotation) => {
                if (sourceAnnotation.length > 0) {
                    let existService = services.find((x) => x.name == i18n.t(`services.annotation`));
                    if (existService == null) {
                        let service = {
                            name: i18n.t(`services.annotation`),
                            originalName: ServiceNames.ANNOTATION,
                            sign: null,
                            id: 101
                        };
                        services.push(service);
                    }
                }
            });
        }
        services = services.sort((a, b) => (a.id > b.id ? 1 : -1));
        return services;
    }
}
