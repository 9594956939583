import { __decorate } from "tslib";
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NotificationRuleDisplayMode } from '@/entities/notifications/NotificationRuleDisplayMode';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import DelayOptions from '@/entities/enums/DelayOptions';
import RepeatOptions from '@/entities/enums/RepeatOptions';
import RequestState from '@/entities/enums/RequestState';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import NotificationPriority from '@/entities/enums/NotificationPriority';
import NotificationChannelType from '@/entities/notifications/NotificationChannelType';
import SourceRepository from '@/services/repository/SourceRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
var notificationSubscriptionsRepository;
var sourceRepository;
var sourceGroupRepository;
/**
 * This component is used for advanced measured data alarm notifications management
 * Advanced = scope is source/sourcegroup.
 * For basic rules (with company/department scope) seeMeasuredDataNotification.vue
 */
let EntityMeasuredDataNotification = class EntityMeasuredDataNotification extends Vue {
    constructor() {
        super(...arguments);
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.NotificationMeasuredRuleScope = NotificationMeasuredRuleScope;
        this.NotificationRuleDisplayMode = NotificationRuleDisplayMode;
        this.NotificationScopeType = NotificationScopeType;
        this.RepeatOptions = RepeatOptions;
        this.currentDisplayMode = NotificationRuleDisplayMode.BASIC;
        this.selectedMode = null;
        this.requestState = null;
        this.sources = [];
        this.sourceGroups = [];
        this.isLoading = false;
        this.measuredRules = [];
    }
    get measuredType() {
        let currentType = JSON.parse(JSON.stringify(this.types.find((x) => x.name == NotificationSubscriptionType.MEASURED_DATA_ALARM)));
        return this.filterAvailableChannels(currentType);
    }
    /**
     * Returns true if currently selected display mode is basic
     */
    get isBasic() {
        return this.currentDisplayMode === null || this.currentDisplayMode === NotificationRuleDisplayMode.BASIC;
    }
    /**
     * Returns number of columns for table of settings. Number of columns depends on notification type,
     * allowed channels and selected display mode.
     */
    get numberOfColumns() {
        let count = this.measuredType.channel.length + 1;
        if (!this.isBasic)
            count++;
        return count;
    }
    get delayOptions() {
        return DelayOptions.getValues();
    }
    get requestFinished() {
        return this.requestState === RequestState.SUCCESS;
    }
    get requestPending() {
        return this.requestState === RequestState.PENDING;
    }
    get requestError() {
        return this.requestState === RequestState.ERROR;
    }
    /**
     * Returns true when user has not the phone number saved in his profile
     */
    get phoneIsNotDefined() {
        return this.currentUser.detailedInfo.PhoneNumber == null;
    }
    /**
     * Returns true when user has the phone number saved in his profile but this number wasn't verified
     */
    get phoneIsNotVerified() {
        return this.currentUser.detailedInfo.PhoneNumber.Verified == false;
    }
    /**
     * Check if user cas use phone channels for notifications (Phone/SMS)
     */
    get phoneChannelsAvailable() {
        return !this.phoneIsNotDefined && !this.phoneIsNotVerified;
    }
    /**
   * Check if buzzer is allowed in tier for currently selected department
   *
   */
    get buzzerIsAllowed() {
        return this.tier?.features.find((x) => x.Name === 'Observer.BuzzerNotifications')?.Value?.Allowed === true;
    }
    async created() {
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        sourceRepository = new SourceRepository(this);
        this.isLoading = true;
        //Create local instance of rule to avoid error about prop mutation
        this.selectedMode = this.selectedMeasuredRulesMode;
        this.currentDisplayMode =
            this.rules.find((x) => x.displayMode === NotificationRuleDisplayMode.ADVANCED) != null
                ? NotificationRuleDisplayMode.ADVANCED
                : NotificationRuleDisplayMode.BASIC;
        await this.loadEntities();
        this.buildRules();
    }
    filterAvailableChannels(type) {
        if (this.buzzerIsAllowed === false || !this.$ability.can(Actions.MANAGEOWN, Subjects.COMPANY))
            type.channel = type.channel.filter(x => x.type !== NotificationChannelType.BUZZER);
        return type;
    }
    buildRules() {
        //firstly build rules for all source groups.
        //Check if rule for group already exists and then use rule data or load default data
        let groupType = this.filterAvailableChannels(this.types.find((x) => x.name == NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM));
        let defaultGroupChannels = {};
        groupType.channel.forEach((channel) => {
            defaultGroupChannels[channel.type] = NotificationSubscription.getDefaultValuesForTypeAndChannel(groupType.name, channel.type);
        });
        this.sourceGroups.forEach((group) => {
            let existingRule = this.rules.find((x) => x.scopeId === group.id);
            if (!existingRule) {
                let newMeasuredRule = {
                    isNew: true,
                    enabled: false,
                    scope: NotificationScopeType.SOURCE_GROUP,
                    scopeId: group.id,
                    scopeName: group.name,
                    type: NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM,
                    typeSettings: groupType,
                    channels: JSON.parse(JSON.stringify(defaultGroupChannels))
                };
                this.measuredRules.push(newMeasuredRule);
            }
            else {
                let currentChannels = {};
                let currentType = this.filterAvailableChannels(this.types.find((x) => x.name === existingRule.notificationType));
                currentType.channel.forEach((channel) => {
                    let existingChannel = existingRule.channels.find((x) => x.type == channel.type);
                    //create default channel settings
                    let ch = NotificationSubscription.getDefaultValuesForTypeAndChannel(currentType.name, channel.type);
                    if (existingChannel) {
                        //load values from existing rule
                        ch = {
                            enabled: true,
                            delay: existingChannel.settings.delay,
                            onResolve: existingChannel.settings.onResolve,
                            repeat: existingChannel.settings.repeat
                        };
                    }
                    else {
                        //if updating rule and channel is not used, set disabled to true
                        ch.enabled = false;
                    }
                    currentChannels[channel.type] = ch;
                });
                let measuredRule = {
                    isNew: false,
                    enabled: existingRule.enabled,
                    scope: NotificationScopeType.SOURCE_GROUP,
                    scopeId: group.id,
                    scopeName: group.name,
                    type: existingRule.notificationType,
                    typeSettings: currentType,
                    channels: currentChannels,
                    subscriptionId: existingRule.notificationSubscriptionId
                };
                this.measuredRules.push(measuredRule);
            }
        });
        // build rules for all sources . Check if rule for source already exists and then use rule data or load default data
        let sourceType = this.filterAvailableChannels(this.types.find((x) => x.name === NotificationSubscriptionType.MEASURED_DATA_ALARM));
        let defaultSourceChannels = {};
        sourceType.channel.forEach((channel) => {
            defaultSourceChannels[channel.type] = NotificationSubscription.getDefaultValuesForTypeAndChannel(sourceType.name, channel.type);
        });
        this.sources.forEach((source) => {
            let existingRule = this.rules.find((x) => x.scopeId === source.id);
            if (!existingRule) {
                let newMeasuredRule = {
                    isNew: true,
                    enabled: false,
                    scope: NotificationScopeType.SOURCE,
                    scopeId: source.id,
                    scopeName: source.name,
                    type: NotificationSubscriptionType.MEASURED_DATA_ALARM,
                    typeSettings: sourceType,
                    channels: JSON.parse(JSON.stringify(defaultSourceChannels))
                };
                this.measuredRules.push(newMeasuredRule);
            }
            else {
                let currentChannels = {};
                let currentType = this.filterAvailableChannels(this.types.find((x) => x.name === existingRule.notificationType));
                currentType.channel.forEach((channel) => {
                    let existingChannel = existingRule.channels.find((x) => x.type == channel.type);
                    //create default channel settings
                    let ch = NotificationSubscription.getDefaultValuesForTypeAndChannel(currentType.name, channel.type);
                    if (existingChannel) {
                        //load values from existing rule
                        ch = {
                            enabled: true,
                            delay: existingChannel.settings.delay,
                            onResolve: existingChannel.settings.onResolve,
                            repeat: existingChannel.settings.repeat
                        };
                    }
                    else {
                        //if updating rule and channel is not used, set disabled to true
                        ch.enabled = false;
                    }
                    currentChannels[channel.type] = ch;
                });
                let measuredRule = {
                    isNew: false,
                    enabled: existingRule.enabled,
                    scope: NotificationScopeType.SOURCE,
                    scopeId: source.id,
                    scopeName: source.name,
                    type: existingRule.notificationType,
                    typeSettings: currentType,
                    channels: currentChannels,
                    subscriptionId: existingRule.notificationSubscriptionId
                };
                this.measuredRules.push(measuredRule);
            }
        });
        if (this.autoSave === false) {
            this.measuredRules.forEach(rule => {
                this.$emit('ruleChanged', this.buildRuleForParent(rule));
            });
        }
    }
    /**
     * Load sources and source groups for current department
     */
    async loadEntities() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(sourceGroupRepository.getSourceGroups(null, null, false, this.departmentId));
        allPromises.push(sourceRepository.getVisibleSources(null, null, this.departmentId));
        return Promise.all(allPromises).then((response) => {
            this.sourceGroups = response[0].getData();
            this.sources = response[1].getData().filter((x) => !x.sourceGroup);
        });
    }
    changeDisplayMode(selected) {
        this.currentDisplayMode = selected;
        this.measuredRules.forEach((rule) => {
            if (rule.subscriptionId)
                this.save(rule);
        });
    }
    /**
     * Check if given type has available onResolve setting
     */
    channelForTypeHasOnResolve(type, channelType) {
        let channel = type.channel.find((x) => x.type == channelType);
        return channel.onResolve;
    }
    save(rule) {
        if (this.autoSave === false) {
            this.$emit('ruleChanged', this.buildRuleForParent(rule));
        }
        else {
            this.requestState = RequestState.PENDING;
            if (rule.isNew) {
                this.createNewRule(rule);
            }
            else {
                this.updateRule(rule);
            }
        }
    }
    buildRuleForParent(rule) {
        let currentChannelsDTO = {};
        Object.keys(rule.channels).forEach((channelKey) => {
            if (rule.channels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: rule.channels[channelKey].delay,
                    onResolve: rule.channels[channelKey].onResolve,
                    repeat: rule.channels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let newRule = {
            ScopeType: rule.scope,
            ScopeId: rule.scopeId,
            NotificationType: rule.type,
            Priority: NotificationPriority.NORMAL.name,
            Enabled: rule.enabled,
            Channels: currentChannelsDTO,
            DisplayMode: this.currentDisplayMode,
            PresetId: null
        };
        return newRule;
    }
    async createNewRule(rule) {
        let currentChannelsDTO = {};
        Object.keys(rule.channels).forEach((channelKey) => {
            if (rule.channels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: rule.channels[channelKey].delay,
                    onResolve: rule.channels[channelKey].onResolve,
                    repeat: rule.channels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let newRule = {
            ScopeType: rule.scope,
            ScopeId: rule.scopeId,
            NotificationType: rule.type,
            Priority: NotificationPriority.NORMAL.name,
            Enabled: true,
            Channels: currentChannelsDTO,
            DisplayMode: this.currentDisplayMode,
            PresetId: null
        };
        let result = await notificationSubscriptionsRepository.createNotificationSubscription([newRule], this.currentUser.apiUserId, this.departmentId);
        if (result instanceof (Array)) {
            this.requestState = RequestState.SUCCESS;
        }
        else {
            this.requestState = RequestState.ERROR;
        }
    }
    async updateRule(rule) {
        let currentChannelsDTO = {};
        Object.keys(rule.channels).forEach((channelKey) => {
            if (rule.channels[channelKey].enabled == false)
                return;
            let channelDTO = {
                Settings: {
                    delay: rule.channels[channelKey].delay,
                    onResolve: rule.channels[channelKey].onResolve,
                    repeat: rule.channels[channelKey].repeat
                },
                IsPaused: false
            };
            currentChannelsDTO[channelKey] = channelDTO;
        });
        let updateDTO = {
            Priority: NotificationPriority.NORMAL.name,
            Enabled: rule.enabled,
            Channels: currentChannelsDTO,
            NotificationType: rule.type,
            DisplayMode: this.currentDisplayMode,
            PresetId: null
        };
        let result = await notificationSubscriptionsRepository.updateNotificationSubscription(rule.subscriptionId, updateDTO, this.currentUser.apiUserId, this.departmentId);
        if (result === true) {
            this.requestState = RequestState.SUCCESS;
        }
        else {
            this.requestState = RequestState.ERROR;
        }
    }
    isPhoneChannel(channel) {
        return channel === NotificationChannelType.SMS || channel === NotificationChannelType.CALL;
    }
    async modeChanged() {
        this.$emit('changeMode', this.selectedMode);
    }
};
__decorate([
    Prop({ type: Array })
], EntityMeasuredDataNotification.prototype, "rules", void 0);
__decorate([
    Prop({ type: Object })
], EntityMeasuredDataNotification.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Array })
], EntityMeasuredDataNotification.prototype, "types", void 0);
__decorate([
    Prop({ type: String })
], EntityMeasuredDataNotification.prototype, "selectedMeasuredRulesMode", void 0);
__decorate([
    Prop({ type: String })
], EntityMeasuredDataNotification.prototype, "departmentId", void 0);
__decorate([
    Prop({ type: Object })
], EntityMeasuredDataNotification.prototype, "tier", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], EntityMeasuredDataNotification.prototype, "autoSave", void 0);
EntityMeasuredDataNotification = __decorate([
    Component({})
], EntityMeasuredDataNotification);
export default EntityMeasuredDataNotification;
