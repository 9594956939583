var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "multi-header title-with-edit is-flex is-align-content-center is-align-items-flex-start",
        },
        [
          _c("h2", { staticClass: "subtitle is-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("background_jobs.title")) + " "),
          ]),
        ]
      ),
      _c(
        "section",
        { staticClass: "mt-2" },
        [
          _c(
            "b-table",
            {
              attrs: {
                data: _vm.backgroundJobs,
                paginated: "",
                "per-page": _vm.pageSize,
                striped: "",
                "default-sort-direction": _vm.sortDirection,
                "default-sort": _vm.sortField,
                total: _vm.total,
                detailed: "",
                "opened-detailed": _vm.backgroundJobs.map((x) => x.FullName),
                "show-detail-icon": true,
                "detail-key": "FullName",
              },
              scopedSlots: _vm._u([
                {
                  key: "detail",
                  fn: function (props) {
                    return [
                      _c("h3", { staticClass: "is-size-6 pb-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("background_jobs.triggers")) + " "
                        ),
                      ]),
                      _c(
                        "b-table",
                        { attrs: { data: props.row.Triggers } },
                        [
                          _c("b-table-column", {
                            attrs: {
                              field: "Name",
                              label: _vm.$t("background_jobs.name"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (jobsTriggers) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(jobsTriggers.row.Name) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              field: "Group",
                              label: _vm.$t("background_jobs.group"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (jobsTriggers) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(jobsTriggers.row.Group) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              field: "NextFireTimeUtc",
                              label: _vm.$t("background_jobs.next"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (jobsTriggers) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dateTimeManager.formatTime(
                                              new Date(
                                                jobsTriggers.row
                                                  .NextFireTimeUtc * 1000
                                              ),
                                              null,
                                              null,
                                              null
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              field: "PreviousFireTimeUtc",
                              label: _vm.$t("background_jobs.previous"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (jobsTriggers) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dateTimeManager.formatTime(
                                              new Date(
                                                jobsTriggers.row
                                                  .PreviousFireTimeUtc * 1000
                                              ),
                                              null,
                                              null,
                                              null
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("section", { staticClass: "section" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "content has-text-grey has-text-centered",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.loggers.loggerList.table.empty"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: "Name",
                  label: _vm.$t("background_jobs.name"),
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchable",
                    fn: function (jobsList) {
                      return [
                        _c("b-input", {
                          attrs: { placeholder: _vm.$t("generals.search") },
                          model: {
                            value: jobsList.filters[jobsList.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                jobsList.filters,
                                jobsList.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "jobsList.filters[jobsList.column.field]",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (jobsList) {
                      return [_vm._v(" " + _vm._s(jobsList.row.Name) + " ")]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "Group",
                  label: _vm.$t("background_jobs.group"),
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchable",
                    fn: function (jobsList) {
                      return [
                        _c("b-input", {
                          attrs: { placeholder: _vm.$t("generals.search") },
                          model: {
                            value: jobsList.filters[jobsList.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                jobsList.filters,
                                jobsList.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "jobsList.filters[jobsList.column.field]",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (jobsList) {
                      return [_vm._v(" " + _vm._s(jobsList.row.Group) + " ")]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: { label: _vm.$t("generals.action") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (jobsList) {
                      return [
                        _c("b-icon", {
                          staticClass: "control-icon clickable",
                          attrs: { icon: "info" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showRawJob(jobsList.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isDetailModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeDetailModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isDetailModalActive = $event
            },
            "close-modal": _vm.closeDetailModal,
          },
        },
        [
          _c("BackgroundJobDetailModal", {
            attrs: { backgroundJob: _vm.selectedJob },
            on: { modalClosed: _vm.closeDetailModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }