var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(" " + _vm._s(_vm.$t("admin.component.user.list.title")) + " "),
        ]),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item is-hidden-mobile",
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.clearFilter()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
            ),
            _c(
              "div",
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: { position: "is-bottom-left", "aria-role": "list" },
                    scopedSlots: _vm._u([
                      {
                        key: "trigger",
                        fn: function () {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.company.list.actions.columns"
                                  ),
                                  position: "is-left",
                                  type: "is-dark",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  { staticStyle: { "font-size": "1rem" } },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        type: "is-primary",
                                        icon: "list",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  _vm._l(_vm.tableColumns, function (column) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: column.field,
                        staticClass: "custom-dropdown-item",
                        attrs: {
                          focusable: false,
                          custom: "",
                          "aria-role": "listitem",
                        },
                      },
                      [
                        _c(
                          "b-checkbox",
                          {
                            attrs: {
                              disabled:
                                column.field ===
                                _vm.tableColumns.fullName.field,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.chooseTableColumns(
                                  column.field,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: column.visible,
                              callback: function ($$v) {
                                _vm.$set(column, "visible", $$v)
                              },
                              expression: "column.visible",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    `admin.component.user.list.table.${column.field}`
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "mobile-filter res-table",
          attrs: {
            data: _vm.users,
            paginated: "",
            "backend-pagination": "",
            "current-page": _vm.page,
            "per-page": _vm.pageSize,
            total: _vm.totalSize,
            "backend-sorting": "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            "backend-filtering": "",
            striped: "",
            "page-input": true,
            "pagination-order": "is-centered",
            "pagination-position": _vm.isMobileView ? "bottom" : "both",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "page-change": _vm.onPageChange,
            sort: _vm.onSort,
            "filters-change": function ($event) {
              return _vm.$buefy.toast.open("search")
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "top-left",
                fn: function () {
                  return [
                    _c("p", { staticClass: "is-hidden-mobile" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 is-hidden-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "empty",
                fn: function () {
                  return [
                    _c("section", { staticClass: "section" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "content has-text-grey has-text-centered",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("admin.component.user.list.table.empty")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.users.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.totalSize,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.apiUserId.field,
              visible: _vm.tableColumns.apiUserId.visible,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.apiUserId.field}`
              ),
              sortable: _vm.tableColumns.apiUserId.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isIdSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.user.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "min:36",
                                      expression: "'min:36'",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: { input: _vm.handleFilterIdDebounce },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterUsers(
                                        _vm.tableColumns.apiUserId.field,
                                        _vm.idSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.idSearch,
                                    callback: function ($$v) {
                                      _vm.idSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "idSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile is-full-width",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "b-field",
                          {
                            attrs: {
                              expanded: "",
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t(
                                    "admin.component.user.list.table.ApiUserId"
                                  )
                                ),
                              },
                              message: _vm.errors.first(
                                _vm.$t(
                                  "admin.component.user.list.table.ApiUserId"
                                )
                              ),
                            },
                          },
                          [
                            _c("b-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:36",
                                  expression: "'min:36'",
                                },
                              ],
                              attrs: {
                                type: "text",
                                name: _vm.$t(
                                  "admin.component.user.list.table.ApiUserId"
                                ),
                                placeholder: _vm.$t("generals.search"),
                                "icon-pack": "fas",
                                icon: "search",
                                expanded: "",
                              },
                              on: { input: _vm.handleFilterIdDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.filterUsers(
                                    _vm.tableColumns.apiUserId.field,
                                    _vm.idSearch
                                  )
                                },
                              },
                              model: {
                                value: _vm.idSearch,
                                callback: function ($$v) {
                                  _vm.idSearch =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "idSearch",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (userList) {
                  return [
                    _c(
                      "span",
                      [
                        userList.row.email !== null
                          ? _c(
                              "router-link",
                              {
                                staticClass: "is-family-monospace",
                                attrs: {
                                  to: {
                                    name: "adminUserDetail",
                                    params: {
                                      userId: userList.row.id,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                                nativeOn: {
                                  contextmenu: function ($event) {
                                    return _vm.copyLinkToClipboard(
                                      userList.row.id
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(userList.row.id))]
                            )
                          : _c("span", [_vm._v(_vm._s(userList.row.id))]),
                        _vm.hasCopyIconAllowed
                          ? _c("b-icon", {
                              staticClass:
                                "clickable is-size-7 is-hidden-touch",
                              attrs: { icon: "clipboard" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.copyLinkToClipboard(
                                    userList.row.id
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            staticClass: "wrap-if-long",
            attrs: {
              field: _vm.tableColumns.fullName.field,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.fullName.field}`
              ),
              visible: _vm.tableColumns.fullName.visible,
              sortable: _vm.tableColumns.fullName.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isFullNameSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.user.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterFullnameDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterUsers(
                                        _vm.tableColumns.fullName.field,
                                        _vm.fullnameSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.fullnameSearch,
                                    callback: function ($$v) {
                                      _vm.fullnameSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "fullnameSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterFullnameDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterUsers(
                                _vm.tableColumns.fullName.field,
                                _vm.fullnameSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.fullnameSearch,
                            callback: function ($$v) {
                              _vm.fullnameSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "fullnameSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (userList) {
                  return [
                    userList.row.email !== null
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminUserDetail",
                                params: {
                                  userId: userList.row.id,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(userList.row.fullName))]
                        )
                      : _c("span", [_vm._v(_vm._s(userList.row.fullName))]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            staticClass: "wrap-if-long",
            attrs: {
              field: _vm.tableColumns.email.field,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.email.field}`
              ),
              visible: _vm.tableColumns.email.visible,
              sortable: _vm.tableColumns.email.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isEmailSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.user.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: { input: _vm.handleFilterEmailDebounce },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterUsers(
                                        _vm.tableColumns.email.field,
                                        _vm.emailSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.emailSearch,
                                    callback: function ($$v) {
                                      _vm.emailSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "emailSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterEmailDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterUsers(
                                _vm.tableColumns.email.field,
                                _vm.emailSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.emailSearch,
                            callback: function ($$v) {
                              _vm.emailSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "emailSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (userList) {
                  return [
                    userList.row.email !== null
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminUserDetail",
                                params: {
                                  userId: userList.row.id,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(userList.row.email))]
                        )
                      : _c("span", [_vm._v(_vm._s(userList.row.email))]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            staticClass: "wrap-if-long",
            attrs: {
              field: _vm.tableColumns.username.field,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.username.field}`
              ),
              visible: _vm.tableColumns.username.visible,
              sortable: _vm.tableColumns.username.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isUsernameSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.user.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterUsernameDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterUsers(
                                        _vm.tableColumns.username.field,
                                        _vm.usernameSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.usernameSearch,
                                    callback: function ($$v) {
                                      _vm.usernameSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "usernameSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterUsernameDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterUsers(
                                _vm.tableColumns.username.field,
                                _vm.usernameSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.usernameSearch,
                            callback: function ($$v) {
                              _vm.usernameSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "usernameSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (userList) {
                  return [
                    userList.row.email !== null
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminUserDetail",
                                params: {
                                  userId: userList.row.id,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(userList.row.username))]
                        )
                      : _c("span", [_vm._v(_vm._s(userList.row.username))]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.phoneNumber.field,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.phoneNumber.field}`
              ),
              visible: _vm.tableColumns.phoneNumber.visible,
              sortable: _vm.tableColumns.phoneNumber.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isPhoneSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.user.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterPhoneNumberDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterUsers(
                                        _vm.tableColumns.phoneNumber.field,
                                        _vm.phoneSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.phoneSearch,
                                    callback: function ($$v) {
                                      _vm.phoneSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "phoneSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterPhoneNumberDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.filterUsers(
                                _vm.tableColumns.phoneNumber.field,
                                _vm.phoneSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.phoneSearch,
                            callback: function ($$v) {
                              _vm.phoneSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "phoneSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (userList) {
                  return [
                    userList.row.phoneNumber
                      ? _c("span", [
                          _vm._v(_vm._s(userList.row.phoneNumber.Number)),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.enabled.field,
              visible: _vm.tableColumns.enabled.visible,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.enabled.field}`
              ),
              sortable: _vm.tableColumns.enabled.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isEnabledSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.user.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.filterUsers(
                                          _vm.tableColumns.enabled.field,
                                          _vm.enabledSearch.toString()
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.enabledSearch,
                                      callback: function ($$v) {
                                        _vm.enabledSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "enabledSearch",
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: true } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.user.enabledState.enabled"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "option",
                                      { domProps: { value: false } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.user.enabledState.disabled"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.filterUsers(
                                      _vm.tableColumns.enabled.field,
                                      _vm.enabledSearch.toString()
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.enabledSearch,
                                  callback: function ($$v) {
                                    _vm.enabledSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "enabledSearch",
                                },
                              },
                              [
                                _c("option", { domProps: { value: true } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.user.enabledState.enabled"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("option", { domProps: { value: false } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.user.enabledState.disabled"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (userList) {
                  return [
                    !userList.row.enabled && userList.row.actionReason
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label:
                                _vm.$t(
                                  "admin.component.user.list.table.reason"
                                ) +
                                ": " +
                                userList.row.actionReason,
                              position: "is-top",
                              type: "is-light",
                            },
                          },
                          [_c("b-icon", { attrs: { icon: "times" } })],
                          1
                        )
                      : _c("b-icon", {
                          attrs: {
                            icon: userList.row.enabled ? "check" : "times",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.verified.field,
              visible: _vm.tableColumns.verified.visible,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.verified.field}`
              ),
              sortable: _vm.tableColumns.verified.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isVerifiedSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.user.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.filterUsers(
                                          _vm.tableColumns.verified.field,
                                          _vm.verifiedSearch.toString()
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.verifiedSearch,
                                      callback: function ($$v) {
                                        _vm.verifiedSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "verifiedSearch",
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: true } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.user.verifiedState.verified"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "option",
                                      { domProps: { value: false } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.user.verifiedState.unverified"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.filterUsers(
                                      _vm.tableColumns.verified.field,
                                      _vm.verifiedSearch.toString()
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.verifiedSearch,
                                  callback: function ($$v) {
                                    _vm.verifiedSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "verifiedSearch",
                                },
                              },
                              [
                                _c("option", { domProps: { value: true } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.user.verifiedState.verified"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("option", { domProps: { value: false } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.user.verifiedState.unverified"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (userList) {
                  return [
                    _c("b-icon", {
                      attrs: {
                        icon: userList.row.verified ? "check" : "times",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.newsletter.field,
              visible: _vm.tableColumns.newsletter.visible,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.newsletter.field}`
              ),
              sortable: _vm.tableColumns.newsletter.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isNewsletterSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.user.list.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.filterUsers(
                                          _vm.tableColumns.newsletter.field,
                                          _vm.newsletterSearch.toString()
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.newsletterSearch,
                                      callback: function ($$v) {
                                        _vm.newsletterSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "newsletterSearch",
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: true } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.user.newsletterState.subscribed"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "option",
                                      { domProps: { value: false } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.user.newsletterState.unsubscribed"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.filterUsers(
                                      _vm.tableColumns.newsletter.field,
                                      _vm.newsletterSearch.toString()
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.newsletterSearch,
                                  callback: function ($$v) {
                                    _vm.newsletterSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "newsletterSearch",
                                },
                              },
                              [
                                _c("option", { domProps: { value: true } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.user.newsletterState.subscribed"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("option", { domProps: { value: false } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.user.newsletterState.unsubscribed"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (userList) {
                  return [
                    _c("b-icon", {
                      attrs: {
                        icon: userList.row.newsletter ? "check" : "times",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.registeredAt.field,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.registeredAt.field}`
              ),
              visible: _vm.tableColumns.registeredAt.visible,
              sortable: _vm.tableColumns.registeredAt.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (userList) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(userList.row.registeredAt * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.lastActivity.field,
              label: _vm.$t(
                `admin.component.user.list.table.${_vm.tableColumns.lastActivity.field}`
              ),
              visible: _vm.tableColumns.lastActivity.visible,
              sortable: _vm.tableColumns.lastActivity.visible,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (userList) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(userList.row.lastActivity * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              "cell-class": "actions",
              field: "action",
              label: _vm.$t("admin.component.user.list.table.action"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (userList) {
                  return [
                    userList.row.email !== null
                      ? _c("div", [
                          !userList.row.enabled &&
                          _vm.$ability.can(
                            _vm.$permActions.ENABLE,
                            _vm.$permSubjects.ADMIN_USERS
                          )
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.enableUser(userList.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.user.list.table.actions.enable"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          userList.row.enabled &&
                          _vm.$ability.can(
                            _vm.$permActions.DISABLE,
                            _vm.$permSubjects.ADMIN_USERS
                          )
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDisableModal(userList.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.user.list.table.actions.disable"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isDisableModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isDisableModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("WarningModal", {
            attrs: {
              title: _vm.$t("component.userAdministration.list.disable.title"),
              message: _vm.$t("component.userAdministration.list.disable.body"),
              confirmBtn: _vm.$t(
                "component.userAdministration.list.disable.confirm"
              ),
            },
            on: {
              modalClosed: function ($event) {
                return _vm.closeModal()
              },
              confirm: _vm.disableUser,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }