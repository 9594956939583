var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("admin.component.user.preregisterUserModal.title")
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.user.preregisterUserModal.email"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t("admin.component.user.preregisterUserModal.email")
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.user.preregisterUserModal.email")
                ),
                "label-position": "on-border",
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email",
                    expression: "'required|email'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.user.preregisterUserModal.email"
                  ),
                  name: _vm.$t(
                    "admin.component.user.preregisterUserModal.email"
                  ),
                  type: "email",
                },
                on: { input: _vm.setUsername },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.user.preregisterUserModal.firstName"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.user.preregisterUserModal.firstName"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.user.preregisterUserModal.firstName")
                ),
                "label-position": "on-border",
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:20",
                    expression: "'required|min:2|max:20'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.user.preregisterUserModal.firstName"
                  ),
                  name: _vm.$t(
                    "admin.component.user.preregisterUserModal.firstName"
                  ),
                  type: "text",
                },
                model: {
                  value: _vm.firstName,
                  callback: function ($$v) {
                    _vm.firstName = $$v
                  },
                  expression: "firstName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.user.preregisterUserModal.lastName"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t("admin.component.user.preregisterUserModal.lastName")
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.user.preregisterUserModal.lastName")
                ),
                "label-position": "on-border",
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:20",
                    expression: "'required|min:2|max:20'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.user.preregisterUserModal.lastName"
                  ),
                  name: _vm.$t(
                    "admin.component.user.preregisterUserModal.lastName"
                  ),
                  type: "text",
                },
                model: {
                  value: _vm.lastName,
                  callback: function ($$v) {
                    _vm.lastName = $$v
                  },
                  expression: "lastName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              staticClass: "is-flex is-justify-content-space-between",
              attrs: {
                label: _vm.$t(
                  "admin.component.user.preregisterUserModal.sendVerification"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.user.preregisterUserModal.sendVerification"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.user.preregisterUserModal.sendVerification"
                  )
                ),
              },
            },
            [
              _c("b-switch", {
                attrs: {
                  name: _vm.$t(
                    "admin.component.user.preregisterUserModal.sendVerification"
                  ),
                },
                model: {
                  value: _vm.sendVerification,
                  callback: function ($$v) {
                    _vm.sendVerification = $$v
                  },
                  expression: "sendVerification",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.preRegisterUser()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("admin.component.user.preregisterUserModal.confirm")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }