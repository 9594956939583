import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UserRepository from '@/services/repository/UserRepository';
import UserPreferences from '@/services/UserPreferences';
var userRepository;
let AdminCompanyUsersComponent = class AdminCompanyUsersComponent extends Vue {
    constructor() {
        super(...arguments);
        this.sortDirection = UserPreferences.LocalStorageKeys.AdminCompanyUsersSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.AdminCompanyUsersSortField.defaultValue;
        this.Action = Action;
        this.selectedAction = null;
        this.currentUser = null;
        this.currentUserId = null;
        this.tableColumns = {
            email: {
                field: 'email',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentEmail)
            },
            username: {
                field: 'username',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentUsername)
            },
            fullName: {
                field: 'fullName',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentFullName)
            },
            roleKey: {
                field: 'role.Key',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentRoleKey)
            },
            enabled: {
                field: 'enabled',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentEnabled)
            },
            assignedAt: {
                field: 'assignedAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentAssignedAt)
            }
        };
    }
    usersChanged() {
        //reset select
        this.$nextTick(() => {
            this.users.forEach((user) => {
                user.selectedAction = null;
            });
        });
    }
    async created() {
        userRepository = new UserRepository(this);
        this.loadData();
    }
    async loadData() {
        this.currentUser = await userRepository.getCurrentUser();
        this.currentUserId = this.currentUser.apiUserId;
    }
    onSort(field, order) {
        this.sortField = field;
        this.sortDirection = order;
    }
    openAddUsersModal() {
        this.$emit('openAddUsersModal');
    }
    actionSelectedUser(user, action) {
        switch (action) {
            case Action.CHANGE_ROLE:
                this.$emit('changeRole', user);
                break;
            case Action.UNASSIGN:
                this.$emit('unassignUser', user);
                break;
            case Action.DISABLE:
                this.$emit('disableUserDialog', user);
                break;
            case Action.ENABLE:
                this.$emit('enableUserDialog', user);
                break;
            default:
                break;
        }
    }
    // changing default sort field
    changeDefaultSortField(sortFieldName) {
        if (this.sortField == sortFieldName) {
            this.sortField = this.tableColumns.fullName.field;
        }
    }
    chooseTableColumns(field, event) {
        switch (field) {
            case this.tableColumns.email.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.email.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentEmail, event);
                break;
            case this.tableColumns.username.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.username.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentUsername, event);
                break;
            case this.tableColumns.roleKey.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.roleKey.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentRoleKey, event);
                break;
            case this.tableColumns.enabled.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.enabled.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentEnabled, event);
                break;
            case this.tableColumns.assignedAt.field:
                if (!event) {
                    this.changeDefaultSortField(this.tableColumns.assignedAt.field);
                }
                UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminCompanyUsersComponentAssignedAt, event);
                break;
        }
        this.$nextTick(() => {
            this.$refs.table.initSort();
        });
    }
};
__decorate([
    Prop({ type: Array })
], AdminCompanyUsersComponent.prototype, "users", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyUsersComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyUsersComponent.prototype, "company", void 0);
__decorate([
    Watch('users')
], AdminCompanyUsersComponent.prototype, "usersChanged", null);
AdminCompanyUsersComponent = __decorate([
    Component({
        components: {}
    })
], AdminCompanyUsersComponent);
export default AdminCompanyUsersComponent;
var Action;
(function (Action) {
    Action["CHANGE_ROLE"] = "changeRole";
    Action["UNASSIGN"] = "unassignUser";
    Action["DISABLE"] = "disableUser";
    Action["ENABLE"] = "enableUser";
})(Action || (Action = {}));
