import { SystemUserRoles } from '@/entities/enums/UserRoles';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
import SessionStorage from '@/services/repository/SessionStorage';
import CookiesStorage from './CookiesStorage';
import { vxm } from '@/store/store.vuex';
import { AdminDepartment } from '@/entities/models/AdminDepartment';
import { faro } from '@grafana/faro-web-sdk';
import AppConfig from '@/configLoader';
import Subjects from './permissions/Subjects';
import { AdminAction } from './permissions/Actions';
import AbilityManager from '@/services/permissions/AbilitiesManager';
var vxDepartmentStore = vxm.departmentStore;
export default class LoggedUserManager {
    /**
     * Saves login credentials after login. This credentials are used to authorize all API calls.
     * @param context Vue instance
     * @param username
     * @param sessionPassword
     * @param apiUserFullName
     */
    static saveLoginData(context, username, sessionPassword, apiUserFullName) {
        CookiesStorage.storeData(context, CookiesStorage.keys.USERNAME, username, Infinity);
        CookiesStorage.storeData(context, CookiesStorage.keys.SESSION_PASSWORD, sessionPassword, Infinity);
        CookiesStorage.storeData(context, CookiesStorage.keys.API_USER_FULL_NAME, apiUserFullName, Infinity);
        CookiesStorage.removeData(context, CookiesStorage.keys.IN_COMPANY);
    }
    /**
     * Saves ID or currently logged user.
     * @param context Vue instance
     * @param userId Id of current user
     */
    static saveIdOfLoggedUser(context, userId) {
        CookiesStorage.storeData(context, CookiesStorage.keys.API_USER_ID, userId, Infinity);
        if (AppConfig.getConfig().features.grafana_faro == true) {
            LoggedUserManager.setFaroUser(userId);
        }
    }
    /**
     * Load ID or currently logged user.
     * @param context Vue instance
     */
    static getIdOfLoggedUser(context) {
        return CookiesStorage.loadData(context, CookiesStorage.keys.API_USER_ID);
    }
    /**
     * Backups admin login credentials before login into company.
     * @param context Vue instance
     */
    static loginToCompany(context, department, routeName, params) {
        this.setSelectedDepartment(department);
        CookiesStorage.storeData(context, CookiesStorage.keys.IN_COMPANY, 'true', Infinity);
        let permissions = [Subjects.ALL + '.' + AdminAction];
        this.setPermissions(permissions);
        context.$ability.update(new AbilityManager(permissions).getAbilities().rules);
        //save current url
        localStorage.setItem(LocalStorageKeys.ADMIN_LAST_URL, JSON.stringify({ name: routeName, params: params }));
    }
    /**
     * Reloads admin login credentials from backup. Used when admin log out from company. Backup is deleted after reload.
     * @param context Vue instance
     */
    static reloadAdminLoginData(context) {
        const apiUserRole = CookiesStorage.loadData(context, CookiesStorage.keys.API_USER_ROLE);
        let permissions = [];
        this.setPermissions(permissions);
        context.$ability.update(new AbilityManager(permissions).getAbilities().rules);
        CookiesStorage.storeData(context, CookiesStorage.keys.API_USER_ROLE, apiUserRole, Infinity);
        CookiesStorage.removeData(context, CookiesStorage.keys.IN_COMPANY);
    }
    /**
     * Delete login credentials
     * @param context Vue instance
     */
    static deleteLoginData(context) {
        CookiesStorage.removeData(context, CookiesStorage.keys.USERNAME);
        CookiesStorage.removeData(context, CookiesStorage.keys.SESSION_PASSWORD);
        CookiesStorage.removeData(context, CookiesStorage.keys.API_USER_FULL_NAME);
        CookiesStorage.removeData(context, CookiesStorage.keys.API_USER_ROLE);
        CookiesStorage.removeData(context, CookiesStorage.keys.API_USER_ID);
        CookiesStorage.removeData(context, CookiesStorage.keys.IN_COMPANY);
        CookiesStorage.removeData(context, LocalStorageKeys.PERMISSIONS);
        localStorage.removeItem(LocalStorageKeys.PERMISSIONS);
        SessionStorage.clearStorageForSession();
    }
    /**
     * Delete admin login credentials
     * @param context Vue instance
     */
    static deleteAdminLoginData(context) {
        CookiesStorage.removeData(context, CookiesStorage.keys.API_USER_ROLE);
        localStorage.removeItem(LocalStorageKeys.ADMIN_PERMISSIONS);
    }
    /**
     * Check if cookies contain login credentials
     * @param cookies VueCookies instance
     * @returns true if login credentials exists.
     */
    static storageContainLoginCredentials(cookies) {
        return (CookiesStorage.exists(cookies, CookiesStorage.keys.USERNAME) &&
            CookiesStorage.exists(cookies, CookiesStorage.keys.SESSION_PASSWORD));
    }
    /**
     * Check if user is system admin or reseller admin
     * @returns true if logged user is system admin.
     */
    static isAdmin() {
        return (vxDepartmentStore.selectedDepartmentRoleKey === SystemUserRoles.SYSTEM_ADMIN ||
            vxDepartmentStore.selectedDepartmentRoleKey === SystemUserRoles.RESELLER_ADMIN);
    }
    /**
     * Check if user is system admin
     * @returns true if logged user is system admin.
     */
    static isSystemAdmin() {
        return vxDepartmentStore.selectedDepartmentRoleKey === SystemUserRoles.SYSTEM_ADMIN;
    }
    /**
     * Check if user is reseller admin
     * @returns true if logged user is reseller admin.
     */
    static isResellerAdmin() {
        return vxDepartmentStore.selectedDepartmentRoleKey === SystemUserRoles.RESELLER_ADMIN;
    }
    /**
     * Check if admin is logged in company
     * @param context Vue instance
     * @returns true if admin is logged into company.
     */
    static isLoggedToCompany(context) {
        return CookiesStorage.loadData(context, CookiesStorage.keys.IN_COMPANY) == 'true';
    }
    static setSelectedDepartment(value) {
        vxDepartmentStore.selectedDepartment = AdminDepartment.convertToUserDepartment(value);
    }
    static getPermissions() {
        return vxDepartmentStore.selectedDepartmentPermissions;
    }
    static getRole() {
        return vxDepartmentStore.selectedDepartmentRoleKey;
    }
    static setPermissions(value) {
        vxDepartmentStore.selectedDepartmentPermissions = value;
    }
    static setRole(value) {
        vxDepartmentStore.selectedDepartmentRoleKey = value;
    }
    static clearDepartments() {
        vxDepartmentStore.departments = null;
        vxDepartmentStore.selectedDepartment = null;
    }
    static setFaroUser(userId) {
        faro.api.setUser({
            // Optional, if you want to pass the user ID
            id: userId,
            // Optional, if you want to set custom attributes
            attributes: {
                'selected-department-id': vxDepartmentStore?.selectedDepartment?.id
            }
        });
    }
}
