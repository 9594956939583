import { BoundingBox, Corner, LayoutData, MapValue, Point, Room, Sensor } from './model/Room';
export default class ThermoMapService {
    populateLayout(thermoMapConfiguration, ppm) {
        let layout = new LayoutData();
        thermoMapConfiguration.rooms.forEach((roomData) => {
            let room = new Room();
            roomData.corners.forEach((cornerData) => {
                let corner = new Corner(cornerData.x, cornerData.y, ppm);
                room.corners.push(corner);
            });
            layout.rooms.push(room);
        });
        thermoMapConfiguration.sensors.forEach((sensorData, index) => {
            let sensor = new Sensor(sensorData.id, sensorData.x, sensorData.y, sensorData.v, ppm, index);
            layout.sensors.push(sensor);
        });
        // thermoMapConfiguration.doors.forEach(doorData => {
        // let door = new Door(doorData.x, doorData.y, PPM);
        // doors.push(door);
        // })
        return layout;
    }
    calculateBoundingBox(rooms, ppm) {
        let boundingBox = new BoundingBox();
        rooms.forEach((room) => {
            room.corners.forEach((corner) => {
                if (corner.absX < boundingBox.absMinX)
                    boundingBox.absMinX = corner.absX;
                if (corner.absX > boundingBox.absMaxX)
                    boundingBox.absMaxX = corner.absX;
                if (corner.absY < boundingBox.absMinY)
                    boundingBox.absMinY = corner.absY;
                if (corner.absY > boundingBox.absMaxY)
                    boundingBox.absMaxY = corner.absY;
            });
        });
        boundingBox.minX = Math.floor(boundingBox.absMinX / ppm);
        boundingBox.maxX = Math.ceil(boundingBox.absMaxX / ppm);
        boundingBox.minY = Math.floor(boundingBox.absMinY / ppm);
        boundingBox.maxY = Math.ceil(boundingBox.absMaxY / ppm);
        return boundingBox;
    }
    calculateBoundingBoxForRoom(room, ppm) {
        let boundingBox = new BoundingBox();
        room.corners.forEach((corner) => {
            if (corner.absX < boundingBox.absMinX) {
                boundingBox.absMinX = corner.absX;
                boundingBox.minX = corner.x;
            }
            if (corner.absX > boundingBox.absMaxX) {
                boundingBox.absMaxX = corner.absX;
                boundingBox.maxX = corner.x;
            }
            if (corner.absY < boundingBox.absMinY) {
                boundingBox.absMinY = corner.absY;
                boundingBox.minY = corner.y;
            }
            if (corner.absY > boundingBox.absMaxY) {
                boundingBox.absMaxY = corner.absY;
                boundingBox.maxY = corner.y;
            }
        });
        boundingBox.minX = Math.floor(boundingBox.absMinX / ppm);
        boundingBox.maxX = Math.ceil(boundingBox.absMaxX / ppm);
        boundingBox.minY = Math.floor(boundingBox.absMinY / ppm);
        boundingBox.maxY = Math.ceil(boundingBox.absMaxY / ppm);
        return boundingBox;
    }
    initializeValueMap(boundingBox, rooms, sensors) {
        let valueMap = [];
        for (let x = boundingBox.minX; x < boundingBox.maxX + 1; x++) {
            valueMap[x] = [];
            for (let y = boundingBox.minY; y < boundingBox.maxY + 1; y++) {
                valueMap[x][y] = new MapValue(null, 'air');
            }
        }
        sensors.forEach((s) => {
            valueMap[s.x][s.y] = { v: s.t, type: 'sensor' };
        });
        rooms.forEach((r) => {
            for (let i = 0; i < r.corners.length; i++) {
                let start = r.corners[i];
                let end = r.corners[(i + 1) % r.corners.length];
                if (start.x == end.x) {
                    let startY = Math.min(start.y, end.y);
                    let endY = Math.max(start.y, end.y);
                    for (let j = startY; j < endY + 1; j++) {
                        valueMap[start.x][j] = { v: 0, type: 'wall' };
                    }
                }
                else if (start.y == end.y) {
                    let startX = Math.min(start.x, end.x);
                    let endX = Math.max(start.x, end.x);
                    for (let j = startX; j < endX + 1; j++) {
                        valueMap[j][start.y] = { v: 0, type: 'wall' };
                    }
                }
                else {
                    this.rasterizeLine(start.x, start.y, end.x, end.y, valueMap, { v: 0, type: 'wall' });
                }
            }
        });
        // doors.forEach(d => {
        //     valueMap[d.x][d.y] = { v: null, type: 'door' };
        // })
        return valueMap;
    }
    rasterizeLine(x0, y0, x1, y1, valueMap, fill) {
        const dx = Math.abs(x1 - x0);
        const dy = Math.abs(y1 - y0);
        const sx = Math.sign(x1 - x0);
        const sy = Math.sign(y1 - y0);
        let err = dx - dy;
        while (true) {
            valueMap[x0][y0] = fill;
            if (x0 === x1 && y0 === y1)
                break;
            const e2 = 2 * err;
            if (e2 > -dy) {
                err -= dy;
                x0 += sx;
            }
            else if (e2 < dx) {
                err += dx;
                y0 += sy;
            }
        }
    }
    initializeValueMapForRoom(room, sensors) {
        let valueMap = [];
        for (let x = room.boundingBox.minX; x < room.boundingBox.maxX + 1; x++) {
            valueMap[x] = [];
            for (let y = room.boundingBox.minY; y < room.boundingBox.maxY + 1; y++) {
                valueMap[x][y] = new MapValue(null, 'air');
            }
        }
        sensors.forEach((s) => {
            if (s.x >= room.boundingBox.minX &&
                s.x < room.boundingBox.maxX &&
                s.y >= room.boundingBox.minY &&
                s.y < room.boundingBox.maxY)
                valueMap[s.x][s.y] = { v: s.t, type: 'sensor' };
        });
        for (let i = 0; i < room.corners.length; i++) {
            let start = room.corners[i];
            let end = room.corners[(i + 1) % room.corners.length];
            if (start.x == end.x) {
                let startY = Math.min(start.y, end.y);
                let endY = Math.max(start.y, end.y);
                for (let j = startY; j < endY + 1; j++) {
                    valueMap[start.x][j] = { v: 0, type: 'wall' };
                }
            }
            else if (start.y == end.y) {
                let startX = Math.min(start.x, end.x);
                let endX = Math.max(start.x, end.x);
                for (let j = startX; j < endX + 1; j++) {
                    valueMap[j][start.y] = { v: 0, type: 'wall' };
                }
            }
            else {
                this.rasterizeLine(start.x, start.y, end.x, end.y, valueMap, { v: 0, type: 'wall' });
            }
        }
        // doors.forEach(d => {
        //     valueMap[d.x][d.y] = { v: null, type: 'door' };
        // })
        return valueMap;
    }
    getSensorDistanceMap(sensor, valueMap, boundingBox) {
        return this.distance_dijkstra(sensor, valueMap, boundingBox);
    }
    getSensorDistanceMapForRoom(sensor, room) {
        // return this.distance_euclidian(sensor, room);
        return this.distance_dijkstra(sensor, room.valueMap, room.boundingBox);
    }
    distance_dijkstra(sensor, valueMap, boundingBox) {
        let map = [];
        // get all routable rooms
        var visited = sensor.room.getConnectedRooms();
        // calculate boundaing box for sensor with all routable rooms
        let distanceBoundingBox = new BoundingBox();
        distanceBoundingBox.absMaxX = Math.max(...visited.map((r) => r.boundingBox.absMaxX));
        distanceBoundingBox.absMaxY = Math.max(...visited.map((r) => r.boundingBox.absMaxY));
        distanceBoundingBox.absMinX = Math.min(...visited.map((r) => r.boundingBox.absMinX));
        distanceBoundingBox.absMinY = Math.min(...visited.map((r) => r.boundingBox.absMinY));
        distanceBoundingBox.minX = Math.floor(distanceBoundingBox.absMinX / 5);
        distanceBoundingBox.maxX = Math.ceil(distanceBoundingBox.absMaxX / 5);
        distanceBoundingBox.minY = Math.floor(distanceBoundingBox.absMinY / 5);
        distanceBoundingBox.maxY = Math.ceil(distanceBoundingBox.absMaxY / 5);
        sensor.distanceBoundaingBox = distanceBoundingBox;
        // init map
        for (let x = distanceBoundingBox.minX; x < distanceBoundingBox.maxX + 1; x++) {
            map[x] = [];
            for (let y = distanceBoundingBox.minY; y < distanceBoundingBox.maxY + 1; y++) {
                map[x][y] = { visited: 0, d: -1, type: 'air' };
            }
        }
        // fill out all room walls to map
        visited.forEach((room) => {
            for (let i = 0; i < room.corners.length; i++) {
                let start = room.corners[i];
                let end = room.corners[(i + 1) % room.corners.length];
                if (start.x == end.x) {
                    let startY = Math.min(start.y, end.y);
                    let endY = Math.max(start.y, end.y);
                    for (let j = startY; j < endY + 1; j++) {
                        map[start.x][j].type = 'wall';
                    }
                }
                else if (start.y == end.y) {
                    let startX = Math.min(start.x, end.x);
                    let endX = Math.max(start.x, end.x);
                    for (let j = startX; j < endX + 1; j++) {
                        map[j][start.y].type = 'wall';
                    }
                }
                else {
                    this.rasterizeLine(start.x, start.y, end.x, end.y, map, { visited: 0, d: -1, type: 'wall' });
                }
            }
        });
        // fill out all doors to map
        visited.forEach((r) => {
            r.doors.forEach((d) => {
                console.log(d.center);
                map[d.center.x][d.center.y].type = 'door';
            });
        });
        // calculate distance
        let start = new Point(sensor.x, sensor.y);
        map[start.x][start.y].d = 0;
        let queue = [];
        queue.push(start);
        let count = 0;
        while (queue.length > 0) {
            count++;
            let c = queue.shift();
            if (map[c.x][c.y].visited)
                continue;
            map[c.x][c.y].visited = 1;
            let ns = this.getNeighbors(c, distanceBoundingBox);
            ns.forEach((n) => {
                if (!map[n.x][n.y].visited) {
                    let newDist = map[c.x][c.y].d + this.euclidianDistance(c, n);
                    if (map[n.x][n.y].d == -1 || map[n.x][n.y].d > newDist)
                        map[n.x][n.y].d = newDist;
                }
            });
            for (let i = 0; i < ns.length; i++) {
                let n = ns[i];
                if (map[n.x][n.y].visited == false && map[n.x][n.y].type != 'wall') {
                    queue.push(new Point(n.x, n.y));
                }
            }
        }
        return map; //.map((x) => x.map((y) => (y.d)));
    }
    distance_euclidian(sensor, room) {
        let map = [];
        for (let x = room.boundingBox.minX; x < room.boundingBox.maxX + 1; x++) {
            map[x] = [];
            for (let y = room.boundingBox.minY; y < room.boundingBox.maxY + 1; y++) {
                map[x][y] = { visited: 0, d: -1 };
            }
        }
        let start = new Point(sensor.x, sensor.y);
        map[start.x][start.y].d = 0;
        var convexCorners = room.corners
            .filter((c) => c.convex)
            .map((c) => {
            return { corner: c, visited: 0, d: 0 };
        });
        var c = 0;
        while (convexCorners.some((c) => c.visited == 0)) {
            c++;
            if (c > 10)
                break;
            convexCorners
                .filter((c) => c.visited == 0)
                .forEach((unvisited) => {
                let cx = unvisited.corner.x + unvisited.corner.offsetX;
                let cy = unvisited.corner.y + unvisited.corner.offsetY;
                if (this.hasDirectLineOfSight({ x: cx, y: cy }, sensor, room)) {
                    unvisited.visited = 1;
                    unvisited.d = this.euclidianDistance(sensor, { x: cx, y: cy });
                }
                else {
                    convexCorners
                        .filter((c) => c.visited == 1)
                        .forEach((visited) => {
                        let vx = visited.corner.x + visited.corner.offsetX;
                        let vy = visited.corner.y + visited.corner.offsetY;
                        if (this.hasDirectLineOfSight({ x: cx, y: cy }, { x: vx, y: vy }, room)) {
                            unvisited.visited = 1;
                            unvisited.d = this.euclidianDistance({ x: vx, y: vy }, { x: cx, y: cy }) + visited.d;
                        }
                    });
                }
            });
        }
        for (let x = room.boundingBox.minX; x < room.boundingBox.maxX + 1; x++) {
            for (let y = room.boundingBox.minY; y < room.boundingBox.maxY + 1; y++) {
                if (this.hasDirectLineOfSight(sensor, { x: x, y: y }, room)) {
                    map[x][y] = { visited: 1, d: this.euclidianDistance(sensor, { x: x, y: y }) };
                }
                else {
                    let foundCorners = convexCorners
                        .filter((c) => this.hasDirectLineOfSight({ x: c.corner.x + c.corner.offsetX, y: c.corner.y + c.corner.offsetY }, { x: x, y: y }, room))
                        .sort((a, b) => a.d - b.d);
                    if (foundCorners.length > 0) {
                        let corner = foundCorners[0];
                        map[x][y] = { visited: 1, d: this.euclidianDistance(corner.corner, { x: x, y: y }) + corner.d };
                    }
                }
            }
        }
        return map.map((x) => x.map((y) => y.d));
    }
    hasDirectLineOfSight(start, end, room) {
        for (let i = 0; i < room.corners.length - 1; i++) {
            let ir = i + 1;
            if (this.lineIntersect(start, end, room.corners[i], room.corners[ir]))
                return false;
        }
        return true;
    }
    lineIntersect(p1, q1, p2, q2) {
        let o1 = this.orientation(p1, q1, p2);
        let o2 = this.orientation(p1, q1, q2);
        let o3 = this.orientation(p2, q2, p1);
        let o4 = this.orientation(p2, q2, q1);
        if (o1 != o2 && o3 != o4) {
            return true;
        }
        if (o1 == 0 && this.onSegment(p1, p2, q1)) {
            return true;
        }
        if (o2 == 0 && this.onSegment(p1, q2, q1)) {
            return true;
        }
        if (o3 == 0 && this.onSegment(p2, p1, q2)) {
            return true;
        }
        if (o4 == 0 && this.onSegment(p2, q1, q2)) {
            return true;
        }
        return false;
    }
    orientation(p, q, r) {
        let val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
        if (val > 0)
            return 1;
        else if (val < 0)
            return 2;
        else
            return 0;
    }
    onSegment(p, q, r) {
        if (q.x <= Math.max(p.x, r.x) &&
            q.x >= Math.min(p.x, r.x) &&
            q.y <= Math.max(p.y, r.y) &&
            q.y >= Math.min(p.y, r.y))
            return true;
        return false;
    }
    euclidianDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }
    getNeighbors(point, boundingBox) {
        var n = [];
        if (point.x > boundingBox.minX)
            n.push(new Point(point.x - 1, point.y));
        if (point.x < boundingBox.maxX - 1)
            n.push(new Point(point.x + 1, point.y));
        if (point.y > boundingBox.minY)
            n.push(new Point(point.x, point.y - 1));
        if (point.y < boundingBox.maxY - 1)
            n.push(new Point(point.x, point.y + 1));
        if (point.x > boundingBox.minX && point.y > boundingBox.minY)
            n.push(new Point(point.x - 1, point.y - 1));
        if (point.x > boundingBox.minX && point.y < boundingBox.maxY - 1)
            n.push(new Point(point.x - 1, point.y + 1));
        if (point.x < boundingBox.maxX - 1 && point.y > boundingBox.minY)
            n.push(new Point(point.x + 1, point.y - 1));
        if (point.y < boundingBox.maxY - 1 && point.x < boundingBox.maxX - 1)
            n.push(new Point(point.x + 1, point.y + 1));
        return n;
    }
    calculateTemperatureMap(valueMap, sensors, boundingBox) {
        for (let x = boundingBox.minX; x < boundingBox.maxX + 1; x++) {
            for (let y = boundingBox.minY; y < boundingBox.maxY + 1; y++) {
                if (sensors.some((s) => s.x == x && s.y == y))
                    continue;
                valueMap[x][y].v = this.interpolate(x, y, sensors);
            }
        }
        return valueMap;
    }
    calculateTemperatureMapForRoom(room, sensors) {
        for (let x = room.boundingBox.minX; x < room.boundingBox.maxX + 1; x++) {
            for (let y = room.boundingBox.minY; y < room.boundingBox.maxY + 1; y++) {
                if (sensors.some((s) => s.x == x && s.y == y))
                    continue;
                room.valueMap[x][y].v = this.interpolate(x, y, sensors);
            }
        }
        return room.valueMap;
    }
    interpolate(x, y, sensors) {
        return this.interpolate_idw(x, y, sensors);
    }
    interpolate_idw(x, y, sensors) {
        let wxs = 0;
        let ws = 0;
        let inRange = false;
        for (let i = 0; i < sensors.length; i++) {
            if (sensors[i].distanceMap[x][y].d != -1)
                inRange = true;
        }
        if (!inRange)
            return null;
        for (let i = 0; i < sensors.length; i++) {
            if (sensors[i].distanceMap[x][y].d == -1)
                continue;
            let w = 1 / Math.pow(sensors[i].distanceMap[x][y].d, 2);
            wxs += w * sensors[i].t;
            ws += w;
        }
        return wxs / ws;
    }
}
