import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import StatisticsType from '@/entities/enums/statisticsType';
import ReportingInterval, { defaultReportingIntervals, getAllAvailableMeasureTimes } from '@/entities/enums/ReportingInterval';
import VueUtilities from '@/services/VueUtilities';
import { CsvReportTypes } from '@/entities/enums/ReportTypes';
import ReportRecordRepository from '@/services/repository/ReportRecordRepository';
import AppConfig from '@/configLoader';
var reportRecordRepository;
let ExportCsvModal = class ExportCsvModal extends Vue {
    constructor() {
        super(...arguments);
        this.CsvScope = CsvScope;
        this.DateRangeType = DateRangeType;
        this.StatisticsType = StatisticsType;
        this.CsvReportTypes = CsvReportTypes;
        this.selectedValue = DateRangeType.RANGE;
        this.selectedEntity = CsvScope.GROUP;
        this.selectedType = StatisticsType.AVERAGE;
        this.selectedSources = [];
        this.selectedSourceGroups = [];
        this.dateRange = [];
        this.today = new Date();
        this.currentlySelectedMonth = null;
        this.timeOfMeasurement = new Date();
        this.monthFilter = null;
        this.isLoading = false;
        this.isMonth = false;
        this.interval = null;
        this.offset = 0;
        this.measurementOffsetIndex = 0;
        this.allMeasuredTimes = [];
        this.hourFormatInClockPicker = null;
        this.currentShowEmpty = null;
        this.selectedMode = CsvReportTypes.FULL;
        this.selectedEventTypes = [];
        this.dateTimeFrom = new Date();
        this.dateTimeTo = new Date();
        this.hourFormat = '24';
    }
    get hasTemp() {
        return this.eventTypes.findIndex((element) => element.name === 'Temperature');
    }
    get hasHum() {
        return this.eventTypes.findIndex((element) => element.name === 'Humidity');
    }
    get hasPress() {
        return this.eventTypes.findIndex((element) => element.name === 'Pressure');
    }
    get reportRange() {
        return AppConfig.getConfig().attributes?.max_report_range_days || 30;
    }
    /* Only temperature and humidity services are allowed in reports */
    get reportsEventTypes() {
        return this.eventTypes.filter((x) => x.slug == 'temperature' || x.slug == 'humidity' || x.slug == 'pressure');
    }
    get diffTime() {
        return this.dateTimeTo.getTime() - this.dateTimeFrom.getTime();
    }
    msToDays(ms) {
        return Math.round(ms / (1000 * 60 * 60 * 24));
    }
    daysToMs(d) {
        return d * 24 * 60 * 60 * 1000;
    }
    created() {
        this.currentShowEmpty = this.showEmpty;
        this.currentlySelectedMonth = this.selectedMonth;
        this.interval = this.configuration.interval;
        this.selectedType = this.configuration.getModel().model;
        this.monthSelected(this.selectedMonth);
        reportRecordRepository = new ReportRecordRepository(this);
        this.getDateRange();
        this.hourFormatInClockPicker = this.dateTimeManager.getSelectedClockFormat().slice(0, 2);
        if (this.configuration.getModel().model == StatisticsType.DAILY) {
            this.offset = this.configuration.getModel().modelParameters.Offset;
        }
        this.assignMeasureTimes(this.interval).then(() => {
            this.measurementOffsetIndex = this.offset / 60;
        });
        if (this.hasOneDayIntervalSelected == true) {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMinutes(this.configuration.getModel().modelParameters.Offset);
            this.timeOfMeasurement = date;
        }
        if (this.selectedSourceGroupId === null) {
            this.selectedEntity = CsvScope.LOGGER;
            this.selectedSources = this.sources.filter((x) => this.sourceGroupSources.find((y) => y.id == x.id) != null);
        }
        else {
            this.selectedSourceGroups = this.sourceGroups.filter((x) => x.id == this.selectedSourceGroupId);
        }
        this.selectedEventTypes = this.reportsEventTypes.map((x) => x.slug);
    }
    clearDateTimeFrom() {
        this.dateTimeFrom = null;
    }
    clearDateTimeTo() {
        this.dateTimeTo = null;
    }
    dateMonthChanged(data) {
        this.monthSelected(data);
        this.getDateRange();
    }
    getDateRange() {
        let defaultFrom = new Date(this.monthFilter.startTS * 1000);
        defaultFrom.setDate(1);
        let defaultTo = this.today.getTime() < this.monthFilter.stopTS * 1000 ? this.today : new Date(this.monthFilter.stopTS * 1000);
        this.dateRange = [defaultFrom, defaultTo];
    }
    monthSelected(filter) {
        this.monthFilter = this.dateTimeManager.getMonthFilter(filter);
    }
    selectCalType(event) {
        if (event === DateRangeType.MONTH) {
            this.dateMonthChanged(this.currentlySelectedMonth);
            return (this.isMonth = true);
        }
        else if (event === DateRangeType.RANGE) {
            return (this.isMonth = false);
        }
    }
    get hasOneDayIntervalSelected() {
        return this.interval === ReportingInterval.INTERVAL_24_00;
    }
    get allReportingIntervals() {
        return defaultReportingIntervals;
    }
    get canHaveOffset() {
        return this.selectedType === StatisticsType.DAILY;
    }
    clockChanged(value) {
        if (this.hasOneDayIntervalSelected == false)
            return;
        this.offset = value.getHours() * 60 + value.getMinutes();
    }
    measuredOffsetChanged() {
        if (this.hasOneDayIntervalSelected == true)
            return;
        this.offset = this.measurementOffsetIndex * 60;
    }
    async assignMeasureTimes(step) {
        let allTimes = await getAllAvailableMeasureTimes(this, step);
        this.allMeasuredTimes = allTimes;
    }
    async reportingIntervalChanged(step) {
        if (this.hasOneDayIntervalSelected == true) {
            this.clockChanged(this.timeOfMeasurement);
        }
        else {
            this.measurementOffsetIndex = 0;
            this.measuredOffsetChanged();
            this.assignMeasureTimes(step);
        }
    }
    typeChanged() {
        this.measurementOffsetIndex = 0;
        this.measuredOffsetChanged();
        this.assignMeasureTimes(this.interval);
    }
    adjustDateTime(reportRange) {
        let end = new Date(this.dateTimeTo.getTime());
        let start = new Date(this.dateTimeTo.getTime() - reportRange);
        this.dateTimeFrom = start;
        this.dateTimeTo = end;
        return;
    }
    /**
     * Check if currently selected date range is less then maximum range
     * specified in app configuration
     */
    checkDateRange(reportRange, diff) {
        if (diff > reportRange) {
            if (this.selectedMode == CsvReportTypes.RAW) {
                VueUtilities.openErrorToast(this, this.$t(`component.report.detail_table.report_export.rawReportWrongRangeMsg.moreThanDay`).toString());
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.report.configuration.modal.range_alert', {
                    selectedDays: this.msToDays(diff),
                    maxDays: this.msToDays(reportRange)
                }).toString());
            }
            this.adjustDateTime(reportRange);
            return false;
        }
        return true;
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    async exportCsv() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.selectedValue == DateRangeType.RANGE) {
                    if (this.dateTimeFrom.getTime() >= this.dateTimeTo.getTime()) {
                        VueUtilities.openErrorToast(this, this.$t('component.thermal_map.range_error'));
                        return;
                    }
                    if (this.selectedMode != CsvReportTypes.RAW &&
                        this.interval * 60 > (this.dateTimeTo.getTime() - this.dateTimeFrom.getTime()) / 1000) {
                        VueUtilities.openErrorToast(this, this.$t('component.report.configuration.modal.interval_range_alert'));
                        return;
                    }
                    else if (this.dateTimeFrom.getTime() - this.today.getTime() > 0 ||
                        this.dateTimeTo.getTime() - this.today.getTime() > 0) {
                        VueUtilities.openErrorToast(this, this.$t(`component.report.detail_table.report_export.rawReportWrongRangeMsg.selectedFuture`).toString());
                        return;
                    }
                    else {
                        this.dateRange = [this.dateTimeFrom, this.dateTimeTo];
                    }
                    // check max report range
                    let range = 0;
                    let diff = this.diffTime;
                    if (this.selectedMode == CsvReportTypes.RAW) {
                        range = this.daysToMs(1) - 60 * 1000;
                    }
                    else {
                        range = this.daysToMs(this.reportRange);
                    }
                    if (!this.checkDateRange(range, diff)) {
                        return;
                    }
                }
                let sourceIds = [];
                if (this.selectedEntity == CsvScope.LOGGER) {
                    if (this.selectedSources.length <= 0) {
                        VueUtilities.openErrorToast(this, this.$t('component.report.detail_table.report_export.select_entity'));
                        return;
                    }
                    sourceIds = this.selectedSources.map((x) => x.id);
                }
                else {
                    if (this.selectedSourceGroups.length <= 0) {
                        VueUtilities.openErrorToast(this, this.$t('component.report.detail_table.report_export.select_entity'));
                        return;
                    }
                    let groupSourcesIds = this.selectedSourceGroups.flatMap((x) => x.sources.map((y) => y.id));
                    if (groupSourcesIds.length <= 0) {
                        //handle case when selected groups are empty (without loggers)
                        VueUtilities.openErrorToast(this, this.$t('component.report.detail_table.report_export.empty_groups_error'));
                        return;
                    }
                    sourceIds = sourceIds.concat(groupSourcesIds);
                }
                //for onePage report and date range, do not manipulate with time
                this.useEndOfDay =
                    this.selectedMode === CsvReportTypes.RAW || this.selectedValue === DateRangeType.RANGE ? false : true;
                this.isLoading = true;
                let res = await this.createReport(sourceIds);
                if (res === false) {
                    VueUtilities.openErrorToast(this, this.$t('error_messages.file_download_failed'));
                }
                else {
                    VueUtilities.openSuccessToast(this, this.$t('component.report.detail_table.report_export.csv_export.success'));
                    this.resultRetrieved(true);
                }
                this.isLoading = false;
            }
        });
    }
    async createReport(sourceIds) {
        let res = null;
        let config = null;
        switch (this.selectedMode) {
            case CsvReportTypes.FULL:
                config = {
                    from: this.dateTimeManager.formatStartDateForReports(this.dateRange[0]).text,
                    to: this.dateTimeManager.formatEndDateForReports(this.dateRange[1], this.useEndOfDay).text,
                    sourceIds: sourceIds,
                    models: this.selectedType,
                    offset: this.offset,
                    interval: this.interval,
                    showEmpty: this.currentShowEmpty,
                    timezone: this.dateTimeManager.getSelectedTimezone(),
                    types: this.selectedEventTypes
                };
                res = await reportRecordRepository.downloadCsv(config);
                break;
            case CsvReportTypes.RAW:
                config = {
                    from: this.dateTimeManager.formatStartDateForReports(this.dateRange[0]).text,
                    to: this.dateTimeManager.formatEndDateForReports(this.dateRange[1], this.useEndOfDay).text,
                    sourceIds: sourceIds,
                    timezone: this.dateTimeManager.getSelectedTimezone(),
                    showEmpty: this.currentShowEmpty,
                    types: this.selectedEventTypes
                };
                res = await reportRecordRepository.downloadRawCsv(config);
                break;
            default:
                res = null;
                break;
        }
        return res;
    }
};
__decorate([
    Prop({ type: Object })
], ExportCsvModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ExportCsvModal.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ExportCsvModal.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: String })
], ExportCsvModal.prototype, "selectedSourceGroupId", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ExportCsvModal.prototype, "sourceGroupSources", void 0);
__decorate([
    Prop({ type: Date })
], ExportCsvModal.prototype, "selectedMonth", void 0);
__decorate([
    Prop({ type: Object })
], ExportCsvModal.prototype, "configuration", void 0);
__decorate([
    Prop({ type: Boolean })
], ExportCsvModal.prototype, "showEmpty", void 0);
__decorate([
    Prop({ type: Array })
], ExportCsvModal.prototype, "eventTypes", void 0);
ExportCsvModal = __decorate([
    Component({ components: {} })
], ExportCsvModal);
export default ExportCsvModal;
var DateRangeType;
(function (DateRangeType) {
    DateRangeType["MONTH"] = "month";
    DateRangeType["RANGE"] = "range";
})(DateRangeType || (DateRangeType = {}));
var CsvScope;
(function (CsvScope) {
    CsvScope["LOGGER"] = "logger";
    CsvScope["GROUP"] = "group";
})(CsvScope || (CsvScope = {}));
