var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "section-header-level" },
      [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("reports.report_settings.configurations")) + " "
          ),
        ]),
        _vm.$ability.can(_vm.$permActions.MANAGEOWN, _vm.$permSubjects.COMPANY)
          ? _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.openReportSettingModal()
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("reports.report_settings.config_report")) +
                    " "
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "b-table",
          {
            ref: "table",
            staticClass: "mt-4 table-wrap",
            attrs: {
              data: _vm.periodicReports,
              paginated: "",
              "per-page": _vm.pageSize,
              "current-page": _vm.page,
              total: _vm.total,
              "default-sort-direction": _vm.sortDirection,
              "default-sort": _vm.sortField,
              "page-input": true,
              "pagination-order": "is-centered",
              detailed: "",
              "detail-key": "id",
              "show-detail-icon": true,
              loading: _vm.isLoading,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.page = $event
              },
              "update:current-page": function ($event) {
                _vm.page = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "detail",
                  fn: function (periodicReport) {
                    return [
                      _c("article", [
                        _c("div", { staticClass: "content" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "reports.report_settings.table.detail.title"
                                )
                              ) +
                              " "
                          ),
                          _c("hr", { staticClass: "dropdown-divider" }),
                          _c("div", { staticClass: "columns" }, [
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "info-table table-layout-equal table-borderless card-table",
                                },
                                [
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.report_freq"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              `reports.report_settings.table.frequent_range.${periodicReport.row.periodicReportPdfType.toLowerCase()}`
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.report_loggers"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        periodicReport.row.sources.length > 0
                                          ? _vm._l(
                                              periodicReport.row.sources,
                                              function (source) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: source.id,
                                                    staticClass: "mt-0",
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "logger_detail",
                                                            params: {
                                                              locationId:
                                                                source.id,
                                                              departmentId:
                                                                _vm.$route
                                                                  .params
                                                                  .departmentId,
                                                              lang: _vm.$route
                                                                .params.lang,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              source.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "has-text-grey-light",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "reports.report_settings.table.empty_loggers"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                      ],
                                      2
                                    ),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.created"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("td", [
                                      periodicReport.row.createdBy
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "userDetail",
                                                      params: {
                                                        userId:
                                                          periodicReport.row
                                                            .createdBy
                                                            .apiUserId,
                                                        departmentId:
                                                          _vm.$route.params
                                                            .departmentId,
                                                        lang: _vm.$route.params
                                                          .lang,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      periodicReport.row
                                                        .createdBy.fullName
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                ", " +
                                                  _vm._s(
                                                    _vm.dateTimeManager.formatTime(
                                                      new Date(
                                                        periodicReport.row
                                                          .createdAt * 1000
                                                      ),
                                                      null,
                                                      null,
                                                      null
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.updated"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("td", [
                                      periodicReport.row.updatedBy
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "userDetail",
                                                      params: {
                                                        userId:
                                                          periodicReport.row
                                                            .updatedBy
                                                            .apiUserId,
                                                        departmentId:
                                                          _vm.$route.params
                                                            .departmentId,
                                                        lang: _vm.$route.params
                                                          .lang,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      periodicReport.row
                                                        .updatedBy.fullName
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                ", " +
                                                  _vm._s(
                                                    _vm.dateTimeManager.formatTime(
                                                      new Date(
                                                        periodicReport.row
                                                          .updatedAt * 1000
                                                      ),
                                                      null,
                                                      null,
                                                      null
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.author"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("td", [
                                      periodicReport.row.author
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "userDetail",
                                                      params: {
                                                        userId:
                                                          periodicReport.row
                                                            .author.apiUserId,
                                                        departmentId:
                                                          _vm.$route.params
                                                            .departmentId,
                                                        lang: _vm.$route.params
                                                          .lang,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      periodicReport.row.author
                                                        .fullName
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.records"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("td", [
                                      periodicReport.row.toggles
                                        .showRecordsTable ||
                                      periodicReport.row.toggles
                                        .showRecordsTable === false
                                        ? _c(
                                            "span",
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: periodicReport.row
                                                    .toggles.showRecordsTable
                                                    ? "check"
                                                    : "times",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "info-table table-layout-equal table-borderless card-table",
                                },
                                [
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.interval"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("td", [
                                      periodicReport.row.interval
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  periodicReport.row.interval >
                                                    30
                                                    ? _vm.$tc(
                                                        "time.hour",
                                                        periodicReport.row
                                                          .interval / 60
                                                      )
                                                    : _vm.$tc(
                                                        "time.minute",
                                                        periodicReport.row
                                                          .interval
                                                      )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [_vm._v("-")]),
                                      periodicReport.row.statModels
                                        ? _c("span", [
                                            _vm._v(
                                              " (" +
                                                _vm._s(
                                                  _vm.$t(
                                                    `component.report.statisticTypes.${_vm.formatStatModel(
                                                      periodicReport.row
                                                    )}`
                                                  )
                                                ) +
                                                ") "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                  _vm.formatStatModel(periodicReport.row) ===
                                  "daily"
                                    ? _c("tr", [
                                        _c("td", { staticClass: "row-label" }, [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "reports.report_settings.table.measuredAt"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("td", [
                                          periodicReport.row.offset >= 0 &&
                                          _vm.formatStatModel(
                                            periodicReport.row
                                          ) === "daily"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatMeasureTime(
                                                        periodicReport.row
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _c("span", [_vm._v("-")]),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.eventTypes"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    periodicReport.row.eventTypes.length > 0
                                      ? _c("td", [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.currentTypes(
                                                    periodicReport.row
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _c("td", [_vm._v("-")]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.chart"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("td", [
                                      periodicReport.row.toggles.showChart ||
                                      periodicReport.row.toggles.showChart ===
                                        false
                                        ? _c(
                                            "span",
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: periodicReport.row
                                                    .toggles.showChart
                                                    ? "check"
                                                    : "times",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.empty_records"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("td", [
                                      periodicReport.row.toggles.showEmpty ||
                                      periodicReport.row.toggles.showEmpty ===
                                        false
                                        ? _c(
                                            "span",
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: periodicReport.row
                                                    .toggles.showEmpty
                                                    ? "check"
                                                    : "times",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "row-label" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "reports.report_settings.table.annotations"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("td", [
                                      periodicReport.row.toggles
                                        .showAnnotationsTable ||
                                      periodicReport.row.toggles
                                        .showAnnotationsTable === false
                                        ? _c(
                                            "span",
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: periodicReport.row
                                                    .toggles
                                                    .showAnnotationsTable
                                                    ? "check"
                                                    : "times",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("span", [_vm._v("-")]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  },
                },
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("section", { staticClass: "section" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "content has-text-grey has-text-centered",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("reports.report_settings.table.empty")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "bottom-left",
                  fn: function () {
                    return [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.notifications_list.table.row_per_page"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "b-field",
                        { staticClass: "pl-2 mb-4-mobile" },
                        [
                          _c(
                            "b-select",
                            {
                              model: {
                                value: _vm.pageSize,
                                callback: function ($$v) {
                                  _vm.pageSize = $$v
                                },
                                expression: "pageSize",
                              },
                            },
                            _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                              return _c(
                                "option",
                                { key: option, domProps: { value: option } },
                                [_vm._v(" " + _vm._s(option) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.periodicReports.length > 0
                  ? {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "is-flex is-justify-content-flex-end",
                            },
                            [
                              _c("p", { staticClass: "pt-2 has-text-grey" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.company.list.table.results",
                                        {
                                          from: _vm.fromTableData,
                                          to: _vm.toTableData,
                                          total: _vm.total,
                                        }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          [
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.periodicReportPdfType.field,
                label: _vm.$t(
                  `reports.report_settings.table.${_vm.tableColumns.periodicReportPdfType.field}`
                ),
                visible: _vm.tableColumns.periodicReportPdfType.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (periodicReport) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `reports.report_settings.table.frequent_range.${periodicReport.row.periodicReportPdfType.toLowerCase()}`
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.interval.field,
                label: _vm.$t(
                  `reports.report_settings.table.${_vm.tableColumns.interval.field}`
                ),
                visible: _vm.tableColumns.interval.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (periodicReport) {
                    return [
                      periodicReport.row.interval
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  periodicReport.row.interval > 30
                                    ? _vm.$tc(
                                        "time.hour",
                                        periodicReport.row.interval / 60
                                      )
                                    : _vm.$tc(
                                        "time.minute",
                                        periodicReport.row.interval
                                      )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                      periodicReport.row.statModels
                        ? _c("span", [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm.$t(
                                    `component.report.statisticTypes.${_vm.formatStatModel(
                                      periodicReport.row
                                    )}`
                                  )
                                ) +
                                ") "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.measuredAt.field,
                label: _vm.$t(
                  `reports.report_settings.table.${_vm.tableColumns.measuredAt.field}`
                ),
                visible: _vm.tableColumns.measuredAt.visible,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (periodicReport) {
                    return [
                      periodicReport.row.offset >= 0 &&
                      _vm.formatStatModel(periodicReport.row) === "daily"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatMeasureTime(periodicReport.row)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.eventTypes.field,
                label: _vm.$t(
                  `reports.report_settings.table.${_vm.tableColumns.eventTypes.field}`
                ),
                visible: _vm.tableColumns.eventTypes.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (periodicReport) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            periodicReport.row.eventTypes.length > 0
                              ? _vm.currentTypes(periodicReport.row)
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm.$ability.can(
              _vm.$permActions.MANAGEOWN,
              _vm.$permSubjects.COMPANY
            )
              ? _c("b-table-column", {
                  attrs: { field: "action", label: _vm.$t("generals.action") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (periodicReport) {
                          return [
                            _c(
                              "b-dropdown",
                              {
                                attrs: {
                                  position: "is-bottom-left",
                                  "append-to-body": "",
                                  "aria-role": "list",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "trigger",
                                      fn: function () {
                                        return [
                                          _c("b-icon", {
                                            staticClass: "clickable",
                                            attrs: { icon: "ellipsis-h" },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "custom-dropdown-item",
                                    attrs: { "aria-role": "listitem" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteConfigModal(
                                          periodicReport.row.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", { attrs: { icon: "trash" } }),
                                    _c("span", { staticClass: "pl-2" }, [
                                      _vm._v(_vm._s(_vm.$t("generals.delete"))),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "custom-dropdown-item",
                                    attrs: { "aria-role": "listitem" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editConfigModal(
                                          periodicReport.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", { attrs: { icon: "pen" } }),
                                    _c("span", { staticClass: "pl-2" }, [
                                      _vm._v(_vm._s(_vm.$t("generals.edit"))),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    198918294
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }