import User from '../User';
export class AdminUser {
    constructor() {
        this.detailedInfo = {};
    }
    isAdmin() {
        return this.role?.IsAdmin === true;
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => AdminUser.define(et));
        }
        else if (data != null) {
            return AdminUser.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let user = new AdminUser();
        user.id = data.Id;
        user.apiUserId = data.Id;
        user.email = data.Email;
        user.fullName = data.FullName;
        user.username = data.Username;
        user.role = data.Role;
        user.verified = data.Verified;
        user.enabled = data.Enabled;
        user.newsletter = data.NewsLetter;
        user.actionReason = data.ActionReason;
        user.actionBy = data.ActionBy;
        user.lastLogin = data.LastLogin;
        user.lastActivity = data.LastActivity;
        user.registeredAt = data.RegisteredAt;
        user.customerData = data.CustomerData;
        user.phoneNumber = {
            Number: data.PhoneNumber?.Number,
            Verified: data.PhoneNumber?.Verified
        };
        user.detailedInfo.PhoneNumber = data.PhoneNumber;
        return user;
    }
    static convertToUsers(adminUsers) {
        let users = [];
        adminUsers.forEach((adminUser) => {
            users.push(AdminUser.convertToUser(adminUser));
        });
        return users;
    }
    static convertToUser(adminUser) {
        let user = new User();
        user.apiUserId = adminUser.id;
        user.id = adminUser.id;
        user.email = adminUser.email;
        user.fullName = adminUser.fullName;
        user.username = adminUser.username;
        user.detailedInfo = adminUser.detailedInfo;
        return user;
    }
}
export var UserActions;
(function (UserActions) {
    UserActions["DISABLE"] = "disable";
    UserActions["UNVERIFY"] = "unverify";
})(UserActions || (UserActions = {}));
