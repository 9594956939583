import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Event } from '@/entities/models/Event';
import EventDomain from '@/entities/enums/eventDomains';
import { UserSettingsValues } from '@/entities/UserSettings';
import DecimalConversion from '@/services/DecimalConversion';
import MeasurementStatuses from '@/entities/enums/measurementStatuses';
import EventType from '@/entities/EventType';
import { ServiceState } from '@/entities/enums/ServiceState';
const UPTIME_ID = EventType.UPTIME_ID;
let EventsTableItem = class EventsTableItem extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
        this.MeasurementStatuses = MeasurementStatuses;
        this.ServiceState = ServiceState;
        this.Event = Event;
        this.EventDomain = EventDomain;
        this.UserSettingsValues = UserSettingsValues;
        this.UPTIME_ID = UPTIME_ID;
    }
    get hasErrorOrActiveService() {
        return (this.data.source.logger && this.data.source.logger.serviceState == ServiceState.ERROR) ||
            this.data.source.activeService
            ? true
            : false;
    }
    favoriteSelected(event, id) {
        event.stopPropagation();
        if (id == null)
            this.$emit('favoriteSelected', UserSettingsValues.UNASSIGNED_GROUP_ID);
        else
            this.$emit('favoriteSelected', id);
    }
    getBoundaryForService(event, boundaries) {
        return boundaries.find((x) => x.eventTypeSlug === event.eventType.slug);
    }
    isOneDay(date) {
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (date.getTime() > yesterday.getTime()) {
            return true;
        }
        else {
            return false;
        }
    }
    getTimeDifference(date) {
        let timeUnit = this.dateTimeManager.getTimeDifference(date);
        return `${this.$t('component.last_events.table.before', {
            time: timeUnit.time,
            part: this.$t('component.last_events.table.timeUnits.' + timeUnit.unit)
        })}`;
    }
    getTooltipText(boundary) {
        if (boundary) {
            let text = '';
            text += this.$t('component.boundaries.lower_alarm').toString();
            text += ': ';
            boundary.lowerAlarmBoundary.Value != null
                ? (text += boundary.lowerAlarmBoundary.Value + boundary.lowerAlarmBoundary.Unit)
                : (text += '-');
            text += '<br>';
            text += this.$t('component.boundaries.lower_warning').toString();
            text += ': ';
            boundary.lowerWarningBoundary.Value != null
                ? (text += boundary.lowerWarningBoundary.Value + boundary.lowerWarningBoundary.Unit)
                : (text += '-');
            text += '<br>';
            text += this.$t('component.boundaries.upper_warning').toString();
            text += ': ';
            boundary.upperWarningBoundary.Value != null
                ? (text += boundary.upperWarningBoundary.Value + boundary.upperWarningBoundary.Unit)
                : (text += '-');
            text += '<br>';
            text += this.$t('component.boundaries.upper_alarm').toString();
            text += ': ';
            boundary.upperAlarmBoundary.Value != null
                ? (text += boundary.upperAlarmBoundary.Value + boundary.upperAlarmBoundary.Unit)
                : (text += '-');
            return text;
        }
    }
};
__decorate([
    Prop({ type: Object })
], EventsTableItem.prototype, "data", void 0);
__decorate([
    Prop({ type: Object })
], EventsTableItem.prototype, "dateTimeManager", void 0);
EventsTableItem = __decorate([
    Component
], EventsTableItem);
export default EventsTableItem;
