const DepartmentTagColors = new Map([
    ['prevádzka funguje ok', 'is-success'],
    ['prebieha identifikácia problému', 'is-warning'],
    ['problém identifikovaný', 'is-success-dark'],
    ['zákazník upozornený na problém', 'is-info'],
    ['zákazník rieši problém svojpomocne', 'is-primary'],
    ['R-DAS rieši problém', 'is-black'],
    ['zákazník problém ignoruje', 'is-bad'],
    ['čakanie na vyjadrenie zákazníka', 'is-info-dark']
]);
export function getTagColor(key) {
    return DepartmentTagColors.get(key) || 'is-light';
}
