var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
      },
      [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(" " + _vm._s(_vm.$t("loggers.settings_title")) + " "),
        ]),
        !_vm.isEditing
          ? _c("b-button", {
              attrs: { "icon-right": "pencil-alt", type: "is-white" },
              on: {
                click: function ($event) {
                  return _vm.editSettings()
                },
              },
            })
          : _c(
              "b-button",
              {
                staticClass: "ml-1",
                attrs: { "icon-left": "times-circle", type: "is-white" },
                on: {
                  click: function ($event) {
                    return _vm.editSettings()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.cancel_edit")) + " ")]
            ),
      ],
      1
    ),
    _c(
      "section",
      [
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("loggers.offline_threshold")
                ),
              },
              message: _vm.errors.first(_vm.$t("loggers.offline_threshold")),
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$t("loggers.offline_threshold")) + " "
                    ),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t("loggers.offline_threshold_note"),
                          position: "is-left",
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "question-circle",
                            type: "is-grey",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            !_vm.isEditing
              ? _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.secToMinutes(
                        _vm.departmentSettings.Source.OfflineThreshold
                      )
                    ) + " min"
                  ),
                ])
              : [
                  _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min_value:5",
                        expression: "'required|min_value:5'",
                      },
                    ],
                    attrs: {
                      name: _vm.$t("loggers.offline_threshold"),
                      placeholder: _vm.$t("loggers.offline_threshold"),
                      type: "number",
                    },
                    model: {
                      value: _vm.offlineThreshold,
                      callback: function ($$v) {
                        _vm.offlineThreshold = _vm._n($$v)
                      },
                      expression: "offlineThreshold",
                    },
                  }),
                  _c("p", { staticClass: "control" }, [
                    _c("span", { staticClass: "button is-static" }, [
                      _vm._v("min"),
                    ]),
                  ]),
                ],
          ],
          2
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              type: {
                "is-danger": _vm.offlineNotification == "true",
              },
              message:
                _vm.offlineNotification == "true" && _vm.isEditing
                  ? _vm.$t("loggers.stop_notifications_alert")
                  : "",
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$t("loggers.stop_notifications")) + " "
                    ),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t("loggers.stop_notifications_note"),
                          position: "is-left",
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "question-circle",
                            type: "is-grey",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            !_vm.isEditing
              ? _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.departmentSettings.Source
                          .DoNotNotifyWhenDeviceOffline === "true"
                          ? _vm.$t("generals.yes")
                          : _vm.$t("generals.no")
                      ) +
                      " "
                  ),
                ])
              : [
                  _c(
                    "b-switch",
                    {
                      attrs: {
                        type: "is-primary",
                        "true-value": "true",
                        "false-value": "false",
                        "native-value":
                          _vm.departmentSettings.Source
                            .DoNotNotifyWhenDeviceOffline,
                      },
                      model: {
                        value: _vm.offlineNotification,
                        callback: function ($$v) {
                          _vm.offlineNotification = $$v
                        },
                        expression: "offlineNotification",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.offlineNotification === "true"
                              ? _vm.$t("generals.yes")
                              : _vm.$t("generals.no")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
          ],
          2
        ),
        _vm.customExpirationIsAllowed
          ? _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("loggers.loggersCalibrationExpiration"),
                  type: {
                    "is-danger": _vm.errors.has(
                      _vm.$t("loggers.loggersCalibrationExpiration")
                    ),
                  },
                  message: _vm.errors.first(
                    _vm.$t("loggers.loggersCalibrationExpiration")
                  ),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("loggers.loggersCalibrationExpiration")
                              ) +
                              " "
                          ),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                type: "is-dark",
                                label: _vm.$t("loggers.customCalibrationNote"),
                                position: "is-left",
                                multilined: "",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  size: "is-small",
                                  icon: "question-circle",
                                  type: "is-grey",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1643412504
                ),
              },
              [
                !_vm.isEditing
                  ? _c(
                      "p",
                      [
                        _vm.departmentSettings.Loggers.Calibration.Custom
                          .ExpiresAt
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tc(
                                      "loggers.expirationYears",
                                      _vm.backupCustomExpiration,
                                      { year: _vm.backupCustomExpiration }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [_vm._v(" - ")],
                      ],
                      2
                    )
                  : _c(
                      "b-field",
                      { attrs: { grouped: "" } },
                      [
                        _c(
                          "b-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "loggers.expirationPlaceholder"
                              ),
                            },
                            model: {
                              value: _vm.customExpiration,
                              callback: function ($$v) {
                                _vm.customExpiration = $$v
                              },
                              expression: "customExpiration",
                            },
                          },
                          _vm._l(_vm.calibrationExpOptions, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tc(
                                        "loggers.expirationYears",
                                        option,
                                        { year: option }
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
              ],
              1
            )
          : _vm._e(),
        _vm.isEditing
          ? _c(
              "div",
              { staticClass: "has-text-centered pt-2" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary", disabled: !_vm.hasChanges },
                    on: {
                      click: function ($event) {
                        return _vm.save()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("generals.save")) + " ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }