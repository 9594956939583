import EventType from '@/entities/EventType';
export default class ComputedEventType {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((cet) => ComputedEventType.define(cet));
        }
        else if (data != null) {
            return ComputedEventType.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let computedEventType = new ComputedEventType();
        computedEventType.key = data.Key;
        if (data.ProducesType)
            computedEventType.producesType = EventType.fromApi(data.ProducesType);
        if (data.RequiredTypes)
            computedEventType.requiredTypes = EventType.fromApi(data.RequiredTypes);
        return computedEventType;
    }
}
