import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import SourceRepository from '@/services/repository/SourceRepository';
import ReportRecordRepository from '@/services/repository/ReportRecordRepository';
import StatisticsType from '@/entities/enums/statisticsType';
import VueUtilities from '@/services/VueUtilities';
import ThermoMapRepository from '@/services/repository/ThermoMapRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import ThermalMapOverview from '../components/thermalMap/ThermalMapOverview.vue';
import ThermalMapUpload from '../components/thermalMap/ThermalMapUpload.vue';
var sourceRepository;
var reportRecordRepository;
var thermoMapRepository;
let ThermalMapPage = class ThermalMapPage extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.sources = [];
        this.selectedMapImage = null;
        this.dateTimeManager = null;
        this.statisticsList = [];
        this.selectedMap = null;
        this.thermoMaps = [];
        this.isUploadingThermoMap = false;
        this.isUpdatingThermoMap = false;
        this.thermoImages = [];
    }
    async created() {
        sourceRepository = new SourceRepository(this);
        reportRecordRepository = new ReportRecordRepository(this);
        thermoMapRepository = new ThermoMapRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(thermoMapRepository.getAllThermoMaps());
        allPromises.push(sourceRepository.getVisibleSources());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1]);
        });
    }
    async processLoadedDataForPage(maps, sources) {
        this.sources = sources.getData();
        this.thermoMaps = maps;
        if (this.thermoMaps.length > 0) {
            await this.loadThermoImages();
            await this.loadStatistics();
            this.loadDataForThermoMap(this.thermoMaps[0].id);
            this.isLoading = false;
        }
        else {
            this.selectedMap = null;
            this.selectedMapImage = null;
            this.uploadThermalMap(true);
            this.isLoading = false;
        }
    }
    async loadStatistics() {
        this.statisticsList = [];
        let allPromises = [];
        this.thermoMaps.forEach((thermoMap) => {
            let sourceIds = thermoMap.locations.map((x) => x.EntityId);
            allPromises.push(reportRecordRepository.getStatisticForSources(sourceIds, this.dateTimeManager.formatStartDateForReports(new Date(thermoMap.from * 1000)).text, this.dateTimeManager.formatEndDateForReports(new Date(thermoMap.to * 1000), false).text, StatisticsType.AVERAGE, null, null, this.dateTimeManager.getSelectedTimezone()));
        });
        await Promise.all(allPromises).then(async (response) => {
            response.forEach((res, i) => {
                if (res) {
                    this.statisticsList.push({ thermoMapId: this.thermoMaps[i].id, statistics: res });
                }
            });
        });
    }
    async loadThermoImages() {
        let allPromises = [];
        this.thermoImages = [];
        this.thermoMaps.forEach((thermoMap) => allPromises.push(thermoMapRepository.getThermoMapImage(thermoMap.id)));
        await Promise.all(allPromises).then(async (response) => {
            if (response.includes(null)) {
                VueUtilities.openErrorToast(this, this.$t('component.floorPlan.loadingError'));
            }
            response.forEach((res, i) => {
                if (res) {
                    this.thermoImages.push({ thermoMapId: this.thermoMaps[i].id, image: res });
                }
            });
        });
    }
    async cancelUpdating() {
        this.isUploadingThermoMap = false;
        this.isUpdatingThermoMap = false;
        if (this.thermoMaps.length > 0) {
            this.isLoading = true;
            await this.loadDataForThermoMap(this.thermoMaps[0].id);
            this.isLoading = false;
        }
    }
    async setSelectedThermoMap(id) {
        await this.loadDataForThermoMap(id);
        this.$refs.thermalMapOverviewComp.loadData();
    }
    async loadDataForThermoMap(id) {
        this.selectedMap = this.thermoMaps.find((x) => x.id === id);
        this.selectedMapImage = this.thermoImages.find((x) => x.thermoMapId === id).image;
    }
    async uploadThermalMap(value, mapConfig) {
        if (this.isUploadingThermoMap) {
            this.isLoading = true;
            let allPromises = [];
            allPromises.push(thermoMapRepository.createThermoMap(mapConfig));
            await Promise.all(allPromises).then((response) => {
                this.loadData();
            });
        }
        else {
            this.selectedMap = null;
            this.selectedMapImage = null;
        }
        this.isUploadingThermoMap = value;
    }
    async updateThermoMap(value, thermoMapId, mapConfig) {
        if (!this.isUpdatingThermoMap) {
            this.selectedMap = this.thermoMaps.find((x) => x.id === thermoMapId);
        }
        else {
            this.isLoading = true;
            let allPromises = [];
            allPromises.push(thermoMapRepository.updateThermoMap(mapConfig, thermoMapId));
            await Promise.all(allPromises).then((response) => {
                this.loadData();
            });
        }
        this.isUpdatingThermoMap = value;
    }
    openDeleteDialog() {
        this.$buefy.dialog.confirm({
            title: this.$t('component.thermal_map.delete.title'),
            message: this.$t('component.thermal_map.delete.msg'),
            confirmText: this.$t('component.thermal_map.delete.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.deleteMapAndConfiguration()
        });
    }
    async deleteMapAndConfiguration() {
        this.isLoading = true;
        let resp = await thermoMapRepository.deleteThermoMap(this.selectedMap.id);
        if (resp == true) {
            VueUtilities.openSuccessToast(this, this.$t('component.thermal_map.delete.success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.thermal_map.delete.failure'));
        }
        this.loadData();
    }
};
ThermalMapPage = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.EDIT, Subjects.ADMIN_THERMOMAP)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            this.loadData();
            next(); // to resolve hook
        },
        beforeRouteLeave(to, from, next) {
            if (this.$refs.thermalMapUploadComp &&
                this.$refs.thermalMapUploadComp?.hasChanges &&
                to.query.confirmed != 'true') {
                this.$refs.thermalMapUploadComp.routeToNavigate = to;
                this.$refs.thermalMapUploadComp.isUnsavedChangesModalActive = true;
            }
            else {
                next();
            }
        },
        components: { ThermalMapUpload, ThermalMapOverview }
    })
], ThermalMapPage);
export default ThermalMapPage;
